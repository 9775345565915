import { Alert, Tooltip } from 'antd';
import { capitalize } from 'lodash';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import './NotificationBox.scss';

type NotificationBoxProps = {
  isError: boolean;
  selectedType: string;
  smallOverage: number;
  largeOverage: number;
};

const NotificationBox = ({
  isError,
  selectedType,
  smallOverage,
  largeOverage,
}: NotificationBoxProps) => {
  const renderAlertMessage = () => {
    return (
      <div className="NotificationBox-Wrapper">
        <div>
          <p className="NotificationBox-Title">
            {!isError && <TickIcon className="NotificationBox-TickIcon" />}
            {isError ? 'Caution: Extra Fee' : 'Success!'}
          </p>
          <p className="NotificationBox-Text">
            {isError
              ? `${capitalize(selectedType)} is outside subscription.`
              : `You've placed your bounding box.`}
          </p>
        </div>
        {isError && (
          <Tooltip
            placement="topLeft"
            title={`Projects outside your subscription cost an extra: Small $${smallOverage} | Large $${largeOverage}`}
            overlayStyle={{ maxWidth: '22rem' }}
            arrowPointAtCenter
          >
            <QuestionMarkIcon className="NotificationBox-QuestionMarkIcon" />
          </Tooltip>
        )}
      </div>
    );
  };
  return (
    <div className="NotificationBox">
      <Alert message={renderAlertMessage()} type={isError ? 'error' : 'success'} />
    </div>
  );
};

export default NotificationBox;
