import styled from 'styled-components';

const Container = styled.div`
  .row {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
  }
`;

export default Container;
