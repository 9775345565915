import { Alert, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { handleOpenCancelConfirm } from '../helpers';
import './RetryAlert.scss';

const RetryAlert = ({
  error,
  addedFileCount,
  recoveredFileCount,
  handleCancelUpload,
  handleRetry,
  isSaveAllowed,
  uploadInProgress,
}) => {
  return (
    <Alert
      className="RetryAlert"
      message="There was an error during upload."
      showIcon
      description={
        <div className="RetryAlert-Description">
          <span>{`${addedFileCount} out of ${
            recoveredFileCount + addedFileCount
          } uploads were not successful.`}</span>
          <div className="RetryAlert-Description-Buttons">
            <Button
              onClick={() =>
                handleOpenCancelConfirm(uploadInProgress, handleCancelUpload, recoveredFileCount)
              }
              className="Button--White"
            >
              Cancel
            </Button>
            <Tooltip title={!isSaveAllowed && error}>
              <Button
                onClick={handleRetry}
                disabled={!isSaveAllowed}
                aria-disabled={!isSaveAllowed}
                className="Button--Blue"
              >
                Retry Upload
              </Button>
            </Tooltip>
          </div>
        </div>
      }
      type="error"
    />
  );
};

RetryAlert.propTypes = {
  error: PropTypes.string.isRequired,
  addedFileCount: PropTypes.number.isRequired,
  recoveredFileCount: PropTypes.number.isRequired,
  handleCancelUpload: PropTypes.func.isRequired,
  handleRetry: PropTypes.func.isRequired,
  isSaveAllowed: PropTypes.bool.isRequired,
  uploadInProgress: PropTypes.bool.isRequired,
};

export default RetryAlert;
