import * as Yup from 'yup';

import {
  passwordMaxLengthValidation,
  passwordMinLengthValidation,
  passwordRegexValidation,
} from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

const noWhitespacesCheck: Yup.TestConfig = {
  name: 'no-whitespaces',
  message: 'Cannot start or end with spaces',
  test: function (this, value) {
    if (!value) {
      return true;
    }
    return value === (value as string).trim();
  },
};

const matchingPasswordsCheck: Yup.TestConfig = {
  name: 'passwords-match',
  message: 'Passwords must match',
  test: function matchingPasswordsCheck(this, value) {
    if (!value) {
      return true;
    }
    return this.parent.password === value;
  },
};

const matchingEmailsCheck: Yup.TestConfig = {
  name: 'emails-match',
  message: 'Email addresses must match',
  test: function matchingEmailsCheck(this, value) {
    if (!value) {
      return true;
    }
    return this.parent.email === value;
  },
};

const validationSchema = Yup.object().shape({
  showConfirmEmail: Yup.boolean(),
  email: Yup.string().required('Email is required').email('Enter valid email'),
  password: Yup.string()
    .notRequired()
    .test(noWhitespacesCheck)
    .min(...passwordMinLengthValidation)
    .max(...passwordMaxLengthValidation)
    .matches(...passwordRegexValidation),
  confirmPassword: Yup.string().when(FORM_CONTROLS.password, (value) =>
    value.length > 0 && value?.[0] !== undefined
      ? Yup.string()
          .required('Passwords must match')
          .test(matchingPasswordsCheck)
          .test(noWhitespacesCheck)
      : Yup.string()
  ),
  confirmEmail: Yup.string().when(FORM_CONTROLS.showConfirmEmail, (value) =>
    value?.[0]
      ? Yup.string()
          .required('Email addresses must match')
          .test(matchingEmailsCheck)
          .test(noWhitespacesCheck)
      : Yup.string()
  ),
});

type FormValues = {
  confirmEmail: string;
  confirmPassword: string;
  email: string;
  password: string;
  showConfirmEmail: boolean;
};

const initialValues: FormValues = {
  confirmEmail: '',
  confirmPassword: '',
  email: '',
  password: '',
  showConfirmEmail: false,
};

export { initialValues, validationSchema };
export type { FormValues };
