import { Store } from '@reduxjs/toolkit';
import axios from 'axios';

import { refreshTokenRequested } from 'state/slices/authSlice';

import { errorCodes } from 'helpers/constants/errorCodes';
import { rootRoute } from '../helpers/constants/routes';
import { destroyTokens } from '../helpers/utils/manageTokens';

import { refreshTokenUrl } from './auth/authUrls';

const baseURL = process.env.REACT_APP_API;

const API = axios.create({
  baseURL,
});

API.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const authorizationProvider = (store: Store) => {
  API.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error.response ? error.response.status : null;
      const { isLoggedIn, isRefreshingToken } = store.getState().auth;
      const originalRequest = error.config;

      if (status === errorCodes.UNAUTHORIZED && originalRequest.url === refreshTokenUrl()) {
        destroyTokens();
        window.location.replace(rootRoute());
      } else if (status === errorCodes.UNAUTHORIZED && isLoggedIn) {
        const token = localStorage.getItem('refreshToken');

        // If a refresh token API request is already running, wait for it to finish
        // before re-attempting the original API that returned a 401
        if (isRefreshingToken) {
          const checkIfReady = (): Promise<any> => {
            const { isRefreshingToken: isStillRefreshingToken } = store.getState().auth;
            if (isStillRefreshingToken) {
              return new Promise((resolve) => setTimeout(() => resolve(checkIfReady()), 1000));
            } else {
              return API(originalRequest);
            }
          };
          return checkIfReady();
        } else {
          // @ts-ignore
          const { type } = await store.dispatch(refreshTokenRequested(token));
          if (type === 'auth/refreshTokenRequested/fulfilled') {
            return API(originalRequest);
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default API;
