import { DroneDataType } from '@cpm/scanifly-shared-data';
import moment from 'moment';
import { Project } from 'types';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { csvDateFormat } from 'helpers/utils/dateFormat';
import { formatPercent } from 'helpers/utils/formatPercent';
import { isProjectCompleted } from 'helpers/utils/isProjectCompleted';
import { renderProjectType } from 'helpers/utils/renderProjectType';

import { droneDataScoreFields } from '../constants/droneDataScoreFields';

export const handleProjectsCSVExport = ({
  dataSource,
  tableName,
}: {
  dataSource: Project[];
  tableName: string;
}) => {
  return dataSource
    .filter((project) => {
      if (tableName === TABLE_NAMES.PROJECTS) {
        return project.statusDescription !== 'Draft';
      }
      return true;
    })
    .sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1))
    .map((item) => {
      const { id, type, company, engine, uploadComplete, completedDate, statusDescription } =
        item || {};

      const droneDataScore = {} as DroneDataType;

      if (tableName === TABLE_NAMES.USER_PROJECTS || tableName === TABLE_NAMES.CUSTOMER_PROJECTS) {
        droneDataScoreFields.forEach((field) => {
          const [fieldName] = field;
          droneDataScore[fieldName] = formatPercent(
            item?.droneData?.[fieldName] ? Number(item.droneData[fieldName]) : 0
          );
        });
      }

      return {
        ...item,
        submittedFor: company ? company.name : '-',
        type: type ? renderProjectType(type) : '-',
        engine: engine ? (engine === 'watt3d' ? 'Watt3d' : 'RC') : '-',
        uploadComplete: uploadComplete ? moment(uploadComplete).format(csvDateFormat) : '-',
        completedDate: completedDate ? moment(completedDate).format(csvDateFormat) : '-',
        id: isProjectCompleted(statusDescription)
          ? `${window.location.origin}/projects/${id}/designs`
          : '-',
        ...droneDataScore,
      };
    });
};
