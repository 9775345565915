import { ChecklistStatusEnum } from '@cpm/scanifly-shared-data';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

interface StatusTagProps {
  statusText: string;
  checklistStatus: string;
}

const COLOR_MAP: { [key: string]: colors } = {
  [ChecklistStatusEnum.formSubmitted]: colors.green,
  [ChecklistStatusEnum.pdfProcessing]: colors.green,
  [ChecklistStatusEnum.awaitingMedia]: colors.teal,
  [ChecklistStatusEnum.draft]: colors.yellow,
  [ChecklistStatusEnum.pdfProcessingFailed]: colors.red,
};

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: ${fontSizes.extraSmall};
  padding: 0.1rem 0.5rem;
  color: ${colors.white};
`;

export const StatusTag = ({ statusText, checklistStatus }: StatusTagProps) => {
  if (checklistStatus === ChecklistStatusEnum.completed) return null;

  return <Tag style={{ backgroundColor: COLOR_MAP[checklistStatus] }}>{statusText}</Tag>;
};
