import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';

import { AREA_LIMITS, PROJECT_TYPE_AREA_LIMITS } from 'helpers/constants/areaLimitsInSqFt';
import { squareMetersToFeetConverter } from 'helpers/utils/metricConversions';

export const mapAreaToProjectType = (area: number): PROJECT_TYPES | null => {
  if (!area) return PROJECT_TYPES.SMALL;
  const areaInSquareFeet = parseFloat(squareMetersToFeetConverter(area).toFixed(2));
  if (areaInSquareFeet < AREA_LIMITS.min) {
    return PROJECT_TYPES.SMALL;
  }
  if (areaInSquareFeet > AREA_LIMITS.max) {
    return PROJECT_TYPES.LARGE;
  }
  return (Object.keys(PROJECT_TYPE_AREA_LIMITS).find(
    (key) =>
      areaInSquareFeet >= PROJECT_TYPE_AREA_LIMITS[key as 'small' | 'large'].min &&
      areaInSquareFeet <= PROJECT_TYPE_AREA_LIMITS[key as 'small' | 'large'].max
  ) ?? null) as PROJECT_TYPES | null;
};
