import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {
  PAYMENT_FIELD,
  PAYMENT_INPUT_OPTIONS,
  STRIPE_ERRORS,
} from 'helpers/constants/stripeConstants';
import { usePaymentField } from 'helpers/hooks/usePaymentField';

import './PaymentField.scss';

const PaymentField = ({ paymentField, setValid, label, authStyle = false }) => {
  const [touched, setTouched, focused, setFocused, filled, setFilled, error, setError] =
    usePaymentField();

  const validateStatus = (error, filled, touched) => {
    return (error || !filled) && touched && 'Form-Input-Error';
  };

  const handleFocus = () => {
    setFocused((focused) => !focused);
  };

  const renderPaymentField = () => {
    switch (paymentField) {
      case PAYMENT_FIELD.CREDIT_CARD:
        return (
          <CardNumberElement
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            options={PAYMENT_INPUT_OPTIONS}
          />
        );
      case PAYMENT_FIELD.EXPIRY_DATE:
        return (
          <CardExpiryElement
            onBlur={handleBlur}
            onChange={handleChange}
            options={PAYMENT_INPUT_OPTIONS}
          />
        );
      case PAYMENT_FIELD.CVC_CODE:
        return (
          <CardCvcElement
            onBlur={handleBlur}
            onChange={handleChange}
            options={PAYMENT_INPUT_OPTIONS}
          />
        );
      default:
        return;
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (touched) {
      return;
    }
    setTouched(true);
  };

  const renderValidationMessage = (field, error, touched, filled) => {
    if (touched && !filled) {
      switch (field) {
        case PAYMENT_FIELD.CREDIT_CARD:
          return 'Credit card number is required';
        case PAYMENT_FIELD.EXPIRY_DATE:
          return 'Expiry date is required';
        case PAYMENT_FIELD.CVC_CODE:
          return 'CVC code is required';
        default:
          return 'This field is required';
      }
    }

    if (error && error.elementType === field) {
      switch (error.error?.code) {
        case STRIPE_ERRORS.INCOMPLETE_NUMBER:
          return 'Card number is incomplete';
        case STRIPE_ERRORS.INVALID_NUMBER:
          return 'Card number is invalid';
        case STRIPE_ERRORS.INCOMPLETE_EXPIRY:
          return 'Expiry date is incomplete';
        case STRIPE_ERRORS.INVALID_EXPIRY_YEAR_PAST:
        case STRIPE_ERRORS.INVALID_EXPIRY_DATE:
          return 'Expiry date is invalid';
        case STRIPE_ERRORS.INCOMPLETE_CVC:
          return 'CVC code is incomplete';
        default:
          return 'The field is invalid';
      }
    }
  };

  const handleChange = (e) => {
    const error = e.error?.code ? e : null;
    const complete = e.complete;
    const filled = !e.empty;

    setError(error);
    setValid(complete);
    setFilled(filled);
  };

  return (
    <div
      className={cn('FormControl-Wrapper', {
        'PaymentField-FormControl-Wrapper--Short':
          !authStyle && paymentField === PAYMENT_FIELD.CVC_CODE,
      })}
    >
      <label htmlFor="paymentInput">{label}</label>
      <div
        className={cn(`PaymentField-PaymentInput ${validateStatus(error, filled, touched)}`, {
          'PaymentField-PaymentInput--Auth': authStyle,
          'PaymentField-PaymentInput--Focus': focused,
          'PaymentField-PaymentInput--CVCCode': paymentField === PAYMENT_FIELD.CVC_CODE,
          'PaymentField-PaymentInput--CVCCode--Auth':
            authStyle && paymentField === PAYMENT_FIELD.CVC_CODE,
          'PaymentField-PaymentInput--ExpiryDate': paymentField === PAYMENT_FIELD.EXPIRY_DATE,
        })}
        id="paymentInput"
        data-testid="credit-card-data-box"
      >
        {renderPaymentField()}
      </div>
      <div
        className={cn('Form-Error', {
          'PaymentField-PaymentInput-Error--Short':
            paymentField === PAYMENT_FIELD.EXPIRY_DATE || paymentField === PAYMENT_FIELD.CVC_CODE,
        })}
      >
        {renderValidationMessage(paymentField, error, touched, filled)}
      </div>
    </div>
  );
};

PaymentField.propTypes = {
  paymentField: PropTypes.string.isRequired,
  setValid: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  authStyle: PropTypes.bool,
};

export default PaymentField;
