import styled from 'styled-components';

import { ReactComponent as ChecklistTemplateBattery } from 'assets/checklist-template-battery.svg';
import { ReactComponent as ChecklistTemplateBuilding } from 'assets/checklist-template-building.svg';
import { ReactComponent as ChecklistTemplateDefault } from 'assets/checklist-template-default.svg';
import { ReactComponent as ChecklistTemplateDesign } from 'assets/checklist-template-design.svg';
import { ReactComponent as ChecklistTemplateFinal } from 'assets/checklist-template-final.svg';
import { ReactComponent as ChecklistTemplateGroundMount } from 'assets/checklist-template-ground-mount.svg';
import { ReactComponent as ChecklistTemplateHome } from 'assets/checklist-template-home.svg';
import { ReactComponent as ChecklistTemplateMaintenance } from 'assets/checklist-template-maintenance.svg';

export const StyledChecklistTemplateBatteryIcon = styled(ChecklistTemplateBattery)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateBuildingIcon = styled(ChecklistTemplateBuilding)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateDefaultIcon = styled(ChecklistTemplateDefault)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateDesignIcon = styled(ChecklistTemplateDesign)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateFinalIcon = styled(ChecklistTemplateFinal)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateGroundMountIcon = styled(ChecklistTemplateGroundMount)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateHomeIcon = styled(ChecklistTemplateHome)`
  height: 4rem;
  width: 4rem;
`;

export const StyledChecklistTemplateMaintenanceIcon = styled(ChecklistTemplateMaintenance)`
  height: 4rem;
  width: 4rem;
`;

export const TemplateIcon = ({ templateTitle }: { templateTitle: string }) => {
  switch (templateTitle) {
    case 'Residential Site Assessment Checklist':
      return <StyledChecklistTemplateHomeIcon />;
    case 'Commercial Site Assessment Checklist':
      return <StyledChecklistTemplateBuildingIcon />;
    case 'Battery Backup and EV Charger Checklist':
      return <StyledChecklistTemplateBatteryIcon />;
    case 'Ground Mount Checklist':
      return <StyledChecklistTemplateGroundMountIcon />;
    case 'Designer Checklist':
      return <StyledChecklistTemplateDesignIcon />;
    case 'Install Checklist':
      return <StyledChecklistTemplateFinalIcon />;
    case 'Maintenance Checklist':
      return <StyledChecklistTemplateMaintenanceIcon />;
    default:
      return <StyledChecklistTemplateDefaultIcon />;
  }
};
