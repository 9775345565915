import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'antd';

import { integrationDisconnect } from 'api/integrations/integrationService';

import { MODAL_PROPS } from 'helpers/constants/modals';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import { IntegrationsDisconnectConfirmationProps } from '../types';
import './IntegrationCard.scss';

const IntegrationsDisconnectConfirmation = ({
  isDisconnectConfirmationVisible,
  handleDisconnectConfirmationClose,
  toggleDisconnectConfirmation,
  setIsConnected,
  isConnected,
  integration,
}: IntegrationsDisconnectConfirmationProps) => {
  const { t } = useTranslation();
  const handleDisconnect = async () => {
    await integrationDisconnect(integration.api);
    setIsConnected(!isConnected);
    toggleDisconnectConfirmation();
  };

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isDisconnectConfirmationVisible}
      onCancel={handleDisconnectConfirmationClose}
      {...MODAL_PROPS}
      className="IntegrationDisconnectConfirmation"
      data-testid="integration-disconnect-confirmation"
    >
      <XIcon
        onClick={toggleDisconnectConfirmation}
        className="close-modal-icon"
        data-testid="close-modal-icon"
      />
      <div className="title">
        {t('Integrations.disconnect')} {integration.name}?
      </div>
      <Button className="Button--White" onClick={handleDisconnectConfirmationClose}>
        {t('Integrations.cancel')}
      </Button>
      <Button
        className="Button--Blue"
        onClick={handleDisconnect}
        data-testid="integration-disconnect-button"
      >
        {t('Integrations.disconnect')}
      </Button>
    </Modal>
  );
};

export default IntegrationsDisconnectConfirmation;
