import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/icons/triangle-exclamation-solid.svg';

function ModalHeaderTitle() {
  const { t } = useTranslation();

  return (
    <>
      <WarningIcon />
      {t('DistantPhotoReview.locationAlert')}
    </>
  );
}

export default ModalHeaderTitle;
