import { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Row } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import { isEqual } from 'lodash-es';

import { currentUserUpdated, userRolesRequested } from 'state/slices/usersSlice';

import { CustomSelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { mapUserRolesToSelectOptions } from 'helpers/utils/formMappers';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import AccountWrapper from '../AccountWrapper';
import './AccountProfile.scss';
import AccountProfileAvatar from './AccountProfileAvatar';
import { DEFAULT_COUNTRY, FORM_CONTROLS, PHONE_NUMBER_INITIAL_VALUE } from './constants';
import { validationSchema } from './validationSchema';

const initialValues = {
  [FORM_CONTROLS.FIRST_NAME]: '',
  [FORM_CONTROLS.LAST_NAME]: '',
  [FORM_CONTROLS.PHONE_NUMBER]: PHONE_NUMBER_INITIAL_VALUE,
  [FORM_CONTROLS.ROLE]: '',
};

const AccountProfile = () => {
  const { currentUser, userRoles, isLoading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [informationSaved, setInformationSaved] = useState(false);
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const {
    touched,
    isValid,
    dirty,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(currentUserUpdated(values));
      setIsFormSaved(true);
    },
  });

  const formatUserProfile = useCallback(() => {
    return {
      [FORM_CONTROLS.FIRST_NAME]: currentUser && currentUser.firstName,
      [FORM_CONTROLS.LAST_NAME]: currentUser && currentUser.lastName,
      [FORM_CONTROLS.PHONE_NUMBER]: currentUser && currentUser.phone,
      [FORM_CONTROLS.ROLE]: currentUser && currentUser.position,
    };
  }, [currentUser]);

  const [handleRoleBlur, getRoleSelectError] = useHandleSelectBlur(handleBlur, FORM_CONTROLS.ROLE);

  useEffect(() => {
    if (userRoles.length === 0) {
      dispatch(userRolesRequested());
    }
  }, [dispatch, userRoles, setFieldValue]);

  useEffect(() => {
    if (currentUser) {
      setFieldValue(FORM_CONTROLS.FIRST_NAME, currentUser.firstName);
      setFieldValue(FORM_CONTROLS.LAST_NAME, currentUser.lastName);
      setFieldValue(FORM_CONTROLS.ROLE, currentUser.position);
      setFieldValue(
        FORM_CONTROLS.PHONE_NUMBER,
        currentUser.phone?.startsWith('+') && DEFAULT_COUNTRY === 'us'
          ? currentUser.phone
          : '1' + currentUser.phone
      );

      setIsPhoneNumberValid(true);
    }
  }, [currentUser, setFieldValue]);

  useEffect(() => {
    setInformationSaved(isEqual(values, formatUserProfile()) && isFormSaved);
  }, [values, isFormSaved, formatUserProfile]);

  const isFormValid = isValid && dirty && isPhoneNumberValid;
  const isFormDirty = !isEqual(values, formatUserProfile());

  const handlePhoneNumberChange = (_value, data, _event, formattedValue) => {
    const isPhoneNumberEmpty = data.dialCode === formattedValue;
    setIsPhoneNumberValid(!isPhoneNumberEmpty);
    setFieldValue(FORM_CONTROLS.PHONE_NUMBER, formattedValue);
  };

  const handlePhoneNumberBlur = (event, data) => {
    const { value } = event.target;
    const valueWithoutPlusSign = value.substr(1);
    const isPhoneNumberEmpty = data.dialCode === valueWithoutPlusSign;
    setIsPhoneNumberValid(!isPhoneNumberEmpty);

    if (!isPhoneNumberTouched) {
      setIsPhoneNumberTouched(true);
    }

    handleBlur(FORM_CONTROLS.PHONE_NUMBER, value);
  };

  return (
    <AccountWrapper>
      <AccountProfileAvatar />
      <form onSubmit={handleSubmit}>
        <h2 className="Title">Profile Information</h2>
        <Row className="AccountProfile-Form-Row">
          <div className="FormControl-Wrapper">
            <label htmlFor="accountProfileFirstName">First Name</label>
            <Input
              placeholder="First Name"
              className={validateStatus(touched, errors, FORM_CONTROLS.FIRST_NAME)}
              id="accountProfileFirstName"
              {...getFieldProps(FORM_CONTROLS.FIRST_NAME)}
            />
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.FIRST_NAME)}
            </div>
          </div>

          <div className="FormControl-Wrapper">
            <label htmlFor="accountProfileLastName">Last Name</label>
            <Input
              placeholder="Last Name"
              className={validateStatus(touched, errors, FORM_CONTROLS.LAST_NAME)}
              id="accountProfileLastName"
              {...getFieldProps(FORM_CONTROLS.LAST_NAME)}
            />
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.LAST_NAME)}
            </div>
          </div>
        </Row>
        <Row className="AccountProfile-Form-Row">
          <div className="FormControl-Wrapper">
            <label htmlFor="accountProfileRole">Role</label>
            <CustomSelect
              {...getFieldProps(FORM_CONTROLS.ROLE)}
              placeholder="Select role"
              isSearchable={false}
              onChange={(option) => {
                setFieldValue(FORM_CONTROLS.ROLE, option.value);
              }}
              options={mapUserRolesToSelectOptions(userRoles)}
              onBlur={handleRoleBlur}
              error={getRoleSelectError(values)}
              variant="form"
              id="accountProfileRole"
            />
            <div className="Form-Error">{getRoleSelectError(values) && 'Role is required'}</div>
          </div>

          <div className="FormControl-Wrapper">
            <label htmlFor="accountProfilePhone">Phone Number</label>
            <PhoneInput
              inputClass={!isPhoneNumberValid && isPhoneNumberTouched && 'Error'}
              buttonClass={!isPhoneNumberValid && isPhoneNumberTouched && 'Error'}
              countryCodeEditable={false}
              {...getFieldProps(FORM_CONTROLS.PHONE_NUMBER)}
              onChange={handlePhoneNumberChange}
              onBlur={handlePhoneNumberBlur}
              country={DEFAULT_COUNTRY}
              id="accountProfilePhone"
            />
            <div className="Form-Error">
              {!isPhoneNumberValid && isPhoneNumberTouched && 'Phone number is required'}
            </div>
          </div>
        </Row>

        <div className="AccountProfile-Button-Wrapper">
          <TickIcon
            className={cn({
              'AccountProfile-Button-Icon--Hidden': !informationSaved,
            })}
          />
          <Button
            onClick={handleSubmit}
            disabled={!isFormValid || !isFormDirty}
            aria-disabled={!isFormValid || !isFormDirty}
            className="Button--Blue AccountProfile-Button"
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </AccountWrapper>
  );
};

export default AccountProfile;
