import { ExifData } from './types';

export const getPitch = (exifData: ExifData | undefined) => {
  return (
    // Autel
    exifData?.Pitch ??
    // DJI
    exifData?.FlightPitchDegree ??
    // Skydio
    exifData?.CameraOrientationNED?.Pitch
  );
};

export const getRoll = (exifData: ExifData | undefined) => {
  return (
    // Autel
    exifData?.Roll ??
    // DJI
    exifData?.FlightRollDegree ??
    // Skydio
    exifData?.CameraOrientationNED?.Roll
  );
};

export const getYaw = (exifData: ExifData | undefined) => {
  return (
    // Autel
    exifData?.Yaw ??
    // DJI
    exifData?.FlightYawDegree ??
    // Skydio
    exifData?.CameraOrientationNED?.Yaw
  );
};
