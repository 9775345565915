import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadAppIcon from 'assets/download-app-store.svg';
import QRLogo from 'assets/qr-logo.svg';

import QRCodeGenerator from './QRCodeGenerator';
import './QRCodeWrapper.scss';

const QRCodeWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();
  const altText = t('QRCode.altText');

  return (
    <section className="QRCode-MainWrapper">
      <div className="QRCode-QRAndDownloadAppIcon">
        {children}
        <div className="QRCode-Wrapper">
          <div className="QRCode-Title">{t('QRCode.title')}</div>
          <div className="QRCode-DownloadAppWrapper">
            <img src={DownloadAppIcon} alt={altText} className="QRCode-DownloadAppIcon" />
          </div>
        </div>
      </div>
      <div className="QRCode-Note">{t('QRCode.expandedViewNote')}</div>
    </section>
  );
};

const ExpandedView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <QRCodeWrapper>
      <a href={t('QRCode.iosAppURL') ?? ''} target="_blank" rel="noopener noreferrer">
        <QRCodeGenerator
          url={t('QRCode.iosAppURL')}
          logo={QRLogo}
          bgColor="#EFEFEF"
          logoWidth={30}
          qrCodeSize={100}
        />
      </a>
    </QRCodeWrapper>
  );
};

export default ExpandedView;
