import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Modal, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import { DESIGN_SERVICES_QUEUE_COMPANY_CSV_COLUMN_TITLES } from 'screens/DesignServices/constants';
import Revision from 'types/Revision';
import ServiceRequest from 'types/ServiceRequest';

import { companyProjectsRequested } from 'state/slices/designServices/designServicesQueueSlice';
import { resetProject } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { Button, CSVExportButton, GoBackButton } from 'components';
import ViewFormModal from '../components/ViewFormModal/ViewFormModal';

import { ACCESS } from 'helpers/constants';
import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterByForDesignServices } from 'screens/DesignServices/helpers/getFilterBy';
import { handleDesignServiceQueueCSVExport } from 'screens/DesignServices/helpers/handleDesignServiceQueueCSVExport';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import colors from 'helpers/constants/colors';
import useToggle from 'helpers/hooks/useToggle';
import '../DesignServices.scss';
import { Extra, Filter, Pagination, Sorter, TableParams } from '../TableParams';
import LayeredStatisticalContainer from './LayeredStatisticalContainer/LayeredStatisticalContainer';
import StatisticalContainer from './StatisticalContainer/StatisticalContainer';
import TableData from './TableData';
import ViewRevisionsModal from './ViewRevisionsModal/ViewRevisionsModal';

const DesignServicesCompanyQueue = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [filtersVisible, toggleFiltersVisible] = useToggle(false);
  const [searchText, setSearchText] = useState('');
  const [displayedData, setDisplayedData] = useState<Array<ServiceRequest>>([]);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [revisionModalVisible, setRevisionModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [modelRevisionData, setModelRevisionData] = useState<Revision | Revision[]>([]);
  const [projectName, setProjectName] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
    },
  });
  const designServicesPrelimAccess = useFeatureToggle(ACCESS.DESIGN_SERVICES_PRELIM);

  const { company } = useSelector((state: RootState) => state.company);
  const {
    designServiceQueueCompanyProjectList,
    averageTurnaroundTime,
    countsByStatus,
    isLoading,
    projectCount,
  } = useSelector((state: RootState) => state.designServicesQueue);

  const handleFormModalOpen = () => setFormModalVisible(true);
  const handleFormModalClose = () => setFormModalVisible(false);

  const handleRevisionModalOpen = () => setRevisionModalVisible(true);
  const handleRevisionModalClose = () => setRevisionModalVisible(false);

  useEffect(() => {
    setDisplayedData(designServiceQueueCompanyProjectList);
  }, [designServiceQueueCompanyProjectList]);

  useEffect(() => {
    dispatch(resetProject());
  }, [dispatch]);

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    if (company) {
      dispatch(
        companyProjectsRequested({
          size: PROJECT_LIST_SIZE,
          pageIndex: pagination?.current ?? 1,
          sortBy: getSortBy(columnKey, order),
          filterBy: getFilterByForDesignServices(filters, searchText),
          companyId: company.id,
        })
      );
    }
  }, [company, dispatch, searchText, tableParams]);

  const handleTableChange = (
    pagination: Pagination,
    filters: Filter | Record<string, FilterValue | null>,
    sorter: Sorter | Sorter[],
    extra: Extra
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  const handleViewRevisionsClick = (
    project: { id: string; name: string },
    modelRevision: Revision | Revision[]
  ) => {
    setProjectName(project.name);
    setModelRevisionData(modelRevision);
    handleRevisionModalOpen();
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        visible={formModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ViewFormModal
          handleClose={handleFormModalClose}
          formData={formData}
          projectName={projectName}
        />
      </Modal>
      {/* @ts-ignore */}
      <Modal
        visible={revisionModalVisible}
        onCancel={handleRevisionModalClose}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ViewRevisionsModal revisionData={modelRevisionData} projectName={projectName} />
      </Modal>
      <div className="DesignServices-Wrapper">
        <div className="DesignServices-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            role="searchbox"
            prefix={<SearchIcon />}
            aria-label="search projects"
            onChange={debounce((e) => {
              setSearchText(e.target.value);
            }, 400)}
            className="ScaniflyAdmin-Search"
          />
          <p data-testid="project-count">{projectCount ?? 0} projects</p>
          <CSVExportButton
            tableName={TABLE_NAMES.COMPANY_DESIGN_SERVICES_QUEUE}
            columnTitles={DESIGN_SERVICES_QUEUE_COMPANY_CSV_COLUMN_TITLES}
            prepareCSVData={handleDesignServiceQueueCSVExport}
            companyId={company?.id}
          />
          <Button
            color={filtersVisible ? undefined : colors.mainBlue}
            onClick={() => toggleFiltersVisible()}
          >
            {t('DesignService.overview')}
          </Button>
        </div>
        {filtersVisible ? (
          <>
            <LayeredStatisticalContainer
              title={t('DesignService.designStatus')}
              data={countsByStatus}
            />
            <StatisticalContainer
              title={t('DesignService.averageTurnaroundTime')}
              value={averageTurnaroundTime}
              isTime={true}
            />
          </>
        ) : null}
        <Table
          className="DesignServices-Table"
          columns={TableData({
            handleFormModalOpen,
            handleViewRevisionsClick,
            setFormData,
            setProjectName,
            designServicesPrelimAccess,
          })}
          dataSource={displayedData}
          rowKey="id"
          scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
          pagination={{
            total: projectCount,
            pageSize: PROJECT_LIST_SIZE,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          onChange={(pagination, filters, sorter, extra) =>
            handleTableChange(pagination, filters, sorter, extra)
          }
          loading={{
            indicator: (
              <div className="DesignServices-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isLoading,
          }}
        />
      </div>
    </>
  );
};

export default DesignServicesCompanyQueue;
