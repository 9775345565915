import styled from 'styled-components';

const TextWrapper = styled.div`
  display: flex;
  font-weight: bold;
  margin: 1rem 0;
`;

type Props = {
  text: string;
};

export const Label = ({ text }: Props) => {
  return <TextWrapper>{text}</TextWrapper>;
};
