import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import {
  DESIGN_OPTIONS,
  DESIGN_OPTIONS_FOR_LARGE_PROJECTS,
  LARGE_UPGRADE_OPTIONS,
  SITEPLAN_UPGRADE_DESIGN_OPTIONS,
  TRUE_UP_UPGRADE_DESIGN_OPTIONS,
  WIREFRAME_UPGRADE_DESIGN_OPTIONS,
} from 'screens/DesignServices/constants';
import { Project } from 'types';

export const getDesignOptions = ({
  project,
  upgrade,
  hasSitePlanRequests,
  hasTrueUpRequests,
}: {
  project: Project | null;
  upgrade: boolean | undefined;
  hasSitePlanRequests: number;
  hasTrueUpRequests: number;
}) => {
  if (project?.type === PROJECT_TYPES.LARGE) {
    if (upgrade) {
      return LARGE_UPGRADE_OPTIONS;
    }
    return DESIGN_OPTIONS_FOR_LARGE_PROJECTS;
  }
  if (upgrade) {
    if (hasSitePlanRequests) {
      return SITEPLAN_UPGRADE_DESIGN_OPTIONS;
    }
    if (hasTrueUpRequests) {
      return TRUE_UP_UPGRADE_DESIGN_OPTIONS;
    }
    return WIREFRAME_UPGRADE_DESIGN_OPTIONS;
  }
  return DESIGN_OPTIONS;
};
