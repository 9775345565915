import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Divider } from 'antd';

import {
  companyBillingInfoRequested,
  companySubscriptionInfoRequested,
  userCompanyRequested,
} from 'state/slices/companySlice';
import { AppDispatch, RootState } from 'state/store/store';

import { SectionHeader } from 'components/BillingAndCredits/SectionHeader/SectionHeader';

import useToggle from 'helpers/hooks/useToggle';
import { renderBillingInfoDisplay } from './helpers/renderBillingInfoDisplay';
import { renderCCDisplay } from './helpers/renderCCDisplay';
import { renderSubscriptionInfoDisplay } from './helpers/renderSubscriptionInfoDisplay';

import AccountWrapper from '../AccountWrapper';
import { CreditsDisplay } from './AccountDesignServicesCredits/CreditsDisplay/CreditsDisplay';
import './AccountPayments.scss';

const AccountPayments = () => {
  const { t } = useTranslation();
  const [isCreditCardFormVisible, toggleCreditCardFormVisible] = useToggle(false);
  const [isBillingInformationFormVisible, toggleBillingInformationFormVisible] = useToggle(false);
  const { company, companyBillingInfo, companySubscriptionInfo } = useSelector(
    (state: RootState) => state.company
  );
  const dispatch: AppDispatch = useDispatch();
  const { hash } = useLocation();
  const creditsRef = useRef<HTMLDivElement | null>(null);

  const scrollToCredits = hash === '#credits';

  useEffect(() => {
    if (scrollToCredits) {
      creditsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToCredits]);

  useEffect(() => {
    if (!company) {
      dispatch(userCompanyRequested());
    }
  }, [company, dispatch]);

  useEffect(() => {
    if (company) {
      dispatch(companyBillingInfoRequested(company?.customerId ?? ''));
      dispatch(companySubscriptionInfoRequested(company.id));
    }
  }, [company, dispatch]);

  const handleCCFormVisibility = () => {
    toggleCreditCardFormVisible();
  };

  const handleBillingInfoFormVisibility = () => {
    toggleBillingInformationFormVisible();
  };

  return (
    <AccountWrapper>
      <SectionHeader>{t('BillingTitles.subscription')}</SectionHeader>
      {renderSubscriptionInfoDisplay({ companySubscriptionInfo })}
      <div ref={creditsRef}></div>
      <Divider />
      <CreditsDisplay companySubscriptionInfo={companySubscriptionInfo} />
      <SectionHeader>{t('BillingTitles.payment')}</SectionHeader>
      {renderCCDisplay({
        isCreditCardFormVisible,
        companyBillingInfo,
        toggleCreditCardFormVisible,
        handleCCFormVisibility,
      })}
      <Divider />
      <SectionHeader>{t('BillingTitles.billing')}</SectionHeader>
      {renderBillingInfoDisplay({
        isBillingInformationFormVisible,
        companyBillingInfo,
        toggleBillingInformationFormVisible,
        handleBillingInfoFormVisibility,
      })}
    </AccountWrapper>
  );
};

export default AccountPayments;
