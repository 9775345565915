import { Switch } from 'antd';
import { ModalContext } from 'components';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { openNotification } from 'helpers/utils/openNotification';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionUpdateRequested } from 'state/slices/subscriptionSlice';
import { AppDispatch, RootState } from 'state/store/store';
import styled from 'styled-components';
import { DesignServicesAutomationFields } from 'types/DesignServicesAutomationFields';
import { StyledToggle, SwitchWrapper } from './StyledToggle';
import { UpdateSettingsDisclaimer } from './UpdateSettingsDisclaimer';
import { WrapperWithNewTag } from './WrapperWithNewTag';

const Header = styled.div`
  color: ${colors.labelGray};
  margin: 2.5rem 0 0.75rem 0;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.semiBold};
`;

const Description = styled.div`
  color: ${colors.labelGray};
  margin: 1rem 0;
  font-size: ${fontSizes.extraSmall};
`;

const SwitchWrapperWithDisclaimer = styled(SwitchWrapper)`
  display: flex;
  align-items: center;
  font-style: italic;

  a {
    text-decoration: underline;
  }
`;

export const AutomationToggles = () => {
  const { t } = useTranslation();
  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);
  const dispatch: AppDispatch = useDispatch();
  const [isAutomatedWireframeSmallChecked, setIsAutomatedWireframeSmallChecked] =
    useState<boolean>(false);
  const [isAutomatedWireframeLargeChecked, setIsAutomatedWireframeLargeChecked] =
    useState<boolean>(false);
  const [isAutoTrueUpOptionChecked, setIsAutoTrueUpOptionChecked] = useState<boolean>(false);

  const { displayDesignServiceAutomationConfirmationModal } = useContext(ModalContext);

  useEffect(() => {
    if (activeSubscription) {
      setIsAutomatedWireframeSmallChecked(
        activeSubscription.automatedWireframeSmallOnsite ?? false
      );
      setIsAutomatedWireframeLargeChecked(
        activeSubscription.automatedWireframeLargeOnsite ?? false
      );
      setIsAutoTrueUpOptionChecked(activeSubscription.automatedTrueUp ?? false);
    }
  }, [activeSubscription]);

  const handleAutomationSettingChangeCancel = useCallback(() => {
    setIsAutomatedWireframeSmallChecked(activeSubscription?.automatedWireframeSmallOnsite ?? false);
    setIsAutomatedWireframeLargeChecked(activeSubscription?.automatedWireframeLargeOnsite ?? false);
    setIsAutoTrueUpOptionChecked(activeSubscription?.automatedTrueUp ?? false);
  }, [
    activeSubscription?.automatedTrueUp,
    activeSubscription?.automatedWireframeLargeOnsite,
    activeSubscription?.automatedWireframeSmallOnsite,
  ]);

  const onUpdateSuccess = useCallback(() => {
    openNotification({
      type: 'success',
      title: 'Success!',
      text: t('DesignServicesAutomationToggles.subscriptionSuccessfullyUpdated'),
    });
  }, [t]);

  const onUpdateError = (errorMessage: string) => {
    openNotification({
      type: 'error',
      title: 'Error!',
      text: errorMessage,
    });
  };

  const updateSubscription = useCallback(
    (fieldsToUpdate: DesignServicesAutomationFields) => {
      if (activeSubscription) {
        dispatch(
          subscriptionUpdateRequested({
            subscriptionId: activeSubscription.id,
            fieldToUpdate: fieldsToUpdate,
            onUpdateSuccess,
            onUpdateError,
          })
        );
      } else {
        onUpdateError(t('DesignServicesAutomationToggles.noActiveSubscriptionError'));
        setIsAutomatedWireframeSmallChecked(false);
        setIsAutomatedWireframeLargeChecked(false);
        setIsAutoTrueUpOptionChecked(false);
      }
    },
    [activeSubscription, dispatch, onUpdateSuccess, t]
  );

  const displayConfirmationModal = useCallback(
    (serviceType: string, fieldsToUpdate: DesignServicesAutomationFields) => {
      displayDesignServiceAutomationConfirmationModal({
        actionButtonOnClick: () => updateSubscription(fieldsToUpdate),
        onCancel: handleAutomationSettingChangeCancel,
        actionButtonLabel: 'OK',
        title: `${t('DesignService.hiThere')} 👋`,
        description: t('DesignServicesAutomationToggles.confirmationModalText', {
          serviceType,
        }),
      });
    },
    [
      displayDesignServiceAutomationConfirmationModal,
      handleAutomationSettingChangeCancel,
      t,
      updateSubscription,
    ]
  );

  const handleSmallWireframeChange = (val: boolean) => {
    setIsAutomatedWireframeSmallChecked(val);
    if (val) {
      displayConfirmationModal(t('DesignServiceSpecifications.wireframe'), {
        automatedWireframeSmallOnsite: true,
        automatedTrueUp: false,
      });
      setIsAutoTrueUpOptionChecked(false);
    } else {
      updateSubscription({ automatedWireframeSmallOnsite: false });
    }
  };

  const handleLargeWireframeChange = (val: boolean) => {
    setIsAutomatedWireframeLargeChecked(val);
    if (val) {
      displayConfirmationModal(t('DesignServiceSpecifications.wireframe'), {
        automatedWireframeLargeOnsite: true,
        automatedTrueUp: false,
      });
      setIsAutoTrueUpOptionChecked(false);
    } else {
      updateSubscription({ automatedWireframeLargeOnsite: false });
    }
  };

  const handleTrueUpChange = (val: boolean) => {
    setIsAutoTrueUpOptionChecked(val);
    if (val) {
      displayConfirmationModal(t('DesignServiceSpecifications.trueUp'), {
        automatedTrueUp: true,
        automatedWireframeSmallOnsite: false,
        automatedWireframeLargeOnsite: false,
      });
      setIsAutomatedWireframeLargeChecked(false);
      setIsAutomatedWireframeSmallChecked(false);
    } else {
      updateSubscription({ automatedTrueUp: false });
    }
  };

  return (
    <WrapperWithNewTag>
      {/*
        Auto Wireframe options
      */}
      <Header>{t('DesignServicesAutomationToggles.autoWireframe')}</Header>
      <Description>{t('DesignServicesAutomationToggles.autoWireframeDescription')}</Description>
      <StyledToggle
        checked={isAutomatedWireframeSmallChecked}
        text={t('DesignServicesAutomationToggles.smallOnSiteOption')}
        onChange={handleSmallWireframeChange}
      />
      <StyledToggle
        checked={isAutomatedWireframeLargeChecked}
        text={t('DesignServicesAutomationToggles.largeOnSiteOption')}
        onChange={handleLargeWireframeChange}
      />

      {/*
        Auto True-Up option
      */}
      <Header>{t('DesignServicesAutomationToggles.autoTrueUp')}</Header>
      <Description>{t('DesignServicesAutomationToggles.autoTrueUpDescription')}</Description>
      <SwitchWrapperWithDisclaimer>
        <Switch
          checked={isAutoTrueUpOptionChecked}
          onChange={handleTrueUpChange}
          aria-label={t('DesignServicesAutomationToggles.autoTrueUpDescription')}
          data-testid="true-up-toggle"
        />
        {activeSubscription?.automatedTrueUp || isAutoTrueUpOptionChecked ? (
          <UpdateSettingsDisclaimer t={t} />
        ) : null}
      </SwitchWrapperWithDisclaimer>
    </WrapperWithNewTag>
  );
};
