import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = Yup.object().shape({
  [FORM_CONTROLS.NAME]: Yup.string()
    .required('Billing contact name is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.EMAIL]: Yup.string()
    .required('Billing contact email is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.COUNTRY]: Yup.string().nullable().required('Country is required'),
  [FORM_CONTROLS.STREET]: Yup.string()
    .required('Street is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.CITY]: Yup.string()
    .required('City is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.ZIP_CODE]: Yup.string()
    .required('ZIP Code is required')
    .test(...noWhitespaces),
});
