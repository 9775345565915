import React, { ChangeEvent, useCallback } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import { useResizableTextarea } from './useResizeableTextArea';

interface EditTextProps {
  id: string;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  expandable?: boolean;
  placeholder?: string;
  parentId?: string;
}

const EditTextContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditTextLabel = styled.span`
  margin-right: 1rem;
  font-weight: bold;
  flex: 1;
`;

type TextBoxProps = {
  expandable?: boolean;
};
const TextBox = styled.textarea<TextBoxProps>`
  width: 100%;
  flex: 5;
  padding: 10px;
  border: 1px solid ${colors.mainBlue};
  border-radius: 0.5rem;
  resize: ${({ expandable }) => (expandable ? 'vertical' : 'none')};
  height: ${({ expandable }) => (expandable ? 'auto' : '40px')};
  min-width: 460px;
`;

export const EditFieldText: React.FC<EditTextProps> = ({
  id,
  label,
  onChange,
  value = '',
  expandable = false,
  placeholder,
  parentId,
}: EditTextProps) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (expandable) return;
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    [expandable]
  );

  const textAreaRef = useResizableTextarea({ parentId, expandable });

  return (
    <EditTextContainer>
      {label && <EditTextLabel>{label}</EditTextLabel>}
      <TextBox
        ref={textAreaRef}
        id={id}
        name={id}
        onChange={onChange}
        expandable={expandable}
        value={value}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
    </EditTextContainer>
  );
};
