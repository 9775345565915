import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';

const FeatureFlag = ({ name, enabled, onChange }) => (
  <li className="FeatureFlags-List-Item">
    <label className="FeatureFlags-List-Item-label" htmlFor={`FeatureFlag-${name}`}>
      <div className="FeatureFlags-List-Item-toggler-wrapper">
        <input
          className="FeatureFlags-List-Item-Checkbox"
          id={`FeatureFlag-${name}`}
          type="checkbox"
          checked={enabled}
          onChange={() => onChange(name, !enabled)}
        />
        <div className="FeatureFlags-List-Item-toggler-slider">
          <div className="FeatureFlags-List-Item-toggler-knob"></div>
        </div>
      </div>
      {startCase(name)}
    </label>
  </li>
);

FeatureFlag.propTypes = {
  name: PropTypes.string,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FeatureFlag;
