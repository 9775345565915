import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DATE_OPTIONS, FILTER } from 'screens/Projects/ProjectsList/constants';

import { CustomSelect } from 'components';

import { filterDateFormat } from 'helpers/utils/dateFormat.js';

import './DateFilter.scss';

const DateFilter = ({ filterOptions, handleOptionsChange, currentValue }) => {
  const handlePickerChange = (value, type) => {
    const valueToSet = value ? value.startOf('day') : null;
    handleOptionsChange({ ...currentValue, [type]: valueToSet });
  };

  return (
    <div className="DateFilter">
      <div className="DateFilter-Selector-Label">
        <label htmlFor="dateTypeFilter">Filter By</label>
        <CustomSelect
          placeholder="Select date"
          options={DATE_OPTIONS}
          onChange={(option) => {
            handleOptionsChange({ ...currentValue, type: option.value });
          }}
          value={currentValue && currentValue.type}
          variant="form"
          isSearchable={false}
          id="dateTypeFilter"
        />
      </div>
      <div className="DateFilter-Selector-Label">
        <label htmlFor="dateRangeFilter">Filter Range</label>
        <CustomSelect
          placeholder="Select range"
          options={filterOptions}
          onChange={(option) => {
            handleOptionsChange({ ...currentValue, range: option.value });
          }}
          value={(currentValue && currentValue.range) || FILTER.BETWEEN}
          variant="form"
          isSearchable={false}
          id="dateRangeFilter"
        />
      </div>
      <div className="DateFilter-Divider" />
      <div className="DateFilter-Selector">
        {currentValue && currentValue.range === FILTER.ON ? (
          <DatePicker
            format={filterDateFormat}
            className="DateFilter-Picker"
            dropdownClassName="DateFilter-Dropdown ignore-onclickoutside"
            onChange={(value) => handlePickerChange(value, FILTER.ON)}
            value={currentValue && currentValue[FILTER.ON]}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        ) : (
          <div className="DateFilter-Content">
            <DatePicker
              format={filterDateFormat}
              className="DateFilter-Picker"
              dropdownClassName="DateFilter-Dropdown ignore-onclickoutside"
              onChange={(value) => handlePickerChange(value, FILTER.FROM)}
              value={currentValue && currentValue[FILTER.FROM]}
              disabledDate={(current) =>
                currentValue &&
                currentValue[FILTER.TO] &&
                moment(currentValue[FILTER.TO]).isSameOrBefore(current, 'day')
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            />
            <span> – </span>
            <DatePicker
              format={filterDateFormat}
              className="DateFilter-Picker"
              dropdownClassName="DateFilter-Dropdown ignore-onclickoutside"
              onChange={(value) => handlePickerChange(value, FILTER.TO)}
              value={currentValue && currentValue[FILTER.TO]}
              disabledDate={(current) =>
                currentValue &&
                currentValue[FILTER.FROM] &&
                moment(currentValue[FILTER.FROM]).isSameOrAfter(current, 'day')
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  handleOptionsChange: PropTypes.func,
  currentValue: PropTypes.object,
};

export default DateFilter;
