import { UppyFile } from '@uppy/core';
import { MediaItem } from 'types';

export const isVideo = (file: { mimeType?: string; type?: string }) => {
  const mediaItem = file as MediaItem;
  const uppyFile = file as UppyFile;
  if (uppyFile?.type) {
    return uppyFile.type.includes('video');
  }
  if (mediaItem?.mimeType) {
    return mediaItem.mimeType.includes('video');
  }
  return false;
};

export const isImage = (file: MediaItem | UppyFile) => {
  const mediaItem = file as MediaItem;
  const uppyFile = file as UppyFile;
  if (mediaItem?.mimeType) {
    return mediaItem?.mimeType.includes('image');
  }
  if (uppyFile?.type) {
    return uppyFile?.type.includes('image');
  }
  return false;
};

export const isVideoOrImage = (file: MediaItem & { type?: string }) => {
  const fileType = file?.mimeType || file?.type;
  if (fileType) {
    return fileType.includes('image') || fileType.includes('video');
  }
  return false;
};

export const isSupported = (mimeType: string) => {
  // Temporary
  if (mimeType) {
    return (
      mimeType?.includes('mp4') ||
      (mimeType?.includes('quicktime') && navigator.vendor.includes('Apple'))
    );
  }
  return false;
};

const validators = {
  isImage,
  isVideoOrImage,
  isVideo,
  isSupported,
};

export default validators;
