import { MediaCategory, MediaCategoryType } from 'types';

import API from '../config';
import {
  createMediaCategoryUrl,
  deleteMediaCategoryUrl,
  getAllMediaCategoriesUrl,
  getMediaCategoriesByTypeUrl,
  getMediaCategoryByIdUrl,
  updateMediaCategoryUrl,
} from './mediaCategoryUrls';

export const deleteMediaCategory = (id: string) => API.delete(deleteMediaCategoryUrl(id));

export const updateMediaCategory = (
  id: string,
  { categoryName }: Pick<MediaCategory, 'categoryName'>
) => API.put(updateMediaCategoryUrl(id), { categoryName });

export const fetchMediaCategoriesByType = (type: MediaCategoryType) =>
  API.get(getMediaCategoriesByTypeUrl(type));

export const fetchAllMediaCategories = () => API.get(getAllMediaCategoriesUrl());

export const fetchMediaCategory = (id: string) => API.get(getMediaCategoryByIdUrl(id));

export const createMediaCategory = (mediaCategory: Pick<MediaCategory, 'categoryName'>) =>
  API.post(createMediaCategoryUrl(), mediaCategory);
