import { Marker } from 'react-map-gl';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as PinIcon } from 'assets/icons/map-pin.svg';

import './MapPin.scss';

const MapPin = ({
  longitude,
  latitude,
  isChecked,
  id,
  isClickable,
  isSatelliteView,
  handleClick = () => {},
}) => {
  return (
    <Marker longitude={longitude} latitude={latitude}>
      {isClickable && (
        <PinIcon
          className={cn('MapPin', {
            'MapPin--Checked': isChecked,
            'MapPin--SatelliteView': isSatelliteView,
          })}
          onClick={() => handleClick(id)}
        />
      )}
      {!isClickable && (
        <PinIcon
          className={cn('MapPin', {
            'MapPin--SatelliteView': isSatelliteView,
            'MapPin--NonClickable': true,
          })}
        />
      )}
    </Marker>
  );
};

MapPin.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isClickable: PropTypes.bool,
  handleClick: PropTypes.func,
  isChecked: PropTypes.bool,
  isSatelliteView: PropTypes.bool,
};

export default MapPin;
