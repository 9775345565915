import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { startCase } from 'lodash';
import moment from 'moment';

import { albumDateFormat } from 'helpers/utils/dateFormat';
import { i18n } from 'helpers/utils/translations';

export const getCategoryName = ({
  isPrelim = false,
  isRevision = false,
  tierLevel,
}: {
  isPrelim?: boolean;
  isRevision?: boolean;
  tierLevel: TIER_LEVEL;
}) => {
  if (isRevision) {
    return i18n.t('DesignService.revisionRequestCategory', {
      formattedDate: moment().format(albumDateFormat).replaceAll('-', '_'),
    });
  }
  if (isPrelim) {
    return i18n.t('DesignService.newBuildAlbumName');
  }
  return i18n.t('DesignService.dsFormFiles', { tierLevel: `${startCase(tierLevel)}` });
};
