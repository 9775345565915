import { useTranslation } from 'react-i18next';

import { Col, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormikProps } from 'formik';
import { Project, ProjectDesignData } from 'types';

import { CustomSelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import fontSizes from 'helpers/constants/fontSizes';
import { Dispatch, SetStateAction } from 'react';
import { getAnnualUsage } from 'screens/Projects/CustomerInfo/CustomerInfoUsage/helpers';
import styled from 'styled-components';
import { FORM_CONTROLS, REQUIRED_ERROR } from '../constants';
import { DesignInformation } from '../MaxFillForms/DesignInformation';
import SetbackInputForms from './SetbackInputForms';
import SizedForTolerance from './SizedForTolerance';
import SizedForTrueUp from './SizedForTrueUp';
import UploadForms from './UploadForms';

const Wrapper = styled.div`
  width: 30rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledData = styled.div`
  font-size: ${fontSizes.extraSmall};
  font-style: italic;
  margin-top: 1rem;
`;

const TrueUpDesignForm = ({
  project,
  getFieldProps,
  setFieldValue,
  handleBlur,
  useExistingDesignForTrueUp,
  handleUseExistingDesignForTrueUp,
  values,
  projectDesigns,
  touched,
  errors,
  tierLevel,
  filesAlreadyStored,
  handleFilesInAlbum,
  uploadComplete,
  setUploadComplete,
  ...formikFormControls
}: {
  project: Project | null;
  useExistingDesignForTrueUp: boolean;
  handleUseExistingDesignForTrueUp: (e: RadioChangeEvent) => void;
  projectDesigns: ProjectDesignData[];
  filesAlreadyStored: any;
  handleFilesInAlbum: (e: any) => void;
  uploadComplete: boolean;
  setUploadComplete: Dispatch<SetStateAction<boolean>>;
  tierLevel: TIER_LEVEL;
} & FormikProps<Record<string, string>>) => {
  const { Group } = Radio;
  const { t } = useTranslation();

  const [handleSelectedDesignBlur, getSelectedDesignError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.SELECTED_DESIGN
  );

  const getDesignSystemValues = (value: string) => {
    if (value.length) {
      if (value.includes(t('DesignService.uploadDesign'))) {
        return '';
      }
      const valueArr = value.split(', ').slice(2);
      return valueArr.join(' | ');
    }
    return '';
  };

  return (
    <Col>
      <Row>
        {/* Use existing design radio buttons */}
        <Col span={24} className="PreliminaryDesignForm-RadioButtons ant-col left-column">
          <div>{t('DesignService.planSet.preliminaryDesignForm.useExistingDesign')}</div>
          <Group
            onChange={handleUseExistingDesignForTrueUp}
            value={useExistingDesignForTrueUp}
            className="radio-button-wrapper"
          >
            <Radio value={true}>
              {t('DesignService.planSet.preliminaryDesignForm.options.yes')}
            </Radio>
            <Radio value={false}>
              {t('DesignService.planSet.preliminaryDesignForm.options.no')}
            </Radio>
          </Group>
        </Col>

        {/* Select design custom select */}
        <Col span={24} className="PreliminaryDesignForm-CustomSelect ant-col right-column">
          <label htmlFor={FORM_CONTROLS.SELECTED_DESIGN}>
            <h3 className="title">
              {t('DesignService.planSet.preliminaryDesignForm.selectDesign')}
            </h3>
          </label>
          <div className="input">
            <CustomSelect
              {...getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)}
              id={FORM_CONTROLS.SELECTED_DESIGN}
              placeholder={t('DesignService.planSet.preliminaryDesignForm.selectDesignPlaceholder')}
              options={
                useExistingDesignForTrueUp
                  ? [
                      ...projectDesigns,
                      {
                        id: '',
                        name: t('DesignService.uploadDesign'),
                        annualProduction: 0,
                        annualConsumption: 0,
                        systemSize: 0,
                      },
                    ].map((design) => {
                      const annualConsumptionValue = project
                        ? getAnnualUsage(
                            project.kwh.some((usage: number) => usage > 0)
                              ? project.kwh
                              : project.monthlyUsage
                          )
                        : 0;

                      return {
                        value: `Design Name: ${design.name}, Design ID: ${design.id}, Annual Production: ${Math.round(design?.annualProduction ?? 0).toLocaleString()} kWh, System Size: ${(design?.systemSize ?? 0).toFixed(3)} kW, Annual Consumption: ${Math.round(annualConsumptionValue).toLocaleString()} kWh`,
                        label: design.name,
                      };
                    }) ?? []
                  : []
              }
              onChange={(option: { value: string }) => {
                setFieldValue(FORM_CONTROLS.SELECTED_DESIGN, option.value);
              }}
              onBlur={handleSelectedDesignBlur}
              error={(useExistingDesignForTrueUp ? getSelectedDesignError(values) : false) ?? false}
              variant="filter"
              aria-label={t('DesignService.planSet.preliminaryDesignForm.selectDesign')}
              tall
              disabled={!useExistingDesignForTrueUp}
            />
            <div className="Form-Error">
              {/* @ts-ignore */}
              {useExistingDesignForTrueUp && getSelectedDesignError(values) ? REQUIRED_ERROR : ''}
            </div>
            <StyledData>
              {getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)?.value
                ? getDesignSystemValues(getFieldProps(FORM_CONTROLS.SELECTED_DESIGN).value)
                : 'No design selected.'}
            </StyledData>
          </div>
        </Col>

        {useExistingDesignForTrueUp ? (
          <SizedForTolerance
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            t={t}
          />
        ) : (
          <FormWrapper>
            <Wrapper>
              <SizedForTrueUp
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                t={t}
                {...formikFormControls}
              />
            </Wrapper>
            <SetbackInputForms
              setFieldValue={setFieldValue}
              getFieldProps={getFieldProps}
              touched={touched}
              errors={errors}
              {...formikFormControls}
            />
            <DesignInformation
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              values={values}
              handleBlur={handleBlur}
              {...formikFormControls}
            />
          </FormWrapper>
        )}

        {getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)
          ?.value?.toLowerCase()
          .includes('upload design') ? (
          <UploadForms
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            tierLevel={tierLevel}
            filesAlreadyStored={filesAlreadyStored}
            handleFilesInAlbum={handleFilesInAlbum}
            uploadComplete={uploadComplete}
            setUploadComplete={setUploadComplete}
            {...formikFormControls}
          />
        ) : null}
      </Row>
    </Col>
  );
};

export default TrueUpDesignForm;
