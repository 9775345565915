import colors from 'helpers/constants/colors';
import styled, { keyframes } from 'styled-components';

type LoadingIndicatorProps = {
  text?: string;
  size?: 'small' | 'med' | 'large';
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const Spinner = styled.div<{ radius: number }>`
  position: relative;
  width: ${(props) => props.radius * 2}px;
  height: ${(props) => props.radius * 2}px;
  animation: ${spin} 3s linear infinite;
`;

const Dot = styled.div<{ opacity: number; dotRadius: number }>`
  position: absolute;
  width: ${(props) => props.dotRadius * 2}px;
  height: ${(props) => props.dotRadius * 2}px;
  background-color: ${colors.mainBlue};
  border-radius: 50%;
  opacity: ${(props) => props.opacity};
`;

const Text = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: ${colors.black};
`;

export const LoadingIndicator = ({ text, size = 'med' }: LoadingIndicatorProps) => {
  let radius = 20;
  let dotRadius = 3;
  let dotCount = 10;

  if (size === 'small') {
    radius = 10;
    dotRadius = 2;
    dotCount = 6;
  } else if (size === 'large') {
    radius = 40;
    dotRadius = 6;
    dotCount = 10;
  }

  const dots = Array.from({ length: dotCount }).map((_, i) => {
    const angle = (i * 360) / dotCount;
    const x = radius - dotRadius + radius * Math.cos((angle * Math.PI) / 180);
    const y = radius - dotRadius + radius * Math.sin((angle * Math.PI) / 180);
    const opacity = 1 - i * (dotCount / 100);
    return (
      <Dot
        key={i}
        style={{ top: `${y}px`, left: `${x}px` }}
        opacity={opacity}
        dotRadius={dotRadius}
      />
    );
  });

  return (
    <IndicatorContainer>
      <Spinner radius={radius}>{dots}</Spinner>
      <Text>{text}</Text>
    </IndicatorContainer>
  );
};
