import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionalActionEnum, ReferenceMediaFieldType } from '@cpm/scanifly-shared-data';
import { useFormik } from 'formik';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';
import {
  EditButtons,
  EditDropdown,
  EditFieldText,
  UploadButton,
} from 'screens/ChecklistTemplate/Editor/common';
import { Option } from 'screens/ChecklistTemplate/Editor/common/SimpleDropdown';

import { ErrorDisplay, ReferenceMediaPreview, Toggle } from 'components';

import { openNotification } from 'helpers/utils/openNotification';

import {
  Description,
  FieldWrapper,
  Label,
  Row,
  RowNoMargin,
  Spacer,
} from './CommonFieldComponents';
import ConditionalStatement from './ConditionalStatement';
import useChecklistButtons from './hooks/useChecklistsButtons';
import IdDisplay from './IdDisplay';

type Props = {
  item: ReferenceMediaFieldType;
};

/**
 * Allows users to select from a list of `mediacategories` with `type` = `reference`
 * Then any media under that category appears as an option to select from in the checklist
 */
function ReferenceMediaField({
  item: {
    id,
    componentType,
    isRequired,
    label,
    description,
    conditional,
    referenceMediaCategoryId,
    exampleMedia,
  },
}: Props) {
  const { t } = useTranslation();
  const {
    getReferenceMediaAction,
    referenceMediaCategories,
    referenceMediaMap,
    updateField,
    moveField,
  } = useContext(ChecklistTemplateContext);

  const referenceMediaCategoryOptions: Option[] = useMemo(
    () =>
      referenceMediaCategories.map(({ categoryName, id }) => ({
        id,
        display: categoryName,
      })),
    [referenceMediaCategories]
  );

  const [selectedCategoryOption, setSelectedCategoryOption] = useState<Option | undefined>(
    referenceMediaCategoryOptions.find(({ id }) => id === referenceMediaCategoryId)
  );

  const selectedOptionIds = useMemo(
    () => (selectedCategoryOption ? [selectedCategoryOption.id] : []),
    [selectedCategoryOption]
  );

  const referenceMedia = useMemo(() => {
    return referenceMediaMap[selectedCategoryOption?.id ?? ''];
  }, [referenceMediaMap, selectedCategoryOption?.id]);

  const handleUpdateField = useCallback(
    (newValues: Partial<ReferenceMediaFieldType>) => {
      updateField(id, newValues);
    },
    [updateField, id]
  );

  const formik = useFormik({
    initialValues: {
      label,
      isRequired,
      description,
      exampleMedia: exampleMedia || [],
      referenceMediaCategoryId,
      conditional: {
        action: conditional?.action || ('' as ConditionalActionEnum),
        isEqual: {
          targetFieldId: conditional?.isEqual?.targetFieldId || '',
          targetValue: conditional?.isEqual?.targetValue || '',
        },
      },
    },
    onSubmit: (values) => {
      handleUpdateField(values);
    },
  });
  const { handleCopy, handleDelete, handleEdit, handleSave, handleCancel, editing } =
    useChecklistButtons({ id, handleSubmit: formik.handleSubmit, resetForm: formik.resetForm });

  /**
   * @param categoryId optional, only pass in if calling this from useEffect
   * otherwise the setState doesn't finish before this is called
   */
  const onSave = (categoryId?: string) => {
    const selectedCategoryId = categoryId ?? selectedCategoryOption?.id;
    if (!selectedCategoryId) {
      openNotification({
        type: 'error',
        title: t('alertMessages.errorTitle'),
        text: (
          <div>
            {t('Checklists.saveFail')}
            <ErrorDisplay error={new Error(t('Checklists.categoryRequired'))} />
          </div>
        ),
      });
      return;
    }

    formik.handleChange({
      target: {
        name: 'referenceMediaCategoryId',
        value: selectedCategoryId,
      },
    });
    handleSave();
  };

  const displayValue = useCallback(() => {
    return <p>{selectedCategoryOption?.display ?? t('Generic.selectOption')}</p>;
  }, [selectedCategoryOption, t]);

  const dropdownOnChange = useCallback((value: Option[]) => {
    setSelectedCategoryOption(value[0]);
  }, []);

  useEffect(() => {
    // If only 1 option default to that
    if (!selectedCategoryOption && referenceMediaCategoryOptions.length === 1) {
      setSelectedCategoryOption(referenceMediaCategoryOptions[0]);
      onSave(referenceMediaCategoryOptions[0]?.id);
    } else if (!referenceMedia && selectedCategoryOption?.id) {
      getReferenceMediaAction(selectedCategoryOption?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getReferenceMediaAction,
    referenceMediaCategoryOptions,
    selectedCategoryOption,
    referenceMedia,
    setSelectedCategoryOption,
  ]);

  return (
    <FieldWrapper conditional={Boolean(conditional?.isEqual.targetValue)}>
      {editing.includes(id) ? (
        <form>
          <EditButtons
            mode="edit"
            componentType={componentType}
            onSave={onSave}
            onDelete={handleDelete}
            onCancel={handleCancel}
          />
          <Label>{t('Checklists.editingField')}</Label>
          <Spacer />
          <IdDisplay id={id} />
          <Spacer />
          <EditDropdown
            renderValue={displayValue}
            label={t('Checklists.referenceCategory') as string}
            onChange={dropdownOnChange}
            options={referenceMediaCategoryOptions}
            selectedOptionIds={selectedOptionIds}
          />
          <Spacer />
          <EditFieldText
            id="label"
            label={t('Checklists.label') as string}
            onChange={formik.handleChange}
            value={formik.values.label}
          />
          <Spacer />
          <EditFieldText
            parentId={id}
            id="description"
            label={t('Checklists.description') as string}
            onChange={formik.handleChange}
            value={formik.values.description}
            expandable
          />
          <Spacer height={3} />
          <UploadButton
            id={id}
            onChange={(media) => formik.setFieldValue('exampleMedia', media)}
            value={formik.values.exampleMedia}
          />
          <Spacer height={3} />
          <ReferenceMediaPreview
            categoryId={referenceMediaCategoryId}
            referenceMedia={referenceMedia}
          />
          <Spacer height={2} />
          <ConditionalStatement
            id={id}
            value={formik.values.conditional}
            onChange={formik.handleChange}
            resetForm={() => {
              formik.resetForm({
                values: {
                  label: formik.values.label,
                  isRequired: formik.values.isRequired,
                  exampleMedia: formik.values.exampleMedia,
                  description: formik.values.description,
                  referenceMediaCategoryId: formik.values.referenceMediaCategoryId,
                  conditional: {
                    action: '' as ConditionalActionEnum,
                    isEqual: {
                      targetFieldId: '',
                      targetValue: '',
                    },
                  },
                },
              });
            }}
          />
          <Spacer />
          <Toggle
            id={'isRequired'}
            label={t('Checklists.isRequired')}
            checked={formik.values.isRequired}
            onChange={formik.handleChange}
            disabled={!editing.includes(id)}
          />
        </form>
      ) : (
        <>
          <RowNoMargin>
            <Label>{label}</Label>
            <EditButtons
              mode="display"
              componentType={componentType}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onUp={() => moveField(id, -1)}
              onDown={() => moveField(id, 1)}
              onCopy={handleCopy}
            />
          </RowNoMargin>
          {description && <Spacer />}
          {description && <Description>{description}</Description>}
          {selectedCategoryOption && <Spacer />}
          {selectedCategoryOption && <Description>{selectedCategoryOption.display}</Description>}
          {selectedCategoryOption && <Spacer />}
          {selectedCategoryOption && (
            <ReferenceMediaPreview
              categoryId={referenceMediaCategoryId}
              referenceMedia={referenceMedia}
            />
          )}
          <Row>{/* Preview photos here */}</Row>
          <Spacer />
          <Toggle
            id={`${id}-required`}
            label={t('Checklists.isRequired')}
            checked={formik.values.isRequired}
          />
        </>
      )}
    </FieldWrapper>
  );
}

export default ReferenceMediaField;
