import { useTranslation } from 'react-i18next';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Card } from 'antd';
import cn from 'classnames';

import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { DesignOption } from 'screens/DesignServices/constants';
import { CreditDisplay } from './CreditDisplay/CreditDisplay';
import { Label } from './Label/Label';
import './SelectDesignButton.scss';
import { Selection } from './Selection/Selection';
import { Specifications } from './Specifications/Specifications';
import { TurnaroundTimeDisplay } from './TurnaroundTimeDisplay/TurnaroundTimeDisplay';

type SelectDesignButtonProps = {
  option?: DesignOption;
  selected: { tierLevel: TIER_LEVEL; isExpedited: boolean } | null;
  handleSelect: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
  hasNewTag?: boolean;
  isExpedited?: boolean;
  id: string;
  isPrelim?: boolean;
};

const StyledDescription = styled.div`
  font-size: ${fontSizes.extraExtraSmall};
  color: ${colors.darkGray};
  margin-top: 0.5rem;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 28%;
  flex-grow: 1;

  @media (max-width: ${breakpoints.xxl}) {
    flex-basis: 27%;
  }
`;

const SelectDesignButton = ({
  option,
  selected,
  handleSelect,
  hasNewTag = false,
  isExpedited = false,
  id,
  isPrelim = false,
}: SelectDesignButtonProps) => {
  const { t } = useTranslation();

  if (!option) return null;

  const isSelected =
    selected?.tierLevel === option.tierLevel &&
    selected?.isExpedited === (option?.isExpedited ?? false);

  return (
    <div
      role="button"
      onClick={() => {
        if (option.tierLevel) {
          handleSelect({ tierLevel: option.tierLevel, isExpedited });
        }
      }}
      onKeyDown={() => {}}
      tabIndex={0}
      data-testid={id}
    >
      <Card
        hoverable
        className={cn('SelectDesignButton-MainWrapper', {
          'SelectDesignButton--active': isSelected,
        })}
      >
        <StyledTitleWrapper>
          <div>
            <Label hasNewTag={hasNewTag} isExpedited={isExpedited} option={option} t={t} />
            <TurnaroundTimeDisplay
              isPrelim={isPrelim}
              isExpedited={isExpedited}
              option={option}
              t={t}
            />
            {option.tierLevel === TIER_LEVEL.planSetForLarge ? (
              <StyledDescription>{t('DesignService.extraChargesDisclaimer')}</StyledDescription>
            ) : null}
          </div>
        </StyledTitleWrapper>
        <CreditDisplay isPrelim={isPrelim} option={option} t={t} />
        <Specifications option={option} />
        <Selection isSelected={isSelected} />
      </Card>
    </div>
  );
};

export default SelectDesignButton;
