export const TABLE_NAMES = {
  ACCOUNTING: 'accounting_table',
  UPLOAD_QUEUE: 'upload_queue_table',
  ACCOUNT_MANAGER: 'account_manager_table',
  USERS: 'all_users_table',
  CUSTOMER_USERS: 'users_table',
  PROJECTS: 'all_projects_table',
  CUSTOMER_PROJECTS: 'customer_projects_table',
  USER_PROJECTS: 'user_projects_table',
  MY_PROJECTS_PAGE: 'all_projects',
  DESIGN_SERVICES_QUEUE: 'design_services_queue',
  COMPANY_DESIGN_SERVICES_QUEUE: 'company_design_services_queue',
  CREDIT_HISTORY: 'credit_history',
  CREDIT_HISTORY_FOR_ALL_COMPANIES: 'credit_history_for_all_companies',
  DESIGN_SERVICES_STATISTICS: 'design_services_statistics',
} as const;
