export const reduce = (array = [], field1 = '', field2 = '') => {
  if (!Array.isArray(array) || typeof field1 !== 'string' || typeof field2 !== 'string') return 0;
  const values = array.map((account) => {
    return (
      (typeof account?.[field1] === 'number' ? account[field1] : 0) +
      (typeof account?.[field2] === 'number' ? account[field2] : 0)
    );
  });
  return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
};
