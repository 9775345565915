const BASE_API_KEY_URL = '/api-keys';
/**
 * Returns the URL for fetching the current user's API keys.
 * @returns {string} The URL endpoint for user API keys.
 */
export const getCurrentUsersApiKeysUrl = () => BASE_API_KEY_URL;

/**
 * Returns the URL for deleting an API key.
 * @param {string} id - The unique identifier of the API key to delete.
 * @returns {string} The URL endpoint for deleting an API key.
 */
export const deleteApiKeyUrl = (id: string) => `${BASE_API_KEY_URL}/${id}`;

/**
 * Returns the URL for creating an API Key.
 * @returns {string} The URL endpoint for creating an API key.
 */
export const createApiKeyUrl = () => BASE_API_KEY_URL;
