import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { camelCase } from 'lodash';
import { DesignOption } from 'screens/DesignServices/constants';
import SelectDesignButton from '../../SelectDesignButton/SelectDesignButton';

type Props = {
  designOptions: DesignOption[];
  selected: {
    tierLevel: TIER_LEVEL;
    isExpedited: boolean;
  } | null;
  handleSelection: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
};

export const OnsiteOptionsWrapper = ({ designOptions, selected, handleSelection }: Props) => {
  return designOptions.map((option) => {
    return (
      <SelectDesignButton
        option={option}
        selected={selected}
        handleSelect={handleSelection}
        hasNewTag={option?.hasNewTag ?? false}
        key={option.serviceType}
        id={camelCase(option.serviceType)}
      />
    );
  });
};
