import { useState } from 'react';
import MapGL, { Marker } from 'react-map-gl';

import cn from 'classnames';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import { MAP_STYLES, MAP_THEMES } from 'screens/MapWrapper/constants';
import 'screens/MapWrapper/MapPin/MapPin.scss';

import { MapStyleControl } from 'components';

import { ReactComponent as PinIcon } from 'assets/icons/map-pin.svg';

import './CustomerInfoMap.scss';

const CustomerInfoMap = ({
  viewport,
  setViewport,
  pin,
  setPin,
  handleMarkerDragEnd,
  isManualPinSetting,
}) => {
  const [mapStyle, setMapStyle] = useState(MAP_STYLES.satellite);
  const isPinVisible = Boolean(pin.longitude && pin.latitude);

  const handleManualPinSetting = (event) => {
    const {
      leftButton,
      lngLat: [longitude, latitude],
    } = event;

    leftButton && setPin({ longitude, latitude });
  };

  return (
    <div
      className={cn('CustomerInfo-Map', {
        'CustomerInfo-Map--ManualPinSetting': isManualPinSetting,
      })}
    >
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        mapStyle={MAP_THEMES[mapStyle]}
        onClick={handleManualPinSetting}
      >
        {isPinVisible && (
          <Marker
            longitude={pin.longitude}
            latitude={pin.latitude}
            draggable
            onDragEnd={handleMarkerDragEnd}
          >
            <PinIcon
              className={cn('MapPin', {
                'MapPin--SatelliteView': mapStyle === MAP_STYLES.satellite,
              })}
            />
          </Marker>
        )}
        <MapStyleControl mapStyle={mapStyle} setMapStyle={setMapStyle} />
      </MapGL>
    </div>
  );
};

CustomerInfoMap.propTypes = {
  viewport: PropTypes.object.isRequired,
  setViewport: PropTypes.func.isRequired,
  pin: PropTypes.objectOf(PropTypes.number).isRequired,
  setPin: PropTypes.func.isRequired,
  handleMarkerDragEnd: PropTypes.func.isRequired,
  isManualPinSetting: PropTypes.bool.isRequired,
};

export default CustomerInfoMap;
