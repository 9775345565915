import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import Heap from 'react-heap';
import { useSelector } from 'react-redux';

import { Layout as AntLayout } from 'antd';
import cn from 'classnames';
import Header from 'screens/Header';
import { Sidebar, SidebarProvider } from 'screens/Sidebar';

import { RootState } from 'state/store/store';

import useHeap from 'helpers/hooks/useHeap';
import useLaunchDarkly from 'helpers/hooks/useLaunchDarkly';
import useUserpilot from 'helpers/hooks/useUserpilot';
import { Analytics } from 'helpers/utils';
import useHubSpot from '../../helpers/hooks/useHubSpot';

import './Layout.scss';

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
};
const Layout = ({ children }: LayoutProps) => {
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { id: companyId, name: companyName } =
    useSelector((state: RootState) => state.company.company) ?? {};
  const heapID = process.env.REACT_APP_HEAP_ID;

  useEffect(() => {
    if (currentUser) {
      const { email, id, firstName: name } = currentUser;
      Sentry.setUser({ email });
      Analytics.getInstance().setUser({
        id,
        email,
        name,
        companyId,
        companyName,
      });
    }
  }, [currentUser, companyId, companyName]);

  useHeap();
  useHubSpot();
  useLaunchDarkly();
  useUserpilot();

  return (
    <AntLayout className="Layout">
      <SidebarProvider>
        {heapID && <Heap appId={heapID} userId={currentUser?.id} />}
        <Sidebar />
        <AntLayout role="main" className={cn('Content')}>
          <Header />
          {children}
        </AntLayout>
      </SidebarProvider>
    </AntLayout>
  );
};

export default Layout;
