import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomYAxisTick } from 'components';

import colors from 'helpers/constants/colors';

import { MONTHS, PLACEHOLDER_DATA } from './constants';
import './InfoViewChart.scss';

const InfoViewShadeChart = ({ values }) => {
  const chartData = Object.keys(values.length ? values : PLACEHOLDER_DATA).map((i) => ({
    name: upperFirst(MONTHS[i]),
    ASA: parseInt(Object.values(values)[i]) || 0,
  }));

  return (
    <div className="InfoViewChart">
      <div className="InfoViewChart-Legend">
        <div className="InfoViewChart-Legend-Bar" />
        Solar Access
      </div>
      {!values.length && (
        <p className="InfoViewChart-Instruction">Please add viewsheds in Scanifly3D</p>
      )}
      <ResponsiveContainer width="99%" minWidth={280} height={256}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickFormatter={(name) => name.charAt(0)}
            tick={{ fill: colors.labelGray }}
            tickLine={false}
            tickMargin={16}
          />
          <YAxis
            axisLine={false}
            tick={<CustomYAxisTick />}
            tickLine={false}
            interval="preserveEnd"
          />
          <Tooltip cursor={values.length} wrapperStyle={!values.length && { display: 'none' }} />
          <Bar dataKey="ASA" fill={colors.mainBlue} radius={[6, 6, 6, 6]} barSize={12} unit="%" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

InfoViewShadeChart.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default InfoViewShadeChart;
