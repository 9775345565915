type FormValues = {
  folderName: string;
};

const InitialValues = {
  folderName: '',
};

enum FormFields {
  folderName = 'folderName',
}

export { FormFields, InitialValues };
export type { FormValues };
