import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-icon.svg';

import colors from 'helpers/constants/colors';

const RequiredWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
`;

const IconWrapper = styled.div<{ color: colors }>`
  display: flex;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
`;

const RequiredIcon = styled(TickIcon)`
  width: 100%;
  height: 100%;
`;
const NotRequiredIcon = styled(XIcon)``;

const TextContainer = styled.div`
  margin-left: 1rem;
`;

type Props = {
  required: boolean;
};

export const Required = ({ required }: Props) => {
  const { t } = useTranslation();
  if (required)
    return (
      <RequiredWrapper>
        <IconWrapper color={colors.green}>
          <RequiredIcon />
        </IconWrapper>
        <TextContainer>{t('Checklists.required')}</TextContainer>
      </RequiredWrapper>
    );

  return (
    <RequiredWrapper>
      <IconWrapper color={colors.yellow}>
        <NotRequiredIcon />
      </IconWrapper>
      <TextContainer>{t('Checklists.notRequired')}</TextContainer>
    </RequiredWrapper>
  );
};
