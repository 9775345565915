import * as Yup from 'yup';

import {
  noWhitespaces,
  passwordMaxLengthValidation,
  passwordMinLengthValidation,
  passwordMustMatchValidation,
  passwordRegexValidation,
} from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = Yup.object().shape({
  [FORM_CONTROLS.PASSWORD]: Yup.string()
    .required('Password is required')
    .test(...noWhitespaces)
    .min(...passwordMinLengthValidation)
    .max(...passwordMaxLengthValidation)
    .matches(...passwordRegexValidation),
  [FORM_CONTROLS.CONFIRM_PASSWORD]: Yup.string()
    .test(...noWhitespaces)
    .test(...passwordMustMatchValidation),
});
