import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const OptionsListWrapper = styled.div``;

const Option = styled.div`
  display: flex;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 0.5rem;
    background-color: ${colors.black};
    border-radius: 0.25rem;
    margin-left: 0.45rem; // Used to align with the required component for OCD purposes
  }
`;

type Props = {
  options: string[];
};

export const Options = ({ options }: Props) => {
  return (
    <OptionsListWrapper>
      {options.map((option: string, index: number) => (
        <Option key={`key-${index}`}>{option}</Option>
      ))}
    </OptionsListWrapper>
  );
};
