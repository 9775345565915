import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 1.1rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.1rem;
  }
  p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
`;

function AlbumSelectPlaceholder() {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <CategoryIcon />
      <p>{t('ChecklistTemplate.selectAlbum')}</p>
    </StyledContainer>
  );
}

export default AlbumSelectPlaceholder;
