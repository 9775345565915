import { ReactComponent as UncheckedIcon } from 'assets/checkbox-unchecked.svg';
import { ReactComponent as CheckboxIcon } from 'assets/checkbox.svg';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

export type Option = {
  display: string;
  id: string;
};

export type SimpleDropdownProps = {
  containerClassName?: string;
  dropdownClassName?: string;
  renderValue: () => JSX.Element;
  renderSelectedOptions?: () => JSX.Element;
  options: Option[];
  disabled?: boolean;
  onChange: (selectedOption: Option[]) => void;
  isMultiSelectEnabled?: boolean;
  /**
   * If `isMultiSelectEnabled` this array will contain all values.
   * If not multi select then array contains only 1 selected value
   */
  selectedOptionIds: string[];
};

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${colors.white};
  border-width: 1px;
  border-radius: 0.5rem;
  height: 3.5rem;
  margin: 0.5rem 1rem;
  padding: 0 1rem;
  &:focus {
    outline: none;
  }

  &: hover {
    padding: 0 1rem;
    background-color: ${colors.lightGray};
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  padding: 0 0.3rem;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;

  svg {
    margin-right: 1rem;
    width: 1.8rem;
    height: 1.8rem;
  }
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
  }

  .checkbox-container {
    flex: 0.15;
  }

  p {
    flex: 0.85;
  }
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

type OnChangeEvent =
  | React.MouseEvent<HTMLInputElement | HTMLLIElement, MouseEvent>
  | ChangeEvent<HTMLInputElement>;

const SimpleDropdown = ({
  containerClassName,
  dropdownClassName,
  renderValue: PlaceholderView,
  renderSelectedOptions: SelectedOptionsView,
  options,
  onChange,
  disabled = false,
  isMultiSelectEnabled,
  selectedOptionIds,
}: SimpleDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    // Make sure the user actually clicked on the button so we prevent incorrectly triggering this event
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const x = e.clientX;
      const y = e.clientY;

      const withinXBounds = x >= rect.left && x <= rect.right;
      const withinYBounds = y >= rect.top && y <= rect.bottom;
      if (withinXBounds && withinYBounds) {
        if (!disabled) {
          setIsOpen(!isOpen);
        }
      }
    }
  };

  useEffect(() => {
    const handleClickoutside = (e: MouseEvent) => {
      e.stopPropagation();
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickoutside);

    return () => {
      document.removeEventListener('mousedown', handleClickoutside);
    };
  }, []);

  const handleOptionClick = (e: OnChangeEvent, selectedOption: Option) => {
    e.stopPropagation();
    if (onChange) {
      if (isMultiSelectEnabled) {
        const selectedOptions = options.filter(({ id }) => {
          const isOptionInArray = selectedOptionIds.includes(id);
          if (id === selectedOption.id) {
            return !isOptionInArray;
          }
          return isOptionInArray;
        });
        onChange(selectedOptions);
      } else {
        onChange([selectedOption]);
      }
    }
    !isMultiSelectEnabled && setIsOpen(false);
  };

  return (
    <Container className={containerClassName}>
      <DropdownWrapper className={dropdownClassName} ref={wrapperRef}>
        <DropdownButton onClick={toggleDropdown} ref={buttonRef}>
          {<PlaceholderView />}
        </DropdownButton>
        {isOpen && (
          <DropdownList>
            {options.map((option) => {
              const isSelected = selectedOptionIds.includes(option.id);
              const onClick = (e: OnChangeEvent) => handleOptionClick(e, option);
              return (
                <DropdownItem key={option.id} onClick={onClick}>
                  <div className={'checkbox-container'}>
                    {isMultiSelectEnabled && (isSelected ? <CheckboxIcon /> : <UncheckedIcon />)}
                  </div>
                  <p>{option.display}</p>
                </DropdownItem>
              );
            })}
          </DropdownList>
        )}
      </DropdownWrapper>
      {SelectedOptionsView && <SelectedOptionsView />}
    </Container>
  );
};

export default SimpleDropdown;
