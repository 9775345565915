import useToggle from 'helpers/hooks/useToggle';

import CollapsibleWrapper from '../CollapsibleWrapper/CollapsibleWrapper';
import CollapsedView from './CollapsedView';
import ExpandedView from './ExpandedView';

type Props = {
  collapsed?: boolean;
  staticContainer?: boolean;
};

const ExpandableQRCode = ({ collapsed = true, staticContainer = false }: Props) => {
  const [isCollapsed, toggleCollapsible] = useToggle(collapsed);
  if (staticContainer) {
    return <ExpandedView />;
  }

  return (
    <CollapsibleWrapper
      header={isCollapsed ? <CollapsedView /> : <ExpandedView />}
      onChange={toggleCollapsible}
      className="QRCode-Border"
    />
  );
};

export default ExpandableQRCode;
