import { ChangeEvent, memo, useState } from 'react';

import { ConditionalActionEnum, ConditionalLogicEnum } from '@cpm/scanifly-shared-data';
import { t } from 'i18next';

import styled from 'styled-components';

import { Dropdown, Toggle } from 'components';

import { EditFieldText } from 'screens/ChecklistTemplate/Editor/common';
import {
  Column,
  Container,
  IdLabel as Label,
  ConditionalRow as Row,
} from './CommonFieldComponents';

const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
`;
const RadioLabel = styled.label`
  align-self: center;
  margin-right: 6rem;
  font-size: 1em;
  margin-top: 2px;
`;

// when {field ID} {conditional dropdown} to value {value field} this field should {action dropdown}
type Props = {
  id: string;
  value: {
    action: ConditionalActionEnum;
    isEqual: {
      targetFieldId: string;
      targetValue: string;
    };
  };
  onChange: (e: ChangeEvent<{ name: string; value: any }>) => void;
  resetForm: () => void;
};

function ConditionalStatement({ id, value, onChange, resetForm }: Props) {
  const [checked, setChecked] = useState<boolean>(Boolean(value.isEqual.targetFieldId));

  const conditions = [ConditionalLogicEnum.isEqual];

  const toggleCheck = () => {
    setChecked(!checked);
    if (checked) {
      resetForm();
    }
  };

  const handleActionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const actionChange: ChangeEvent<{ name: string; value: string }> = {
      target: {
        name: 'conditional.action',
        value: event.target.value,
      },
      // not sure why i can't type this
    } as any;
    onChange(actionChange);
  };

  return (
    <>
      <Container>
        <Label>{t('Checklists.conditionalField')}</Label>
        <Toggle
          id={`${id}-required`}
          label={t('Checklists.isEnabled')}
          onChange={toggleCheck}
          checked={checked}
          disabled={false}
        />
      </Container>
      {checked && (
        <Container>
          <Column>
            <Row>
              <Label>{t('Checklists.targetFieldId')}</Label>
              <EditFieldText
                id={'conditional.isEqual.targetFieldId'}
                placeholder="ID"
                value={value.isEqual.targetFieldId}
                onChange={onChange}
              />
            </Row>
            <Row>
              <Label>{t('Checklists.condition')}</Label>
              <Dropdown displayedValue="Equals" options={conditions} disabled value={'isEqual'} />
            </Row>
            <Row>
              <Label>{t('Checklists.input')}</Label>
              <EditFieldText
                id={'conditional.isEqual.targetValue'}
                placeholder="Value"
                value={value.isEqual.targetValue}
                onChange={onChange}
              />
            </Row>
            <Row>
              <Label>{t('Checklists.thisFieldShould')}</Label>
              <RadioInput
                id="display"
                value={ConditionalActionEnum.display}
                checked={value.action === ConditionalActionEnum.display}
                onChange={handleActionChange}
              />
              <RadioLabel htmlFor="display">{t('Checklists.display')}</RadioLabel>
              <RadioInput
                id="hide"
                value={ConditionalActionEnum.hide}
                checked={value.action === ConditionalActionEnum.hide}
                onChange={handleActionChange}
              />
              <RadioLabel htmlFor="hide">{t('Checklists.hide')}</RadioLabel>
            </Row>
          </Column>
        </Container>
      )}
    </>
  );
}

export default memo(ConditionalStatement);
