import { i18n } from 'helpers/utils/translations';

export const FORM_CONTROLS = {
  SUMMARY: 'summary',
  WEATHER_CONSIDERATIONS: 'weatherConsiderations',
  ISSUES: 'issues',
  RECOMMENDED_FIXES: 'recommendedFixesAndImprovements',
  NOTES_FOR_GENERAL_MAINTENANCE: 'notesForGeneralMaintenance',
  NOTES_FOR_THERMAL: 'notesForThermal',
};

export const initialValues = {
  [FORM_CONTROLS.SUMMARY]: '',
  [FORM_CONTROLS.WEATHER_CONSIDERATIONS]: '',
  [FORM_CONTROLS.ISSUES]: '',
  [FORM_CONTROLS.RECOMMENDED_FIXES]: '',
  [FORM_CONTROLS.NOTES_FOR_GENERAL_MAINTENANCE]: '',
  [FORM_CONTROLS.NOTES_FOR_THERMAL]: '',
};

export const formFields = [
  {
    fieldName: FORM_CONTROLS.NOTES_FOR_GENERAL_MAINTENANCE,
    title: i18n.t('MaintenanceReport.formTitles.notesForGeneralMaintenance'),
  },
  {
    fieldName: FORM_CONTROLS.NOTES_FOR_THERMAL,
    title: i18n.t('MaintenanceReport.formTitles.notesForThermal'),
  },
  { fieldName: FORM_CONTROLS.SUMMARY, title: i18n.t('MaintenanceReport.formTitles.summary') },
  {
    fieldName: FORM_CONTROLS.WEATHER_CONSIDERATIONS,
    title: i18n.t('MaintenanceReport.formTitles.weatherConsiderations'),
  },
  { fieldName: FORM_CONTROLS.ISSUES, title: i18n.t('MaintenanceReport.formTitles.issues') },
  {
    fieldName: FORM_CONTROLS.RECOMMENDED_FIXES,
    title: i18n.t('MaintenanceReport.formTitles.recommendedFixesAndImprovements'),
  },
];
