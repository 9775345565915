import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

import { CarouselArrowProps } from './types';

const CarouselArrow = ({ direction, className, onClick }: CarouselArrowProps) => {
  return (
    <button className={className} onClick={onClick} aria-label={direction}>
      {direction === 'right' ? (
        <ChevronRight onClick={onClick} />
      ) : (
        <ChevronLeft onClick={onClick} />
      )}
    </button>
  );
};

export default CarouselArrow;
