import { useHistory, useParams } from 'react-router-dom';

import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';

import { orderDesignRoute, upgradeDesignRoute } from 'helpers/constants/routes';
import { getButtonText } from 'screens/DesignServices/helpers/getButtonText';
import { getIsValid } from 'screens/DesignServices/helpers/isFormValid';

import { BUTTON, FORM_CONTROLS } from '../constants';

type Props = {
  setFormStep: (step: number) => void;
  formStep: number;
  tierLevel: TIER_LEVEL;
  form: any[];
  handleSubmit: () => void;
  isSubmitButtonDisabled: boolean;
  values: any;
  stepNumber: number;
  status: DESIGN_STATUS;
  handleResponse: ({
    projectId,
    status,
    tierLevel,
    stepNumber,
  }: {
    projectId: string;
    status: string;
    tierLevel: string;
    stepNumber: number;
  }) => void;
  filesAlreadyStored: boolean;
  useExistingDesign: boolean;
  useExistingDesignForTrueUp: boolean;
  getFieldProps: Function;
  upgrade: boolean;
};

const FormActionButtons = ({
  setFormStep,
  formStep,
  tierLevel,
  form = [],
  handleSubmit,
  isSubmitButtonDisabled,
  values,
  stepNumber,
  status,
  handleResponse,
  filesAlreadyStored,
  useExistingDesign,
  useExistingDesignForTrueUp,
  getFieldProps,
  upgrade,
}: Props) => {
  const history = useHistory();
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const buttonText = getButtonText({
    step: formStep,
    formLength: form.length,
    tierLevel: tierLevel,
    filesAlreadyStored: filesAlreadyStored,
  });
  const isValid = getIsValid(tierLevel, formStep, values, useExistingDesignForTrueUp);
  const isPlanSet =
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim;

  const isPlanSetCommentForm = isPlanSet && formStep === form.length - 1;

  const isPlanSetPreliminaryDesignForm = isPlanSet && formStep === form.length - 2;

  const isTrueUpDesignForm = tierLevel === TIER_LEVEL.trueUp && formStep === form.length - 1;

  const handleNext = () => {
    if (formStep < form.length) {
      setFormStep(formStep + 1);
    }
  };

  const handleBack = () => {
    setFormStep(formStep > 1 ? formStep - 1 : formStep);

    if (formStep === 1) {
      const previousStep = stepNumber === 1 ? 1 : stepNumber - 2;

      handleResponse({ projectId, status, tierLevel, stepNumber: previousStep });
      history.push(orderDesignRoute(projectId, stepNumber - 2));

      if (upgrade) {
        history.push(upgradeDesignRoute(projectId, String(stepNumber - 2)));
      } else {
        history.push(orderDesignRoute(projectId, stepNumber - 2));
      }
    }
  };

  const isButtonDisabled =
    isSubmitButtonDisabled ||
    formStep > form?.length ||
    !isValid ||
    (isPlanSetCommentForm && filesAlreadyStored === null) ||
    (isPlanSetPreliminaryDesignForm &&
      useExistingDesign &&
      !getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)?.value?.length) ||
    (isTrueUpDesignForm &&
      useExistingDesignForTrueUp &&
      !getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)?.value?.length);

  return (
    <div className="action-button">
      <Button className="Button--Blue" onClick={handleBack}>
        {BUTTON.BACK}
      </Button>
      <Button
        className="Button--Blue"
        onClick={
          buttonText === BUTTON.SUBMIT_REQUEST || buttonText === BUTTON.SUBMIT_ORDER
            ? handleSubmit
            : handleNext
        }
        data-testid={
          buttonText === BUTTON.SUBMIT_REQUEST || buttonText === BUTTON.SUBMIT_ORDER
            ? 'ds-order-submit'
            : 'ds-order-next'
        }
        disabled={isButtonDisabled}
        aria-disabled={isButtonDisabled}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default FormActionButtons;
