import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { getFormattedTimeDifferenceForAverage } from 'helpers/utils/getFormattedTimeDifference';
import { snakeCase } from 'lodash';
import styled from 'styled-components';

type Props = {
  title: string;
  value: number | null;
  isTime?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  background-color: ${colors.white};
  width: fit-content;
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.neutralGray};
  border-radius: 1rem;
  margin-bottom: 1rem;
  font-size: ${fontSizes.small};

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: ${fontSizes.extraSmall};
  }
`;

const StyledTitle = styled.p`
  font-weight: ${fontWeights.semiBold};
  margin-right: 1.5rem;
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.xxl}) {
    margin-right: 0.5rem;
    font-size: ${fontSizes.extraSmall};
  }
`;

const StatisticalContainer = ({ title, value, isTime = false }: Props) => {
  return (
    <StyledContainer>
      <StyledTitle data-testid={`${snakeCase(title)}`}>{title}:</StyledTitle>
      <div data-testid={`${snakeCase(title)}_value`}>
        {isTime ? getFormattedTimeDifferenceForAverage(value) : value ?? 0}
      </div>
    </StyledContainer>
  );
};

export default StatisticalContainer;
