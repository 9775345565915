export const priceFormatter = (fractionDigits) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatUSD = (num, fractionDigits = 2) => {
  if (typeof num !== Number) {
    num = Number(num);
    if (Number.isNaN(num)) {
      num = 0;
    }
  }
  return priceFormatter(fractionDigits).format(num);
};
