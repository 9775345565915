import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal, Spin, Table } from 'antd';
import { debounce } from 'lodash-es';

import { adminCompaniesWithMembersPopulatedRequested } from 'state/slices/admin/adminCompaniesSlice';
import { resetCreditTransactions } from 'state/slices/designServices/orderDesignServiceCreditsSlice';

import { CSVExportButton, GoBackButton } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { filterAccounts } from 'helpers/utils/filterAccounts';

import { ReactComponent as EnvelopeIcon } from 'assets/icons/envelope.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { AppDispatch, RootState } from 'state/store/store';
import '../ScaniflyAdmin.scss';
import AccountInvitation from './AccountInvite/AccountInvitation';
import AdjustDesignCredits from './AddDesignCredits/AdjustDesignCredits';
import { ACCOUNT_MANAGER_CSV_COLUMN_TITLES, CREDIT_ACTIONS } from './constants';
import { handleAccountManagerCSVExport } from './helpers';
import TableData, { Account } from './TableData';

const AccountManager = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [dataSource, setDataSource] = useState<Account[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{ name: string; id: string } | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const { companiesWithMembersPopulated, isLoading } = useSelector(
    (state: RootState) => state.adminCompanies
  );

  useEffect(() => {
    dispatch(adminCompaniesWithMembersPopulatedRequested());
    dispatch(resetCreditTransactions());
  }, [dispatch]);

  useEffect(() => {
    setDataSource(companiesWithMembersPopulated);
    setIsDataLoading(isLoading);
  }, [companiesWithMembersPopulated, isLoading]);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleRefundCreditModalOpen = () => {
    setSelectedAction(CREDIT_ACTIONS.REFUND);
  };

  const handleAddCreditModalOpen = () => {
    setSelectedAction(CREDIT_ACTIONS.ADD_TRIAL_CREDIT);
  };

  const handleRemoveCreditModalOpen = () => {
    setSelectedAction(CREDIT_ACTIONS.REMOVE_CREDIT);
  };

  const handleCreditModalClose = () => {
    setSelectedCompany(null);
    setSelectedAction(null);
  };

  return (
    <>
      {/* @ts-ignore antd is not compatible with @types/react v18 */}
      <Modal visible={modalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <AccountInvitation />
      </Modal>
      {/* @ts-ignore antd is not compatible with @types/react v18 */}
      <Modal visible={Boolean(selectedAction)} onCancel={handleCreditModalClose} {...MODAL_PROPS}>
        <AdjustDesignCredits
          handleAddCreditModalClose={handleCreditModalClose}
          selectedCompany={selectedCompany}
          selectedAction={selectedAction}
        />
      </Modal>
      <div className="ScaniflyAdmin-Wrapper">
        <div className="ScaniflyAdmin-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            prefix={<SearchIcon />}
            aria-label="search accounts"
            onChange={debounce((e) => {
              const filteredData = filterAccounts(companiesWithMembersPopulated, e.target.value);
              setDataSource(filteredData);
            }, 400)}
            className="ScaniflyAdmin-Search"
            data-testid="searchbox"
          />
          <p data-testid="account-count">{dataSource.length} accounts</p>
          <Button className="Button--Blue" onClick={handleModalOpen}>
            <EnvelopeIcon />
            Send Account Invite
          </Button>
          <CSVExportButton
            tableName={TABLE_NAMES.ACCOUNT_MANAGER}
            columnTitles={[...ACCOUNT_MANAGER_CSV_COLUMN_TITLES]}
            dataSource={dataSource}
            prepareCSVData={handleAccountManagerCSVExport}
          />
        </div>
        <Table
          className="ScaniflyAdmin-Table"
          columns={TableData({
            handleAddCreditModalOpen,
            handleRefundCreditModalOpen,
            handleRemoveCreditModalOpen,
            setSelectedCompany,
          })}
          dataSource={dataSource}
          rowKey="id"
          pagination={{ pageSize: 50 }}
          scroll={{ x: 'auto', y: 'calc(100vh - 34rem)' }}
          loading={{
            indicator: (
              <div className="ScaniflyAdmin-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isDataLoading,
          }}
        />
      </div>
    </>
  );
};

export default AccountManager;
