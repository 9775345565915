import colors from 'helpers/constants/colors';
import styled from 'styled-components';

const StyledVerticalDivider = styled.div`
  height: 100%;
  width: 0.1rem;
  background-color: ${colors.darkGray};
`;

const VerticalDivider = () => {
  return <StyledVerticalDivider data-testid="vertical-divider" />;
};

export default VerticalDivider;
