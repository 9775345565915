import { Modal } from 'antd';

import { UpgradeTierLevelForAccess } from 'components';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import './UpgradeModal.scss';

interface IUpgradeModal {
  messageGroup: string;
  isModalOpen: boolean;
  toggleModal: () => void;
}
/*
    To use the UpgradeModal, you need to pass in the messageGroup value prop from ./constants and
    the isModalOpen and toggleModal (useToggle) props from the parent component using UpgradeModal.
*/
const UpgradeModal = ({ messageGroup, isModalOpen, toggleModal }: IUpgradeModal) => {
  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isModalOpen}
      onOk={toggleModal}
      footer={null}
      className="UpgradeModal"
      data-testid="upgrade-modal"
    >
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <UpgradeTierLevelForAccess messageGroup={messageGroup} />
    </Modal>
  );
};

export default UpgradeModal;
