import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { i18n } from 'helpers/utils/translations';

export const getTooltipTitle = ({
  tableName,
  foldersAccess,
}: {
  tableName: string;
  foldersAccess?: string[] | null;
}) => {
  if (tableName === TABLE_NAMES.MY_PROJECTS_PAGE && foldersAccess?.length === 0) {
    return i18n.t('CreditHistory.noAccessToCompanyFolders');
  } else if (tableName === TABLE_NAMES.CREDIT_HISTORY) {
    return i18n.t('CreditHistory.noTransactions');
  } else if (tableName === TABLE_NAMES.DESIGN_SERVICES_STATISTICS) {
    return '';
  }
  return i18n.t('CreditHistory.noProjects');
};
