import { ChecklistStatusEnum, ChecklistType, SectionType } from '@cpm/scanifly-shared-data';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';
import { Button, Dropdown, LoadingIndicator, Tooltip } from 'components';
import colors from 'helpers/constants/colors';
import { DATE_OPTIONS_FOR_ALBUMS } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftPlacement = styled.div`
  justify-content: flex-start;
`;

const MiddleSpacer = styled.div`
  flex-grow: 1;
`;

const RightPlacement = styled.div`
  display: flex;
  justify-self: end;
  flex-direction: row;
`;

const RefreshGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CheckIconWrapper = styled.div<{ color: colors }>`
  display: flex;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
`;

const CheckIcon = styled(TickIcon)`
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const UpdatedText = styled.div`
  margin: 0 1rem;
`;

type HeaderProps = {
  checklist?: ChecklistType;
  selectedSection?: SectionType;
  setSelectedSection?: (sectionId: string) => void;
  onChecklistRefresh: () => Promise<void>;
  checklistLoading: boolean;
  refreshTimestamp: Date;
};

export const Header = ({
  checklist,
  selectedSection,
  setSelectedSection,
  checklistLoading,
  onChecklistRefresh,
  refreshTimestamp,
}: HeaderProps) => {
  const { t } = useTranslation();

  const { sectionTitles, sectionIds }: { sectionTitles: string[]; sectionIds: string[] } =
    useMemo(() => {
      const sectionTitles: string[] = [];
      const sectionIds: string[] = [];
      if (!checklist?.submittedResponse) return { sectionTitles, sectionIds };
      for (const section of checklist?.submittedResponse ?? []) {
        sectionTitles.push(section.title);
        sectionIds.push(section.id);
      }
      return { sectionTitles, sectionIds };
    }, [checklist?.submittedResponse]);

  const downloadPdf = useCallback(async () => {
    if (!checklist?.generatedPdf) return;
    const pdfResponse = await fetch(checklist?.generatedPdf);
    if (pdfResponse.status !== 200) return;
    const pdfObjectUrl = window.URL.createObjectURL(await pdfResponse.blob());
    const dlLink = document.createElement('a');
    dlLink.style.display = 'none';
    dlLink.href = pdfObjectUrl;
    dlLink.download = `${checklist.title}.pdf`;
    document.body.appendChild(dlLink);
    dlLink.click();
    window.URL.revokeObjectURL(pdfObjectUrl);
  }, [checklist?.generatedPdf, checklist?.title]);

  const pdfButtonTooltipText = useMemo(() => {
    if (checklist?.status === ChecklistStatusEnum.completed) {
      return t('Checklists.downloadPdf');
    }
    return t('Checklists.checklistNotSubmitted');
  }, [checklist?.status, t]);

  const refreshTime = useMemo(
    () =>
      refreshTimestamp
        .toLocaleDateString('en-US', DATE_OPTIONS_FOR_ALBUMS)
        .replaceAll('/', '-')
        .split(', ')
        .reverse()
        .join(', '),
    [refreshTimestamp]
  );

  if (!checklist) return <LoadingIndicator />;
  return (
    <Wrapper>
      <LeftPlacement>
        {selectedSection !== null && (
          <Dropdown
            options={sectionIds}
            value={selectedSection?.id}
            displayedValue={selectedSection?.title}
            displayedOptions={sectionTitles}
            onChange={setSelectedSection}
          />
        )}
      </LeftPlacement>
      <MiddleSpacer />
      <RightPlacement>
        <RefreshGroup>
          {checklistLoading ? (
            <LoadingContainer>
              <LoadingIndicator size="small" />
              <UpdatedText>{t('Checklists.updating')}</UpdatedText>
            </LoadingContainer>
          ) : (
            <LoadingContainer>
              <CheckIconWrapper color={colors.green}>
                <CheckIcon />
              </CheckIconWrapper>
              <UpdatedText>{t('Checklists.lastRefreshed', { refreshTime })}</UpdatedText>
            </LoadingContainer>
          )}
          <Tooltip bottom title={t('Checklists.refreshTooltip')}>
            <Button
              disabled={checklistLoading}
              icon="refresh"
              color={colors.mainBlue}
              label={t('Checklists.refresh')}
              onClick={onChecklistRefresh}
            />
          </Tooltip>
        </RefreshGroup>
        <Tooltip bottom title={pdfButtonTooltipText}>
          <Button
            disabled={checklist?.generatedPdf === undefined || checklist?.generatedPdf === ''}
            icon="clouddownload"
            label={t(`Checklists.pdf`) ?? ''}
            padding="0"
            color={colors.mainBlue}
            onClick={downloadPdf}
          />
        </Tooltip>
      </RightPlacement>
    </Wrapper>
  );
};
