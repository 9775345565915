import { CheckMarkFieldType } from '@cpm/scanifly-shared-data';

import { BooleanAnswer, Description, FieldType, Label, Required, Seperator } from './components';

type Props = {
  item: CheckMarkFieldType;
};

export const CheckMark = ({ item }: Props) => {
  return (
    <>
      <FieldType type={item.componentType} />
      <Label text={item.label} />
      {item.description && <Description text={item.description} />}
      <Required required={item.isRequired} />
      <Seperator />
      <BooleanAnswer answer={item.value} />
    </>
  );
};
