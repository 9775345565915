import API from 'api/config';

import {
  integrationAuthUrl,
  integrationDestinationUrl,
  integrationDirectoriesUrl,
  integrationDisconnectUrl,
  integrationUrl,
} from './integrationUrls';

export const integrationStatus = (integrationName: string) =>
  API.get(integrationUrl(integrationName));
export const integrationAuth = (integrationApi: string, prompt = true) =>
  API.get(integrationAuthUrl(integrationApi, prompt));
export const integrationDisconnect = (integrationApi: string) =>
  API.delete(integrationDisconnectUrl(integrationApi));
export const integrationDestination = (integrationApi: string, selectedDirectory: any) =>
  API.patch(integrationDestinationUrl(integrationApi), selectedDirectory);
export const integrationDirectories = (integrationApi: string, parentId?: string | null) =>
  API.get(integrationDirectoriesUrl(integrationApi), {
    params: {
      parentId,
    },
  });
export const integrationCreateDirectory = ({
  integrationApi,
  name,
  parentId,
}: {
  integrationApi: string;
  name: string;
  parentId?: string | null;
}) =>
  API.post(integrationDirectoriesUrl(integrationApi), {
    name,
    parentId,
  });
