import { useTranslation } from 'react-i18next';

import { Row } from 'antd';
import { FieldConfig, FieldInputProps } from 'formik';

import { FORM_CONTROLS } from '../constants';

const AccountingNotesForm = ({
  getFieldProps,
}: {
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
}) => {
  const { t } = useTranslation();

  return (
    <div className="Accounting-Form-Notes">
      <label className="Title" htmlFor="notes">
        {t('AccountingForms.notes')}
      </label>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper--Notes">
          <textarea
            className="ant-input"
            {...getFieldProps(FORM_CONTROLS.NOTES)}
            rows={5}
            placeholder="Lorem ipsum dolor sit amed"
            id="notes"
          />
        </div>
      </Row>
    </div>
  );
};

export default AccountingNotesForm;
