import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

export const planSetValidationSchema = Yup.object().shape({
  //PROJECTINFO START
  [FORM_CONTROLS.HOMEOWNER_NAME]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.COMPANY_ADDRESS]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.UTILITY_COMPANY]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.AHJ]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_VALUE]: Yup.string().when(FORM_CONTROLS.SIZED_FOR_CATEGORY, {
    is: (category: string) => category !== SIZED_FOR_CATEGORIES.none,
    then: (schema) => schema.required('*This field is required').test(...noWhitespaces),
  }),
  //PROJECTINFO END

  // STRUCTURALINFO START
  [FORM_CONTROLS.ROOFING_TYPE]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.ATTACHMENT]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.RACKING]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.CENTER_SPACING]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.RAFTER_SIZE]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),

  [FORM_CONTROLS.MAX_SPACING]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),

  // STRUCTURALINFO ENDS

  //ELECTRICALINFO START
  [FORM_CONTROLS.MAIN_BREAKER_RATING]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MSP_ONSITE]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MODULE_INFO]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.BATTERY_INFO]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.AC_DISCONNECT]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MAIN_BUSBAR_RATING]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.METER_LOCATION]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.INVERTER_INFO]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.BATTERY_COUNT]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PV_REQUIRED]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
});
