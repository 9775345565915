import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ServiceRequest from 'types/ServiceRequest';

import {
  fetchDesignServiceRequestsByProjectId,
  updateServiceRequest,
} from 'api/designServices/designServicesService';

import { i18n } from 'helpers/utils/translations';

import { updateServiceRequestForQueue } from './designServicesQueueSlice';

const name = 'designServiceRequests';

type StateType = {
  requestId: undefined | string | null;
  serviceRequests: null | ServiceRequest[];
  incompleteServiceRequest: null | ServiceRequest;
  updatedServiceRequest: null | ServiceRequest;
  isLoading: boolean;
  error?: any;
};

export const serviceRequestsRequested = createAsyncThunk(
  `${name}/serviceRequestsRequested`,
  async (
    {
      projectId,
      requestId,
    }: {
      projectId: string;
      requestId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchDesignServiceRequestsByProjectId({
        projectId,
      });
      return { data: response.data, requestId };
    } catch (error: any) {
      return rejectWithValue({ data: error?.response?.data, requestId });
    }
  }
);

export const serviceRequestUpdateRequested = createAsyncThunk(
  `${name}/serviceRequestUpdateRequested`,
  async (
    {
      status,
      serviceRequestId,
      finalData,
      handleSuccess,
      handleError,
    }: {
      status?: string;
      serviceRequestId: string;
      finalData: any;
      handleSuccess: () => void;
      handleError: (description?: string) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateServiceRequest({
        serviceRequestId,
        finalData,
        status,
      });

      handleSuccess();
      dispatch(updateServiceRequestForQueue(response.data));
      return response.data;
    } catch (error: any) {
      handleError(i18n.t('DesignService.designCompleted.modelRevisionError'));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const designServiceRequestsSlice = createSlice({
  name,
  initialState: {
    requestId: null,
    serviceRequests: null,
    incompleteServiceRequest: null,
    updatedServiceRequest: null,
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {
    resetServiceRequest: (state) => {
      state.requestId = null;
      state.serviceRequests = null;
      state.incompleteServiceRequest = null;
      state.updatedServiceRequest = null;
      state.error = null;
    },
    resetUpdatedServiceRequest: (state) => {
      state.updatedServiceRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(serviceRequestsRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serviceRequestsRequested.fulfilled, (state, { payload }) => {
        state.serviceRequests = payload.data;
        state.incompleteServiceRequest = payload.data.find(
          (request: ServiceRequest) =>
            request.status !== DESIGN_STATUS.completed && request.status !== DESIGN_STATUS.canceled
        );
        state.requestId = payload.requestId;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(serviceRequestsRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.requestId = error.requestId;
        state.serviceRequests = [];
        state.isLoading = false;
      })
      .addCase(serviceRequestUpdateRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serviceRequestUpdateRequested.fulfilled, (state, { payload }) => {
        state.updatedServiceRequest = payload;
        state.serviceRequests = state.serviceRequests?.length
          ? state.serviceRequests.map((serviceRequest) =>
              serviceRequest.id === payload.id ? payload : serviceRequest
            )
          : [];
        state.error = null;
        state.isLoading = false;
      })
      .addCase(serviceRequestUpdateRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export const { resetServiceRequest, resetUpdatedServiceRequest } =
  designServiceRequestsSlice.actions;

export default designServiceRequestsSlice.reducer;
