import colors from 'helpers/constants/colors';

import { MAP_STYLES, MARKER_SIZE } from './constants';

export const getMarker = (animate, mapRef, mapStyle = MAP_STYLES.satellite) => {
  return {
    width: MARKER_SIZE,
    height: MARKER_SIZE,
    data: new Uint8Array(Math.pow(MARKER_SIZE, 2) * 4),

    onAdd() {
      this.context = document
        .createElement('canvas')
        .getContext('2d', { willReadFrequently: true });
    },

    render() {
      const t = (performance.now() % 1000) / 1000;
      const radius = (MARKER_SIZE / 2) * 0.25;
      const context = this.context;

      context.beginPath(); // Draw inner circle
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = mapStyle === MAP_STYLES.streets ? colors.mainBlue : colors.lightBlue;
      context.strokeStyle = colors.white;
      context.lineWidth = animate ? 2 + 3 * (1 - t) : 3.5;
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      if (animate) {
        // Draw outer circle and repaint map to animate selected markers
        const outerRadius = (MARKER_SIZE / 2) * 0.7 * t + radius;
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        context.fillStyle =
          mapStyle === MAP_STYLES.streets
            ? `rgba(28, 72, 242, ${1 - t})`
            : `rgba(0, 138, 252, ${1 - t})`;
        context.fill();
        mapRef.current.getMap().triggerRepaint();
      }
      return true;
    },
  };
};
