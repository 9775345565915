import { useDispatch } from 'react-redux';

import { loginRequested, logoutRequested } from 'state/slices/authSlice';

const useAuth = () => {
  const dispatch = useDispatch();

  const login = (credentials) => {
    dispatch(loginRequested(credentials));
  };

  const logout = () => {
    dispatch(logoutRequested());
  };

  return {
    login,
    logout,
  };
};

export default useAuth;
