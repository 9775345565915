import { ControlBar, Player } from 'video-react';

import { Container } from './components';

import { validators } from 'helpers/utils';

import { VideoPlayerProps } from './types';
import { Unsupported } from './Unsupported';

const VideoPlayer = ({ className, mimeType, videoUrl }: VideoPlayerProps) => {
  const isSupported = validators.isSupported(mimeType);
  return isSupported ? (
    <Container className={className ?? 'defaultSize'} data-testid={'video-player'}>
      <Player src={videoUrl}>
        <ControlBar autoHide={false} />
      </Player>
    </Container>
  ) : (
    <Unsupported mimeType={mimeType} />
  );
};

export default VideoPlayer;
