import '../CustomerInfoForm.scss';
import { CustomerInfoAddressItemProps } from './types';

export default function CustomerInfoAddressItem({
  children,
  onClick,
}: CustomerInfoAddressItemProps) {
  return (
    <div
      role="textbox"
      tabIndex={0}
      aria-label="address"
      className="CustomerInfoForm-AddressItem"
      onMouseDown={onClick}
    >
      {children}
    </div>
  );
}
