import * as Yup from 'yup';

import {
  noWhitespaces,
  passwordMaxLengthValidation,
  passwordMinLengthValidation,
  passwordMustMatchValidation,
  passwordRegexValidation,
} from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = Yup.object().shape({
  [FORM_CONTROLS.FIRST_NAME]: Yup.string()
    .required('First name is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.LAST_NAME]: Yup.string()
    .required('Last name is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PHONE_NUMBER]: Yup.string().required('Phone number is required'),
  [FORM_CONTROLS.COMPANY]: Yup.string()
    .required('Company is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PASSWORD]: Yup.string()
    .required('Password is required')
    .test(...noWhitespaces)
    .min(...passwordMinLengthValidation)
    .max(...passwordMaxLengthValidation)
    .matches(...passwordRegexValidation),
  [FORM_CONTROLS.CONFIRM_PASSWORD]: Yup.string()
    .test(...passwordMustMatchValidation)
    .test(...noWhitespaces),
});
