/**
 * Different from NODE_ENV
 *
 * testing | staging | production
 */
const env = process.env.REACT_APP_ENVIRONMENT;

/**
 * Determine if running locally in a dev environment or release build
 */
const isProductionBuild = process.env.NODE_ENV === 'production';

const isProductionEnv = process.env.REACT_APP_ENVIRONMENT === 'production';

const isStagingEnv = process.env.REACT_APP_ENVIRONMENT === 'staging';

const isTestingEnv = process.env.REACT_APP_ENVIRONMENT === 'testing';

export { env, isProductionBuild, isProductionEnv, isStagingEnv, isTestingEnv };
