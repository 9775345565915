import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button, Input } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useFormik } from 'formik';
import { CATEGORY_TYPES } from 'screens/Albums/constants';
import { MODAL_TYPES } from 'screens/Albums/RenameCategoryModal/constants';
import RenameCategoryModal from 'screens/Albums/RenameCategoryModal/RenameCategoryModal';

import {
  companyCategoryCreated,
  companyCategoryDeleted,
} from 'state/slices/companyCategoriesSlice';
import {
  companyRequested,
  defaultCategoriesRequested,
  userCompanyRequested,
} from 'state/slices/companySlice';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import usePermissions from 'helpers/hooks/usePermissions';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import './AccountCompanyInfoCategories.scss';
import { FORM_CONTROLS, initialValues } from './constants';
import { validationSchema } from './validationSchema';

const AccountCompanyInfoCategories = () => {
  const { company, defaultCategories } = useSelector((state) => state.company);
  const { isRequestSuccessful, isCreateRequestPending } = useSelector(
    (state) => state.companyCategories
  );
  const { isScaniflyAdmin } = usePermissions();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const companyCategories = company?.mediaCategories;
  const dispatch = useDispatch();

  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: validationSchema(defaultCategories.concat(companyCategories)),
    onSubmit: ({ categoryName }) => {
      dispatch(
        companyCategoryCreated({
          companyId: company?.id,
          categoryName: categoryName,
        })
      );
      resetForm();
    },
  });

  useEffect(() => {
    if (isRequestSuccessful && company?.id) {
      dispatch(companyRequested(company?.id));
    }
  }, [company?.id, dispatch, isRequestSuccessful]);

  useEffect(() => {
    if (!company) {
      dispatch(userCompanyRequested());
    }
    dispatch(defaultCategoriesRequested());
  }, [company, dispatch]);

  useEffect(() => {
    if (company?.id) {
      dispatch(companyRequested(company?.id));
    }
  }, [company?.id, dispatch]);

  const isFormValid = isValid && dirty;

  const handleDeleteCategory = ({ categoryName }) => {
    confirm({
      title: `Delete Album: ${categoryName}`,
      content: `Are you sure you want to delete ${categoryName}? Any media from ${categoryName} will be moved to Archive album.`,
      okButtonProps: { style: { background: colors.red } },
      okText: 'Delete Album',
      onOk: () => {
        dispatch(
          companyCategoryDeleted({
            companyId: company?.id,
            categoryName: categoryName,
          })
        );
      },
      ...CONFIRM_PROPS,
    });
  };

  const renderCategoriesList = (categoriesList, type) => {
    return categoriesList.map((category) => {
      return (
        <div key={category.id} className="AccountCompanyInfoCategories-CategoriesList-Category">
          <div>
            <CategoryIcon className="AccountCompanyInfoCategories-CategoriesList-Category-CategoryIcon" />
            <span className="AccountCompanyInfoCategories-CategoriesList-Category-Name">
              {category.categoryName}
            </span>
          </div>
          <div>
            {type === CATEGORY_TYPES.COMPANY && (
              <>
                <PencilIcon
                  onClick={() => setSelectedCategory(category)}
                  className="AccountCompanyInfoCategories-CategoriesList-Category-Icon"
                  aria-label="rename album"
                />
                <XIcon
                  onClick={() => handleDeleteCategory(category)}
                  className="AccountCompanyInfoCategories-CategoriesList-Category-XIcon"
                  aria-label="delete album"
                />
              </>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {selectedCategory && (
        <RenameCategoryModal
          categories={defaultCategories.concat(companyCategories)}
          id={company?.id}
          type={MODAL_TYPES.COMPANY}
          isModalVisible={!!selectedCategory}
          handleModalClose={() => setSelectedCategory(null)}
          selectedCategory={selectedCategory}
        />
      )}
      <form onSubmit={handleSubmit}>
        <h2 className="Title">Company Albums</h2>
        <div className="AccountCompanyInfo-Subtitle">
          Add albums to organize media uploads for each project. All teammates will have access to
          these under Albums.
        </div>
        <div className="AccountCompanyInfo-Form-Wrapper">
          <div className="FormControl-Wrapper">
            <label className="Label--Big" htmlFor="addCategory">
              Add Album
            </label>
            <Input
              placeholder="Enter album name"
              className={`Input--High ${validateStatus(
                touched,
                errors,
                FORM_CONTROLS.CATEGORY_NAME
              )}`}
              id="addCategory"
              {...getFieldProps(FORM_CONTROLS.CATEGORY_NAME)}
            />
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.CATEGORY_NAME)}
            </div>
          </div>
          <Button
            onClick={handleSubmit}
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            className="Button--Blue AccountCompanyInfo-Form-Button"
            loading={isCreateRequestPending}
          >
            Add Album
          </Button>
        </div>
        <div className="AccountCompanyInfoCategories-CategoriesList">
          {renderCategoriesList(
            notesAccess
              ? defaultCategories.filter(
                  (category) => category.id !== process.env.REACT_APP_NOTES_CATEGORY_ID
                )
              : defaultCategories,
            CATEGORY_TYPES.DEFAULT
          )}
          {renderCategoriesList(companyCategories || [], CATEGORY_TYPES.COMPANY)}
        </div>
      </form>
    </>
  );
};

export default AccountCompanyInfoCategories;
