import PropTypes from 'prop-types';

import './PDFViewer.scss';

const PDFViewer = ({ imageUrl }) => {
  return (
    <object
      data={imageUrl}
      type="application/pdf"
      width="100%"
      height="100%"
      aria-label="Download PDF"
    />
  );
};

PDFViewer.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default PDFViewer;
