import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

const Content = styled.div`
  text-align: center;
  width: 100%;

  h3 {
    color: ${colors.mainBlue};
    font-size: 1.8rem;
    margin-top: -6vh;
  }

  p {
    color: ${colors.darkGray};
    font-size: ${fontSizes.medium};
  }
`;

export default Content;
