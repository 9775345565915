import ErrorContent from './ModelContent/ErrorContent';
import SuccessContent from './ModelContent/SuccessContent';
import './OrderResponseModal.scss';

const OrderResponseModal = ({
  handleModalClose,
  responseData,
  upgrade,
}: {
  handleModalClose: () => void;
  responseData: any;
  upgrade: boolean;
}) => {
  if (Object.keys(responseData).length) {
    return (
      <SuccessContent
        handleModalClose={handleModalClose}
        responseData={responseData}
        upgrade={upgrade}
      />
    );
  } else {
    return <ErrorContent handleModalClose={handleModalClose} />;
  }
};

export default OrderResponseModal;
