import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import {
  batchUpdateFoldersRequested,
  companyFoldersProjectsRemoved,
  foldersRequested,
  resetIsRequestSuccess,
} from 'state/slices/foldersSlice';

import { CustomSelect, Tag } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import usePermissions from 'helpers/hooks/usePermissions';
import { pluralize } from 'helpers/utils/pluralize';

import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';

import { myProjectsOption, SELECT_OPTION } from './constants';
import './EditFoldersModal.scss';

const EditFoldersModal = ({ isModalVisible, toggleModal, checkedProjects }) => {
  const dispatch = useDispatch();
  const { folders, isRequestSuccessful } = useSelector((state) => state.folders);
  const { projects } = useSelector((state) => state.projects);
  const { currentUser } = useSelector((state) => state.users);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const { isScaniflyAdmin, isAdmin } = usePermissions();

  const getFolderOptions = () => {
    if (currentUser?.foldersAccess === null || isScaniflyAdmin || isAdmin) {
      return myProjectsOption.concat(
        folders.map(({ name, id }) => ({
          label: name,
          value: id,
        }))
      );
    }

    if (currentUser?.foldersAccess?.length > 0) {
      return folders.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    }

    return [];
  };

  const handleFolderSelect = ({ value }, { action }) => {
    action === SELECT_OPTION && setIsOptionSelected(true);
    setSelectedFolderId(value);
  };

  const handleSave = () => {
    toggleModal(false);
    dispatch(
      selectedFolderId
        ? batchUpdateFoldersRequested({ id: selectedFolderId, projectIds: checkedProjects })
        : companyFoldersProjectsRemoved(checkedProjects)
    );
  };

  useEffect(() => {
    dispatch(foldersRequested());
  }, [dispatch]);

  useEffect(() => {
    if (isRequestSuccessful) {
      setSelectedFolderId('');
      dispatch(resetIsRequestSuccess());
    }
  }, [dispatch, isRequestSuccessful]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => toggleModal(false)}
      focusTriggerAfterClose={false}
      title="Edit Project Folders"
      className="EditFoldersModal"
      {...MODAL_PROPS}
    >
      <span className="EditFoldersModal-CommonGroup">
        <span>Common group</span> • {pluralize(checkedProjects.length, 'Project')}
      </span>
      <div className="EditFoldersModal-Tags">
        {projects
          .filter(({ id, folder }) => checkedProjects.includes(id) && folder)
          .map(({ folder }) => folder)
          .filter((folder, i, array) => array.findIndex(({ id }) => id === folder.id) === i)
          .map((folder) => (
            <Tag
              maxTextLength={30}
              color={folder?.color}
              className="EditFoldersModal-Tag"
              key={folder?.id}
            >
              <div className="TagContent">
                <FolderIcon className="FolderIcon" />
                <span className="EditFoldersModal-Tag-Text">{folder?.name}</span>
              </div>
            </Tag>
          ))}
      </div>
      <div className="EditFoldersModal-FolderSelect">
        <CustomSelect
          placeholder="Move selected projects to"
          options={getFolderOptions()}
          onChange={handleFolderSelect}
          isSearchable={false}
          variant="filter"
        />
      </div>
      <div className="EditFoldersModal-FolderTag">
        {Boolean(selectedFolderId) &&
          folders
            .filter(({ id }) => id === selectedFolderId)
            .map(({ color, name, id }) => (
              <Tag maxTextLength={30} color={color} className="EditFoldersModal-Tag" key={id}>
                <div className="TagContent">
                  <FolderIcon className="FolderIcon" />
                  <span className="EditFoldersModal-Tag-Text">{name}</span>
                </div>
              </Tag>
            ))}
      </div>
      <div className="EditFoldersModal-Buttons">
        <Button onClick={() => toggleModal(false)} className="Button--White">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          className="Button--Blue"
          disabled={!isOptionSelected}
          aria-disabled={!isOptionSelected}
          autoFocus={isOptionSelected}
        >
          Move Projects
        </Button>
      </div>
    </Modal>
  );
};

EditFoldersModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  checkedProjects: PropTypes.array.isRequired,
};

export default EditFoldersModal;
