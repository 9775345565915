import { useTranslation } from 'react-i18next';

import { ButtonContainer, Description, Title, TopGrid } from './styled-components';

import { Button } from 'components';
import { FirstSeparator } from 'screens/ChecklistTemplate/Manager/components';

import colors from 'helpers/constants/colors';
import { useLocation } from 'react-router-dom';

type TopSectionProps = {
  isLoading: boolean;
  onImportClick: () => void;
  onNewChecklistClick: () => void;
};

export const TopSection = ({ onNewChecklistClick, isLoading, onImportClick }: TopSectionProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { pathname } = location;
  const isAdmin = pathname.includes('company-info/checklist-admin-manager');

  return (
    <>
      <TopGrid>
        <Title>{t('PageTitles.checklistManager')}</Title>
        <ButtonContainer>
          {isAdmin && (
            <Button
              width="15rem"
              disabled={isLoading}
              onClick={onImportClick}
              color={colors.mainBlue}
              label={t('Checklists.importTemplate')}
            />
          )}
          <Button
            width="15rem"
            disabled={isLoading}
            onClick={onNewChecklistClick}
            color={colors.mainBlue}
            label={t('Checklists.newChecklist')}
          />
        </ButtonContainer>
        <Description>
          {isAdmin
            ? t('Checklists.checklistAdminManagerDescription')
            : t('Checklists.checklistManagerDescription')}
        </Description>
      </TopGrid>
      <FirstSeparator />
    </>
  );
};
