import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  margin: -0.5rem 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

const RadioCircle = styled.span`
  position: relative;
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 0.7rem;
  vertical-align: middle;
  background-color: inherit;
  color: ${colors.mainBlue};
  border: 0.15rem solid ${colors.neutralGray};
  border-radius: 2rem;
`;

const Paragraph = styled.label`
  margin-left: 1rem;
  margin-top: 0.3rem;
  color: ${colors.black};
`;

const RadioContainer = styled.div`
  margin: 2rem 0;
`;

type Props = {
  value: string;
  id: string;
};
const RadioOptions = ({ id, value }: Props) => {
  return (
    <Container>
      <Label id={value}>
        <Input type="radio" id={id} value={value} disabled />
        <RadioCircle />
        <Paragraph>{value}</Paragraph>
      </Label>
    </Container>
  );
};

export { RadioContainer, RadioOptions };
