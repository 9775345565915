import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Row } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import { isEqual } from 'lodash-es';

import { companyUpdated } from 'state/slices/companySlice';

import { CountrySelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import { FORM_CONTROLS } from './constants';
import { validationSchema } from './validationSchema';

const AccountCompanyInfoMain = () => {
  const { company } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const [informationSaved, setInformationSaved] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);

  const initialValues = {
    [FORM_CONTROLS.COMPANY_NAME]: '',
    [FORM_CONTROLS.COUNTRY]: null,
    [FORM_CONTROLS.STREET]: '',
    [FORM_CONTROLS.CITY]: '',
    [FORM_CONTROLS.STATE]: '',
    [FORM_CONTROLS.ZIP_CODE]: '',
  };

  const formatCompanyValues = (company) => {
    return {
      address: {
        line: company?.address.line,
        city: company?.address.city,
        state: company?.address.state,
        zip: company?.address.zip,
        country: company?.address.country,
      },
      name: company?.name,
    };
  };

  const formatCompanyFormValues = (values) => {
    return {
      address: {
        line: values[FORM_CONTROLS.STREET],
        city: values[FORM_CONTROLS.CITY],
        state: values[FORM_CONTROLS.STATE],
        zip: values[FORM_CONTROLS.ZIP_CODE],
        country: values[FORM_CONTROLS.COUNTRY],
      },
      name: values[FORM_CONTROLS.COMPANY_NAME],
    };
  };

  const {
    touched,
    isValid,
    dirty,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        companyUpdated({
          companyId: company.id,
          companyInfo: formatCompanyFormValues(values),
        })
      );
      setIsFormSaved(true);
    },
  });

  const [handleCountryBlur, getCountrySelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.COUNTRY
  );

  useEffect(() => {
    if (company) {
      setFieldValue(FORM_CONTROLS.COMPANY_NAME, company.name);
      setFieldValue(FORM_CONTROLS.COUNTRY, company.address.country);
      setFieldValue(FORM_CONTROLS.STREET, company.address.line);
      setFieldValue(FORM_CONTROLS.CITY, company.address.city);
      setFieldValue(FORM_CONTROLS.STATE, company.address.state);
      setFieldValue(FORM_CONTROLS.ZIP_CODE, company.address.zip);
    }
  }, [setFieldValue, company]);

  useEffect(() => {
    if (isEqual(formatCompanyFormValues(values), formatCompanyValues(company)) && isFormSaved) {
      setInformationSaved(true);
    } else {
      setInformationSaved(false);
    }
  }, [values, company, isFormSaved]);

  const isFormValid = isValid && dirty;
  const isFormDirty = !isEqual(formatCompanyFormValues(values), formatCompanyValues(company));

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Title">Company Name</h2>
      <Row className="AccountCompanyInfo-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountCompanyInfoName">Name</label>
          <Input
            placeholder="Name"
            className={`Input--Long ${validateStatus(touched, errors, FORM_CONTROLS.COMPANY_NAME)}`}
            id="accountCompanyInfoName"
            {...getFieldProps(FORM_CONTROLS.COMPANY_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.COMPANY_NAME)}
          </div>
        </div>
      </Row>
      <h2 className="Title">Company Headquarters Address</h2>
      <Row className="AccountCompanyInfo-Form-Row">
        <CountrySelect
          {...getFieldProps(FORM_CONTROLS.COUNTRY)}
          formControl={FORM_CONTROLS.COUNTRY}
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          onBlur={handleCountryBlur}
          error={getCountrySelectError(values)}
          variant="form"
          aria-label="select country"
        />
        <div className="Form-Error">
          {getCountrySelectError(values) && errors[FORM_CONTROLS.COUNTRY]}
        </div>
      </Row>
      <Row className="AccountCompanyInfo-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountCompanyInfoStreet">Street</label>
          <Input
            placeholder="Street"
            className={`Input--Long ${validateStatus(touched, errors, FORM_CONTROLS.STREET)}`}
            id="accountCompanyInfoStreet"
            {...getFieldProps(FORM_CONTROLS.STREET)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.STREET)}
          </div>
        </div>
      </Row>
      <Row className="AccountCompanyInfo-Form-Row AccountCompanyInfo-Form-Address">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountCompanyInfoCity">City</label>
          <Input
            placeholder="City"
            className={validateStatus(touched, errors, FORM_CONTROLS.CITY)}
            id="accountCompanyInfoCity"
            {...getFieldProps(FORM_CONTROLS.CITY)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CITY)}
          </div>
        </div>
        <div className="FormControl-Wrapper">
          <label htmlFor="accountCompanyInfoState">State</label>
          <Input
            placeholder="State"
            className={`Input--Short ${validateStatus(touched, errors, FORM_CONTROLS.STATE)}`}
            id="accountCompanyInfoState"
            {...getFieldProps(FORM_CONTROLS.STATE)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.STATE)}
          </div>
        </div>
        <div className="FormControl-Wrapper">
          <label htmlFor="accountCompanyInfoZip">ZIP Code</label>
          <Input
            placeholder="ZIP Code"
            className={`Input--Short ${validateStatus(touched, errors, FORM_CONTROLS.ZIP_CODE)}`}
            id="accountCompanyInfoZip"
            {...getFieldProps(FORM_CONTROLS.ZIP_CODE)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.ZIP_CODE)}
          </div>
        </div>
      </Row>

      <div className="AccountCompanyInfo-Button-Wrapper">
        <TickIcon
          className={cn({
            'AccountCompanyInfo-Button-Icon--Hidden': !informationSaved,
          })}
        />
        <Button
          onClick={handleSubmit}
          disabled={!isFormValid || !isFormDirty}
          aria-disabled={!isFormValid || !isFormDirty}
          className="Button--Blue AccountCompanyInfo-Button"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default AccountCompanyInfoMain;
