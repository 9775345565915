import { createSlice } from '@reduxjs/toolkit';

const name = 'localPreferences';

enum Preferences {
  albumView = 'albumView',
}

type LocalPreferencesState = {
  [Preferences.albumView]: 'list' | 'thumbnail';
};

const initialState: LocalPreferencesState = {
  [Preferences.albumView]: 'list',
};

const localPreferencesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setAlbumView: (
      state,
      action: {
        payload: 'list' | 'thumbnail';
      }
    ) => {
      if (action.payload === 'list' || action.payload === 'thumbnail') {
        state[Preferences.albumView] = action.payload;
      }
    },
  },
});

export const selectAlbumView = (state: { localPreferences: LocalPreferencesState }) =>
  state.localPreferences[Preferences.albumView];

export const { setAlbumView } = localPreferencesSlice.actions;

export default localPreferencesSlice.reducer;
