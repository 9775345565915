import styled, { css } from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as DownIcon } from 'assets/down.svg';
import { ReactComponent as UpIcon } from 'assets/up.svg';

type Props = {
  disabled?: boolean;
  upOnClick?: () => void;
  downOnClick?: () => void;
};

const ButtonContainer = styled.div`
  box-shadow:
    0px 0px 1px rgba(9, 30, 66, 0.25),
    0px 0px 0px 1px rgba(9, 30, 66, 0.13);
  overflow: hidden;
  border-radius: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: flex;
`;

const ButtonStyle = styled.button`
  background-color: ${(props) => (props.disabled ? colors.lightGray : colors.white)};
  min-width: 4rem;
  min-height: 3.7rem;
  border: none;
  font-size: .875rem
  line-height: 20px;
  align-items: center;
  font-weight: 500;
  padding: 0;
  zindex: 1000;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'cursor' : 'auto')};
    color: ${colors.mainBlue};
  }
`;

const LeftButton = styled(ButtonStyle)``;

const RightButton = styled(ButtonStyle)``;

const Icon = css`
  margin-top: -4px;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  display: inline-block;
  ${ButtonStyle}: hover & {
    stroke: ${colors.mainBlue};
  }
`;

const StyledUpIcon = styled(UpIcon)`
  ${Icon}
  stroke: ${colors.mainBlue};
  ${LeftButton}: hover & {
    transform: scale(1.2);
    path {
      stroke: ${colors.mainBlue};
    }
  }
`;
const StyledDownIcon = styled(DownIcon)`
  ${Icon}
  ${RightButton}: hover & {
    transform: scale(1.2);
    path {
      stroke: ${colors.mainBlue};
    }
  }
`;

const UpDownButton = ({ disabled, upOnClick, downOnClick }: Props) => {
  return (
    <ButtonContainer>
      <LeftButton disabled={disabled} onClick={upOnClick}>
        <StyledUpIcon />
      </LeftButton>
      <RightButton disabled={disabled} onClick={downOnClick}>
        <StyledDownIcon />
      </RightButton>
    </ButtonContainer>
  );
};

export default UpDownButton;
