import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import Revision from 'types/Revision';
import ServiceRequest from 'types/ServiceRequest';

const ViewRevisionsButton = ({
  item,
  handleViewRevisionsClick,
}: {
  item: ServiceRequest & { project: { id: string; name: string } };
  handleViewRevisionsClick: (
    project: { id: string; name: string },
    modelRevision: Revision | Revision[]
  ) => void;
}) => {
  const { t } = useTranslation();
  const { modelRevision, project } = item;

  return (
    <Button
      className="Button--White"
      onClick={() => (modelRevision ? handleViewRevisionsClick(project, modelRevision) : null)}
      disabled={!modelRevision}
      aria-disabled={!modelRevision}
      data-testid="view-revision-button"
    >
      {t('DesignService.designServiceQueue.viewFormModal.viewRevisions')}
    </Button>
  );
};

export default ViewRevisionsButton;
