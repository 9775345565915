import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Note } from 'types';

import { SLICE_NAMESPACE } from './constants';
import {
  createNote,
  deleteBlankNote,
  deleteNote,
  mentionsUserListRequested,
  noteRequested,
  notesRequested,
  updateNote,
} from './notesThunks';
import { NotesState } from './types';

const initialState: NotesState = {
  notes: [],
  selectedNote: null,
  isLoading: false,
  isNoteLoading: false,
  isRequestPending: false,
  isRequestSuccessful: false,
  mentionsUserList: [],
  isMentionsUserListLoading: false,
  error: null,
};

const notesSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    resetSubmittedForm: (state) => {
      state.isRequestSuccessful = false;
    },
    resetMentionsUserList: (state) => {
      state.mentionsUserList = [];
    },
    setSelectedNote: (state, { payload }: PayloadAction<Note | null>) => {
      state.selectedNote = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(notesRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(notesRequested.fulfilled, (state, { payload }: { payload: Note[] }) => {
        state.notes = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(notesRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isLoading = false;
      })
      .addCase(noteRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(noteRequested.fulfilled, (state, { payload }: { payload: Note }) => {
        state.notes = state.notes.map((note) => {
          if (note.id === payload.id) {
            return payload;
          }
          return note;
        });
        state.selectedNote =
          state.selectedNote?.id === payload.id
            ? {
                ...payload,
                // Local state probably has more up to date text value
                text: state.selectedNote?.text ?? payload.text,
              }
            : state.selectedNote;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(noteRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isLoading = false;
      })
      .addCase(createNote.pending, (state) => {
        state.isRequestPending = true;
        state.isNoteLoading = true;
        state.isRequestSuccessful = false;
      })
      .addCase(createNote.fulfilled, (state, { payload }: { payload: Note }) => {
        state.selectedNote = payload;
        state.error = null;
        state.isRequestPending = false;
        state.isNoteLoading = false;
        state.isRequestSuccessful = true;
      })
      .addCase(createNote.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isNoteLoading = false;
        state.isRequestPending = false;
      })
      .addCase(deleteNote.pending, (state) => {
        state.isRequestPending = true;
        state.isRequestSuccessful = false;
      })
      .addCase(deleteNote.fulfilled, (state) => {
        state.error = null;
        state.isRequestPending = false;
        state.isRequestSuccessful = true;
      })
      .addCase(deleteNote.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isRequestPending = false;
        state.isRequestSuccessful = false;
      })
      .addCase(updateNote.pending, (state) => {
        state.isRequestPending = true;
        state.isNoteLoading = true;
        state.isRequestSuccessful = false;
      })
      .addCase(updateNote.fulfilled, (state) => {
        state.selectedNote = null;
        state.error = null;
        state.isRequestPending = false;
        state.isNoteLoading = false;
        state.isRequestSuccessful = true;
      })
      .addCase(updateNote.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isRequestPending = false;
        state.isNoteLoading = false;
        state.isRequestSuccessful = false;
      })
      .addCase(mentionsUserListRequested.pending, (state) => {
        state.isMentionsUserListLoading = true;
      })
      .addCase(mentionsUserListRequested.fulfilled, (state, { payload }) => {
        state.mentionsUserList = payload;
        state.error = null;
        state.isMentionsUserListLoading = false;
      })
      .addCase(mentionsUserListRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isMentionsUserListLoading = false;
      });
  },
});

const { resetMentionsUserList, resetSubmittedForm, setSelectedNote } = notesSlice.actions;
const notesActions = {
  createNote,
  deleteBlankNote,
  deleteNote,
  mentionsUserListRequested,
  noteRequested,
  notesRequested,
  resetMentionsUserList,
  resetSubmittedForm,
  setSelectedNote,
  updateNote,
};
export { notesActions };

export default notesSlice.reducer;
