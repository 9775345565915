import { mapBoxBaseUrl, mapBoxTokenParam } from './constants';

export default async function reverseGeocode(latitude, longitude) {
  try {
    const response = await fetch(
      `${mapBoxBaseUrl}/${longitude},${latitude}.json${mapBoxTokenParam}`
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
  return null;
}
