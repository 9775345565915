import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DesignServiceStatistics } from 'types/DesignServiceStatistics';

import { fetchDesignServiceStatistics } from 'api/designServices/designServicesService';

const name = 'designServiceStatistics';

type StateType = {
  statisticsData: DesignServiceStatistics | null;
  isLoading: boolean;
  error?: any;
};

export const statisticsRequested = createAsyncThunk(
  `${name}/statisticsRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDesignServiceStatistics();
      return { data: response.data };
    } catch (error: any) {
      return rejectWithValue({ data: error?.response?.data });
    }
  }
);

const designServiceStatisticsSlice = createSlice({
  name,
  initialState: {
    statisticsData: null,
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(statisticsRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(statisticsRequested.fulfilled, (state, { payload }) => {
        state.statisticsData = payload.data;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(statisticsRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export default designServiceStatisticsSlice.reducer;
