import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProjectDesignData } from 'types';

import {
  createDefaultProjectDesign,
  createProjectDesign,
  deleteProjectDesign,
  duplicateProjectDesign,
  fetchProjectDesigns,
  updateProjectDesign,
} from 'api/projectDesigns/projectDesignsService';

const name = 'projectDesigns';

export const projectDesignsRequested = createAsyncThunk(
  `${name}/projectDesignsRequested`,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await fetchProjectDesigns(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectDesignCreated = createAsyncThunk(
  `${name}/projectDesignCreated`,
  async (designData: ProjectDesignData, { rejectWithValue, dispatch }) => {
    try {
      const design = await createProjectDesign(designData);
      dispatch(projectDesignsRequested(designData.projectId));
      return design.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const defaultProjectDesignCreated = createAsyncThunk(
  `${name}/projectDefaultDesignCreated`,
  async (designData: ProjectDesignData, { rejectWithValue, dispatch }) => {
    try {
      const design = await createDefaultProjectDesign(designData);
      dispatch(projectDesignsRequested(designData.projectId));
      return design.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectDesignDeleted = createAsyncThunk(
  `${name}/projectDesignDeleted`,
  async (designData: ProjectDesignData, { rejectWithValue, dispatch }) => {
    try {
      if (designData.id) {
        await deleteProjectDesign(designData.id);
        dispatch(projectDesignsRequested(designData.projectId));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectDesignUpdated = createAsyncThunk(
  `${name}/projectDesignUpdated`,
  async ({ projectId, designId, name }: ProjectDesignData, { rejectWithValue, dispatch }) => {
    try {
      if (designId) {
        await updateProjectDesign(designId, name);
        dispatch(projectDesignsRequested(projectId));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectDesignDuplicated = createAsyncThunk(
  `${name}/projectDesignDuplicated`,
  async (
    { designId, projectId, name }: { designId: string; projectId: string; name: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const design = await duplicateProjectDesign(designId, name);
      dispatch(projectDesignsRequested(projectId));
      return design;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

type ProjectDesignState = {
  projectDesigns: ProjectDesignData[];
  isLoading: boolean;
  isRequestPending: boolean;
  isRequestSuccessful: boolean;
  isCreateDefaultDesignRequestPending: boolean;
  isCreateDefaultDesignRequestSuccessful: boolean;
  error: null | string | undefined;
  id?: string;
  designId?: string;
};

const projectDesignsSlice = createSlice({
  name,
  initialState: {
    projectDesigns: [],
    isLoading: false,
    isRequestPending: false,
    isRequestSuccessful: false,
    isCreateDefaultDesignRequestPending: false,
    isCreateDefaultDesignRequestSuccessful: false,
    error: null,
  } as ProjectDesignState,
  reducers: {
    resetSubmittedForm: (state) => {
      state.isRequestSuccessful = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectDesignsRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(projectDesignsRequested.fulfilled, (state, { payload }) => {
      state.projectDesigns = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(projectDesignsRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(projectDesignCreated.pending, (state) => {
      state.isRequestPending = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignCreated.fulfilled, (state) => {
      state.error = null;
      state.isRequestPending = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(projectDesignCreated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isRequestPending = false;
    });
    builder.addCase(defaultProjectDesignCreated.pending, (state) => {
      state.isCreateDefaultDesignRequestPending = true;
      state.isCreateDefaultDesignRequestSuccessful = false;
    });
    builder.addCase(defaultProjectDesignCreated.fulfilled, (state) => {
      state.error = null;
      state.isCreateDefaultDesignRequestPending = false;
      state.isCreateDefaultDesignRequestSuccessful = true;
    });
    builder.addCase(defaultProjectDesignCreated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isCreateDefaultDesignRequestPending = false;
    });
    builder.addCase(projectDesignDeleted.pending, (state) => {
      state.isRequestPending = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignDeleted.fulfilled, (state) => {
      state.error = null;
      state.isRequestPending = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(projectDesignDeleted.rejected, (state, { error }) => {
      state.error = error.message;
      state.isRequestPending = false;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignUpdated.pending, (state) => {
      state.isRequestPending = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignUpdated.fulfilled, (state) => {
      state.error = null;
      state.isRequestPending = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(projectDesignUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isRequestPending = false;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignDuplicated.pending, (state) => {
      state.isRequestPending = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(projectDesignDuplicated.fulfilled, (state) => {
      state.error = null;
      state.isRequestPending = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(projectDesignDuplicated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isRequestPending = false;
      state.isRequestSuccessful = false;
    });
  },
});

export const { resetSubmittedForm } = projectDesignsSlice.actions;

export default projectDesignsSlice.reducer;
