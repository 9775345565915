import { ReactNode } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 82vh;
`;

type Props = {
  children: ReactNode | ReactNode[];
};

export const ContentRow = ({ children }: Props) => {
  return <Wrapper id="contentRow">{children}</Wrapper>;
};
