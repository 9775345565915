import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Input, Row } from 'antd';
import { FieldConfig, FieldInputProps, FormikErrors, FormikTouched, FormikValues } from 'formik';

import { RootState } from 'state/store/store';

import { CustomSelect, Divider } from 'components';

import { renderValidationMessage } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

const AccountingScaniflyInfoForm = ({
  getFieldProps,
  setFieldValue,
  touched,
  errors,
}: {
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
}) => {
  const { scaniflyAdminList } = useSelector((state: RootState) => state.adminUsers);
  const { t } = useTranslation();

  return (
    <div className="Accounting-Form-ScaniflyInfo">
      <h2 className="Title">{t('AccountingForms.scaniflyInfo')}</h2>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingOwner">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.scaniflySalesRep')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.OWNER)}
            </div>
          </label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.OWNER)}
            onChange={(option: { value: string; label: string }) => {
              setFieldValue(FORM_CONTROLS.OWNER, option.value);
            }}
            placeholder={t('AccountingForms.selectSalesRep')}
            options={scaniflyAdminList.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            variant="filter"
            id="accountingOwner"
            tall
          />
        </div>
      </Row>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingAccountManager">
            {t('AccountingForms.scaniflyCSAccountManager')}
          </label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.ACCOUNT_MANAGER)}
            onChange={(option: { value: string; label: string }) => {
              setFieldValue(FORM_CONTROLS.ACCOUNT_MANAGER, option.value);
            }}
            placeholder={t('AccountingForms.selectAccountManager')}
            options={scaniflyAdminList.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            variant="filter"
            id="accountingAccountManager"
            tall
          />
        </div>
      </Row>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountingContract">
            <span className="Accounting-Form-Asterisk">* </span>
            {t('AccountingForms.contractLink')}
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.CONTRACT)}
            </div>
          </label>
          <Input
            {...getFieldProps(FORM_CONTROLS.CONTRACT)}
            placeholder="hubspot.com/contract-link"
            id="accountingContract"
          />
        </div>
      </Row>
      <Divider />
    </div>
  );
};

export default AccountingScaniflyInfoForm;
