import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import cn from 'classnames';
import { selectAdminsAndSlice } from 'screens/Sidebar/helpers';
import { User } from 'types';

import { companySubscriptionInfoRequested, userCompanyRequested } from 'state/slices/companySlice';
import { AppDispatch, RootState } from 'state/store/store';

import { PermissionGatedButton, SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { accountPaymentsInvoicesRoute } from 'helpers/constants/routes';
import { SUPPORT_URL } from 'helpers/constants/urls';
import usePermissions from 'helpers/hooks/usePermissions';
import { numberFormatter } from 'helpers/utils/numberFormatter';

import { ReactComponent as HelpIcon } from 'assets/help-icon.svg';
import { ReactComponent as RefreshIcon } from 'assets/refresh-icon.svg';
import { ReactComponent as CreditsIcon } from 'assets/sidebar-credits.svg';

import MenuItem from '../MenuItem';
import './SidebarFooter.scss';

type SidebarFooterProps = {
  isSidebarCollapsed: boolean;
  className: string;
};

const SidebarFooter = ({ isSidebarCollapsed, className }: SidebarFooterProps) => {
  const { isDesignServiceProvider, hasBillingPermissions, isSimpleDesignOrSalesManager } =
    usePermissions();
  const { company, companySubscriptionInfo, isSubscriptionInfoLoading, subscriptionRefreshDate } =
    useSelector((state: RootState) => state.company);
  const dispatch: AppDispatch = useDispatch();
  const adminUsers: User[] =
    company && company?.members ? selectAdminsAndSlice(company.members, 5) : [];

  useEffect(() => {
    if (!company) {
      dispatch(userCompanyRequested());
    }
  }, [company, dispatch]);

  const handleConfirm = () => {
    confirm({
      title: 'No Billing Access',
      content: (
        <>
          <div>Please contact your admin to access the billing tab.</div>
          {adminUsers.length
            ? adminUsers.map(({ firstName, lastName, email }) => (
                <div className="Sidebar-Confirm-Wrapper" key={`admin-${email}`}>
                  <div className="Sidebar-Confirm-Name">{`${firstName} ${lastName}`}:</div>
                  <div className="Sidebar-Confirm-Email">
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="send email"
                    >
                      {`${email}`}
                    </a>
                  </div>
                </div>
              ))
            : null}
        </>
      ),
      cancelButtonProps: { style: { display: 'none' } },
      className: 'Sidebar-Confirm',
      okText: 'Close',
      ...CONFIRM_PROPS,
    });
  };

  const handleRefresh = () => {
    if (company && !isDesignServiceProvider) {
      dispatch(companySubscriptionInfoRequested(company.id));
    }
  };

  return (
    <div className={cn('Sidebar-Footer-Wrapper', className)}>
      {!isDesignServiceProvider || !isSimpleDesignOrSalesManager ? (
        <section>
          <PermissionGatedButton action={handleConfirm} permission={hasBillingPermissions}>
            <MenuItem
              key="billing"
              icon={
                <span className="anticon anticon--Credits" role="img" aria-label="credits">
                  <CreditsIcon />
                </span>
              }
              className="Billing"
              isCollapsed={isSidebarCollapsed}
              link={hasBillingPermissions ? `${accountPaymentsInvoicesRoute()}#credits` : undefined}
              aria-label="billings and credits tab"
              title="Credits"
            >
              <div className="Sidebar-CreditsWrapper">
                <div className="Sidebar-Title">Credits</div>
                {/* Note: This is to display remaining credits */}
                <SidebarTag
                  text={
                    companySubscriptionInfo
                      ? numberFormatter(companySubscriptionInfo?.credits?.amount) ?? 0
                      : 0
                  }
                  type={TagTypes.score}
                />
              </div>
            </MenuItem>
          </PermissionGatedButton>
          <section
            className={cn('Sidebar-RefreshWrapper', {
              'Sidebar-NoDisplay': isSidebarCollapsed,
            })}
          >
            <Tooltip placement="right" title="Click to Refresh">
              <RefreshIcon
                onClick={() => {
                  handleRefresh();
                }}
                className={cn('Sidebar-RefreshIcon', {
                  'Sidebar-RefreshIcon--Spinning': isSubscriptionInfoLoading,
                })}
              />
            </Tooltip>
            <aside className="Sidebar-InfoWrapper">
              <p>Last updated:</p>
              <p>{subscriptionRefreshDate ?? '--'}</p>
            </aside>
          </section>
        </section>
      ) : null}
      <MenuItem
        key="help"
        icon={
          <span className="anticon anticon--Help" role="img" aria-label="help">
            <HelpIcon />
          </span>
        }
        className={cn('Help', { 'Help--Collapsed': isSidebarCollapsed })}
        isCollapsed={isSidebarCollapsed}
        link={SUPPORT_URL}
        linkIsExternal
        aria-label="scanifly support docs"
        title="Help"
      >
        Help?
      </MenuItem>
    </div>
  );
};

export default SidebarFooter;
