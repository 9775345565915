import {
  DESIGN_STATUS,
  DESIGN_STATUS_FILTERS,
  DESIGN_TIER_LEVEL_FILTERS,
  mapSystemSizeValueToType,
  MODE,
  PROJECT_STATUS,
  PROJECT_TYPES,
  SYSTEM_SIZE,
  SYSTEM_SIZE_FILTERS,
} from '@cpm/scanifly-shared-data';
import { ServiceRequest } from '@cpm/scanifly-shared-data/lib/util/types';
import moment from 'moment';
import Revision from 'types/Revision';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { csvDateFormat } from 'helpers/utils/dateFormat';
import { getFormattedTimeDifferenceInMins } from 'helpers/utils/getFormattedTimeDifference';
import { i18n } from 'helpers/utils/translations';

interface ServiceRequestData extends ServiceRequest {
  id: string;
  company?: {
    id?: string;
  };
  companyName?: string;
  createdAt: string;
  updatedAt: string;
  form?: { systemSize?: SYSTEM_SIZE; newBuildPlansRequired?: boolean };
  project: {
    id?: string;
    mode?: MODE;
    name?: string;
    status?: PROJECT_STATUS;
    type?: PROJECT_TYPES;
  };
  projectDesign?: {
    id?: string;
  };
  status?: DESIGN_STATUS;
  userInfo?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  systemSize?: SYSTEM_SIZE;
  upgradedFrom?: string;
  modelRevision?: Revision | Revision[];
  modelRevisionCount?: string;
  designServiceProviderName?: string;
  designServiceProviderId?: string;
  completedDate?: Date;
  designStartDate?: Date;
  isExpedited?: boolean;
}

export const handleDesignServiceQueueCSVExport = ({
  dataSource,
}: {
  dataSource: ServiceRequestData[];
}) => {
  return dataSource.map((item) => {
    const {
      status: id,
      status,
      tierLevel,
      createdAt,
      project,
      companyName,
      systemSize,
      upgradedFrom,
      modelRevision,
      modelRevisionCount,
      designServiceProviderName,
    } = item || {};

    const systemSizeModified =
      !systemSize && item?.form?.systemSize
        ? mapSystemSizeValueToType(item?.form?.systemSize ?? '')
        : systemSize ?? '-';

    const modifyRevisionReasons = (modelRevision?: Revision | Revision[]) => {
      if (modelRevision) {
        let lastRevisionRequest: Revision;
        if (Array.isArray(modelRevision)) {
          lastRevisionRequest = modelRevision[modelRevision.length - 1];
        } else {
          lastRevisionRequest = modelRevision;
        }
        const modifiedInput = `${lastRevisionRequest?.reasons?.join('\n')}${
          lastRevisionRequest?.note ? '\n' + lastRevisionRequest?.note : ''
        }`;

        if (modifiedInput.length > 0) {
          return modifiedInput;
        }
        return 'No reasons have been selected';
      }
      return '--';
    };

    return {
      ...item,
      projectName: project ? project.name : '',
      status: status
        ? DESIGN_STATUS_FILTERS.find((designStatus) => designStatus.value === status)?.text
        : '--',
      tierLevel: tierLevel
        ? `${DESIGN_TIER_LEVEL_FILTERS.find((status) => status.value === tierLevel)?.text}${
            upgradedFrom ? ' ' + i18n.t('DesignService.uploadQueue.upgradeTag') : ''
          }`
        : '--',
      createdAt: createdAt ? moment(createdAt).format(csvDateFormat) : '--',
      companyName: companyName ?? '--',
      systemSize:
        SYSTEM_SIZE_FILTERS.find((size) => size.value === systemSizeModified)?.text ?? '--',
      projectType: project
        ? PROJECT_TYPE_FILTERS.find((type) => type.value === project.type)?.text
        : '--',
      orderId: id ?? '--',
      modelRevisionCount: modelRevisionCount ?? '--',
      modelRevision: modifyRevisionReasons(modelRevision),
      designServiceProviderName: designServiceProviderName ?? '--',
      completedDate: item?.completedDate
        ? getFormattedTimeDifferenceInMins(new Date(item.completedDate), new Date(item.createdAt))
        : '--',
      designStartDate:
        item?.designStartDate && item?.completedDate
          ? getFormattedTimeDifferenceInMins(
              new Date(item.designStartDate),
              new Date(item.completedDate)
            )
          : '--',
      isExpedited: `${item?.isExpedited ? i18n.t('Generic.yes') : i18n.t('Generic.no')}`,
      newBuildPlansRequired: `${item?.form?.newBuildPlansRequired ? i18n.t('Generic.yes') : i18n.t('Generic.no')}`,
    };
  });
};
