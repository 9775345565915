import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';

export const FORM_CONTROLS = {
  FIRE_SETBACKS: 'fireSetbacks',
  OBSTRUCTION_SETBACKS: 'obstructionSetbacks',
  ATTACHMENT: 'attachment',
  RACKING: 'racking',
  MODULE_INFO: 'moduleInfo',
  INVERTER_INFO: 'inverterInfo',
  SIZED_FOR_CATEGORY: 'sizedForCategory',
  NEGATIVE_TOLERANCE: 'negativeTolerance',
  POSITIVE_TOLERANCE: 'positiveTolerance',
} as const;

export const initialValues = {
  [FORM_CONTROLS.FIRE_SETBACKS]: {
    ridge: '',
    eave: '',
    rake: '',
    valley: '',
    hip: '',
  },
  [FORM_CONTROLS.OBSTRUCTION_SETBACKS]: {
    vents: '',
    exhaust: '',
    skylights: '',
    parapet: '',
    general: '',
  },
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: SIZED_FOR_CATEGORIES.production,
  [FORM_CONTROLS.NEGATIVE_TOLERANCE]: -3,
  [FORM_CONTROLS.POSITIVE_TOLERANCE]: 10,
};
