import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Project } from 'types';

import {
  adminFetchAllProjects,
  adminFetchCompanyProjects,
  adminFetchScaniflyProjects,
  adminFetchUserProjects,
} from 'api/admin/adminService';
import { fetchCompany } from 'api/company/companyService';
import { updateProject } from 'api/projects/projectsService';
import { fetchUser } from 'api/users/usersService';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';

const name = 'adminProjects';

type AdminState = {
  allProjects: Project[];
  projectCount: number;
  isProjectsLoading: boolean;
  isAllProjectsFetchError: null | string | undefined;
  allScaniflyProjects: Project[];
  isAllScaniflyProjectsLoading: boolean;
  isAllScaniflyProjectsFetchError: null | string | undefined;
  totalScaniflyProjects: number;
  allCustomerProjects: Project[];
  isAllCompanyProjectsFetchError: null | string | undefined;
  allUserProjects: Project[];
  isAllUserProjectsFetchError: null | string | undefined;
  totalAllProjects: number;
  totalAllCompanyProjects: number;
  totalAllUserProjects: number;
  updatedProject: null | Project;
  statusUpdateError: null | string | undefined;
  statusUpdateSuccess: null | string;
  projectUpdateError: null | string | undefined;
  projectUpdateSuccess: null | string;
  totalCompanyProjects: undefined | number;
  totalUserProjects: undefined | number;
  pageIndex: Number;
  filterOptions: object;
  companyName: string;
  userName: string;
  error: null | string | undefined;
};

export const adminProjectsRequested = createAsyncThunk(
  `${name}/projectsRequested`,
  async (
    {
      size = PROJECT_LIST_SIZE,
      pageIndex = 1,
      sortBy = {
        createdAt: 'desc',
      },
      filterBy = undefined,
    }: { size: number; pageIndex: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminFetchAllProjects(size, pageIndex, sortBy, filterBy);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminScaniflyProjectsRequested = createAsyncThunk(
  `${name}/scaniflyProjectsRequested`,
  async (
    {
      size = PROJECT_LIST_SIZE,
      pageIndex = 1,
      sortBy = {
        createdAt: 'desc',
      },
      filterBy = undefined,
    }: { size: number; pageIndex: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminFetchScaniflyProjects(size, pageIndex, sortBy, filterBy);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyProjectsRequested = createAsyncThunk(
  `${name}/companyProjectsRequested`,
  async (
    {
      companyId,
      size = PROJECT_LIST_SIZE,
      pageIndex = 1,
      sortBy = {
        createdAt: 'desc',
      },
      filterBy = undefined,
    }: { companyId: string; size: number; pageIndex: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminFetchCompanyProjects(
        companyId,
        size,
        pageIndex,
        sortBy,
        filterBy
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUserProjectsRequested = createAsyncThunk(
  `${name}/userProjectsRequested`,
  async (
    {
      userId,
      size = PROJECT_LIST_SIZE,
      pageIndex = 1,
      sortBy = {
        createdAt: 'desc',
      },
      filterBy = undefined,
    }: { userId: string; size: number; pageIndex: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminFetchUserProjects(userId, size, pageIndex, sortBy, filterBy);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminProjectStatusUpdateRequested = createAsyncThunk(
  `${name}/projectStatusUpdateRequested`,
  async (
    {
      projectId,
      targetStatus,
      tableName,
      onSuccess,
    }: {
      projectId: string;
      targetStatus: string;
      tableName: string;
      onSuccess?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateProject(projectId, { status: targetStatus });
      if (onSuccess) {
        onSuccess();
      }
      return { ...response.data, tableName };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyNameRequested = createAsyncThunk(
  `${name}/companyNameRequested`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompany(companyId);
      return response?.data?.name ?? '';
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUserNameRequested = createAsyncThunk(
  `${name}/userNameRequested`,
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await fetchUser(userId);
      if (!response.data) return '';
      return `${response.data.firstName} ${response.data.lastName}`;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminProjectsSlice = createSlice({
  name,
  initialState: {
    allProjects: [],
    projectCount: 0,
    isProjectsLoading: false,
    isAllProjectsFetchError: null,
    allScaniflyProjects: [],
    isAllScaniflyProjectsLoading: false,
    isAllScaniflyProjectsFetchError: null,
    totalScaniflyProjects: 0,
    allCustomerProjects: [],
    isAllCompanyProjectsFetchError: null,
    allUserProjects: [],
    isAllUserProjectsFetchError: null,
    totalAllProjects: 0,
    totalAllCompanyProjects: 0,
    totalCompanyProjects: undefined,
    totalUserProjects: undefined,
    totalAllUserProjects: 0,
    updatedProject: null,
    statusUpdateError: null,
    statusUpdateSuccess: null,
    projectUpdateError: null,
    projectUpdateSuccess: null,
    pageIndex: 1,
    filterOptions: {},
    companyName: '',
    userName: '',
    error: null,
  } as AdminState,
  reducers: {
    resetStatusUpdateFlags: (state) => {
      state.statusUpdateError = null;
      state.statusUpdateSuccess = null;
    },
    resetUserUpdateFlags: (state) => {
      state.projectUpdateError = null;
      state.projectUpdateSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminProjectsRequested.pending, (state) => {
      state.isProjectsLoading = true;
      state.projectCount = 0;
    });
    builder.addCase(adminProjectsRequested.fulfilled, (state, { payload }) => {
      state.allProjects = payload.items;
      state.pageIndex = payload.pageIndex;
      state.projectCount = payload.totalCount;
      state.isAllProjectsFetchError = null;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminProjectsRequested.rejected, (state, { error }) => {
      state.isAllProjectsFetchError = error.message;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminScaniflyProjectsRequested.pending, (state) => {
      state.isAllScaniflyProjectsLoading = true;
    });
    builder.addCase(adminScaniflyProjectsRequested.fulfilled, (state, { payload }) => {
      state.allScaniflyProjects = payload.items;
      state.pageIndex = payload.pageIndex;
      state.totalScaniflyProjects = payload.totalCount;
      state.isAllScaniflyProjectsFetchError = null;
      state.isAllScaniflyProjectsLoading = false;
    });
    builder.addCase(adminScaniflyProjectsRequested.rejected, (state, { error }) => {
      state.isAllScaniflyProjectsFetchError = error.message;
      state.isAllScaniflyProjectsLoading = false;
    });
    builder.addCase(adminCompanyProjectsRequested.pending, (state) => {
      state.isProjectsLoading = true;
    });
    builder.addCase(adminCompanyProjectsRequested.fulfilled, (state, { payload }) => {
      state.allCustomerProjects = payload.items;
      state.totalCompanyProjects = payload.totalCount;
      state.isAllCompanyProjectsFetchError = null;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminCompanyProjectsRequested.rejected, (state, { error }) => {
      state.isAllCompanyProjectsFetchError = error.message;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminUserProjectsRequested.pending, (state) => {
      state.isProjectsLoading = true;
    });
    builder.addCase(adminUserProjectsRequested.fulfilled, (state, { payload }) => {
      state.allUserProjects = payload.items;
      state.totalUserProjects = payload.totalCount;
      state.isAllUserProjectsFetchError = null;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminUserProjectsRequested.rejected, (state, { error }) => {
      state.isAllUserProjectsFetchError = error.message;
      state.isProjectsLoading = false;
    });
    builder.addCase(adminProjectStatusUpdateRequested.fulfilled, (state, { payload }) => {
      state.updatedProject = payload;
      const { tableName } = payload || {};

      if (tableName === TABLE_NAMES.UPLOAD_QUEUE) {
        state.allScaniflyProjects = [
          ...state.allScaniflyProjects.filter((project) => project.id !== payload.id),
          payload,
        ];
      } else if (tableName === TABLE_NAMES.PROJECTS) {
        state.allProjects = [
          ...state.allProjects.filter((project) => project.id !== payload.id),
          payload,
        ];
      } else if (tableName === TABLE_NAMES.CUSTOMER_PROJECTS) {
        state.allCustomerProjects = [
          ...state.allCustomerProjects.filter((project) => project.id !== payload.id),
          payload,
        ];
      }

      if (payload.status === ADMIN_PROJECT_STATUSES.DELETED) {
        state.statusUpdateSuccess = `${payload.name} has been successfully deleted.`;
      } else if (payload.status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE) {
        state.statusUpdateSuccess = `${payload.name} has been successfully published.`;
      } else if (payload.status === ADMIN_PROJECT_STATUSES.REPLACED) {
        state.statusUpdateSuccess = `The original project model data is successfully replaced with "${payload.name}" project.`;
      } else {
        state.statusUpdateSuccess = `${payload.name} has been successfully updated.`;
      }
    });
    builder.addCase(adminProjectStatusUpdateRequested.rejected, (state, { error }) => {
      state.statusUpdateError = error.message;
      state.statusUpdateSuccess = null;
    });
    builder.addCase(adminCompanyNameRequested.fulfilled, (state, { payload }) => {
      state.companyName = payload ?? '';
      state.error = null;
    });
    builder.addCase(adminCompanyNameRequested.rejected, (state, { error }) => {
      state.companyName = '';
      state.error = error.message;
    });
    builder.addCase(adminUserNameRequested.fulfilled, (state, { payload }) => {
      state.userName = payload ?? '';
      state.error = null;
    });
    builder.addCase(adminUserNameRequested.rejected, (state, { error }) => {
      state.userName = '';
      state.error = error.message;
    });
  },
});

export const { resetStatusUpdateFlags, resetUserUpdateFlags } = adminProjectsSlice.actions;

export default adminProjectsSlice.reducer;
