import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Invoice from 'types/Invoice';

import {
  adminCreateInvoice,
  adminRefreshInvoiceStatuses,
  adminUpdateInvoice,
} from 'api/admin/adminService';

import { createInvoiceSliceErrorMessage } from 'helpers/utils/createInvoiceSliceErrorMessage';
import { openNotification } from 'helpers/utils/openNotification';

import { updateCompaniesWithAccountingInfo } from './adminCompaniesSlice';

const name = 'adminInvoices';

export const invoiceCreationRequested = createAsyncThunk(
  `${name}/invoiceCreationRequested`,
  async (
    { companyId, month, year }: { companyId: string; month: number; year: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await adminCreateInvoice(companyId, month, year);
      openNotification({
        type: 'success',
        title: 'Success!',
        text: 'Invoice creation was successful!',
      });
      dispatch(invoiceUpdateRequested({ companyId, month, year }));
      return response.data;
    } catch (error: any) {
      openNotification({
        type: 'error',
        title: 'Error!',
        text: createInvoiceSliceErrorMessage(error),
      });
      return rejectWithValue(error.response.data);
    }
  }
);

export const invoiceUpdateRequested = createAsyncThunk(
  `${name}/invoiceUpdateRequested`,
  async (
    { companyId, month, year }: { companyId: string; month: number; year: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await adminUpdateInvoice(companyId, month, year);
      dispatch(updateCompaniesWithAccountingInfo([response.data]));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const invoiceStatusRefreshRequested = createAsyncThunk(
  `${name}/invoiceStatusRefreshRequested`,
  async (
    { month, year, withNotification }: { month: number; year: number; withNotification?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await adminRefreshInvoiceStatuses(month, year);
      if (withNotification) {
        openNotification({
          type: 'success',
          title: 'Success!',
          text: 'Invoice status refresh was successful!',
        });
      }
      dispatch(updateCompaniesWithAccountingInfo(response.data));
      return response.data;
    } catch (error: any) {
      console.error(error);
      openNotification({
        type: 'error',
        title: 'Error!',
        text: "Something has gone wrong and we couldn't refresh the invoice statuses.",
      });
      return rejectWithValue(error.response.data);
    }
  }
);

type InvoiceState = {
  newInvoice: Invoice | null;
  isInvoiceCreationLoading: boolean;
  invoiceCreationError: null | string | undefined;
  isInvoiceRefreshLoading: boolean;
};

const adminInvoicesSlice = createSlice({
  name,
  initialState: {
    newInvoice: null,
    isInvoiceCreationLoading: false,
    invoiceCreationError: null,
    isInvoiceRefreshLoading: false,
  } as InvoiceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(invoiceCreationRequested.pending, (state) => {
      state.isInvoiceCreationLoading = true;
    });
    builder.addCase(invoiceCreationRequested.fulfilled, (state, { payload }) => {
      state.newInvoice = payload;
      state.invoiceCreationError = null;
      state.isInvoiceCreationLoading = false;
    });
    builder.addCase(invoiceCreationRequested.rejected, (state, { error }: { error: any }) => {
      state.invoiceCreationError = error.message;
      state.newInvoice = null;
      state.isInvoiceCreationLoading = false;
    });
    builder.addCase(invoiceStatusRefreshRequested.pending, (state) => {
      state.isInvoiceRefreshLoading = true;
    });
    builder.addCase(invoiceStatusRefreshRequested.fulfilled, (state) => {
      state.isInvoiceRefreshLoading = false;
    });
    builder.addCase(invoiceStatusRefreshRequested.rejected, (state) => {
      state.isInvoiceRefreshLoading = false;
    });
  },
});

export default adminInvoicesSlice.reducer;
