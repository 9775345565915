import { ReactNode } from 'react';

import styled from 'styled-components';

// 1090px is the smallest the current screen can support, we need to consider how this
// screen should act on smaller screens before removing this min-width value
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: top;
  margin: 0 3rem;
  min-width: 1090px;
  @media screen and (max-width: 768px) {
    left: 0;
    margin: 0;
  }
`;

type Props = {
  children: ReactNode | ReactNode[];
};

export const ScreenWrapper = ({ children }: Props) => {
  return <Wrapper id="checklistScreenWrapper">{children}</Wrapper>;
};
