import { Note, User } from 'types';

import { RootState } from 'state/store/store';

const selectNotes = (state: RootState): Note[] => state.notes.notes;

const selectSelectedNote = (state: RootState): Note | null => state.notes.selectedNote;

const selectIsLoading = (state: RootState): boolean => state.notes.isLoading;

const selectIsNoteLoading = (state: RootState): boolean => state.notes.isNoteLoading;

const selectIsRequestPending = (state: RootState): boolean => state.notes.isRequestPending;

const selectMentionsUserList = (state: RootState): User[] => state.notes.mentionsUserList;

export {
  selectIsLoading,
  selectIsNoteLoading,
  selectIsRequestPending,
  selectMentionsUserList,
  selectNotes,
  selectSelectedNote,
};
