import { NotApplicableFieldType } from '@cpm/scanifly-shared-data';

import { BooleanAnswer, FieldType, Label, Seperator } from './components';

type Props = {
  item: NotApplicableFieldType;
};

export const NotApplicable = ({ item }: Props) => {
  return (
    <>
      <FieldType type={item.componentType} />
      <Label text={item.label} />
      <Seperator />
      <BooleanAnswer answer={item.value} />
    </>
  );
};
