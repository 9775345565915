import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import styled from 'styled-components';

import { ModalFooterProps } from './types';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .Button {
    margin-right: 1rem;
  }
  .Button--Blue {
    margin-left: 1rem;
  }
`;
function ModalFooter({ isLoading, onIgnore, onRemove }: ModalFooterProps) {
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <Button
        className="Button"
        data-testid={`distant-photo-ignore-btn`}
        disabled={isLoading}
        onClick={onIgnore}
      >
        {t('DistantPhotoReview.ignore')}
      </Button>
      <Button
        className="Button--Blue"
        data-testid={`distant-photo-remove-btn`}
        disabled={isLoading}
        loading={isLoading}
        onClick={onRemove}
      >
        {t('DistantPhotoReview.remove')}
      </Button>
    </StyledDiv>
  );
}

export default ModalFooter;
