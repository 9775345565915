const EmailLink = ({ emailAddress = 'hello@scanifly.com' }: { emailAddress?: string }) => {
  const mailTo = `mailto:${emailAddress}`;
  return (
    <a href={mailTo} target="_blank" rel="noreferrer" aria-label="send email">
      {emailAddress}
    </a>
  );
};

export default EmailLink;
