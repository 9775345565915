const SignUpTermsOfService = () => {
  return (
    <div className="SignUp-Footer-TermsOfService">
      By signing up, I agree to the Scanifly{' '}
      <a href="https://scanifly.com/terms" target="_blank" rel="noreferrer">
        Privacy Policy and Terms of Service
      </a>
      .
    </div>
  );
};

export default SignUpTermsOfService;
