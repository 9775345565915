import { CREDIT_CARD_BRAND } from 'helpers/constants/creditCardBrand';

import { ReactComponent as Amex } from 'assets/icons/cards/amex.svg';
import { ReactComponent as DinersClub } from 'assets/icons/cards/diners-club.svg';
import { ReactComponent as Discover } from 'assets/icons/cards/discover.svg';
import JCB from 'assets/icons/cards/jcb.png';
import { ReactComponent as MasterCard } from 'assets/icons/cards/mastercard.svg';
import { ReactComponent as UnionPay } from 'assets/icons/cards/unionpay.svg';
import UnknownCreditCard from 'assets/icons/cards/unknown-card.png';
import Visa from 'assets/icons/cards/visa.png';

export const renderCardLogo = (companyBillingInfo) => {
  switch (companyBillingInfo?.brand) {
    case CREDIT_CARD_BRAND.VISA:
      return <img className="AccountPayments-CardInfo-Brand" alt="Visa" src={Visa} />;
    case CREDIT_CARD_BRAND.JCB:
      return <img className="AccountPayments-CardInfo-Brand" alt="JCB" src={JCB} />;
    case CREDIT_CARD_BRAND.DINERS_CLUB:
      return <DinersClub className="AccountPayments-CardInfo-Brand" />;
    case CREDIT_CARD_BRAND.DISCOVER:
      return <Discover className="AccountPayments-CardInfo-Brand" />;
    case CREDIT_CARD_BRAND.MASTERCARD:
      return <MasterCard className="AccountPayments-CardInfo-Brand" />;
    case CREDIT_CARD_BRAND.UNIONPAY:
      return <UnionPay className="AccountPayments-CardInfo-Brand" />;
    case CREDIT_CARD_BRAND.AMERICAN_EXPRESS:
      return <Amex className="AccountPayments-CardInfo-Brand" />;
    default:
      return (
        <img className="AccountPayments-CardInfo-Brand" alt="Card brand" src={UnknownCreditCard} />
      );
  }
};
