import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CarouselRef } from 'antd/lib/carousel';

type MediaCarouselContextProps = {
  activeIndex: number | undefined;
  mediaCarouselRef: RefObject<CarouselRef> | null;
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>;
};

type MediaCarouselProviderProps = {
  children: ReactNode;
};

const mediaCarouselContextProps: MediaCarouselContextProps = {
  activeIndex: undefined,
  mediaCarouselRef: null,
  setActiveIndex: () => {},
};

export const MediaCarouselContext =
  createContext<MediaCarouselContextProps>(mediaCarouselContextProps);

export function MediaCarouselProvider({ children }: MediaCarouselProviderProps) {
  const mediaCarouselRef = useRef<CarouselRef>(null);

  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  const value = useMemo(
    () => ({
      activeIndex,
      mediaCarouselRef,
      setActiveIndex,
    }),
    [activeIndex]
  );

  return <MediaCarouselContext.Provider value={value}>{children}</MediaCarouselContext.Provider>;
}

MediaCarouselProvider.Context = MediaCarouselContext;
