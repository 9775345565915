import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  ChecklistTemplateStatusEnum,
  ChecklistTemplateType,
  ChecklistTemplateTypeEnum,
} from '@cpm/scanifly-shared-data';
import { isDefaultTemplate } from 'screens/ChecklistTemplate/utils';
import { v4 as uuidv4 } from 'uuid';

import {
  copyChecklistTemplate,
  createAndStoreChecklistTemplate,
  deleteChecklistTemplateById,
  getChecklistTemplateById,
  importAndStoreSiteCaptureTemplate,
  updateChecklistTemplateById,
} from 'state/slices/checklists';
import { AppDispatch } from 'state/store/store';

import { ErrorDisplay, ModalContext, Tooltip } from 'components';
import { TemplateListCard, TemplateTitle } from '../components';
import {
  EditLink,
  IconButton,
  ItemGrid,
  StyledCopyIcon,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledEyeIcon,
  StyledPublishIcon,
  StyledUnpublishIcon,
  TopSection,
} from './components';

import ChecklistTemplateSubtypeEnum from '@cpm/scanifly-shared-data/lib/commonTypes/checklists/ChecklistTemplateSubtypeEnum';
import colors from 'helpers/constants/colors';
import { accountCompanyChecklistTemplateEditorRoute } from 'helpers/constants/routes';
import { openNotification } from 'helpers/utils/openNotification';

type Props = {
  selectedCompanyId?: string;
  templates: ChecklistTemplateType[];
  isAdmin?: boolean;
};

export const CustomTemplateListCard = ({ selectedCompanyId, templates, isAdmin }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { displayInputModal, displayImportModal } = useContext(ModalContext);

  const onNewChecklistSubmit = useCallback(
    async (input: string) => {
      const newTemplate = {
        title: input,
        type: ChecklistTemplateTypeEnum.custom,
        status: ChecklistTemplateStatusEnum.unpublished,
        checklistStructure: [
          {
            id: uuidv4(),
            title: 'Placeholder Section',
            components: [],
          },
        ],
        subtype: ChecklistTemplateSubtypeEnum.OPERATIONS,
      };
      setIsLoading(true);
      await dispatch<any>(createAndStoreChecklistTemplate(newTemplate, selectedCompanyId));
      setIsLoading(false);
    },
    [dispatch, selectedCompanyId]
  );
  const onNewChecklistClick = useCallback(() => {
    displayInputModal({
      title: t('Checklists.newChecklist'),
      description: t('Checklists.newChecklistDescription'),
      placeholderText: t('Checklists.newChecklistInputPlaceholder') ?? undefined,
      actionButtonOnClick: onNewChecklistSubmit,
      actionButtonLabel: t('Checklists.createChecklist'),
      onCancel: () => setIsLoading(false),
    });
  }, [displayInputModal, t, onNewChecklistSubmit]);

  const onImportSubmit = useCallback(
    async (input: string) => {
      setIsLoading(true);
      await dispatch<any>(importAndStoreSiteCaptureTemplate(input, selectedCompanyId));
      setIsLoading(false);
    },
    [dispatch, selectedCompanyId]
  );

  const onImportClick = useCallback(() => {
    displayImportModal({
      title: t('Checklists.importTemplate'),
      description: t('Checklists.importDescription'),
      actionButtonOnClick: onImportSubmit,
      actionButtonLabel: t('Checklists.importTemplate'),
      onCancel: () => setIsLoading(false),
    });
  }, [displayImportModal, t, onImportSubmit]);

  const onChecklistCopyClick = useCallback(
    (template: ChecklistTemplateType) => {
      const onCopyChecklistSubmit = async (input: string) => {
        setIsLoading(true);
        await dispatch<any>(
          copyChecklistTemplate({ ...template, title: input }, selectedCompanyId)
        );
        setIsLoading(false);
      };
      displayInputModal({
        title: t('Checklists.copyChecklist'),
        description: t('Checklists.copyChecklistDescription', { title: template.title }),
        placeholderText:
          t('Checklists.copyChecklistCopyOf', { title: template.title }) ?? undefined,
        defaultValue: t('Checklists.copyChecklistCopyOf', { title: template.title }) ?? undefined,
        actionButtonOnClick: onCopyChecklistSubmit,
        actionButtonLabel: t('Checklists.copyChecklist'),
      });
    },
    [displayInputModal, t, dispatch, selectedCompanyId]
  );

  const onDeleteClick = useCallback(
    (template: ChecklistTemplateType) => {
      const { title, id } = template;
      displayInputModal({
        title: t('Checklists.deleteChecklist'),
        subtitle: title,
        description: isAdmin
          ? t('Checklists.adminDeleteDescription')
          : t('Checklists.deleteDescription'),
        instruction: t('Checklists.deleteInstruction'),
        confirmValue: title.trim(),
        actionButtonOnClick: async () => {
          await dispatch<any>(deleteChecklistTemplateById(id));
        },
        actionButtonLabel: t('Checklists.deleteChecklist'),
        actionButtonColor: colors.red,
      });
    },
    [displayInputModal, dispatch, t, isAdmin]
  );

  const onPublishUnpublishClick = useCallback(
    async (template: Omit<ChecklistTemplateType, 'checklistStructure'>) => {
      const { id, status, title } = template;
      const publishUnpublishActionText =
        status === ChecklistTemplateStatusEnum.published
          ? t('ChecklistTemplate.unpublish')
          : t('ChecklistTemplate.publish');
      const publishUnpublishButtonColor =
        status === ChecklistTemplateStatusEnum.published ? colors.red : colors.mainBlue;

      displayInputModal({
        title:
          status === ChecklistTemplateStatusEnum.published
            ? t('ChecklistTemplate.unpublishChecklist', { title })
            : t('ChecklistTemplate.publishChecklist', { title }),
        description:
          status === ChecklistTemplateStatusEnum.published
            ? t('ChecklistTemplate.areYouSureUnpublish')
            : t('ChecklistTemplate.areYouSurePublish'),
        confirmValue: title.trim(),
        actionButtonOnClick: async () => {
          setIsLoading(true);
          try {
            const populated = await dispatch(getChecklistTemplateById(id));
            await dispatch<any>(
              updateChecklistTemplateById(id, {
                ...template,
                checklistStructure: populated.checklistStructure,
                status:
                  status === ChecklistTemplateStatusEnum.published
                    ? ChecklistTemplateStatusEnum.unpublished
                    : ChecklistTemplateStatusEnum.published,
              })
            );
            openNotification({
              type: 'success',
              title: t('alertMessages.successTitle'),
              text: (
                <div>
                  {status === ChecklistTemplateStatusEnum.published
                    ? t('ChecklistTemplate.unpublishSuccess')
                    : t('ChecklistTemplate.publishSuccess')}
                </div>
              ),
            });
          } catch (e: any) {
            openNotification({
              type: 'error',
              title: t('alertMessages.errorTitle'),
              text: (
                <div>
                  {status === ChecklistTemplateStatusEnum.published
                    ? t('ChecklistTemplate.unpublishFail')
                    : t('ChecklistTemplate.publishFail')}
                  <ErrorDisplay error={e} />
                </div>
              ),
            });
          } finally {
            setIsLoading(false);
          }
        },
        actionButtonLabel: publishUnpublishActionText,
        actionButtonColor: publishUnpublishButtonColor,
      });
    },
    [dispatch, displayInputModal, t]
  );

  const templatesList = useMemo(
    () =>
      Array.isArray(templates) &&
      templates.map((template) => (
        <ItemGrid key={template.id}>
          <TemplateTitle>
            {template.title.trim() !== '' ? template.title : `${t('Checklists.templateTitle')}`}
          </TemplateTitle>
          <Tooltip
            title={
              isDefaultTemplate(template.type)
                ? (t('Checklists.viewTemplate') as string)
                : (t('Checklists.edit') as string)
            }
          >
            <EditLink
              to={accountCompanyChecklistTemplateEditorRoute(template.id)}
              id="editChecklistLink"
            >
              {isDefaultTemplate(template.type) ? <StyledEyeIcon /> : <StyledEditIcon />}
            </EditLink>
          </Tooltip>
          <IconButton
            icon={<StyledCopyIcon />}
            tooltip={t('Checklists.copy') as string}
            onClick={() => onChecklistCopyClick(template)}
          />
          <IconButton
            disabled={isDefaultTemplate(template.type)}
            icon={
              template.status === ChecklistTemplateStatusEnum.published ? (
                <StyledPublishIcon />
              ) : (
                <StyledUnpublishIcon />
              )
            }
            onClick={() => onPublishUnpublishClick(template)}
            tooltip={
              template.status === ChecklistTemplateStatusEnum.published
                ? t('Checklists.published')
                : t('Checklists.unpublished')
            }
          />
          <IconButton
            disabled={isDefaultTemplate(template.type)}
            icon={<StyledDeleteIcon />}
            onClick={() => onDeleteClick(template)}
            tooltip={t('Checklists.delete') as string}
          />
        </ItemGrid>
      )),
    [templates, onDeleteClick, onChecklistCopyClick, onPublishUnpublishClick, t]
  );

  return (
    <TemplateListCard id="custom-template-card">
      <TopSection
        isLoading={isLoading}
        onNewChecklistClick={onNewChecklistClick}
        onImportClick={onImportClick}
      />
      {templatesList}
    </TemplateListCard>
  );
};
