export const PROJECT_STATUSES = {
  DRAFT: 'Draft',
  REMOTE: 'Remote',
  NO_FLIGHT: 'No Flight',
  UPLOADING: 'Uploading',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  ERROR: 'Error',
  /**
   * Images are uploaded but error when submitting to Decimator
   * Resubmission may fix.
   */
  SUBMISSION_ERROR: 'Submission Error',
  DELETED: 'Deleted',
  PUBLISHED: 'Published',
  REPLACED: 'Replaced',
  MAINTENANCE: 'Maintenance',
};

export const ADMIN_PROJECT_STATUSES = {
  DRONE_IMAGES_UPLOADED: 'adminDroneImagesUploaded',
  LOCATION_SUBMITTED: 'adminLocationSubmitted',
  ERROR: 'adminUploadError',
  PROCESSED: 'adminUploadComplete',
  PROCESSING: 'adminUploadProcessing',
  PUBLISHED: 'uploadComplete',
  DELETED: 'adminDeclined',
  REPROCESSING: 'adminReprocessing',
  REPLACED: 'adminReplaced',
};

export const NON_ADMIN_PROJECT_STATUSES = {
  LOCATION_SUBMITTED: 'locationSubmitted',
  DRONE_IMAGES_UPLOADING: 'droneImagesUploading',
  DRONE_IMAGES_UPLOADED: 'droneImagesUploaded',
  UPLOAD_PROCESSING: 'uploadProcessing',
  UPLOAD_ERROR: 'uploadError',
  UPLOAD_COMPLETE: 'uploadComplete',
  MAINTENANCE: 'maintenance',
  REMOTE: 'remoteDesignStarted',
  NO_FLIGHT: 'noFlight',
};

export const ADMIN_PROJECTS_TABLES_PROJECT_STATUSES = [
  {
    value: NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
    text: `${PROJECT_STATUSES.DRAFT} (Drone Images Uploaded)`,
  },
  {
    value: NON_ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
    text: `${PROJECT_STATUSES.DRAFT} (Location Submitted)`,
  },
  {
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING,
    text: PROJECT_STATUSES.PROCESSING,
  },
  {
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
    text: PROJECT_STATUSES.PROCESSED,
  },
  {
    value: NON_ADMIN_PROJECT_STATUSES.ERROR,
    text: PROJECT_STATUSES.ERROR,
  },
];

export const UPLOAD_QUEUE_PROJECT_STATUSES = [
  {
    value: ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
    text: `${PROJECT_STATUSES.DRAFT} (Drone Images Uploaded)`,
  },
  {
    value: ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
    text: `${PROJECT_STATUSES.DRAFT} (Location Submitted)`,
  },
  {
    value: ADMIN_PROJECT_STATUSES.PROCESSING,
    text: PROJECT_STATUSES.PROCESSING,
  },
  {
    value: ADMIN_PROJECT_STATUSES.PROCESSED,
    text: PROJECT_STATUSES.PROCESSED,
  },
  {
    value: ADMIN_PROJECT_STATUSES.ERROR,
    text: PROJECT_STATUSES.ERROR,
  },
  {
    value: ADMIN_PROJECT_STATUSES.REPLACED,
    text: PROJECT_STATUSES.REPLACED,
  },
];
