import styled from 'styled-components';

const Container = styled.div`
  .defaultSize {
    width: 100% !important;
    height: 52vh !important;
    padding-top: 0 !important;
  }

  .video-react {
    &-big-play-button {
      background-color: $dark-gray !important;
      left: calc(50% - 1.5em) !important;
      top: calc(50% - 0.75em) !important;
    }

    &-control {
      font-family: $fontFamily;
      background-color: rgba($dark-gray, 0.5) !important;
      margin-top: 0 !important;
    }

    &-time {
      &-divider {
        display: none;
      }
    }
  }
`;

export default Container;
