import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import styled from 'styled-components';

export const Subtitle = styled.div`
  color: ${colors.labelGray};
  font-size: ${fontSizes.mediumSmall};
  margin: 0;
  max-width: 25rem;

  @media only screen and (max-width: ${breakpoints.md}) {
    visibility: hidden;
  }
`;
