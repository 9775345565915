import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UppyFile } from '@uppy/core';
import MediaItem from 'types/MediaItem';

const QUERY_PARAM = 'preview';

type Props = {
  mediaItems: Array<MediaItem | UppyFile>;
  isMediaLoading: boolean;
};

export const usePreviewSelect = ({ mediaItems, isMediaLoading }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const [selected, setSelected] = useState<MediaItem | UppyFile | null>(null);

  const setQuery = useCallback(
    (id: string) => {
      queryParams.set(QUERY_PARAM, id);
      const newParams = queryParams.toString();
      history.replace({
        ...location,
        search: newParams,
      });
    },
    [queryParams, history, location]
  );

  const deleteQuery = useCallback(() => {
    queryParams.delete(QUERY_PARAM);
    const newParams = queryParams.toString();
    history.replace({
      ...location,
      search: newParams,
    });
  }, [queryParams, history, location]);

  const setSelectedImage = useCallback(
    (item: MediaItem | UppyFile | null) => {
      if (item === null) {
        deleteQuery();
        setSelected(null);
      } else {
        setQuery(item.id);
        setSelected(item);
      }
    },
    [deleteQuery, setQuery]
  );

  useEffect(() => {
    if (!isMediaLoading) {
      const currentQuery = queryParams.get(QUERY_PARAM);
      if (currentQuery && selected?.id !== currentQuery) {
        const newSelected = mediaItems.find((item) => item.id === currentQuery);
        if (newSelected) {
          setSelectedImage(newSelected);
        } else {
          setSelectedImage(null);
        }
      }
    }
  }, [mediaItems, isMediaLoading, setSelectedImage, selected, queryParams]);

  return {
    selectedImage: selected,
    setSelectedImage,
  };
};
