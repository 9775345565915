import { mapCountryCodeToName } from 'helpers/utils/mapCountryNameToCode';

export const isSame = (oldValues, newValues) => {
  return (
    newValues?.name === oldValues?.customerName &&
    newValues?.email === oldValues?.customerEmail &&
    (newValues?.country === mapCountryCodeToName(oldValues?.customerAddress?.country) ||
      newValues?.country === oldValues?.customerAddress?.country) &&
    newValues?.city === oldValues?.customerAddress?.city &&
    newValues?.street === oldValues?.customerAddress?.line &&
    newValues?.state === oldValues?.customerAddress?.state &&
    newValues?.zipCode === oldValues?.customerAddress?.zip &&
    newValues?.vatNumber === oldValues?.vatNumber
  );
};
