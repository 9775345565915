import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { deleteNearmapsByProjectId } from 'api/nearmaps/nearmapsService';

const name = 'nearmaps';

type StateType = {
  isLoading: boolean;
  error?: any;
};

export const deleteNearmapsRequested = createAsyncThunk(
  `${name}/deleteNearmapsRequested`,
  async (
    {
      projectId,
      onSuccess,
      onError,
    }: {
      projectId: string;
      onSuccess: (projectId: string) => void;
      onError: (projectId: string, errorMessage: string) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteNearmapsByProjectId({
        projectId,
      });
      onSuccess(projectId);
      return { data: response.data };
    } catch (error: any) {
      onError(projectId, error?.response?.data?.message);
      return rejectWithValue({ data: error?.response?.data });
    }
  }
);

const designServiceRequestsSlice = createSlice({
  name,
  initialState: {
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteNearmapsRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNearmapsRequested.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(deleteNearmapsRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export default designServiceRequestsSlice.reducer;
