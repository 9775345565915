import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { projectRequested } from 'state/slices/projectSlice';

import { getConfigS3DV3 } from 'api/config/configService';

import { s3dV2, s3dV3 } from 'helpers/constants/routes';

// select v2/v3 of S3D using project owner's LD flag for scanifly admins and design service providers
const S3DVSelect = () => {
  const { projectId, designId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);

  useEffect(() => {
    if (!project || project?.id !== projectId) {
      if (projectId) {
        dispatch(projectRequested(projectId));
      }
    }
  }, [dispatch, project, projectId]);

  useEffect(() => {
    async function checkFlag(userId) {
      if (!userId) {
        history.replace(s3dV3(projectId, designId));
        return;
      }
      const response = await getConfigS3DV3(userId);
      const url = response.data.useV3 ? s3dV3(projectId, designId) : s3dV2(projectId, designId);
      history.replace(url); // `replace` over `push` to avoid infinite loop on back button navigation from design
    }

    if (project && projectId && designId && designId !== 'undefined') {
      checkFlag(project.createdById);
    }
  }, [history, project, projectId, designId]);
};

export { S3DVSelect };
