import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PERMISSION, ROLE } from '@cpm/scanifly-shared-data';
import { Button, Input } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { adminFoldersRequested, foldersRequested } from 'state/slices/foldersSlice';

import { CustomSelect } from 'components';

import { ACCESS } from 'helpers/constants';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import usePermissions from 'helpers/hooks/usePermissions';
import { teammatePropTypes } from 'helpers/props/teammate';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { handlePermissionsChange, handleProjectAccessChange } from './helpers/handleChange';
import { inviteNewUser } from './helpers/inviteNewUser';
import { permissionsOptions, projectAccessOptions } from './helpers/options';
import {
  createDefaultPermissions,
  createTeammatesInitialPermissions,
} from './helpers/setPermissions';
import { updatePermissions } from './helpers/updatePermissions';

import MultiCheck from '../MultiCheck';
import { FORM_CONTROLS, OPTIONS } from './constants';
import './InviteOrChangeTeammatesPermissions.scss';
import { validationSchema } from './validationSchema';

const InviteOrChangeTeammatesPermissions = ({
  teammate,
  handleModalClose,
  modalClosedFromOutside,
  setModalClosedFromOutside,
  isAddTeammate,
  isEditModal,
  companyId,
  companyName,
}) => {
  const isEdit = Boolean(teammate);
  const { isScaniflyAdmin } = usePermissions();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { ADMIN_TOOLS, ALL_PROJECTS } = OPTIONS;
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [currentProjectAccess, setCurrentProjectAccess] = useState([]);
  const { folders } = useSelector((state) => state.folders);
  const { companiesNotPopulated } = useSelector((state) => state.adminCompanies);
  const isSimpleDesignFeatureEnabled = useFeatureToggle(ACCESS.SIMPLE_DESIGN);
  const initialValues = {
    [FORM_CONTROLS.EMAIL]: isEdit ? teammate.email : '',
    [FORM_CONTROLS.COMPANY_ID]: companyId || '',
  };
  const { currentUser } = useSelector((state) => state.users);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const allPermissionsButSimpleDesign = (({ SIMPLEDESIGN, SALESMANAGER, ...rest }) => rest)(
    PERMISSION
  );

  const allPermissions = useMemo(
    () => [ADMIN_TOOLS.value, ...Object.values(allPermissionsButSimpleDesign)],
    [ADMIN_TOOLS, allPermissionsButSimpleDesign]
  );

  const allProjects = useMemo(
    () => [ALL_PROJECTS.value, ...folders.map((folder) => folder.id)],
    [ALL_PROJECTS, folders]
  );

  const options = companiesNotPopulated.map((company) => {
    return { value: company.id, label: company.name, id: company.value };
  });

  const isAdminAddingTeammate =
    isScaniflyAdmin && isAddTeammate && pathname === '/scanifly-admin/user-manager' && !isEditModal;

  const { touched, isValid, errors, getFieldProps, setFieldValue, values, handleBlur, resetForm } =
    useFormik({
      initialValues,
      validationSchema: validationSchema(isAdminAddingTeammate),
    });

  const [handleCompanyBlur, getCompanySelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.COMPANY_ID
  );

  const isFormValid = isValid && values.email;

  useEffect(() => {
    if (isScaniflyAdmin && companyId) {
      dispatch(adminFoldersRequested(companyId));
    } else {
      dispatch(foldersRequested());
    }
  }, [dispatch, isScaniflyAdmin, companyId]);

  useEffect(() => {
    if (modalClosedFromOutside) {
      setModalClosedFromOutside(false);

      if (teammate) {
        createTeammatesInitialPermissions({
          teammate,
          setCurrentPermissions,
          setCurrentProjectAccess,
          allProjects,
        });
      } else {
        resetForm();
        createDefaultPermissions({ setCurrentPermissions, setCurrentProjectAccess, allProjects });
      }
    }
  }, [resetForm, modalClosedFromOutside, setModalClosedFromOutside, teammate, allProjects]);

  useEffect(() => {
    if (teammate) {
      createTeammatesInitialPermissions({
        teammate,
        setCurrentPermissions,
        setCurrentProjectAccess,
        allProjects,
      });
    } else {
      createDefaultPermissions({ setCurrentPermissions, setCurrentProjectAccess, allProjects });
    }
  }, [allProjects, teammate]);

  const onUpdatePermissionsOrSendInvite = useCallback(() => {
    const projectAccess = {
      folderIds: currentProjectAccess.includes(ALL_PROJECTS.value) ? null : currentProjectAccess,
    };

    const isAdminPromotion =
      !teammate?.roles.includes(ROLE.admin) && currentPermissions.includes(ADMIN_TOOLS.value);

    const isAdminDemotion =
      teammate?.roles.includes(ROLE.admin) && !currentPermissions.includes(ADMIN_TOOLS.value);

    const selectedPermissionsWithoutAdminTools = currentPermissions.filter(
      (val) => val !== ADMIN_TOOLS.value
    );

    if (isEdit) {
      updatePermissions({
        dispatch,
        teammate,
        companyId,
        selectedPermissionsWithoutAdminTools,
        projectAccess,
        isScaniflyAdmin,
        isAdminPromotion,
        isAdminDemotion,
      });
    } else {
      inviteNewUser({
        dispatch,
        companiesNotPopulated,
        values,
        currentPermissions,
        selectedPermissionsWithoutAdminTools,
        projectAccess,
      });
    }

    handleModalClose();
  }, [
    isEdit,
    dispatch,
    teammate,
    companyId,
    currentPermissions,
    currentProjectAccess,
    isScaniflyAdmin,
    handleModalClose,
    companiesNotPopulated,
    values,
    ADMIN_TOOLS.value,
    ALL_PROJECTS.value,
  ]);

  return (
    <div className="InviteOrChangeTeammatesPermissions-Wrapper">
      <div className="InviteOrChangeTeammatesPermissions-Title">
        {isEdit
          ? `Edit Teammate Permissions: ${teammate.firstName} ${teammate.lastName}`
          : `Add Teammate To ${companyName ? companyName : 'Company Account'}`}
      </div>
      {isAdminAddingTeammate ? (
        <div className="InviteOrChangeTeammatesPermissions-Select-Wrapper">
          <label htmlFor="inviteCustomer">Customer</label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.COMPANY_ID)}
            onChange={(option) => {
              setFieldValue(FORM_CONTROLS.COMPANY_ID, option.value);
              dispatch(adminFoldersRequested(option.value));
            }}
            onBlur={handleCompanyBlur}
            error={getCompanySelectError(values)}
            placeholder="Select Customer..."
            options={options}
            id="inviteCustomer"
          />
          <div className="Form-Error">
            {getCompanySelectError(values) && 'Customer is required'}
          </div>
        </div>
      ) : (
        ''
      )}
      {!isEditModal ? (
        <div className="InviteOrChangeTeammatesPermissions-Input-Wrapper">
          <label htmlFor="email">Email</label>
          <Input
            placeholder="name@company.com"
            disabled={isEdit || (isAdminAddingTeammate && !values.companyId)}
            aria-disabled={isEdit || (isAdminAddingTeammate && !values.companyId)}
            className={`InviteOrChangeTeammatesPermissions-Input ${validateStatus(
              touched,
              errors,
              FORM_CONTROLS.EMAIL
            )}`}
            id="email"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
          </div>
        </div>
      ) : null}

      <div className="InviteOrChangeTeammatesPermissions-Subtitle">
        Project Access
        <Button
          className="Button--Unstyled"
          onClick={() => setCurrentProjectAccess([])}
          data-testid="clear-teammate-permissions"
        >
          <p className="InviteOrChangeTeammatesPermissions-Clear">Clear</p>
        </Button>
      </div>
      <MultiCheck
        options={projectAccessOptions({ folders, isAdminAddingTeammate, values })}
        onChange={(value) =>
          handleProjectAccessChange({
            values: value,
            currentPermissions,
            currentProjectAccess,
            setCurrentProjectAccess,
            setCurrentPermissions,
            allProjects,
          })
        }
        value={currentProjectAccess}
        isFolders={true}
      />

      <div className="InviteOrChangeTeammatesPermissions-Subtitle">Permissions</div>
      <MultiCheck
        options={permissionsOptions({ values, currentUser, isSimpleDesignFeatureEnabled })}
        onChange={(value) =>
          handlePermissionsChange({
            values: value,
            currentPermissions,
            allPermissions,
            setCurrentPermissions,
            setCurrentProjectAccess,
            allProjects,
          })
        }
        value={currentPermissions}
      />
      <div className="InviteOrChangeTeammatesPermissions-Buttons-Wrapper">
        <Button
          className="InviteOrChangeTeammatesPermissions-Button--Link"
          onClick={handleModalClose}
          id="close-modal"
        >
          Cancel
        </Button>
        <Button
          disabled={!isFormValid}
          aria-disabled={!isFormValid}
          className="Button--Blue InviteOrChangeTeammatesPermissions-Button--Blue"
          onClick={onUpdatePermissionsOrSendInvite}
          autoFocus={isFormValid}
          id={isEdit || isEditModal ? `update-${teammate.id}` : 'invite-user'}
        >
          {isEdit || isEditModal ? 'Update' : 'Send invite'}
        </Button>
      </div>
    </div>
  );
};

InviteOrChangeTeammatesPermissions.propTypes = {
  teammate: teammatePropTypes,
  handleModalClose: PropTypes.func.isRequired,
  modalClosedFromOutside: PropTypes.bool.isRequired,
  setModalClosedFromOutside: PropTypes.func.isRequired,
  isAddTeammate: PropTypes.bool,
  isEditModal: PropTypes.bool,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
};

export default InviteOrChangeTeammatesPermissions;
