import { ReactComponent as VideoIcon } from 'assets/camera.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/uppy-cancel.svg';

import './UploadThumbnail.scss';

const UploadThumbnail = ({
  handleDelete,
  isVideo,
  src,
  onClick,
}: {
  handleDelete?: () => void;
  isVideo: boolean;
  src: string;
  onClick?: () => void;
}) => {
  return (
    <span>
      {isVideo ? (
        <VideoIcon className="UploadThumbnail" />
      ) : (
        // eslint-disable-next-line
        <img onClick={onClick} role="button" src={src} alt="preview" className="UploadThumbnail" />
      )}
      {handleDelete ? (
        <button
          onClick={handleDelete}
          className="UploadThumbnail-Delete"
          type="button"
          aria-label="Remove file"
        >
          <CancelIcon />
        </button>
      ) : null}
    </span>
  );
};

export default UploadThumbnail;
