import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Row } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';

import {
  loginCredentialsUpdated,
  resetLoginCredentialsUpdateSuccess,
} from 'state/slices/usersSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import './AccountLoginSecurityCredentials.scss';
import { FORM_CONTROLS } from './constants';
import { FormValues, initialValues, validationSchema } from './validationSchema';

const { Password } = Input;

export default function AccountLoginSecurityCredentials() {
  const dispatch: AppDispatch = useDispatch();

  const { currentUser, loginCredentialsUpdateError, loginCredentialsUpdateSuccess } = useSelector(
    (state: RootState) => state.users
  );

  const [emailChanged, setEmailChanged] = useState(false);

  const {
    touched,
    isValid,
    errors,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    resetForm,
    values,
  } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const params = {
        password: values.password || null,
        email: emailChanged ? values.email : null,
      };
      dispatch(loginCredentialsUpdated(params));
    },
  });

  useEffect(() => {
    if (loginCredentialsUpdateSuccess) {
      const values: FormValues = {
        ...initialValues,
        email: currentUser?.email || '',
      };
      resetForm({ values });
      dispatch(resetLoginCredentialsUpdateSuccess());
    }
  }, [
    dispatch,
    currentUser?.email,
    resetForm,
    loginCredentialsUpdateSuccess,
    values,
    setFieldValue,
  ]);

  useEffect(() => {
    if (loginCredentialsUpdateError) {
      const values: FormValues = {
        ...initialValues,
        email: currentUser?.email || '',
      };
      resetForm({ values });
    }
  }, [loginCredentialsUpdateError, resetForm, currentUser]);

  useEffect(() => {
    if (values?.email !== currentUser?.email) {
      setEmailChanged(true);
      setFieldValue(FORM_CONTROLS.showConfirmEmail, true);
    } else {
      setEmailChanged(false);
      setFieldValue(FORM_CONTROLS.showConfirmEmail, false);
    }
  }, [currentUser?.email, values?.email, setFieldValue]);

  useEffect(() => {
    if (currentUser?.email) {
      setFieldValue(FORM_CONTROLS.email, currentUser.email);
    }
  }, [setFieldValue, currentUser]);

  const isDirty = !emailChanged ? !!values.password : true;

  const isFormValid = isValid && isDirty;

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="Title">Login Credentials</h2>
      <label htmlFor="accountLoginSecurityCredentialsEmail">Enter Your Email</label>
      <Row className="AccountLoginSecurityCredentials-Form-Row AccountLoginSecurityCredentials-Form-Row-Email">
        <Input
          placeholder="Enter your email address"
          type={FORM_CONTROLS.email}
          id="accountLoginSecurityCredentialsEmail"
          {...getFieldProps(FORM_CONTROLS.email)}
        />
        <div className="Form-Error AccountLoginSecurityCredentials-Form-Error-Email">
          {renderValidationMessage(touched, errors, FORM_CONTROLS.email)}
        </div>
      </Row>

      {values.showConfirmEmail && (
        <>
          <label htmlFor="accountLoginSecurityCredentialsEmailConfirm">Confirm Your Email</label>
          <Row className="AccountLoginSecurityCredentials-Form-Row AccountLoginSecurityCredentials-Form-Row-Email">
            <Input
              placeholder="Confirm your email address"
              type={FORM_CONTROLS.confirmEmail}
              id="accountLoginSecurityCredentialsEmailConfirm"
              {...getFieldProps(FORM_CONTROLS.confirmEmail)}
            />
            <div className="Form-Error AccountLoginSecurityCredentials-Form-Error-Email">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.confirmEmail)}
            </div>
          </Row>
        </>
      )}

      <h2 className="Title">Change Password</h2>
      <Row className="AccountLoginSecurityCredentials-Form-Row AccountLoginSecurityCredentials-Form-Row-Password">
        <div className="FormControl-Wrapper AccountLoginSecurityCredentials-Form-Wrapper">
          <label htmlFor="accountLoginSecurityCredentialsNewPassword">Enter New Password</label>
          <Password
            placeholder="Enter New Password"
            className={validateStatus(touched, errors, FORM_CONTROLS.password)}
            id="accountLoginSecurityCredentialsNewPassword"
            {...getFieldProps(FORM_CONTROLS.password)}
          />
          <div className="Form-Error Form-Error--Medium AccountLoginSecurityCredentials-Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.password)}
          </div>
        </div>

        <div className="FormControl-Wrapper AccountLoginSecurityCredentials-Form-Wrapper">
          <label htmlFor="accountLoginSecurityCredentialsNewPasswordConfirm">
            Re-enter New Password
          </label>
          <Password
            placeholder="Confirm New Password"
            className={validateStatus(touched, errors, FORM_CONTROLS.confirmPassword)}
            id="accountLoginSecurityCredentialsNewPasswordConfirm"
            {...getFieldProps(FORM_CONTROLS.confirmPassword)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.confirmPassword)}
          </div>
        </div>
      </Row>

      <div className="AccountLoginSecurityCredentials-Button-Wrapper">
        <TickIcon
          className={cn({
            'AccountLoginSecurityCredentials-Button-Icon--Hidden':
              isDirty || loginCredentialsUpdateError,
          })}
        />
        <Button
          // @ts-ignore ant-d non-sense
          onClick={handleSubmit}
          disabled={!isFormValid || !isDirty}
          aria-disabled={!isFormValid || !isDirty}
          className="Button--Blue AccountLoginSecurityCredentials-Button"
        >
          Save
        </Button>
      </div>
    </form>
  );
}
