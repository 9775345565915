import { Dispatch } from 'redux';

import {
  deleteExampleMedia as deleteExampleMediaApi,
  fetchExampleMediaByTemplateId,
} from 'api/exampleMedia';

import { deleteExampleMediaAction, getExampleMediaAction } from './exampleMediaSlice';

export const getExampleMediaByTemplate = (templateId: string) => async (dispatch: Dispatch) => {
  try {
    const response = await fetchExampleMediaByTemplateId(templateId);
    if (response.data) {
      dispatch(
        getExampleMediaAction({
          media: response.data,
          templateId,
        } as any)
      );
    }
    return response.data;
  } catch (error) {
    console.error('Failed to fetch example media by template ID', error);
    throw error;
  }
};

export const deleteExampleMedia =
  ({ mediaId, templateId }: { mediaId: string; templateId: string }) =>
  async (dispatch: Dispatch) => {
    const response = await deleteExampleMediaApi(mediaId);
    if (response.status === 200) {
      dispatch(deleteExampleMediaAction({ mediaId, templateId } as any));
    }
    return response;
  };
