import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Message } from 'yup';

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 128;
const PASSWORD_REGEX = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/);
const NUMBERS_AND_PERIOD_REGEX = new RegExp(/([0-9])|\.|,/);
const SPECIAL_CHAR_REGEX = new RegExp(/([~`!&@#$%^()_={}/\\[\]:;,.<>+?-])/);

export const validateStatus = (
  touched: FormikTouched<FormikValues>,
  errors: FormikErrors<FormikValues>,
  name: string,
  dirty = false
): string | undefined => {
  return (
    dirty ? errors[name] && 'Form-Input-Error' : touched[name] && errors[name] && 'Form-Input-Error'
  ) as string | undefined;
};

export const validateNestedStatus = (
  touched: FormikTouched<FormikValues>,
  errors: FormikErrors<FormikValues>,
  name: string,
  subFieldName: string,
  dirty = false
) => {
  return dirty
    ? // @ts-ignore
      errors?.[name]?.[subFieldName] && 'Form-Input-Error'
    : // @ts-ignore
      touched[name]?.[subFieldName] && errors[name]?.[subFieldName] && 'Form-Input-Error';
};

/**
 * idk wth this is supposed to return
 */
export const renderValidationMessage = (
  touched: FormikTouched<FormikValues>,
  errors: FormikErrors<FormikValues>,
  name: string,
  dirty = false
): any => {
  return (dirty ? errors[name] : touched[name] ? errors[name] : '') as any;
};

export const renderNestedValidationMessage = (
  touched: FormikTouched<FormikValues>,
  errors: FormikErrors<FormikValues>,
  name: string,
  subField: string,
  dirty = false
) => {
  // @ts-ignore ain't nobody got time for this
  return dirty ? errors[name]?.[subField] : touched[name]?.[subField] && errors[name]?.[subField];
};

export const passwordMustMatchValidation = [
  'passwords-match',
  'Passwords must match',
  function (value: string) {
    // @ts-ignore no clue wtf `this` is in this context
    return this.parent.password === value;
  },
];

export const passwordRegexValidation: [
  regex: RegExp,
  message: Message<{
    regex: RegExp;
  }>,
] = [
  PASSWORD_REGEX,
  'Password has to include at least one capital letter, one lowercase letter, one number, and one special character',
];

export const passwordMinLengthValidation: [min: number, message?: string] = [
  PASSWORD_MIN_LENGTH,
  `Password has to have at least ${PASSWORD_MIN_LENGTH} characters`,
];

export const passwordMaxLengthValidation: [max: number, message?: string] = [
  PASSWORD_MAX_LENGTH,
  `Password cannot have more than ${PASSWORD_MAX_LENGTH} characters`,
];

export const noSpecialCharacters = [
  'no-special-characters',
  'Special characters are not allowed',
  function (value: string) {
    return !SPECIAL_CHAR_REGEX.test(value);
  },
];

export const noWhitespaces: [string, string, (value: string) => boolean] = [
  'no-whitespaces',
  'Cannot start or end with spaces',
  function (value: string) {
    return value !== undefined && value === value.trim();
  },
];

export const handleNotAllowedCharactersInput = (event: any) => {
  if (!NUMBERS_AND_PERIOD_REGEX.test(event.key)) {
    event.preventDefault();
  }
};
