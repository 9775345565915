import { snakeCase } from 'lodash';
import { MediaItem, SurveyMedia } from 'types';

export const formatFileName = (media: SurveyMedia | MediaItem) => {
  const surveyMedia = media as SurveyMedia;
  if (surveyMedia.mediaCategoryName) {
    const { fileNameCounter, mediaCategoryName, originalFileExtension } = surveyMedia;
    return snakeCase(mediaCategoryName + ' ' + fileNameCounter) + originalFileExtension;
  }
  const { name, originalFilenameWithoutExtension, originalFileExtension } = surveyMedia;
  return name ?? originalFilenameWithoutExtension + originalFileExtension;
};
