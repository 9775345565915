import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Col, Row } from 'antd';
import { MediaCategory } from 'types';

import { createProjectCategory } from 'state/slices/projectMediaCategories';
import { AppDispatch, RootState } from 'state/store/store';

import { getCategoryName } from '../helpers/getCategoryName';

import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import styled from 'styled-components';
import { getDisclaimerText } from '../helpers/getDisclaimerText';
import InformationNeeded from '../shared/InformationNeeded';
import ItemsUploadedRadioButtons from '../shared/ItemsUploadedRadioButtons';
import { DropZone } from './DropZone';
import { UploadFormProps } from './types';
import './UploadForm.scss';

const UploadForm = ({
  tierLevel,
  uploadComplete,
  setUploadComplete,
  handleFilesInAlbum,
  filesAlreadyStored,
  handleNewBuildPlans,
  areNewBuildPlansRequired,
  isRevision = false,
  serviceRequestProjectId,
}: UploadFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { project } = useSelector((state: RootState) => state.project);
  const { projectMedias } = useSelector((state: RootState) => state.medias);

  const [categoryIdForServiceRequest, setCategoryIdForServiceRequest] = useState<string | null>(
    null
  );
  const [categoryNameForServiceRequest, setCategoryNameForServiceRequest] = useState<string | null>(
    null
  );

  const isSitePlan = tierLevel === TIER_LEVEL.sitePlan;
  const isPlanSet =
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim;
  const isPlanSetOrSitePlan = isPlanSet || isSitePlan;
  const isPrelim = tierLevel === TIER_LEVEL.maxFill || tierLevel === TIER_LEVEL.siteModeling;
  const isTrueUp = tierLevel === TIER_LEVEL.trueUp;

  useEffect(() => {
    const createCompanyCategory = async () => {
      if (!categoryNameForServiceRequest) {
        const categoryName = getCategoryName({ isPrelim, isRevision, tierLevel });
        const project = await dispatch(
          createProjectCategory({
            projectId: projectId ?? serviceRequestProjectId,
            categoryName,
          })
        );

        setCategoryNameForServiceRequest(categoryName);
        const mediaCategoryId = project?.mediaCategories?.filter(
          (category: MediaCategory) => category.categoryName === categoryName
        )[0]?.id;
        if (mediaCategoryId) {
          setCategoryIdForServiceRequest(mediaCategoryId);
        }
      }
    };
    createCompanyCategory();
  }, [
    categoryNameForServiceRequest,
    dispatch,
    isPrelim,
    isRevision,
    projectId,
    serviceRequestProjectId,
    t,
    tierLevel,
  ]);

  const onUploadComplete = () => setUploadComplete(true);

  const userId = project?.submittedFor ? project.submittedFor.id : currentUser?.id;

  const showDropZone = Boolean(
    (filesAlreadyStored !== null && isPlanSetOrSitePlan && !filesAlreadyStored) ||
      (isRevision && filesAlreadyStored) ||
      (isPrelim && areNewBuildPlansRequired) ||
      isTrueUp
  );

  const dropHereOrText = `${t('DesignService.planSet.uploadForm.placeholder')} ${
    isPrelim && !isRevision ? t('DesignService.fileFormats') : ''
  }`;

  return (
    <Col className="DesignService">
      <Row>
        {isPlanSetOrSitePlan && !isRevision ? (
          <Col span={24}>
            <InformationNeeded />
          </Col>
        ) : null}
        {isTrueUp ? (
          <UploadDisclaimerForTrueUp />
        ) : (
          <ItemsUploadedRadioButtons
            handleFilesInAlbum={handleFilesInAlbum}
            filesAlreadyStored={filesAlreadyStored}
            handleNewBuildPlans={handleNewBuildPlans}
            areNewBuildPlansRequired={areNewBuildPlansRequired}
            isPrelim={isPrelim}
            isRevision={isRevision}
          />
        )}
        {showDropZone && categoryIdForServiceRequest && categoryNameForServiceRequest && userId ? (
          <DropZone
            projectId={projectId ?? serviceRequestProjectId}
            projectMedias={projectMedias}
            projectGeolocation={project?.geolocation}
            categoryId={categoryIdForServiceRequest}
            categoryName={categoryNameForServiceRequest}
            onUploadComplete={onUploadComplete}
            userId={userId}
            uploadComplete={uploadComplete}
            dropHereOrText={dropHereOrText}
            title={getDisclaimerText({ isPrelim, isRevision })}
          />
        ) : null}
      </Row>
    </Col>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${fontSizes.extraSmall};
`;

const StyledTitle = styled.div`
  font-weight: ${fontWeights.bold};
`;

const StyledSubTitle = styled.span`
  text-decoration: underline;
`;

const UploadDisclaimerForTrueUp = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledTitle>{t('DesignService.uploadTitle')}</StyledTitle>
      <div>
        <StyledSubTitle>{t('DesignService.uploadNoteTitle')}</StyledSubTitle>{' '}
        {t('DesignService.uploadNote')}
      </div>
    </Wrapper>
  );
};

export default UploadForm;
