import { createSlice } from '@reduxjs/toolkit';

import { ACCESS } from 'helpers/constants';

const name = 'featureToggleOverrides';

type FeatureFlagState = {
  [key in ACCESS]: boolean;
};

const featureToggleOverridesSlice = createSlice({
  name,
  initialState: {} as FeatureFlagState,
  reducers: {
    toggleFeature: (
      state,
      action: {
        payload: {
          featureName: ACCESS;
          enabled: boolean;
        };
      }
    ) => {
      state[action.payload.featureName] = action.payload.enabled;
    },
  },
});

export const { toggleFeature } = featureToggleOverridesSlice.actions;

export default featureToggleOverridesSlice.reducer;
