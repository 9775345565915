import API from '../config';
import {
  companyCategoryUrl,
  createCompanyCategoryUrl,
  defaultCategoriesUrl,
} from './companyCategoriesUrls';

export const createCompanyCategory = (companyId: string, categoryName: string) =>
  API.post(createCompanyCategoryUrl(companyId), { categoryName });
export const deleteCompanyCategory = (companyId: string, categoryName: string) =>
  API.delete(companyCategoryUrl(companyId, categoryName));
export const updateCompanyCategory = (
  companyId: string,
  categoryName: string,
  newCategoryName: string
) => API.patch(companyCategoryUrl(companyId, categoryName), { newCategoryName });
export const fetchDefaultCategories = () => API.get(defaultCategoriesUrl());
