import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';

const CreditDisplayRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  @media only screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const CreditDisplayWithNote = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export { CreditDisplayRow, CreditDisplayWithNote };
