import API from '../config';
import { createNoteUrl, fetchMentionsUserListUrl, fetchNotesUrl, noteByIdUrl } from './notesUrls';

export const fetchtNotesApi = (projectId: string) => API.get(fetchNotesUrl(projectId));
export const fetchNoteApi = (noteId: string) => API.get(noteByIdUrl(noteId));
export const createNoteApi = (payload: { projectId: string; text: string }) =>
  API.post(createNoteUrl(), payload);
export const deleteNoteApi = (noteId: string) => API.delete(noteByIdUrl(noteId));
export const updatetNoteApi = (noteId: string, text: string, media: string[]) =>
  API.patch(noteByIdUrl(noteId), { text, media });

export const fetchMentionsUserListApi = (projectId: string) =>
  API.get(fetchMentionsUserListUrl(projectId));
