import { ACCESS } from 'helpers/constants/access';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

import ProjectCategory from './ProjectCategory';
import OldProjectCategory from './ProjectCategory.old';

export default function ProjectCategoryToggle({
  isAdminRoute = false,
}: {
  isAdminRoute?: boolean;
}) {
  const enableProjectCategoryRefactor = useFeatureToggle(ACCESS.ENABLE_PROJECT_CATEGORY_REFACTOR);

  if (enableProjectCategoryRefactor) {
    // eslint-disable-next-line
    console.log('Enabling Project Category Refactor...');
    return <ProjectCategory isAdminRoute={isAdminRoute} />;
  }
  return <OldProjectCategory isAdminRoute={isAdminRoute} />;
}
