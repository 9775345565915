type CompletedDateType = {
  completedDate?: Date | string;
};

export const completedDateSorter = (a?: CompletedDateType, b?: CompletedDateType): number => {
  if (a?.completedDate && b?.completedDate) {
    return Math.sign(+new Date(a.completedDate) - +new Date(b.completedDate));
  } else if (!a?.completedDate) {
    return 1;
  }
  return -1;
};
