import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert, Button, Input } from 'antd';
import { useFormik } from 'formik';

import { resetPasswordRequested } from 'state/slices/authSlice';

import { rootRoute } from 'helpers/constants/routes';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, SUCCESS_QUERY } from './constants';
import './Reset.scss';
import { validationSchema } from './validationSchema';

const initialValues = {
  [FORM_CONTROLS.EMAIL]: '',
};

const Reset = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSuccess = location.search === SUCCESS_QUERY;
  const { touched, isValid, dirty, handleSubmit, errors, getFieldProps } = useFormik({
    initialValues,
    validationSchema: isSuccess ? null : validationSchema,
    onSubmit: (values) => {
      onReset(values);
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onReset = (values) => {
    dispatch(resetPasswordRequested(values));
    setIsSubmitted(true);
  };

  const handleReturn = () => history.push(rootRoute());

  const isFormValid = isValid && dirty;

  return isSubmitted && !isSuccess ? (
    <>
      <div className="Input-Wrapper Input-Wrapper--Alert" data-testid="resetPasswordConfirm">
        <Alert
          message="We have sent you an email with a link to reset your password."
          type="success"
        />
      </div>
      <Button className="Button--Big" onClick={handleReturn}>
        Return to Login
      </Button>
    </>
  ) : (
    <form>
      {isSuccess ? (
        <div className="Input-Wrapper Input-Wrapper--Alert">
          <Alert message="Your new password has been confirmed" type="success" />
        </div>
      ) : (
        <div className="Input-Wrapper">
          <label htmlFor="resetEmail">Your Email</label>
          <Input
            placeholder="Enter your email address"
            className={validateStatus(touched, errors, FORM_CONTROLS.EMAIL)}
            type={FORM_CONTROLS.EMAIL}
            id="resetEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
          </div>
        </div>
      )}

      <Button
        className="Button--Big"
        disabled={!isSuccess && !isFormValid}
        aria-disabled={!isSuccess && !isFormValid}
        htmlType="submit"
        onClick={isSuccess ? handleReturn : handleSubmit}
        data-testid="sendResetInstructionsButton"
      >
        {isSuccess ? 'Log In' : 'Send Reset Instructions'}
      </Button>
    </form>
  );
};

export default Reset;
