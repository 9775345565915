import { useCallback, useContext } from 'react';

import { t } from 'i18next';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import { FieldTypeEnum } from '@cpm/scanifly-shared-data';
import { ModalContext } from 'components';

type Props = {
  id: string;
  handleSubmit: () => void;

  resetForm: () => void;
};

function useChecklistButtons({ id, handleSubmit, resetForm }: Props) {
  const { displayConditionalModal } = useContext(ModalContext);
  const { addConditionalField, copyField, deleteField, editing, setEditing } =
    useContext(ChecklistTemplateContext);

  const handleCopy = useCallback(() => copyField(id), [copyField, id]);
  const handleDelete = useCallback(() => deleteField(id), [deleteField, id]);
  const handleEdit = useCallback(() => setEditing([...editing, id]), [setEditing, editing, id]);
  const handleSave = useCallback(() => {
    handleSubmit();
    setEditing(editing.filter((existingId) => existingId !== id));
  }, [handleSubmit, setEditing, editing, id]);
  const handleCancel = useCallback(() => {
    resetForm();
    setEditing(editing.filter((existingId) => existingId !== id));
  }, [resetForm, setEditing, editing, id]);

  const handleCondition = useCallback(() => {
    const createConditionSubmit = ({
      fieldType,
      targetInput,
      action,
      newLabel,
    }: {
      fieldType: string;
      targetInput: string;
      action: string;
      newLabel: string;
    }) => {
      addConditionalField({
        fieldType: fieldType as FieldTypeEnum,
        targetInput,
        action,
        newLabel,
        targetFieldId: id,
      });
    };
    displayConditionalModal({
      title: t('Checklists.createCondition'),
      description: t('Checklists.conditionDescription'),
      actionButtonOnClick: createConditionSubmit,
      actionButtonLabel: t('Checklists.addCondition'),
    });
  }, [displayConditionalModal, addConditionalField, id]);

  return {
    handleCondition,
    editing,
    handleCopy,
    handleDelete,
    handleSave,
    handleEdit,
    handleCancel,
  };
}

export default useChecklistButtons;
