import i18next from 'i18next';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';

export const MAX_LENGTH = 300;

const t = (param: string) => {
  return i18next.t(`DesignServiceForm.${param}`);
};

export const TRUE_UP_FORM = {
  SIZED_FOR_CATEGORY: {
    title: t('sizedFor'),
    options: [
      { label: t('consumption'), value: SIZED_FOR_CATEGORIES.consumption },
      { label: t('production'), value: SIZED_FOR_CATEGORIES.production },
      { label: t('systemSize'), value: SIZED_FOR_CATEGORIES.systemSize },
    ],
  },
  SIZED_FOR_CATEGORY_FOR_NO_DESIGN_SELECTED: {
    title: t('sizedFor'),
    options: [
      { label: t('none'), value: SIZED_FOR_CATEGORIES.none },
      { label: t('consumption'), value: SIZED_FOR_CATEGORIES.consumption },
      { label: t('production'), value: SIZED_FOR_CATEGORIES.production },
      { label: t('systemSize'), value: SIZED_FOR_CATEGORIES.systemSize },
    ],
  },
  SIZED_FOR_VALUE: {
    title: '',
    placeholder: {
      none: t('none'),
      consumption: '%',
      production: 'kWh',
      systemSize: 'kW',
    },
  },
} as const;

export const COMMENTS_FORM = {
  COMMENTS: {
    title: t('comments'),
  },
};

export const FIRE_SETBACKS = {
  title: t('fireSetbacks'),
  setbacks: [
    {
      title: t('ridge'),
      placeholder: '12"',
      id: 'ridge',
    },
    {
      title: t('eave'),
      placeholder: '12"',
      id: 'eave',
    },
    {
      title: t('rake'),
      placeholder: '12"',
      id: 'rake',
    },
    {
      title: t('valley'),
      placeholder: '12"',
      id: 'valley',
    },
    {
      title: t('hip'),
      placeholder: '12"',
      id: 'hip',
    },
  ],
};

export const OBSTRUCTION_SETBACKS = {
  title: t('obstructionSetbacks'),
  setbacks: [
    {
      title: t('vents'),
      placeholder: '12"',
      id: 'vents',
    },
    {
      title: t('exhaust'),
      placeholder: '12"',
      id: 'exhaust',
    },
    {
      title: t('skylights'),
      placeholder: '12"',
      id: 'skylights',
    },
    {
      title: t('parapet'),
      placeholder: '12"',
      id: 'parapet',
    },
    {
      title: t('general'),
      placeholder: '12"',
      id: 'general',
    },
  ],
};
