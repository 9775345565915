import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import colors from 'helpers/constants/colors';
import styled from 'styled-components';

const BtnLnk = styled(Link)`
  color: ${colors.white};
  font-weight: 500;
  letter-spacing: 0.02rem;
  background-color: ${colors.mainBlue};
  border-radius: 1rem;
  height: 3.8rem;
  padding: 0.7rem 2rem 0 2rem;
  font-size: 14px;

  &:hover {
    color: #2950ff;
    background: #fff;
    border: 0.1rem solid #2950ff;
  }
`;

interface ButtonLinkProps {
  to: string;
  children?: ReactNode;
  className?: string;
  blue?: boolean;
}

const ButtonLink = ({ to, className, children }: ButtonLinkProps) => {
  return (
    <BtnLnk to={to} className={className}>
      {children}
    </BtnLnk>
  );
};

export default ButtonLink;
