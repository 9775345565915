import { cloneElement, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { Button } from 'antd';
import cn from 'classnames';
import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';
import { FILTER, MORE_FILTERS } from 'screens/Projects/ProjectsList/constants';

import { Tag } from 'components';

import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import './SingleFilter.scss';

const SingleFilter = ({
  filter,
  isActive,
  setActive,
  handleFilterSave,
  savedFilters,
  isDisabled,
  options,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [isCleared, setIsCleared] = useState(false);

  const isSearch = filter.label === FILTER.LOCATION;
  const isCheckbox = filter.label === FILTER.FOLDER;
  const isDate = filter.id === FILTER.DATE;
  const isMore = filter.id === FILTER.MORE;

  const handleOptionsChange = (list) => {
    setIsCleared(true);
    setSelectedOptions(
      Array.isArray(list) && list[0] ? list.map((item) => item.value || item) : list
    );
  };

  const filterOptions = isDate
    ? [
        {
          label: FILTER.BETWEEN,
          value: FILTER.BETWEEN,
        },
        {
          label: FILTER.ON,
          value: FILTER.ON,
        },
      ]
    : options && isMore
      ? options.map((filter) => ({
          id: filter.id,
          label: filter.title,
          value: filter.title,
          options: filter.options?.map((option) => ({
            label: option.title,
            value: option.value,
          })),
        }))
      : Array.isArray(options)
        ? options.map((option) => ({
            label: isCheckbox ? (
              <Tag color={option.color || ''}>
                <FolderIcon />
                <span>{option.title}</span>
              </Tag>
            ) : (
              startCase(option.title)
            ),
            value: option.value,
            id: option.value,
          }))
        : [];

  const handleClose = () => {
    setSelectedOptions(savedFilters[filter.label] || null);
    setActive();
    setIsCleared(false);
  };

  const handleSave = () => {
    setActive();
    setIsCleared(false);
    return handleFilterSave(filter.label, selectedOptions);
  };

  const handleClear = () => {
    setIsCleared(true);
    setSelectedOptions(null);
    return handleFilterSave(filter.label, null);
  };

  const dropdownRef = useOnclickOutside(() => {
    handleClose();
  });

  const currentValue =
    selectedOptions?.length || isCleared ? selectedOptions : savedFilters[filter.label];

  const getMoreFiltersCounter = () => {
    const counter = MORE_FILTERS.map((filter) =>
      isActive
        ? selectedOptions?.[filter.id]?.length || 0
        : savedFilters?.[FILTER.MORE]?.[filter.id]?.length || 0
    );
    const projectTypeIndex = MORE_FILTERS.findIndex((item) => item.id === FILTER.TYPES);
    //
    // divides counter at project type filter index as we are pushing two, past project
    // type filter options that we wouldn't like to display along with 'small' and 'large'
    //
    return counter.reduce(
      (acc, currentVal, currentIndex) =>
        acc + (currentIndex === projectTypeIndex ? currentVal / 3 : currentVal)
    );
  };

  return (
    <>
      <Button
        className={cn('Button--Filter', { 'Button--Active': isActive })}
        onClick={() => setActive(filter.id)}
        disabled={!filterOptions?.length || isDisabled}
        aria-disabled={!filterOptions?.length || isDisabled}
        data-testid={`project-filter-${filter.id}`}
      >
        {Boolean(selectedOptions?.length) && (
          <span className="Single-Filter-Counter">
            {isActive ? selectedOptions?.length || '' : savedFilters[filter.label]?.length || ''}
          </span>
        )}
        {isMore && Boolean(getMoreFiltersCounter()) && (
          <span className="Single-Filter-Counter">{getMoreFiltersCounter()}</span>
        )}
        {isDate && selectedOptions?.on && <span className="Single-Filter-Counter">1</span>}
        {isDate && selectedOptions?.[FILTER.FROM] && selectedOptions?.[FILTER.TO] && (
          <span className="Single-Filter-Counter">2</span>
        )}
        {filter.title}
        {(selectedOptions?.length > 1 ||
          (selectedOptions?.[FILTER.FROM] && selectedOptions?.[FILTER.TO])) &&
          's'}
      </Button>
      {isActive && Boolean(filterOptions.length) && (
        <div className="Single-Filter-Dropdown ignore-onclickoutside" ref={dropdownRef}>
          <div className="Single-Filter-Header">
            <div className="Single-Filter-Header-Info">
              <h3>
                {!isMore ? 'Select' : ''} {filter.title}
                {isDate}
              </h3>
              {!isSearch && !isDate && !isMore && (
                <h5>
                  {selectedOptions?.length || 'no'} {filter.id}
                  {!isCheckbox && selectedOptions?.length > 1 && 's'} selected
                </h5>
              )}
            </div>
            <Button onClick={handleClose} className="Button--Unstyled" aria-label="close">
              <XIcon />
            </Button>
          </div>
          <div
            className={cn('Single-Filter-Content', {
              'Single-Filter-Content-Search': isSearch,
            })}
          >
            {filter.component &&
              cloneElement(filter.component, {
                filterOptions,
                handleOptionsChange,
                currentValue,
              })}
          </div>
          <div className="Single-Filter-Footer">
            <Button className="Button--Unstyled" onClick={handleClear}>
              <p className="Single-Filter-Clear">Clear</p>
            </Button>
            <Button
              className="Button Button--Blue"
              onClick={handleSave}
              disabled={
                isDate &&
                selectedOptions?.range !== FILTER.ON &&
                Boolean(selectedOptions?.from) !== Boolean(selectedOptions?.to)
              }
              aria-disabled={
                isDate &&
                selectedOptions?.range !== FILTER.ON &&
                Boolean(selectedOptions?.from) !== Boolean(selectedOptions?.to)
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

SingleFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  handleFilterSave: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  savedFilters: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default SingleFilter;
