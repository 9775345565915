import { Link } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { User } from 'types';

import { scaniflyAdminProjectsByUserRoute } from 'helpers/constants/routes';
import { handleCopyClick } from 'helpers/utils/handleCopyClick';

import { POSITION_FILTERS, ROLES } from '../constants';
import { DATE_OPTIONS } from '../CustomerSupportUpload/constants';
import EditDeleteButton from './EditDeleteButton';

export const Columns = (): ColumnType<User>[] => {
  return [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '14rem',
      sorter: (a: User, b: User) => (a?.firstName ?? '').localeCompare(b?.firstName ?? ''),
      render: (item: string, record: User) => (
        <Link
          className="Button--Icon"
          aria-label="company project manager"
          to={scaniflyAdminProjectsByUserRoute(record.id)}
        >
          {item}
        </Link>
      ),
    },
    {
      title: 'Last Name',
      width: '14rem',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a: User, b: User) => (a?.lastName ?? '').localeCompare(b?.lastName ?? ''),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      width: '17rem',
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: User, b: User) => (a?.company ?? '').localeCompare(b?.company ?? ''),
      render: (company: string, item: User) =>
        item.companyId ? (
          <Link to={`/scanifly-admin/user-manager/${item.companyId}`}>{company}</Link>
        ) : (
          '--'
        ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      sorter: (a: User, b: User) => (a?.createdAt ?? '').localeCompare(b?.createdAt ?? ''),
      render: (createdAt: string) => {
        return createdAt ? new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS) : '–';
      },
    },
    {
      title: 'User ID',
      dataIndex: 'id',
      width: '15rem',
      sorter: (a: User, b: User) => (a?.id ?? '').localeCompare(b?.id ?? ''),
      render: (id: string, item: User) => {
        return (
          <div className="ScaniflyAdmin-ButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label="copy user id"
              className="ScaniflyAdmin-ButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, 'User ID', `${item.firstName} ${item.lastName}`)}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '10rem',
      render: (_: any, record: User) => <EditDeleteButton record={record} />,
    },
    {
      title: 'User Type',
      dataIndex: 'roles',
      key: 'roles',
      width: '13rem',
      sorter: (a: User, b: User) =>
        (a?.roles ? a.roles[0] : '').localeCompare(b?.roles ? b.roles[0] : ''),
      render: (roles: string[]) => {
        if (roles.includes('scaniflyAdmin')) return ROLES.scaniflyAdmin;
        if (roles.includes('admin')) return ROLES.admin;
        if (roles.includes('default')) return ROLES.default;
        return 'Role not defined';
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: '10rem',
      filters: POSITION_FILTERS,
      onFilter: (value: string | number | boolean, record: User) =>
        record.position?.toLowerCase() === value.toString().toLowerCase(),
      render: (position: string) =>
        POSITION_FILTERS.find((el) => el.value === position)?.text ?? '-',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '10rem',
      sorter: (a: User, b: User) => (a?.email ?? '').localeCompare(b?.email ?? ''),
      render: (email: string, record: User) => (
        <Link
          className="Button--Icon"
          aria-label="email account holder"
          to={scaniflyAdminProjectsByUserRoute(record.id)}
        >
          {email}
        </Link>
      ),
    },
    {
      title: 'Phone',
      width: '18rem',
      dataIndex: 'phone',
      render: (phone?: string) =>
        !phone || phone.includes('null') || phone.length < 2 ? '-' : phone,
    },
  ];
};
