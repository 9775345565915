import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { DesignOption } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { StyledFlexSection } from '../components';

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
`;

const StyledSpec = styled.p`
  min-width: 15rem;
  color: ${colors.gray};

  @media (min-width: ${breakpoints.lg}) {
    min-width: 12rem;
    font-size: ${fontSizes.extraSmall};
  }
`;

export const Specifications = ({ option }: { option: DesignOption | undefined }) => {
  return (
    <StyledFlexSection>
      {option?.specifications.map((spec) => {
        return (
          <StyledSpec key={spec}>
            <StyledCheckmarkIcon title="checkmark" /> {`${spec}`}
          </StyledSpec>
        );
      })}
    </StyledFlexSection>
  );
};
