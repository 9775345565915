import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Modal, Spin } from 'antd';
import { ServiceRequest } from 'types';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { designServicesLandingRoute, projectsListRoute } from 'helpers/constants/routes';
import { getMainSelectionSteps } from 'screens/DesignServices/helpers/getMainSelectionSteps';

import DesignSteps from '../DesignSteps/DesignSteps';
import './DesignSelection.scss';
import OrderResponseModal from './OrderResponseModal/OrderResponseModal';

const DesignSelection = ({ upgrade = false }: { upgrade: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId, step } = useParams<{ projectId: string; step: string }>();
  const { project } = useSelector((state: RootState) => state.project);
  const { company } = useSelector((state: RootState) => state.company);
  const [selectedStep, setSelectedStep] = useState<number>(Number(step));
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [responseData, setResponseData] = useState({});
  const history = useHistory();

  const handleModalClose = () => {
    setModalVisible(false);
    setResponseData({});
  };

  const handleResponse = (
    data: Partial<ServiceRequest> & { stepNumber: number; projectId?: string }
  ) => {
    if (Object.keys(data).length) {
      const tierLevel = data?.tierLevel;
      const stepNumber = data?.stepNumber;
      const ORDER_COMPLETE = 3;

      if (
        tierLevel !== TIER_LEVEL.wireframe &&
        tierLevel !== TIER_LEVEL.wireframeForLarge &&
        stepNumber < ORDER_COMPLETE
      ) {
        handleStepChange(stepNumber);
      } else {
        handleStepChange(ORDER_COMPLETE);
        setModalVisible(true);
      }

      setResponseData(data);
    }
  };

  const handleStepChange = (step: number | string) => {
    setSelectedStep(Number(step));
  };

  const designServicesAccess = getFeatureAvailability(
    false,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  useEffect(() => {
    if (!company) {
      return history.push(designServicesLandingRoute(projectId));
    }
    if (!designServicesAccess) {
      return history.push(projectsListRoute());
    }
    if (!project || project?.id !== projectId) {
      dispatch(projectRequested(projectId));
    }
  }, [designServicesAccess, dispatch, history, project, projectId, company]);

  if (designServicesAccess) {
    return (
      <div data-testid="design-selection">
        {/* @ts-ignore screw you ant-d */}
        <Modal visible={modalVisible} onCancel={handleModalClose} footer={null}>
          <OrderResponseModal
            handleModalClose={handleModalClose}
            responseData={responseData}
            upgrade={upgrade}
          />
        </Modal>
        <div className="DesignSelection-ProjectTitle">{project?.name ?? ''}</div>
        <div className="DesignSelection-MainWrapper">
          <DesignSteps
            current={selectedStep}
            options={getMainSelectionSteps({ handleResponse, upgrade })}
            handleStepChange={handleStepChange}
          />
        </div>
      </div>
    );
  } else {
    return <Spin size="large"></Spin>;
  }
};

export default DesignSelection;
