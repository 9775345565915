import colors from 'helpers/constants/colors';
import fontWeights from 'helpers/constants/fontWeights';
import { ReactElement } from 'react';
import styled from 'styled-components';

const StyledAnchor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  background: ${colors.mainBlue};
  border: 0.1rem solid ${colors.mainBlue};
  cursor: pointer;
  max-width: 23rem;

  a {
    color: ${colors.white};
    font-weight: ${fontWeights.semiBold};
  }

  svg {
    fill: ${colors.white};
    margin-right: 1rem;
  }

  &:hover {
    color: ${colors.mainBlue};
    background: ${colors.white};
    border: 0.1rem solid ${colors.mainBlue};

    a {
      color: ${colors.mainBlue};
    }

    svg {
      fill: ${colors.mainBlue};
    }
  }
`;

interface ButtonLinkProps {
  href: string;
  text: string;
  openInNewTab?: boolean;
  icon?: ReactElement<any, any>;
}

export const ButtonAnchor = ({ href, text, icon, openInNewTab = false }: ButtonLinkProps) => {
  return (
    <StyledAnchor>
      {icon}
      <a
        href={href}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        role="button"
        aria-label={text}
      >
        {text}
      </a>
    </StyledAnchor>
  );
};
