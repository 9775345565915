import { ReactElement } from 'react';

import { DRONE_DATA_SCORE, DRONE_DATA_SCORE as SCORES } from '@cpm/scanifly-shared-data';

import { Tag } from 'components';

import colors from 'helpers/constants/colors';
import { newWeights, Weights } from 'helpers/constants/weights';
import { formatPercent } from 'helpers/utils/formatPercent';

const finalScore = SCORES.droneDataScore;

const getTag = (field: string): { title: string; color: string } => {
  if (field in newWeights) {
    return { title: 'Raw', color: colors.purple };
  } else if (field === finalScore) {
    return { title: 'Final Score', color: colors.orange };
  }
  return { title: 'Weighted Score', color: colors.teal };
};

export const createDroneDataColumns = (
  arr: [DRONE_DATA_SCORE, string][] = []
): {
  title: ReactElement;
  dataIndex: string[];
  width: string;
  render: (field: string) => string;
}[] => {
  return arr.map((item) => {
    const [field, title] = item;

    const tag = getTag(field);

    return {
      title: (
        <>
          <div className="Tag">{title}</div>
          <section>
            <Tag color={tag.color}>{tag.title}</Tag>
            {field in newWeights ? (
              <Tag color={colors.lightPurple}>{newWeights[field as keyof Weights]}</Tag>
            ) : null}
          </section>
        </>
      ),
      dataIndex: ['droneData', field],
      key: field,
      width: '20rem',
      render: (field: string) => (field ? formatPercent(+field ?? 0) : '-'),
    };
  });
};
