import { QRCode } from 'react-qrcode-logo';

import { SUPPORT_URL } from 'helpers/constants/urls';

import QRLogo from 'assets/qr-logo.svg';

type Props = {
  url: string;
  qrCodeSize: number;
  logo: string;
  logoWidth: number;
  bgColor: string;
};

const QRCodeGenerator = ({
  url = SUPPORT_URL,
  qrCodeSize = 100,
  logo = QRLogo,
  logoWidth = 30,
  bgColor = '#FFFFFF',
}: Props) => {
  return (
    <QRCode
      value={url}
      logoImage={logo}
      logoPadding={0}
      bgColor={bgColor}
      logoWidth={logoWidth}
      size={qrCodeSize}
    />
  );
};

export default QRCodeGenerator;
