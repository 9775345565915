import i18n from 'i18next';
import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './initialValues';

export const validationSchema = (designs = [], designId = null) => {
  const t = i18n.t;
  return Yup.object().shape({
    [FORM_CONTROLS.NAME]: Yup.string()
      .required(t('NewDesignModal.error'))
      .test(...noWhitespaces)
      .test('uniqueNames', 'Model names must be unique', (value) => {
        return designId
          ? !designs.filter(({ name, id }) => !name.localeCompare(value) && id !== designId).length // allows the user to rename a design to its original value, but prevents duplicate design names
          : !designs.filter(({ name }) => !name.localeCompare(value)).length; // prevents the user from adding duplicate design names
      }),
  });
};
