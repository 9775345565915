import { ExampleMedia } from '@cpm/scanifly-shared-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_NAMESPACE } from './constants';
import { ExampleMediaState } from './types';

const initialState: ExampleMediaState = {
  exampleMediaMap: {},
  exampleMediaOrder: [],
};

const exampleMediaSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    createExampleMediaAction: (
      state,
      { payload: createdMedia }: PayloadAction<ExampleMedia & { templateId: string }>
    ) => {
      const { templateId, id } = createdMedia;

      if (!state.exampleMediaMap[templateId]) {
        state.exampleMediaMap[templateId] = {
          media: {},
        };
      }

      state.exampleMediaMap[templateId].media[id] = createdMedia;
      if (!state.exampleMediaOrder.includes(id)) {
        state.exampleMediaOrder.push(id);
      }
    },
    getExampleMediaAction: (
      state: ExampleMediaState,
      { payload }: PayloadAction<{ media: ExampleMedia[]; templateId: string }>
    ) => {
      const { templateId, media } = payload;

      if (!state.exampleMediaMap[templateId]) {
        state.exampleMediaMap[templateId] = {
          media: {},
        };
      }

      const updatedMedia = { ...state.exampleMediaMap[templateId].media };
      const updatedOrder = [...state.exampleMediaOrder];

      media.forEach((mediaItem) => {
        const { id } = mediaItem;

        updatedMedia[id] = mediaItem;
        if (!updatedOrder.includes(id)) {
          updatedOrder.push(id);
        }
      });

      state.exampleMediaMap[templateId].media = updatedMedia;
      state.exampleMediaOrder = updatedOrder;
    },
    deleteExampleMediaAction: (
      state: ExampleMediaState,
      { payload }: PayloadAction<{ mediaId: string; templateId: string }>
    ) => {
      const { templateId, mediaId } = payload;

      if (state.exampleMediaMap[templateId]) {
        delete state.exampleMediaMap[templateId].media[mediaId];
      }

      state.exampleMediaOrder = state.exampleMediaOrder.filter((id: string) => id !== mediaId);
    },
  },
});

export const { createExampleMediaAction, getExampleMediaAction, deleteExampleMediaAction } =
  exampleMediaSlice.actions;

export default exampleMediaSlice.reducer;
