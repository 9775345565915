import * as FullStory from '@fullstory/browser';

import { environment } from '../environment';

class Analytics {
  private static instance: Analytics;
  isInitialized = false;

  private constructor() {
    if (Analytics.instance) {
      throw new Error('Analytics constructor called multiple times (singleton)');
    }
  }

  static getInstance() {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  clearSession() {
    if (this.isInitialized) {
      FullStory.anonymize();
    }
  }

  initAnalytics() {
    if (!environment.isTestingEnv) {
      const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
      if (orgId) {
        FullStory.init({ orgId }, () => {
          // eslint-disable-next-line no-console
          console.log(`FullStory initialized for ${environment.env}`);
        });
        FullStory.consent(true);
        this.isInitialized = true;
      }
    }
  }

  setUser({
    id,
    email,
    name,
    companyId,
    companyName,
  }: {
    id?: string;
    email?: string;
    name?: string;
    companyId?: string;
    companyName?: string;
  }) {
    if (this.isInitialized) {
      if (id) {
        FullStory.identify(id);
      }
      FullStory.setUserVars({
        email,
        name,
        companyId,
        companyName,
        environment: environment.env,
      });
    }
  }
}

export default Analytics;
