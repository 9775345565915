import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import cn from 'classnames';
import MapWrapper from 'screens/MapWrapper';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import ExpandableQRCode from 'components/QRCode/ExpandableQRCode';

import { DRONE_IMAGES } from 'helpers/constants/categories';
import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectCategoriesRoute,
  draftProjectCategoryRoute,
  draftProjectSiteDataRoute,
  projectCategoriesRoute,
  projectCategoryRoute,
  projectChecklistRoute,
  projectsListRoute,
  rootRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminDraftSiteDataRoute,
  scaniflyAdminProjectCategoriesRoute,
  scaniflyAdminProjectCategoryRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';

import './SiteData.scss';

const SiteData = ({ isAdminRoute = false }: { isAdminRoute?: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const { isProjectRequestedLoading, project } = useSelector((state: RootState) => state.project);
  const { isScaniflyAdmin } = usePermissions();
  const { company } = useSelector((state: RootState) => state.company);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const checklistFeatureAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.CHECKLISTS,
    company?.pricingTier
  );

  const isDraft =
    project?.statusDescription === PROJECT_STATUSES.DRAFT ||
    project?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectSiteDataRoute(projectId)
        : scaniflyAdminDraftSiteDataRoute(projectId),
    }),
  };

  const pins = useMemo(() => {
    return project
      ? [
          {
            location: project.geolocation,
            id: project.id,
          },
        ]
      : [];
  }, [project]);

  // On initial load request project
  useEffect(() => {
    if (!project) {
      if (!isProjectRequestedLoading && isInitialLoad) {
        dispatch(projectRequested(projectId));
        setIsInitialLoad(false);
      } else if (!isInitialLoad && !isProjectRequestedLoading) {
        history.push(projectsListRoute());
      }
    }
  }, [isInitialLoad, projectId, dispatch, project, isProjectRequestedLoading, history]);

  useEffect(() => {
    if ((projectId === project?.id && isNewProject && !isDraft) || (!isNewProject && isDraft)) {
      history.push(rootRoute());
    }
  }, [project, history, isDraft, isNewProject, projectId]);

  // This routing block is repeated often.
  // TODO: Refactor workflow routing to a single helper function
  const getDroneImagesDraftRoute = (projectId: string, pathName: string) => {
    return isAdminRoute
      ? scaniflyAdminDraftProjectCategoryRoute(projectId, pathName)
      : draftProjectCategoryRoute(projectId, pathName);
  };

  const getDroneImagesProjectRoute = (projectId: string, pathName: string) => {
    return isAdminRoute
      ? scaniflyAdminProjectCategoryRoute(projectId, pathName)
      : projectCategoryRoute(projectId, pathName);
  };

  const handleOpenDroneImages = () => {
    history.push(
      isDraft
        ? getDroneImagesDraftRoute(project.id, DRONE_IMAGES)
        : getDroneImagesProjectRoute(project?.id ?? '', DRONE_IMAGES)
    );
  };

  const getAlbumsDraftRoute = (projectId: string) => {
    return isAdminRoute
      ? scaniflyAdminDraftProjectCategoriesRoute(projectId)
      : draftProjectCategoriesRoute(projectId);
  };

  const getAlbumsProjectRoute = (projectId: string) => {
    return isAdminRoute
      ? scaniflyAdminProjectCategoriesRoute(projectId)
      : projectCategoriesRoute(projectId);
  };

  const handleOpenAlbums = () => {
    history.push(
      isDraft ? getAlbumsDraftRoute(project.id) : getAlbumsProjectRoute(project?.id ?? '')
    );
  };

  const handleOpenChecklists = () => {
    history.push(projectChecklistRoute(project?.id));
  };

  const handleSaveAndExit = () => {
    handleOpenDroneImages();
  };

  return (
    <MapWrapper pins={pins} isClickable={false}>
      <div className="MapWrapper-QRCodeContainer">
        <ExpandableQRCode />
      </div>
      <div className="SiteData">
        {isAdminRoute && (
          <div className="SiteData-SubmittedFor">
            Submitted for: {project?.submittedFor?.firstName} {project?.submittedFor?.lastName} at{' '}
            {project?.submittedFor?.company.name}
          </div>
        )}
        <h2 className="SiteData-ProjectName">{project?.name}</h2>
        <div className="SiteData-Label">View your site data here</div>
        {checklistFeatureAccess && (
          <div
            role="button"
            id="site-data-checklists"
            tabIndex={0}
            className={cn('SiteData-CategoriesList-Category')}
            onClick={handleOpenChecklists}
            onKeyDown={(e) => e.key === 'Enter' && handleOpenChecklists()}
          >
            <div>
              <CategoryIcon className="SiteData-CategoriesList-Category-CategoryIcon" />
              <span className="SiteData-CategoriesList-Category-Name">
                {PAGE_TITLES.CHECKLISTS}
              </span>
            </div>
          </div>
        )}

        <div
          role="button"
          tabIndex={0}
          className={cn('SiteData-CategoriesList-Category')}
          id="site-data-drone-images"
          onClick={handleOpenDroneImages}
          onKeyDown={(e) => e.key === 'Enter' && handleOpenDroneImages()}
        >
          <div>
            <CategoryIcon className="SiteData-CategoriesList-Category-CategoryIcon" />
            <span className="SiteData-CategoriesList-Category-Name">{DRONE_IMAGES}</span>
          </div>
        </div>

        <div
          role="button"
          tabIndex={0}
          className={cn('SiteData-CategoriesList-Category')}
          id="site-data-albums"
          onClick={handleOpenAlbums}
          onKeyDown={(e) => e.key === 'Enter' && handleOpenAlbums()}
        >
          <div>
            <CategoryIcon className="SiteData-CategoriesList-Category-CategoryIcon" />
            <span className="SiteData-CategoriesList-Category-Name">{PAGE_TITLES.ALBUMS}</span>
          </div>
        </div>

        <div className="SiteData-Buttons">
          <div className="SiteData-Buttons-Wrapper">
            <Button onClick={handleSaveAndExit} className="Button--White">
              Next
            </Button>
          </div>
        </div>
      </div>
    </MapWrapper>
  );
};

export default SiteData;
