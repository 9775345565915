import { GoBackButton } from 'components';
import { BackButtonContainer, BackButtonGrid } from './components';

const StyledBackButton = () => {
  return (
    <BackButtonGrid>
      <BackButtonContainer>
        <GoBackButton />
      </BackButtonContainer>
    </BackButtonGrid>
  );
};

export default StyledBackButton;
