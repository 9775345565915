import { CREATE_API_KEY, DELETE_API_KEY, GET_USER_API_KEYS } from './constants';

export enum RequestStatus {
  inProgress = 'inProgress',
  complete = 'complete',
  failed = 'failed',
}

export interface ApiKey {
  id: string;
  name: string;
  keyPrefix: string;
  userId: string;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
  expiresAt?: Date;
}

type RequestState = {
  status: RequestStatus | undefined;
  error: string | undefined;
};

export type ApiRequestActions =
  | typeof CREATE_API_KEY
  | typeof DELETE_API_KEY
  | typeof GET_USER_API_KEYS;

export type SetRequestStatePayload = {
  request: ApiRequestActions;
  status: RequestStatus;
  error?: string;
};

export interface ApiKeyState {
  apiKeys: { [key: string]: ApiKey };
  userMap: { [key: string]: string[] };
  requestState: Record<ApiRequestActions, RequestState>;
}
