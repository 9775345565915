// Library imports
import * as Sentry from '@sentry/react';
import { useUppy } from '@uppy/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Coords, UPLOAD_MANIFEST_INTENT } from '@cpm/scanifly-shared-data';
import { uuid4 } from '@sentry/utils';
import type { PluginOptions, UploadResult, UppyFile, UppyOptions } from '@uppy/core';
import Uppy from '@uppy/core';
import DefaultStore from '@uppy/store-default';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Tus from '@uppy/tus';
import {
  openFileListNotification,
  openNetworkNotification,
  openRestrictionFailedNotification,
} from 'screens/Albums/ProjectCategory/helpers';
import MediaItem from 'types/MediaItem';

import { refreshTokenRequested } from 'state/slices/authSlice';
import { projectMediasRequested } from 'state/slices/mediasSlice';
import { saveUploadManifestRequested } from 'state/slices/uploadManifestsSlice';
import { AppDispatch } from 'state/store/store';

// Internal imports
import { ACCESS } from 'helpers/constants/access';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import { environment } from 'helpers/utils';
import jwtUtils from 'helpers/utils/jwtUtils';
import {
  EventPlugin,
  ImageLocationPlugin,
  PhotoMetaPlugin,
  UploadManifestPlugin,
  XHRUploadWithRetryPlugin,
} from 'helpers/utils/uppy';
import { EventPluginOptions } from 'helpers/utils/uppy/EventPlugin';
import { FileMetaData } from 'helpers/utils/uppy/types';
import { UploadManifestPluginOptions } from 'helpers/utils/uppy/UploadManifestPlugin';

import {
  NOTIFICATION_TYPES,
  SUPPORTED_FILE_TYPES,
} from '../../screens/Albums/ProjectCategory/constants';

type UseUploadManagerParams = {
  /**
   * @note true by default
   * Will remove completed files once queue is empty
   */
  clearOnSuccess?: boolean;
  isDroneImages: boolean;
  projectMedias: MediaItem[];
  projectId: string;
  projectGeolocation: Coords | undefined;
  categoryId: string;
  categoryName: string;
  onDroneImagesUploaded?: () => void;
  onFilesPicked?: () => void;
  onFilesRemoved?: () => void;
  onSurveyMediaImagesUploaded?: () => void;
  userId: string | undefined;
};

const useUploadManager = ({
  clearOnSuccess = true,
  isDroneImages,
  projectMedias,
  projectId,
  projectGeolocation,
  categoryId,
  categoryName,
  onDroneImagesUploaded,
  onFilesPicked,
  onFilesRemoved,
  onSurveyMediaImagesUploaded,
  userId,
}: UseUploadManagerParams) => {
  const dispatch: AppDispatch = useDispatch();
  const uppyLogToConsole = useFeatureToggle(ACCESS.UPPY_LOG_TO_CONSOLE);
  const uppyUseTus = useFeatureToggle(ACCESS.UPPY_USE_TUS);

  const [duplicateFiles, setDuplicateFiles] = useState<UppyFile[]>([]);
  const [addedFiles, setAddedFiles] = useState<UppyFile<FileMetaData>[]>([]);
  const [failedFiles, setFailedFiles] = useState<UppyFile[]>([]);
  const [completedFiles, setCompletedFiles] = useState<UppyFile[]>([]);
  const [uploadAllowed, setUploadAllowed] = useState<boolean>(false);
  const [uploadProgressMap, setUploadProgressMap] = useState<{
    [key: string]: {
      bytesUploaded: number;
      bytesTotal: number;
    };
  }>({});

  const getCurrentAccessToken = () => localStorage.getItem('accessToken');

  const uppyStore = useMemo(() => new DefaultStore(), []);
  useEffect(() => {
    // linting did not appreciate that nextState was defined but not used. Unfortunately, we only want
    // to use patch here and we have to define both prevState and nextState just to get to it.
    //
    // Why only patch?
    // Uppy manages state largely through object spreading which only intelligently merges the top
    // level of the object. This creates a pattern that is pervasive throughout uppy where the state
    // is retrieved, then spread with the previous state of that root level item.
    // EX:
    // const files = getState().files;
    // const someFileUpdate = { id: fileObject };
    // setState({files: {...files, ...someFileUpdate}});
    //
    // TLDR: Uppy state management is guaranteed to have entire root level objects in the patch parameter
    //
    // eslint-disable-next-line no-unused-vars
    const unsubFromStore = uppyStore.subscribe((_prevState, _nextState, patch) => {
      if (patch?.files) {
        // Typescript lost its mind because an object with no keys {}, contains no keys as strings...
        // This was the only way I could get it to shut up
        const updatedFiles = patch.files as UppyFile<FileMetaData>[];
        const pendingFiles: UppyFile<FileMetaData>[] = [];
        for (const fileId in updatedFiles) {
          const file = updatedFiles[fileId] as UppyFile<FileMetaData>;
          if (!file?.progress?.uploadComplete) {
            pendingFiles.push(file);
          }
        }
        setAddedFiles(pendingFiles);
      }
    });
    return unsubFromStore;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadedFileNameSet = useMemo(
    () =>
      projectMedias.map(
        (file) => file.originalFilenameWithoutExtension + file.originalFileExtension
      ),
    [projectMedias]
  );

  // This function executes after files have been selected and before they are added
  // Returning false here will prevent the file from being added
  const onBeforeFileAdded = useCallback(
    (currentFile: UppyFile): UppyFile | boolean | undefined => {
      if (uploadedFileNameSet.includes(currentFile.name) && isDroneImages) {
        setDuplicateFiles((prevFiles) => [...prevFiles, currentFile]);
        return false;
      }
      // Adding file size to metadata
      if (currentFile.size) {
        return { ...currentFile, meta: { ...currentFile.meta, upload_size: currentFile.size } };
      }
      // Returning true to continue with file add with no modifications
      return true;
    },
    [isDroneImages, uploadedFileNameSet]
  );

  const onFilesAdded = useCallback(
    // eslint-disable-next-line no-unused-vars
    (_: UppyFile[]) => {
      // Showing the error message for duplicate files in one message instead of many
      if (duplicateFiles.length > 0) {
        openFileListNotification({ files: duplicateFiles, type: NOTIFICATION_TYPES.DUPLICATE });
        setDuplicateFiles([]);
      }
    },
    [duplicateFiles]
  );

  const generateManifest = useCallback(
    async (files: UppyFile[]): Promise<string> => {
      if (projectId === undefined || categoryId === undefined) {
        return '';
      }
      const uploadManifestId = uuid4();
      const manifestFiles = files.map((file) => {
        return {
          fileName: file.name,
        };
      });
      const intent = isDroneImages ? UPLOAD_MANIFEST_INTENT.drone : UPLOAD_MANIFEST_INTENT.survey;
      await dispatch(
        saveUploadManifestRequested({
          projectId,
          mediaCategoryId: categoryId,
          files: manifestFiles,
          uploadManifestId,
          intent,
          userId,
        })
      );
      return uploadManifestId;
    },
    [dispatch, isDroneImages, projectId, categoryId, userId]
  );

  const uploadManifestPluginOptions = useMemo<UploadManifestPluginOptions>(() => {
    // @todo if user cancels upload by refreshing page the status of project isn't reset for drone images uploads
    return {
      generateManifest: isDroneImages ? undefined : generateManifest,
    };
  }, [generateManifest, isDroneImages]);

  const onComplete = useCallback(
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    ({ successful, failed }: UploadResult) => {
      if (successful) {
        setCompletedFiles((prevCompletedFiles) => [...prevCompletedFiles, ...successful]);
      }
      if (!failed.length) {
        if (isDroneImages) {
          setAddedFiles([]);
        } else {
          // const currentUploads = uppy.getFiles().filter((file) => !file.progress.uploadComplete);
          // setAddedFiles(currentUploads);
          // if (!currentUploads.length) {
          //   uppy.cancelAll();
          // }
        }
      } else {
        setFailedFiles(failed);
      }
      dispatch(
        projectMediasRequested({
          projectId,
          mediaCategoryName: categoryName,
        })
      );
    },
    [categoryName, projectId, dispatch, isDroneImages]
  );

  const uppyOptions = useMemo(() => {
    const uppyConsoleLogger = {
      debug: (log: any[]) => console.log(log), //eslint-disable-line
      warn: (log: any[]) => console.log(log), //eslint-disable-line
      error: (log: any[]) => console.log(log), //eslint-disable-line
    };
    const options: UppyOptions = {
      autoProceed: isDroneImages ? false : uploadAllowed,
      restrictions: {
        allowedFileTypes: isDroneImages ? SUPPORTED_FILE_TYPES.JPG : SUPPORTED_FILE_TYPES.ALL,
      },
      onBeforeFileAdded,
      store: uppyStore,
      debug: !environment.isProductionBuild,
    };
    if (uppyLogToConsole) {
      options.logger = uppyConsoleLogger;
    }
    return options;
  }, [uploadAllowed, isDroneImages, onBeforeFileAdded, uppyStore, uppyLogToConsole]);

  const uppyUploader = useMemo(() => {
    if (uppyUseTus) {
      return {
        plugin: Tus,
        options: {
          endpoint: isDroneImages
            ? `${process.env.REACT_APP_API}/medias/upload`
            : `${process.env.REACT_APP_API}/surveyMedias/upload`,
          headers: {
            Authorization: `Bearer ${getCurrentAccessToken()}`,
          },
          allowedMetaFields: null,
          retryDelays: [0, 1000, 3000, 5000],
          // eslint-disable-next-line no-unused-vars
          onShouldRetry(err: any, retryAttempt: number, _options: any, next: any) {
            if (retryAttempt < 3) {
              return true;
            }

            return next(err);
          },
          storeFingerprintForResuming: false,
          removeFingerprintOnSuccess: true,
        },
      };
    } else {
      return {
        plugin: XHRUploadWithRetryPlugin,
        options: {
          endpoint: isDroneImages
            ? `${process.env.REACT_APP_API}/medias/upload-xhr`
            : `${process.env.REACT_APP_API}/surveyMedias/upload-xhr`,
          headers: {
            Authorization: `Bearer ${getCurrentAccessToken()}`,
          },
          timeout: 0,
          allowedMetaFields: null, // send all metadata fields
        },
      };
    }
  }, [uppyUseTus, isDroneImages]);

  const onRestrictionFailed = useCallback(
    // eslint-disable-next-line no-unused-vars
    (_: UppyFile | undefined, error: Error) => {
      openRestrictionFailedNotification(error, isDroneImages);
    },
    [isDroneImages]
  );

  const eventPluginOptions = useMemo<EventPluginOptions>(() => {
    return {
      onFilesAdded,
      onComplete,
      onRestrictionFailed,
    };
  }, [onFilesAdded, onComplete, onRestrictionFailed]);

  const imageLocationPluginOptions = useMemo(() => {
    return {
      stateSetter: onFilesPicked,
      rejectMissingGpsFiles: !!isDroneImages,
      renderFileErrors: (files: UppyFile[]) =>
        isDroneImages &&
        openFileListNotification({ files, type: NOTIFICATION_TYPES.MISSING_LOCATION }),
    } as PluginOptions;
  }, [isDroneImages, onFilesPicked]);

  const uppy = useUppy(() =>
    new Uppy(uppyOptions)
      .use(ImageLocationPlugin, imageLocationPluginOptions)
      .use(PhotoMetaPlugin, { projectGeolocation })
      .use(UploadManifestPlugin, uploadManifestPluginOptions)
      .use(ThumbnailGenerator)
      .use(EventPlugin, eventPluginOptions)
      // @ts-expect-error
      .use(uppyUploader.plugin, uppyUploader.options)
      // TODO: find out what fires these events because they are not in the list of events Uppy provides by default
      // @ts-expect-error
      .on('is-offline', () => {
        openNetworkNotification(NOTIFICATION_TYPES.OFFLINE);
      })
      // @ts-expect-error
      .on('back-online', () => {
        openNetworkNotification(NOTIFICATION_TYPES.ONLINE);
      })
      // Next 3 events are for tracking upload progress
      .on('files-added', (files) => {
        files.forEach(({ id, size }) => {
          setUploadProgressMap((prevUploadProgressMap) => ({
            ...prevUploadProgressMap,
            [id]: {
              bytesUploaded: 0,
              bytesTotal: size,
            },
          }));
        });
      })
      .on('file-removed', ({ id }) => {
        setUploadProgressMap((prevUploadProgressMap) => {
          delete prevUploadProgressMap[id];
          return {
            ...prevUploadProgressMap,
          };
        });
        onFilesRemoved && onFilesRemoved();
      })
      .on('upload-progress', (file, { bytesTotal, bytesUploaded }) => {
        setUploadProgressMap((prevUploadProgressMap) => ({
          ...prevUploadProgressMap,
          [file!.id]: {
            bytesTotal,
            bytesUploaded,
          },
        }));
      })
  );

  const updateAuthorizationHeader = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const xhrPlugin = uppy.getPlugin('XHRUploadWithRetryPlugin');

      const updateHeader = (newAccessToken: string | null) => {
        if (newAccessToken) {
          if (xhrPlugin) {
            xhrPlugin.setOptions({
              ...uppyUploader.options,
              headers: {
                ...uppyUploader.options.headers,
                Authorization: `Bearer ${newAccessToken}`,
              },
            });
          }
        }
      };

      // Expired, refresh it
      if (jwtUtils.isExpired(accessToken)) {
        const oldRefreshToken = localStorage.getItem('refreshToken');
        if (oldRefreshToken) {
          try {
            // @ts-ignore
            const { type } = await dispatch(refreshTokenRequested(oldRefreshToken));
            if (type === 'auth/refreshTokenRequested/fulfilled') {
              const newAccessToken = localStorage.getItem('accessToken');
              updateHeader(newAccessToken);
            }
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
          }
        } // Check if plugin header doesn't match whats in local storage now
      } else if (uppyUploader.options.headers.Authorization !== accessToken) {
        updateHeader(accessToken);
      }
    }
  }, [dispatch, uppy, uppyUploader.options]);

  const retryFailedUploads = useCallback(async () => {
    await updateAuthorizationHeader();
    failedFiles.forEach(({ id, size }) => {
      setUploadProgressMap((prevUploadProgressMap) => ({
        ...prevUploadProgressMap,
        [id]: {
          bytesUploaded: 0,
          bytesTotal: size,
        },
      }));
    });
    setFailedFiles([]);
    uppy.retryAll();
  }, [failedFiles, updateAuthorizationHeader, uppy]);

  // Uppy does not update references to anything that is set in the useUppy hook. This has
  // something to do with the requirement that uppy be initialized once and only once.
  // In order to use any code that is not static, we need to set them manually in the useEffect hooks
  useEffect(() => {
    uppy.setOptions(uppyOptions);
  }, [uppyOptions, uppy]);

  useEffect(() => {
    uppy.getPlugin('ImageLocationPlugin')?.setOptions(imageLocationPluginOptions);
  }, [imageLocationPluginOptions, uppy]);

  useEffect(() => {
    uppy.getPlugin('UploadManifestPlugin')?.setOptions(uploadManifestPluginOptions);
  }, [uploadManifestPluginOptions, uppy]);

  useEffect(() => {
    uppy.getPlugin('EventPlugin')?.setOptions(eventPluginOptions);
  }, [eventPluginOptions, uppy]);

  useEffect(() => {
    uppy.getPlugin('PhotoMetaPlugin')?.setOptions({ projectGeolocation });
  }, [projectGeolocation, uppy]);

  // Plugin mounting an unmounting based on React state
  useEffect(() => {
    const xhrPlugin = uppy.getPlugin('XHRUploadWithRetryPlugin');
    const tusPlugin = uppy.getPlugin('Tus');

    if (uppyUseTus) {
      if (xhrPlugin) {
        uppy.removePlugin(xhrPlugin);
      }
      if (tusPlugin) {
        tusPlugin.setOptions(uppyUploader.options);
      } else {
        // @ts-expect-error
        uppy.use(uppyUploader.plugin, uppyUploader.options);
      }
    } else {
      if (tusPlugin) {
        uppy.removePlugin(tusPlugin);
      }
      if (xhrPlugin) {
        xhrPlugin.setOptions(uppyUploader.options);
      } else {
        // @ts-expect-error
        uppy.use(uppyUploader.plugin, uppyUploader.options);
      }
    }
  }, [uppyUploader, uppy, uppyUseTus]);

  useEffect(() => {
    uppy.setMeta({
      project_id: projectId,
      user_id: userId,
      category_id: categoryId,
      category_name: categoryName,
    });
  }, [uppy, projectId, userId, categoryId, categoryName]);

  useEffect(() => {
    if ((!categoryId || !projectId) && uploadAllowed) {
      setUploadAllowed(false);
    } else if (categoryId && projectId && !uploadAllowed && !isDroneImages) {
      if (addedFiles.length > 0) {
        uppy.upload();
      }
      setUploadAllowed(true);
    } else if (categoryId && projectId && !uploadAllowed && isDroneImages) {
      setUploadAllowed(true);
    }
  }, [addedFiles, setUploadAllowed, uploadAllowed, categoryId, projectId, isDroneImages, uppy]);

  useEffect(() => {
    if (!addedFiles.length && !failedFiles.length && completedFiles.length) {
      clearOnSuccess && setCompletedFiles([]);
      if (isDroneImages) {
        onDroneImagesUploaded && onDroneImagesUploaded();
      } else {
        onSurveyMediaImagesUploaded && onSurveyMediaImagesUploaded();
      }
    }
  }, [
    addedFiles,
    clearOnSuccess,
    completedFiles,
    failedFiles,
    isDroneImages,
    onDroneImagesUploaded,
    onSurveyMediaImagesUploaded,
  ]);

  const upload = useCallback(async () => {
    if (uploadAllowed) {
      await updateAuthorizationHeader();
      uppy.upload();
    }
  }, [updateAuthorizationHeader, uploadAllowed, uppy]);

  return {
    addedFiles,
    completedFiles,
    failedFiles,
    retryFailedUploads,
    uploadAllowed,
    upload,
    uploadProgressMap,
    uppy,
  };
};

export default useUploadManager;
