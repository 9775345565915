export enum MediaCategoryType {
  company = 'company',
  design = 'design',
  project = 'project',
  droneImages = 'drone-images',
  aggregate = 'aggregate',
  default = 'default',
  overhead = 'overhead',
  reference = 'reference',
}
