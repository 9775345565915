import { FORM_CONTROLS } from 'screens/DesignServices/constants';
import * as Yup from 'yup';

export const cancelationValidationSchema = () => {
  const schema = {
    [FORM_CONTROLS.CANCEL_NOTE]: Yup.string(),
    [FORM_CONTROLS.CUSTOMER_MOVING_FORWARD]: Yup.boolean(),
    [FORM_CONTROLS.NO_NEED]: Yup.boolean(),
    [FORM_CONTROLS.OTHER]: Yup.boolean(),
    [FORM_CONTROLS.TOO_LONG]: Yup.boolean(),
  };
  return Yup.object().shape(schema);
};
