import { CreditTransactionFilter } from 'types/TableParams';

export const getFilterBy = ({
  filters,
  searchText,
}: {
  filters?: CreditTransactionFilter;
  searchText: string;
}) => {
  const { tierLevel, 'project.type': projectType, category } = filters || {};

  return {
    tierLevel: tierLevel?.length ? tierLevel : undefined,
    projectType: projectType?.length ? projectType : undefined,
    category: category?.length ? category : undefined,
    searchText,
  };
};
