import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { Project } from 'types';

import { createBoundaries } from 'api/boundaries/boundariesService';
import {
  adminCreateProject,
  adminUpdateProject,
  createProject,
  deleteProjectsTeammates,
  fetchProject,
  updateProject,
  updateProjectsTeammates,
  updateProjectTypeAndMode,
  uploadProject,
} from 'api/projects/projectsService';

import { errorCodes } from 'helpers/constants/errorCodes';
import { projectsListRoute } from 'helpers/constants/routes';
import history from 'helpers/utils/history';

type Values = {
  projectName: string;
  address: string;
  latitude: number;
  longitude: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  usage: any;
  engine?: string | undefined;
  status: string;
  companyId?: string | undefined;
  createdBy?: string | undefined;
};

export const formatValues = ({
  projectName,
  address,
  latitude,
  longitude,
  firstName,
  lastName,
  email,
  phoneNumber,
  usage,
  engine = undefined,
  status,
  companyId = undefined,
  createdBy = undefined,
}: Partial<Values>) => ({
  name: projectName,
  address,
  ...(latitude && longitude && { geolocation: { latitude, longitude } }),
  status,
  ownerDetails: {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(email && { email }),
    ...(phoneNumber && { phone: phoneNumber }),
  },
  kwh: usage
    ? [
        usage.january ? Number(usage.january) : 0,
        usage.february ? Number(usage.february) : 0,
        usage.march ? Number(usage.march) : 0,
        usage.april ? Number(usage.april) : 0,
        usage.may ? Number(usage.may) : 0,
        usage.june ? Number(usage.june) : 0,
        usage.july ? Number(usage.july) : 0,
        usage.august ? Number(usage.august) : 0,
        usage.september ? Number(usage.september) : 0,
        usage.october ? Number(usage.october) : 0,
        usage.november ? Number(usage.november) : 0,
        usage.december ? Number(usage.december) : 0,
      ]
    : [],
  ...(engine && { engine }),
  ...(companyId && { companyId }),
  ...(createdBy && { createdBy }),
});

const name = 'project';

const UPDATE_PROJECT_STATUS = `${name}/UPDATE_PROJECT_STATUS`;

type ProjectState = {
  project: null | Project;
  isLoading: boolean;
  isProjectRequestedLoading: Boolean;
  isSubmitted: Boolean;
  error: string | null;
  isProjectUploadedSuccessfully: boolean;
  isTeammateInProjectUpdatedSuccess: boolean;
  isScaniflyInfoUpdatedSuccessfully: boolean;
  adminIsSubmitted: boolean;
  tempProjectData: any;
  isResubmissionLoading: boolean;
  resubmissionError: string | null;
};

export const adminUpdatedProject = createAsyncThunk(
  `${name}/adminUpdatedProject`,
  async (
    {
      projectId,
      values,
    }: {
      projectId: string;
      values: Values;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminUpdateProject(projectId, values);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminSubmittedProject = createAsyncThunk(
  `${name}/adminSubmittedProject`,
  async (
    {
      projectId,
      projectData,
    }: {
      projectId: string;
      projectData: Values;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminCreateProject({
        projectId,
        projectData: formatValues(projectData),
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSubmittedProject = createAsyncThunk(
  `${name}/userSubmittedProject`,
  async (projectData: Omit<Values, 'status'>, { rejectWithValue }) => {
    try {
      const response = await createProject(formatValues(projectData));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userUpdatedProject = createAsyncThunk(
  `${name}/userUpdatedProject`,
  async (
    { projectId, values }: { projectId: string; values: Partial<Values> },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateProject(projectId, formatValues(values));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUpdatedProjectScaniflyInfo = createAsyncThunk(
  `${name}/adminUpdatedProjectScaniflyInfo`,
  async ({ projectId, values }: { projectId: string; values: Values }, { rejectWithValue }) => {
    try {
      const response = await adminUpdateProject(projectId, values);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectRequested = createAsyncThunk(
  `${name}/projectRequested`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchProject(id);
      return response.data;
    } catch (error: any) {
      if (error.response.data.statusCode === errorCodes.FORBIDDEN) {
        history.push(projectsListRoute());
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userUpdatedProjectTeammate = createAsyncThunk(
  `${name}/userUpdatedProjectTeammate`,
  async (
    {
      projectId,
      userId,
    }: {
      projectId: string;
      userId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateProjectsTeammates(projectId, userId);
      return response.data;
    } catch (error: any) {
      if (error.response.data.statusCode === 403) {
        history.push(projectsListRoute());
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userDeleteProjectTeammate = createAsyncThunk(
  `${name}/userDeleteProjectTeammate`,
  async (
    {
      projectId,
      userId,
    }: {
      projectId: string;
      userId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteProjectsTeammates(projectId, userId);
      return response.data;
    } catch (error: any) {
      if (error.response.data.statusCode === 403) {
        history.push(projectsListRoute());
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userResubmittedProject = createAsyncThunk(
  `${name}/userResubmittedProject`,
  async ({ projectId }: { projectId: string }, { rejectWithValue }) => {
    try {
      const response = await uploadProject(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userUploadedProject = createAsyncThunk(
  `${name}/userUploadedProject`,
  async (
    {
      projectId,
      projectType,
      boundaries,
      mode,
    }: {
      projectId: string;
      projectType: PROJECT_TYPES;
      boundaries: any;
      mode: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await updateProjectTypeAndMode(projectId, projectType, mode);
      await createBoundaries(boundaries);
      const response = await uploadProject(projectId);
      return response.data;
    } catch (error: any) {
      dispatch(projectRequested(projectId));
      return rejectWithValue(error.response.data);
    }
  }
);

const projectSlice = createSlice({
  name,
  initialState: {
    project: null,
    isLoading: false,
    isProjectRequestedLoading: false,
    isSubmitted: false,
    error: null,
    isProjectUploadedSuccessfully: false,
    isTeammateInProjectUpdatedSuccess: false,
    isScaniflyInfoUpdatedSuccessfully: false,
    adminIsSubmitted: false,
    tempProjectData: null,
    isResubmissionLoading: false,
    resubmissionError: null,
  } as ProjectState,
  reducers: {
    userSubmittedForm: (state) => {
      state.isSubmitted = false;
    },
    userSubmittedScaniflyInfo: (state) => {
      state.isScaniflyInfoUpdatedSuccessfully = false;
    },
    adminSuccessfullySubmittedProject: (state) => {
      state.adminIsSubmitted = false;
    },
    userSuccessfullyUploadedProject: (state) => {
      state.isProjectUploadedSuccessfully = false;
      state.project = null;
    },
    userSuccessfullyUpdatedTeammates: (state) => {
      state.isTeammateInProjectUpdatedSuccess = false;
    },
    resetProject: (state) => {
      state.project = null;
      state.tempProjectData = null;
    },
    setTempProjectValues: (state, action) => {
      state.tempProjectData = { ...state.tempProjectData, ...action.payload };
    },
    resetTempProjectValues: (state) => {
      state.tempProjectData = null;
    },
    [UPDATE_PROJECT_STATUS]: (state, action) => {
      if (state.project) {
        state.project.status = action.payload.status;
        state.project.remoteDesignCount = action.payload.remoteDesignCount;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminSubmittedProject.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.adminIsSubmitted = false;
    });
    builder.addCase(adminSubmittedProject.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.adminIsSubmitted = true;
      state.isLoading = false;
    });
    builder.addCase(adminSubmittedProject.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
      state.adminIsSubmitted = false;
    });
    builder.addCase(userSubmittedProject.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSubmitted = false;
    });
    builder.addCase(userSubmittedProject.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isSubmitted = true;
      state.isLoading = false;
    });
    builder.addCase(userSubmittedProject.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSubmitted = false;
    });
    builder.addCase(userUpdatedProject.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.isSubmitted = false;
    });
    builder.addCase(userUpdatedProject.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isSubmitted = true;
      state.isLoading = false;
    });
    builder.addCase(userUpdatedProject.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSubmitted = false;
    });
    builder.addCase(adminUpdatedProject.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(adminUpdatedProject.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(adminUpdatedProject.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(adminUpdatedProjectScaniflyInfo.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.isScaniflyInfoUpdatedSuccessfully = false;
    });
    builder.addCase(adminUpdatedProjectScaniflyInfo.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isScaniflyInfoUpdatedSuccessfully = true;
      state.isLoading = false;
    });
    builder.addCase(
      adminUpdatedProjectScaniflyInfo.rejected,
      (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isLoading = false;
        state.isScaniflyInfoUpdatedSuccessfully = false;
      }
    );
    builder.addCase(projectRequested.pending, (state) => {
      state.isProjectRequestedLoading = true;
      state.error = null;
    });
    builder.addCase(projectRequested.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isProjectRequestedLoading = false;
    });
    builder.addCase(projectRequested.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isProjectRequestedLoading = false;
    });
    builder.addCase(userUpdatedProjectTeammate.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(userUpdatedProjectTeammate.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isLoading = false;
      state.isTeammateInProjectUpdatedSuccess = true;
    });
    builder.addCase(userUpdatedProjectTeammate.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isTeammateInProjectUpdatedSuccess = false;
    });
    builder.addCase(userDeleteProjectTeammate.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(userDeleteProjectTeammate.fulfilled, (state, { payload }) => {
      state.project = payload;
      state.error = null;
      state.isLoading = false;
      state.isTeammateInProjectUpdatedSuccess = true;
    });
    builder.addCase(userDeleteProjectTeammate.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isTeammateInProjectUpdatedSuccess = false;
    });
    builder.addCase(userUploadedProject.pending, (state) => {
      state.isLoading = true;
      state.isProjectUploadedSuccessfully = false;
    });
    builder.addCase(userUploadedProject.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      state.isProjectUploadedSuccessfully = true;
    });
    builder.addCase(userUploadedProject.rejected, (state, { error }: { error: any }) => {
      state.isLoading = false;
      state.error = error.message;
      state.isProjectUploadedSuccessfully = false;
    });
    builder.addCase(userResubmittedProject.pending, (state) => {
      state.isResubmissionLoading = true;
    });
    builder.addCase(userResubmittedProject.fulfilled, (state, { payload }) => {
      state.isResubmissionLoading = false;
      state.resubmissionError = null;
      state.project = payload;
    });
    builder.addCase(userResubmittedProject.rejected, (state) => {
      state.isResubmissionLoading = false;
      state.resubmissionError = 'An error occurred while submitting your model for processing';
    });
  },
});

export const updateProjectStatusById =
  (projectId: string, newStatus: string) => async (dispatch: Dispatch) => {
    const response = await updateProject(projectId, { status: newStatus });
    if (response.data) {
      dispatch(projectSlice.actions[UPDATE_PROJECT_STATUS](response.data));
    }
    return response.data;
  };

export const {
  userSubmittedForm,
  userSuccessfullyUploadedProject,
  userSuccessfullyUpdatedTeammates,
  adminSuccessfullySubmittedProject,
  resetProject,
  userSubmittedScaniflyInfo,
  setTempProjectValues,
  resetTempProjectValues,
} = projectSlice.actions;
export default projectSlice.reducer;
