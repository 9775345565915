import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import styled from 'styled-components';

export const StyledFlexSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const StyledTitleContainer = styled.div`
  font-weight: ${fontWeights.bold};
  margin-right: 0.5rem;
  color: ${colors.darkGray};
`;

export const StyledServiceType = styled.div`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.large};
  margin-right: 1rem;
  white-space: nowrap;
`;

export const StyledServiceGroupTitle = styled(StyledServiceType)`
  margin: 1rem 0 0.75rem 0.5rem !important;
`;
