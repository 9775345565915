import { PRICING_TIER } from '@cpm/scanifly-shared-data';
import { capitalize } from 'lodash';

export const CREDIT_ACTIONS = {
  REFUND: 'refund',
  ADD_TRIAL_CREDIT: 'addTrialCredit',
  REMOVE_CREDIT: 'removeCredit',
};

export const FORM_CONTROLS = {
  EMAIL: 'email',
};

export const ADD_DESIGN_CREDIT_FORM_CONTROLS = {
  QUANTITY: 'quantity',
  MEMO: 'memo',
};

export const ACCOUNT_MANAGER_CSV_COLUMN_TITLES = [
  {
    title: 'Company',
    dataIndex: 'name',
  },
  {
    title: 'Pricing Tier',
    dataIndex: 'pricingTier',
  },
  {
    title: 'Company ID',
    dataIndex: 'id',
  },
  {
    title: 'Status',
    dataIndex: 'actStatus',
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdAt',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Account Holder',
    dataIndex: 'accountHolder',
  },
  {
    title: 'Users',
    dataIndex: 'users',
  },
];

export const TIERED_PRICING = [...new Set(Object.values(PRICING_TIER))].map((tier) => {
  return {
    tier: tier,
    header: capitalize(tier),
  };
});
