import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { renderDangerZoneCategory } from 'screens/ScaniflyAdmin/Accounting/helpers';
import { POSITION_FILTERS } from 'screens/ScaniflyAdmin/constants';
import { Userpilot } from 'userpilot';

import { companySubscriptionInfoRequested } from 'state/slices/companySlice';

import usePermissions from './usePermissions';

const useUserpilot = () => {
  const { currentUser } = useSelector((state) => state.users);
  const { isScaniflyAdmin, isDesignServiceProvider } = usePermissions();
  const { company, companySubscriptionInfo } = useSelector((state) => state.company);
  const [dangerZoneCategories, setDangerZoneCategories] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (company?.id && !isScaniflyAdmin && !isDesignServiceProvider) {
      dispatch(companySubscriptionInfoRequested(company?.id));
    }
  }, [company?.id, dispatch, isDesignServiceProvider, isScaniflyAdmin]);

  useEffect(() => {
    if (company?.id && !isScaniflyAdmin && !isDesignServiceProvider && companySubscriptionInfo) {
      setDangerZoneCategories(
        renderDangerZoneCategory(companySubscriptionInfo).split('\n').join(' ').trim()
      );
    }
  }, [company?.id, companySubscriptionInfo, dispatch, isDesignServiceProvider, isScaniflyAdmin]);

  const { subscription } = companySubscriptionInfo || {};

  useEffect(() => {
    if (currentUser?.id && company?.id) {
      Userpilot.identify(currentUser.id, {
        name: [currentUser.firstName, currentUser.lastName].join(' '),
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        position: POSITION_FILTERS.find((el) => el.value === currentUser.position)?.text ?? '-',
        role: currentUser.roles.join(', '),
        status: currentUser.status,
        created_at: currentUser.createdAt,
        company: {
          id: company.id,
          name: company.name,
          companySize: subscription?.companySize,
          pricingTier: company.pricingTier,
          created_at: company.createdAt,
          contractStartDate: subscription?.contractStartDate,
          contractEndDate: subscription?.contractEndDate,
          hubspotURL: subscription?.hubspotUrl,
          largeProjectQuota: subscription?.largeProjectQuota,
          smallProjectQuota: subscription?.smallProjectQuota,
          scaniflyAccountManager: subscription?.scaniflyAccountManager,
          dangerZoneCategories: dangerZoneCategories,
        },
      });
    }
  }, [currentUser, company, subscription, dangerZoneCategories]);

  useEffect(() => {
    Userpilot.reload(); // userpilot must be reloaded on every route change
  }, [location]);

  if (process.env.REACT_APP_USERPILOT_TOKEN) {
    Userpilot.initialize(process.env.REACT_APP_USERPILOT_TOKEN);
  }
};

export default useUserpilot;
