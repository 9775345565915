import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Subscription } from 'types';

import { updateSubscription } from 'api/subscriptions/subscriptionService';
import { DesignServicesAutomationFields } from 'types/DesignServicesAutomationFields';

const name = 'subscriptions';

export const subscriptionUpdateRequested = createAsyncThunk(
  `${name}/subscriptionUpdateRequested`,
  async (
    {
      subscriptionId,
      fieldToUpdate,
      onUpdateSuccess,
      onUpdateError,
    }: {
      subscriptionId: string;
      fieldToUpdate: DesignServicesAutomationFields;
      onUpdateSuccess: () => void;
      onUpdateError: (error: string) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateSubscription(subscriptionId, fieldToUpdate);
      onUpdateSuccess();
      return response.data;
    } catch (error: any) {
      onUpdateError(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

type SubscriptionState = {
  updatedSubscription: null | Subscription;
  isUpdatedSubscriptionLoading: boolean;
  subscriptionUpdateError: null | string | undefined;
};

const subscriptionsSlice = createSlice({
  name,
  initialState: {
    updatedSubscription: null,
    isUpdatedSubscriptionLoading: false,
    subscriptionUpdateError: null,
  } as SubscriptionState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(subscriptionUpdateRequested.pending, (state) => {
      state.isUpdatedSubscriptionLoading = true;
    });
    builder.addCase(subscriptionUpdateRequested.fulfilled, (state, { payload }) => {
      state.updatedSubscription = payload;
      state.subscriptionUpdateError = null;
      state.isUpdatedSubscriptionLoading = false;
    });
    builder.addCase(subscriptionUpdateRequested.rejected, (state, { error }: { error: any }) => {
      state.subscriptionUpdateError = error.message;
      state.isUpdatedSubscriptionLoading = false;
      state.updatedSubscription = null;
    });
  },
});

export default subscriptionsSlice.reducer;
