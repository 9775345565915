import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PAYMENT_TYPE } from '@cpm/scanifly-shared-data';
import { Input, Row } from 'antd';
import { FieldConfig, FieldInputProps } from 'formik';

import { CustomSelect, Divider } from 'components';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { formatUSD } from 'helpers/utils/priceFormatter';

import { DSP_TIERS, FORM_CONTROLS } from '../constants';
import { handleNegativeInputValues } from '../helpers';
import { PaymentTypeSelect } from './PaymentTypeSelect';
import { PlanSetProviderSelect } from './PlanSetProviderSelect';

const AccountingDesignServicesForm = ({
  getFieldProps,
  setFieldValue,
}: {
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean | undefined
  ) => void;
}) => {
  const dspSubscriptionFeeSmall =
    getFieldProps(FORM_CONTROLS.DSP_SUBSCRIPTION_FEE_SMALL).value ?? 0;
  const dspSubscriptionFeeLarge =
    getFieldProps(FORM_CONTROLS.DSP_SUBSCRIPTION_FEE_LARGE).value ?? 0;
  const dspPaymentType = getFieldProps(FORM_CONTROLS.DSP_PAYMENT_TYPE).value;

  const { t } = useTranslation();

  const totalDspSubscriptionFee = useMemo(() => {
    return dspSubscriptionFeeSmall + dspSubscriptionFeeLarge;
  }, [dspSubscriptionFeeLarge, dspSubscriptionFeeSmall]);

  return (
    <div className="Accounting-Form-DesignServices">
      <h2 className="Title">{t('AccountingForms.designServicesInfo')}</h2>
      <PlanSetProviderSelect
        setFieldValue={setFieldValue}
        getFieldProps={getFieldProps}
        field="planSetProvider"
        formControl={FORM_CONTROLS.PLAN_SET_PROVIDER}
      />
      <PaymentTypeSelect
        setFieldValue={setFieldValue}
        getFieldProps={getFieldProps}
        field="dspPaymentType"
        formControl={FORM_CONTROLS.DSP_PAYMENT_TYPE}
      />
      {PROJECT_TYPE_FILTERS.map((type, index) => {
        return (
          <div key={`${type.text}-${index}`}>
            <p>{type.text}</p>
            <Row className="Accounting-Form-Row">
              <div className="FormControl-Wrapper">
                <label htmlFor={`dspTier-${type.value}`}>{t('AccountingForms.dspTier')}</label>
                <CustomSelect
                  {...getFieldProps(
                    FORM_CONTROLS[
                      `DSP_TIER_${type.value.toUpperCase()}` as keyof typeof FORM_CONTROLS
                    ]
                  )}
                  onChange={(option: { title: string; value: string }) => {
                    setFieldValue(
                      FORM_CONTROLS[
                        `DSP_TIER_${type.value.toUpperCase()}` as keyof typeof FORM_CONTROLS
                      ],
                      option.value
                    );
                  }}
                  placeholder="Select Tier"
                  options={DSP_TIERS.map((tier) => ({ value: tier.value, label: tier.title }))}
                  variant="filter"
                  id={`dspTier-${type.value}`}
                  tall
                />
              </div>
            </Row>
            <Row className="Accounting-Form-Row">
              <div className="FormControl-Wrapper">
                <label htmlFor={`dspSubscriptionFee-${type.value}`}>
                  {dspPaymentType === PAYMENT_TYPE.upfront
                    ? t('AccountingForms.total')
                    : t('AccountingForms.monthly')}{' '}
                  {t('AccountingForms.dspSubscriptionFee')}
                </label>
                <Input
                  {...getFieldProps(
                    FORM_CONTROLS[
                      `DSP_SUBSCRIPTION_FEE_${type.value.toUpperCase()}` as keyof typeof FORM_CONTROLS
                    ]
                  )}
                  id={`dspSubscriptionFee-${type.value}`}
                  type="number"
                  min="0"
                  prefix="$"
                  placeholder="0"
                  onChange={(ev) =>
                    handleNegativeInputValues(
                      ev,
                      setFieldValue,
                      `DSP_SUBSCRIPTION_FEE_${type.value.toUpperCase()}` as keyof typeof FORM_CONTROLS
                    )
                  }
                />
              </div>
            </Row>
          </div>
        );
      })}
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="totalCredits">{t('AccountingForms.totalCredits')}</label>
          <Input
            {...getFieldProps(FORM_CONTROLS.TOTAL_CREDITS)}
            placeholder="0"
            id="totalCredits"
            type="number"
            min="0"
            onChange={(ev) => handleNegativeInputValues(ev, setFieldValue, 'TOTAL_CREDITS')}
          />
        </div>
      </Row>
      <div className="Accounting-Form-Total-Fee">
        <p>{t('AccountingForms.totalDspSubscriptionFee')}</p>
        <p>{formatUSD(totalDspSubscriptionFee)}</p>
      </div>
      <Divider />
    </div>
  );
};

export default AccountingDesignServicesForm;
