import { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';

import GeocodingService, {
  GeocodeFeature,
  GeocodeRequest,
} from '@mapbox/mapbox-sdk/services/geocoding';
import { MapViewport } from 'screens/MapWrapper/types';
import { CustomerInfoAddressInputProps } from 'screens/Projects/CustomerInfo/CustomerInfoForm/CustomerInfoAddressInput/types';
import { GeocoderInputComponentProps } from 'screens/Projects/CustomerInfo/CustomerInfoForm/types';
import { WebMercatorViewport, WebMercatorViewportOptions } from 'viewport-mercator-project';

type GeocoderProps = {
  timeout?: number;
  queryParams?: Partial<GeocodeRequest> | null;
  viewport: MapViewport;
  onSelected: (viewport: MapViewport, item: GeocodeFeature) => void;
  transitionDuration?: number;
  hideOnSelect?: boolean;
  pointZoom: number;
  mapboxApiAccessToken: string;
  formatItem?: (item: GeocodeFeature) => string;
  style?: string;
  inputComponent: (
    props: GeocoderInputComponentProps<Partial<CustomerInfoAddressInputProps>>
  ) => JSX.Element;
  itemComponent: (
    props: PropsWithChildren<{ className: string; item: GeocodeFeature; onClick: () => void }>
  ) => JSX.Element;
  limit?: number;
  localGeocoder?: (query: string) => GeocodeFeature[];
  localOnly?: boolean;
  updateInputOnSelect?: boolean;
  initialInputValue?: string;
};

const Geocoder = ({
  timeout = 300,
  queryParams = {},
  viewport,
  onSelected,
  transitionDuration = 0,
  hideOnSelect = false,
  pointZoom = 16,
  mapboxApiAccessToken,
  formatItem = (item: { place_name: string }) => item.place_name,
  style = '',
  localGeocoder,
  limit = 5,
  localOnly,
  initialInputValue = '',
  updateInputOnSelect,
  inputComponent,
  itemComponent,
}: GeocoderProps) => {
  let debounceTimeout: ReturnType<typeof setTimeout>;
  const [results, setResults] = useState<GeocodeFeature[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const geocodingService = GeocodingService({ accessToken: mapboxApiAccessToken });

  useEffect(() => {
    if (inputValue.length === 0 && initialInputValue && initialInputValue !== '') {
      setInputValue(initialInputValue);
    }
  }, [inputValue.length, initialInputValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const queryString = event?.target?.value;
    if (queryString) {
      setInputValue(event?.target?.value);
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      const localResults = localGeocoder ? localGeocoder(queryString) : [];
      const params = { ...queryParams, ...{ limit: (limit ?? 0) - localResults.length } };

      if (params.limit > 0 && !localOnly && queryString.length > 0) {
        geocodingService
          .forwardGeocode({ ...params, query: queryString })
          .send()
          .then((res) => {
            setResults([...localResults, ...res.body.features]);
          });
      } else {
        setResults(localResults);
      }
    }, timeout);
  };

  const handleOnSelected = (item: GeocodeFeature) => {
    let newViewport = new WebMercatorViewport(viewport as unknown as WebMercatorViewportOptions);
    const { bbox, center } = item;

    if (bbox) {
      newViewport = newViewport.fitBounds([
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ]);
    } else {
      newViewport = {
        longitude: center[0],
        latitude: center[1],
        zoom: pointZoom,
      } as WebMercatorViewport;
    }

    const { longitude, latitude, zoom } = newViewport;

    onSelected({ ...viewport, ...{ longitude, latitude, zoom, transitionDuration } }, item);

    if (hideOnSelect) {
      setResults([]);
    }

    if (updateInputOnSelect) {
      setInputValue(formatItem(item));
    }
  };

  const handleShowResults = () => {
    setShowResults(true);
  };

  const handleHideResults = () => {
    setTimeout(() => {
      setShowResults(false);
    }, 300);
  };

  const Input = inputComponent || 'input';
  const Item = itemComponent || 'div';

  return (
    <div className={`react-geocoder ${style}`}>
      <Input
        onChange={onChange}
        onBlur={handleHideResults}
        onFocus={handleShowResults}
        value={inputValue}
      />

      {showResults && !!results.length && (
        <div className="react-geocoder-results">
          {results.map((item, index) => (
            <Item
              key={index}
              className="react-geocoder-item"
              onClick={() => handleOnSelected(item)}
              item={item}
            >
              {formatItem(item)}
            </Item>
          ))}
        </div>
      )}
    </div>
  );
};

export default Geocoder;
