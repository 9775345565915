import { ReactComponent as WarningIcon } from 'assets/icons/triangle-exclamation-solid.svg';
import { SidebarTag, Tooltip } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';
import fontSizes from 'helpers/constants/fontSizes';
import { TFunction } from 'i18next';
import { DesignOption } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { StyledServiceType } from '../components';

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWarningIcon = styled(WarningIcon)`
  height: 1.75rem;
  margin-top: 0.5rem;
`;

const StyledServiceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem !important;
`;

const StyledExpeditedText = styled.span`
  font-size: ${fontSizes.large};
`;

export const Label = ({
  hasNewTag,
  isExpedited,
  option,
  t,
}: {
  hasNewTag: boolean;
  isExpedited: boolean;
  option: DesignOption | undefined;
  t: TFunction<'translation', undefined>;
}) => {
  return (
    <StyledServiceWrapper>
      <StyledServiceType>{`${option?.serviceType}`}</StyledServiceType>{' '}
      <StyledExpeditedText>{isExpedited ? t('DesignService.expedited') : ''}</StyledExpeditedText>
      <StyledIconContainer>
        {hasNewTag ? <SidebarTag text="NEW" type={TagTypes.new} /> : null}
        {option?.disclaimerText ? (
          <Tooltip bottom={false} title={option?.disclaimerText}>
            <StyledWarningIcon title="warning-icon" />
          </Tooltip>
        ) : null}
      </StyledIconContainer>
    </StyledServiceWrapper>
  );
};
