import { useTranslation } from 'react-i18next';

import '../PublicReportDisplay.scss';
import SectionHeader from '../SectionHeader/SectionHeader';

const InspectionNotes = ({
  data,
}: {
  data: {
    summary?: string;
    weatherConsiderations?: string;
    issues?: string;
    recommendedFixesAndImprovements?: string;
  };
}) => {
  const { t } = useTranslation();

  const { summary, weatherConsiderations, issues, recommendedFixesAndImprovements } = data || {};

  const dataToRender = [
    { title: t('MaintenanceReport.formTitles.summary'), data: summary },
    { title: t('MaintenanceReport.formTitles.weatherConsiderations'), data: weatherConsiderations },
    { title: t('MaintenanceReport.formTitles.issues'), data: issues },
    {
      title: t('MaintenanceReport.formTitles.recommendedFixesAndImprovements'),
      data: recommendedFixesAndImprovements,
    },
  ];

  return (
    <>
      <SectionHeader title={t('MaintenanceReport.publicDisplay.inspectionNotes')} />
      <div className="PublicReportDisplay-DataWrapper">
        {dataToRender.map(({ title, data }: { title: string; data?: string }) => {
          if (data?.length) {
            return (
              <div className="subwrapper" key={title}>
                <h3>{title}:</h3>
                <p data-testid="maintenance-report-form-fields">{data}</p>
              </div>
            );
          }
          return <></>;
        })}
      </div>
    </>
  );
};

export default InspectionNotes;
