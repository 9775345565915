import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Tooltip } from 'antd';
import { kebabCase } from 'lodash-es';

import { ConditionalWrapper, UpgradeModal } from 'components';
import { MESSAGE_GROUP } from 'components/UpgradeTierLevelForAccess/constants';

import { ReactComponent as BoltIcon } from 'assets/icons/bolt-icon.svg';
import { ACCESS } from 'helpers/constants/access';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { useLocation } from 'react-router-dom';

import { MenuItemType } from 'screens/Sidebar/types';
import { RootState } from 'state/store/store';
import { ALL_STEPS, DESIGN_SERVICES_MENU } from '../../constants';
import CollapsibleMenu from '../CollapsibleMenu';
import MenuItem from '../MenuItem';
import './InlineMenu.scss';

type Props = {
  menuItems: MenuItemType[];
  isCollapsed: boolean;
};

const InlineMenu = ({ menuItems, isCollapsed }: Props) => {
  const history = useHistory();
  const { isScaniflyAdmin } = usePermissions();
  const { company } = useSelector((state: RootState) => state.company);
  const [isModalOpen, toggleModal] = useToggle();
  const [messageGroup, setMessageGroup] = useState('');
  const [expanded, setExpanded] = useState<string[]>([]);
  const location = useLocation();
  const url = location.pathname;

  const expandMenuItem = (label: string) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.includes(label)) {
        return prevExpanded.filter((item) => item !== label);
      }
      return [...prevExpanded, label];
    });
  };

  useEffect(() => {
    if (url.includes('designs') && !expanded.includes('Designs')) expandMenuItem('Designs');
    if (url.includes('overview') && !expanded.includes('Project Overview'))
      expandMenuItem('Project Overview');
    if (url.includes('site-data') && !expanded.includes('Site Data')) expandMenuItem('Site Data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );

  const designServicesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  const maintenanceReportAccess = useFeatureToggle(ACCESS.MAINTENANCE_REPORT);

  const checklistAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.CHECKLISTS,
    company?.pricingTier
  );

  const openModalOrFollowLink =
    (target: any, label: string, displayUpgradeMessage: boolean) =>
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      let message = '';
      if (label === ALL_STEPS.COMMENTS) {
        message = MESSAGE_GROUP.COMMENTS;
      } else if (label === DESIGN_SERVICES_MENU.ORDER_A_DESIGN) {
        message = MESSAGE_GROUP.DESIGN_SERVICE;
      } else if (label === ALL_STEPS.CHECKLISTS) {
        message = MESSAGE_GROUP.CHECKLISTS;
      }

      if (displayUpgradeMessage) {
        setMessageGroup(message);
        toggleModal();
      } else {
        history.push(target);
      }
    };

  const menuItemLabel = (label: string) => {
    if (
      (label === ALL_STEPS.COMMENTS && !notesAccess) ||
      (label === ALL_STEPS.CHECKLISTS && !checklistAccess) ||
      (label === DESIGN_SERVICES_MENU.ORDER_A_DESIGN && !designServicesAccess)
    ) {
      return (
        <span className="menu-item-label">
          {label}
          {label === DESIGN_SERVICES_MENU.ORDER_A_DESIGN ? (
            <BoltIcon className="icon" />
          ) : (
            <Tooltip
              title={
                <>
                  <strong>
                    <u>Upgrade</u>
                  </strong>{' '}
                  to unlock
                </>
              }
              placement="top"
            >
              <BoltIcon className="icon" />
            </Tooltip>
          )}
        </span>
      );
    }

    return label;
  };

  return (
    <>
      <UpgradeModal
        messageGroup={messageGroup}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
      <div className="Inline-Menu-Container">
        {menuItems.map((menuItem) => {
          if (menuItem?.children !== undefined && menuItem?.children?.length > 0) {
            return (
              <CollapsibleMenu
                key={menuItem.key}
                icon={menuItem.icon}
                label={menuItem.label}
                id={menuItem.key}
                disabled={menuItem.disabled}
                isSidebarCollapsed={isCollapsed}
                link={menuItem.link}
                expanded={expanded.includes(menuItem.label)}
                expandMenuItem={expandMenuItem}
              >
                {menuItem?.children?.map(
                  ({
                    key,
                    link,
                    label,
                    isDisabled = false,
                    tooltipTitle,
                    rightPlacement,
                    displayUpgradeMessage = false,
                  }) => {
                    return (
                      <ConditionalWrapper
                        condition={Boolean((isDisabled && tooltipTitle) || displayUpgradeMessage)}
                        wrapper={(children) => <Tooltip title={tooltipTitle}>{children}</Tooltip>}
                        key={key}
                      >
                        <NavLink
                          id={key}
                          to={link}
                          className={isDisabled ? 'Inline-Menu-Disabled' : 'Inline-Menu-Item'}
                          exact
                          key={key}
                          onClick={(e) =>
                            openModalOrFollowLink(link, label, displayUpgradeMessage)(e)
                          }
                          data-testid={key?.replace('sidebar-submenu-child-', '')}
                        >
                          <div
                            className="Child-Content-Wrapper"
                            data-testid={`menu-item-${kebabCase(label)}`}
                          >
                            {menuItemLabel(label)}
                            {rightPlacement}
                          </div>
                        </NavLink>
                      </ConditionalWrapper>
                    );
                  }
                )}
              </CollapsibleMenu>
            );
          }
          return (
            <MenuItem
              key={menuItem.key}
              icon={menuItem.icon}
              id={menuItem.key}
              disabled={menuItem.disabled}
              isCollapsed={isCollapsed}
              link={menuItem.link}
              title={menuItem.label}
              openModalOrFollowLink={(e: any) =>
                openModalOrFollowLink(
                  menuItem.link,
                  menuItem.label,
                  (menuItem.label === ALL_STEPS.COMMENTS && !notesAccess) ||
                    (menuItem.label === ALL_STEPS.MAINTENANCE && !maintenanceReportAccess) ||
                    (menuItem.label === DESIGN_SERVICES_MENU.ORDER_A_DESIGN &&
                      !designServicesAccess)
                )(e)
              }
            >
              {menuItemLabel(menuItem.label)}
            </MenuItem>
          );
        })}
      </div>
    </>
  );
};

export default InlineMenu;
