import { OPTIONS } from '../constants';

const { ALL_PROJECTS, ADMIN_TOOLS } = OPTIONS;

export const handlePermissionsChange = ({
  values,
  currentPermissions,
  allPermissions,
  setCurrentPermissions,
  setCurrentProjectAccess,
  allProjects,
}) => {
  const adminToolsSelected =
    !currentPermissions.includes(ADMIN_TOOLS.value) && values.includes(ADMIN_TOOLS.value);

  const permissionUnselectedWhileAdminToolsSelected =
    values.includes(ADMIN_TOOLS.value) && JSON.stringify(values) !== JSON.stringify(allPermissions);

  if (adminToolsSelected) {
    setCurrentPermissions(allPermissions);
    setCurrentProjectAccess(allProjects);
  } else if (permissionUnselectedWhileAdminToolsSelected) {
    const selectedPermissionsWithoutAdminTools = values.filter((val) => val !== ADMIN_TOOLS.value);
    setCurrentPermissions(selectedPermissionsWithoutAdminTools);
  } else {
    setCurrentPermissions(values);
  }
};

export const handleProjectAccessChange = ({
  values,
  currentPermissions,
  currentProjectAccess,
  setCurrentProjectAccess,
  setCurrentPermissions,
  allProjects,
}) => {
  const adminToolsIsSelected = currentPermissions.includes(ADMIN_TOOLS.value);
  const allProjectsUnselected =
    currentProjectAccess.includes(ALL_PROJECTS.value) && !values.includes(ALL_PROJECTS.value);

  const allProjectsSelected =
    !currentProjectAccess.includes(ALL_PROJECTS.value) && values.includes(ALL_PROJECTS.value);

  const projectUnselectedWhileAllProjectsSelected =
    values.includes(ALL_PROJECTS.value) && JSON.stringify(values) !== JSON.stringify(allProjects);

  if (allProjectsSelected) {
    setCurrentProjectAccess(allProjects);
  } else if (projectUnselectedWhileAllProjectsSelected) {
    const selectedProjectsWithoutAllProjects = values.filter((val) => val !== ALL_PROJECTS.value);
    setCurrentProjectAccess(selectedProjectsWithoutAllProjects);

    if (adminToolsIsSelected) {
      const selectedPermissionsWithoutAdminTools = currentPermissions.filter(
        (val) => val !== ADMIN_TOOLS.value
      );
      setCurrentPermissions(selectedPermissionsWithoutAdminTools);
    }
  } else if (allProjectsUnselected && adminToolsIsSelected) {
    const selectedPermissionsWithoutAdminTools = currentPermissions.filter(
      (val) => val !== ADMIN_TOOLS.value
    );
    setCurrentPermissions(selectedPermissionsWithoutAdminTools);
    setCurrentProjectAccess(values);
  } else {
    setCurrentProjectAccess(values);
  }
};
