import { useTranslation } from 'react-i18next';

import { Col, Input, Row } from 'antd';
import { MAX_LENGTH_FOR_ADDITIONAL_NOTES } from 'screens/DesignServices/constants';

import { FORM_CONTROLS } from '../constants';
import './CommentForm.scss';

interface ICommentForm {
  getFieldProps: Function;
}

const CommentForm = ({ getFieldProps }: ICommentForm) => {
  const { TextArea } = Input;
  const { t } = useTranslation();

  return (
    <Col className="CommentForm">
      <Row className="form-row additional-info">
        <Col span={24}>
          <div className="additional-info-title">{t('DesignService.planSet.comments.title')}</div>
          <TextArea
            {...getFieldProps(FORM_CONTROLS.COMMENTS)}
            id={FORM_CONTROLS.COMMENTS}
            aria-label={t('DesignService.planSet.comments.title')}
            placeholder={t('DesignService.planSet.comments.placeholder')}
            rows={4}
            maxLength={MAX_LENGTH_FOR_ADDITIONAL_NOTES}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default CommentForm;
