import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Row } from 'antd';
import PropTypes from 'prop-types';

import { userRolesRequested } from 'state/slices/usersSlice';

import { CustomSelect } from 'components';

import { USER_TYPES } from 'helpers/constants/USER_TYPES';
import { debouncedCompanyNameChecker } from 'helpers/utils/debouncedCompanyNameChecker';
import { mapUserRolesToSelectOptions } from 'helpers/utils/formMappers';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { DEFAULT_COUNTRY, FORM_CONTROLS } from '../constants';

const SignUpProfileInformation = ({
  touched,
  errors,
  getFieldProps,
  userType,
  setFieldValue,
  handleBlur,
  setIsPhoneNumberValid,
  isPhoneNumberValid,
}) => {
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const { userRoles } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userRoles.length === 0) {
      dispatch(userRolesRequested());
    } else {
      setFieldValue(FORM_CONTROLS.ROLE, userRoles[0].key);
    }
  }, [dispatch, userRoles, setFieldValue]);

  const handleCompanyNameChange = (e) => {
    const companyName = e.target.value.trim();
    if (companyName) {
      debouncedCompanyNameChecker(dispatch, companyName);
    }
    getFieldProps(FORM_CONTROLS.COMPANY).onChange(e);
  };

  const handlePhoneNumberChange = (_value, data, _event, formattedValue) => {
    const isPhoneNumberEmpty = data.dialCode === formattedValue;
    setIsPhoneNumberValid(!isPhoneNumberEmpty);
    setFieldValue(FORM_CONTROLS.PHONE_NUMBER, formattedValue);
  };

  const handlePhoneNumberBlur = (event, data) => {
    const { value } = event.target;
    const valueWithoutPlusSign = value.substr(1);
    const isPhoneNumberEmpty = data.dialCode === valueWithoutPlusSign;
    setIsPhoneNumberValid(!isPhoneNumberEmpty);

    if (!isPhoneNumberTouched) {
      setIsPhoneNumberTouched(true);
    }

    handleBlur(FORM_CONTROLS.PHONE_NUMBER, value);
  };

  return (
    <>
      <h2 className="SignUp-Form-Title">Profile Information</h2>

      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="signUpFirstName">First Name</label>
          <Input
            placeholder="First Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.FIRST_NAME)}
            id="signUpFirstName"
            {...getFieldProps(FORM_CONTROLS.FIRST_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.FIRST_NAME)}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="signUpLastName">Last Name</label>
          <Input
            placeholder="Last Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.LAST_NAME)}
            id="signUpLastName"
            {...getFieldProps(FORM_CONTROLS.LAST_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.LAST_NAME)}
          </div>
        </div>
      </Row>

      <Row className="SignUp-Form-Row">
        <div className="SignUp-Form-Wrapper-Select">
          <label htmlFor="signUpRole">Role</label>
          <CustomSelect
            {...getFieldProps(FORM_CONTROLS.ROLE)}
            placeholder="Select role"
            isSearchable={false}
            onChange={(option) => {
              setFieldValue(FORM_CONTROLS.ROLE, option.value);
            }}
            options={mapUserRolesToSelectOptions(userRoles)}
            id="signUpRole"
            tall
          />
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="signUpPhone">Phone Number</label>
          <PhoneInput
            inputClass={!isPhoneNumberValid && isPhoneNumberTouched && 'Error'}
            buttonClass={!isPhoneNumberValid && isPhoneNumberTouched && 'Error'}
            countryCodeEditable={false}
            {...getFieldProps(FORM_CONTROLS.PHONE_NUMBER)}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            country={DEFAULT_COUNTRY}
            id="signUpPhone"
          />
          <div className="Form-Error">
            {!isPhoneNumberValid && isPhoneNumberTouched && 'Phone number is required'}
          </div>
        </div>
      </Row>
      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="signUpCompany">Company</label>
          <Input
            placeholder="Company"
            className={validateStatus(touched, errors, FORM_CONTROLS.COMPANY)}
            disabled={userType === USER_TYPES.TEAMMATE}
            aria-disabled={userType === USER_TYPES.TEAMMATE}
            id="signUpCompany"
            {...getFieldProps(FORM_CONTROLS.COMPANY)}
            onChange={handleCompanyNameChange}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.COMPANY)}
          </div>
        </div>
      </Row>
    </>
  );
};

SignUpProfileInformation.propTypes = {
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  getFieldProps: PropTypes.func.isRequired,
  userType: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setIsPhoneNumberValid: PropTypes.func.isRequired,
  isPhoneNumberValid: PropTypes.bool.isRequired,
};

export default SignUpProfileInformation;
