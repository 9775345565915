import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';

import { loginRequested } from 'state/slices/authSlice';
import { companyCreated } from 'state/slices/companySlice';
import { userFilledBillingAddressForm } from 'state/slices/signUpSlice';

import { loginUrl } from 'api/auth/authUrls';

import { signUpMainInformationRoute } from 'helpers/constants/routes';

import SignUpError from '../SignUpError';
import SignUpTermsOfService from '../SignUpTermsOfService';
import { FORM_CONTROLS } from './constants';
import SignUpBillingAddress from './SignUpBillingAddress';
import SignUpBillingContact from './SignUpBillingContact';
import { validationSchema } from './validationSchema';

const SignUpBillingInformation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { billingAddress, mainInformation, token } = useSelector((state) => state.signUp);
  const { companyWithUserExistsError, error, isLoading, company } = useSelector(
    (state) => state.company
  );
  const { isFirstAdminUserCreated } = useSelector((state) => state.users);

  const initialValues = {
    [FORM_CONTROLS.NAME]: '',
    [FORM_CONTROLS.EMAIL]: '',
    [FORM_CONTROLS.COUNTRY]: null,
    [FORM_CONTROLS.STREET]: '',
    [FORM_CONTROLS.CITY]: '',
    [FORM_CONTROLS.STATE]: '',
    [FORM_CONTROLS.ZIP_CODE]: '',
    [FORM_CONTROLS.VAT_NUMBER]: '',
  };

  const {
    touched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    getFieldProps,
    setValues,
    values,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmitSignUp(values);
    },
  });

  const handleGoBack = () => {
    dispatch(userFilledBillingAddressForm(values));
    history.push({
      pathname: signUpMainInformationRoute(),
      search: `?token=${token}`,
    });
  };

  useEffect(() => {
    if (!token || jwt_decode(token).companyId !== 'initial') {
      history.push(loginUrl());
    }
  }, [history, token]);

  useEffect(() => {
    if (billingAddress) {
      setValues(billingAddress);
    }
  }, [billingAddress, setValues]);

  const onSubmitSignUp = (values) => {
    const formattedCompanyValues = {
      name: mainInformation.company,
      billingEmail: values.email,
      address: {
        line: values.street,
        city: values.city,
        state: values.state,
        zip: values.zipCode,
        country: values.country,
      },
    };
    const formattedUserValues = {
      position: mainInformation.role,
      phone: mainInformation.phoneNumber,
      firstName: mainInformation.firstName,
      lastName: mainInformation.lastName,
      password: mainInformation.password,
    };

    if (!company) {
      dispatch(
        companyCreated({
          companyData: formattedCompanyValues,
          user: formattedUserValues,
          email: mainInformation.email,
          token,
          onSuccess: handleSubmit,
        })
      );
    }
  };

  useEffect(() => {
    if (isFirstAdminUserCreated) {
      dispatch(
        loginRequested({
          email: mainInformation.email,
          password: mainInformation.password,
        })
      );
    }
  }, [isFirstAdminUserCreated, dispatch, mainInformation]);

  return (
    <form onSubmit={handleSubmit}>
      <SignUpBillingContact
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
        handleBlur={handleBlur}
      />

      <SignUpBillingAddress
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
        values={values}
        handleBlur={handleBlur}
      />

      <div className="SignUp-Footer-Wrapper">
        <SignUpTermsOfService />

        {(companyWithUserExistsError || error) && <SignUpError errorMessage={error} />}

        <Button
          className="Button--Big"
          disabled={!(isValid && dirty) || companyWithUserExistsError}
          aria-disabled={!(isValid && dirty) || companyWithUserExistsError}
          htmlType="submit"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Create Account
        </Button>
        <Button type="link" onClick={handleGoBack}>
          &#8592; Go Back
        </Button>
      </div>
    </form>
  );
};

export default SignUpBillingInformation;
