import { Link } from 'react-router-dom';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as PublishIcon } from 'assets/publish.svg';
import { ReactComponent as RefreshIcon } from 'assets/refresh-icon.svg';
import { ReactComponent as UnpublishIcon } from 'assets/unpublish.svg';

export const CustomTemplateContainer = styled.div`
  margin: 4rem;
  padding: 8rem 6rem 8rem 6rem;
  display: flex;
  border: 1px solid ${colors.lightGray};
  border-radius: 1.5rem;
  background-color: ${colors.white};
  height: auto;
  width: auto;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 8rem 2rem 2rem 2rem;
    margin: 2rem;
  }
`;

export const Title = styled.div`
  font-size: ${fontSizes.medium};
  display: flex;
  margin: 0 2rem;
  font-weight: 500;
  grid-column: 1 / span 2;
  grid-row: 1;
`;

export const Description = styled.div`
  font-size: 1.2rem;
  color: $label-gray;
  line-height: 1.7rem;
  margin-bottom: 3rem;
  width: 70%;
  color: ${colors.labelGray};
  margin: 0 2rem;
  grid-column: 1 / span 2;
  grid-row: 2;
`;

export const ButtonContainer = styled.div`
  grid-column: 5;
  grid-row: 1 / span 2;
`;

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
`;

export const TemplateTitle = styled.div`
  font-size: ${fontSizes.mediumSmall};
  margin: 0 1rem;
  flex: 0.5;
`;

export const StyledEditIcon = styled(EditIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledCopyIcon = styled(CopyIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledPublishIcon = styled(PublishIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledRefreshIcon = styled(RefreshIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledUnpublishIcon = styled(UnpublishIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const StyledEyeIcon = styled(EyeIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const DottedSeperator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px dotted ${colors.neutralGray};
`;

export const FirstSeparator = styled(DottedSeperator)`
  margin: 4rem 0 0 0;
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(4, min-content);
  gap: 20px;
  align-items: center;
  border-bottom: 1px dotted ${colors.neutralGray};
  padding: 2rem 0;
`;

export const BottomGrid = styled.div`
  display: grid;
  margin: 4rem -4rem;
  grid-template-columns: auto auto auto auto auto auto auto;
`;

export const SaveButtonContainer = styled.div`
  grid-column: 7;
  grid-row: 4;
`;

// The Edit icon is exactly 12 pixels smaller than the copy or delete icons
// the padding here fixes that minor spacing issue
export const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
`;
