import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { ACCESS } from 'helpers/constants';
import colors from 'helpers/constants/colors';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

export type Metadata = {
  [key: string]: string;
};

const Divider = styled.div`
  border-bottom: 0.1rem dotted ${colors.labelGray};
  margin-bottom: 2.9rem;
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.9rem 0;
`;

const MetadataTitle = styled.h3`
  text-align: left;
  margin-bottom: 2.9rem;
`;

const MetadataItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const MetadataKey = styled.input`
  height: 4.2rem;
  font-size: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 0.5rem;
  background-color: ${colors.white};
`;

const MetadataSeparator = styled.div`
  align-self: center;
  margin: 0 0.5rem;
  font-weight: bold;
`;

const MetadataValue = styled.input`
  height: 4.2rem;
  font-size: 1.6rem;
  border-radius: 0.8rem;
  flex-grow: 1;
  padding: 0 0.5rem;
  background-color: ${colors.white};
`;

export const MetadataDisplay = ({ metadata }: { metadata: Metadata }) => {
  const isMetadataEnabled = useFeatureToggle(ACCESS.DISPLAY_EXTERNAL_METADATA);
  const { t } = useTranslation();
  if (!isMetadataEnabled || !metadata || Object.entries(metadata).length < 1) return null;
  return (
    <MetadataContainer>
      <Divider />
      <MetadataTitle>{t('ExternalMetadata.title')}</MetadataTitle>
      {Object.entries(metadata).map(([key, value]) => (
        <MetadataItem key={key}>
          <MetadataKey id={`metadata-key-${key}`} value={key} disabled />
          <MetadataSeparator>:</MetadataSeparator>
          <MetadataValue id={`metadata-value-${key}`} type="text" value={value} disabled />
        </MetadataItem>
      ))}
    </MetadataContainer>
  );
};
