import { useTranslation } from 'react-i18next';

import { Radio, RadioChangeEvent, Tooltip } from 'antd';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import './ProjectTypeFilter.scss';

const { Group, Button: RadioButton } = Radio;

type Props = {
  handleChange: (event: RadioChangeEvent | null) => void;
  projectType: string | null | undefined;
  areaInSquareFeet: number | null | undefined;
  areaInSquareMeter: number | null | undefined;
};

const ProjectTypeFilter = ({
  handleChange,
  projectType,
  areaInSquareFeet,
  areaInSquareMeter,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="ProjectTypeFilter">
      <div className="ProjectTypeFilter-Header">
        <p>{t('ProjectCategory.selectProjectType')}</p>
        <Tooltip
          placement="topLeft"
          title={t('ProjectCategory.tooltipText')}
          overlayStyle={{ maxWidth: '40rem' }}
          arrowPointAtCenter
        >
          <QuestionMarkIcon />
        </Tooltip>
      </div>
      <div className="ProjectTypeFilter-Wrapper">
        <div className="ProjectTypeFilter-ButtonWrapper">
          <Group onChange={handleChange} value={projectType} buttonStyle="solid">
            {PROJECT_TYPE_FILTERS.map(({ text, value }) => (
              <RadioButton key={value} className="ProjectTypeFilter-Button" value={value}>
                {text}
              </RadioButton>
            ))}
          </Group>
        </div>
        <div className="ProjectCategory-ModelArea">
          Model area:{' '}
          {areaInSquareFeet?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}{' '}
          ft² (
          {areaInSquareMeter?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}{' '}
          m²)
        </div>
      </div>
    </div>
  );
};

export default ProjectTypeFilter;
