import { ReferenceMediaFieldType } from '@cpm/scanifly-shared-data';

import { Description, FieldType, Label, MediaAnswer, Required, Seperator } from './components';

type Props = {
  item: ReferenceMediaFieldType;
};

export const ReferenceMediaField = ({ item }: Props) => {
  return (
    <>
      <FieldType type={item.componentType} />
      <Label text={item.label} />
      {item.description && <Description text={item.description} />}
      <Required required={item.isRequired} />
      <Seperator />
      <MediaAnswer answer={item.value} />
    </>
  );
};
