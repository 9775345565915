import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Alert, Button, Input } from 'antd';
import { useFormik } from 'formik';

import { passwordChangeRequested, resetFormSubmitted } from 'state/slices/usersSlice';

import { errorCodes } from 'helpers/constants/errorCodes';
import { resetPasswordRoute } from 'helpers/constants/routes';
import { useQuery } from 'helpers/hooks/useQuery';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, SUCCESS_QUERY } from './constants';
import './SetPassword.scss';
import { validationSchema } from './validationSchema';

const initialValues = {
  [FORM_CONTROLS.PASSWORD]: '',
  [FORM_CONTROLS.CONFIRM_PASSWORD]: '',
};

const { Password } = Input;

const SetNewPassword = () => {
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error } = useSelector((state) => state.users);
  const history = useHistory();
  const query = useQuery();

  const { touched, isValid, dirty, handleSubmit, errors, getFieldProps } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onReset(values);
    },
  });

  const token = useMemo(() => query.get('token'), [query]);

  const onReset = ({ password }) => {
    dispatch(passwordChangeRequested({ password, token }));
  };

  useEffect(() => {
    if (isSuccess) {
      history.push(`${resetPasswordRoute()}${SUCCESS_QUERY}`);
    }
    return () => {
      dispatch(resetFormSubmitted());
    };
  }, [dispatch, history, isSuccess]);

  const isTokenExpired = error?.statusCode === errorCodes.UNAUTHORIZED;

  const isFormValid = isValid && dirty;

  return (
    <form>
      <div className="FormControl-Wrapper SetPassword-Form-Wrapper">
        <label htmlFor="newPassword">Enter New Password</label>
        <Password
          placeholder="Enter your password"
          className={validateStatus(touched, errors, FORM_CONTROLS.PASSWORD)}
          id="newPassword"
          {...getFieldProps(FORM_CONTROLS.PASSWORD)}
        />
        <div className="Form-Error Form-Error--Long">
          {renderValidationMessage(touched, errors, FORM_CONTROLS.PASSWORD)}
        </div>
      </div>

      <div className="Input-Wrapper">
        <label htmlFor="newPasswordConfirm">Confirm New Password</label>
        <Password
          placeholder="Confirm your password"
          className={validateStatus(touched, errors, FORM_CONTROLS.CONFIRM_PASSWORD)}
          id="newPasswordConfirm"
          {...getFieldProps(FORM_CONTROLS.CONFIRM_PASSWORD)}
        />
        <div className="Form-Error">
          {renderValidationMessage(touched, errors, FORM_CONTROLS.CONFIRM_PASSWORD)}
        </div>
      </div>

      {isTokenExpired && (
        <Alert
          type="error"
          className="SetPassword-Error"
          message="Token has expired and a new reset password link has to be generated."
        />
      )}

      <Button
        className="Button--Big"
        disabled={!isFormValid || isTokenExpired}
        aria-disabled={!isFormValid || isTokenExpired}
        htmlType="submit"
        onClick={handleSubmit}
        loading={isLoading}
      >
        Update Password
      </Button>
    </form>
  );
};

export default SetNewPassword;
