import { ReactComponent as FilledCheckmarkIcon } from 'assets/icons/checkmark-filled.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg';
import styled from 'styled-components';
import { StyledFlexSection } from '../components';

const StyledFilledCheckmarkIcon = styled(FilledCheckmarkIcon)`
  width: 3.5rem;
  height: 3.5rem;
  animation: increaseOpacity 1s linear;
`;

const StyledCircleIcon = styled(CircleIcon)`
  width: 3.5rem;
  height: 3.5rem;
  animation: increaseOpacity 1s linear;
`;

export const Selection = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <StyledFlexSection>
      {isSelected ? (
        <StyledFilledCheckmarkIcon title="filledCheckmark" />
      ) : (
        <StyledCircleIcon title="circle" />
      )}
    </StyledFlexSection>
  );
};
