import { notification } from 'antd';

export const openNotification = ({ type, title, text, key = null, ...rest }) => {
  notification[type]({
    message: title,
    description: text,
    placement: 'bottomLeft',
    bottom: 56,
    ...rest,
    ...(key && { key }),
  });
};
