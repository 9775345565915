import { pickBy } from 'lodash-es';

export const mapCreatedProject = (createdProject) =>
  pickBy({
    projectName: createdProject?.name,
    phoneNumber: createdProject?.ownerDetails?.phone,
    firstName: createdProject?.ownerDetails?.firstName,
    lastName: createdProject?.ownerDetails?.lastName,
    email: createdProject?.ownerDetails?.email,
    address: createdProject?.address,
    latitude: createdProject?.geolocation.latitude,
    longitude: createdProject?.geolocation.longitude,
    usage: {
      january: createdProject?.kwh[0] ? createdProject?.kwh[0].toString() : '',
      february: createdProject?.kwh[1] ? createdProject?.kwh[1].toString() : '',
      march: createdProject?.kwh[2] ? createdProject?.kwh[2].toString() : '',
      april: createdProject?.kwh[3] ? createdProject?.kwh[3].toString() : '',
      may: createdProject?.kwh[4] ? createdProject?.kwh[4].toString() : '',
      june: createdProject?.kwh[5] ? createdProject?.kwh[5].toString() : '',
      july: createdProject?.kwh[6] ? createdProject?.kwh[6].toString() : '',
      august: createdProject?.kwh[7] ? createdProject?.kwh[7].toString() : '',
      september: createdProject?.kwh[8] ? createdProject?.kwh[8].toString() : '',
      october: createdProject?.kwh[9] ? createdProject?.kwh[9].toString() : '',
      november: createdProject?.kwh[10] ? createdProject?.kwh[10].toString() : '',
      december: createdProject?.kwh[11] ? createdProject?.kwh[11].toString() : '',
    },
  });
