import { useSelector } from 'react-redux';

import cn from 'classnames';
import { kebabCase } from 'lodash-es';

import { RootState } from 'state/store/store';

import {
  accountChecklistTemplateManagerRoute,
  designServicesCompanyQueueRoute,
  designServicesProviderQueueRoute,
  projectsListRoute,
  scaniflyAdminMenuRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as AdminIcon } from 'assets/admin-icon-sidebar.svg';
import { ReactComponent as ChecklistTemplateManagerIcon } from 'assets/checklist-manager.svg';
import { ReactComponent as DesignServicesQueueIcon } from 'assets/design-queue-icon.svg';
import { ReactComponent as MyProjectsIcon } from 'assets/house-chimney-solid.svg';
import { ReactComponent as ProjectIcon } from 'assets/my-projects-icon.svg';

import { ALL_STEPS, SCANIFLY_ADMIN_MENU } from '../../constants';
import { getScaniflyAdminMenuRoute } from '../../helpers';
import MenuItem from '../MenuItem';
import ProjectMenuSidebar from '../ProjectMenuSidebar';
import './SidebarBody.scss';

type SidebarBodyProps = {
  className: string;
  isSidebarCollapsed: boolean;
  isScaniflyAdminPath: boolean;
  isScaniflyAdminProjectView: boolean;
  isNewProjectView: boolean;
  isMyProjectsPathActive: boolean;
  isTeamPathActive: boolean;
  isDesignServicesProviderQueuePathActive: boolean;
  isDesignServicesCompanyQueuePathActive: boolean;
  isChecklistTemplateManagerPathActive: boolean;
  isDraftProjectView: boolean;
  isProjectView: boolean;
};

const SidebarBody = ({
  isSidebarCollapsed,
  className,
  isScaniflyAdminPath,
  isScaniflyAdminProjectView,
  isNewProjectView,
  isMyProjectsPathActive,
  isDesignServicesProviderQueuePathActive,
  isDesignServicesCompanyQueuePathActive,
  isChecklistTemplateManagerPathActive,
  isDraftProjectView,
  isProjectView,
}: SidebarBodyProps) => {
  const { isAdmin, isScaniflyAdmin, isDesignServiceProvider } = usePermissions();

  const { project } = useSelector((state: RootState) => state.project);

  const renderCreateProjectsSteps = () => {
    return (
      <ProjectMenuSidebar
        isScaniflyAdminView={isScaniflyAdminProjectView}
        isNewProjectView={isNewProjectView}
        isDraftView
        isCollapsed={isSidebarCollapsed}
      />
    );
  };

  const renderProjectMenu = () => {
    return (
      <ProjectMenuSidebar
        isScaniflyAdminView={isScaniflyAdminProjectView}
        isNewProjectView={isNewProjectView}
        isDraftView={false}
        isCollapsed={isSidebarCollapsed}
      />
    );
  };

  const renderScaniflyAdminMenu = () => (
    <div className="Sidebar-Admin-Menu">
      {Object.values(SCANIFLY_ADMIN_MENU).map((menuItem) => {
        const menuItemKey = kebabCase(menuItem);
        return (
          <MenuItem
            key={`${menuItemKey}`}
            link={getScaniflyAdminMenuRoute(menuItem)}
            title={`admin ${menuItem}`}
          >
            {menuItem}
          </MenuItem>
        );
      })}
    </div>
  );

  return (
    <div
      className={cn('Sidebar-Body-Wrapper', className, {
        'Sidebar-Body-Wrapper--DesignQueue': isDesignServiceProvider,
      })}
    >
      {!isDesignServiceProvider && (
        <MenuItem
          key="my-projects"
          icon={
            <span className="anticon anticon--Projects" role="img" aria-label="my projects">
              <MyProjectsIcon />
            </span>
          }
          isSelected={isMyProjectsPathActive}
          isCollapsed={isSidebarCollapsed}
          link={projectsListRoute()}
          title={ALL_STEPS.MY_PROJECTS}
        >
          {isDesignServiceProvider ? ALL_STEPS.DESIGN_QUEUE : ALL_STEPS.MY_PROJECTS}
        </MenuItem>
      )}

      {isAdmin && !isDesignServiceProvider && !isProjectView && !isDraftProjectView && (
        <MenuItem
          key="checklist-manager"
          icon={
            <span className="anticon anticon--Projects" role="img" aria-label="checklist manager">
              <ChecklistTemplateManagerIcon />
            </span>
          }
          isSelected={isChecklistTemplateManagerPathActive}
          isCollapsed={isSidebarCollapsed}
          link={accountChecklistTemplateManagerRoute()}
          title={ALL_STEPS.CHECKLIST_MANAGER}
        >
          {ALL_STEPS.CHECKLIST_MANAGER}
        </MenuItem>
      )}

      {isDesignServiceProvider && (
        <MenuItem
          key="design-queue"
          icon={
            <span className="anticon anticon--Projects" role="img" aria-label="design queue">
              <DesignServicesQueueIcon />
            </span>
          }
          isSelected={isDesignServicesProviderQueuePathActive}
          isCollapsed={isSidebarCollapsed}
          link={designServicesProviderQueueRoute()}
          title={ALL_STEPS.DESIGN_QUEUE}
          className={cn({
            'Sidebar-Body-DesignQueue': isDesignServiceProvider,
          })}
        >
          {ALL_STEPS.DESIGN_QUEUE}
        </MenuItem>
      )}

      {project ? (
        <MenuItem
          key="project-name"
          icon={<ProjectIcon className="anticon anticon--Project" aria-label="project" />}
          isSelected={false}
          isCollapsed={isSidebarCollapsed}
          title={ALL_STEPS.PROJECT_NAME}
        >
          {project?.name}
        </MenuItem>
      ) : null}

      {!isScaniflyAdminProjectView &&
        (isNewProjectView || isDraftProjectView || isProjectView) &&
        !isDesignServicesCompanyQueuePathActive && (
          <div>{isProjectView ? renderProjectMenu() : renderCreateProjectsSteps()}</div>
        )}

      {!isDesignServiceProvider && isAdmin && (
        <>
          <MenuItem
            key="design-queue"
            icon={
              <span className="anticon anticon--Projects" role="img" aria-label="design queue">
                <DesignServicesQueueIcon />
              </span>
            }
            isSelected={isDesignServicesCompanyQueuePathActive}
            isCollapsed={isSidebarCollapsed}
            link={designServicesCompanyQueueRoute()}
            title={ALL_STEPS.DESIGN_QUEUE}
            className={cn({
              'Sidebar-Body-DesignQueue': isDesignServiceProvider,
            })}
          >
            {ALL_STEPS.DESIGN_QUEUE}
          </MenuItem>
        </>
      )}

      <MenuItem
        className={cn({
          'Sidebar-ScaniflyAdminButton--Hidden': !isScaniflyAdmin,
        })}
        key="scanifly-admin"
        aria-hidden={!isScaniflyAdmin}
        icon={
          <span
            role="img"
            aria-label="admin"
            className="Sidebar-ScaniflyAdminButton-Icon anticon anticon--Admin"
          >
            <AdminIcon />
          </span>
        }
        isCollapsed={isSidebarCollapsed}
        link={scaniflyAdminMenuRoute()}
        isSelected={isScaniflyAdminPath}
        title="Admin"
      >
        <span>Admin</span>
      </MenuItem>
      {isScaniflyAdminPath && !isSidebarCollapsed && (
        <div>
          {isScaniflyAdminProjectView ? (
            <>{isProjectView ? renderProjectMenu() : renderCreateProjectsSteps()}</>
          ) : (
            renderScaniflyAdminMenu()
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarBody;
