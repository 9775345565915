import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { FILTER } from 'screens/Projects/ProjectsList/constants';

import { CustomSelect, MultiCheck } from 'components';

import colors from 'helpers/constants/colors';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import { SUPPORT_URL } from 'helpers/constants/urls';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import { getAdditionalOptions } from './helpers';
import './MoreFilter.scss';

const MoreFilter = ({ filterOptions, handleOptionsChange, currentValue }) => {
  const handleChange = (values, option) => {
    const options = option === FILTER.TYPES ? getAdditionalOptions(values) : values;
    handleOptionsChange({
      ...currentValue,
      [option]: options.map((arrValue) => arrValue?.value || arrValue),
    });
  };

  const getTagProps = (label) => {
    if (label === FILTER.ROLES) return colors.lightBlue;
    if (label === FILTER.TEAMMATES) return colors.purple;
  };

  return (
    <div className="MoreFilter">
      {filterOptions?.map(
        ({ id, label, options }) =>
          options && (
            <div className="MoreFilter-Item" key={id}>
              <div className="MoreFilter-Label">
                {label}
                {label === FILTER.PROJECT_TYPE && (
                  <Tooltip
                    placement="top"
                    title="Project types have been consolidated to Small and Large."
                    overlayStyle={{ maxWidth: '26rem' }}
                    arrowPointAtCenter
                  >
                    <a
                      href={`${SUPPORT_URL}/uploading-geo-tagged-images`}
                      aria-label="scanifly support doc on uploading drone images"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <QuestionMarkIcon className="MoreFilter-Icon" />
                    </a>
                  </Tooltip>
                )}
              </div>
              {(label === FILTER.ROLES || label === FILTER.TEAMMATES) && (
                <CustomSelect
                  placeholder={`Select ${label}`}
                  options={options}
                  onChange={(value) => handleChange(value, id)}
                  value={(currentValue && currentValue[id]) || []}
                  tagColor={getTagProps(label)}
                  isMulti
                  variant="filter"
                  tall
                />
              )}
              {(label === FILTER.STATUS || label === FILTER.PROJECT_TYPE) && (
                <MultiCheck
                  options={
                    label === FILTER.STATUS
                      ? options.filter(
                          (item) =>
                            item.label !== PROJECT_STATUSES.DELETED &&
                            item.label !== PROJECT_STATUSES.UPLOADING
                        )
                      : options.filter(
                          (item) =>
                            item.value === PROJECT_TYPES.SMALL || item.value === PROJECT_TYPES.LARGE
                        )
                  }
                  onChange={(value) => handleChange(value, id)}
                  value={currentValue ? currentValue[id] : []}
                />
              )}
            </div>
          )
      )}
    </div>
  );
};

MoreFilter.propTypes = {
  filterOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({ label: PropTypes.node, value: PropTypes.string }),
  ]),
  handleOptionsChange: PropTypes.func,
  currentValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
};

export default MoreFilter;
