import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UploadResult } from '@uppy/core';
import { Divider, Row } from 'antd';
import AccountWrapper from 'screens/Account/AccountWrapper';
import { MediaCategoryType } from 'types';

import { getMediaCategoriesByType, selectCategoriesByType } from 'state/slices/mediaCategories';
import {
  deleteReferenceMedia,
  getReferenceMediaByCategory,
  selectMediaByCategory,
} from 'state/slices/referenceMedia';
import { RootState } from 'state/store/store';

import {
  Button,
  CustomSelect,
  ErrorDisplay,
  ReferenceMediaPreview,
  UploadDashboardModal,
} from 'components';
import { Container, MediaContainer, SelectContainer } from './components';

import useToggle from 'helpers/hooks/useToggle';
import { openNotification } from 'helpers/utils/openNotification';

type Option = {
  value: string;
  label: string;
};

/**
 * Controls which media is shown in the reference media field in the checklist template editor
 */
const ReferenceMedia = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<Option>();
  const [isUploadModalVisible, toggleIsUploadModalVisible] = useToggle(false);

  const referenceMediaCategories = useSelector((state: RootState) =>
    selectCategoriesByType(state, MediaCategoryType.reference)
  );
  const referenceMedia = useSelector((state: RootState) =>
    selectMediaByCategory(state, selectedCategory?.value ?? '')
  );

  const referenceMediaCategoryOptions: Option[] = useMemo(
    () =>
      referenceMediaCategories.map(({ categoryName, id }) => ({
        value: id,
        label: categoryName,
      })),
    [referenceMediaCategories]
  );

  const getReferenceMediaCategoriesAction = useCallback(async () => {
    await dispatch<any>(getMediaCategoriesByType(MediaCategoryType.reference));
  }, [dispatch]);
  const getReferenceMediaAction = useCallback(
    async (categoryId: string) => {
      await dispatch<any>(getReferenceMediaByCategory(categoryId));
    },
    [dispatch]
  );
  const deleteReferenceMediaAction = useCallback(
    async ({ mediaId, categoryId }: { mediaId: string; categoryId: string }) => {
      await dispatch<any>(deleteReferenceMedia({ mediaId, categoryId }));
    },
    [dispatch]
  );

  const onUploadComplete = useCallback(
    (_: UploadResult) => {
      if (selectedCategory) {
        getReferenceMediaAction(selectedCategory?.value);
      }
    },
    [selectedCategory, getReferenceMediaAction]
  );

  // Load categories
  useEffect(() => {
    const loadCategories = async () => {
      try {
        await getReferenceMediaCategoriesAction();
      } catch (err: any) {
        openNotification({
          type: 'error',
          title: t('alertMessages.errorTitle'),
          text: (
            <div>
              {t('error.couldNotLoadPage')}
              <ErrorDisplay error={err} />
            </div>
          ),
        });
      } finally {
        setIsLoading(false);
      }
    };
    loadCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load media for category
  useEffect(() => {
    if (selectedCategory) {
      const loadMedia = async () => {
        try {
          await getReferenceMediaAction(selectedCategory.value);
        } catch (err: any) {
          openNotification({
            type: 'error',
            title: t('alertMessages.errorTitle'),
            text: (
              <div>
                {t('error.couldNotLoadPage')}
                <ErrorDisplay error={err} />
              </div>
            ),
          });
        } finally {
          setIsLoading(false);
        }
      };
      loadMedia();
    }
  }, [selectedCategory, getReferenceMediaAction, t]);

  return (
    <Container>
      {selectedCategory ? (
        <UploadDashboardModal
          isModalVisible={isUploadModalVisible}
          toggleIsModalVisible={toggleIsUploadModalVisible}
          handleUploadComplete={onUploadComplete}
          categoryId={selectedCategory.value}
          endpoint={`${process.env.REACT_APP_API}/referenceMedia/upload-xhr`}
        />
      ) : null}
      <AccountWrapper>
        <h2>{t('ReferenceMedia.referenceMedia')}</h2>
        <Row className="row">
          <SelectContainer>
            <label htmlFor="accountingCustomer">{t('ReferenceMedia.referenceCategory')}</label>
            <CustomSelect
              onChange={setSelectedCategory}
              placeholder={t('ReferenceMedia.selectCategory')}
              options={referenceMediaCategoryOptions}
              loading={isLoading}
              variant="filter"
              id="category"
              tall
              value={selectedCategory?.value}
            />
          </SelectContainer>
        </Row>
        {selectedCategory ? (
          <>
            <Divider />
            <MediaContainer>
              <Button
                label={t('ReferenceMedia.uploadImages')}
                onClick={toggleIsUploadModalVisible}
              />
              <ReferenceMediaPreview
                categoryId={selectedCategory.value}
                handleDelete={deleteReferenceMediaAction}
                referenceMedia={referenceMedia}
              />
            </MediaContainer>
          </>
        ) : null}
      </AccountWrapper>
    </Container>
  );
};

export default ReferenceMedia;
