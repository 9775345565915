import PropTypes from 'prop-types';

import './CustomStep.scss';

const CustomStep = ({ step, steps, title }) => {
  const progressPercentage = `${(step / steps) * 100 + 1}%`;

  return (
    <div className="CustomStep-Wrapper">
      <div className="step-counter">
        <div className="form-title">{title}</div>
        <div className="steps">
          <span>Steps {step}</span> of {steps}
        </div>
      </div>
      <div className="rail">
        <div
          className="progress"
          style={{
            width: progressPercentage,
          }}
        />
      </div>
    </div>
  );
};

CustomStep.propTypes = {
  title: PropTypes.string,
  step: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
};

export default CustomStep;
