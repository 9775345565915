export enum VIEWS {
  THREE_D = 'threeD',
  INFO = 'info',
}

export const MODALS = {
  ADD_NEW_DESIGN: 'addNewDesign',
  CONFIRM_DELETE_DESIGN: 'confirmDeleteDesign',
  RENAME_DESIGN: 'renameDesign',
  DUPLICATE_DESIGN: 'duplicateDesign',
};

export const MIN_DESIGNS = 1;

export const DESIGN_TYPES = {
  REMOTE_DESIGN: 'remoteDesign',
  ONSITE_DESIGN: 'onsiteDesign',
  MAINTENANCE: 'maintenance',
};
