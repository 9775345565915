import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MaintenanceReport } from '@cpm/scanifly-shared-data';
import { Button, Modal } from 'antd';

import {
  allMaintenanceReportsRequested,
  maintenanceReportDeletionRequested,
} from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { AppDispatch } from 'state/store/store';

import { MODAL_PROPS } from 'helpers/constants/modals';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import './ConfirmDeleteModal.scss';

type Props = {
  isDeleteModalOpen: boolean;
  toggleDeleteModal: () => void;
  report?: MaintenanceReport;
};

const ConfirmDeleteModal = ({ isDeleteModalOpen, toggleDeleteModal, report }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const handleDeleteReport = () => {
    if (report?.project) {
      dispatch(
        maintenanceReportDeletionRequested({
          reportId: report.id,
          projectId: report.project,
        })
      );
      dispatch(allMaintenanceReportsRequested({ projectId: report.project }));
      toggleDeleteModal();
    }
  };

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isDeleteModalOpen}
      onCancel={toggleDeleteModal}
      className="ConfirmDeleteModal"
      {...MODAL_PROPS}
    >
      <XIcon
        onClick={toggleDeleteModal}
        className="close-modal-icon"
        data-testid="close-modal-icon"
      />
      <>
        <h2 className="ConfirmDeleteModal-Title">{t('MaintenanceReport.deleteModal.title')}</h2>
        <p>{t('MaintenanceReport.deleteModal.content')}</p>
      </>

      <div className="ConfirmDeleteModal-Buttons-Wrapper">
        <Button onClick={toggleDeleteModal} className="Button--White">
          {t('MaintenanceReport.buttonText.cancel')}
        </Button>
        <Button onClick={handleDeleteReport} className="Button--Blue">
          {t('MaintenanceReport.deleteModal.buttonText')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
