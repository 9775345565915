import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { login, logout, refreshToken, resetPassword, sendInvitation } from 'api/auth/authService';
import { InviteUserRequest } from 'api/auth/types';

import { Analytics } from 'helpers/utils';
import { destroyTokens, setTokens } from 'helpers/utils/manageTokens';

import { currentUserRequested } from './usersSlice';

const name = 'auth';

export const loginRequested = createAsyncThunk(
  `${name}/loginRequested`,
  async (credentials: { email: string; password: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await login(credentials);
      const { refreshToken, accessToken } = response.data;
      setTokens(accessToken, refreshToken);
      dispatch(currentUserRequested());
      return Boolean(accessToken);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutRequested = createAsyncThunk(
  `${name}/logoutRequested`,
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue }) => {
    try {
      await logout();
      destroyTokens();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordRequested = createAsyncThunk(
  `${name}/resetPasswordRequested`,
  async (emailAddress: string, { rejectWithValue }) => {
    try {
      await resetPassword(emailAddress);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshTokenRequested = createAsyncThunk(
  `${name}/refreshTokenRequested`,
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await refreshToken(token);
      const { refreshToken: newRefreshToken, accessToken } = response.data;
      setTokens(accessToken, newRefreshToken);
      return Boolean(accessToken);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const newUserInvited = createAsyncThunk(
  `${name}/newUserInvited`,
  async (newUser: InviteUserRequest, { rejectWithValue }) => {
    try {
      await sendInvitation(newUser);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name,
  initialState: {
    isLoggedIn: Boolean(localStorage.getItem('accessToken')),
    isLoading: false,
    isRefreshingToken: false,
    error: null,
    errorLogin: null,
    isUnsupportedCardError: false,
  },
  reducers: {
    cardWasDeclined: (state) => {
      state.isUnsupportedCardError = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginRequested.fulfilled, (state, { payload }) => {
      state.isLoggedIn = payload;
      state.errorLogin = null;
      state.isLoading = false;
    });
    builder.addCase(loginRequested.rejected, (state, { payload }) => {
      state.errorLogin = payload as any;
      state.isLoading = false;
    });
    builder.addCase(logoutRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logoutRequested.fulfilled, (state) => {
      Analytics.getInstance().clearSession();
      // @todo is this a bug? does currentUser exist in auth slice or user slice ?
      // @ts-ignore
      state.currentUser = null;
      state.isLoggedIn = false;
      state.isLoading = false;
    });
    builder.addCase(logoutRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isLoading = false;
    });
    builder.addCase(refreshTokenRequested.pending, (state) => {
      state.isRefreshingToken = true;
    });
    builder.addCase(refreshTokenRequested.fulfilled, (state) => {
      state.isRefreshingToken = false;
    });
    builder.addCase(refreshTokenRequested.rejected, (state) => {
      state.isRefreshingToken = false;
    });
    builder.addCase(resetPasswordRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordRequested.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(resetPasswordRequested.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as any;
    });
    builder.addCase(newUserInvited.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(newUserInvited.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(newUserInvited.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as any;
    });
  },
});

export const { cardWasDeclined } = authSlice.actions;

export default authSlice.reducer;
