import { WebMercatorViewport } from 'react-map-gl';

import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import areaHelper from '@turf/area';
import bearing from '@turf/bearing';
import centerHelper from '@turf/center';
import { getBoundsOfDistance, getPreciseDistance } from 'geolib';
import { Project } from 'types';
import BoundingBox, { BoundingBoxFeature } from 'types/BoundingBox';

import { PROJECT_TYPE_AREA_LIMITS, SMALL_MAX_AREA_LIMIT } from 'helpers/constants/areaLimitsInSqFt';
import { squareFeetToMetersConverter } from 'helpers/utils/metricConversions';

export const getBoundingBox = (
  coords: [{ latitude: number; longitude: number }, { latitude: number; longitude: number }],
  editorRef?: any
) => {
  const [southwestern, northeastern] = coords;

  const preparedFeature = {
    type: 'Feature',
    properties: {
      shape: 'Rectangle',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [southwestern.longitude, northeastern.latitude],
          [northeastern.longitude, northeastern.latitude],
          [northeastern.longitude, southwestern.latitude],
          [southwestern.longitude, southwestern.latitude],
          [southwestern.longitude, northeastern.latitude],
        ],
      ],
    },
  };

  editorRef && editorRef.current.addFeatures(preparedFeature);

  return preparedFeature;
};

// Factor by which to adjust the "bbox radius" in order to downsize the default
// small bounding box by ~50ft², to avoid reprojection error throwing a project
// from "small" to "large" class. From empirically testing a project at a high
// latitude (~61°) in Finland, where you would expect this effect to be more
// pronounced, this scale factor is such that you can move the bounding box
// downward by over a *mile* before longitudinal expansion causes it to cross
// the small/large threshold.
const DEFAULT_RADIUS_SCALE_FACTOR = Math.sqrt((SMALL_MAX_AREA_LIMIT - 50) / SMALL_MAX_AREA_LIMIT);

export const getCoordsForBoundingBox = (
  geolocation: { latitude: number; longitude: number },
  project: Pick<Project, 'type' | 'geolocation'>
) => {
  if (!project.type) {
    throw new Error('getCoordsForBoundingBox missing project type');
  }
  const maxBboxRadius = // @ts-ignore
    Math.sqrt(squareFeetToMetersConverter(PROJECT_TYPE_AREA_LIMITS[project.type].max)) / 2;

  // note: getBoundsOfDistance(pt, r) returns the bounding box of the circle of
  //   points within r meters of pt, so its dimensions are 2r x 2r.
  const point =
    !geolocation.latitude && !geolocation.longitude ? project?.geolocation : geolocation;
  return getBoundsOfDistance(
    // NOTE: when selecting a new img & then a new category the geolocation becomes undefined
    // In order to mitigate the error being thrown, we will use the geolocation that was attached to the project
    point,
    maxBboxRadius * DEFAULT_RADIUS_SCALE_FACTOR
  );
};

export const mapFeatureToBoundingBox = (feature: any, projectId: string) => {
  const coordinates = feature.geometry.coordinates[0].slice(0, 4);
  const corners = [
    { lat: coordinates[0][1], lng: coordinates[0][0] },
    { lat: coordinates[1][1], lng: coordinates[1][0] },
    { lat: coordinates[2][1], lng: coordinates[2][0] },
    { lat: coordinates[3][1], lng: coordinates[3][0] },
  ];
  const area = areaHelper(feature);
  const center = centerHelper(feature);
  const headingDeg = (360 + bearing(coordinates[2], coordinates[1])) % 360;

  return {
    projectId,
    boundingBox: {
      area,
      center: {
        lng: center.geometry.coordinates[0],
        lat: center.geometry.coordinates[1],
      },
      corners,
      heading: (headingDeg * Math.PI) / 180,
      height: getPreciseDistance(
        { latitude: corners[0].lat, longitude: corners[0].lng },
        { latitude: corners[3].lat, longitude: corners[3].lng }
      ),
      width: getPreciseDistance(
        { latitude: corners[0].lat, longitude: corners[0].lng },
        { latitude: corners[1].lat, longitude: corners[1].lng }
      ),
    },
  };
};

export const getBoundsForMarkers = (markers: any) => {
  const markersCorners = markers.reduce(
    (
      bboxSoFar: { minLat: number; maxLat: number; minLng: number; maxLng: number },
      point: { geolocation: { latitude: any; longitude: any } }
    ) => {
      return {
        minLat: Math.min(bboxSoFar.minLat, Number(point.geolocation.latitude)),
        maxLat: Math.max(bboxSoFar.maxLat, Number(point.geolocation.latitude)),
        minLng: Math.min(bboxSoFar.minLng, Number(point.geolocation.longitude)),
        maxLng: Math.max(bboxSoFar.maxLng, Number(point.geolocation.longitude)),
      };
    },
    { minLat: Infinity, maxLat: -Infinity, minLng: Infinity, maxLng: -Infinity }
  );
  const cornersLongLat: [[number, number], [number, number]] = [
    [markersCorners.minLng, markersCorners.minLat],
    [markersCorners.maxLng, markersCorners.maxLat],
  ];
  const viewport = new WebMercatorViewport({
    width: 800,
    height: 600,
  }).fitBounds(cornersLongLat, { padding: 200 });
  const { longitude, latitude, zoom } = viewport;
  const zoomWithMinValue = zoom > 18 ? 18 : zoom;

  return { longitude, latitude, zoom: zoomWithMinValue };
};

export const createBoundingBoxFeature = (boundaries: {
  boundingBox: BoundingBox;
}): {
  type: string;
  features: [BoundingBoxFeature];
} => {
  const coordinates = [
    [...boundaries.boundingBox.corners, boundaries.boundingBox.corners[0]].map(({ lat, lng }) => [
      lng,
      lat,
    ]),
  ];

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          shape: 'Rectangle',
        },
        geometry: {
          type: 'Polygon',
          coordinates,
        },
      },
    ],
  };
};

export const getBoundingBoxPointRadius = (projectType: PROJECT_TYPES) => {
  // This function will be obsolete with bounding box update
  switch (projectType) {
    case PROJECT_TYPES.SMALL_COMMERCIAL:
      return 8;
    case PROJECT_TYPES.COMMERCIAL:
      return 13;
    case PROJECT_TYPES.LARGE:
      return 15;
    case PROJECT_TYPES.UTILITY:
      return 17;
    default:
      return 5;
  }
};
