import { useTranslation } from 'react-i18next';

import { Container, Content } from './components';

import { ReactComponent as VideoIcon } from 'assets/camera.svg';

import { UnsupportedProps } from './types';

function Unsupported({ mimeType }: UnsupportedProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <VideoIcon className="image" />
      <Content>
        <h3>{t('VideoPlayer.weAreWorking')}</h3>
        <p data-testid={'unsupported-text'}>
          {t(
            `VideoPlayer.${
              mimeType?.includes('quicktime') ? 'pleaseUseAppOrBrowser' : 'plaseUseApp'
            }`
          )}
        </p>
      </Content>
    </Container>
  );
}

export default Unsupported;
