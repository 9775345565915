import i18next from 'i18next';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';

import { i18n } from 'helpers/utils/translations';

export const MAX_LENGTH = 300;

const t = (param: string) => {
  return i18next.t(`DesignServiceForm.${param}`);
};

export const PROJECT_INFORMATION_FORM = {
  HOMEOWNER_NAME: { title: `${t('homeowner')}*`, placeholder: t('homeowner') },
  METER_NUMBER: { title: `${t('meterNumber')}*`, placeholder: t('meterNumber') },
  UTILITY_COMPANY: { title: `${t('utilityCompany')}*`, placeholder: t('utilityCompany') },
  COMPANY_ADDRESS: { title: `${t('companyAddress')}*` },
  PROJECT_ADDRESS: { title: t('projectAddress') },
  SIZED_FOR_CATEGORY: {
    title: t('sizedFor'),
    options: [
      { label: t('none'), value: SIZED_FOR_CATEGORIES.none },
      { label: t('consumption'), value: SIZED_FOR_CATEGORIES.consumption },
      { label: t('production'), value: SIZED_FOR_CATEGORIES.production },
      { label: t('systemSize'), value: SIZED_FOR_CATEGORIES.systemSize },
    ],
  },
  SIZED_FOR_VALUE: {
    title: '',
    placeholder: {
      none: t('none'),
      consumption: '%',
      production: 'kWh',
      systemSize: 'kW',
    },
  },
  AHJ: {
    title: `${t('ahj')}*`,
    placeholder: t('ahj'),
    titleNotRequired: t('ahj'),
  },
  INVERTER_COUNT: {
    title: t('inverterCountTitle'),
    placeholder: t('inverterCountPlaceholder'),
    options: [
      { label: 'One', value: '1' },
      { label: 'Two', value: '2' },
      { label: 'Three', value: '3' },
      { label: 'Other', value: 'other' },
    ],
  },
  SYSTEM_SIZE: {
    title: t('systemSizeTitle'),
    placeholder: '10 kW',
  },
  VIEWSHED_PLACEMENT: {
    title: t('viewshedPlacementTitle'),
    placeholder: t('viewshedPlacementPlaceholder'),
    options: [
      {
        label: t('viewshedPlacementOption1'),
        value: t('viewshedPlacementOption1'),
      },
      {
        label: t('viewshedPlacementOption2'),
        value: t('viewshedPlacementOption2'),
      },
    ],
  },
};

export const STRUCTURAL_INFORMATION_FORM = {
  ROOFING_TYPE: {
    title: 'What type of roofing does the structure have?*',
    placeholder: 'Type of Roofing',
    options: [
      { label: 'Composite Shingle', value: 'compositeShingle' },
      { label: 'Roof Tiles', value: 'roofTiles' },
      { label: 'Metal', value: 'metal' },
      { label: 'Other', value: 'other' },
    ],
  },
  ATTACHMENT: {
    title: 'Attachment manufacturer and model*',
    placeholder: 'Unirac',
  },
  RACKING: {
    title: 'Racking manufacturer and model*',
    placeholder: 'Unirac',
  },
  CENTER_SPACING: {
    title: 'What is the on center rafter spacing?*',
    placeholder: 'Center rafter spacing',
  },
  RAFTER_SIZE: {
    title: 'What are the size of the rafters?*',
    placeholder: '2 x 6',
  },
  MAX_SPACING: {
    title: 'Preferred max spacing of attachments*',
    placeholder: '36"',
  },
};

export const ELECTRICAL_INFORMATION_FORM = {
  MAIN_BREAKER_RATING: {
    title: i18n.t('PlanSetElectricalInformationForm.mainBreakerRatingTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.mainBreakerRatingPlaceholder'),
  },
  MSP_ONSITE: {
    title: i18n.t('PlanSetElectricalInformationForm.mspOnsiteTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.mspOnsitePlaceholder'),
  },
  MODULE_INFO: {
    title: i18n.t('PlanSetElectricalInformationForm.moduleInfoTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.moduleInfoPlaceholder'),
  },
  BATTERY_INFO: {
    title: i18n.t('PlanSetElectricalInformationForm.batteryInfoTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.batteryInfoPlaceholder'),
  },
  AC_DISCONNECT: {
    title: i18n.t('PlanSetElectricalInformationForm.acDisconnectTitle'),
  },
  MAIN_BUSBAR_RATING: {
    title: i18n.t('PlanSetElectricalInformationForm.mainBusbarTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.mainBusbarPlaceholder'),
  },
  METER_LOCATION: {
    title: i18n.t('PlanSetElectricalInformationForm.meterLocationTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.meterLocationPlaceholder'),
  },
  INVERTER_INFO: {
    title: i18n.t('PlanSetElectricalInformationForm.inverterInfoTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.inverterInfoPlaceholder'),
  },
  BATTERY_COUNT: {
    title: i18n.t('PlanSetElectricalInformationForm.batteryCountTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.batteryCountPlaceholder'),
  },
  PV_REQUIRED: {
    title: i18n.t('PlanSetElectricalInformationForm.pvRequiredTitle'),
  },
  INTERCONNECTION_METHOD: {
    title: i18n.t('PlanSetElectricalInformationForm.interconnectionMethodTitle'),
    placeholder: i18n.t('PlanSetElectricalInformationForm.interconnectionMethodPlaceholder'),
  },
};

export const COMMENTS_FORM = {
  COMMENTS: {
    title: t('comments'),
  },
};

export const PRELIMINARY_DESIGN_FORM = {
  SELECTED_DESIGN: {
    title: t('preliminaryDesign'),
  },
  DESIGN_URL: {
    title: i18next.t('DesignService.planSet.preliminaryDesignForm.designUrl'),
  },
};

export const FIRE_SETBACKS = {
  title: t('fireSetbacks'),
  setbacks: [
    {
      title: t('ridge'),
      placeholder: '12"',
      id: 'ridge',
    },
    {
      title: t('eave'),
      placeholder: '12"',
      id: 'eave',
    },
    {
      title: t('rake'),
      placeholder: '12"',
      id: 'rake',
    },
    {
      title: t('valley'),
      placeholder: '12"',
      id: 'valley',
    },
    {
      title: t('hip'),
      placeholder: '12"',
      id: 'hip',
    },
  ],
};

export const OBSTRUCTION_SETBACKS = {
  title: t('obstructionSetbacks'),
  setbacks: [
    {
      title: t('vents'),
      placeholder: '12"',
      id: 'vents',
    },
    {
      title: t('exhaust'),
      placeholder: '12"',
      id: 'exhaust',
    },
    {
      title: t('skylights'),
      placeholder: '12"',
      id: 'skylights',
    },
    {
      title: t('parapet'),
      placeholder: '12"',
      id: 'parapet',
    },
    {
      title: t('general'),
      placeholder: '12"',
      id: 'general',
    },
  ],
};
