import { useTranslation } from 'react-i18next';

import { PROJECT_STATUS } from '@cpm/scanifly-shared-data';
import { Button, Scanifly3DButton } from 'components';
import colors from 'helpers/constants/colors';
import { projectNotesRoute } from 'helpers/constants/routes';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center !important;
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  margin-top: 3rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PendingApprovalActionButtons = ({
  handleAcceptDesign,
  projectId,
}: {
  handleAcceptDesign: () => void;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleAddComment = useCallback(() => {
    history.push(projectNotesRoute(projectId));
  }, [history, projectId]);

  return (
    <StyledButtonWrapper>
      <StyledWrapper>
        <Scanifly3DButton
          projectId={projectId}
          status={PROJECT_STATUS.uploadComplete}
          isPendingApproval={true}
        />
        <StyledButton
          icon="addComment"
          color={colors.darkBlue}
          width="auto"
          data-testid="add-comment-button"
          onClick={handleAddComment}
        >
          {t('DesignStatusDisplay.addComment')}
        </StyledButton>
      </StyledWrapper>
      <StyledButton
        icon="acceptDesign"
        color={colors.darkBlue}
        width="15rem"
        data-testid="accept-design-button"
        onClick={handleAcceptDesign}
      >
        {t('DesignStatusDisplay.acceptDesign')}
      </StyledButton>
    </StyledButtonWrapper>
  );
};

export default PendingApprovalActionButtons;
