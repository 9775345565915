import { Card } from 'antd';

import { EmailLink } from 'components';

import { ReactComponent as BoltIcon } from 'assets/icons/bolt-icon.svg';

const UpgradeMessage = () => (
  <Card className="UpgradeMessage">
    <div className="UpgradeMessage-header">
      <div className="title">
        <BoltIcon />
        <span>Drone Data Score</span>
      </div>
      <div className="chip-container">
        <span className="professional">professional</span>
        <span className="enterprise">enterprise</span>
      </div>
    </div>
    <div className="UpgradeMessage-message">
      Get at-a-glance insight into the data collected from your drone flight. Scoring includes
      flight path, camera technique, and image quality.
    </div>
    <div className="UpgradeMessage-contact">
      Contact your Scanifly rep or reach out to <EmailLink /> for upgrading.
    </div>
  </Card>
);

export default UpgradeMessage;
