import { TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { SIZED_FOR_CATEGORIES } from '../constants';

type DSFormValues = {
  homeownerName?: string;
  ahj?: string;
  designOneModule?: string;
  moduleSpacingLong?: string;
  moduleSpacingShort?: string;
  azimuthRangeStart?: string;
  azimuthRangeEnd?: string;
  moduleOrientation?: string;
  mixedOrientation?: string;
  utilityCompany?: string;
  systemSize?: string;
  companyAddress?: string;
  mainBreakerRating?: string;
  mspOnsite?: string;
  moduleInfo?: string;
  batteryInfo?: string;
  acDisconnect?: string;
  mainBusbarRating?: string;
  meterLocation?: string;
  inverterInfo?: string;
  batteryCount?: string;
  pvRequired?: string;
  roofingType?: string;
  attachment?: string;
  racking?: string;
  centerSpacing?: string;
  rafterSize?: string;
  maxSpacing?: string;
  sizedForCategory?: string;
  sizedForValue?: string;
};

const isSitePlanProjectInfoFormValid = (values: DSFormValues) => {
  const { homeownerName, ahj, sizedForCategory, sizedForValue } = values;
  const fields = [homeownerName, ahj, sizedForCategory];
  const isSizedForValid =
    sizedForCategory === SIZED_FOR_CATEGORIES.none ||
    (sizedForCategory !== SIZED_FOR_CATEGORIES.none && sizedForValue);

  return fields.every((val) => val?.length) && Boolean(isSizedForValid);
};

const isTrueUpFormValid = (values: DSFormValues) => {
  const {
    designOneModule,
    moduleSpacingLong,
    moduleSpacingShort,
    azimuthRangeStart,
    azimuthRangeEnd,
    moduleOrientation,
    mixedOrientation,
  } = values;

  const fields = [
    designOneModule,
    moduleSpacingLong,
    moduleSpacingShort,
    azimuthRangeStart,
    azimuthRangeEnd,
    moduleOrientation,
    mixedOrientation,
  ];

  return fields.every((val) => val?.length);
};

const isSiteModellingProjectInfoFormValid = (values: DSFormValues) => {
  const { homeownerName } = values;
  const fields = [homeownerName];

  return fields.every((val) => val?.length);
};

const isMaxFillDesignInfoFormValid = (values: DSFormValues) => {
  const {
    designOneModule,
    moduleSpacingLong,
    moduleSpacingShort,
    azimuthRangeStart,
    azimuthRangeEnd,
    moduleOrientation,
    mixedOrientation,
  } = values;

  const fields = [
    designOneModule,
    moduleSpacingLong,
    moduleSpacingShort,
    azimuthRangeStart,
    azimuthRangeEnd,
    moduleOrientation,
    mixedOrientation,
  ];

  return fields.every((val) => val?.length);
};

const isMaxFillProjectInfoFormValid = (values: DSFormValues) => {
  const { homeownerName, sizedForCategory, sizedForValue } = values;
  const fields = [homeownerName, sizedForCategory];

  const isSizedForValid =
    sizedForCategory === SIZED_FOR_CATEGORIES.none ||
    (sizedForCategory !== SIZED_FOR_CATEGORIES.none && sizedForValue);

  return fields.every((val) => val?.length) && Boolean(isSizedForValid);
};

const isPlanSetProjectInfoFormValid = (values: DSFormValues) => {
  const { homeownerName, utilityCompany, ahj, companyAddress, sizedForCategory, sizedForValue } =
    values;
  const fields = [homeownerName, utilityCompany, companyAddress, ahj, sizedForCategory];
  const isSizedForValid =
    sizedForCategory === SIZED_FOR_CATEGORIES.none ||
    (sizedForCategory !== SIZED_FOR_CATEGORIES.none && sizedForValue);

  return fields.every((val) => val?.length) && Boolean(isSizedForValid);
};

const isStructuralInformationFormValid = (values: DSFormValues) => {
  const { roofingType, attachment, racking, centerSpacing, rafterSize, maxSpacing } = values;
  const fields = [roofingType, attachment, racking, centerSpacing, rafterSize, maxSpacing];
  return fields.every((val) => val?.length);
};

const isElectricalInformationFormValid = (values: DSFormValues) => {
  const {
    mainBreakerRating,
    mspOnsite,
    moduleInfo,
    batteryInfo,
    acDisconnect,
    mainBusbarRating,
    meterLocation,
    inverterInfo,
    batteryCount,
    pvRequired,
  } = values;
  const fields = [
    mainBreakerRating,
    mspOnsite,
    moduleInfo,
    batteryInfo,
    acDisconnect,
    mainBusbarRating,
    meterLocation,
    inverterInfo,
    batteryCount,
    pvRequired,
  ];
  return fields.every((val) => val?.length);
};

const getIsValid = (
  tierLevel: TIER_LEVEL,
  step: number,
  values: DSFormValues,
  useExistingDesignForTrueUp?: boolean
) => {
  if (tierLevel === TIER_LEVEL.sitePlan) {
    return isSitePlanProjectInfoFormValid(values);
  }

  if (tierLevel === TIER_LEVEL.trueUp) {
    if (!useExistingDesignForTrueUp) {
      return isTrueUpFormValid(values);
    }
  }

  if (
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim
  ) {
    if (step === 1) {
      return isPlanSetProjectInfoFormValid(values);
    } else if (step === 2) {
      return isStructuralInformationFormValid(values);
    } else if (step === 3) {
      return isElectricalInformationFormValid(values);
    }
  }

  if (tierLevel === TIER_LEVEL.siteModeling) {
    return isSiteModellingProjectInfoFormValid(values);
  }

  if (tierLevel === TIER_LEVEL.maxFill && step === 1) {
    return isMaxFillProjectInfoFormValid(values);
  }

  if (tierLevel === TIER_LEVEL.maxFill && step === 2) {
    return isMaxFillDesignInfoFormValid(values);
  }

  return true;
};

export {
  getIsValid,
  isElectricalInformationFormValid,
  isPlanSetProjectInfoFormValid,
  isSiteModellingProjectInfoFormValid,
  isSitePlanProjectInfoFormValid,
  isStructuralInformationFormValid,
  isTrueUpFormValid,
};
export type { DSFormValues };
