import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { projectNotesRoute } from 'helpers/constants/routes';

import { ReactComponent as DataRequestIcon } from 'assets/data-request-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const DataRefinement = ({
  handleModalOpen,
  status,
  projectId,
}: {
  handleModalOpen: () => void;
  status: string;
  projectId?: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleViewCommentClick = useCallback(() => {
    history.push(projectNotesRoute(projectId));
  }, [history, projectId]);

  return (
    <div className="DesignStatus-Wrapper">
      <DataRequestIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignStatusDisplay.dataRefinement')}</div>
      <ActionButtons
        handleClick={handleViewCommentClick}
        handleCancel={handleModalOpen}
        hideCancelButton={true}
        status={status}
      />
    </div>
  );
};

export default DataRefinement;
