import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Folder } from 'types';

import {
  batchUpdateFolders,
  createFolder,
  deleteFolder,
  deleteFolderFromProject,
  fetchFolders,
  fetchFoldersByCompanyId,
  removeFoldersFromProjects,
  updateFolderColor,
  updateFolderForProject,
  updateFolderName,
} from 'api/folders/foldersService';

import { projectRequested } from './projectSlice';

const name = 'companyFolders';

type FoldersState = {
  folders: Folder[];
  isLoading: boolean;
  isSuccess: boolean;
  isRequestSuccessful: boolean;
  requestsCount: number;
  successfulRequestsCount: number;
  errors: (string | null | undefined)[];
  error: string | null | undefined;
  isFoldersInProjectUpdatedSuccess: boolean;
};

export const adminFoldersRequested = createAsyncThunk(
  `${name}/adminFoldersRequested`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchFoldersByCompanyId(id);
      return response.data as Folder[];
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createFolderRequested = createAsyncThunk(
  `${name}/createFolderRequested`,
  async ({ name, color }: Pick<Folder, 'name' | 'color'>, { rejectWithValue }) => {
    try {
      await createFolder(name, color);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFolderColorRequested = createAsyncThunk(
  `${name}/updateFolderColorRequested`,
  async ({ id, color }: Pick<Folder, 'id' | 'color'>, { rejectWithValue }) => {
    try {
      await updateFolderColor(id, color);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFolderNameRequested = createAsyncThunk(
  `${name}/updateFolderNameRequested`,
  async ({ id, name }: Pick<Folder, 'id' | 'name'>, { rejectWithValue }) => {
    try {
      await updateFolderName(id, name);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const batchUpdateFoldersRequested = createAsyncThunk(
  `${name}/batchUpdateFolders`,
  async ({ id, projectIds }: { id: string; projectIds: string[] }, { rejectWithValue }) => {
    try {
      await batchUpdateFolders(id, projectIds);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFolderRequested = createAsyncThunk(
  `${name}/deleteFolderRequested`,
  async ({ id }: Pick<Folder, 'id'>, { rejectWithValue }) => {
    try {
      await deleteFolder(id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyFoldersProjectsRemoved = createAsyncThunk(
  `${name}/companyFoldersProjectsRemoved`,
  async (projectIds: string[], { rejectWithValue }) => {
    try {
      await removeFoldersFromProjects(projectIds);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const foldersRequested = createAsyncThunk(
  `${name}/foldersRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchFolders();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFolderForProjectRequested = createAsyncThunk(
  `${name}/updateFolderForProjectRequested`,
  async (
    { folderId, projectId }: { folderId: string; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateFolderForProject(folderId, projectId);
      dispatch(projectRequested(projectId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFolderFromProjectRequested = createAsyncThunk(
  `${name}/deleteFolderFromProjectRequested`,
  async (
    { folderId, projectId }: { folderId: string; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await deleteFolderFromProject(folderId, projectId);
      dispatch(projectRequested(projectId));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const foldersSlice = createSlice({
  name,
  initialState: {
    folders: [],
    isLoading: false,
    isSuccess: false,
    isRequestSuccessful: false,
    requestsCount: 0,
    successfulRequestsCount: 0,
    errors: [],
    error: null,
    isFoldersInProjectUpdatedSuccess: false,
  } as FoldersState,
  reducers: {
    resetRequestsCount: (state) => {
      state.requestsCount = 0;
      state.successfulRequestsCount = 0;
    },
    userSuccessfullyUpdatedFolderInProject: (state) => {
      state.isFoldersInProjectUpdatedSuccess = false;
    },
    resetIsRequestSuccess: (state) => {
      state.isRequestSuccessful = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createFolderRequested.pending, (state) => {
      state.isLoading = true;
      state.requestsCount++;
      state.errors = [];
    });
    builder.addCase(createFolderRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.successfulRequestsCount++;
    });
    builder.addCase(createFolderRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.errors.push(error.message);
    });
    builder.addCase(updateFolderColorRequested.pending, (state) => {
      state.isLoading = true;
      state.requestsCount++;
      state.errors = [];
    });
    builder.addCase(updateFolderColorRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.successfulRequestsCount++;
    });
    builder.addCase(updateFolderColorRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.errors.push(error.message);
    });
    builder.addCase(batchUpdateFoldersRequested.pending, (state) => {
      state.isLoading = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(batchUpdateFoldersRequested.fulfilled, (state) => {
      state.isLoading = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(batchUpdateFoldersRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isRequestSuccessful = false;
    });
    builder.addCase(deleteFolderRequested.pending, (state) => {
      state.isLoading = true;
      state.requestsCount++;
      state.errors = [];
    });
    builder.addCase(deleteFolderRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.successfulRequestsCount++;
    });
    builder.addCase(deleteFolderRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.errors.push(error.message);
    });
    builder.addCase(foldersRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(foldersRequested.fulfilled, (state, { payload }) => {
      state.folders = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(foldersRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(adminFoldersRequested.fulfilled, (state, { payload }) => {
      state.folders = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(adminFoldersRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(adminFoldersRequested.pending, (state) => {
      state.isLoading = true;
      state.isFoldersInProjectUpdatedSuccess = false;
    });
    builder.addCase(updateFolderForProjectRequested.pending, (state) => {
      state.isLoading = true;
      state.isFoldersInProjectUpdatedSuccess = false;
    });
    builder.addCase(updateFolderForProjectRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isFoldersInProjectUpdatedSuccess = true;
    });
    builder.addCase(updateFolderForProjectRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isFoldersInProjectUpdatedSuccess = false;
    });
    builder.addCase(deleteFolderFromProjectRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFolderFromProjectRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isFoldersInProjectUpdatedSuccess = true;
    });
    builder.addCase(deleteFolderFromProjectRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isFoldersInProjectUpdatedSuccess = false;
    });
    builder.addCase(companyFoldersProjectsRemoved.pending, (state) => {
      state.isLoading = true;
      state.isRequestSuccessful = false;
    });
    builder.addCase(companyFoldersProjectsRemoved.fulfilled, (state) => {
      state.isLoading = false;
      state.isRequestSuccessful = true;
    });
    builder.addCase(companyFoldersProjectsRemoved.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isRequestSuccessful = false;
    });
  },
});

export const { userSuccessfullyUpdatedFolderInProject, resetRequestsCount, resetIsRequestSuccess } =
  foldersSlice.actions;

export default foldersSlice.reducer;
