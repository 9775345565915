import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }

  .qr {
    flex: 0.48;

    .QRCode-Border {
      .ant-collapse-borderless,
      .ant-collapse {
        background-color: unset;

        .ant-collapse-item {
          border: none;
        }
      }

      border: 1px solid ${colors.neutralGray}
      border-radius: 1rem;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      overflow-x: hidden;
    }
  }
`;

export default TopSection;
