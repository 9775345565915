import { ProjectDesignData } from 'types';

import API from '../config';
import {
  createDefaultDesignUrl,
  createProjectDesignUrl,
  duplicateProjectDesignUrl,
  fetchProjectDesignsUrl,
  projectDesignUrl,
} from './projectDesignsUrls';

export const fetchProjectDesigns = (projectId: string) =>
  API.get(fetchProjectDesignsUrl(projectId));
export const createProjectDesign = (designData: ProjectDesignData) =>
  API.post(createProjectDesignUrl(), { ...designData });
export const createDefaultProjectDesign = (designData: ProjectDesignData) =>
  API.post(createDefaultDesignUrl(), { ...designData });
export const duplicateProjectDesign = (designId: string, name: string) =>
  API.post(duplicateProjectDesignUrl(designId), { name });
export const deleteProjectDesign = (designId: string) => API.delete(projectDesignUrl(designId));
export const updateProjectDesign = (designId: string, name: string) =>
  API.patch(projectDesignUrl(designId), { name });
