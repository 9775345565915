import { useTranslation } from 'react-i18next';

import { Divider } from 'antd';
import { camelCase } from 'lodash';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import { newWeights, oldWeights, Weights } from 'helpers/constants/weights';
import { formatPercent } from 'helpers/utils/formatPercent';

const StyledMainWrapper = styled.div`
  .ant-divider-horizontal {
    margin: 0.8rem 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
`;

const StyledValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1.2rem;
  margin-right: auto;
`;

const StyledLineItem = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  background: ${(props) => props.color};
`;

const StyledLabel = styled.div`
  color: ${colors.labelGray};
`;

const StyledValue = styled.div`
  color: ${colors.darkGray};
  font-weight: 500;
`;

const StyledWeightValue = styled.div`
  background: #eaeaeb;
  color: ${colors.darkGray};
  font-size: 8px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05rem;
  border-radius: 1.2rem;
  padding: 0.4rem 1rem 0.2rem 1rem;
  max-width: fit-content;
  max-height: 2.4rem;
  margin-top: 0.1rem;
  text-transform: uppercase;
`;

const DroneDataScoreStat = ({
  color,
  name,
  value,
  isLast = false,
  completedAfterDDSv2Release,
}: {
  color: string;
  name: string;
  value: number;
  isLast: boolean;
  completedAfterDDSv2Release: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledMainWrapper>
      <StyledContainer>
        <>
          <StyledLineItem color={color} />
          <StyledValueContainer>
            <StyledLabel>{name}</StyledLabel>
            <StyledWeightValue>
              {completedAfterDDSv2Release
                ? newWeights[camelCase(name) as keyof Weights]
                : oldWeights[camelCase(name) as keyof Weights]}{' '}
              {t('DroneDataScore.weighting')}
            </StyledWeightValue>
          </StyledValueContainer>
        </>
        <StyledValue>{formatPercent(value)}</StyledValue>
      </StyledContainer>
      {!isLast && <Divider />}
    </StyledMainWrapper>
  );
};

export default DroneDataScoreStat;
