import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Input, Row } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import {
  companyBillingInfoRequested,
  companyClientSecretRequested,
  companyCreditCardInfoUpdated,
} from 'state/slices/companySlice';

import { ContactScaniflySupport, CountrySelect, PaymentField } from 'components';

import { PAYMENT_FIELD, SETUP_PAYMENT_METHOD_STATUSES } from 'helpers/constants/stripeConstants';
import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { mapCountryCodeToName, mapCountryNameToCode } from 'helpers/utils/mapCountryNameToCode';
import { openNotification } from 'helpers/utils/openNotification';

import { FORM_CONTROLS } from '../constants/formControls';
import { initialValuesForCC } from '../constants/initialValues';
import './AccountPaymentsCreditCardForm.scss';
import { validationSchema } from './validationSchema';

const AccountPaymentsCreditCardForm = ({ toggleForm, companyBillingInfo }) => {
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [isCardError, setIsCardError] = useState(false);
  const [cvcCodeValid, setCvcCodeValid] = useState(false);
  const [expiryDateValid, setExpiryDateValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    companyBillingInfo?.creditCardAddress?.country
  );
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { companyClientSecret, company } = useSelector((state) => state.company);
  const [isSetupPaymentMethodLoading, setIsSetupPaymentMethodLoading] = useState(false);
  const [isSetupPaymentMethodCompleted, setIsSetupPaymentMethodCompleted] = useState(false);

  useEffect(() => {
    if (!companyClientSecret) {
      dispatch(companyClientSecretRequested());
    }
  }, [companyClientSecret, dispatch]);

  const handleUpdatePaymentMethod = useCallback(
    async (billingInfo) => {
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (!stripe || !elements || !companyClientSecret) {
        return;
      }

      setIsSetupPaymentMethodLoading(true);
      if (isCardError) {
        setIsCardError(false);
      }

      const response = await stripe.confirmCardSetup(companyClientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${billingInfo.firstName} ${billingInfo.lastName}`,
            address: {
              city: billingInfo.city,
              country: mapCountryNameToCode(billingInfo.country),
              line1: billingInfo.street,
              postal_code: billingInfo.zipCode,
              state:
                billingInfo.country === 'US' || billingInfo.country === 'United States'
                  ? billingInfo.state
                  : '',
            },
          },
        },
      });

      if (response.setupIntent?.status === SETUP_PAYMENT_METHOD_STATUSES.SUCCEEDED) {
        dispatch(
          companyCreditCardInfoUpdated({
            customerId: company?.customerId,
            paymentMethodId: response.setupIntent.payment_method,
          })
        );
        setIsSetupPaymentMethodCompleted(true);
        setIsSetupPaymentMethodLoading(false);
        openNotification({
          type: 'success',
          title: 'Success!',
          text: 'Your billing information has been successfully updated!',
        });
      } else if (response.error) {
        setIsSetupPaymentMethodLoading(false);
        setIsCardError(true);
        openNotification({
          type: 'error',
          title: 'Error!',
          text: <ContactScaniflySupport erroredAction="update your billing information" />,
        });
      }
    },
    [elements, stripe, companyClientSecret, isCardError, dispatch, company?.customerId]
  );

  const {
    touched,
    handleSubmit,
    errors,
    isValid,
    getFieldProps,
    setFieldValue,
    values,
    handleBlur,
    validateForm,
  } = useFormik({
    initialValues: initialValuesForCC,
    validationSchema,
    onSubmit: (values) => handleUpdatePaymentMethod({ ...values }),
  });

  useEffect(() => {
    if (isSetupPaymentMethodCompleted) {
      dispatch(companyClientSecretRequested());
      dispatch(companyBillingInfoRequested(company.customerId));
      toggleForm();
    }
  }, [isSetupPaymentMethodCompleted, toggleForm, company, dispatch]);

  useEffect(() => {
    if (companyBillingInfo) {
      setFieldValue(FORM_CONTROLS.FIRST_NAME, companyBillingInfo?.firstName);
      setFieldValue(FORM_CONTROLS.LAST_NAME, companyBillingInfo?.lastName);
      setFieldValue(
        FORM_CONTROLS.COUNTRY,
        mapCountryCodeToName(companyBillingInfo?.creditCardAddress?.country) ??
          companyBillingInfo?.creditCardAddress?.country
      );
      setFieldValue(FORM_CONTROLS.STATE, companyBillingInfo?.creditCardAddress?.state);
      setFieldValue(FORM_CONTROLS.STREET, companyBillingInfo?.creditCardAddress?.line);
      setFieldValue(FORM_CONTROLS.CITY, companyBillingInfo?.creditCardAddress?.city);
      setFieldValue(FORM_CONTROLS.ZIP_CODE, companyBillingInfo?.creditCardAddress?.zip);
    }
  }, [companyBillingInfo, setFieldValue]);

  useEffect(() => {
    if (!isValid) {
      validateForm();
    }
  }, [isValid, validateForm]);

  const [handleCountryBlur, getCountrySelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.COUNTRY
  );

  const isFormValid = isValid && cardNumberValid && expiryDateValid && cvcCodeValid;

  return (
    <form className="AccountPaymentsCreditCardForm">
      <Row className="AccountPaymentsCreditCardForm-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountPaymentsCreditCardFormFirstName">First Name</label>
          <Input
            placeholder="First Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.FIRST_NAME)}
            id="accountPaymentsCreditCardFormFirstName"
            {...getFieldProps(FORM_CONTROLS.FIRST_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.FIRST_NAME)}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="accountPaymentsCreditCardFormLastName">Last Name</label>
          <Input
            placeholder="Last Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.LAST_NAME)}
            id="accountPaymentsCreditCardFormLastName"
            {...getFieldProps(FORM_CONTROLS.LAST_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.LAST_NAME)}
          </div>
        </div>
      </Row>

      <Row className="AccountPaymentsCreditCardForm-Row">
        <div className="FormControl-Wrapper FormControl-Wrapper--Country">
          <CountrySelect
            {...getFieldProps(FORM_CONTROLS.COUNTRY)}
            formControl={FORM_CONTROLS.COUNTRY}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            setSelectedCountry={setSelectedCountry}
            onBlur={handleCountryBlur}
            error={getCountrySelectError(values)}
            variant="form"
          />
          <div className="Form-Error">
            {getCountrySelectError(values) && errors[FORM_CONTROLS.COUNTRY]}
          </div>
        </div>
      </Row>
      <Row className="AccountPaymentsCreditCardForm-Row">
        <div className="FormControl-Wrapper FormControl-Wrapper--Long">
          <label htmlFor="accountPaymentsCreditCardFormStreet">Street</label>
          <Input
            placeholder="Street"
            className={validateStatus(touched, errors, FORM_CONTROLS.STREET)}
            id="accountPaymentsCreditCardFormStreet"
            {...getFieldProps(FORM_CONTROLS.STREET)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.STREET)}
          </div>
        </div>
      </Row>

      <Row className="AccountPaymentsCreditCardForm-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="accountPaymentsCreditCardFormCity">City</label>
          <Input
            placeholder="City"
            className={validateStatus(touched, errors, FORM_CONTROLS.CITY)}
            id="accountPaymentsCreditCardFormCity"
            {...getFieldProps(FORM_CONTROLS.CITY)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CITY)}
          </div>
        </div>

        {selectedCountry === 'United States' || selectedCountry === 'US' ? (
          <div className="FormControl-Wrapper FormControl-Wrapper--Short">
            <label htmlFor="accountPaymentsCreditCardFormState">State</label>
            <Input
              placeholder="State"
              className={validateStatus(touched, errors, FORM_CONTROLS.STATE)}
              id="accountPaymentsCreditCardFormState"
              {...getFieldProps(FORM_CONTROLS.STATE)}
            />
          </div>
        ) : null}

        <div className="FormControl-Wrapper FormControl-Wrapper--Short">
          <label htmlFor="accountPaymentsCreditCardFormZip">ZIP Code</label>
          <Input
            placeholder="ZIP Code"
            className={validateStatus(touched, errors, FORM_CONTROLS.ZIP_CODE)}
            id="accountPaymentsCreditCardFormZip"
            {...getFieldProps(FORM_CONTROLS.ZIP_CODE)}
          />
          <div className="Form-Error Form-Error--Short">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.ZIP_CODE)}
          </div>
        </div>
      </Row>

      <h2 className="AccountPaymentsCreditCardForm-SubTitle">Credit Card Information</h2>

      <Row className="AccountPaymentsCreditCardForm-Row">
        <PaymentField
          label="Credit Card Number"
          paymentField={PAYMENT_FIELD.CREDIT_CARD}
          setValid={setCardNumberValid}
        />
        <PaymentField
          label="Valid thru"
          paymentField={PAYMENT_FIELD.EXPIRY_DATE}
          setValid={setExpiryDateValid}
        />
        <PaymentField
          label="CVC"
          paymentField={PAYMENT_FIELD.CVC_CODE}
          setValid={setCvcCodeValid}
        />
      </Row>

      {isCardError && (
        <Alert
          className="Alert"
          type="error"
          message="This card is not supported, please contact Scanifly Administrator."
        />
      )}

      <div className="AccountPaymentsCreditCardForm-Button-Wrapper">
        <Button
          onClick={toggleForm}
          className="Button--White"
          data-testid="cancel-payment-info-update"
        >
          Cancel
        </Button>
        <Button
          loading={isSetupPaymentMethodLoading}
          disabled={!isFormValid}
          aria-disabled={!isFormValid}
          onClick={handleSubmit}
          className="Button--Blue"
          data-testid="update-cc-info-button"
        >
          Update Credit Card Info
        </Button>
      </div>
    </form>
  );
};

AccountPaymentsCreditCardForm.propTypes = {
  toggleForm: PropTypes.func.isRequired,
  companyBillingInfo: PropTypes.shape({
    creditCardAddress: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      line: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
    brand: PropTypes.string,
    customerId: PropTypes.string,
    expMonth: PropTypes.number,
    expYear: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerName: PropTypes.string,
    customerEmail: PropTypes.string,
    last4: PropTypes.string,
    paymentMethodId: PropTypes.string,
  }),
};

export default AccountPaymentsCreditCardForm;
