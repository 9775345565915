import { ServiceRequest } from 'types';

import { i18n } from 'helpers/utils/translations';

import SelectWrapper from '../OrderDesign/DesignSelection/SelectWrapper/SelectWrapper';

export const getMainSelectionSteps = ({
  handleResponse,
  upgrade = false,
}: {
  handleResponse: (
    data: Partial<ServiceRequest> & { stepNumber: number; projectId?: string }
  ) => void;
  upgrade?: boolean;
}) => {
  return [
    {
      stepNumber: 1,
      description: i18n.t('DesignService.selectPlan'),
      content: <SelectWrapper stepNumber={1} handleResponse={handleResponse} upgrade={upgrade} />,
    },
    {
      stepNumber: 2,
      description: i18n.t('DesignService.forms'),
      content: <SelectWrapper stepNumber={2} handleResponse={handleResponse} upgrade={upgrade} />,
    },
    {
      stepNumber: 3,
      description: i18n.t('DesignService.submit'),
      content: <></>,
    },
  ];
};
