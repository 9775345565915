import { GENERAL_SERVICE_CREDITS } from '@cpm/scanifly-shared-data';

import { i18n } from 'helpers/utils/translations';

export const NearmapCreditList = [
  [
    {
      title: i18n.t('Credits.1000m2'),
      creditCount: GENERAL_SERVICE_CREDITS.nearmaps.toString(),
    },
    {
      title: i18n.t('Credits.additional1000m2'),
      creditCount: `+${GENERAL_SERVICE_CREDITS.nearmaps}`,
    },
  ],
  [
    {
      title: i18n.t('Credits.allAvailableDates'),
      creditCount: i18n.t('Credits.creditCountForAllAvailableDates'),
    },
  ],
];
