import Uppy from '@uppy/core';
import XHRUpload, { XHRUploadOptions } from '@uppy/xhr-upload';

export type XHRUploadWithRetryPluginOptions = {
  id?: string;
  retries?: number;
  factor?: number;
} & XHRUploadOptions;

/**
 * Wrapper for the Uppy upload-xhr plugin which supports automatic retries
 * with backoff before marking an upload as "failed." Intended for use in
 * an environment where upload HTTP requests sporadically fail.
 *
 * @todo write retry logic that works, previous implementation required
 * `uppy.setOptions({ autoProceed: true })`
 * for the files to be automatically retried by uppy
 *
 * But this causes issues because:
 * 1. if a user uploads to survey media and an item fails
 * 2. autoProceed is set to true
 * 3. User goes to drone photos next and selects images
 * 4. images automatically upload before they can set a bounding box
 *
 * Ned to overwrite `super.upload` and make it so we don't have to add a new request
 * but rather can reuse the original request
 */
export default class XHRUploadWithRetryPlugin extends XHRUpload {
  retries: number;
  factor: number;

  constructor(uppy: Uppy, opts: XHRUploadWithRetryPluginOptions) {
    super(uppy, opts);

    this.id = `${this.id}WithRetryPlugin`;

    this.retries = opts.retries || 3;
    this.factor = opts.factor || 2;
  }
}
