import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

export const maxFillValidationSchema = Yup.object().shape({
  [FORM_CONTROLS.HOMEOWNER_NAME]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.DESIGN_ONE_MODULE]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MODULE_SPACING_LONG]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MODULE_SPACING_SHORT]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.AZIMUTH_RANGE_START]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.AZIMUTH_RANGE_END]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MODULE_ORIENTATION]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.MIXED_ORIENTATION]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_CATEGORY]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.SIZED_FOR_VALUE]: Yup.string().when(FORM_CONTROLS.SIZED_FOR_CATEGORY, {
    is: (category: string) => category !== SIZED_FOR_CATEGORIES.none,
    then: (schema) => schema.required('*This field is required').test(...noWhitespaces),
  }),
});
