import { StatusBar } from '@uppy/react';
import { useMemo } from 'react';

import { Button } from 'antd';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import { UploadOverlayProps } from './types';
import './UploadOverlay.scss';
import { UploadProgressBar } from './UploadProgressBar';

const UploadOverlay = ({ handleCancel, uploadProgressMap, uppy }: UploadOverlayProps) => {
  const uploadProgressPercent = useMemo(() => {
    if (!uploadProgressMap) {
      return 0;
    }
    const { totalBytes, totalUploadedBytes } = Object.keys(uploadProgressMap).reduce(
      (accumulator, fileId) => {
        const { bytesUploaded, bytesTotal } = uploadProgressMap[fileId];
        return {
          totalBytes: accumulator.totalBytes + bytesTotal,
          totalUploadedBytes: accumulator.totalUploadedBytes + bytesUploaded,
        };
      },
      { totalBytes: 0, totalUploadedBytes: 0 }
    );

    return Math.round((totalUploadedBytes / totalBytes) * 100);
  }, [uploadProgressMap]);

  if (!uppy && !uploadProgressMap) {
    throw new Error('Uppy or UploadProgressMap required');
  }

  return (
    <div aria-modal="true" role="dialog" className="UploadOverlay">
      <Button className="UploadOverlay-Cancel" onClick={handleCancel} aria-label="Cancel upload">
        <XIcon />
      </Button>
      {uppy ? (
        <StatusBar
          uppy={uppy}
          hideUploadButton
          showProgressDetails
          hidePauseResumeButton
          hideRetryButton
          hideCancelButton
        />
      ) : (
        <UploadProgressBar uploadProgressPercent={uploadProgressPercent} />
      )}
    </div>
  );
};

export default UploadOverlay;
