import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Input, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldConfig, FieldInputProps } from 'formik';
import { camelCase } from 'lodash';
import {
  FORM_CONTROLS_MODEL,
  MAX_LENGTH_FOR_REVISION_NOTE,
  MODEL_PROBLEMS,
} from 'screens/DesignServices/constants';
import { ServiceRequest } from 'types';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import UploadForm from '../../DesignSelection/FormWrapper/UploadForm/UploadForm';
import '../CancelOrderModal/CancelOrderModal.scss';

const Wrapper = styled(Row)`
  display: flex;
`;

const StyledCol = styled(Col)`
  padding-right: 1rem;
  width: 50%;
  margin-top: 0.5rem;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    font-size: ${fontSizes.small};
    color: ${colors.gray};
    margin-left: 0;
    margin-bottom: 1rem;
  }
`;

const Label = styled.p`
  width: 100%;
`;

const RevisionModal = ({
  handleChange,
  handleSubmit,
  getFieldProps,
  serviceRequest,
}: {
  handleChange: (e: CheckboxChangeEvent | ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  serviceRequest: ServiceRequest;
}) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [uploadComplete, setUploadComplete] = useState(false);
  const [filesAlreadyStored, setFilesAlreadyStored] = useState(null);
  const midPoint = Math.ceil(MODEL_PROBLEMS.length / 2);
  const leftColData = MODEL_PROBLEMS.slice(0, midPoint);
  const rightColData = MODEL_PROBLEMS.slice(midPoint);

  const handleFilesInAlbum = (e: any) => {
    const val = e.target.value;
    setFilesAlreadyStored(val);
    setUploadComplete(val);
  };

  return (
    <form onSubmit={handleSubmit} data-testid="revision-modal">
      <div className="CancelOrderModal-Wrapper">
        <p className="CancelOrderModal-Title">{t('DesignService.revisionModal.title')}</p>
        <div className="CancelOrderModal-Section">
          <Label>{t('DesignService.revisionModal.subheader')}</Label>
          <Wrapper>
            <StyledCol>
              {leftColData.map((option) => {
                return (
                  <Checkbox onChange={handleChange} key={camelCase(option.text)} name={option.name}>
                    {option.text}
                  </Checkbox>
                );
              })}
            </StyledCol>
            <StyledCol>
              {rightColData.map((option) => {
                return (
                  <Checkbox onChange={handleChange} key={camelCase(option.text)} name={option.name}>
                    {option.text}
                  </Checkbox>
                );
              })}
            </StyledCol>
          </Wrapper>
        </div>
        <div className="CancelOrderModal-Section">
          <Label>{t('DesignService.revisionModal.noteTitle')}</Label>
          <div className="CancelOrderModal-TextArea">
            <TextArea
              rows={4}
              maxLength={MAX_LENGTH_FOR_REVISION_NOTE}
              name="note"
              onChange={handleChange}
              value={getFieldProps(FORM_CONTROLS_MODEL.NOTE).value}
            />
          </div>
        </div>
        <UploadForm
          tierLevel={serviceRequest.tierLevel}
          uploadComplete={uploadComplete}
          setUploadComplete={setUploadComplete}
          handleFilesInAlbum={handleFilesInAlbum}
          filesAlreadyStored={filesAlreadyStored}
          isRevision={true}
          serviceRequestProjectId={serviceRequest.project.id}
        />
      </div>
    </form>
  );
};

export default RevisionModal;
