import { PRICING_TIER } from '@cpm/scanifly-shared-data';
import { Subscription } from 'types';

import API from 'api/config';

import { USER_STATUS } from 'helpers/constants/userStatus';

import {
  adminCreateInvoiceUrl,
  adminCreateNewSubscriptionUrl,
  adminDeactivateUserUrl,
  adminDeleteAccessTokenUrl,
  adminDeleteUserUrl,
  adminEditUserUrl,
  adminFetchAccessTokensUrl,
  adminFetchActiveSubscriptionUrl,
  adminFetchAllProjectsUrl,
  adminFetchAllSubscriptionsOfAGivenCompanyUrl,
  adminFetchAllUsersUrl,
  adminFetchCompaniesNotPopulatedUrl,
  adminFetchCompaniesUrl,
  adminFetchCompaniesWithAccountInfoUrl,
  adminFetchCompaniesWithPopulatedMembersUrl,
  adminFetchCompanyNameAndIdsUrl,
  adminFetchCompanyProjectsUrl,
  adminFetchCompanyUsersUrl,
  adminFetchScaniflyAdminsUrl,
  adminFetchSelectedSubscriptionUrl,
  adminFetchUserProjectsUrl,
  adminFreezeUserUrl,
  adminGenerateAccessTokenUrl,
  adminReactivateUserUrl,
  adminRefreshInvoiceStatusesUrl,
  adminScaniflyProjectsUrl,
  adminUpdateCompanyMemberStatusesUrl,
  adminUpdateCompanyPricingTierUrl,
  adminUpdateInvoiceUrl,
  adminUpdateSubscriptionUrl,
  adminUpdateUserPermissionsUrl,
  adminUpdateUserProjectAccessUrl,
} from './adminUrls';

export const adminFetchAllProjects = (
  size: number,
  pageIndex: number,
  sortBy?: {},
  filterBy?: {}
) =>
  API.get(adminFetchAllProjectsUrl(), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });

export const adminFetchAccessTokens = (pageIndex: number, size: number, companyName = null) =>
  API.get(adminFetchAccessTokensUrl(), {
    params: {
      pageIndex,
      size,
      ...(companyName ? { companyName } : {}),
    },
  });

export const adminDeleteAccessToken = (id: string) => API.delete(adminDeleteAccessTokenUrl(id));

export const adminGenerateAccessToken = (id: string) => API.post(adminGenerateAccessTokenUrl(id));

export const deleteUser = (userId: string) => API.delete(adminDeleteUserUrl(userId));

export const adminUpdateStatus = (userId: string, targetStatus: string) => {
  if (targetStatus === USER_STATUS.INACTIVE) {
    return API.post(adminDeactivateUserUrl(userId));
  }
  if (targetStatus === USER_STATUS.ACTIVE) {
    return API.post(adminReactivateUserUrl(userId));
  }
  if (targetStatus === USER_STATUS.FROZEN) {
    return API.post(adminFreezeUserUrl(userId));
  }
};

export const adminFetchScaniflyProjects = (
  size: number,
  pageIndex: number,
  sortBy?: {},
  filterBy?: {}
) =>
  API.get(adminScaniflyProjectsUrl(), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });

export const adminFetchActiveSubscription = (companyId: string) =>
  API.get(adminFetchActiveSubscriptionUrl(companyId));

export const adminFetchSelectedSubscription = (subscriptionId: string) =>
  API.get(adminFetchSelectedSubscriptionUrl(subscriptionId));

export const adminCreateInvoice = (companyId: string, month: number, year: number) =>
  API.post(adminCreateInvoiceUrl(companyId, month, year));

export const adminUpdateInvoice = (companyId: string, month: number, year: number) =>
  API.get(adminUpdateInvoiceUrl(companyId, month, year));

export const adminRefreshInvoiceStatuses = (month: number, year: number) =>
  API.get(adminRefreshInvoiceStatusesUrl(month, year));

export const adminCreateNewSubscription = (newSubscription: Subscription) =>
  API.post(adminCreateNewSubscriptionUrl(), newSubscription);

export const adminUpdateSubscription = (
  subscriptionId: string,
  updatedSubscription: Subscription
) => API.post(adminUpdateSubscriptionUrl(subscriptionId), updatedSubscription);

export const adminFetchAllSubscriptionsOfAGivenCompany = (companyId: string) =>
  API.get(adminFetchAllSubscriptionsOfAGivenCompanyUrl(companyId));

export const adminUpdateUser = (userId: string) => API.post(adminEditUserUrl(userId));

export const adminFetchAllUsers = () => API.get(adminFetchAllUsersUrl());

export const adminFetchCompanyUsers = (companyId: string) =>
  API.get(adminFetchCompanyUsersUrl(companyId));

export const adminFetchCompanyProjects = (
  companyId: string,
  size: number,
  pageIndex: number,
  sortBy?: {},
  filterBy?: {}
) =>
  API.get(adminFetchCompanyProjectsUrl(companyId), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });

export const adminFetchUserProjects = (
  userId: string,
  size: number,
  pageIndex: number,
  sortBy?: {},
  filterBy?: {}
) =>
  API.get(adminFetchUserProjectsUrl(userId), {
    params: {
      pageIndex,
      size,
      sortBy,
      filterBy,
    },
  });

export const adminFetchCompanies = () => API.get(adminFetchCompaniesUrl());

export const adminUpdateCompanyMemberStatuses = (companyId: string, targetStatus: string) => {
  return API.patch(adminUpdateCompanyMemberStatusesUrl(companyId), { targetStatus });
};

export const adminFetchCompaniesWithPopulatedMembers = () =>
  API.get(adminFetchCompaniesWithPopulatedMembersUrl());

export const adminFetchCompaniesNotPopulated = () => API.get(adminFetchCompaniesNotPopulatedUrl());
export const adminFetchCompanyNameAndIds = () => API.get(adminFetchCompanyNameAndIdsUrl());

export const adminFetchCompaniesWithAccountInfo = (month: number, year: number) =>
  API.get(adminFetchCompaniesWithAccountInfoUrl(month, year));

export const adminFetchScaniflyAdmins = () => API.get(adminFetchScaniflyAdminsUrl());

export const updateUserPermissions = (id: string, permissions: { permissions: any }) =>
  API.patch(adminUpdateUserPermissionsUrl(id), permissions);

export const updateUserProjectAccess = (id: string, projectAccess: null | string[]) =>
  API.patch(adminUpdateUserProjectAccessUrl(id), projectAccess);

export const adminUpdateCompanyPriceTier = (
  companyId: string,
  newPricingTier: { newPricingTier: PRICING_TIER }
) => API.post(adminUpdateCompanyPricingTierUrl(companyId), newPricingTier);
