import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { Button } from 'components';

import OptionProps from './OptionProps';

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;
`;
type PopoverProps = {
  visible: boolean;
  top: number;
  left: number;
};

const Popover = styled.div<PopoverProps>`
  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 100;
  border-radius: 1.3rem;
`;

const OptionsButton = ({ children, border, padding }: OptionProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const popOverRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleButtonClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPopoverPosition({
        top: rect.top,
        left: rect.right - buttonRef.current.clientWidth,
      });
    }
    setShowPopover(!showPopover);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (popOverRef.current && !popOverRef.current.contains(event.target as Node)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current && showPopover) {
        const rect = buttonRef.current.getBoundingClientRect();
        setPopoverPosition({
          top: rect.top,
          left: rect.right - buttonRef.current.clientWidth,
        });
      }
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showPopover]);

  return (
    <PopoverContainer ref={popOverRef}>
      <Button
        ref={buttonRef}
        width="auto"
        padding={padding ? padding : '0 0 0 1rem'}
        border={border}
        onClick={handleButtonClick}
        icon="toggle"
        iconSize="2rem"
      />
      <Popover
        id="pop-over"
        visible={showPopover}
        top={popoverPosition.top}
        left={popoverPosition.left}
      >
        {children}
      </Popover>
    </PopoverContainer>
  );
};

export default OptionsButton;
