import * as Yup from 'yup';

export enum CHARGE_EXTRA_CREDITS_FORM_CONTROLS {
  'CREDIT_COUNT' = 'creditCount',
  'MEMO' = 'memo',
}

export type ChargeExtraCreditsFormValues = {
  memo: string;
  creditCount?: number;
};

export const validationSchema = Yup.object().shape({
  [CHARGE_EXTRA_CREDITS_FORM_CONTROLS.MEMO]: Yup.string().required('* Memo is required'),
  [CHARGE_EXTRA_CREDITS_FORM_CONTROLS.CREDIT_COUNT]: Yup.number().required(
    '* Credit count is required'
  ),
});
