import { SectionType } from '@cpm/scanifly-shared-data';
import { useContext } from 'react';
import styled from 'styled-components';

import { LoadingIndicator } from 'components';

import { ChecklistContext } from '../ChecklistProvider';
import { FieldCard } from '../FieldCard';

const SectionContainer = styled.div`
  overflow-y: auto;
  padding-right: 1rem;
`;

type SectionViewProps = {
  section?: SectionType;
};

export const SectionView = ({ section }: SectionViewProps) => {
  const { isFieldVisible } = useContext(ChecklistContext);
  if (!section) {
    return <LoadingIndicator />;
  }
  return (
    <SectionContainer>
      {section.components.map((field) => {
        return !field.conditional || isFieldVisible(field.id) ? (
          <FieldCard key={field.id} field={field} />
        ) : null;
      })}
    </SectionContainer>
  );
};
