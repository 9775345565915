import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = Yup.object().shape({
  [FORM_CONTROLS.FIRST_NAME]: Yup.string()
    .required('First name is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.LAST_NAME]: Yup.string()
    .required('Last name is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PHONE_NUMBER]: Yup.string()
    .required('Phone number is required')
    .test(...noWhitespaces),
});
