import { useEffect, useRef } from 'react';

type Props = {
  value: any;
};

const usePrevious = <T>({ value }: Props): T => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current as unknown as T;
};

export default usePrevious;
