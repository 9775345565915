import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Button, Modal } from 'antd';
import { useFormik } from 'formik';
import { ProjectDesignData } from 'types';

import {
  defaultProjectDesignCreated,
  projectDesignCreated,
} from 'state/slices/projectDesignsSlice';
import { updateProjectStatusById } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ACCESS } from 'helpers/constants';
import { DRONE_IMAGES } from 'helpers/constants/categories';
import { MODAL_PROPS } from 'helpers/constants/modals';
import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
  PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import {
  maintenanceReportRoute,
  projectCategoryRoute,
  scaniflyAdminProjectCategoryRoute,
} from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import useS3D from 'helpers/hooks/useS3D';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';
import { ReactComponent as Maintenance } from 'assets/maintenance-button-icon.svg';
import { ReactComponent as Drone } from 'assets/onsite-drone.svg';
import { ReactComponent as Satelite } from 'assets/remote-satelite.svg';

import { DESIGN_TYPES } from '../Designs/constants';
import { initialValues } from './constants';
import './RemoteOnsiteModal.scss';

interface IRemoteOnsiteModal {
  isDesignModalVisible: boolean;
  handleModalClose: () => void;
  toggleDesignModal: () => void;
  handleSaveAndContinue?: () => void;
  newProject: boolean;
}

const RemoteOnsiteModal = ({
  isDesignModalVisible,
  handleModalClose,
  toggleDesignModal,
  handleSaveAndContinue,
  newProject = true,
}: IRemoteOnsiteModal) => {
  const { t } = useTranslation();
  const [designTypeSelected, setDesignTypeSelected] = useState<string>('');
  const [remoteCount, setRemoteCount] = useState<number>(0);
  const [onSiteCount, setOnSiteCount] = useState<number>(0);
  const dispatch: AppDispatch = useDispatch();
  const s3d = useS3D();
  const history = useHistory();
  const location = useLocation();
  const { projectId } = useParams<{ projectId: string; categoryName: string }>();
  const { projectDesigns, isRequestPending } = useSelector(
    (state: RootState) => state.projectDesigns
  );
  const { project } = useSelector((state: RootState) => state.project);

  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);

  const isScaniflyAdminView =
    project?.submittedFor && location.pathname.startsWith('/scanifly-admin');

  const buildFormikObject = {
    initialValues,
    onSubmit: () => {
      handleCreateDesign();
    },
  };

  const { isValid, dirty, handleSubmit } = useFormik(buildFormikObject);
  const hasAccessToRemoteDesign = useFeatureToggle(ACCESS.REMOTE_DESIGN);
  const hasAccessToMaintenance = useFeatureToggle(ACCESS.MAINTENANCE_REPORT);

  const canUseRemoteDesignFeature =
    activeSubscription?.canUseRemoteDesign || hasAccessToRemoteDesign;
  const canUseMaintenanceFeature = activeSubscription?.canUseMaintenance || hasAccessToMaintenance;

  const handleButtonClick = (buttonName: string) => {
    if (buttonName === DESIGN_TYPES.MAINTENANCE && !canUseMaintenanceFeature) {
      window.open('https://scanifly.com/maintenance ', '_blank', 'noreferrer');
    } else if (buttonName === DESIGN_TYPES.REMOTE_DESIGN && !canUseRemoteDesignFeature) {
      window.open('https://scanifly.com/prelim-design ', '_blank', 'noreferrer');
    } else {
      setDesignTypeSelected(buttonName);
    }
  };

  useEffect(() => {
    const remoteArray = projectDesigns.filter((el) => el.remote);

    setRemoteCount(remoteArray.length);
    setOnSiteCount(projectDesigns.length - remoteArray.length);
  }, [projectDesigns]);

  useEffect(() => {
    if (!isDesignModalVisible) {
      setDesignTypeSelected('');
    }
  }, [designTypeSelected, isDesignModalVisible]);

  const hasDroneImages =
    project?.status === ADMIN_PROJECT_STATUSES.PUBLISHED ||
    project?.status === ADMIN_PROJECT_STATUSES.REPLACED ||
    project?.status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE;

  const onsiteDesigns = projectDesigns.filter((design: ProjectDesignData) => !design.remote);

  const isOnsiteDesignDisabled =
    project?.statusDescription === PROJECT_STATUSES.PROCESSING ||
    project?.statusDescription === PROJECT_STATUSES.UPLOADING ||
    project?.statusDescription === PROJECT_STATUSES.ERROR ||
    project?.statusDescription === PROJECT_STATUSES.SUBMISSION_ERROR ||
    (project?.statusDescription === PROJECT_STATUSES.MAINTENANCE && onsiteDesigns.length === 0);

  const isRemoteDesignDisabled =
    ![PROJECT_STATUSES.REMOTE, PROJECT_STATUSES.NO_FLIGHT].includes(
      project?.statusDescription || ''
    ) && !newProject;

  const handleCreateDesign = async () => {
    if (designTypeSelected === DESIGN_TYPES.ONSITE_DESIGN) {
      if (onSiteCount === 0 && !newProject && !hasDroneImages) {
        toggleDesignModal();
        const droneImagesRoute = isScaniflyAdminView
          ? scaniflyAdminProjectCategoryRoute(projectId, DRONE_IMAGES)
          : projectCategoryRoute(projectId, DRONE_IMAGES);
        history.push(droneImagesRoute);
      } else {
        if (handleSaveAndContinue) {
          handleSaveAndContinue();
        } else {
          await dispatch(
            projectDesignCreated({
              projectId: projectId,
              name: `Design ${onSiteCount + 1}`,
              remote: false,
            })
          );

          toggleDesignModal();
        }
      }
    } else if (designTypeSelected === DESIGN_TYPES.REMOTE_DESIGN) {
      if (!newProject) {
        dispatch(
          projectDesignCreated({
            projectId: projectId,
            name: `Remote Design ${remoteCount + 1}`,
            remote: true,
          })
        );
        toggleDesignModal();
      } else {
        dispatch(updateProjectStatusById(projectId, 'remoteDesignStarted'));
        const design = await dispatch(
          defaultProjectDesignCreated({
            projectId: projectId,
            name: `Remote Design 1`,
            remote: true,
          })
        );

        const remoteDesignId = design.payload.id;

        if (remoteDesignId) {
          history.push(s3d(projectId, remoteDesignId));
        }
      }
    } else if (designTypeSelected === DESIGN_TYPES.MAINTENANCE) {
      dispatch(updateProjectStatusById(projectId, NON_ADMIN_PROJECT_STATUSES.MAINTENANCE));

      history.push(maintenanceReportRoute(projectId));
    }
  };

  const isFormValid = isValid && dirty;
  const isSubmitDisabled = newProject ? !isFormValid && !designTypeSelected : !designTypeSelected;

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isDesignModalVisible}
      onCancel={handleModalClose}
      title={
        newProject
          ? t('RemoteOnsiteDesign.selectProjectType')
          : t('RemoteOnsiteDesign.createNewDesign')
      }
      className="RemoteOnsiteModal"
      {...MODAL_PROPS}
    >
      <XIcon
        onClick={toggleDesignModal}
        className="close-modal-icon"
        data-testid="close-modal-icon"
      />

      <form>
        <div className="content-wrapper">
          {!newProject && (
            <label htmlFor="remote">{t('RemoteOnsiteDesign.selectProjectType')}</label>
          )}
          <div className="remote-onsite-buttons">
            <button
              className={`button remote ${
                designTypeSelected === DESIGN_TYPES.REMOTE_DESIGN ? 'active' : ''
              } ${canUseRemoteDesignFeature && !isRemoteDesignDisabled ? '' : 'not-available'}`}
              onClick={() => handleButtonClick(DESIGN_TYPES.REMOTE_DESIGN)}
              type="button"
              name="remote"
              disabled={isRemoteDesignDisabled}
              data-testid="modal-remote-button"
            >
              <Satelite />
              {t('RemoteOnsiteDesign.remoteDesign')}
            </button>
            <button
              className={`button onsite ${
                designTypeSelected === DESIGN_TYPES.ONSITE_DESIGN ? 'active' : ''
              }`}
              onClick={() => handleButtonClick(DESIGN_TYPES.ONSITE_DESIGN)}
              disabled={isOnsiteDesignDisabled}
              type="button"
              name="onsite"
              data-testid="modal-onsite-button"
            >
              <Drone />
              {t('RemoteOnsiteDesign.onsiteDesign')}
            </button>
            <button
              onClick={() => handleButtonClick(DESIGN_TYPES.MAINTENANCE)}
              className={`button maintenance ${
                designTypeSelected === DESIGN_TYPES.MAINTENANCE ? 'active' : ''
              } ${canUseMaintenanceFeature ? '' : 'not-available'}`}
              type="button"
              name="maintenance"
              data-testid="modal-maintenance-button"
            >
              <Maintenance />
              {t('RemoteOnsiteDesign.maintenance')}
            </button>
          </div>
        </div>
        <div className="action-buttons">
          <Button
            onClick={toggleDesignModal}
            className="Button--White"
            data-testid="modal-cancel-button"
          >
            {t('RemoteOnsiteDesign.cancel')}
          </Button>
          <Button
            disabled={isSubmitDisabled}
            aria-disabled={isSubmitDisabled}
            className="Button--Blue"
            loading={isRequestPending}
            autoFocus={isFormValid}
            onClick={() => handleSubmit()}
            data-testid="modal-submit-button"
          >
            {t('RemoteOnsiteDesign.createNewDesign')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoteOnsiteModal;
