import { useEffect, useRef } from 'react';

type Props = {
  parentId?: string;
  expandable: boolean;
};

export const useResizableTextarea = ({ parentId, expandable }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!parentId) return;
    if (!expandable) return;
    const textArea = textAreaRef.current;
    if (!textArea) return;

    const storedHeight = localStorage.getItem(`${parentId}-height`);
    if (storedHeight) {
      textArea.style.height = `${storedHeight}px`;
    }

    let isResizing = false;

    const handleMouseMove = () => {
      if (isResizing) {
        localStorage.setItem(`${parentId}-height`, textArea.offsetHeight.toString());
      }
    };

    const handleMouseDown = () => {
      isResizing = true;
    };

    const handleMouseUp = () => {
      if (isResizing) {
        localStorage.setItem(`${parentId}-height`, textArea.offsetHeight.toString());
        isResizing = false;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    textArea.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      textArea.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [parentId, expandable]);

  return textAreaRef;
};
