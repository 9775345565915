import { DRONE_DATA_SCORE as SCORES } from '@cpm/scanifly-shared-data';

import colors from 'helpers/constants/colors';

export const COLORS = ['#00D0FA', '#009AFF', colors.mainBlue];

export const FAKE_DRONE_DATA = {
  [SCORES.droneDataScore]: undefined,
  [SCORES.cameraTechnique]: '33',
  [SCORES.flightPath]: '33',
  [SCORES.imageQuality]: '33',
  [SCORES.cameraTiltScore]: undefined,
  [SCORES.imageFiltersScore]: undefined,
  [SCORES.surveyEfficiencyScore]: undefined,
  [SCORES.imageCountScore]: undefined,
  [SCORES.idealFlightDeviationScore]: undefined,
  [SCORES.interImageDistanceScore]: undefined,
  [SCORES.detailFlightScore]: undefined,
  [SCORES.contextFlightScore]: undefined,
};

// NOTE: The DDS creation began in the afternoon/evening of 10/31 and so not all the small projects processed on 10/31 had score yet, so the threshold for DDS UI to be shown is at the end of 10/31/2022 for small projects and the beginning of 10/31/2022 for large projects. Additional information: CPM-1089
export const thresholdDateForSmallProjects = new Date(2022, 9, 31, 23, 59, 59).getTime();
export const thresholdDateForLargeProjects = new Date(2022, 9, 31).getTime();
