import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionalActionEnum, TextInputFieldType } from '@cpm/scanifly-shared-data';
import { useFormik } from 'formik';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';
import { EditButtons, EditFieldText, UploadButton } from 'screens/ChecklistTemplate/Editor/common';

import { Toggle } from 'components';

import {
  Description,
  FieldWrapper,
  Label,
  RowNoMargin,
  Spacer,
  StyledTextInput,
} from './CommonFieldComponents';
import ConditionalStatement from './ConditionalStatement';
import useChecklistButtons from './hooks/useChecklistsButtons';
import IdDisplay from './IdDisplay';

type Props = {
  item: TextInputFieldType;
};

function TextInput({
  item: { id, componentType, isRequired, label, description, conditional, exampleMedia },
}: Props) {
  const { t } = useTranslation();
  const { updateField, moveField } = useContext(ChecklistTemplateContext);

  const handleUpdateField = useCallback(
    (newValues: Partial<TextInputFieldType>) => {
      updateField(id, newValues);
    },
    [updateField, id]
  );

  const formik = useFormik({
    initialValues: {
      label,
      isRequired,
      description,
      exampleMedia: exampleMedia || [],
      conditional: {
        action: conditional?.action || ('' as ConditionalActionEnum),
        isEqual: {
          targetFieldId: conditional?.isEqual?.targetFieldId || '',
          targetValue: conditional?.isEqual?.targetValue || '',
        },
      },
    },
    onSubmit: (values) => {
      handleUpdateField(values);
    },
  });

  const {
    handleCondition,
    handleCopy,
    handleDelete,
    handleEdit,
    handleSave,
    handleCancel,
    editing,
  } = useChecklistButtons({ id, handleSubmit: formik.handleSubmit, resetForm: formik.resetForm });

  return (
    <FieldWrapper conditional={Boolean(conditional?.isEqual.targetValue)}>
      {editing.includes(id) ? (
        <form>
          <RowNoMargin>
            <Label>{t('Checklists.editingField')}</Label>
            <EditButtons
              mode="edit"
              componentType={componentType}
              onSave={handleSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
            />
          </RowNoMargin>
          <Spacer />
          <IdDisplay id={id} />
          <Spacer />
          <EditFieldText
            id="label"
            label={t('Checklists.label') as string}
            onChange={formik.handleChange}
            value={formik.values.label}
          />
          <Spacer />
          <EditFieldText
            parentId={id}
            id="description"
            label={t('Checklists.description') as string}
            onChange={formik.handleChange}
            value={formik.values.description}
            expandable
          />
          <Spacer height={3} />
          <UploadButton
            id={id}
            onChange={(mediaId) => {
              formik.setFieldValue('exampleMedia', mediaId);
            }}
            value={formik.values.exampleMedia}
          />
          <Spacer height={4} />
          <ConditionalStatement
            id={`${id}-condition`}
            value={formik.values.conditional}
            onChange={formik.handleChange}
            resetForm={() => {
              formik.resetForm({
                values: {
                  label,
                  isRequired: formik.values.isRequired,
                  exampleMedia: formik.values.exampleMedia,
                  description,
                  conditional: {
                    action: '' as ConditionalActionEnum,
                    isEqual: {
                      targetFieldId: '',
                      targetValue: '',
                    },
                  },
                },
              });
            }}
          />
          <Spacer />
          <Toggle
            id={'isRequired'}
            label={t('Checklists.isRequired')}
            checked={formik.values.isRequired}
            onChange={formik.handleChange}
            disabled={!editing.includes(id)}
          />
          <Spacer />
        </form>
      ) : (
        <>
          <RowNoMargin>
            <Label>{label}</Label>
            <EditButtons
              mode="display"
              componentType={componentType}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onUp={() => moveField(id, -1)}
              onDown={() => moveField(id, 1)}
              onCopy={handleCopy}
              onCondition={handleCondition}
            />
          </RowNoMargin>
          {description && <Spacer />}
          {description && <Description>{description}</Description>}
          <Spacer />
          <StyledTextInput disabled placeholder="Lorem ipsum..." />
          <Toggle
            id={`${id}-required`}
            label={t('Checklists.isRequired')}
            checked={formik.values.isRequired}
          />
        </>
      )}
    </FieldWrapper>
  );
}

export default TextInput;
