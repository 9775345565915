import { Link } from 'react-router-dom';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as ChecklistManagerIcon } from 'assets/checklist-manager.svg';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: ${fontSizes.medium};
  display: flex;
  font-weight: 500;
  grid-column: 1 / span 2;
  grid-row: 1;
  margin-top: 2rem;
`;

export const Description = styled.div`
  font-size: 1.2rem;
  color: $label-gray;
  line-height: 1.7rem;
  color: ${colors.labelGray};
  grid-column: 1 / span 2;
  grid-row: 2;
`;

export const ButtonContainer = styled.div`
  grid-column: 5;
  grid-row: 1 / span 2;
`;

export const StyledChecklistManagerIcon = styled(ChecklistManagerIcon)`
  height: 4rem;
  width: 4rem;
`;

export const StyledEyeIcon = styled(EyeIcon)`
  height: 1.7rem;
  width: 1.7rem;
  &:hover {
    fill: ${colors.mainBlue};
  }
`;

export const ViewLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
`;

export const DottedSeperator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px dotted ${colors.neutralGray};
`;

export const FirstSeparator = styled(DottedSeperator)`
  margin: 4rem 0 0 0;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
  border-bottom: 1px dotted ${colors.neutralGray};
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr repeat(2, min-content);
  gap: 20px;
  align-items: center;
  border-bottom: 1px dotted ${colors.neutralGray};
  padding: 2rem 0;
`;

export const BottomGrid = styled.div`
  display: grid;
  margin: 4rem -4rem;
  grid-template-columns: auto auto auto auto auto auto auto;
`;

export const DefaultTemplateRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const DefaultTemplateRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DefaultIcons = styled.div``;
