import React, { createContext, useCallback, useMemo } from 'react';

import { ChecklistType, checklistUtils } from '@cpm/scanifly-shared-data';

interface ChecklistProviderProps {
  children: React.ReactNode;
  checklist: ChecklistType;
}

interface ChecklistContextType {
  isFieldVisible: (fieldId: string) => boolean;
}

export const ChecklistContext = createContext<ChecklistContextType>({
  isFieldVisible: (_) => true,
});

const ChecklistProvider: React.FC<ChecklistProviderProps> = ({
  children,
  checklist,
}: ChecklistProviderProps) => {
  const { conditionalMap } = useMemo(() => {
    const checklistFieldsArray = [];
    const valueMap: { [key: string]: any } = {};
    for (const section of checklist.submittedResponse ?? []) {
      for (const field of section.components) {
        checklistFieldsArray.push(field);
        valueMap[field.id] = field.value;
      }
    }
    return checklistUtils.countCompletedFields(checklistFieldsArray, valueMap);
  }, [checklist]);

  const isFieldVisible = useCallback(
    (fieldId: string) => {
      return conditionalMap[fieldId]?.isVisible ?? false;
    },
    [conditionalMap]
  );

  const contextValue: ChecklistContextType = useMemo(
    () => ({
      isFieldVisible,
    }),
    [isFieldVisible]
  );

  return <ChecklistContext.Provider value={contextValue}>{children}</ChecklistContext.Provider>;
};

export default ChecklistProvider;
