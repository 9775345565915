import { SortOrder } from 'antd/lib/table/interface';

import { SORT_OPTIONS } from 'helpers/constants/sortOptions';

export const getSortBy = (columnKey?: string | number | bigint, order?: SortOrder) => {
  if (!columnKey || !order)
    return {
      createdAt: SORT_OPTIONS.descend,
    };

  if (typeof columnKey === 'bigint') {
    columnKey = columnKey.toString();
  }

  return { [columnKey]: SORT_OPTIONS[order] };
};
