import React, { useState } from 'react';

import styled from 'styled-components';

import ErrorDisplay from 'components/ErrorDisplay';

import colors from 'helpers/constants/colors';

type ModalProps = {
  title?: string;
  description?: string;
  actionButtonOnClick: () => void | Promise<void>;
  actionButtonLabel: string;
  actionButtonTestId?: string;
  onCancel?: () => void;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

// It is usually somewhat risky to be so aggressive with z-index but in this case we
// know nothing should be above the overlay until it is dismissed.
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = styled.div`
  background: ${colors.white};
  padding: 3rem;
  width: 20%;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  min-width: 70rem;
  max-height: 90vh;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 1.4em;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 3rem;
`;

const CommonButton = styled.button`
  margin-left: 2rem;
  border-radius: 0.8rem;
  border: 1px solid ${colors.lightGray};
  padding: 0.5rem 2rem;
  cursor: pointer;
`;

const CancelButton = styled(CommonButton)`
  background: ${colors.white};
`;

const ActionButton = styled(CommonButton)`
  background: ${colors.mainBlue};
  color: ${colors.white};

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.gray};
    cursor: not-allowed;
  }
`;

const CommercialConfirmationModal: React.FC<ModalProps> = ({
  title,
  description,
  actionButtonOnClick,
  actionButtonLabel,
  actionButtonTestId,
  onCancel,
  isVisible,
  setIsVisible,
}: ModalProps) => {
  const [error, setError] = useState<any | undefined>();

  const handleClose = () => {
    setError(undefined);
    setIsVisible(false);
    onCancel && onCancel();
  };
  const handleButtonClick = async () => {
    try {
      await actionButtonOnClick();
      setError(undefined);
      setIsVisible(false);
    } catch (e: any) {
      setError(e);
    }
  };

  return isVisible ? (
    <Overlay>
      <Modal>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
        {error && <ErrorDisplay error={error} />}
        <ButtonGroup>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ActionButton type="submit" data-testid={actionButtonTestId} onClick={handleButtonClick}>
            {actionButtonLabel}
          </ActionButton>
        </ButtonGroup>
      </Modal>
    </Overlay>
  ) : null;
};

export default CommercialConfirmationModal;
