import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerLabel = styled.div`
  font-weight: bold;
  margin: 1rem 0;
`;

const OptionWrapper = styled.div`
  display: flex;
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1rem;
    height: 0.5rem;
    background-color: ${colors.black};
    border-radius: 0.25rem;
    margin-left: 0.45rem; // Used to align with the required component for OCD purposes
  }
`;

type Props = {
  answer: string | null;
};

export const OptionAnswer = ({ answer }: Props) => {
  const { t } = useTranslation();
  return (
    <AnswerWrapper>
      <AnswerLabel>{t('Checklists.answer')}</AnswerLabel>
      <OptionWrapper>{answer !== null && answer}</OptionWrapper>
    </AnswerWrapper>
  );
};
