import { DRONE_IMAGES, DRONE_IMAGES_CATEGORY } from 'helpers/constants/categories';
import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import {
  designServicesLandingRoute,
  draftChecklistRoute,
  draftNotesRoute,
  draftProjectCategoriesRoute,
  draftProjectCategoryRoute,
  draftProjectConsumptionDataRoute,
  draftProjectCustomerInfoRoute,
  draftProjectDesignsRoute,
  draftProjectInfo,
  draftProjectOverviewRoute,
  draftProjectSiteDataRoute,
  maintenanceReportRoute,
  projectCategoriesRoute,
  projectCategoryRoute,
  projectChecklistRoute,
  projectConsumptionDataRoute,
  projectCustomerInfoRoute,
  projectDesignsRoute,
  projectInfoRoute,
  projectNotesRoute,
  projectOverviewRoute,
  projectSiteDataRoute,
  projectsListRoute,
  scaniflyAdminAccessTokensRoute,
  scaniflyAdminAccountingRoute,
  scaniflyAdminAccountManagerRoute,
  scaniflyAdminAllCreditHistoryRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDesignServicesQueueRoute,
  scaniflyAdminDraftConsumptionDataRoute,
  scaniflyAdminDraftCustomerInfoRoute,
  scaniflyAdminDraftNotesRoute,
  scaniflyAdminDraftOverviewRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectDesignsRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminDraftScaniflyInfoRoute,
  scaniflyAdminDraftSiteDataRoute,
  scaniflyAdminProjectCategoriesRoute,
  scaniflyAdminProjectCategoryRoute,
  scaniflyAdminProjectConsumptionDataRoute,
  scaniflyAdminProjectCustomerInfoRoute,
  scaniflyAdminProjectDesignsRoute,
  scaniflyAdminProjectNotesRoute,
  scaniflyAdminProjectOverviewRoute,
  scaniflyAdminProjectProjectInfoRoute,
  scaniflyAdminProjectScaniflyInfoRoute,
  scaniflyAdminProjectSiteDataRoute,
  scaniflyAdminProjectsRoute,
  scaniflyAdminUsersRoute,
} from 'helpers/constants/routes';

import {
  ALL_STEPS,
  CS_PROJECT_STEPS,
  CS_UPLOAD_STEPS,
  DESIGN_SERVICES_MENU,
  PROJECT_MENU,
  SCANIFLY_ADMIN_MENU,
} from './constants';

export const getProjectMenuRoute = (projectId, routeName) => {
  switch (routeName) {
    case ALL_STEPS.PROJECT_OVERVIEW:
      return projectOverviewRoute(projectId);
    case PROJECT_MENU.CUSTOMER_INFO:
      return projectCustomerInfoRoute(projectId);
    case ALL_STEPS.CONSUMPTION_DATA:
      return projectConsumptionDataRoute(projectId);
    case PROJECT_MENU.PROJECT_INFO:
      return projectInfoRoute(projectId);
    case PROJECT_MENU.MODELS:
      return projectDesignsRoute(projectId);
    case ALL_STEPS.SITE_DATA:
      return projectSiteDataRoute(projectId);
    case PROJECT_MENU.DRONE_IMAGES:
      return projectCategoryRoute(projectId, DRONE_IMAGES);
    case PROJECT_MENU.ALBUMS:
      return projectCategoriesRoute(projectId);
    case PROJECT_MENU.COMMENTS:
      return projectNotesRoute(projectId);
    case DESIGN_SERVICES_MENU.ORDER_A_DESIGN:
      return designServicesLandingRoute(projectId);
    case ALL_STEPS.CHECKLISTS:
      return projectChecklistRoute(projectId);
    case ALL_STEPS.MAINTENANCE:
      return maintenanceReportRoute(projectId);
    default:
      return projectsListRoute();
  }
};

export const getAdminProjectMenuRoute = (projectId, routeName) => {
  switch (routeName) {
    case ALL_STEPS.PROJECT_OVERVIEW:
      return scaniflyAdminProjectOverviewRoute(projectId);
    case CS_PROJECT_STEPS.CUSTOMER_INFO:
      return scaniflyAdminProjectCustomerInfoRoute(projectId);
    case ALL_STEPS.CONSUMPTION_DATA:
      return scaniflyAdminProjectConsumptionDataRoute(projectId);
    case CS_PROJECT_STEPS.SCANIFLY_INFO:
      return scaniflyAdminProjectScaniflyInfoRoute(projectId);
    case CS_PROJECT_STEPS.PROJECT_INFO:
      return scaniflyAdminProjectProjectInfoRoute(projectId);
    case CS_PROJECT_STEPS.MODELS:
      return scaniflyAdminProjectDesignsRoute(projectId);
    case ALL_STEPS.SITE_DATA:
      return scaniflyAdminProjectSiteDataRoute(projectId);
    case PROJECT_MENU.DRONE_IMAGES:
      return scaniflyAdminProjectCategoryRoute(projectId, DRONE_IMAGES);
    case CS_PROJECT_STEPS.ALBUMS:
      return scaniflyAdminProjectCategoriesRoute(projectId);
    case CS_PROJECT_STEPS.COMMENTS:
      return scaniflyAdminProjectNotesRoute(projectId);
    case DESIGN_SERVICES_MENU.ORDER_A_DESIGN:
      return designServicesLandingRoute(projectId);
    case ALL_STEPS.MAINTENANCE:
      return maintenanceReportRoute(projectId);
    default:
      return projectsListRoute();
  }
};

export const getDraftProjectMenuRoute = (projectId, routeName) => {
  switch (routeName) {
    case ALL_STEPS.PROJECT_OVERVIEW:
      return draftProjectOverviewRoute(projectId);
    case PROJECT_MENU.CUSTOMER_INFO:
      return draftProjectCustomerInfoRoute(projectId);
    case ALL_STEPS.CONSUMPTION_DATA:
      return draftProjectConsumptionDataRoute(projectId);
    case PROJECT_MENU.PROJECT_INFO:
      return draftProjectInfo(projectId);
    case PROJECT_MENU.MODELS:
      return draftProjectDesignsRoute(projectId);
    case ALL_STEPS.SITE_DATA:
      return draftProjectSiteDataRoute(projectId);
    case ALL_STEPS.DRONE_IMAGES:
      return draftProjectCategoryRoute(projectId, DRONE_IMAGES);
    case PROJECT_MENU.ALBUMS:
      return draftProjectCategoriesRoute(projectId);
    case PROJECT_MENU.COMMENTS:
      return draftNotesRoute(projectId);
    case ALL_STEPS.CHECKLISTS:
      return draftChecklistRoute(projectId);
    case DESIGN_SERVICES_MENU.ORDER_A_DESIGN:
      return designServicesLandingRoute(projectId);
    case ALL_STEPS.MAINTENANCE:
      return maintenanceReportRoute(projectId);
    default:
      return projectsListRoute();
  }
};

export const getAdminDraftProjectMenuRoute = (projectId, routeName) => {
  switch (routeName) {
    case ALL_STEPS.PROJECT_OVERVIEW:
      return scaniflyAdminDraftOverviewRoute(projectId);
    case CS_UPLOAD_STEPS.CUSTOMER_INFO:
      return scaniflyAdminDraftCustomerInfoRoute(projectId);
    case ALL_STEPS.CONSUMPTION_DATA:
      return scaniflyAdminDraftConsumptionDataRoute(projectId);
    case CS_UPLOAD_STEPS.SCANIFLY_INFO:
      return scaniflyAdminDraftScaniflyInfoRoute(projectId);
    case CS_UPLOAD_STEPS.PROJECT_INFO:
      return scaniflyAdminDraftProjectInfoRoute(projectId);
    case CS_UPLOAD_STEPS.MODELS:
      return scaniflyAdminDraftProjectDesignsRoute(projectId);
    case ALL_STEPS.SITE_DATA:
      return scaniflyAdminDraftSiteDataRoute(projectId);
    case ALL_STEPS.DRONE_IMAGES:
      return scaniflyAdminProjectCategoryRoute(projectId, DRONE_IMAGES);
    case CS_UPLOAD_STEPS.ALBUMS:
      return scaniflyAdminDraftProjectCategoriesRoute(projectId);
    case CS_UPLOAD_STEPS.COMMENTS:
      return scaniflyAdminDraftNotesRoute(projectId);
    case ALL_STEPS.MAINTENANCE:
      return maintenanceReportRoute(projectId);
    default:
      return projectsListRoute();
  }
};

export const getScaniflyAdminMenuRoute = (routeName) => {
  switch (routeName) {
    case SCANIFLY_ADMIN_MENU.USERS:
      return scaniflyAdminUsersRoute();
    case SCANIFLY_ADMIN_MENU.ACCOUNT_MANAGER:
      return scaniflyAdminAccountManagerRoute();
    case SCANIFLY_ADMIN_MENU.UPLOAD_QUEUE:
      return scaniflyAdminCustomerSupportUploadRoute();
    case SCANIFLY_ADMIN_MENU.PROJECTS:
      return scaniflyAdminProjectsRoute();
    case SCANIFLY_ADMIN_MENU.ACCESS_TOKENS:
      return scaniflyAdminAccessTokensRoute();
    case SCANIFLY_ADMIN_MENU.ACCOUNTING:
      return scaniflyAdminAccountingRoute();
    case SCANIFLY_ADMIN_MENU.DS_UPLOAD_QUEUE:
      return scaniflyAdminDesignServicesQueueRoute();
    case SCANIFLY_ADMIN_MENU.CREDIT_HISTORY:
      return scaniflyAdminAllCreditHistoryRoute();
    default:
      return scaniflyAdminCustomerSupportUploadRoute();
  }
};

// Functionally there isn't any real difference in the nav between draft and not draft projects
// except the route that gets used. There are many places in the code base that seem to take
// queues from the current URL though so I didn't feel comfortable removing it entirely.
export const getMenuRoute = (routeName, isScaniflyAdminView, isDraft, projectId) => {
  if (projectId) {
    if (isDraft) {
      return isScaniflyAdminView
        ? getAdminDraftProjectMenuRoute(projectId, routeName)
        : getDraftProjectMenuRoute(projectId, routeName);
    } else {
      return isScaniflyAdminView
        ? getAdminProjectMenuRoute(projectId, routeName)
        : getProjectMenuRoute(projectId, routeName);
    }
  }
  return projectsListRoute();
};

export const isMenuItemDisabled = (item, notesAccess) => {
  return item === PROJECT_MENU.COMMENTS && !notesAccess;
};

export const isMenuItemHidden = (item, notes, photosAndStories) => {
  return (
    (item === PROJECT_MENU.COMMENTS && !notes) ||
    (item === PROJECT_MENU.ALBUMS && !photosAndStories) ||
    (item === PROJECT_MENU.DRONE_IMAGES && photosAndStories)
  );
};

export const isMenuItemActive = (match, item, pathname) => {
  if (pathname.includes(DRONE_IMAGES_CATEGORY)) {
    return item === PROJECT_MENU.MODELS;
  }
  return match;
};

export const insertStepBefore = (sourceObj, newStep, contingentStepKey) => {
  const sourceKeys = Object.keys(sourceObj);
  const indexOfContingent = sourceKeys.indexOf(contingentStepKey);
  if (indexOfContingent === -1) {
    return sourceObj;
  }
  let newObj = {};
  sourceKeys.forEach((key, index) => {
    if (index === indexOfContingent) {
      newObj = { ...newObj, ...newStep };
    }
    newObj[key] = sourceObj[key];
  });
  return newObj;
};

export const insertStepAfter = (sourceObj, newStep, contingentStep) => {
  const sourceKeys = Object.keys(sourceObj);
  const sourceValues = Object.values(sourceObj);
  const indexOfContingent = sourceValues.indexOf(contingentStep);
  if (indexOfContingent === -1) {
    return sourceObj;
  }
  let newObj = {};
  sourceKeys.forEach((key, index) => {
    newObj[key] = sourceObj[key];
    if (index === indexOfContingent) {
      newObj = { ...newObj, ...newStep };
    }
  });
  return newObj;
};

export const isProjectEligibleForDesignOrder = (status) => {
  // Errored projects will have a status page showing they have errored
  // So the design order submenu will be visible for them
  // but the design selection page will not
  if (!status) return;
  const notEligibleStatuses = [
    NON_ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
    ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
    ADMIN_PROJECT_STATUSES.DELETED,
  ];
  return !notEligibleStatuses.includes(status);
};

export const getTooltipTitle = ({ isBasicTier, title }) => {
  if (title === DESIGN_SERVICES_MENU.ORDER_A_DESIGN && isBasicTier) {
    return (
      <>
        Design Services
        <br />
        <strong>
          <u>Upgrade</u>
        </strong>{' '}
        to unlock
      </>
    );
  }
  return 'Coming soon...';
};

export const selectAdminsAndSlice = (members, num) => {
  let admins = members.filter((member) => member.roles.includes('admin'));
  if (admins.length > num) {
    admins = admins.slice(0, num + 1);
  }
  return admins;
};
