import { CREDIT_TRANSACTION_CATEGORY } from '@cpm/scanifly-shared-data';
import { TFunction } from 'i18next';
import { CREDIT_ACTIONS } from '../constants';

export const getCreditTransactionCategoryByAction = (action: string) => {
  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return CREDIT_TRANSACTION_CATEGORY.refund;
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return CREDIT_TRANSACTION_CATEGORY.oneOffPurchase;
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return CREDIT_TRANSACTION_CATEGORY.manualAdjustment;
    default:
      return null;
  }
};

export const getModalTitle = ({
  action,
  t,
  companyName,
}: {
  action: string;
  t: TFunction<'translation', undefined>;
  companyName: string;
}) => {
  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return t('AccountManager.refundCreditsTitle', { companyName });
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return t('AccountManager.addTrialCreditsTitle', { companyName });
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return t('AccountManager.removeCreditsTitle', { companyName });
    default:
      return '';
  }
};

export const getButtonText = ({
  action,
  t,
}: {
  action: string;
  t: TFunction<'translation', undefined>;
}) => {
  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return t('AccountManager.refundCredits');
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return t('AccountManager.addCredits');
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return t('AccountManager.removeCredits');
    default:
      return '';
  }
};

export const getPlaceholder = ({
  action,
  t,
}: {
  action: string;
  t: TFunction<'translation', undefined>;
}) => {
  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return t('AccountManager.memoRefund');
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return t('AccountManager.memoAdd');
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return t('AccountManager.memoRemoval');
    default:
      return '';
  }
};

export const getSuccessText = ({
  quantity,
  companyName,
  balance,
  action,
  t,
}: {
  action: string;
  t: TFunction<'translation', undefined>;
  quantity: number;
  companyName: string;
  balance: number;
}) => {
  const data = { quantity, companyName, balance };

  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return t('AccountManager.successRefundCredits', data);
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return t('AccountManager.successAddTrialCredits', data);
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return t('AccountManager.successRemoveCredits', data);
    default:
      return '';
  }
};

export const getFailureText = ({
  companyName,
  action,
  t,
}: {
  action: string;
  t: TFunction<'translation', undefined>;
  companyName: string;
}) => {
  switch (action) {
    case CREDIT_ACTIONS.REFUND:
      return t('AccountManager.failureRefundCredits', { companyName });
    case CREDIT_ACTIONS.ADD_TRIAL_CREDIT:
      return t('AccountManager.failureAddTrialCredits', { companyName });
    case CREDIT_ACTIONS.REMOVE_CREDIT:
      return t('AccountManager.failureRemoveCredits', { companyName });
    default:
      return '';
  }
};
