import { OPTIONS } from 'components/InviteOrChangeTeammatesPermissions/constants';

import { PERMISSION, ROLE } from '@cpm/scanifly-shared-data';

export const createTeammatesInitialPermissions = ({
  teammate,
  setCurrentPermissions,
  setCurrentProjectAccess,
  allProjects,
}) => {
  const allProjectsAccess = !teammate.foldersAccess;
  const adminToolsAccess = teammate.roles.includes(ROLE.admin);

  setCurrentPermissions([
    ...teammate.permissions,
    ...(adminToolsAccess ? [OPTIONS.ADMIN_TOOLS.value] : []),
  ]);

  setCurrentProjectAccess(allProjectsAccess ? allProjects : teammate.foldersAccess);
};

export const createDefaultPermissions = ({
  setCurrentPermissions,
  setCurrentProjectAccess,
  allProjects,
}) => {
  const defaultPermissions = [PERMISSION.UPLOAD, PERMISSION.WATT3D];

  setCurrentPermissions(defaultPermissions);
  setCurrentProjectAccess(allProjects);
};
