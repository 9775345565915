import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BoundingBox } from 'types';

import { fetchBoundaries } from 'api/boundaries/boundariesService';

const name = 'boundaries';

type BoundariesState = {
  boundaries: {
    boundingBox: BoundingBox;
    projectId: string;
  } | null;
  isLoading: boolean;
  error: null | string | undefined;
};

export const boundariesRequested = createAsyncThunk(
  `${name}/boundariesRequested`,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await fetchBoundaries(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const boundariesSlice = createSlice({
  name,
  initialState: {
    boundaries: null,
    isLoading: false,
    error: null,
  } as BoundariesState,
  reducers: {
    resetBoundaries: (state) => {
      state.boundaries = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(boundariesRequested.pending, (state) => {
      state.isLoading = true;
      state.boundaries = null;
    });
    builder.addCase(boundariesRequested.fulfilled, (state, { payload }) => {
      state.boundaries = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(boundariesRequested.rejected, (state, { error }: { error: any }) => {
      state.error = error.message;
      state.isLoading = false;
    });
  },
});

export const { resetBoundaries } = boundariesSlice.actions;

export default boundariesSlice.reducer;
