export const fetchProjectMediaUrl = (id: string) => `/medias/${id}`;
export const fetchProjectMediasUrl = (projectId: string) => `/medias/project/${projectId}`;
export const fetchAllMediasUrl = (projectId: string) => `/medias/project/all/${projectId}`;
export const deleteProjectMediasUrl = () => '/medias/many';

export const fetchCategoryMediaUrl = (id: string) => `/surveyMedias/${id}`;
export const downloadCategoryMediaUrl = (id: string) => `surveyMedias/${id}/download-proxy`;
export const fetchCategoryMediasUrl = (projectId: string, categoryName?: string) =>
  `/surveyMedias/project/${projectId}?mediaCategoryName=${categoryName}`;
export const fetchCategoryMediasByIdUrl = (projectId: string, categoryId: string) =>
  `surveyMedias/project/${projectId}?mediaCategoryId=${categoryId}`;
export const deleteCategoryMediasUrl = () => '/surveyMedias/many';
