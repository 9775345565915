import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import colors from 'helpers/constants/colors';
import styled from 'styled-components';

const StyledPlusIcon = styled(PlusIcon)`
  display: block;
  margin: auto;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0.35;

  & path {
    fill: ${colors.labelGray};
  }
`;

const StyledButton = styled.button`
  width: 21.6rem;
  height: 26rem;
  margin: 1rem;
  position: relative;

  border-radius: 1rem;
  background-color: ${colors.background};
  border: 0.1rem solid ${colors.neutralGray};
  cursor: pointer;
  padding: 0 2.4rem 0 2.4rem;

  &:hover {
    background-color: ${colors.whiteFiftyPercent};
    transition: 300ms ease-in-out;

    .plus-icon {
      opacity: 0.45;
      transition: 350ms ease-in-out;
    }
  }
`;

export const AddTeammateButton = ({ handleModalOpen }: { handleModalOpen: () => void }) => {
  return (
    <StyledButton onClick={handleModalOpen} aria-label="add teammate" data-testid="add-teammate">
      <StyledPlusIcon className="plus-icon" />
    </StyledButton>
  );
};
