import {
  calculateChargePerPeriod,
  calculateOverageByProjectType,
  COMPANY_SIZE,
  INVOICE_STATUS,
  PAYMENT_TYPE,
  PROJECT_TYPES,
} from '@cpm/scanifly-shared-data';
import { Table } from 'antd';
import { capitalize } from 'lodash-es';
import moment from 'moment';
import {
  AccountingInfo,
  AccountingInfoQuantityKey,
  AccountingInfoSubscription,
  AccountingInfoSubscriptionQuantityKey,
} from 'types/AccountingInfo';
import Invoice from 'types/Invoice';

import colors from 'helpers/constants/colors';
import fontWeights from 'helpers/constants/fontWeights';
import { filterDateFormat } from 'helpers/utils/dateFormat';
import { numberFormatter } from 'helpers/utils/numberFormatter';
import { formatUSD } from 'helpers/utils/priceFormatter';
import { reduce } from 'helpers/utils/reducer';

import {
  DANGER_ZONE_CATEGORY,
  DS_CUTOFF_PERCENTAGE,
  FORM_CONTROLS,
  RENEWAL_CUTOFF_PERCENTAGE,
  UPGRADE_CUTOFF_PERCENTAGE,
} from './constants';

export const handleAccountingCSVExport = ({
  dataSource,
  month,
  year,
}: {
  dataSource: AccountingInfo[];
  month: number;
  year: number;
}) => {
  return dataSource.map((item) => {
    const {
      subscription,
      stripeInvoice,
      smallProjectsByMonth,
      largeProjectsByMonth,
      smallProjectsBySubscriptionStartDate,
      largeProjectsBySubscriptionStartDate,
      accountHolderName,
      accountHolderEmail,
      dspAdditionalFee,
      creditsBalance,
      totalPurchasedCredits,
      nearmapCreditsUsedBySubscriptionStart: nearmapCredits,
      designServiceCreditsUsedBySubscriptionStart: designServiceCredits,
    } = item || {};

    const {
      contractStartDate,
      contractEndDate,
      smallProjectQuota,
      largeProjectQuota,
      discountPercentage,
      paymentType,
      scaniflyOwner,
      scaniflyAccountManager,
      stripeCustomerId,
    } = subscription || {};

    return {
      ...item,
      contractStartDate: subscription
        ? moment.utc(new Date(contractStartDate)).format(filterDateFormat)
        : 'No active contract.',
      contractEndDate: subscription
        ? moment.utc(new Date(contractEndDate)).format(filterDateFormat)
        : 'No active contract.',
      smallSubscription: subscription ? smallProjectQuota : 0,
      largeSubscription: subscription ? largeProjectQuota : 0,
      smallProjectsByMonth: smallProjectsByMonth ?? 0,
      largeProjectsByMonth: largeProjectsByMonth ?? 0,
      smallProjectsBySubscriptionStartDate: smallProjectsBySubscriptionStartDate ?? 0,
      largeProjectsBySubscriptionStartDate: largeProjectsBySubscriptionStartDate ?? 0,
      smallProjectsRemaining: calculateRemainingProjectCount(item, PROJECT_TYPES.SMALL),
      largeProjectsRemaining: calculateRemainingProjectCount(item, PROJECT_TYPES.LARGE),
      monthlySubscription: formatUSD(
        subscription ? calculateMonthlySubscriptionAmount(subscription, month, year) : 0
      ),
      discount: subscription ? discountPercentage + '%' : 0,
      monthlyOverage: formatUSD(calculateTotalOverage(item)),
      monthlyTotal: formatUSD(calculateTotalFeeWithAdditionalFees({ item, month, year })),
      status: stripeInvoice ? capitalize(stripeInvoice.status) : 'Invoice not created.',
      scaniflyOwner: scaniflyOwner ? `${scaniflyOwner.firstName} ${scaniflyOwner.lastName}` : '-',
      scaniflyAccountManager: scaniflyAccountManager
        ? `${scaniflyAccountManager.firstName} ${scaniflyAccountManager.lastName}`
        : '-',
      updatedAt: `${(month + 1).toString().padStart(2, '0')}/${year}`,
      accountHolderName: accountHolderName ?? '-',
      accountHolderEmail: accountHolderEmail ?? '-',
      stripeCustomerId: stripeCustomerId ?? '-',
      paymentType: paymentType ? capitalize(paymentType) : capitalize(PAYMENT_TYPE.monthly),
      dangerZone: renderDangerZoneCategory(item),
      creditsBalance: creditsBalance.toLocaleString('en-US') ?? 0,
      totalPurchasedCredits: totalPurchasedCredits.toLocaleString('en-US') ?? 0,
      nearmapCreditsUsedBySubscriptionStart: nearmapCredits
        ? nearmapCredits.toLocaleString('en-US')
        : 0,
      designServiceCreditsUsedBySubscriptionStart: designServiceCredits
        ? designServiceCredits.toLocaleString('en-US')
        : 0,
      dspSubscriptionFee: formatUSD(
        calculateTotalDspSubscriptionFee({ subscription, month, year })
      ),
      dspAdditionalFee: formatUSD(dspAdditionalFee),
    };
  });
};

export const handleNegativeInputValues = (
  event: { target: HTMLInputElement },
  setFieldValue: (
    field: string,
    value: string | number,
    shouldValidate?: boolean | undefined
  ) => void,
  fieldName: keyof typeof FORM_CONTROLS
) => {
  if (event?.target?.value === '') {
    setFieldValue(FORM_CONTROLS[fieldName], '');
  } else if (Number(event.target.value) < 0) {
    setFieldValue(FORM_CONTROLS[fieldName], 0);
  } else {
    setFieldValue(FORM_CONTROLS[fieldName], Number(event.target.value));
  }
};

export const calculateMonthlyFee = ({
  monthlySubscription,
  discount,
}: {
  monthlySubscription: number;
  discount: number;
}) => {
  const subscription = monthlySubscription || 0;
  const discountRate = discount || 0;

  return Math.max(subscription - subscription * (discountRate / 100), 0);
};

export const calculateProfit = (monthlySubscription: number, discount: number) => {
  const subscription = monthlySubscription || 0;
  const discountRate = discount || 0;

  return Math.max((subscription - subscription * (discountRate / 100)) * 12, 0);
};

export const getCompanySizeByProfit = (profit: number) => {
  if (profit < 6000) {
    return COMPANY_SIZE.small;
  }
  if (profit >= 6000 && profit < 100000) {
    return COMPANY_SIZE.medium;
  }
  if (profit >= 100000) {
    return COMPANY_SIZE.large;
  }
};

export const checkIfDisabled = ({
  month,
  year,
  paymentType,
  subscriptionStartMonth,
  monthlyTotal,
}: {
  month: number;
  year: number;
  paymentType?: PAYMENT_TYPE;
  subscriptionStartMonth?: number;
  monthlyTotal: number;
}) => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  if (paymentType === PAYMENT_TYPE.upfront) {
    if (month === subscriptionStartMonth) return false;
  }

  if (year > currentYear) return true;
  if (year === currentYear && month >= currentMonth) return true;
  if (!monthlyTotal) return true;

  return false;
};

export const calculateTotalOverage = (item: AccountingInfo) => {
  const {
    smallProjectsByMonth,
    largeProjectsByMonth,
    smallProjectsBySubscriptionStartDate,
    largeProjectsBySubscriptionStartDate,
    subscription,
  } = item || {};

  const { smallProjectQuota, largeProjectQuota, smallOverage, largeOverage } = subscription || {};

  const overageForSmallProjects = calculateOverageByProjectType({
    projectCountOfCurrentMonth: smallProjectsByMonth,
    projectCountBySubscriptionStartDate: smallProjectsBySubscriptionStartDate,
    projectQuota: smallProjectQuota,
    projectType: PROJECT_TYPES.SMALL,
    overageValue: smallOverage,
  });

  const overageForLargeProjects = calculateOverageByProjectType({
    projectCountOfCurrentMonth: largeProjectsByMonth,
    projectCountBySubscriptionStartDate: largeProjectsBySubscriptionStartDate,
    projectQuota: largeProjectQuota,
    projectType: PROJECT_TYPES.LARGE,
    overageValue: largeOverage,
  });

  return overageForSmallProjects + overageForLargeProjects;
};

export const calculateTotalFeeWithAdditionalFees = ({
  item,
  month,
  year,
}: {
  item: AccountingInfo;
  month: number;
  year: number;
}) => {
  if (!item?.subscription) return 0;
  const { dspAdditionalFee: dspOneOffCreditFee, subscription } = item || {};
  const { discountPercentage } = subscription || {};
  const totalDspSubscriptionFeeForMonth = calculateTotalDspSubscriptionFee({
    subscription,
    month,
    year,
  });
  const subscriptionAmount = calculateMonthlySubscriptionAmount(item.subscription, month, year);
  const totalOverageForMonth = calculateTotalOverage(item);

  return (
    subscriptionAmount -
    (subscriptionAmount * discountPercentage) / 100 +
    totalOverageForMonth +
    dspOneOffCreditFee +
    totalDspSubscriptionFeeForMonth
  );
};

// There are 5 danger zone conditions, but 4 labels:
// 1. "Renewal":
//    a. Danger zone for when the contract end date is within 3 months. This is a reminder to contact the company for renewal.
//    b. If the company has used more than 75% of their pre-negotiated quota (for both small and large)
// 2. "Additional Fees": If the company has additional fees for that given month
// 3. "Inactive": If the company has a quota for any project type, but did not use any projects of that type in that given month
// 4. "Upgrade": This occurs when the current project count in the given month is 15% or more than the total amount in the subscription.
// 5. "Design Services": This occurs when the company has less than 20% of their design credits remaining.

export const isAccountInDangerZone = (account: AccountingInfo) => {
  if (!account.subscription) {
    return false;
  }

  return (
    isEligibleForRenewal(account) ||
    calculateTotalOverage(account) > 0 ||
    isInactive(account) ||
    isEligibleForUpgrade(account) ||
    isEligibleForDesignServicesUpgrade(account)
  );
};

const isThreeMonthsToContractEndDate = (contractEndDate: string) => {
  if (contractEndDate) {
    // As the billing is done for months that are prior to the current one,
    // we included the contracts that are ending in the prior month to this danger zone category
    // so they are visible on their expiration month in the accounting table
    const oneMonthAgo = moment.utc().subtract(1, 'months').startOf('month').format().slice(0, 10);
    const threeMonthsLater = moment.utc().add(3, 'months').format().slice(0, 10);
    const formattedContractEndDate = contractEndDate.slice(0, 10);
    return (
      moment.utc(formattedContractEndDate).isSameOrAfter(oneMonthAgo) &&
      moment.utc(formattedContractEndDate).isSameOrBefore(threeMonthsLater)
    );
  }
  return false;
};

export const renderDangerZoneCategory = (account: AccountingInfo) => {
  if (!account.subscription) {
    return 'No subscription.';
  }

  let dangerZone = '';

  // Renewal check:
  if (isEligibleForRenewal(account)) {
    dangerZone += `${DANGER_ZONE_CATEGORY.renewal} \n`;
  }

  // Inactive check:
  if (isInactive(account)) {
    dangerZone += `${DANGER_ZONE_CATEGORY.inactive} \n`;
  }

  // Additional fees check:
  if (calculateTotalOverage(account) > 0) {
    dangerZone += `${DANGER_ZONE_CATEGORY.additionalFees} \n`;
  }

  // Upgrade check:
  if (isEligibleForUpgrade(account)) {
    dangerZone += `${DANGER_ZONE_CATEGORY.upgrade} \n`;
  }

  if (dangerZone.length === 0) {
    return 'Account not in danger zone.';
  }

  return dangerZone;
};

export const isInactive = (account: AccountingInfo) => {
  const { smallProjectQuota, largeProjectQuota } = account.subscription || {};
  const { smallProjectsByMonth, largeProjectsByMonth } = account || {};

  return Boolean(
    (largeProjectQuota && largeProjectsByMonth === 0) ||
      (smallProjectQuota && smallProjectsByMonth === 0)
  );
};

export const isEligibleForRenewal = (account: AccountingInfo) => {
  const { smallProjectQuota, largeProjectQuota, contractEndDate } = account.subscription || {};

  const { largeProjectsBySubscriptionStartDate, smallProjectsBySubscriptionStartDate } =
    account || {};

  return Boolean(
    (largeProjectQuota &&
      largeProjectsBySubscriptionStartDate / largeProjectQuota > RENEWAL_CUTOFF_PERCENTAGE) ||
      (smallProjectQuota &&
        smallProjectsBySubscriptionStartDate / smallProjectQuota > RENEWAL_CUTOFF_PERCENTAGE) ||
      isThreeMonthsToContractEndDate(contractEndDate as string)
  );
};

export const isEligibleForUpgrade = (account: AccountingInfo) => {
  const { smallProjectQuota, largeProjectQuota } = account.subscription || {};
  const { smallProjectsByMonth, largeProjectsByMonth } = account || {};

  return Boolean(
    (largeProjectQuota && largeProjectsByMonth > largeProjectQuota * UPGRADE_CUTOFF_PERCENTAGE) ||
      (smallProjectQuota && smallProjectsByMonth > smallProjectQuota * UPGRADE_CUTOFF_PERCENTAGE)
  );
};

export const isEligibleForDesignServicesUpgrade = (account: AccountingInfo) => {
  const { creditsBalance, totalPurchasedCredits } = account || {};

  return creditsBalance / totalPurchasedCredits < DS_CUTOFF_PERCENTAGE;
};

export const isEligibleForRenewalOrUpgrade = (
  account: AccountingInfo,
  projectType: PROJECT_TYPES
) => {
  const {
    subscription,
    smallProjectsByMonth,
    largeProjectsByMonth,
    smallProjectsBySubscriptionStartDate,
    largeProjectsBySubscriptionStartDate,
  } = account || {};

  const { smallProjectQuota, largeProjectQuota } = subscription || {};

  if (!subscription) return false;

  if (projectType === PROJECT_TYPES.SMALL) {
    if (
      smallProjectQuota &&
      (smallProjectsBySubscriptionStartDate / smallProjectQuota > RENEWAL_CUTOFF_PERCENTAGE ||
        smallProjectsByMonth > smallProjectQuota * UPGRADE_CUTOFF_PERCENTAGE)
    ) {
      return true;
    }
  }

  if (projectType === PROJECT_TYPES.LARGE) {
    if (
      largeProjectQuota &&
      (largeProjectsBySubscriptionStartDate / largeProjectQuota > RENEWAL_CUTOFF_PERCENTAGE ||
        largeProjectsByMonth > largeProjectQuota * UPGRADE_CUTOFF_PERCENTAGE)
    ) {
      return true;
    }
  }
  return false;
};

export const selectInvoiceStatusColor = (stripeInvoice?: Invoice) => {
  if (!stripeInvoice) return '';

  const { status } = stripeInvoice;

  if (status === INVOICE_STATUS.draft) return 'Accounting-LightBlue';
  if (status === INVOICE_STATUS.open) return 'Accounting-Orange';
  if (status === INVOICE_STATUS.paid) return 'Accounting-Green';
  if (status === INVOICE_STATUS.failed || status === INVOICE_STATUS.uncollectible)
    return 'Accounting-Red';
  if (status === INVOICE_STATUS.void) return 'Accounting-Purple';
};

export const calculateRemainingProjectCount = (item: AccountingInfo, type: PROJECT_TYPES) => {
  const {
    subscription,
    smallProjectsBySubscriptionStartDate,
    largeProjectsBySubscriptionStartDate,
  } = item || {};
  const { smallProjectQuota, largeProjectQuota } = item?.subscription || {};

  if (type === PROJECT_TYPES.SMALL && subscription && smallProjectQuota) {
    return smallProjectQuota - smallProjectsBySubscriptionStartDate;
  }
  if (type === PROJECT_TYPES.LARGE && subscription && largeProjectQuota) {
    return largeProjectQuota - largeProjectsBySubscriptionStartDate;
  }
  return 0;
};

export const calculateMonthlySubscriptionAmount = (
  subscription: AccountingInfoSubscription,
  month: number,
  year: number
) => {
  const { paymentType, dspPaymentType, contractStartDate, subscriptionAmount } = subscription || {};
  if (!subscription || !paymentType) return 0;

  return calculateChargePerPeriod({
    paymentType,
    dspPaymentType,
    contractStartDate,
    month,
    year,
    feeAmount: subscriptionAmount,
  });
};

export const calculateTotalDspSubscriptionFee = ({
  subscription,
  month,
  year,
}: {
  subscription: AccountingInfoSubscription;
  month: number;
  year: number;
}) => {
  if (!subscription) return 0;

  const {
    paymentType,
    dspSubscriptionFeeSmall,
    dspSubscriptionFeeLarge,
    dspPaymentType,
    contractStartDate,
  } = subscription || {};

  if (!paymentType) return 0;

  return calculateChargePerPeriod({
    paymentType,
    dspPaymentType,
    contractStartDate,
    month,
    year,
    feeAmount: (dspSubscriptionFeeSmall ?? 0) + (dspSubscriptionFeeLarge ?? 0),
    isDspCharge: true,
  });
};

const calculateTotalSubscriptionAmount = (
  accountingInformationArray: AccountingInfo[],
  month: number,
  year: number
) => {
  const values = accountingInformationArray.map((account) =>
    calculateMonthlySubscriptionAmount(account.subscription, month, year)
  );
  return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
};

const calculateAccountingTotal = (
  accountingInformationArray: AccountingInfo[],
  fieldName: AccountingInfoQuantityKey
) => {
  const values = accountingInformationArray.map(
    (account: AccountingInfo) => account[fieldName] ?? 0
  );

  return values.reduce((prevValue: number, currValue: number) => prevValue + currValue, 0);
};

const calculateSubscriptionTotal = (
  accountingInformationArray: AccountingInfo[],
  fieldName: AccountingInfoSubscriptionQuantityKey
) => {
  const values = accountingInformationArray.map((account) =>
    account.subscription ? account.subscription[fieldName] : 0
  );

  return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
};

const calculateTotalDiscount = (
  accountingInformationArray: AccountingInfo[],
  month: number,
  year: number
) => {
  const values = accountingInformationArray.map((account) =>
    account.subscription
      ? (account.subscription.discountPercentage / 100) *
        calculateMonthlySubscriptionAmount(account.subscription, month, year)
      : 0
  );
  return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
};

const calculateTotalMonthlyOverage = (accountingInformationArray: AccountingInfo[]) => {
  const values = accountingInformationArray.map((account) =>
    account?.subscription ? calculateTotalOverage(account) : 0
  );
  return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
};

const calculateMonthlyTotal = (
  accountingInformationList: AccountingInfo[],
  month: number,
  year: number
) => {
  return (
    calculateTotalSubscriptionAmount(accountingInformationList, month, year) -
    calculateTotalDiscount(accountingInformationList, month, year) +
    calculateTotalMonthlyOverage(accountingInformationList) +
    reduce(accountingInformationList, 'dspAdditionalFee') +
    reduce(
      accountingInformationList.map((item) => item.subscription),
      'dspSubscriptionFeeSmall',
      'dspSubscriptionFeeLarge'
    )
  );
};

export const calculateTotalRemainingProjectCount = (
  accountingInformationList: AccountingInfo[],
  type: PROJECT_TYPES
) => {
  if (type === PROJECT_TYPES.SMALL) {
    const values = accountingInformationList.map((account) =>
      account.subscription
        ? Math.max(
            account.subscription.smallProjectQuota - account.smallProjectsBySubscriptionStartDate,
            0
          )
        : 0
    );
    return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
  }
  if (type === PROJECT_TYPES.LARGE) {
    const values = accountingInformationList.map((account) =>
      account.subscription
        ? Math.max(
            account.subscription.largeProjectQuota - account.largeProjectsBySubscriptionStartDate,
            0
          )
        : 0
    );
    return values.reduce((prevValue, currValue) => prevValue + currValue, 0);
  }
  return 0;
};

export const renderStyledTableCell = (
  item: AccountingInfo,
  fieldName: keyof AccountingInfo,
  subscriptionField: keyof AccountingInfoSubscription
) => {
  const field = item[fieldName];
  return {
    props: {
      style: {
        background:
          field === 0 && item.subscription && (item.subscription[subscriptionField] as number) > 0
            ? colors.yellowHighlight
            : '',
        fontWeight:
          field === 0 && item.subscription && (item.subscription[subscriptionField] as number) > 0
            ? fontWeights.semiBold
            : fontWeights.normal,
      },
    },
    children: numberFormatter(field ?? 0),
  };
};

export const TableSummary = ({
  accountingInformationList,
  month,
  year,
  isScaniflyBillingAdmin = false,
}: {
  accountingInformationList: AccountingInfo[];
  month: number;
  year: number;
  isScaniflyBillingAdmin: boolean;
}) => {
  return (
    // @ts-ignore
    <Table.Summary.Row index={1}>
      {/* @ts-ignore */}
      <Table.Summary.Cell index={2} colSpan={5}>
        Totals
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalSubscriptionFee">
        {formatUSD(calculateTotalSubscriptionAmount(accountingInformationList, month, year))}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalDiscount">
        {formatUSD(calculateTotalDiscount(accountingInformationList, month, year))}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalDSPSubscriptionFee">
        {formatUSD(
          accountingInformationList
            .map((item: AccountingInfo) =>
              calculateTotalDspSubscriptionFee({
                subscription: item.subscription,
                month,
                year,
              })
            )
            .reduce((prevValue, currValue) => prevValue + currValue, 0)
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="monthlySubscriptionOverage">
        {formatUSD(calculateTotalMonthlyOverage(accountingInformationList))}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="monthlyDSPOverage">
        {formatUSD(reduce(accountingInformationList, 'dspAdditionalFee'))}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="monthlyTotal">
        {formatUSD(calculateMonthlyTotal(accountingInformationList, month, year))}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} colSpan={isScaniflyBillingAdmin ? 6 : 4}></Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalSmallProjectQuota">
        {numberFormatter(
          calculateSubscriptionTotal(accountingInformationList, 'smallProjectQuota')
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalLargeProjectQuota">
        {numberFormatter(
          calculateSubscriptionTotal(accountingInformationList, 'largeProjectQuota')
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalSmallProjectsByMonth">
        {numberFormatter(
          calculateAccountingTotal(accountingInformationList, 'smallProjectsByMonth')
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalLargeProjectsByMonth">
        {numberFormatter(
          calculateAccountingTotal(accountingInformationList, 'largeProjectsByMonth')
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalSmallProjectsBySubscriptionStart">
        {numberFormatter(
          calculateAccountingTotal(
            accountingInformationList,
            'smallProjectsBySubscriptionStartDate'
          )
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalLargeProjectsBySubscriptionStart">
        {numberFormatter(
          calculateAccountingTotal(
            accountingInformationList,
            'largeProjectsBySubscriptionStartDate'
          )
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalSmallRemainingProjectCount">
        {numberFormatter(
          calculateTotalRemainingProjectCount(accountingInformationList, PROJECT_TYPES.SMALL)
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalLargeRemainingProjectCount">
        {numberFormatter(
          calculateTotalRemainingProjectCount(accountingInformationList, PROJECT_TYPES.LARGE)
        )}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalRemainingDesignCredits">
        {numberFormatter(reduce(accountingInformationList, 'creditsBalance'))}
      </Table.Summary.Cell>
      {/* @ts-ignore */}
      <Table.Summary.Cell id="totalPurchasedCreditCount">
        {numberFormatter(reduce(accountingInformationList, 'totalPurchasedCredits'))}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
