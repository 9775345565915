import { useSelector } from 'react-redux';

import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomYAxisTick } from 'components';

import colors from 'helpers/constants/colors';

import { MONTHS, PLACEHOLDER_DATA } from './constants';
import './InfoViewChart.scss';

const InfoViewProdConsumChart = ({ values }) => {
  const { project } = useSelector((state) => state.project);
  const hasUsageData = project.kwh.some((usage) => usage > 0);

  const chartData = Object.keys(values.length ? values : PLACEHOLDER_DATA).map((i) => ({
    name: upperFirst(MONTHS[i]),
    Production: Object.values(values)[i],
    Consumption: hasUsageData
      ? Object.values(project.kwh)[i]
      : Object.values(project.monthlyUsage)[i] || 0,
  }));

  const renderInstruction = () => {
    const noUsageData = !hasUsageData && !project.monthlyUsage.length;
    if (!values.length && noUsageData) {
      return 'Please add modules in Scanifly3D and usage in Customer Info';
    }
    if (noUsageData) {
      return 'Please add usage in Customer Info';
    }
    if (!values.length) {
      return 'Please add modules in Scanifly3D';
    }
  };

  return (
    <div className="InfoViewChart">
      <div className="InfoViewChart-Legend">
        <div className="InfoViewChart-Legend-Bar" />
        Production
        <div className="InfoViewChart-Legend-Bar InfoViewChart-Legend-Bar-Consum" />
        Consumption
      </div>
      {(!values.length || !project.kwh) && (
        <p className="InfoViewChart-Instruction">{renderInstruction()}</p>
      )}
      <ResponsiveContainer width="99%" minWidth={280} height={256}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickFormatter={(name) => name.charAt(0)}
            tick={{ fill: colors.labelGray }}
            tickLine={false}
            tickMargin={16}
          />
          <YAxis
            axisLine={false}
            tick={<CustomYAxisTick />}
            tickLine={false}
            interval="preserveEnd"
          />
          <Tooltip
            cursor={values.length && project.kwh}
            wrapperStyle={(!values.length || !project.kwh) && { display: 'none' }}
          />
          {values && (
            <Bar
              dataKey="Production"
              fill={
                hasUsageData || project.monthlyUsage.length
                  ? colors.mainBlue
                  : colors.labelGrayFortyFivePercent
              }
              radius={[6, 6, 6, 6]}
              barSize={12}
              unit=" kWh"
            />
          )}
          {project.kwh && (
            <Bar
              dataKey="Consumption"
              fill={values.length ? colors.teal : colors.labelGrayFortyFivePercent}
              radius={[6, 6, 6, 6]}
              barSize={12}
              unit=" kWh"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

InfoViewProdConsumChart.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default InfoViewProdConsumChart;
