import { useTranslation } from 'react-i18next';

import { Row } from 'antd';
import { FieldInputProps } from 'formik';

import { CustomSelect } from 'components';

import { PAYMENT_TYPE_FILTERS } from '../constants';

type Option = {
  text: string;
  value: string;
};

const PaymentTypeSelect = ({
  setFieldValue,
  getFieldProps,
  field,
  formControl,
}: {
  setFieldValue: (field: string, value: string) => void;
  getFieldProps: (field: string) => FieldInputProps<any>;
  field: string;
  formControl: string;
}) => {
  const { t } = useTranslation();

  return (
    <Row className="Accounting-Form-CustomSelectRow">
      <div className="FormControl-Wrapper">
        <label htmlFor={field}>{t('AccountingForms.paymentType')}</label>
        <CustomSelect
          {...getFieldProps(formControl)}
          onChange={(option: Option) => {
            setFieldValue(formControl, option.value);
          }}
          placeholder={t('AccountingForms.selectPaymentType')}
          options={PAYMENT_TYPE_FILTERS.map((type) => ({ value: type.value, label: type.text }))}
          variant="filter"
          id={field}
          tall
        />
      </div>
    </Row>
  );
};

export { PaymentTypeSelect };
