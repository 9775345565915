import { useTranslation } from 'react-i18next';

import { integrationAuth } from 'api/integrations/integrationService';

import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel-red-filled.svg';
import { ReactComponent as FilledCheckmarkIcon } from 'assets/icons/checkmark-filled-green.svg';

import { ConnectionStatusProps } from '../types';
import IntegrationsDisconnectConfirmation from './IntegrationDisconnectConfirmation';

const ConnectionStatus = ({ isConnected, setIsConnected, integration }: ConnectionStatusProps) => {
  const { t } = useTranslation();
  const [isDisconnectConfirmationVisible, toggleDisconnectConfirmation] = useToggle(false);

  const handleDisconnectConfirmationClose = () => toggleDisconnectConfirmation();

  const handleConnection = async (prompt = false) => {
    const authData = await integrationAuth(integration.api, prompt);
    window.location.href = authData.data;
    setIsConnected(isConnected);
  };

  return (
    <>
      <IntegrationsDisconnectConfirmation
        isDisconnectConfirmationVisible={isDisconnectConfirmationVisible}
        handleDisconnectConfirmationClose={handleDisconnectConfirmationClose}
        toggleDisconnectConfirmation={toggleDisconnectConfirmation}
        setIsConnected={setIsConnected}
        isConnected={isConnected}
        integration={integration}
      />
      <div className="connection-status">
        {isConnected ? (
          <>
            <span className="connected" data-testid="integration-connected">
              <FilledCheckmarkIcon />
              {t('Integrations.connected')}
            </span>
            <button onClick={toggleDisconnectConfirmation} data-testid="integration-disconnect">
              {t('Integrations.disconnect')}
            </button>
          </>
        ) : (
          <>
            <span className="disconnected" data-testid="integration-disconnected">
              <CancelIcon />
              {t('Integrations.notConnected')}
            </span>
            <button onClick={() => handleConnection(true)} data-testid="integration-reconnect">
              {t('Integrations.reconnect')}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ConnectionStatus;
