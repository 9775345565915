type UsageMap = {
  january: any;
  february: any;
  march: any;
  april: any;
  may: any;
  june: any;
  july: any;
  august: any;
  september: any;
  october: any;
  november: any;
  december: any;
};

type FormValues = {
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  projectName: string;
  usage: UsageMap;
};

const InitialValues = {
  address: '',
  email: '',
  firstName: '',
  lastName: '',
  latitude: 0,
  longitude: 0,
  phoneNumber: '',
  projectName: '',
  usage: {
    january: '',
    february: '',
    march: '',
    april: '',
    may: '',
    june: '',
    july: '',
    august: '',
    september: '',
    october: '',
    november: '',
    december: '',
  },
};

enum FormFields {
  address = 'address',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  latitude = 'latitude',
  longitude = 'longitude',
  phoneNumber = 'phoneNumber',
  projectName = 'projectName',
  usage = 'usage',
}

export { FormFields, InitialValues };
export type { FormValues, UsageMap };
