import PropTypes from 'prop-types';

import colors from 'helpers/constants/colors';
import { abbreviateNumber } from 'helpers/utils/abbreviateNumber';
import { numberFormatter } from 'helpers/utils/numberFormatter';

const FORMAT_THRESHOLD = 10000000;

const CustomYAxisTick = ({ y = 0, payload: { value } }) => (
  <g transform={`translate(${0},${y})`}>
    <text x={0} y={0} textAnchor="start" fill={colors.labelGray}>
      {value <= FORMAT_THRESHOLD ? numberFormatter(value) : abbreviateNumber(value)}
    </text>
  </g>
);

CustomYAxisTick.propTypes = {
  y: PropTypes.number,
  payload: PropTypes.shape({
    coordinate: PropTypes.number.isRequired,
    isShow: PropTypes.bool.isRequired,
    offset: PropTypes.number.isRequired,
    tickCoord: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

export default CustomYAxisTick;
