import { CopyOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';

import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';

import { DATE_OPTIONS } from '../CustomerSupportUpload/constants';
import './AccessTokens.scss';
import { handleCopyClick } from './helpers';

export const tableColumnData = () => {
  return [
    {
      title: 'Company',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Access Token Count',
      dataIndex: 'accessTokens',
      render: (accessTokens) => accessTokens.length,
      sorter: (a, b) => a.accessTokens.length - b.accessTokens.length,
    },
  ];
};

export const expandedRowRenderContent = ({ data, handleDelete }) => {
  const columns = [
    {
      title: '#',
      key: 'index',
      width: '8rem',
      render: (_record, _item, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Token prefix',
      dataIndex: 'tokenPrefix',
      key: 'tokenPrefix',
      width: '15rem',
      render: (tokenPrefix) => {
        return (
          <div className="ScaniflyAdmin-TokenWrapper">
            {tokenPrefix}
            <CopyOutlined
              role="button"
              className="ScaniflyAdmin-ButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(tokenPrefix)}
              aria-label="copy token prefix"
            />
          </div>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (createdAt) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '9rem',
      key: 'actions',
      render: (_, record) => (
        <div className="ScaniflyAdmin-NestedTable--ActionsContainer">
          <Tooltip placement="top" title="Delete access token">
            <Button
              size="small"
              onClick={() =>
                handleDelete({
                  companyId: data.id,
                  companyName: data.name,
                  id: record.id,
                })
              }
              className="Button--Red"
              id="TokenDeleteBtn"
              aria-label="delete access token"
            >
              <TrashIcon />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={data.accessTokens}
      pagination={false}
      className="ScaniflyAdmin-NestedTable"
    />
  );
};
