import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MODE, PROCESSING_ESTIMATES } from '@cpm/scanifly-shared-data';

import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as InProgressIcon } from 'assets/icons/in-progress-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const ProjectProcessing = ({
  handleModalOpen,
  mode,
  projectStatus,
}: {
  handleModalOpen: () => void;
  mode?: string;
  projectStatus: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const processingTimeEstimate =
    mode === MODE.ludicrous
      ? `~${PROCESSING_ESTIMATES.ludicrous} minutes`
      : `~${PROCESSING_ESTIMATES.normal} hours`;

  const isProjectProcessing = [
    ADMIN_PROJECT_STATUSES.PROCESSING,
    NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING,
  ].includes(projectStatus);

  return (
    <div className="DesignStatus-Wrapper">
      <InProgressIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignStatusDisplay.processing')}</div>
      <p className="DesignStatus-Timeframe">
        {t('DesignStatusDisplay.timeframe')}{' '}
        <span className="DesignStatus-Value">{processingTimeEstimate}</span>
      </p>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
        hideCancelButton={!isProjectProcessing}
      />
    </div>
  );
};

export default ProjectProcessing;
