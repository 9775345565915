import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import VerticalDivider from 'components/VerticalDivider/VerticalDivider';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { TFunction } from 'i18next';
import { Fragment } from 'react';
import { DesignOption, SERVICE_REQUEST_OPTIONS } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { StyledFlexSection, StyledTitleContainer } from '../components';

const StyledCreditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
`;

const StyledCreditContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
`;

const CreditCount = styled.p`
  font-weight: ${fontWeights.extraBold};
  font-size: ${fontSizes.mediumLarge};
  color: ${colors.mainBlue};

  @media (min-width: ${breakpoints.lg}) {
    font-size: $fontSizeLarge;
    color: $main-blue;
  }
`;

export const getCorrectCredit = ({
  type,
  option,
  t,
}: {
  type?: string | undefined;
  option: DesignOption | undefined;
  t: TFunction<'translation', undefined>;
}): string | number => {
  if (type === t('DesignService.commercial')) {
    if (option?.creditsForCommercial === option?.credits) {
      return t('DesignService.custom');
    }
    return option?.creditsForCommercial ?? 0;
  }

  if (option?.tierLevel === TIER_LEVEL.planSetForLarge) {
    return t('DesignService.custom');
  }

  return option?.credits ?? 0;
};

export const CreditDisplay = ({
  isPrelim,
  option,
  t,
}: {
  isPrelim: boolean;
  option: DesignOption | undefined;
  t: TFunction<'translation', undefined>;
}) => {
  return (
    <StyledFlexSection>
      {isPrelim ? (
        <StyledCreditWrapper data-testid="credit-wrapper">
          {SERVICE_REQUEST_OPTIONS.map((type) => (
            <Fragment key={type}>
              <StyledCreditContainer>
                <StyledTitleContainer>{type}</StyledTitleContainer>
                <CreditCount>{getCorrectCredit({ type, option, t })}</CreditCount>
                <p>{t('DesignService.credits')}</p>
              </StyledCreditContainer>
              {type === t('DesignService.residential') ? <VerticalDivider /> : null}
            </Fragment>
          ))}
        </StyledCreditWrapper>
      ) : (
        <>
          <CreditCount>{getCorrectCredit({ option, t })}</CreditCount>
          <p>{t('DesignService.credits')}</p>
        </>
      )}
    </StyledFlexSection>
  );
};
