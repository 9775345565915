import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as CloseIcon } from 'assets/icons/x-gray-icon.svg';
import { ReactComponent as MentionIcon } from 'assets/mention.svg';

import MentionText from './MentionText';

const Main = styled.div`
  display: flex;
  justify-content: right;
  padding: 5px;
  margin-right: -10px;
  &:first-child {
    margin-left: -5rem;
  }
  &:hover {
    background-color: #eaf0f6;
    border-radius: 8px;
  }
  cursor: pointer;
`;
type RowProps = {
  large?: boolean;
};

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: ${(props) => (props.large ? '-10px' : '0')};
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  margin-right: 10px;
  width: 21px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 350px;
  > * {
    margin-bottom: 0px;
  }
`;

const StyledMentionIcon = styled(MentionIcon)`
  width: 30px;
  height: 15px;
  fill: #6a6f83;
  ${Main}:hover & {
    fill: ${colors.mainBlue};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dfdfdf;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #2e3134;
`;

const ProjectName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #2e3134;
`;

const Time = styled.p`
  color: #c3c7d1;
  font-size: 11px;
  margin-top: -24px;
  margin-bottom: 10px;
`;

type UnreadIconProps = {
  hasRead?: boolean;
};
const UnreadIcon = styled.div<UnreadIconProps>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: ${colors.mainBlue};
  opacity: ${(props) => (props.hasRead ? '0' : '100%')};
  margin: auto;
`;

const HiddenBorder = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  margin-right: 1px;
  cursor: pointer;
  ${Main}:hover & {
    border: 1px solid #cfcfcf;
  }
`;

const StyledMentionText = styled(MentionText)`
  margin-right: 1px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-top: 10px;
  align-self: center;
  margin-right: 20px;
  display: none;
  cursor: pointer;

  ${Main}:hover & {
    display: flex;
  }
`;

const PressableArea = styled.div`
  padding-left: 20px;
  display: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export {
  ButtonColumn,
  Column,
  HiddenBorder,
  IconWrapper,
  InfoText,
  Main,
  PressableArea,
  ProjectName,
  Row,
  StyledCloseIcon,
  StyledMentionIcon,
  StyledMentionText,
  Time,
  UnreadIcon,
};
