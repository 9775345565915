import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';

import { companyCategoryUpdated } from 'state/slices/companyCategoriesSlice';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { updateProjectCategory } from 'state/slices/projectMediaCategories';
import { AppDispatch } from 'state/store/store';
import { MediaCategory } from 'types';
import { validationSchema } from '../validationSchema';
import { FORM_CONTROLS, initialValues, MODAL_TYPES } from './constants';
import './RenameCategoryModal.scss';

const RenameCategoryModal = ({
  categories,
  id,
  type,
  isModalVisible,
  handleModalClose,
  selectedCategory,
}: {
  categories: MediaCategory[];
  id: string;
  type: string;
  isModalVisible: boolean;
  handleModalClose: () => void;
  selectedCategory: MediaCategory;
}) => {
  const dispatch: AppDispatch = useDispatch();

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, setFieldValue } = useFormik(
    {
      initialValues,
      validationSchema: validationSchema(categories, selectedCategory?.id),
      onSubmit: (values) => {
        dispatch(
          type === MODAL_TYPES.COMPANY
            ? companyCategoryUpdated({
                companyId: id,
                categoryName: selectedCategory.categoryName,
                newCategoryName: values.categoryName,
              })
            : updateProjectCategory({
                projectId: id,
                categoryName: selectedCategory.categoryName,
                newCategoryName: values.categoryName,
              })
        );
        handleModalClose();
      },
    }
  );

  useEffect(() => {
    setFieldValue(FORM_CONTROLS.CATEGORY_NAME, selectedCategory.categoryName);
  }, [setFieldValue, selectedCategory, isModalVisible]);

  const handleModalVisibility = useCallback(() => {
    handleModalClose();
  }, [handleModalClose]);

  const isFormValid = isValid && dirty;

  return (
    // @ts-ignore antd is not compatible with @types/react v18
    <Modal
      visible={isModalVisible}
      onCancel={handleModalVisibility}
      className="RenameCategoryModal"
      {...MODAL_PROPS}
    >
      <span className="RenameCategoryModal-Title">
        Rename Album: {selectedCategory.categoryName}
      </span>
      <form onSubmit={handleSubmit}>
        <div className="FormControl-Wrapper">
          <Input
            defaultValue={selectedCategory.categoryName}
            className={validateStatus(touched, errors, FORM_CONTROLS.CATEGORY_NAME, dirty)}
            type={FORM_CONTROLS.CATEGORY_NAME}
            aria-label="new album name"
            {...getFieldProps(FORM_CONTROLS.CATEGORY_NAME)}
            autoFocus
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CATEGORY_NAME, dirty)}
          </div>
        </div>
        <div className="RenameCategoryModal-ButtonWrapper">
          <Button onClick={handleModalClose} className="Button--White">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            htmlType="submit"
            className="Button--Blue"
          >
            Rename Album
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RenameCategoryModal;
