import { Modal } from 'antd';

import { MODAL_PROPS } from 'helpers/constants/modals';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import { IntegrationsSettingsModalProps } from '../types';
import DirectorySelect from './DirectorySelect';
import './IntegrationSettingsModal.scss';

const IntegrationsSettingsModal = ({
  isSettingsModalVisible,
  handleModalClose,
  toggleSettingsModal,
  headerTitle,
  setDestinationPath,
  integration,
}: IntegrationsSettingsModalProps) => {
  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isSettingsModalVisible}
      onCancel={handleModalClose}
      title={headerTitle}
      {...MODAL_PROPS}
      className="IntegrationsSettingsModal"
    >
      <XIcon
        onClick={toggleSettingsModal}
        className="close-modal-icon"
        data-testid="close-modal-icon"
      />
      <DirectorySelect
        toggleSettingsModal={toggleSettingsModal}
        setDestinationPath={setDestinationPath}
        integration={integration}
      />
    </Modal>
  );
};

export default IntegrationsSettingsModal;
