import { useSelector } from 'react-redux';

import { Alert } from 'antd';
import PropTypes from 'prop-types';

import './SignUpError.scss';

const SignUpError = ({ errorMessage }) => {
  const { companyWithUserExistsError, companyExistsError } = useSelector((state) => state.company);

  const message = () => {
    if (companyWithUserExistsError) {
      return 'A company created with this email address already exists. Please contact Scanifly Admin for more information.';
    } else if (companyExistsError) {
      return companyExistsError;
    }
    return `${errorMessage}: Something went wrong, we couldn't sign you up. Please contact Scanifly Admin for more information.`;
  };

  return <Alert className="SignUpError" message={message()} type="error" />;
};

SignUpError.propTypes = {
  errorMessage: PropTypes.string,
};

export default SignUpError;
