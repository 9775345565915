export const FORM_CONTROLS = {
  EMAIL: 'email',
  COMPANY_ID: 'companyId',
};

export const OPTIONS = {
  ADMIN_TOOLS: { label: 'Admin tools', value: 'adminTools' },
  ALL_PROJECTS: { label: 'All Projects', value: 'allProjects' },
};

export const PERMISSION_TOOLTIP_TEXTS = {
  ADMIN_TOOLS: 'Access to all permissions, add/remove teammates, Company Info in Settings',
  BILLING: 'Access payment info',
  UPLOAD: 'Access to New Project Upload',
  WATT3D: `Access Scanifly's 3D modeling software`,
};
