import React, { useState } from 'react';

import styled from 'styled-components';

import ErrorDisplay from 'components/ErrorDisplay';

import colors from 'helpers/constants/colors';
import hexToRgbA from 'helpers/utils/hexToRgbA';
import { ImportModalProps } from '../types';

type ModalProps = ImportModalProps & {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

// It is usually somewhat risky to be so aggressive with z-index but in this case we
// know nothing should be above the overlay until it is dismissed.
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = styled.div`
  background: ${colors.white};
  padding: 3rem;
  width: 20%;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  min-width: 50rem;
`;

const Title = styled.h1`
  font-size: 1.4em;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  white-space: pre-wrap;
  font-size: 1em;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};

  ::placeholder {
    color: ${colors.midGray};
  }

  :focus {
    outline: none;
    border-color: ${colors.mainBlue};
    box-shadow: 0 0 0 2px ${hexToRgbA(colors.lightBlue, 0.5)};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 3rem;
`;

const CommonButton = styled.button`
  margin-left: 2rem;
  border-radius: 0.8rem;
  border: 1px solid ${colors.lightGray};
  padding: 0.5rem 2rem;
  cursor: pointer;
`;

const CancelButton = styled(CommonButton)`
  background: ${colors.white};
`;

const ActionButton = styled(CommonButton)<{ backgroundColor: string }>`
  background: ${(props) => props.backgroundColor};
  color: ${colors.white};

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.gray};
    cursor: not-allowed;
  }
`;

const ImportModal: React.FC<ModalProps> = ({
  title,
  description,
  actionButtonLabel,
  actionButtonOnClick,
  onCancel,
  isVisible,
  setIsVisible,
}: ModalProps) => {
  const [fileContent, setFileContent] = useState<any>();
  const [error, setError] = useState<any | undefined>();

  const handleClose = () => {
    setError(undefined);
    setIsVisible(false);
    setFileContent('');
    onCancel && onCancel();
  };
  const handleButtonClick = async () => {
    try {
      await actionButtonOnClick(fileContent);
      setFileContent('');
      handleClose();
    } catch (e: any) {
      setError(e);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonResult = JSON.parse(e.target?.result as string);
          setFileContent(jsonResult);
        } catch (error) {
          console.error('Invalid JSON file');
        }
      };
      reader.readAsText(file);
    }
  };

  return isVisible ? (
    <Overlay>
      <Modal>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Input type="file" accept=".json" onChange={handleFileChange} />
        {error && <ErrorDisplay error={error} />}
        <ButtonGroup>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ActionButton backgroundColor={colors.mainBlue} onClick={handleButtonClick}>
            {actionButtonLabel}
          </ActionButton>
        </ButtonGroup>
      </Modal>
    </Overlay>
  ) : null;
};

export default ImportModal;
