import moment from 'moment';
import Project from 'types/Project';

import { csvDateFormat } from 'helpers/utils/dateFormat';
import { isProjectCompleted } from 'helpers/utils/isProjectCompleted';
import { renderProjectType } from 'helpers/utils/renderProjectType';

export const handleUploadQueueCSVExport = ({ dataSource }: { dataSource: Project[] }) => {
  return dataSource
    .sort((a: Project, b: Project) => (b.createdAt > a.createdAt ? 1 : -1))
    .map((item: Project) => ({
      ...item,
      submittedFor: item.submittedFor ? item.submittedFor?.company?.name : '-',
      ownerDetails: item.ownerDetails?.firstName
        ? `${item.ownerDetails?.firstName} ${item.ownerDetails?.lastName}`
        : item.submittedFor
          ? `${item.submittedFor?.firstName} ${item.submittedFor?.lastName}`
          : '-',
      type: item.type ? renderProjectType(item.type) : '-',
      engine: item.engine ? (item.engine === 'watt3d' ? 'Watt3d' : 'RC') : '-',
      uploadComplete: item.uploadComplete ? moment(item.uploadComplete).format(csvDateFormat) : '-',
      completedDate: item.completedDate ? moment(item.completedDate).format(csvDateFormat) : '-',
      id: isProjectCompleted(item.statusDescription)
        ? `${window.location.origin}/projects/${item.id}/designs`
        : '-',
    }));
};
