import * as Yup from 'yup';

import { noSpecialCharacters, noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = (categories = []) =>
  Yup.object().shape({
    [FORM_CONTROLS.CATEGORY_NAME]: Yup.string()
      .required('Album name is required')
      .test(...noWhitespaces)
      .test(...noSpecialCharacters)
      .test('uniqueNames', 'Album names must be unique', (value) => {
        const lowerCaseValue = value?.toLocaleLowerCase();
        return !categories.filter(
          ({ categoryName }) => categoryName.toLocaleLowerCase() === lowerCaseValue
        ).length;
      }),
  });
