import { Option } from 'screens/ChecklistTemplate/Editor/common';
import styled from 'styled-components';
import MediaCategoryItem from './MediaCategoryItem';

type SelectedCategoriesProps = {
  options: Option[];
};

const SelectedCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  margin-top: 1rem;

  > :nth-child(odd) {
    margin-right: 0.6rem;

    @media only screen and (max-width: 700px) {
      margin-right: 0;
    }
  }
`;

function SelectedCategories({ options }: SelectedCategoriesProps) {
  return (
    <SelectedCategoryContainer>
      {options.map((option) => (
        <MediaCategoryItem categoryName={option.display} key={option.id} />
      ))}
    </SelectedCategoryContainer>
  );
}

export default SelectedCategories;
