import { Tooltip } from 'components';
import colors from 'helpers/constants/colors';
import styled from 'styled-components';
import { IconButtonProps } from './types';

const StyledButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    > svg {
      fill: ${colors.black};
    }
  }
`;

function IconButton({ disabled, icon, onClick, tooltip }: IconButtonProps) {
  return (
    <Tooltip title={tooltip}>
      <StyledButton disabled={disabled} onClick={onClick}>
        {icon}
      </StyledButton>
    </Tooltip>
  );
}

export default IconButton;
