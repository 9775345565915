import { i18n } from 'helpers/utils/translations';

export const CREDIT_HISTORY_CSV_COLUMN_TITLES = [
  {
    title: i18n.t('CSVExportTitles.transactionId'),
    dataIndex: 'id',
  },
  {
    title: i18n.t('CSVExportTitles.category'),
    dataIndex: 'category',
  },
  {
    title: i18n.t('CSVExportTitles.credits'),
    dataIndex: 'credits',
  },
  {
    title: i18n.t('CSVExportTitles.projectName'),
    dataIndex: 'projectName',
  },
  {
    title: i18n.t('CSVExportTitles.projectType'),
    dataIndex: 'projectType',
  },
  {
    title: i18n.t('CSVExportTitles.serviceRequestId'),
    dataIndex: 'serviceRequestId',
  },
  {
    title: i18n.t('CSVExportTitles.tierLevel'),
    dataIndex: 'tierLevel',
  },
  {
    title: i18n.t('CSVExportTitles.expedited'),
    dataIndex: 'isExpedited',
  },
  {
    title: i18n.t('CSVExportTitles.newBuildPlans'),
    dataIndex: 'newBuildPlansRequired',
  },
  {
    title: i18n.t('CSVExportTitles.createdAt'),
    dataIndex: 'createdAt',
  },
  {
    title: i18n.t('CSVExportTitles.userEmail'),
    dataIndex: 'userEmail',
  },
  {
    title: i18n.t('CSVExportTitles.nearmapArea'),
    dataIndex: 'nearmapArea',
  },
];

export const CREDIT_HISTORY_CSV_COLUMN_TITLES_FOR_ALL_COMPANIES = [
  ...CREDIT_HISTORY_CSV_COLUMN_TITLES.slice(0, 1),
  {
    title: i18n.t('CSVExportTitles.company'),
    dataIndex: 'companyName',
  },
  {
    title: i18n.t('CSVExportTitles.stripeId'),
    dataIndex: 'companyStripeId',
  },
  ...CREDIT_HISTORY_CSV_COLUMN_TITLES.slice(1),
];

export const STATISTIC_TITLES = [{ title: 'Data', dataIndex: 'name' }];
