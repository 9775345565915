import { TIER_LEVEL_DESCRIPTIONS } from '@cpm/scanifly-shared-data';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-icon.svg';
import breakpoints from 'helpers/constants/breakpoints';
import fontSizes from 'helpers/constants/fontSizes';
import { TFunction } from 'i18next';
import { DesignOption, SERVICE_REQUEST_OPTIONS } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { StyledTitleContainer } from '../components';

const StyledClockIcon = styled(ClockIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const StyledEstimate = styled.div`
  font-size: ${fontSizes.extraSmall};
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  @media (min-width: ${breakpoints.lg}) {
    span {
      font-size: $fontSizeExtraExtraSmall;
    }
  }
`;

export const TurnaroundTimeDisplay = ({
  isPrelim,
  isExpedited,
  option,
  t,
}: {
  isPrelim: boolean;
  isExpedited: boolean;
  option: DesignOption | undefined;
  t: TFunction<'translation', undefined>;
}) => {
  if (isPrelim) {
    return SERVICE_REQUEST_OPTIONS.map((type) => (
      <StyledEstimate key={type}>
        <StyledClockIcon />
        <StyledTitleContainer>{type}:</StyledTitleContainer>
        <span>
          {type === t('DesignService.commercial')
            ? option?.timeEstimateForCommercial
            : option?.timeEstimate}
          {option?.serviceType === TIER_LEVEL_DESCRIPTIONS.planSet &&
          type === t('DesignService.commercial')
            ? '+'
            : ''}{' '}
          {isExpedited && type === t('DesignService.residential')
            ? t('DesignService.minute')
            : t('DesignService.hour')}{' '}
        </span>
        {t('DesignService.turnaround')}
      </StyledEstimate>
    ));
  } else {
    return (
      <StyledEstimate>
        <StyledClockIcon />
        <span>
          {option?.timeEstimate} {isExpedited ? t('DesignService.minute') : t('DesignService.hour')}{' '}
        </span>
        {t('DesignService.turnaround')}
      </StyledEstimate>
    );
  }
};
