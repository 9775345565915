import moment from 'moment';
import 'moment/min/locales.min';

export const fullMonthDateFormat = 'LL'; // MMMM DD, YYYY
export const filterDateFormat = 'L'; // MM/DD/YYYY
export const timeStampFormat = 'LT'; // h:mm A

export const csvDateFormat = 'MM-DD-YYYY';
export const albumDateFormat = 'MM-DD-YY';
export const monthDateFormat = 'MMMM YYYY';
export const shortDateFormat = 'M/D/YYYY';

export const dateTimeFormat = 'LL LT';

const locale = navigator.language.slice(0, 2);

export const formatDate = (date) => {
  return moment(date).locale(locale).local().format(fullMonthDateFormat);
};

export const formatTimeStamp = (date) => {
  return moment(date).locale(locale).local().format(timeStampFormat);
};

export const formatDateTime = (date) => {
  return moment(date).locale(locale).local().format(dateTimeFormat);
};

// TODO: Add helper functions for all admin console date formatting
