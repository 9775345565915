import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import styled from 'styled-components';

const StyledAnchor = styled.a`
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  background: ${colors.neutralBlue};
  color: ${colors.mainBlue};
  cursor: pointer;
  font-size: ${fontSizes.small};
  white-space: nowrap;
`;

interface ButtonLinkProps {
  href: string;
  text: string;
}

export const LearnMore = ({ href, text }: ButtonLinkProps) => {
  return (
    <StyledAnchor
      href={href}
      target={'_blank'}
      rel={'noopener noreferrer'}
      role="button"
      aria-label={text}
    >
      {text}
    </StyledAnchor>
  );
};
