import { PRICING_TIER } from '@cpm/scanifly-shared-data';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Company, Invoice } from 'types';

import {
  adminFetchCompanies,
  adminFetchCompaniesNotPopulated,
  adminFetchCompaniesWithAccountInfo,
  adminFetchCompaniesWithPopulatedMembers,
  adminFetchCompanyNameAndIds,
  adminUpdateCompanyMemberStatuses,
  adminUpdateCompanyPriceTier,
} from 'api/admin/adminService';

const name = 'adminCompanies';

type CompanyWithAccountingInfo = Company & { companyId: string; stripeInvoice: Invoice };

type CompanyState = {
  isLoading: boolean;
  error: null | string | undefined;
  isSuccess: boolean;
  isPricingTierUpdated: boolean;
  companies: Company[];
  companiesWithMembersPopulated: Company[];
  companiesNotPopulated: Company[];
  companyNameAndIdList: { id: string; name: string }[];
  companiesWithAccountingInfo: CompanyWithAccountingInfo[];
};

export const adminCompaniesRequested = createAsyncThunk(
  `${name}/companiesRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompanies();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyMemberStatusUpdateRequested = createAsyncThunk(
  `${name}/memberStatusUpdateRequested`,
  async (
    {
      companyId,
      targetStatus,
      onSuccess,
    }: { companyId: string; targetStatus: string; onSuccess: (targetStatus: string) => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminUpdateCompanyMemberStatuses(companyId, targetStatus);
      onSuccess(targetStatus);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompaniesWithMembersPopulatedRequested = createAsyncThunk(
  `${name}/companiesWithMembersPopulatedRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompaniesWithPopulatedMembers();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompaniesNotPopulatedRequested = createAsyncThunk(
  `${name}/adminCompaniesNotPopulatedRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompaniesNotPopulated();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyNameAndIdRequested = createAsyncThunk(
  `${name}/adminCompanyNameAndIdRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompanyNameAndIds();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompaniesWithAccountingInfoRequested = createAsyncThunk(
  `${name}/adminCompaniesWithAccountingInfoRequested`,
  async ({ month, year }: { month: number; year: number }, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompaniesWithAccountInfo(month, year);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUpdateCompanyPricingTier = createAsyncThunk(
  `${name}/adminUpdateCompanyPricingTier`,
  async (
    { companyId, newPricingTier }: { companyId: string; newPricingTier: PRICING_TIER },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await adminUpdateCompanyPriceTier(companyId, { newPricingTier });
      dispatch(adminCompaniesWithMembersPopulatedRequested());
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminCompaniesSlice = createSlice({
  name,
  initialState: {
    isLoading: false,
    error: null,
    isSuccess: false,
    isPricingTierUpdated: false,
    companies: [],
    companiesWithMembersPopulated: [],
    companiesNotPopulated: [],
    companyNameAndIdList: [],
    companiesWithAccountingInfo: [],
  } as CompanyState,
  reducers: {
    updateCompaniesWithAccountingInfo: (state, { payload }) => {
      const invoices = payload;
      invoices.forEach((invoice: Invoice) => {
        const updatedCompany = state.companiesWithAccountingInfo.find(
          (company: CompanyWithAccountingInfo) => company.companyId === invoice.companyId
        );
        if (updatedCompany) updatedCompany.stripeInvoice = invoice;
      });
    },
    updateCompanyStatus: (state: CompanyState, { payload }) => {
      const { companyId, targetStatus } = payload;
      state.companiesWithMembersPopulated = state.companiesWithMembersPopulated.map((company) =>
        company.id === companyId
          ? { ...company, accountHolder: { ...company.accountHolder, status: targetStatus } }
          : company
      );
    },
  },
  extraReducers: (builder) => {
    // adminCompaniesRequested
    builder.addCase(adminCompaniesRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompaniesRequested.fulfilled, (state, { payload }) => {
      state.companies = payload ?? [];
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompaniesRequested.rejected, (state, { error }) => {
      state.companies = [];
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    // adminCompaniesWithMembersPopulatedRequested
    builder.addCase(adminCompaniesWithMembersPopulatedRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompaniesWithMembersPopulatedRequested.fulfilled, (state, { payload }) => {
      state.companiesWithMembersPopulated = payload ?? [];
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompaniesWithMembersPopulatedRequested.rejected, (state, { error }) => {
      state.companiesWithMembersPopulated = [];
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    // adminCompaniesNotPopulatedRequested
    builder.addCase(adminCompaniesNotPopulatedRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompaniesNotPopulatedRequested.fulfilled, (state, { payload }) => {
      state.companiesNotPopulated = payload ?? [];
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompaniesNotPopulatedRequested.rejected, (state, { error }) => {
      state.companiesNotPopulated = [];
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    // adminCompanyNameAndIdRequested
    builder.addCase(adminCompanyNameAndIdRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompanyNameAndIdRequested.fulfilled, (state, { payload }) => {
      state.companyNameAndIdList = payload ?? [];
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompanyNameAndIdRequested.rejected, (state, { error }) => {
      state.companyNameAndIdList = [];
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    // adminCompaniesWithAccountingInfoRequested
    builder.addCase(adminCompaniesWithAccountingInfoRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompaniesWithAccountingInfoRequested.fulfilled, (state, { payload }) => {
      state.companiesWithAccountingInfo = payload ?? [];
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompaniesWithAccountingInfoRequested.rejected, (state, { error }) => {
      state.companiesWithAccountingInfo = [];
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    // adminUpdateCompanyPricingTier
    builder.addCase(adminUpdateCompanyPricingTier.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminUpdateCompanyPricingTier.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
      state.isPricingTierUpdated = true;
    });
    builder.addCase(adminUpdateCompanyPricingTier.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
      state.isPricingTierUpdated = false;
    });
    // adminCompanyMemberStatusUpdateRequested
    builder.addCase(adminCompanyMemberStatusUpdateRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminCompanyMemberStatusUpdateRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminCompanyMemberStatusUpdateRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
  },
});

export const { updateCompaniesWithAccountingInfo, updateCompanyStatus } =
  adminCompaniesSlice.actions;

export default adminCompaniesSlice.reducer;
