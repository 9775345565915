// eslint-disable-next-line import/no-anonymous-default-export
export default {
  extraExtraSmall: '1rem',
  extraSmall: '1.2rem',
  small: '1.3rem',
  mediumSmall: '1.4rem',
  medium: '1.6rem',
  large: '2rem',
  mediumLarge: '2.6rem',
  extraLarge: '3.3rem',
};
