import { MouseEventHandler } from 'react';

import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsis-icon.svg';

import './MenuMini.scss';

const MenuMini = ({
  onClick,
  onMouseDown,
}: {
  onClick: () => void;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      className="Menu-Mini"
      aria-label="open menu"
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      <EllipsisIcon />
    </button>
  );
};

export default MenuMini;
