import { Row } from 'antd';
import { FieldInputProps } from 'formik';

import { CustomSelect } from 'components';

import { REQUIRED_ERROR } from '../constants';

export const ModifiedCustomSelect = ({
  getFieldProps,
  setFieldValue,
  values,
  select,
}: {
  getFieldProps: <Value = any>(props: any) => FieldInputProps<Value>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: Record<string, string>;
  select: {
    formControl: string;
    formData: {
      options: { label: string; value: string }[];
      title: string;
      placeholder: string;
    };
    handleBlur: Function;
    getError?: Function;
  };
}) => {
  return (
    <Row className="form-row">
      <section className="input-wrapper">
        <label htmlFor={select.formControl}>
          <h3 className="title">{select.formData.title}</h3>
        </label>
        <div className="input">
          <CustomSelect
            {...getFieldProps(select.formControl)}
            id={select.formControl}
            placeholder={select.formData.placeholder}
            options={select.formData.options}
            onChange={(option: { label: string; value: string }) => {
              setFieldValue(select.formControl, option.value);
            }}
            error={select.getError ? select.getError(values) : undefined}
            onBlur={select.handleBlur}
            variant="filter"
            aria-label={select.formData.title}
            tall
          />
          <div className="Form-Error">
            {select.getError ? select.getError(values) && REQUIRED_ERROR : undefined}
          </div>
        </div>
      </section>
    </Row>
  );
};
