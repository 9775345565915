import { TIER_LEVEL } from '@cpm/scanifly-shared-data';

import {
  FORM_CONTROLS,
  initialValuesForPlanSet,
  initialValuesForSitePlan,
  initialValuesForTrueUp,
  initialValuesForTrueUpWithNoDesign,
} from '../OrderDesign/DesignSelection/FormWrapper/constants';

export const getInitialFormValues = ({
  tierLevel,
  upgrade,
  previousFormData,
  useExistingDesign = true,
}: {
  tierLevel: TIER_LEVEL;
  upgrade: boolean;
  previousFormData: Record<string, any> | null;
  useExistingDesign?: boolean;
}) => {
  if (tierLevel === TIER_LEVEL.sitePlan) {
    return initialValuesForSitePlan;
  } else if (tierLevel === TIER_LEVEL.trueUp) {
    if (useExistingDesign) {
      return initialValuesForTrueUp;
    }
    return initialValuesForTrueUpWithNoDesign;
  } else if (
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim
  ) {
    if (upgrade && previousFormData) {
      Object.entries(previousFormData).forEach(
        ([key, value]) =>
          (initialValuesForPlanSet[key as keyof typeof initialValuesForPlanSet] = value)
      );
      return initialValuesForPlanSet;
    }
    return initialValuesForPlanSet;
  } else {
    return {
      [FORM_CONTROLS.SIZED_FOR_CATEGORY]: 'none',
      [FORM_CONTROLS.SIZED_FOR_VALUE]: '',
    } as Partial<typeof initialValuesForSitePlan>;
  }
};
