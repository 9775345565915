import { Input, Row } from 'antd';
import PropTypes from 'prop-types';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

const SignUpBillingContact = ({ touched, errors, getFieldProps }) => {
  return (
    <>
      <h2 className="SignUp-Form-Title">Billing Contact</h2>
      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="SignUpBillingContactName">Name</label>
          <Input
            placeholder="Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.NAME)}
            id="SignUpBillingContactName"
            {...getFieldProps(FORM_CONTROLS.NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.NAME)}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="SignUpBillingContactEmail">Email</label>
          <Input
            placeholder="name@company.com"
            className={validateStatus(touched, errors, FORM_CONTROLS.EMAIL)}
            id="SignUpBillingContactEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
          </div>
        </div>
      </Row>
    </>
  );
};

SignUpBillingContact.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  getFieldProps: PropTypes.func,
};

export default SignUpBillingContact;
