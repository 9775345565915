import { PROJECT_TYPES, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import confirm from 'antd/lib/modal/confirm';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import {
  designServicesLandingRoute,
  projectCategoriesRoute,
  scaniflyAdminCustomerSupportUploadRoute,
} from 'helpers/constants/routes';
import { openNotification } from 'helpers/utils/openNotification';
import { pluralize } from 'helpers/utils/pluralize';

import {
  NOTIFICATION_DESCRIPTIONS,
  NOTIFICATION_TITLES,
  NOTIFICATION_TYPES,
  SUPPORTED_FILE_TYPES,
} from './constants';
import './ProjectCategory.scss';

export const dedupedFiles = (files, projectMedias) => {
  const filteredFiles = new Set(files);
  const duplicateFiles = [];
  const currentUploadedFileSet = new Set(
    projectMedias.map((file) => file.originalFilenameWithoutExtension + file.originalFileExtension)
  );

  files.forEach((file) => {
    if (currentUploadedFileSet.has(file.name)) {
      duplicateFiles.push(file);
      filteredFiles.delete(file);
    }
  });

  return { filteredFiles: [...filteredFiles], duplicateFiles };
};

export const getGeolocationFromMeta = (latLngStr) => {
  const [latitude, longitude] = latLngStr.split(',').map(parseFloat);
  return { latitude, longitude };
};

export const isValidGeolocation = (geolocation) => {
  if (geolocation === null || geolocation === undefined) return false;

  // If geolocation is an object with latitude and longitude properties, and those properties are numbers, return true
  if (
    geolocation?.latitude &&
    !isNaN(geolocation?.latitude) &&
    geolocation?.longitude &&
    !isNaN(geolocation?.longitude)
  ) {
    return true;
  }

  // If geolocation is a string, split it into an array of strings, map each string to a float, and check if they are numbers
  if (typeof geolocation === 'string') {
    const [latitude, longitude] = geolocation.split(',').map(parseFloat);
    return !isNaN(latitude) && !isNaN(longitude);
  }

  return false;
};

export const handleConfirmMediaDeletion = (handleDeleteMedia, mediaQuantity) => {
  confirm({
    title: 'Delete Media',
    content: `Are you sure you want to delete ${pluralize(mediaQuantity, 'image')}?`,
    okText: 'Delete',
    okButtonProps: { style: { background: colors.red } },
    onOk: () => handleDeleteMedia(),
    ...CONFIRM_PROPS,
  });
};

export const handleOpenCancelConfirm = (
  uploadInProgress,
  handleCancelUpload,
  projectMediaCount
) => {
  if (uploadInProgress) {
    confirm({
      title: 'Cancel Upload',
      content: 'Are you sure you want to cancel the upload process?',
      okButtonProps: { style: { background: colors.red } },
      okText: 'Cancel Upload',
      onOk: () => handleCancelUpload(),
      ...CONFIRM_PROPS,
    });
  } else {
    confirm({
      title: 'Are you sure?',
      content: `We will save ${projectMediaCount} successfully uploaded files for you, but you will need to re-upload the remaining images that did not upload successfully.`,
      okButtonProps: { style: { background: colors.red } },
      okText: 'Cancel Retry',
      onOk: () => handleCancelUpload(),
      ...CONFIRM_PROPS,
    });
  }
};

const getRestrictionFailedKey = (error) => {
  if (error.message.includes(NOTIFICATION_TYPES.DUPLICATE)) {
    return NOTIFICATION_TYPES.DUPLICATE;
  }
  if (error.message.includes(SUPPORTED_FILE_TYPES.JPG[0])) {
    return NOTIFICATION_TYPES.FILE_TYPE;
  }
  return NOTIFICATION_TYPES.RESTRICTION_FAILED;
};

export const openFileListNotification = ({ files, type }) => {
  const firstThreeFiles = files.slice(0, 3);
  const content = (
    <div>
      {type === NOTIFICATION_TYPES.DUPLICATE
        ? NOTIFICATION_DESCRIPTIONS.DUPLICATE
        : NOTIFICATION_DESCRIPTIONS.MISSING_LOCATION}
      <div className="ProjectCategory-ErrorList">
        {firstThreeFiles.map((file) => (
          <span>
            {file.name}
            {file.id !== firstThreeFiles[firstThreeFiles.length - 1].id && ', '}
          </span>
        ))}
        {files.length > 3 && <span>, ...</span>}
      </div>
    </div>
  );
  openNotification({
    type: 'warning',
    title:
      type === NOTIFICATION_TYPES.DUPLICATE
        ? NOTIFICATION_TITLES.DUPLICATE
        : NOTIFICATION_TITLES.MISSING_LOCATION,
    text: content,
  });
};

export const openNetworkNotification = (type) => {
  const offline = type === NOTIFICATION_TYPES.OFFLINE;
  openNotification({
    type: offline ? 'error' : 'success',
    title: offline ? NOTIFICATION_TITLES.OFFLINE : NOTIFICATION_TITLES.ONLINE,
    text: '',
    key: NOTIFICATION_TYPES.NETWORK_CONNECTION,
    duration: offline ? 0 : 4.5,
  });
};

export const openRestrictionFailedNotification = (error, isDroneImages) => {
  const key = getRestrictionFailedKey(error);
  const text = {
    duplicate: NOTIFICATION_DESCRIPTIONS.DUPLICATE_RESTRICTION,
    fileType: isDroneImages
      ? NOTIFICATION_DESCRIPTIONS.FILE_TYPE_JPG
      : NOTIFICATION_DESCRIPTIONS.FILE_TYPE_ALL,
    restrictionFailed: NOTIFICATION_DESCRIPTIONS.RESTRICTION_FAILED,
  }[key];
  const title = {
    duplicate: NOTIFICATION_TITLES.DUPLICATE,
    fileType: NOTIFICATION_TITLES.FILE_TYPE,
    restrictionFailed: NOTIFICATION_TITLES.RESTRICTION_FAILED,
  }[key];
  openNotification({
    type: 'warning',
    title,
    text,
    key,
  });
};

export const openUploadSuccessNotification = () => {
  openNotification({
    type: 'success',
    title: NOTIFICATION_TITLES.SUCCESS,
    text: NOTIFICATION_DESCRIPTIONS.SUCCESS,
  });
};

export const selectDefaultBoundingBoxType = (activeSubscription = null) => {
  // If user has project quota for large project type, but not for small project type, the BB should default to 'large'
  // If user has project quota for small project type, but not for large project type, the BB should default to 'small'
  // If user has project quota for both large and small project type, the BB should default to 'small'
  // If user is scanifly admin, it should default to 'small'
  // If user has no subscription (trial customers & scanifly admins), it should default to 'small'
  const { largeProjectQuota, smallProjectQuota } = activeSubscription ?? {};
  if (largeProjectQuota && !smallProjectQuota) {
    return PROJECT_TYPES.LARGE;
  }
  return PROJECT_TYPES.SMALL;
};

export const getSuccessfulUploadRedirectionRoute = (
  designServicesAccess,
  isAdminRoute,
  companySubscriptionInfo,
  projectId,
  projectType
) => {
  if (!designServicesAccess) {
    return projectCategoriesRoute(projectId);
  }

  const credits = companySubscriptionInfo?.credits?.amount;
  const hasAutomatedWorkflow =
    companySubscriptionInfo?.dspTierSmall === TIER_LEVEL.acceleratedWireframes;
  if (isAdminRoute) {
    return scaniflyAdminCustomerSupportUploadRoute();
  } else if (
    ((credits && credits > 0) || hasAutomatedWorkflow) &&
    projectType === PROJECT_TYPES.SMALL
  ) {
    return designServicesLandingRoute(projectId);
  }
  return projectCategoriesRoute(projectId);
};
