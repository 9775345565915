import API from '../config';
import {
  deleteCategoryMediasUrl,
  deleteProjectMediasUrl,
  downloadCategoryMediaUrl,
  fetchAllMediasUrl,
  fetchCategoryMediasByIdUrl,
  fetchCategoryMediasUrl,
  fetchCategoryMediaUrl,
  fetchProjectMediasUrl,
  fetchProjectMediaUrl,
} from './mediasUrls';

export const fetchProjectMedia = (id: string) => API.get(fetchProjectMediaUrl(id));
export const downloadCategoryMedia = (id: string) =>
  API.get(downloadCategoryMediaUrl(id), { responseType: 'blob' });
export const fetchProjectMedias = (projectId: string) => API.get(fetchProjectMediasUrl(projectId));
export const fetchAllMedias = (projectId: string) => API.get(fetchAllMediasUrl(projectId));
export const deleteProjectMedias = (medias: { medias: string[] }) =>
  API.delete(deleteProjectMediasUrl(), { data: medias });

export const fetchCategoryMedia = (id: string) => API.get(fetchCategoryMediaUrl(id));
export const fetchCategoryMedias = (projectId: string, categoryName?: string) =>
  API.get(fetchCategoryMediasUrl(projectId, categoryName));
export const fetchCategoryMediasById = (projectId: string, categoryId: string) =>
  API.get(fetchCategoryMediasByIdUrl(projectId, categoryId));
export const deleteCategoryMedias = (medias: { medias: string[] }) =>
  API.delete(deleteCategoryMediasUrl(), { data: medias });
