import { arrayRange } from 'helpers/utils/arrayRange';

const moduleSpacingOptions = arrayRange(0, 3, 0.5).map((value) => ({
  label: value + '"',
  value: String(value),
}));

const azimuthRangeOptions = arrayRange(0, 360, 10).map((value) => ({
  label: value + '°',
  value: String(value),
}));

export const DESIGN_INFORMATION_FORM = {
  DESIGN_ONE_MODULE: {
    title: 'Design 1 Module*',
    placeholder: '--Select--',
    options: [],
  },
  DESIGN_TWO_MODULE: {
    title: 'Design 2 Module',
    placeholder: '--Select--',
    options: [],
  },
  MODULE_SPACING_LONG: {
    title: 'Module spacing (long side)*',
    placeholder: '1"',
    options: moduleSpacingOptions,
  },
  MODULE_SPACING_SHORT: {
    title: 'Module spacing (short side)*',
    placeholder: '1"',
    options: moduleSpacingOptions,
  },
  AZIMUTH_RANGE_START: {
    title: 'Azimuth range*',
    placeholder: '90°',
    options: azimuthRangeOptions,
  },
  AZIMUTH_RANGE_END: {
    title: ' ',
    placeholder: '270°',
    options: azimuthRangeOptions,
  },
  MODULE_ORIENTATION: {
    title: 'Module orientation*',
    placeholder: '--Select--',
    options: [
      { label: 'Landscape', value: 'landscape' },
      { label: 'Portrait', value: 'portrait' },
    ],
  },
  MIXED_ORIENTATION: {
    title: 'Mixed orientation when possible*',
    placeholder: '--Select--',
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
  },
  PREFERRED_MODULE_TILT: {
    title: 'Preferred module tilt for flat roofs',
    placeholder: '5°',
  },
  TSRF_MINIMUM: {
    title: 'TSRF minimum',
    placeholder: '',
  },
};
