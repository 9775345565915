import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { MediaItem, SurveyMedia } from 'types';

import { formatFileName } from './formatFileName';

export default async function zipMedia(files: (MediaItem | SurveyMedia)[], fileName: string) {
  try {
    const zip = new JSZip();

    files.forEach((file) => {
      zip.file(formatFileName(file), JSZipUtils.getBinaryContent(file.imgUrl));
    });

    await zip
      .generateAsync({
        type: 'blob',
      })
      .then(function (media) {
        saveAs(media, fileName);
      });

    return true;
  } catch (error) {
    console.error(error);
  }
  return null;
}
