import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { POSITION_FILTERS } from 'screens/ScaniflyAdmin/constants';
import { User } from 'types';

import { companySubscriptionInfoRequested } from 'state/slices/companySlice';
import { AppDispatch, RootState } from 'state/store/store';

import usePermissions from './usePermissions';

const useHeap = () => {
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { isScaniflyAdmin, isDesignServiceProvider } = usePermissions();
  const { company, companySubscriptionInfo } = useSelector((state: RootState) => state.company);
  const { project } = useSelector((state: RootState) => state.project);
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const { id, name, address, createdAt, pricingTier } = company ?? {
    id: null,
    name: null,
    address: { country: null },
    createdAt: null,
    pricingTier: null,
  };

  useEffect(() => {
    if (id && !isScaniflyAdmin && !isDesignServiceProvider) {
      dispatch(companySubscriptionInfoRequested(id));
    }
  }, [id, dispatch, isDesignServiceProvider, isScaniflyAdmin]);

  /*const {
    companySize,
    contractStartDate,
    contractEndDate,
    hubspotUrl,
    largeProjectQuota,
    smallProjectQuota,
    scaniflyAccountManager,
  } = companySubscriptionInfo?.subscription ?? {
    compan
  }*/

  const { email, firstName, lastName }: User = currentUser ?? {
    email: '',
    firstName: '',
    lastName: '',
  };

  useEffect(() => {
    if (process.env.REACT_APP_HEAP_ID && currentUser?.id && window.heap) {
      const props = {
        userEmail: email,
        firstName,
        lastName,
        companyName: name,
        companyLocation: address.country,
        companySize: companySubscriptionInfo?.subscription?.companySize,
        companyCreatedAt: createdAt,
        pricingTier,
        contractStartDate: companySubscriptionInfo?.subscription?.contractStartDate,
        contractEndDate: companySubscriptionInfo?.subscription?.contractStartDate,
        hubspotURL: companySubscriptionInfo?.subscription?.hubspotUrl,
        largeProjectQuota: companySubscriptionInfo?.subscription?.largeProjectQuota,
        smallProjectQuota: companySubscriptionInfo?.subscription?.smallProjectQuota,
        scaniflyAccountManager: companySubscriptionInfo?.subscription?.scaniflyAccountManager,
        userRole: '',
      };

      if (currentUser?.position) {
        props.userRole =
          POSITION_FILTERS.find((el) => el.value === currentUser.position)?.text ?? '-';
      }

      window.heap.identify(currentUser.id);
      window.heap.addUserProperties(props);
    }
  }, [
    currentUser,
    company,
    companySubscriptionInfo?.subscription,
    email,
    firstName,
    lastName,
    name,
    address.country,
    createdAt,
    pricingTier,
  ]);

  useEffect(() => {
    window.heap?.clearEventProperties();

    if (project && window.heap) {
      window.heap.addEventProperties({
        projectId: project.id,
        projectName: project.name,
      });
    }
  }, [location, project]);
};

export default useHeap;
