import styled from 'styled-components';

const ProjectName = styled.h2`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 1rem;
`;

export default ProjectName;
