import { Tag as AntdTag } from 'antd';
import { MAX_LENGTH } from 'screens/DesignServices/OrderDesign/DesignSelection/FormWrapper/PlanSetForms/constants';

import { truncateLongText } from 'helpers/utils/truncateLongText';

const Tag = ({
  children,
  color,
  maxTextLength = MAX_LENGTH,
  ...props
}: {
  children?: any;
  color?: string;
  maxTextLength?: number;
}) => (
  <AntdTag color={color} {...props} data-testid={`${color}-tag`}>
    {typeof children === 'string' && children.length > maxTextLength
      ? truncateLongText(children, maxTextLength)
      : children}
  </AntdTag>
);

export default Tag;
