import { openNotification } from 'helpers/utils/openNotification';

export const openSuccessNotification = (name) => {
  openNotification({
    type: 'success',
    title: 'Success!',
    text: `You have exported ${name}.zip`,
  });
};

export const openErrorNotification = () => {
  openNotification({
    type: 'error',
    title: 'Oops!',
    text: 'Something went wrong. Please try again.',
  });
};
