import { isAccountInDangerZone } from 'screens/ScaniflyAdmin/Accounting/helpers';
import { DATE_OPTIONS } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';

import { USER_STATUS } from 'helpers/constants/userStatus';

export const filterAccountingInformation = (
  accounts,
  searchTerm,
  showOnlyActiveAccounts,
  showOnlyActiveSubscriptions,
  showOnlyDangerZone
) =>
  accounts.filter((account) => {
    return (
      (showOnlyActiveAccounts && account.accountHolderStatus !== ''
        ? account.accountHolderStatus === USER_STATUS.ACTIVE
        : true) &&
      (showOnlyActiveSubscriptions ? account.subscription !== undefined : true) &&
      (showOnlyDangerZone ? isAccountInDangerZone(account) : true) &&
      (searchTerm === '' ||
        [
          account.companyName,
          account.accountHolderName,
          account.stripeInvoice?.status,
          new Date(account.subscription?.contractStartDate).toLocaleDateString(
            'en-US',
            DATE_OPTIONS
          ),
          new Date(account.subscription?.contractEndDate).toLocaleDateString('en-US', DATE_OPTIONS),
        ].some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  });
