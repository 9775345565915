import { ReferenceMedia } from '@cpm/scanifly-shared-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CREATE_REFERENCE_MEDIA,
  DELETE_REFERENCE_MEDIA,
  GET_REFERENCE_MEDIA_BY_CATEGORY,
  SLICE_NAMESPACE,
} from './constants';
import { ReferenceMediaState } from './types';

const initialState: ReferenceMediaState = {
  referenceMediaByCategory: {},
};

const referenceMediaSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    [CREATE_REFERENCE_MEDIA]: (state, { payload: createdMedia }: PayloadAction<ReferenceMedia>) => {
      state.referenceMediaByCategory[createdMedia.mediaCategoryId].push(createdMedia);
    },
    [GET_REFERENCE_MEDIA_BY_CATEGORY]: (
      state,
      { payload: media }: PayloadAction<ReferenceMedia[]>
    ) => {
      const categoryId = media?.[0]?.mediaCategoryId;
      if (categoryId) {
        state.referenceMediaByCategory[categoryId] = media;
      }
    },
    [DELETE_REFERENCE_MEDIA]: (
      state,
      { payload }: PayloadAction<{ mediaId: string; categoryId: string }>
    ) => {
      state.referenceMediaByCategory[payload.categoryId] = state.referenceMediaByCategory[
        payload.categoryId
      ].filter(({ id }) => id !== payload.mediaId);
    },
  },
  extraReducers: () => {},
});

const { actions } = referenceMediaSlice;
export { actions };

export default referenceMediaSlice.reducer;
