import { useEffect, useMemo, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Pagination, Space, Spin, Switch } from 'antd';
import cn from 'classnames';
import { debounce, pull } from 'lodash-es';
import MapWrapper from 'screens/MapWrapper';
import EditFoldersModal from 'screens/Projects/ProjectsFilters/EditFoldersModal';
import FilterHelpers from 'screens/Projects/ProjectsFilters/FilterHelpers';
import SingleFilter from 'screens/Projects/ProjectsFilters/SingleFilter';
import ProjectTile from 'screens/Projects/ProjectsList/ProjectTile';

import { adminCompanyNameAndIdRequested } from 'state/slices/admin/adminCompaniesSlice';
import { resetProject } from 'state/slices/projectSlice';
import {
  allProjectsRequested,
  filtersRequested,
  projectsRequested,
  resetProjectsLoadedSuccessfully,
  resetStatusUpdateFlags,
} from 'state/slices/projectsSlice';

import { ACCESS } from 'helpers/constants';
import { PROJECT_LIST_GRID_SIZE, PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { isDesktopView } from 'helpers/utils/breakpoints';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { FILTERS, MORE_FILTERS, SORT } from './constants';
import './ProjectsList.scss';

const ProjectsList = () => {
  const dispatch = useDispatch();
  const [isCheckedAll, toggleIsCheckedAll] = useToggle();
  const [isMapOnly, toggleIsMapOnly] = useToggle();
  const [isListView, setIsListView] = useToggle(true);
  const [currentBounds, setCurrentBounds] = useState();
  const [savedFilters, setSavedFilters] = useState({
    sort: SORT.CREATED_AT,
    order: SORT.DESC,
  });
  const [openedFilter, setOpenedFilter] = useState('');
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [isEditFoldersModalOpen, setIsEditFoldersModalOpen] = useState(false);
  const [resetViewport, setResetViewport] = useState(false);
  const { isScaniflyAdmin } = usePermissions();

  const {
    projects,
    totalProjects,
    pageIndex,
    filterOptions,
    isLoading,
    allProjects,
    isFiltersLoading,
    projectsLoadedSuccessfully,
    statusUpdateError,
    statusUpdateSuccess,
  } = useSelector((state) => state.projects);

  const { activeSubscription } = useSelector((state) => state.adminSubscriptions);
  const hasAccessToRemoteDesign = useFeatureToggle(ACCESS.REMOTE_DESIGN);
  const canUseRemoteDesignFeature =
    activeSubscription?.canUseRemoteDesign || hasAccessToRemoteDesign;

  const pageSize = isListView && isDesktopView() ? PROJECT_LIST_SIZE : PROJECT_LIST_GRID_SIZE;

  const { isRequestSuccessful } = useSelector((state) => state.folders);

  const isProjectsListAvailable = Boolean(projects.length);

  const projectIds = allProjects && allProjects.map((project) => project.id);

  useEffect(() => {
    if (projectsLoadedSuccessfully && isCheckedAll && isMapOnly) {
      setCheckedProjects(projects.map((project) => project.id));
      dispatch(resetProjectsLoadedSuccessfully());
    }
  }, [dispatch, isCheckedAll, isMapOnly, projects, projectsLoadedSuccessfully]);

  useEffect(() => {
    dispatch(filtersRequested());
    if (isScaniflyAdmin) {
      dispatch(adminCompanyNameAndIdRequested());
    }
  }, [dispatch, isScaniflyAdmin]);

  useEffect(() => {
    if (totalProjects) {
      dispatch(resetProject());
      dispatch(allProjectsRequested(savedFilters));
    }
  }, [dispatch, totalProjects, savedFilters]);

  useEffect(() => {
    if (!isMapOnly) {
      dispatch(projectsRequested({ ...savedFilters, page: 1, size: pageSize }));
    }
  }, [dispatch, isMapOnly, savedFilters, pageSize]);

  useEffect(() => {
    if (isMapOnly) {
      dispatch(
        projectsRequested({
          page: pageIndex,
          ...savedFilters,
          bounds: currentBounds,
          size: allProjects.length,
        })
      );
    }
  }, [dispatch, isMapOnly, pageIndex, savedFilters, currentBounds, allProjects]);

  useEffect(() => {
    if (isRequestSuccessful) {
      dispatch(projectsRequested({ ...savedFilters, page: pageIndex, size: pageSize }));
      setCheckedProjects([]);
      if (isCheckedAll) {
        toggleIsCheckedAll();
      }
    }
  }, [
    dispatch,
    savedFilters,
    isRequestSuccessful,
    pageIndex,
    pageSize,
    toggleIsCheckedAll,
    isCheckedAll,
  ]);

  useEffect(() => {
    if (statusUpdateError) {
      openNotification({
        type: 'error',
        title: 'There was an error.' + statusUpdateError,
        text: 'Please try again in a few seconds.',
      });

      dispatch(resetStatusUpdateFlags());
    }
  }, [dispatch, statusUpdateError]);

  useEffect(() => {
    if (statusUpdateSuccess) {
      openNotification({
        type: 'success',
        text: statusUpdateSuccess,
      });

      dispatch(resetStatusUpdateFlags());
    }
  }, [dispatch, statusUpdateSuccess]);

  const handleCheckAll = () => {
    const checkedProjectIds = isMapOnly ? projects.map((project) => project.id) : projectIds;
    !isCheckedAll ? setCheckedProjects(checkedProjectIds) : setCheckedProjects([]);
    return toggleIsCheckedAll();
  };
  const handleCheck = (id) => {
    if (isCheckedAll) {
      toggleIsCheckedAll();
    }
    checkedProjects.includes(id)
      ? setCheckedProjects([...pull(checkedProjects, id)])
      : setCheckedProjects([...checkedProjects, id]);
  };

  const onPaginationChange = (page) => {
    dispatch(projectsRequested({ ...savedFilters, page, size: pageSize }));
  };

  const pins = useMemo(
    () =>
      allProjects.map((project) => ({
        id: project.id,
        location: project.geolocation,
        isChecked: checkedProjects.includes(project.id),
      })),
    [allProjects, checkedProjects]
  );

  const handleFilterSave = (filterType, values) => {
    isMapOnly
      ? setSavedFilters({
          sort: savedFilters.sort,
          order: savedFilters.order,
          bounds: currentBounds,
          [filterType]: values,
        })
      : setSavedFilters({
          ...savedFilters,
          [filterType]: values,
          bounds: null,
        });
  };

  const handleBoundsChange = (bounds) => {
    setCurrentBounds(bounds);
    isMapOnly &&
      dispatch(
        projectsRequested({
          page: pageIndex,
          bounds,
          sort: savedFilters.sort,
          order: savedFilters.order,
          size: pageSize,
        })
      );
  };

  const handleMapOnly = () => {
    if (!isMapOnly) {
      dispatch(
        projectsRequested({
          page: pageIndex,
          ...savedFilters,
          bounds: currentBounds,
          size: allProjects.length,
        })
      );
    } else {
      if (currentBounds && allProjects?.length) {
        const markersOutOfBounds = allProjects.some((project) => {
          const { latitude, longitude } = project.geolocation;
          return !currentBounds.contains({ lat: latitude, lng: longitude });
        });
        setResetViewport(markersOutOfBounds);
      }
      if (isCheckedAll) {
        setCheckedProjects(projectIds);
      }
      dispatch(projectsRequested({ page: 1, ...savedFilters, bounds: null, size: pageSize }));
    }
    toggleIsMapOnly();
  };

  const handleInputChange = (e) => {
    setSavedFilters({
      ...savedFilters,
      searchText: e.target.value,
    });
  };

  const options = {
    ...filterOptions,
    more: MORE_FILTERS.map((filter) => ({
      id: filter.id,
      title: filter.label,
      options: filterOptions[filter.id],
    })),
  };

  return (
    <MapWrapper
      pins={pins}
      clustered={true}
      checkedPins={checkedProjects}
      allProjects={allProjects}
      withHideMapButton
      withToggleFullscreen
      resetViewport={resetViewport}
      setResetViewport={setResetViewport}
      isMapOnly={isMapOnly}
      handleBoundsChange={handleBoundsChange}
    >
      <div className="Projects-Filter-Wrapper">
        <Input
          placeholder="Search projects"
          className="Input--High Input--Search"
          prefix={<SearchIcon />}
          onChange={debounce(handleInputChange, 1000)}
          aria-label="search projects"
          data-testid="project-search-bar"
        />
        <div className="Projects-Filter-Wrapper-Main">
          {FILTERS.map((filter) => (
            <SingleFilter
              key={filter.id}
              filter={filter}
              handleFilterSave={handleFilterSave}
              setActive={setOpenedFilter}
              isActive={openedFilter === filter.id}
              savedFilters={savedFilters}
              isDisabled={isMapOnly || isFiltersLoading}
              options={options[filter.id]}
            />
          ))}
          <div className="Projects-Filter-Switch-Wrapper">
            <span>Map only</span>
            <Switch
              className="Projects-Filter-Switch"
              onChange={handleMapOnly}
              aria-label="toggle map only"
            />
          </div>
        </div>
        <FilterHelpers
          handleCheckAll={handleCheckAll}
          isCheckedAll={isCheckedAll}
          checkedProjects={checkedProjects}
          isListView={isListView}
          setIsListView={setIsListView}
          handleFilterSave={handleFilterSave}
          savedFilters={savedFilters}
          toggleEditFoldersModalOpen={setIsEditFoldersModalOpen}
          totalProjects={totalProjects}
        />
      </div>
      <div className="Projects-List">
        {isLoading && (
          <Space direction="vertical" size="large">
            <Spin size="large" />
          </Space>
        )}
        {!isLoading &&
          projects.map((project) => (
            <div
              className={cn('Projects-List-TileWrapper', {
                'Projects-List-TileWrapper--List': (isListView && isDesktopView()) || isTablet,
              })}
              key={project.id}
            >
              <ProjectTile
                {...project}
                onCheck={handleCheck}
                isChecked={checkedProjects.includes(project.id)}
                isListView={isListView}
                canUseRemoteDesignFeature={canUseRemoteDesignFeature}
              />
            </div>
          ))}
      </div>
      <br />
      {!isLoading && isProjectsListAvailable && (
        <div className="Projects-List-Pagination-Wrapper">
          <Pagination
            current={pageIndex}
            total={totalProjects}
            disabled={isMapOnly}
            onChange={onPaginationChange}
            defaultPageSize={pageSize}
            showSizeChanger={false}
            showTotal={(_, range) =>
              `${range[0]}-${isMapOnly ? projects.length : range[1]} of ${totalProjects} projects`
            }
          />
        </div>
      )}
      {!isLoading && !isProjectsListAvailable && (
        <div className="Projects-List-NoProjects">
          <p>There are no projects available</p>
        </div>
      )}
      <EditFoldersModal
        isModalVisible={isEditFoldersModalOpen}
        toggleModal={setIsEditFoldersModalOpen}
        checkedProjects={checkedProjects}
      />
    </MapWrapper>
  );
};

export default ProjectsList;
