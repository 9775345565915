import { createSlice } from '@reduxjs/toolkit';

import {
  CategoryRequestFailedPayload,
  CategoryRequestInProgressPayload,
  CategoryRequestSuccessfulPayload,
  MediaState,
  RequestStatus,
} from './types';

import { SLICE_NAMESPACE } from './constants';

const mediasSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState: {
    mediaMap: {},
    clientRefIdMap: {},
  } as MediaState,
  reducers: {
    clearLocalProjectMediasCache: (
      state,
      { payload: { projectId } }: { payload: { projectId: string } }
    ) => {
      delete state.mediaMap[projectId];
    },
    CATEGORY_REQUEST_IN_PROGRESS: (
      state,
      { payload: { projectId, categoryId } }: CategoryRequestInProgressPayload
    ) => {
      if (!state.mediaMap[projectId]) {
        state.mediaMap[projectId] = {
          media: {},
          categoryMap: {},
        };
      }
      if (!state.mediaMap[projectId].categoryMap[categoryId]) {
        state.mediaMap[projectId].categoryMap[categoryId] = {
          mediaOrder: [],
        };
      }
      state.mediaMap[projectId].categoryMap[categoryId].requestStatus = RequestStatus.inProgress;
      state.mediaMap[projectId].categoryMap[categoryId].lastRequested = new Date().getTime();
    },
    CATEGORY_REQUEST_SUCCESSFUL: (
      state,
      { payload: { projectId, categoryId, media } }: CategoryRequestSuccessfulPayload
    ) => {
      if (!state.mediaMap[projectId]) {
        state.mediaMap[projectId] = {
          media: {},
          categoryMap: {},
        };
      }
      if (!state.mediaMap[projectId].categoryMap[categoryId]) {
        state.mediaMap[projectId].categoryMap[categoryId] = {
          mediaOrder: [],
        };
      }
      state.mediaMap[projectId].categoryMap[categoryId].requestStatus = RequestStatus.complete;
      const mediaOrder = [];
      for (const mediaItem of media) {
        state.mediaMap[projectId].media[mediaItem.id] = mediaItem;
        state.clientRefIdMap[mediaItem?.clientRefId ?? mediaItem.id] = {
          projectId,
          mediaId: mediaItem.id,
        };
        mediaOrder.push(mediaItem.id);
      }
      state.mediaMap[projectId].categoryMap[categoryId].mediaOrder = mediaOrder;
    },
    CATEGORY_REQUEST_FAILED: (
      state,
      { payload: { projectId, categoryId, errorMessage } }: CategoryRequestFailedPayload
    ) => {
      if (!state.mediaMap[projectId]) {
        state.mediaMap[projectId] = {
          media: {},
          categoryMap: {},
        };
      }
      if (!state.mediaMap[projectId].categoryMap[categoryId]) {
        state.mediaMap[projectId].categoryMap[categoryId] = {
          mediaOrder: [],
        };
      }
      state.mediaMap[projectId].categoryMap[categoryId].requestStatus = RequestStatus.failed;
      state.mediaMap[projectId].categoryMap[categoryId].errorMessage = errorMessage;
    },
  },
});

export const { actions, reducer } = mediasSlice;
