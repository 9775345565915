import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaCategory } from 'types';

import {
  CREATE_MEDIA_CATEGORY,
  DELETE_MEDIA_CATEGORY,
  GET_ALL_MEDIA_CATEGORIES,
  GET_MEDIA_CATEGORIES_BY_TYPE,
  GET_MEDIA_CATEGORY_BY_ID,
  SLICE_NAMESPACE,
  UPDATE_MEDIA_CATEGORY,
} from './constants';
import { MediaCategoriesState } from './types';

const initialState: MediaCategoriesState = {
  allMediaCategories: [],
  categoriesByType: {},
};

const mediaCategoriesSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    [UPDATE_MEDIA_CATEGORY]: (
      state,
      { payload: updatedCategory }: PayloadAction<MediaCategory>
    ) => {
      state.allMediaCategories.map((cat) => {
        if (cat.id.toString() === updatedCategory.id.toString()) {
          return updatedCategory;
        }
        return cat;
      });
      state.categoriesByType[updatedCategory.type].map((cat) => {
        if (cat.id.toString() === updatedCategory.id.toString()) {
          return updatedCategory;
        }
        return cat;
      });
    },
    [CREATE_MEDIA_CATEGORY]: (
      state,
      { payload: createdCategory }: PayloadAction<MediaCategory>
    ) => {
      state.allMediaCategories.push(createdCategory);
      state.categoriesByType[createdCategory.type].push(createdCategory);
    },
    [GET_ALL_MEDIA_CATEGORIES]: (
      state,
      { payload: mediaCategories }: PayloadAction<MediaCategory[]>
    ) => {
      state.allMediaCategories = mediaCategories;
      state.categoriesByType = {};
      mediaCategories.forEach((cat) => {
        if (state.categoriesByType[cat.type]) {
          state.categoriesByType[cat.type].push(cat);
        } else {
          state.categoriesByType[cat.type] = [cat];
        }
      });
    },
    [GET_MEDIA_CATEGORY_BY_ID]: (
      state,
      { payload: mediaCategory }: PayloadAction<MediaCategory>
    ) => {
      let exists = false;
      state.allMediaCategories.map((cat) => {
        if (cat.id.toString() === mediaCategory.id.toString()) {
          exists = true;
          return mediaCategory;
        }
        return cat;
      });
      if (!exists) {
        state.allMediaCategories.push(mediaCategory);
      }

      exists = false;
      state.categoriesByType[mediaCategory.type].map((cat) => {
        if (cat.id.toString() === mediaCategory.id.toString()) {
          exists = true;
          return mediaCategory;
        }
        return cat;
      });
      if (!exists) {
        state.categoriesByType[mediaCategory.type].push(mediaCategory);
      }
    },
    [GET_MEDIA_CATEGORIES_BY_TYPE]: (
      state,
      { payload: mediaCategories }: PayloadAction<MediaCategory[]>
    ) => {
      const type = mediaCategories?.[0]?.type;
      if (type) {
        state.categoriesByType[type] = mediaCategories;
      }
    },
    [DELETE_MEDIA_CATEGORY]: (state, { payload: categoryId }: PayloadAction<string>) => {
      const category = state.allMediaCategories.find((cat) => cat.id === categoryId);
      if (category) {
        state.allMediaCategories = state.allMediaCategories.filter(({ id }) => id !== categoryId);
        state.categoriesByType[category.type] = state.categoriesByType[category.type].filter(
          ({ id }) => id !== categoryId
        );
      }
    },
  },
  extraReducers: () => {},
});

const { actions } = mediaCategoriesSlice;
export { actions };

export default mediaCategoriesSlice.reducer;
