import API from '../config';
import {
  changePasswordUrl,
  createUserUrl,
  deleteUserUrl,
  demoteAdminToDefaultUrl,
  fetchCompanyUsersUrl,
  fetchCurrentUserAvatarUrl,
  fetchCurrentUserUrl,
  fetchDesignServiceProviderListUrl,
  fetchUsersRolesUrl,
  fetchUserUrl,
  promoteUserToAdminUrl,
  updateCurrentEmailUrl,
  updateCurrentUserAvatarUrl,
  updateCurrentUserUrl,
  updateUserPermissionsUrl,
  updateUserProjectAccessUrl,
} from './usersUrls';

type CurrentUserData = {
  firstName: string;
  lastName: string;
  position: string;
  phone: string;
};

export const fetchCurrentUser = () => API.get(fetchCurrentUserUrl());
export const fetchDesignServiceProviderList = () => API.get(fetchDesignServiceProviderListUrl());
export const fetchUser = (userId: string) => API.get(fetchUserUrl(userId));
export const fetchCurrentUserAvatar = (token: string | null) =>
  API.get(fetchCurrentUserAvatarUrl(token));
export const fetchUsersRoles = () => API.get(fetchUsersRolesUrl());
export const deleteUser = (userId: string) => API.delete(deleteUserUrl(userId));
export const updateCurrentUser = (currentUserData: CurrentUserData) =>
  API.patch(updateCurrentUserUrl(), currentUserData);
export const changePassword = (password: string, token: string | undefined) =>
  API.patch(
    changePasswordUrl(),
    { password },
    {
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    }
  );
export const createUser = (user: any, token: string) => {
  return API.post(createUserUrl(), user, {
    headers: {
      Invitation: token,
    },
  });
};
export const updateCurrentUserAvatar = (avatar: string) =>
  API.post(updateCurrentUserAvatarUrl(), avatar);
export const updateUserPermissions = (id: string, permissions: any) =>
  API.patch(updateUserPermissionsUrl(id), permissions);
export const updateUserProjectAccess = (id: string, projectAccess: any) =>
  API.patch(updateUserProjectAccessUrl(id), projectAccess);
export const promoteUserToAdmin = (id: string) => API.patch(promoteUserToAdminUrl(id));
export const demoteAdminToDefault = (id: string) => API.patch(demoteAdminToDefaultUrl(id));
export const updateCurrentEmail = (email: string) => API.post(updateCurrentEmailUrl(), { email });
export const fetchCompanyUsers = (id: string) => API.get(fetchCompanyUsersUrl(id));
