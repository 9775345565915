import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Button } from 'antd';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { ImagePreviewContext } from 'components/ImagePreview/ImagePreviewProvider';
import { ToggleResolutionButton } from 'components/ImagePreview/ToggleResolutionButton';

import { validators } from 'helpers/utils';
import { dynamicImageResizerUtils } from 'helpers/utils/dynamicImageResizerUtils';
import { isGeneralBucket } from 'helpers/utils/isGeneralBucket';

import { ReactComponent as CompressIcon } from 'assets/icons/compress-icon.svg';

import { VideoPlayer } from '../VideoPlayer';
import './ImagePanzoom.scss';
import { ImagePanzoomProps } from './types';
import { ZoomStepper } from './ZoomStepper';

const ImagePanzoom = ({
  thumbnailUrl,
  mimeType,
  type,
  imageUrl,
  isPortrait = false,
}: ImagePanzoomProps) => {
  const isVideo = validators.isVideo({ type, mimeType });

  const containerRef = useRef<HTMLDivElement>(null);

  const { canUseImageResizer, width, height, setWidth, setHeight, isHighRes, toggleHighRes } =
    useContext(ImagePreviewContext);

  /**
   * If true then the dynamic image resizer failed (HEIC image)
   * so fall back to using supplied image from server
   */
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);

  const source = useMemo(() => {
    const useDynamicSize =
      canUseImageResizer && !isVideo && !isHighRes && !hasLoadingError && width && height;
    if (useDynamicSize) {
      const resizedImageUrl = dynamicImageResizerUtils.getImageUrlForSize({
        width,
        height,
        imageUrl,
        backgroundColor: { r: 0, g: 0, b: 0, alpha: 0 },
      });
      return resizedImageUrl;
    }
    return imageUrl;
  }, [width, height, imageUrl, canUseImageResizer, isVideo, isHighRes, hasLoadingError]);

  const onError = (err: any) => {
    console.error('ImagePanzoom onError', err);
    setHasLoadingError(true);
  };

  const updateFrame = useDebouncedCallback(
    () => {
      if (containerRef.current) {
        const { offsetWidth: width, offsetHeight: height } = containerRef.current;
        setWidth((prevWidth) => {
          return width ?? prevWidth;
        });
        setHeight((prevHeight) => {
          return height ?? prevHeight;
        });
      }
    },
    1000,
    { trailing: true }
  );

  // Track width/height of image so we can generate image with proper size
  useEffect(() => {
    updateFrame();

    // Add event listener for window resize
    window.addEventListener('resize', updateFrame);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateFrame);
    };
    // eslint-disable-next-line
  }, [containerRef]);

  return (
    <TransformWrapper alignmentAnimation={{ sizeX: 0, sizeY: 0 }} wheel={{ disabled: false }}>
      {({ zoomIn, zoomOut, resetTransform }) => (
        <>
          <div ref={containerRef} className="ImagePanzoom">
            <TransformComponent
              contentClass="ImagePanzoom-TransformComponent"
              wrapperClass="ImagePanzoom-TransformComponent"
            >
              {isVideo ? (
                <VideoPlayer videoUrl={imageUrl} mimeType={mimeType || type || ''} />
              ) : (
                <img
                  crossOrigin={isGeneralBucket(thumbnailUrl) ? undefined : 'anonymous'}
                  className={cn('ImagePanzoom-Image', {
                    'ImagePanzoom-Image--Portrait': isPortrait,
                  })}
                  src={source}
                  onError={onError}
                  alt="preview"
                  data-testid={'imagepanzoom-img'}
                />
              )}
            </TransformComponent>
          </div>
          <div className="ImagePanzoom-Button-Wrapper">
            <ZoomStepper zoomIn={zoomIn} zoomOut={zoomOut} />
            {canUseImageResizer && !isVideo && !hasLoadingError ? (
              <ToggleResolutionButton
                className={'ImagePanzoom-Button-Toggle'}
                isHighRes={!!isHighRes}
                toggleHighRes={toggleHighRes}
              />
            ) : null}
            <Button
              className="ImagePanzoom-Button-Reset"
              onClick={() => resetTransform()}
              aria-label="reset zoom"
            >
              <CompressIcon />
            </Button>
          </div>
        </>
      )}
    </TransformWrapper>
  );
};

export default memo(ImagePanzoom);
