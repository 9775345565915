import colors from './colors';
import fontFamily from './fontFamily';

export const PAYMENT_FIELD = {
  CREDIT_CARD: 'cardNumber',
  EXPIRY_DATE: 'cardExpiry',
  CVC_CODE: 'cardCvc',
  VAT_NUMBER: 'VATNumber',
};

export const PAYMENT_INPUT_OPTIONS = {
  style: {
    base: {
      fontFamily: fontFamily,
      fontSize: '13px',
      color: colors.darkGray,
      '::placeholder': {
        color: colors.labelGray,
      },
    },
    invalid: {
      color: colors.darkGray,
    },
  },
};

export const STRIPE_ERRORS = {
  INCOMPLETE_NUMBER: 'incomplete_number',
  INVALID_NUMBER: 'invalid_number',
  INCOMPLETE_EXPIRY: 'incomplete_expiry',
  INVALID_EXPIRY_YEAR_PAST: 'invalid_expiry_year_past',
  INVALID_EXPIRY_DATE: 'invalid_expiry_year',
  INCOMPLETE_CVC: 'incomplete_cvc',
};

export const SETUP_PAYMENT_METHOD_STATUSES = {
  SUCCEEDED: 'succeeded',
};

export const STRIPE_FONT =
  'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap';
