import { useDispatch } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';

import { createProjectCategory } from 'state/slices/projectMediaCategories';
import { validationSchema } from '../validationSchema';
import './AddNewProjectCategoryModal.scss';
import { FORM_CONTROLS, initialValues } from './constants';

const AddNewProjectCategoryModal = ({ categories, handleModalCancel, projectId, modalVisible }) => {
  const dispatch = useDispatch();

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: validationSchema(categories),
    onSubmit: (values) => {
      handleAddNewCategory(values.categoryName);
    },
  });

  const handleAddNewCategory = (categoryName) => {
    dispatch(
      createProjectCategory({
        projectId,
        categoryName,
      })
    );
    resetForm();
    handleModalCancel();
  };

  const isFormValid = isValid && dirty;

  return (
    <Modal visible={modalVisible} onCancel={handleModalCancel} {...MODAL_PROPS}>
      <span className="AddNewProjectCategoryModal-Title">Add New Album</span>
      <form onSubmit={handleSubmit}>
        <label className="AddNewProjectCategoryModal-Label" htmlFor="addNewProjectCategory">
          Album Name
        </label>
        <div className="FormControl-Wrapper">
          <Input
            prefix={<CategoryIcon />}
            placeholder="Enter album name"
            className={cn(
              'AddNewProjectCategoryModal-Input Input--HighAndLong',
              validateStatus(touched, errors, FORM_CONTROLS.CATEGORY_NAME)
            )}
            id="addNewProjectCategory"
            {...getFieldProps(FORM_CONTROLS.CATEGORY_NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CATEGORY_NAME)}
          </div>
        </div>

        <div className="AddNewProjectCategoryModal-Buttons-Wrapper">
          <Button onClick={handleModalCancel} className="Button--White">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            className="Button--Blue"
            autoFocus={isFormValid}
          >
            Create Album
          </Button>
        </div>
      </form>
    </Modal>
  );
};

AddNewProjectCategoryModal.propTypes = {
  categories: PropTypes.array.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
};

export default AddNewProjectCategoryModal;
