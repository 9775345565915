import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  DESIGN_TIME_ESTIMATES,
  estimateTurnaroundTimeForSmall,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';

import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as InProgressIcon } from 'assets/icons/in-progress-icon.svg';

import { i18n } from 'helpers/utils/translations';
import ActionButtons from '../ActionButtons/ActionButtons';

export const getProcessingTimeEstimate = ({
  tierLevel,
  isExpedited,
  isCommercial,
}: {
  tierLevel: TIER_LEVEL;
  isExpedited?: boolean;
  isCommercial?: boolean;
}) => {
  if (tierLevel === TIER_LEVEL.planSetForLarge || tierLevel === TIER_LEVEL.wireframeForLarge) {
    return `~${DESIGN_TIME_ESTIMATES[tierLevel as keyof typeof DESIGN_TIME_ESTIMATES] ?? 3} ${i18n.t('DesignService.hours')}`;
  }

  const turnaroundTime = estimateTurnaroundTimeForSmall({ tierLevel, isExpedited, isCommercial });

  const unit =
    turnaroundTime?.unit === 'minutes'
      ? i18n.t('DesignService.minutes')
      : i18n.t('DesignService.hours');

  return `~${turnaroundTime.design} ${unit}`;
};

const DesignInProgress = ({
  handleModalOpen,
  tierLevel,
  isExpedited = false,
  isCommercial = false,
}: {
  handleModalOpen: () => void;
  tierLevel: TIER_LEVEL;
  isExpedited?: boolean;
  isCommercial?: boolean;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const processingTimeEstimate = getProcessingTimeEstimate({
    tierLevel,
    isExpedited,
    isCommercial,
  });

  return (
    <div className="DesignStatus-Wrapper">
      <InProgressIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignService.designInProgress.context')}</div>
      <p className="DesignStatus-Timeframe">
        {t('DesignService.designInProgress.timeframe')}{' '}
        <span className="DesignStatus-Value">{processingTimeEstimate}</span>
      </p>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
        hideCancelButton={true}
      />
    </div>
  );
};

export default DesignInProgress;
