import { useTranslation } from 'react-i18next';

import EditChecklistSection from 'screens/Account/AccountCompanyInfo/EditChecklistSection/EditChecklistSection';

import { VideoPlayer } from 'components';
import { ExpandableQRCode } from 'components/QRCode';
import { Container, ManageContainer, NoChecklists, TopSection } from './components';

const VIDEO_URL = `https://scanifly-prod-general.s3.us-east-1.amazonaws.com/assets/using_custom_checklist_for_site_surveys.mp4`;

const ChecklistInfo = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <NoChecklists>{t('Checklists.notFound')}</NoChecklists>
      <TopSection>
        <ManageContainer>
          <EditChecklistSection />
        </ManageContainer>
        <div className={'qr'}>
          <ExpandableQRCode collapsed={false} />
        </div>
      </TopSection>
      <VideoPlayer className={'videoPlayer'} mimeType={'video/mp4'} videoUrl={VIDEO_URL} />
    </Container>
  );
};

export default ChecklistInfo;
