import { createSettingsApi } from '@cpm/settings-ui';

const cpBackEndUrl = process.env.REACT_APP_API;
const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const settingsApi = createSettingsApi({
  accessToken: () => getAccessToken()!,
  portalBackendUrl: cpBackEndUrl!,
  featureFlags: {},
});
