import React, { createContext, DragEvent, useCallback, useMemo, useState } from 'react';

import {
  ChecklistTemplateStatusEnum,
  ConditionalActionEnum,
  DropdownMenuFieldType,
  ExampleMedia,
  FieldTypeEnum,
  FieldTypeUnion,
  ReferenceMedia,
  SectionType,
} from '@cpm/scanifly-shared-data';
import { MediaCategory } from 'types';
import { v4 as uuidv4 } from 'uuid';

interface ChecklistTemplateProviderProps {
  templateId: string;
  children: React.ReactNode;
  companyMediaCategories: MediaCategory[];
  getReferenceMediaAction: (categoryId: string) => Promise<ReferenceMedia[]>;
  referenceMediaCategories: MediaCategory[];
  /**
   * key is referenceMedia category ID
   */
  referenceMediaMap: { [key: string]: ReferenceMedia[] };
  exampleMediaMap: ExampleMedia[];
  getExampleMediaAction: (templateId: string) => Promise<ExampleMedia[]>;
  sections: SectionType[];
  setSections: (newSections: SectionType[]) => void;
  setStatus: (status: ChecklistTemplateStatusEnum) => void;
  setTitle: (title: string) => void;
  status: ChecklistTemplateStatusEnum;
  title: string;
}

interface ChecklistTemplateContextType {
  templateId: string;
  companyMediaCategories: MediaCategory[];
  getReferenceMediaAction: (categoryId: string) => Promise<ReferenceMedia[]>;
  referenceMediaCategories: MediaCategory[];
  referenceMediaMap: { [key: string]: ReferenceMedia[] };
  getExampleMediaAction: (templateId: string) => Promise<ExampleMedia[]>;
  exampleMediaMap: ExampleMedia[];
  sections: SectionType[];
  title: string;
  status: ChecklistTemplateStatusEnum;
  setStatus: (status: ChecklistTemplateStatusEnum) => void;
  editing: string[];
  setEditing: (editingId: string[]) => void;
  addField: (sectionId: string, newField: FieldTypeUnion) => void;
  addConditionalField: ({
    fieldType,
    targetInput,
    action,
    targetFieldId,
    newLabel,
  }: {
    fieldType: FieldTypeEnum;
    targetInput: string;
    action: string;
    targetFieldId: string;
    newLabel: string;
  }) => void;
  updateField: (fieldId: string, updatedField: Partial<FieldTypeUnion>) => void;
  deleteField: (fieldId: string) => void;
  moveField: (sectionId: string, movement: number) => void;
  copyField: (fieldId: string) => void;
  createSection: (newSection: SectionType) => void;
  updateSectionTitle: (sectionId: string, updatedTitle: string) => void;
  updateChecklistTitle: (updatedTitle: string) => void;
  deleteSection: (sectionId: string) => void;
  setActiveSection: (sectionId: string) => void;
  activeSection?: SectionType;
  moveSection: (sectionId: string, movement: number) => void;
  handleDragStart: (index: number) => (event: DragEvent<HTMLDivElement>) => void;
  handleDragOver: (event: DragEvent<HTMLDivElement>) => void;
  handleSectionDrop: (index: number) => (event: DragEvent<HTMLDivElement>) => void;
  handleFieldDrop: (fromIndex: number, toIndex: number, sectionId: string) => void;
  handleFieldDragStart: (index: number) => (event: DragEvent<HTMLLIElement>) => void;
  handleFieldDragOver: (event: DragEvent<HTMLLIElement>) => void;
}

export const ChecklistTemplateContext = createContext<ChecklistTemplateContextType>({
  templateId: '',
  companyMediaCategories: [],
  getReferenceMediaAction: () => Promise.resolve([]),
  referenceMediaCategories: [],
  referenceMediaMap: {},
  exampleMediaMap: [],
  getExampleMediaAction: () => Promise.resolve([]),
  sections: [] as SectionType[],
  title: '',
  status: ChecklistTemplateStatusEnum.unpublished,
  editing: [],
  setEditing: () => {},
  setStatus: () => {},
  addField: () => {},
  addConditionalField: () => {},
  updateField: () => {},
  deleteField: () => {},
  copyField: () => {},
  moveField: () => {},
  createSection: () => {},
  updateSectionTitle: () => {},
  updateChecklistTitle: () => {},
  deleteSection: () => {},
  setActiveSection: () => {},
  moveSection: () => {},
  handleDragStart: () => () => {},
  handleDragOver: () => {},
  handleSectionDrop: () => () => {},
  handleFieldDrop: () => () => {},
  handleFieldDragStart: () => () => {},
  handleFieldDragOver: () => () => {},
});

const ChecklistTemplateProvider: React.FC<ChecklistTemplateProviderProps> = ({
  templateId,
  children,
  companyMediaCategories,
  getReferenceMediaAction,
  referenceMediaCategories,
  referenceMediaMap,
  exampleMediaMap,
  getExampleMediaAction,
  sections,
  setSections,
  setStatus,
  setTitle,
  status,
  title,
}: ChecklistTemplateProviderProps) => {
  const [activeSectionId, setActiveSectionId] = useState<string>();
  const [editing, setEditing] = useState<string[]>([]);

  const addField = useCallback(
    (sectionId: string, newField: FieldTypeUnion) => {
      const updatedSections = JSON.parse(JSON.stringify(sections));
      for (const section of updatedSections) {
        if (section.id === sectionId) {
          if (newField.componentType === FieldTypeEnum.notApplicable) {
            section.components.unshift(newField);
          } else {
            section.components.push(newField);
          }
          setSections(updatedSections);
          return;
        }
      }
    },
    [sections, setSections]
  );

  const addConditionalField = useCallback(
    ({
      fieldType,
      targetInput,
      action,
      targetFieldId,
      newLabel,
    }: {
      fieldType: FieldTypeEnum;
      targetInput: string;
      action: string;
      targetFieldId: string;
      newLabel: string;
    }) => {
      const updatedSections: SectionType[] = JSON.parse(JSON.stringify(sections));
      //generate conditionalComponent
      //need help with typing this.
      let baseField: FieldTypeUnion = {
        id: uuidv4(),
        isRequired: false,
        label: newLabel,
        componentType: fieldType as any,
        value: null,
        conditional: {
          action: action as ConditionalActionEnum,
          isEqual: {
            targetFieldId: targetFieldId,
            targetValue: targetInput,
          },
        },
      };
      const dropdownOrRadio =
        fieldType === FieldTypeEnum.dropDown || fieldType === FieldTypeEnum.radio;

      if (dropdownOrRadio) {
        baseField = { ...baseField, options: ['example option'] } as any as DropdownMenuFieldType;
      }
      const newField: FieldTypeUnion = baseField;

      for (const section of updatedSections) {
        for (let i = 0; i < section.components.length; i++) {
          if (section.components[i].id === targetFieldId) {
            const originalIndex = i;
            section.components.splice(originalIndex + 1, 0, newField);
            setSections(updatedSections);
            return;
          }
        }
      }
    },
    [sections, setSections]
  );

  const updateField = useCallback(
    (fieldId: string, newField: Partial<FieldTypeUnion>) => {
      const updatedSections = JSON.parse(JSON.stringify(sections));
      const updatedField = JSON.parse(JSON.stringify(newField));

      for (const section of updatedSections) {
        for (let i = 0; i < section.components.length; i++) {
          if (section.components[i].id === fieldId) {
            section.components[i] = { ...section.components[i], ...updatedField };
            if (section.components[i]?.conditional?.action === ('' as ConditionalActionEnum)) {
              delete section.components[i].conditional;
            }
            setSections(updatedSections);
            return;
          }
        }
      }
    },
    [sections, setSections]
  );

  const deleteField = useCallback(
    (fieldId: string) => {
      //delete local storage if item is deleted
      if (localStorage.getItem(`${fieldId}-height`)) {
        localStorage.removeItem(`${fieldId}-height`);
      }
      const updatedSections = JSON.parse(JSON.stringify(sections));
      for (const section of updatedSections) {
        section.components = section.components.filter(
          (component: FieldTypeUnion) => component.id !== fieldId
        );
      }
      setSections(updatedSections);
    },
    [sections, setSections]
  );

  const copyField = useCallback(
    (fieldId: string) => {
      const updatedSections = JSON.parse(JSON.stringify(sections));
      for (const section of updatedSections) {
        for (let i = 0; i < section.components.length; i++) {
          if (section.components[i].id === fieldId) {
            const copiedField = {
              ...section.components[i],
              id: uuidv4(),
              exampleMedia: [],
            };
            //copyField just below selected field
            const originalIndex = i;
            section.components.splice(originalIndex + 1, 0, copiedField);
            setSections(updatedSections);
            return;
          }
        }
      }
    },
    [sections, setSections]
  );

  const moveField = useCallback(
    (fieldId: string, movement: number) => {
      const clonedSections: SectionType[] = JSON.parse(JSON.stringify(sections));

      for (let i = 0; i < clonedSections.length; i++) {
        const section = clonedSections[i];
        for (let j = 0; j < section.components.length; j++) {
          if (section.components[j].id === fieldId) {
            const newPosition = j + movement;
            // If the field is at the edge or would go outside of the array bounds, do nothing
            if (newPosition < 0 || newPosition >= section.components.length) {
              return;
            }
            // Remove the field from its current position
            const [field] = section.components.splice(j, 1);
            // Add the field back to the components array at the new position
            section.components.splice(newPosition, 0, field);
            setSections(clonedSections);
            return clonedSections;
          }
        }
      }
    },
    [sections, setSections]
  );

  const createSection = useCallback(
    (newSection: SectionType) => {
      setSections([...sections, newSection]);
    },
    [sections, setSections]
  );

  const updateSectionTitle = useCallback(
    (sectionId: string, updatedTitle: string) => {
      let updatedSections = JSON.parse(JSON.stringify(sections));
      updatedSections = updatedSections.map((section: SectionType) => {
        if (section.id === sectionId) {
          return { ...section, ...{ title: updatedTitle } };
        }
        return section;
      });
      setSections(updatedSections);
    },
    [sections, setSections]
  );
  const updateChecklistTitle = useCallback(
    (updatedTitle: string) => {
      if (updatedTitle === '') {
        return;
      }
      setTitle(updatedTitle);
    },
    [setTitle]
  );

  const deleteSection = useCallback(
    (sectionId: string) => {
      const updatedSections = sections.filter((section) => section.id !== sectionId);
      setSections(updatedSections);
    },
    [sections, setSections]
  );

  const setActiveSection = useCallback((newSectionId: string) => {
    setActiveSectionId(newSectionId);
  }, []);

  const activeSection = useMemo(() => {
    const foundSection = sections.find((section) => section?.id === activeSectionId);
    if (foundSection) {
      return foundSection;
    } else if (sections.length > 0) {
      return sections[0];
    } else {
      return undefined;
    }
  }, [activeSectionId, sections]);

  const moveSection = useCallback(
    (sectionId: string, movement: number) => {
      const sectionIndex = sections.findIndex((section) => section.id === sectionId);
      if (sectionIndex === -1) {
        // Section with the given ID was not found in the array
        return;
      }
      const newIndex = sectionIndex + movement;
      if (newIndex < 0 || newIndex >= sections.length) {
        // Moving the section would result in an index out of bounds
        return;
      }
      // Create a new array to avoid mutating the original array
      const newSections = JSON.parse(JSON.stringify(sections));
      // Remove the section from its current position
      const [section] = newSections.splice(sectionIndex, 1);
      // Insert the section at the new position
      newSections.splice(newIndex, 0, section);
      setSections(newSections);
    },
    [sections, setSections]
  );

  const handleDragStart = useCallback(
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('text/plain', index.toString());
    },
    []
  );

  const handleFieldDragStart = useCallback(
    (index: number) => (event: React.DragEvent<HTMLLIElement>) => {
      event.dataTransfer.setData('text/plain', index.toString());
    },
    []
  );
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);
  const handleFieldDragOver = useCallback((event: React.DragEvent<HTMLLIElement>) => {
    event.preventDefault();
  }, []);
  const handleSectionDrop = useCallback(
    (index: number) => (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const sourceIndex = event.dataTransfer.getData('text/plain');
      const newItems = JSON.parse(JSON.stringify(sections));
      const [removedItem] = newItems.splice(Number(sourceIndex), 1);
      newItems.splice(index, 0, removedItem);
      setSections(newItems);
    },
    [sections, setSections]
  );

  const handleFieldDrop = useCallback(
    (fromIndex: number, toIndex: number, sectionId: string) => {
      const newSections: SectionType[] = JSON.parse(JSON.stringify(sections));
      const section = newSections.find((sec) => sec.id === sectionId);
      if (!section) return;

      const [movedItem] = section.components.splice(fromIndex, 1);
      section.components.splice(toIndex, 0, movedItem);

      setSections(newSections);
    },
    [sections, setSections]
  );

  const contextValue: ChecklistTemplateContextType = useMemo(
    () => ({
      templateId,
      companyMediaCategories,
      getReferenceMediaAction,
      referenceMediaMap,
      referenceMediaCategories,
      exampleMediaMap,
      getExampleMediaAction,
      sections,
      title,
      status,
      editing,
      setEditing,
      setSections,
      setStatus,
      addField,
      addConditionalField,
      updateField,
      deleteField,
      moveField,
      copyField,
      createSection,
      updateSectionTitle,
      updateChecklistTitle,
      deleteSection,
      setActiveSection,
      activeSection,
      moveSection,
      handleDragStart,
      handleDragOver,
      handleSectionDrop,
      handleFieldDrop,
      handleFieldDragStart,
      handleFieldDragOver,
    }),
    [
      templateId,
      companyMediaCategories,
      getReferenceMediaAction,
      referenceMediaMap,
      referenceMediaCategories,
      exampleMediaMap,
      getExampleMediaAction,
      sections,
      title,
      status,
      setStatus,
      setSections,
      addField,
      editing,
      setEditing,
      addConditionalField,
      updateField,
      deleteField,
      moveField,
      copyField,
      createSection,
      updateSectionTitle,
      updateChecklistTitle,
      deleteSection,
      setActiveSection,
      activeSection,
      moveSection,
      handleDragStart,
      handleDragOver,
      handleSectionDrop,
      handleFieldDrop,
      handleFieldDragStart,
      handleFieldDragOver,
    ]
  );

  return (
    <ChecklistTemplateContext.Provider value={contextValue}>
      {children}
    </ChecklistTemplateContext.Provider>
  );
};

export default ChecklistTemplateProvider;
