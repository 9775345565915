import { i18n } from 'helpers/utils/translations';

export const TABLE_TITLES: {
  title: string;
  field: string | null;
}[] = [
  { title: i18n.t('MaintenanceReport.tableColumns.date'), field: 'createdAt' },
  { title: i18n.t('MaintenanceReport.tableColumns.title'), field: 'title' },
  { title: i18n.t('MaintenanceReport.tableColumns.inspector'), field: 'createdBy' },
  { title: i18n.t('MaintenanceReport.tableColumns.actions'), field: null },
];
