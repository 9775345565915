import { useSelector } from 'react-redux';

import AccountWrapper from '../AccountWrapper';
import AccountLoginSecurityCredentials from './AccountLoginSecurityCredentials';
import AccountLoginSecurityError from './AccountLoginSecurityError';

const AccountLoginSecurity = () => {
  const { loginCredentialsUpdateError } = useSelector((state) => state.users);
  return (
    <AccountWrapper>
      <AccountLoginSecurityCredentials />
      {loginCredentialsUpdateError && (
        <AccountLoginSecurityError errorMessage={loginCredentialsUpdateError} />
      )}
    </AccountWrapper>
  );
};

export default AccountLoginSecurity;
