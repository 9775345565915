export const fetchUsersRolesUrl = () => '/users/positions';
export const fetchCurrentUserUrl = () => '/users/current';
export const fetchDesignServiceProviderListUrl = () => '/users/designServiceProviderList';
export const fetchUserUrl = (userId: string) => `/users/${userId}`;
export const fetchCurrentUserAvatarUrl = (token: string | null) =>
  `/users/current/image?base64=true&token=${token}`;
export const updateCurrentUserUrl = () => '/users/current';
export const updateCurrentUserAvatarUrl = () => '/users/current/image';
export const updateUserPermissionsUrl = (id: string) => `/users/${id}/permissions`;
export const updateUserProjectAccessUrl = (id: string) => `/users/${id}/access`;
export const promoteUserToAdminUrl = (id: string) => `/users/${id}/role/admin`;
export const demoteAdminToDefaultUrl = (id: string) => `/users/${id}/role/default`;
export const changePasswordUrl = () => '/users/current/password';
export const deleteUserUrl = (userId: string) => `/users/${userId}`;
export const createUserUrl = () => '/users';
export const updateCurrentEmailUrl = () => '/users/current/email';
export const fetchCompanyUsersUrl = (id: string) => `/users/admin/users/${id}`;
