import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import { projectDesignDeleted, resetSubmittedForm } from 'state/slices/projectDesignsSlice';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { designPropTypes } from 'helpers/props/design';

import './DeleteDesignModal.scss';

const DeleteDesignModal = ({ handleModalClose, isModalVisible, selectedDesign }) => {
  const dispatch = useDispatch();
  const { isRequestSuccessful, isRequestPending } = useSelector((state) => state.projectDesigns);

  useEffect(() => {
    if (isRequestSuccessful) {
      handleModalClose();
      dispatch(resetSubmittedForm());
    }
  }, [dispatch, handleModalClose, isRequestSuccessful]);

  const deleteDesign = () => {
    dispatch(projectDesignDeleted(selectedDesign));
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleModalClose}
      className="ProjectDesigns-Modal"
      {...MODAL_PROPS}
    >
      <div className="DeleteDesignModal-Description">
        Are you sure you want to delete model {selectedDesign.name}?
      </div>
      <div className="DeleteDesignModal-Buttons-Wrapper">
        <Button onClick={handleModalClose} className="Button--White">
          Cancel
        </Button>
        <Button onClick={deleteDesign} className="Button--Red" loading={isRequestPending} autoFocus>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

DeleteDesignModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  selectedDesign: designPropTypes.isRequired,
};

export default DeleteDesignModal;
