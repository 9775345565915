import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import User from 'types/User';

import {
  changePassword,
  createUser,
  deleteUser,
  demoteAdminToDefault,
  fetchCompanyUsers,
  fetchCurrentUser,
  fetchCurrentUserAvatar,
  fetchDesignServiceProviderList,
  fetchUsersRoles,
  promoteUserToAdmin,
  updateCurrentEmail,
  updateCurrentUser,
  updateCurrentUserAvatar,
  updateUserPermissions,
  updateUserProjectAccess,
} from 'api/users/usersService';

import { USER_ALREADY_EXISTS_ERROR } from 'helpers/constants/errors';

import { adminCompanyUsersRequested } from './admin/adminCompanyUsersSlice';
import { adminUsersRequested } from './admin/adminUsersSlice';
import { loginRequested } from './authSlice';
import {
  companyLogoRequested,
  companyMembersRequested,
  userCompanyRequested,
} from './companySlice/companySlice';

type CurrentUserData = {
  firstName: string;
  lastName: string;
  role: string;
  phoneNumber: string;
};
const formatCurrentUserData = ({ firstName, lastName, role, phoneNumber }: CurrentUserData) => ({
  firstName,
  lastName,
  position: role,
  phone: phoneNumber,
});

const name = 'users';

type UserState = {
  currentUser: User | null;
  designServiceProviders: User[] | null;
  currentUserAvatar: any;
  isUserLoading: boolean;
  isDesignServiceProviderLoading: boolean;
  userRoles: string[];
  isLoading: boolean;
  error: string | null | undefined;
  isSuccess: boolean;
  userExistsError: string | null;
  isFirstAdminUserCreated: boolean;
  loginCredentialsUpdateError: string | null;
  loginCredentialsUpdateSuccess: boolean;
  selectedCompanyUsers: User | null;
};

export const companyUsersRequested = createAsyncThunk(
  `${name}/companyUsersRequested`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyUsers(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const currentUserRequested = createAsyncThunk(
  `${name}/currentUserRequested`,
  // eslint-disable-next-line no-unused-vars
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetchCurrentUser();
      const currentUser = response.data;
      dispatch(userCompanyRequested());
      dispatch(currentUserAvatarRequested());
      dispatch(companyLogoRequested());
      return currentUser;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const designServiceProvidersRequested = createAsyncThunk(
  `${name}/designServiceProvidersRequested`,
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchDesignServiceProviderList();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRolesRequested = createAsyncThunk(
  `${name}/userRolesRequested`,
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchUsersRoles();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userCreationRequested = createAsyncThunk(
  `${name}/userCreationRequested`,
  async ({ user, email, token }: any, { dispatch, rejectWithValue }) => {
    try {
      await createUser(user, token);
      const { password } = user;
      dispatch(loginRequested({ email, password }));
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const firstAdminUserCreationRequested = createAsyncThunk(
  `${name}/firstAdminUserCreationRequested`,
  async (
    {
      user,
      token,
    }: {
      user: any;
      token: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await createUser(user, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userDeletionRequested = createAsyncThunk(
  `${name}/userDeletionRequested`,
  async (
    {
      userId,
      firstName,
      lastName,
      onDeletionSuccess,
    }: {
      userId: string;
      firstName: string;
      lastName: string;
      onDeletionSuccess: (firstName: string, lastName: string) => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await deleteUser(userId);
      dispatch(companyMembersRequested());
      onDeletionSuccess(firstName, lastName);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const passwordChangeRequested = createAsyncThunk(
  `${name}/passwordChangeRequested`,
  async (
    {
      password,
      token,
    }: {
      password: string;
      token: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await changePassword(password, token);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const currentUserUpdated = createAsyncThunk(
  `${name}/currentUserUpdated`,
  async (currentUserData: CurrentUserData, { rejectWithValue }) => {
    try {
      const response = await updateCurrentUser(formatCurrentUserData(currentUserData));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const currentUserAvatarUpdated = createAsyncThunk(
  `${name}/currentUserAvatarUpdated`,
  async (avatar: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCurrentUserAvatar(avatar);
      dispatch(currentUserAvatarRequested());
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const currentUserAvatarRequested = createAsyncThunk(
  `${name}/currentUserAvatarRequested`,
  // eslint-disable-next-line no-unused-vars
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetchCurrentUserAvatar(token);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userPermissionsUpdated = createAsyncThunk(
  `${name}/userPermissionsUpdated`,
  async (
    {
      id,
      permissions,
    }: {
      id: string;
      permissions: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updateUserPermissions(id, { permissions: permissions });

      dispatch(companyMembersRequested());
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userProjectsAccessUpdated = createAsyncThunk(
  `${name}/userProjectsAccessUpdated`,
  async (
    {
      userId,
      projectAccess,
    }: {
      userId: string;
      projectAccess: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updateUserProjectAccess(userId, projectAccess);
      dispatch(companyMembersRequested());
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userAdminStatusChanged = createAsyncThunk(
  `${name}/userAdminStatusChanged `,
  async (
    {
      isAdminPromotion,
      id,
      companyId,
      permissions,
      projectAccess,
      isScaniflyAdmin,
    }: {
      isAdminPromotion: boolean;
      id: string;
      companyId: string;
      permissions: any;
      projectAccess: any;
      isScaniflyAdmin: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (isAdminPromotion) {
        await promoteUserToAdmin(id);
      } else {
        await demoteAdminToDefault(id);
      }

      await updateUserPermissions(id, { permissions: permissions });
      await updateUserProjectAccess(id, projectAccess);

      dispatch(companyMembersRequested());

      if (isScaniflyAdmin) {
        dispatch(adminUsersRequested());

        if (companyId) {
          dispatch(adminCompanyUsersRequested(companyId));
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginCredentialsUpdated = createAsyncThunk(
  `${name}/loginCredentialsUpdated`,
  async (
    {
      email = null,
      password = null,
      token,
    }: {
      email: string | null;
      password: string | null;
      token?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await Promise.all([
        email ? updateCurrentEmail(email) : Promise.resolve(undefined),
        password ? changePassword(password, token) : Promise.resolve(undefined),
      ]);
      return email;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const usersSlice = createSlice({
  name,
  initialState: {
    currentUser: null,
    designServiceProviders: null,
    currentUserAvatar: null,
    isUserLoading: true,
    isDesignServiceProviderLoading: false,
    userRoles: [],
    isLoading: false,
    error: null,
    isSuccess: false,
    userExistsError: null,
    isFirstAdminUserCreated: false,
    loginCredentialsUpdateError: null,
    loginCredentialsUpdateSuccess: false,
    selectedCompanyUsers: [],
  } as UserState,
  reducers: {
    resetFormSubmitted: (state) => {
      state.isSuccess = false;
    },
    resetLoginCredentialsUpdateSuccess: (state) => {
      state.loginCredentialsUpdateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyUsersRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyUsersRequested.fulfilled, (state, { payload }) => {
      state.selectedCompanyUsers = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(companyUsersRequested.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.isLoading = false;
    });
    builder.addCase(currentUserRequested.pending, (state) => {
      state.isUserLoading = true;
    });
    builder.addCase(currentUserRequested.fulfilled, (state, { payload }) => {
      state.currentUser = payload;
      state.error = null;
      state.isUserLoading = false;
    });
    builder.addCase(currentUserRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isUserLoading = false;
    });
    builder.addCase(designServiceProvidersRequested.pending, (state) => {
      state.isDesignServiceProviderLoading = true;
    });
    builder.addCase(designServiceProvidersRequested.fulfilled, (state, { payload }) => {
      state.designServiceProviders = payload;
      state.error = null;
      state.isDesignServiceProviderLoading = false;
    });
    builder.addCase(designServiceProvidersRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isDesignServiceProviderLoading = false;
    });
    builder.addCase(currentUserAvatarRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(currentUserAvatarRequested.fulfilled, (state, { payload }) => {
      state.currentUserAvatar = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(currentUserAvatarRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isLoading = false;
    });
    builder.addCase(userRolesRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userRolesRequested.fulfilled, (state, { payload }) => {
      state.userRoles = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userRolesRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isLoading = false;
    });

    builder.addCase(currentUserUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(currentUserUpdated.fulfilled, (state, { payload }) => {
      state.error = null;
      state.currentUser = payload;
      state.isLoading = false;
    });
    builder.addCase(currentUserUpdated.rejected, (state, { error }) => {
      state.error = error.message as any;
      state.isLoading = false;
    });

    builder.addCase(userAdminStatusChanged.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userAdminStatusChanged.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userAdminStatusChanged.rejected, (state, { error }) => {
      state.error = error.message as any;
      state.isLoading = false;
    });

    builder.addCase(passwordChangeRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(passwordChangeRequested.fulfilled, (state) => {
      state.error = null;
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(passwordChangeRequested.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.isLoading = false;
    });

    builder.addCase(userPermissionsUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userPermissionsUpdated.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userPermissionsUpdated.rejected, (state, { error }) => {
      state.error = error.message as any;
      state.isLoading = false;
    });

    builder.addCase(userProjectsAccessUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userProjectsAccessUpdated.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userProjectsAccessUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });

    builder.addCase(userCreationRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userCreationRequested.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(userCreationRequested.rejected, (state, { payload }: { payload: any }) => {
      if (USER_ALREADY_EXISTS_ERROR.every((i) => payload.message.includes(i))) {
        state.userExistsError = payload.message;
      } else {
        state.error = payload.message;
      }
      state.isLoading = false;
    });

    builder.addCase(firstAdminUserCreationRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(firstAdminUserCreationRequested.fulfilled, (state) => {
      state.isFirstAdminUserCreated = true;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(
      firstAdminUserCreationRequested.rejected,
      (state, { payload }: { payload: any }) => {
        if (USER_ALREADY_EXISTS_ERROR.every((i) => payload.message.includes(i))) {
          state.userExistsError = payload.message;
        } else {
          state.error = payload.message;
        }
        state.isLoading = false;
      }
    );
    builder.addCase(loginCredentialsUpdated.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginCredentialsUpdated.fulfilled, (state, { payload }) => {
      state.loginCredentialsUpdateError = null;
      state.loginCredentialsUpdateSuccess = true;
      state.isLoading = false;
      if (payload) {
        state.currentUser = { ...state.currentUser, email: payload };
      }
    });
    builder.addCase(loginCredentialsUpdated.rejected, (state, { payload }: { payload: any }) => {
      state.loginCredentialsUpdateError = payload.message;
      state.loginCredentialsUpdateSuccess = false;
      state.isLoading = false;
    });
  },
});

export const { resetFormSubmitted, resetLoginCredentialsUpdateSuccess } = usersSlice.actions;

export default usersSlice.reducer;
