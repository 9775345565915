import { ChecklistTemplateType } from '@cpm/scanifly-shared-data';

import API from '../config';
import {
  createChecklistTemplateUrl,
  createChecklistUrl,
  deleteChecklistTemplateUrl,
  fetchAllChecklistsUrl,
  fetchChecklistUrl,
  getChecklistTemplatesUrl,
  getChecklistTemplateUrl,
  importSiteCaptureTemplateUrl,
  resetChecklistTemplateUrl,
  updateChecklistTemplateUrl,
} from './checklistsUrls';

export const fetchChecklist = (projectId: string, checklistId: string) =>
  API.get(fetchChecklistUrl(projectId, checklistId));
export const createChecklist = () => API.post(createChecklistUrl());
export const fetchAllChecklists = (projectId: string) => API.get(fetchAllChecklistsUrl(projectId));
export const getChecklistTemplate = (templateId: string) =>
  API.get(getChecklistTemplateUrl(templateId));
export const getChecklistTemplates = ({
  withUnpublished,
  companyId,
}: {
  withUnpublished: boolean;
  companyId?: string;
}) => API.get(getChecklistTemplatesUrl({ withUnpublished, companyId }));
export const updateChecklistTemplate = (templateId: string, template: ChecklistTemplateType) =>
  API.put(updateChecklistTemplateUrl(templateId), template);
export const resetChecklistTemplate = (templateId: string) =>
  API.put(resetChecklistTemplateUrl(templateId));
export const deleteChecklistTemplate = (templateId: string) =>
  API.delete(deleteChecklistTemplateUrl(templateId));
export const createChecklistTemplate = (
  template: Omit<ChecklistTemplateType, 'id' | 'createdAt' | 'updatedAt' | 'companyId'>,
  companyId?: string
) => API.post(createChecklistTemplateUrl(companyId), template);
export const importSiteCaptureTemplate = (template: any, companyId?: string) =>
  API.post(importSiteCaptureTemplateUrl(companyId), template);
