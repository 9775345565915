import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionalActionEnum, FieldTypeEnum } from '@cpm/scanifly-shared-data';

import styled from 'styled-components';

import { Dropdown, ErrorDisplay } from 'components';

import colors from 'helpers/constants/colors';
import hexToRgbA from 'helpers/utils/hexToRgbA';

type ModalProps = {
  title: string;
  description: string;
  placeholderText?: string;
  defaultValue?: string;
  actionButtonLabel: string;
  actionButtonOnClick: ({
    fieldType,
    targetInput,
    action,
    newLabel,
  }: {
    fieldType: string;
    targetInput: string;
    action: string;
    newLabel: string;
  }) => void;
  onCancel?: () => void;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
};
const Row = styled.div`
  display: flex;
  flex-direction: Row;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = styled.div`
  background: ${colors.white};
  padding: 3rem;
  width: 40%;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  min-width: 50rem;
`;

const Title = styled.h1`
  font-size: 1.4em;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1em;
`;

const Label = styled.label`
  display: inline-block;
  width: 50%;
  padding: 1rem;
  font-size: 1em;
  font-weight: 500;
  color: ${colors.black};
`;

const Input = styled.input`
  display: inline-block;
  width: 50%;
  border: 1px solid #ccc;
  padding: 0.5rem;
  margin-bottom: 0.7rem;
  border-radius: 0.5rem;
  border: 1px solid ${colors.lightGray};

  ::placeholder {
    color: ${colors.midGray};
  }

  :focus {
    outline: none;
    border-color: ${colors.mainBlue};
    box-shadow: 0 0 0 1px ${hexToRgbA(colors.lightBlue, 0.5)};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 3rem;
`;

const CommonButton = styled.button`
  margin-left: 2rem;
  border-radius: 0.8rem;
  border: 1px solid ${colors.lightGray};
  padding: 0.5rem 2rem;
  cursor: pointer;
`;

const CancelButton = styled(CommonButton)`
  background: ${colors.white};
`;

const ActionButton = styled(CommonButton)`
  background: ${colors.mainBlue};
  color: ${colors.white};

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.gray};
    cursor: not-allowed;
  }
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  margin-right: 5px;
`;
const RadioLabel = styled.label`
  align-self: center;
  margin-right: 6rem;
  font-size: 1em;
  margin-top: 2px;
`;

const ConditionalModal: React.FC<ModalProps> = ({
  title,
  description,
  placeholderText,
  defaultValue,
  actionButtonOnClick,
  actionButtonLabel,
  onCancel,
  isVisible,
  setIsVisible,
}: ModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<any | undefined>();
  const [action, setAction] = useState<ConditionalActionEnum>(ConditionalActionEnum.display);
  const [fieldType, setFieldType] = useState<FieldTypeEnum | undefined>(undefined);
  const [newLabel, setNewLabel] = useState<string>('');

  const { t } = useTranslation();

  // components that are not yet supported from displaying
  const componentOrder = [
    FieldTypeEnum.textInput,
    FieldTypeEnum.dropDown,
    FieldTypeEnum.checkMark,
    FieldTypeEnum.radio,
    FieldTypeEnum.media,
    FieldTypeEnum.timePicker,
    FieldTypeEnum.datePicker,
  ];

  const fieldTypeTitles = componentOrder.map((component) => t(`Checklists.${component}`));

  const handleActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as ConditionalActionEnum);
  };
  const handleFieldTypeChange = (selectedOptionString: string) => {
    if (selectedOptionString in FieldTypeEnum) {
      setFieldType(FieldTypeEnum[selectedOptionString as keyof typeof FieldTypeEnum]);
    } else {
      setError(new Error(`Invalid field type option: ${selectedOptionString}`));
    }
  };

  useEffect(() => {
    setInputValue(defaultValue ?? '');
  }, [defaultValue]);

  const handleClose = () => {
    setError(undefined);
    setIsVisible(false);
    setInputValue(defaultValue ?? '');
    setNewLabel('');
    onCancel && onCancel();
  };

  const handleButtonClick = async () => {
    try {
      if (fieldType !== undefined) {
        actionButtonOnClick({ fieldType, targetInput: inputValue, action, newLabel });
        setInputValue('');
        setAction(ConditionalActionEnum.display);
        setFieldType(undefined);
        setNewLabel('');
        handleClose();
      } else {
        setError(new Error('Field Type must be selected.'));
      }
    } catch (e: any) {
      setError(e);
    }
  };

  const actionButtonDisabled = useMemo(() => {
    const trimmedInput = inputValue.trim();
    const trimmedLabel = newLabel.trim();
    return (trimmedInput.length < 1 || trimmedLabel.length < 1) && fieldType !== undefined;
  }, [inputValue, newLabel, fieldType]);

  return isVisible ? (
    <Overlay>
      <Modal>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Row>
          <Label>{t('Checklists.ifInputEquals')}</Label>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholderText}
          />
        </Row>
        <Row>
          <Label>{t('Checklists.createNewField')}</Label>
          <Dropdown
            value={fieldType ? t(`Checklists.${fieldType}`) : 'Select a Field Type'}
            options={componentOrder}
            displayedOptions={fieldTypeTitles}
            onChange={handleFieldTypeChange}
          />
        </Row>

        {error && <ErrorDisplay error={error} />}
        <Row>
          <Label>{t('Checklists.newFieldShould')}</Label>
          <RadioInput
            id="display"
            value={ConditionalActionEnum.display}
            checked={action === ConditionalActionEnum.display}
            onChange={handleActionChange}
          />
          <RadioLabel htmlFor="display">{t('Checklists.display')}</RadioLabel>
          <RadioInput
            id="hide"
            value={ConditionalActionEnum.hide}
            checked={action === ConditionalActionEnum.hide}
            onChange={handleActionChange}
          />
          <RadioLabel htmlFor="hide">{t('Checklists.hide')}</RadioLabel>
        </Row>
        <Row>
          <Label>{t('Checklists.newFieldLabel')}</Label>
          <Input
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
            placeholder={placeholderText}
          />
        </Row>

        <ButtonGroup>
          <CancelButton onClick={handleClose}>{t('Checklists.cancel')}</CancelButton>
          <ActionButton disabled={actionButtonDisabled} onClick={handleButtonClick}>
            {actionButtonLabel}
          </ActionButton>
        </ButtonGroup>
      </Modal>
    </Overlay>
  ) : null;
};

export default ConditionalModal;
