import { responseStatus } from 'helpers/constants/responseStatus';

export const errorMessage = `Oops! Something went wrong. Please try again.`;
export const successMessage = (credit) => `${credit} credits have been added to your account.`;

export const getNotificationMessage = (creditsDebitedIntoAccount, response) => {
  if (
    response === responseStatus.success &&
    creditsDebitedIntoAccount &&
    typeof creditsDebitedIntoAccount === 'number'
  ) {
    return successMessage(creditsDebitedIntoAccount);
  }
  return errorMessage;
};
