import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import { startCase } from 'lodash';
import ServiceRequest from 'types/ServiceRequest';

import { fetchCategoryMedias } from 'api/medias/mediasService';

import { openErrorNotification } from 'components/ZipButton/helpers';

import {
  designServicesCompanyQueueRoute,
  designServicesProviderQueueRoute,
  scaniflyAdminDesignServicesQueueRoute,
} from 'helpers/constants/routes';

const ViewFormButton = ({
  item,
  handleModalOpen,
  setFormData,
  setProjectName,
}: {
  item: ServiceRequest & { project: { id: string; name: string } };
  handleModalOpen: () => void;
  setFormData: Dispatch<SetStateAction<{}>>;
  setProjectName: Dispatch<SetStateAction<string>>;
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { tierLevel, modelRevision, form, project } = item;
  const isWireframe = tierLevel === TIER_LEVEL.wireframe;
  const lastRevisionRequest = Array.isArray(modelRevision)
    ? modelRevision?.[modelRevision?.length - 1]
    : modelRevision;

  const isDesignServicesQueueRoute =
    location.pathname === designServicesProviderQueueRoute() ||
    location.pathname === designServicesCompanyQueueRoute() ||
    location.pathname === scaniflyAdminDesignServicesQueueRoute();

  const mediaCategoryName = isDesignServicesQueueRoute
    ? `${startCase(tierLevel)} Form Files`
    : null;

  const mediaCategoryNameForNewBuildPlans = isDesignServicesQueueRoute
    ? t('DesignService.newBuildAlbumName')
    : null;

  const modelRevisionToDisplay = lastRevisionRequest
    ? `${lastRevisionRequest?.reasons?.join('\n')}${
        lastRevisionRequest?.note ? '\n' + lastRevisionRequest?.note : ''
      }`
    : '';

  const modelRevisionReasons = modelRevision
    ? {
        modelRevision: modelRevisionToDisplay?.length
          ? modelRevisionToDisplay
          : t('DesignService.designServiceQueue.viewFormModal.noRevisionData'),
      }
    : {};

  const handleClick = async () => {
    if (mediaCategoryName && mediaCategoryNameForNewBuildPlans && project.id) {
      const newPlanMediaData = await fetchCategoryMedias(
        project?.id,
        mediaCategoryNameForNewBuildPlans
      );
      fetchCategoryMedias(project?.id, mediaCategoryName)
        .then(({ data }) => {
          setFormData(
            form
              ? {
                  ...form,
                  uploads: [...data, ...newPlanMediaData.data],
                  ...modelRevisionReasons,
                }
              : {}
          );
          setProjectName(project ? project.name : '--');
          handleModalOpen();
        })
        .catch(() => {
          openErrorNotification();
        });
    }
  };

  return (
    <Button
      className="Button--White Accounting-Button"
      onClick={handleClick}
      disabled={isWireframe}
      aria-disabled={isWireframe}
      data-testid="view-form-button"
    >
      View Form
    </Button>
  );
};

export default ViewFormButton;
