import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadAppIcon from 'assets/download-app-store.svg';

import './QRCodeWrapper.scss';

const CollapsedView: React.FC = () => {
  const { t } = useTranslation();
  const altText = t('QRCode.altText');

  return (
    <section className="QRCode-MainWrapper">
      <div className="QRCode-QRAndDownloadAppIcon">
        <div className="QRCode-DownloadAppWrapper">
          <img src={DownloadAppIcon} alt={altText} className="QRCode-DownloadAppIcon" />
        </div>
      </div>
      <div className="QRCode-Note">{t('QRCode.note')}</div>
    </section>
  );
};

export default CollapsedView;
