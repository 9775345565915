/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { swapComment } from 'helpers/utils/mention';

import './CommentParagraph.css';

const CommentParagraph = ({ comment }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (comment !== '') {
      const replaceText = swapComment(comment);
      setText(replaceText);
    }
  }, [comment]);

  return (
    <p
      className="comments"
      dangerouslySetInnerHTML={{
        __html: text.replace(/\n\r?/g, '<br />'),
      }}
    />
  );
};
export default CommentParagraph;
