import { useEffect, useMemo, useState } from 'react';
import { ColorResult, GithubPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, Dropdown, Input, Modal } from 'antd';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { Folder } from 'types';

import {
  createFolderRequested,
  deleteFolderRequested,
  foldersRequested,
  resetRequestsCount,
  updateFolderColorRequested,
  updateFolderNameRequested,
} from 'state/slices/foldersSlice';
import { AppDispatch, RootState } from 'state/store/store';

import colors from 'helpers/constants/colors';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-icon.svg';

import FolderModal from './FolderModal';
import './Folders.scss';
import { FormFields, FormValues, InitialValues } from './types';
import { ValidationSchema } from './validationSchema';

const Folders = () => {
  const {
    folders,
    requestsCount,
    successfulRequestsCount,
    errors: requestErrors,
  } = useSelector((state: RootState) => state.folders);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const [tempFolderList, setTempFolderList] = useState(folders);
  const [modalType, setModalType] = useState<'Rename' | 'Remove' | null>(null);
  const [folderToEdit, setFolderToEdit] = useState<Folder>();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [pickedColor, setPickedColor] = useState<colors>(colors.mainBlue);

  const validationSchema = useMemo(() => ValidationSchema(tempFolderList), [tempFolderList]);

  useEffect(() => {
    if (folders) {
      setTempFolderList(folders);
    }
  }, [folders]);

  useEffect(() => {
    dispatch(foldersRequested());
  }, [dispatch]);

  useEffect(() => {
    if (requestsCount && requestsCount === successfulRequestsCount) {
      dispatch(foldersRequested());
      dispatch(resetRequestsCount());
    }
  }, [dispatch, requestsCount, successfulRequestsCount]);

  useEffect(() => {
    tempFolderList.forEach((folder) => {
      const companyFolder = folders.filter(({ id }) => id === folder.id)[0];
      if (companyFolder) {
        if (folder.color !== companyFolder.color) {
          dispatch(updateFolderColorRequested(folder));
        } else if (folder.name !== companyFolder.name) {
          dispatch(updateFolderNameRequested(folder));
        }
      }
    });
  }, [tempFolderList, folders, dispatch]);

  const handleAddFolder = (
    { folderName }: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    resetForm();
    dispatch(createFolderRequested({ name: folderName, color: pickedColor }));
  };

  const handleDeleteFolder = () => {
    if (folderToEdit) {
      const { id } = folderToEdit;
      dispatch(deleteFolderRequested({ id }));
      setModalType(null);
    }
  };

  const handleRenameFolder = ({ folderName }: FormValues) => {
    setTempFolderList(
      tempFolderList.map((folder) => {
        if (folder.id === folderToEdit?.id) {
          return {
            ...folder,
            name: folderName,
          };
        }
        return folder;
      })
    );
    setModalType(null);
  };

  const handleToggleColorPicker = () => {
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  const colorPickerOnChange = (color: ColorResult, folderId: string | undefined = undefined) => {
    folderId
      ? setTempFolderList(
          tempFolderList.map((folder) => {
            if (folder.id === folderId) {
              return {
                ...folder,
                color: color.hex,
              };
            }
            return folder;
          })
        )
      : setPickedColor(color.hex as colors);

    handleToggleColorPicker();
  };

  const handleModalOpen = (folder: Folder, type: 'Remove' | 'Rename') => {
    setModalType(type);
    setFolderToEdit(folder);
  };

  const handleModalClose = () => {
    setModalType(null);
  };

  const renderFolderList = () => {
    return tempFolderList.map((folder) => {
      return (
        <div
          key={folder.id}
          className="AccountCompanyInfoFolders-FolderList-Folder"
          style={{ backgroundColor: folder.color }}
        >
          {/* @ts-ignore Oh how much I hate ant-d */}
          <Dropdown
            overlay={
              <div
                className={cn('AccountCompanyInfoFolders-FolderList-Folder-ColorPicker-Wrapper', {
                  'AccountCompanyInfoFolders-ColorPicker--Hidden': isColorPickerOpen,
                })}
              >
                <GithubPicker onChange={(color) => colorPickerOnChange(color, folder.id)} />
              </div>
            }
          >
            <FolderIcon
              className="AccountCompanyInfoFolders-FolderList-Folder-Icon"
              onClick={handleToggleColorPicker}
              aria-label={t('CompanyInfo.changeFolderInfo') as string}
            />
          </Dropdown>
          <span className="AccountCompanyInfoFolders-FolderList-Folder-TagText">{folder.name}</span>
          <PencilIcon
            className="AccountCompanyInfoFolders-FolderList-Folder-Icon"
            onClick={() => handleModalOpen(folder, 'Rename')}
            aria-label={t('CompanyInfo.renameFolder') as string}
          />
          <XIcon
            className="AccountCompanyInfoFolders-FolderList-Folder-Icon"
            onClick={() => handleModalOpen(folder, 'Remove')}
            aria-label={t('CompanyInfo.removeFolder') as string}
          />
        </div>
      );
    });
  };

  const renderColorPicker = () => {
    return (
      /* @ts-ignore ant-d garbage */
      <Dropdown
        overlay={
          <div
            className={cn('AccountCompanyInfoFolders-ColorPicker-Wrapper', {
              'AccountCompanyInfoFolders-ColorPicker--Hidden': isColorPickerOpen,
            })}
          >
            <GithubPicker triangle="top-left" onChange={(color) => colorPickerOnChange(color)} />
          </div>
        }
      >
        <button
          className="AccountCompanyInfoFolders-ColorPicker"
          aria-label={t('CompanyInfo.colorPicker') as string}
          onClick={handleToggleColorPicker}
          style={{ backgroundColor: pickedColor }}
        />
      </Dropdown>
    );
  };

  return (
    <>
      {/* @ts-ignore*/}
      <Modal
        visible={modalType !== null}
        onCancel={handleModalClose}
        title={t('CompanyInfo.folderModalTitle', { modalType, name: folderToEdit?.name })}
        {...MODAL_PROPS}
      >
        {folderToEdit ? (
          <FolderModal
            folder={folderToEdit}
            folders={tempFolderList}
            onDelete={handleDeleteFolder}
            onClose={handleModalClose}
            onRename={handleRenameFolder}
            type={modalType}
          />
        ) : null}
      </Modal>
      <Formik<FormValues>
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={handleAddFolder}
        validateOnMount
      >
        {({ dirty, touched, errors, isValid, getFieldProps, handleSubmit }) => (
          <>
            <h2 className="Title">{t('CompanyInfo.companyFolders')}</h2>
            <div className="AccountCompanyInfo-Subtitle">{t('CompanyInfo.groupProjects')}</div>
            <div className="AccountCompanyInfo-Form-Wrapper">
              <div className="FormControl-Wrapper">
                <label className="Label--Big" htmlFor="addFolder">
                  {t('CompanyInfo.addFolder')}
                </label>
                <Input
                  className={`Input--High ${validateStatus(
                    touched,
                    errors,
                    FormFields.folderName,
                    dirty
                  )}`}
                  {...getFieldProps(FormFields.folderName)}
                  placeholder={t('CompanyInfo.enterFolderName') as string}
                  suffix={renderColorPicker()}
                  id="addFolder"
                />
                <div className="Form-Error">
                  {renderValidationMessage(touched, errors, FormFields.folderName, dirty)}
                </div>
              </div>
              <Button
                className="Button--Blue AccountCompanyInfo-Form-Button"
                // @ts-ignore
                onClick={handleSubmit}
                disabled={!isValid}
                aria-disabled={!isValid}
              >
                {t('CompanyInfo.addFolder')}
              </Button>
            </div>

            <div className="AccountCompanyInfoFolders-FolderList">{renderFolderList()}</div>

            {Boolean(requestErrors?.length) && (
              <Alert
                className="AccountCompanyInfoFolders-Error"
                type="error"
                message={t('alertMessage.error')}
                closable
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default Folders;
