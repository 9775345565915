import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
  PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';

export const isProjectCompleted = (statusDescription) =>
  statusDescription !== PROJECT_STATUSES.PROCESSING &&
  statusDescription !== PROJECT_STATUSES.UPLOADING &&
  statusDescription !== PROJECT_STATUSES.ERROR &&
  statusDescription !== PROJECT_STATUSES.DRAFT;

export const isErrored = (status) =>
  status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_ERROR || status === ADMIN_PROJECT_STATUSES.ERROR;

export const isDraft = (status) =>
  status === NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED ||
  status === ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED ||
  status === NON_ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED ||
  status === ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED;

export const isProcessing = (status) =>
  status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING ||
  status === ADMIN_PROJECT_STATUSES.PROCESSING;

export const isProcessed = (status) =>
  status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE ||
  status === ADMIN_PROJECT_STATUSES.PROCESSED;
