import { CSV_EXPORT_MESSAGE } from 'screens/ScaniflyAdmin/constants';

import { SUPPORT_URL } from 'helpers/constants/urls';
import { openNotification } from 'helpers/utils/openNotification';

export const handleNotifications = (isError) => {
  openNotification({
    type: isError ? 'error' : 'success',
    title: isError ? 'Error!' : 'Success!',
    text: isError ? (
      <div>
        Something has gone wrong. Please contact
        <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
          {' '}
          Scanifly support
        </a>
        .
      </div>
    ) : (
      CSV_EXPORT_MESSAGE
    ),
  });
};
