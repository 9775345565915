import { mapBoxBaseUrl, mapBoxTokenParam } from './constants';

export default async function forwardGeocode(queryString) {
  try {
    const formattedQueryString = encodeURIComponent(queryString);
    const response = await fetch(
      `${mapBoxBaseUrl}/${formattedQueryString}.json${mapBoxTokenParam}`
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
  return null;
}
