import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import { MONTHS } from './constants';

const ClearDataModal = ({
  modalVisible,
  hideModal,
  handleInputChange,
}: {
  modalVisible: boolean;
  hideModal: () => void;
  handleInputChange: (value: number | string, inputName: string) => void;
}) => {
  const { t } = useTranslation();
  const handleClear = () => {
    Object.values(MONTHS).forEach((month) => {
      handleInputChange('', `usage.${month}`);
    });
    hideModal();
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        visible={modalVisible}
        onCancel={hideModal}
        title={t('ProjectConsumptionInfo.clearModal.title')}
        okText={t('ProjectConsumptionInfo.clearModal.button')}
        onOk={handleClear}
      >
        <p className="CustomerInfo-Modal">{t('ProjectConsumptionInfo.clearModal.text')}</p>
      </Modal>
    </>
  );
};

export default ClearDataModal;
