import styled from 'styled-components';

// 9.4rem is apparently the size of the header bar
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-height: calc(100vh - 9.4rem);
  height: 100%;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;

  .Checklists-PDF-viewer {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    * {
      width: 100%;
    }
  }

  .Spinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export default Container;
