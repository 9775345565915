import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import { BrowserTracing } from '@sentry/tracing';
import 'antd/dist/antd.less';

import { Analytics, environment } from 'helpers/utils';
import history from 'helpers/utils/history';

import './styles/index.scss';

import App from './screens/App';

if (environment.isProductionBuild) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        // maskAllText: true,
        // blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 10,
    environment: environment.env,
    release: process.env.REACT_APP_SENTRY_RELEASE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    /** Sentry truncates the messages to a default of 250 characters.
     * We're setting maxValueLength to increase this to 5000 characters.
     * There is a hard limit of 8192 characters.
     * https://sentry.zendesk.com/hc/en-us/articles/23337524872987-Why-is-the-the-message-in-my-error-being-truncated
     */
    maxValueLength: 5000,
  });

  Analytics.getInstance().initAnalytics();
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Could not locate root container');
}
const root = ReactDOM.createRoot(container);
root.render(<App />);
