import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as DownIcon } from 'assets/icons/chevron-down.svg';

type Props = {
  options: string[];
  displayedOptions?: string[] | undefined;
  value?: string | undefined;
  displayedValue?: string | undefined;
  onChange?: (selectedOption: string) => void | undefined;
  disabled?: boolean;
};

const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.white};
  border-color: ${colors.midGray};
  border-width: 1px;
  border-radius: 0.5rem;
  min-width: 30%;
  height: 4rem;
  padding: 0 2rem;
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${colors.lightGray};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
`;

const StyledDownIcon = styled(DownIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  vertical-align: middle;
  fill: ${colors.lightGray};
  ${DropdownButton}: hover & {
    fill: ${colors.mainBlue};
  }
`;

const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem 0;
  color: ${colors.black};
  &:hover {
    color: ${colors.mainBlue};
    background-color: ${colors.lightGray};
  }
`;

const OptionMenu = styled.div`
  tranform-origin: top bottom;
  position: absolute;
  top: 4rem;
  background-color: ${colors.background};
  overflow: scroll;
  z-index: 20;
  min-width: 30%;
  border: 1px solid ${colors.neutralGray};
  border-radius: 0.5rem;
  height: auto;
  max-height: 20rem;
  justify-content: left;
`;

const Label = styled.label`
  display: block;
  padding: 0.4rem 2rem;
  color: inherit;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Dropdown = ({
  options,
  onChange,
  disabled = false,
  value,
  displayedValue,
  displayedOptions,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!disabled) setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    if (onChange) onChange(option);

    setIsOpen(false);
  };

  const renderOptions = () => {
    const optionsMapped = options.map((option, index) => {
      return (
        <OptionRow onClick={() => handleOptionClick(option)} key={`${option + index}`}>
          <Label>{displayedOptions?.length ? displayedOptions[index] : option}</Label>
        </OptionRow>
      );
    });

    return optionsMapped;
  };
  useEffect(() => {
    const handleClickoutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickoutside);

    return () => {
      document.removeEventListener('mousedown', handleClickoutside);
    };
  }, []);

  return (
    <Wrapper>
      <DropdownButton onClick={toggle}>
        {displayedValue ? displayedValue : value ? value : null}
        <IconWrapper>
          <StyledDownIcon />
        </IconWrapper>
      </DropdownButton>
      {isOpen ? (
        <OptionMenu role="menu" ref={menuRef}>
          {renderOptions()}
        </OptionMenu>
      ) : null}
    </Wrapper>
  );
};

export default Dropdown;
