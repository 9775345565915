import styled from 'styled-components';

import { FormInput, RadioYesNo } from '../components';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';
import { StyledLeftCol, StyledRightCol, Wrapper } from '../styledComponents';
import { ELECTRICAL_INFORMATION_FORM } from './constants';
import FormProps from './props';

const RadioButtonWrapper = styled.div`
  display: flex;
  justify-content: 'space-between';
`;

const ElectricalInfo = ({ getFieldProps, touched, errors, setFieldValue }: FormProps) => {
  return (
    <Wrapper>
      <StyledLeftCol>
        {/* Main breaker rating */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.MAIN_BREAKER_RATING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MAIN_BREAKER_RATING)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.MAIN_BREAKER_RATING.placeholder}
          id={FORM_CONTROLS.MAIN_BREAKER_RATING}
          className={validateStatus(touched, errors, FORM_CONTROLS.MAIN_BREAKER_RATING)}
          errorValidation={renderValidationMessage(
            touched,
            errors,
            FORM_CONTROLS.MAIN_BREAKER_RATING
          )}
        />
        {/* MSP on site */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.MSP_ONSITE.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MSP_ONSITE)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.MSP_ONSITE.placeholder}
          id={FORM_CONTROLS.MSP_ONSITE}
          className={validateStatus(touched, errors, FORM_CONTROLS.MSP_ONSITE)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.MSP_ONSITE)}
        />
        {/* Module info */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.MODULE_INFO.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MODULE_INFO)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.MODULE_INFO.placeholder}
          id={FORM_CONTROLS.MODULE_INFO}
          className={validateStatus(touched, errors, FORM_CONTROLS.MODULE_INFO)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.MODULE_INFO)}
          setFieldValue={setFieldValue}
        />
        {/* battery info */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.BATTERY_INFO.title}
          fieldProps={getFieldProps(FORM_CONTROLS.BATTERY_INFO)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.BATTERY_INFO.placeholder}
          id={FORM_CONTROLS.BATTERY_INFO}
          className={validateStatus(touched, errors, FORM_CONTROLS.BATTERY_INFO)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.BATTERY_INFO)}
        />
        {/* interconnection method */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.INTERCONNECTION_METHOD.title}
          fieldProps={getFieldProps(FORM_CONTROLS.INTERCONNECTION_METHOD)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.INTERCONNECTION_METHOD.placeholder}
          id={FORM_CONTROLS.INTERCONNECTION_METHOD}
          className={validateStatus(touched, errors, FORM_CONTROLS.INTERCONNECTION_METHOD)}
          errorValidation={renderValidationMessage(
            touched,
            errors,
            FORM_CONTROLS.INTERCONNECTION_METHOD
          )}
        />
      </StyledLeftCol>
      <StyledRightCol>
        {/* bus bar rating */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.MAIN_BUSBAR_RATING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MAIN_BUSBAR_RATING)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.MAIN_BUSBAR_RATING.placeholder}
          id={FORM_CONTROLS.MAIN_BUSBAR_RATING}
          className={validateStatus(touched, errors, FORM_CONTROLS.MAIN_BUSBAR_RATING)}
          errorValidation={renderValidationMessage(
            touched,
            errors,
            FORM_CONTROLS.MAIN_BUSBAR_RATING
          )}
        />
        {/* meter location on site */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.METER_LOCATION.title}
          fieldProps={getFieldProps(FORM_CONTROLS.METER_LOCATION)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.METER_LOCATION.placeholder}
          id={FORM_CONTROLS.METER_LOCATION}
          className={validateStatus(touched, errors, FORM_CONTROLS.METER_LOCATION)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.METER_LOCATION)}
        />
        {/* inverter info */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.INVERTER_INFO.title}
          fieldProps={getFieldProps(FORM_CONTROLS.INVERTER_INFO)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.INVERTER_INFO.placeholder}
          id={FORM_CONTROLS.INVERTER_INFO}
          className={validateStatus(touched, errors, FORM_CONTROLS.INVERTER_INFO)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.INVERTER_INFO)}
          setFieldValue={setFieldValue}
        />
        {/* battery quantity */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.BATTERY_COUNT.title}
          fieldProps={getFieldProps(FORM_CONTROLS.BATTERY_COUNT)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.BATTERY_COUNT.placeholder}
          id={FORM_CONTROLS.BATTERY_COUNT}
          className={validateStatus(touched, errors, FORM_CONTROLS.BATTERY_COUNT)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.BATTERY_COUNT)}
        />
        <RadioButtonWrapper>
          {/* pv meter required yes/no */}
          <RadioYesNo
            title={ELECTRICAL_INFORMATION_FORM.PV_REQUIRED.title}
            fieldProps={getFieldProps(FORM_CONTROLS.PV_REQUIRED)}
            id={FORM_CONTROLS.PV_REQUIRED}
          />
          {/* ac disconnect yes/no */}
          <RadioYesNo
            title={ELECTRICAL_INFORMATION_FORM.AC_DISCONNECT.title}
            fieldProps={getFieldProps(FORM_CONTROLS.AC_DISCONNECT)}
            id={FORM_CONTROLS.AC_DISCONNECT}
          />
        </RadioButtonWrapper>
      </StyledRightCol>
    </Wrapper>
  );
};

export default ElectricalInfo;
