import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from 'antd';
import { SidebarContext } from 'screens/Sidebar';

import { useViewportSize } from 'helpers/hooks/useViewportSize';

import { ReactComponent as ShareIcon } from 'assets/icons/share-icon.svg';

import { getLayoutWidth } from '../helpers';
import InfoViewProdConsumChart from './Charts/InfoViewProdConsumChart';
import InfoViewShadeChart from './Charts/InfoViewShadeChart';
import './InfoView.scss';
import { InfoViewProps } from './types';

const InfoView = ({ selectedDesign, shareUrl = '', thumbnailUrl }: InfoViewProps) => {
  const { width } = useViewportSize();
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const { isSidebarCollapsed } = useContext(SidebarContext);

  const monthlyProduction = selectedDesign.monthlyProduction?.map((value) => value / 1000);

  const copyText = (toCopy: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = toCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  const handleCopy = () => {
    copyText(shareUrl);
    setIsCopied(true);
    setTimeout(setIsCopied, 3000, false);
  };

  const contentName = selectedDesign?.name ? selectedDesign?.name : 'New Design';

  return (
    <div className="InfoView">
      <div
        className="InfoView-Wrapper"
        style={{ width: getLayoutWidth(width ?? 0, isSidebarCollapsed) }}
      >
        <div className="InfoView-Buttons">
          <Tooltip
            placement="top"
            title={
              shareUrl.length
                ? isCopied
                  ? t('Designs.copied')
                  : t('Designs.copyToClicpboard')
                : t('Designs.generateLink')
            }
            arrowPointAtCenter
          >
            <Button
              onClick={handleCopy}
              disabled={!shareUrl.length}
              aria-disabled={!shareUrl.length}
            >
              <ShareIcon />
              {t('Designs.shareSystemInfo')}
            </Button>
          </Tooltip>
        </div>
        <div className="InfoView-Content">
          <p className="InfoView-Content-Name">{contentName}</p>
          <div className="InfoView-Content-Thumbnail">
            <img
              className="InfoView-Content-Thumbnail-Image"
              src={selectedDesign.thumbnailUrl || thumbnailUrl}
              alt="Thumbnail"
            />
          </div>
          <div className="InfoView-Content-Shading">
            <span>{t('Designs.shading')}</span>
          </div>
          <div className="InfoView-Content-Graph-Wrapper">
            <InfoViewShadeChart values={selectedDesign.avgMonthlySolarAccess ?? []} />
          </div>
          <div className="InfoView-Content-ProductionConsumption">
            <span>{t('Designs.productionConsumption')}</span>
          </div>
          <div className="InfoView-Content-Graph-Wrapper">
            <InfoViewProdConsumChart values={monthlyProduction ?? []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoView;
