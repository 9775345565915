import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import PropTypes from 'prop-types';

import { adminProjectStatusUpdateRequested } from 'state/slices/admin/adminProjectsSlice';
import { projectRequested } from 'state/slices/projectSlice';

import { CustomSelect } from 'components';

import {
  ADMIN_PROJECTS_TABLES_PROJECT_STATUSES,
  UPLOAD_QUEUE_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';

import './ChangeProjectStatusModal.scss';

const ChangeProjectStatusModal = ({ projectId, handleModalClose, tableName }) => {
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState(null);
  const statusOptions =
    tableName === TABLE_NAMES.CUSTOMER_PROJECTS
      ? ADMIN_PROJECTS_TABLES_PROJECT_STATUSES
      : UPLOAD_QUEUE_PROJECT_STATUSES;

  const { project } = useSelector((state) => state.project);

  useEffect(() => {
    if (!projectId) return;
    dispatch(projectRequested(projectId));
  }, [dispatch, projectId]);

  const handleChangeProjectStatus = () => {
    if (selectedStatus) {
      dispatch(
        adminProjectStatusUpdateRequested({
          projectId,
          targetStatus: selectedStatus,
          tableName,
        })
      );
      handleModalClose();
    }
  };

  return (
    <div className="ChangeProjectStatusModal-Wrapper">
      <div className="ChangeProjectStatusModal-Title">
        {`Change project status for ${project?.name}`}
      </div>
      <div className="ChangeProjectStatusModal-Select-Wrapper">
        <CustomSelect
          placeholder="Select a Status"
          options={
            statusOptions.map((status) => ({
              value: status.value,
              label: status.text,
            })) ?? []
          }
          variant="filter"
          value={selectedStatus}
          onChange={(option) => setSelectedStatus(option.value)}
          tall
        />
      </div>
      <div className="ChangeProjectStatusModal-Buttons-Wrapper">
        <Button className="Button--White" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button className="Button--Blue" onClick={handleChangeProjectStatus}>
          Change Project Status
        </Button>
      </div>
    </div>
  );
};

ChangeProjectStatusModal.propTypes = {
  projectId: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  tableName: PropTypes.string,
};

export default ChangeProjectStatusModal;
