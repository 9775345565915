import { useTranslation } from 'react-i18next';

import { Col, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { ReactComponent as WarningIcon } from 'assets/icons/triangle-exclamation-solid.svg';
import colors from 'helpers/constants/colors';
import styled from 'styled-components';
import { BoldLabelTitle, LabelTitle } from '../styledComponents';
import './ItemsUploadedRadioButtons.scss';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledWarningIcon = styled(WarningIcon)`
  height: 2rem;
  width: auto;
  flex-shrink: 0;
  margin: 0.75rem 1rem 0 0;
`;

const StyledGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  width: 15%;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 3rem;

  .ant-radio-wrapper {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${colors.labelGray};
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
`;

const StyledLabel = styled(LabelTitle)`
  margin-top: 1rem;
`;

const CommercialZone = ({
  handleCommercialZone,
  commercialZone,
}: {
  handleCommercialZone: (e: RadioChangeEvent) => void;
  commercialZone: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledWarningIcon />
      <Col span={24}>
        <BoldLabelTitle>{t('DesignService.commercialZoneTitle')}</BoldLabelTitle>
        <StyledLabel>{t('DesignService.commercialZoneDisclaimer')}</StyledLabel>
        <StyledGroup onChange={handleCommercialZone} value={commercialZone}>
          <Radio value={true} data-testid="radio-button-yes">
            {t('Generic.yes')}
          </Radio>
          <Radio value={false} data-testid="radio-button-no">
            {t('Generic.no')}
          </Radio>
        </StyledGroup>
      </Col>
    </StyledWrapper>
  );
};

export default CommercialZone;
