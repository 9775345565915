import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Card, Modal, Tooltip } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { DropdownMenu, InviteOrChangeTeammatesPermissions, MenuMini } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { teammatePropTypes } from 'helpers/props/teammate';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import UserAvatarIcon from 'assets/user-avatar.svg';

import DeleteTeammateModal from '../DeleteTeammateModal';
import { MODALS } from './consts';
import './Teammate.scss';

const Teammate = ({ teammate, isHorizontal, removeTeammate }) => {
  const { CHANGE_TEAMMATE_PERMISSIONS, CONFIRM_DELETE_TEAMMATE } = MODALS;
  const [isMenuOpen, toggleMenu] = useToggle();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [modalClosedFromOutside, setModalClosedFromOutside] = useState(false);
  const { currentUser } = useSelector((state) => state.users);
  const { company } = useSelector((state) => state.company);
  const { isAdmin, isScaniflyAdmin } = usePermissions();
  const isMe = currentUser?.id === teammate?.id;

  const currentAccessToken = localStorage.getItem('accessToken');

  const confirmDeleteTeammate = () => {
    if (isHorizontal) return removeTeammate(teammate.id);
    setCurrentModal(CONFIRM_DELETE_TEAMMATE);
    setModalVisible(true);
    toggleMenu();
  };

  const changeTeammatesPermissions = () => {
    setCurrentModal(CHANGE_TEAMMATE_PERMISSIONS);
    setModalVisible(true);
    toggleMenu();
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalClosedFromOutside(true);
  };

  const getPhoneNumber = (phone) => {
    if (phone?.startsWith('+1') && phone?.charAt(2) !== ' ') {
      return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    }
    if (phone?.startsWith('(')) {
      return '+1 ' + phone;
    }

    return phone;
  };

  const Dropdown = () => {
    return (
      <>
        {!isHorizontal && (
          <li>
            <button
              onClick={changeTeammatesPermissions}
              className="DropdownMenu-Item"
              data-testid="teammate-edit-permssions"
            >
              <PencilIcon />
              Edit Permissions
            </button>
          </li>
        )}
        <li>
          <button
            onClick={confirmDeleteTeammate}
            className="DropdownMenu-Item"
            data-testid="teammate-remove-permssions"
          >
            <CancelIcon />
            Remove Teammate
          </button>
        </li>
      </>
    );
  };

  return (
    <>
      <Modal
        visible={modalVisible && currentModal === CONFIRM_DELETE_TEAMMATE}
        onCancel={handleModalClose}
        title="Remove account"
        {...MODAL_PROPS}
      >
        <DeleteTeammateModal teammate={teammate} handleModalClose={handleModalClose} />
      </Modal>

      <Modal
        visible={modalVisible && currentModal === CHANGE_TEAMMATE_PERMISSIONS}
        onCancel={handleModalClose}
        {...MODAL_PROPS}
      >
        <InviteOrChangeTeammatesPermissions
          teammate={teammate}
          handleModalClose={handleModalClose}
          setModalClosedFromOutside={setModalClosedFromOutside}
          modalClosedFromOutside={modalClosedFromOutside}
          companyId={company?.id}
        />
      </Modal>
      <Card className={cn('Teammate', { 'Teammate--Horizontal': isHorizontal })}>
        {(isHorizontal || ((isAdmin || isScaniflyAdmin) && !isMe)) && (
          <div className="Teammate-Menu" data-testid="teammate-profile-button-container">
            <MenuMini onClick={toggleMenu} />
            {isMenuOpen && (
              <DropdownMenu toggleMenu={toggleMenu}>
                <Dropdown />
              </DropdownMenu>
            )}
          </div>
        )}
        <Avatar
          alt="Avatar"
          src={
            teammate.imageUrl
              ? `${process.env.REACT_APP_API}/users/${teammate.id}/image?token=${currentAccessToken}`
              : UserAvatarIcon
          }
          className="Teammate-Avatar"
        />
        <div className="Teammate-Name">
          <Tooltip title={`${teammate.firstName} ${teammate.lastName}`}>
            {teammate.firstName} {teammate.lastName}
          </Tooltip>
        </div>
        <div className="Teammate-Role">{teammate.positionDescription}</div>
        <div className="Teammate-AdditionalInfo-Wrapper">
          <div className="Teammate-AdditionalInfo">
            <Tooltip title={teammate.email}>{teammate.email?.split('-').join('‑')}</Tooltip>
          </div>
          <div className="Teammate-AdditionalInfo">{getPhoneNumber(teammate.phone) || null}</div>
        </div>
      </Card>
    </>
  );
};

Teammate.propTypes = {
  teammate: teammatePropTypes.isRequired,
  isHorizontal: PropTypes.bool,
  removeTeammate: PropTypes.func,
};

export default Teammate;
