import { TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { maxFillValidationSchema } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/maxFillValidationSchema';
import { planSetValidationSchema } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/planSetValidationSchema';
import { siteModelingValidationSchema } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/siteModelingValidationSchema';
import { sitePlanValidationSchema } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/sitePlanValidationSchema';
import { trueUpValidationSchema } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/trueUpValidationSchema';
import { trueUpValidationSchemaWithExistingDesign } from '../OrderDesign/DesignSelection/FormWrapper/validationForms/trueUpValidationSchemaWithoutExistingDesign';

export const getValidationSchema = (
  tierLevel: TIER_LEVEL,
  useExistingDesignForTrueUp?: boolean
) => {
  if (tierLevel === TIER_LEVEL.sitePlan) {
    return sitePlanValidationSchema;
  } else if (tierLevel === TIER_LEVEL.trueUp) {
    if (useExistingDesignForTrueUp) {
      return trueUpValidationSchemaWithExistingDesign;
    }
    return trueUpValidationSchema;
  } else if (
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim
  ) {
    return planSetValidationSchema;
  } else if (tierLevel === TIER_LEVEL.siteModeling) {
    return siteModelingValidationSchema;
  } else if (tierLevel === TIER_LEVEL.maxFill) {
    return maxFillValidationSchema;
  }

  return {};
};
