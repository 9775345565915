import { FORM_CONTROLS, FORM_CONTROLS_CREDITS } from './formControls';

export const initialValuesForCC = {
  [FORM_CONTROLS.FIRST_NAME]: '',
  [FORM_CONTROLS.LAST_NAME]: '',
  [FORM_CONTROLS.COUNTRY]: null,
  [FORM_CONTROLS.STREET]: '',
  [FORM_CONTROLS.CITY]: '',
  [FORM_CONTROLS.STATE]: '',
  [FORM_CONTROLS.ZIP_CODE]: '',
};

export const initialValuesForBillingInfo = {
  [FORM_CONTROLS.NAME]: '',
  [FORM_CONTROLS.EMAIL]: '',
  [FORM_CONTROLS.COUNTRY]: null,
  [FORM_CONTROLS.STREET]: '',
  [FORM_CONTROLS.CITY]: '',
  [FORM_CONTROLS.STATE]: '',
  [FORM_CONTROLS.ZIP_CODE]: '',
  [FORM_CONTROLS.VAT_NUMBER]: '',
};

export const initialValuesForCredits = {
  [FORM_CONTROLS_CREDITS.CREDIT_COUNT]: '',
};
