import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchDDSFeedbackReportUrl } from 'api/droneDataScore/droneDataScoreService';

const name = 'droneDataScore';

type StateType = {
  projectId: null | string;
  feedbackReportUrl: null | string;
  DDSPDFReportCreatedAt: null | string;
  isLoading: boolean;
  error?: any;
};

export const DDSFeedbackReportDownloadUrlRequested = createAsyncThunk(
  `${name}/DDSFeedbackReportDownloadUrlRequested`,
  async (
    { projectId, companyId }: { projectId: string; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchDDSFeedbackReportUrl({
        projectId,
        companyId,
      });
      return { data: { ...response.data, projectId } };
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

const droneDataScoreSlice = createSlice({
  name,
  initialState: {
    projectId: null,
    feedbackReportUrl: null,
    DDSPDFReportCreatedAt: null,
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {
    resetState: (state) => {
      state.projectId = null;
      state.feedbackReportUrl = null;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DDSFeedbackReportDownloadUrlRequested.pending, (state: StateType) => {
        state.isLoading = true;
      })
      .addCase(DDSFeedbackReportDownloadUrlRequested.fulfilled, (state: StateType, { payload }) => {
        state.feedbackReportUrl = payload.data.url;
        state.DDSPDFReportCreatedAt = payload.data?.DDSPDFReportCreatedAt;
        state.projectId = payload.data.projectId;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(DDSFeedbackReportDownloadUrlRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export default droneDataScoreSlice.reducer;
