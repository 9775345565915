import { DESIGN_STATUS, DESIGN_STATUS_FILTERS } from '@cpm/scanifly-shared-data';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { snakeCase } from 'lodash';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  background-color: ${colors.white};
  width: fit-content;
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.neutralGray};
  border-radius: 1rem;
  margin-bottom: 1rem;
  font-size: ${fontSizes.small};

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: ${fontSizes.extraSmall};
  }
`;

const StyledWrapper = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  border-right: 1px solid ${colors.neutralGray};

  &:last-of-type {
    border: none;
  }
`;

const StyledTitle = styled.p`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.semiBold};
  margin-right: 1.5rem;
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.xxl}) {
    margin-right: 0.5rem;
    font-size: ${fontSizes.extraSmall};
  }
`;

const StyledSubtitle = styled.p`
  margin-right: 0.5rem;
  margin-bottom: 0;
`;

const LayeredStatisticalContainer = ({
  data,
  title,
}: {
  data: Partial<Record<DESIGN_STATUS, number>> | null;
  title: string;
}) => {
  return (
    <StyledContainer>
      <StyledTitle data-testid={`${snakeCase(title)}`}>{title}:</StyledTitle>
      {DESIGN_STATUS_FILTERS.map((status) => {
        return (
          <StyledWrapper key={status.value}>
            <StyledSubtitle data-testid={status.value}>{status.text}:</StyledSubtitle>
            <div data-testid={`${status.value}Data`}>
              {data ? data[status.value as DESIGN_STATUS] ?? 0 : 0}
            </div>
          </StyledWrapper>
        );
      })}
    </StyledContainer>
  );
};

export default LayeredStatisticalContainer;
