export const getDefaultValue = ({
  isPrelim,
  isRevision,
  areNewBuildPlansRequired,
  filesAlreadyStored,
}: {
  isPrelim: boolean;
  isRevision: boolean;
  areNewBuildPlansRequired: boolean | undefined;
  filesAlreadyStored: boolean | null;
}) => {
  if (isPrelim && !isRevision) {
    return Boolean(areNewBuildPlansRequired);
  }
  if (isRevision) {
    return Boolean(filesAlreadyStored);
  }
  return filesAlreadyStored;
};
