export const ZOOM_BY_PROJECT = {
  small: 17,
  large: 15,
};

export const SCANIFLY_YELLOW = [252, 202, 101, 170];
export const SCANIFLY_GREEN = [39, 226, 164];
export const SCANIFLY_BLUE = [28, 72, 242];
export const SCANIFLY_LIGHT_BLUE = [0, 138, 252];
export const SCANIFLY_GRAY = [0, 0, 0, 50];

export const DRONE_IMAGE_MARKERS = 'Drone image markers';

export const EDIT_BOUNDING_BOX_DEBOUNCE_MS = 100;
