import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

import useToggle from 'helpers/hooks/useToggle';

type ImagePreviewContextProps = {
  canUseImageResizer: boolean;
  width: undefined | number;
  height: number | undefined;
  isHighRes: boolean;
  setWidth: Dispatch<SetStateAction<number | undefined>>;
  setHeight: Dispatch<SetStateAction<number | undefined>>;
  toggleHighRes: () => void;
};

type ImagePreviewProviderProps = {
  children: ReactNode;
  isDynamicImageResizerEnabled: boolean;
};

const imagePreviewContextProps: ImagePreviewContextProps = {
  canUseImageResizer: false,
  width: undefined,
  height: undefined,
  isHighRes: false,
  setHeight: () => {},
  setWidth: () => {},
  toggleHighRes: () => {},
};

export const ImagePreviewContext =
  createContext<ImagePreviewContextProps>(imagePreviewContextProps);

export function ImagePreviewProvider({
  children,
  isDynamicImageResizerEnabled,
}: ImagePreviewProviderProps) {
  // Default to low resolution
  const [isHighRes, toggleHighRes] = useToggle(!isDynamicImageResizerEnabled);
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const canUseImageResizer = useMemo(
    () => isDynamicImageResizerEnabled && !!width && !!height,
    [width, height, isDynamicImageResizerEnabled]
  );

  const value = useMemo(
    () => ({
      canUseImageResizer,
      width,
      height,
      isHighRes,
      setWidth,
      setHeight,
      toggleHighRes,
    }),
    [canUseImageResizer, width, height, isHighRes, toggleHighRes]
  );

  return <ImagePreviewContext.Provider value={value}>{children}</ImagePreviewContext.Provider>;
}

ImagePreviewProvider.Context = ImagePreviewContext;
