import { ReactComponent as DocumentIcon } from 'assets/icons/document-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/red-error-circle-icon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';
import { formatBytesToMB } from 'helpers/utils/formatBytesToMB';
import { IndividualFileUploadProps } from './types';

const IndividualFileUpload = ({
  isFailed,
  fileName,
  uploadProgress,
  uploadComplete,
}: IndividualFileUploadProps) => {
  const { bytesUploaded, bytesTotal } = uploadProgress;

  const progressPercentage = `${(bytesUploaded / bytesTotal) * 100}%`;

  const uploadStatus = () => {
    if (isFailed) {
      return 'Upload Error';
    } else if (bytesUploaded === bytesTotal) {
      return uploadComplete ? 'Uploaded' : 'Uploading...';
    } else {
      return `${formatBytesToMB(bytesUploaded, 0)}/${formatBytesToMB(bytesTotal, 0)}`;
    }
  };

  const indicatorIcon = () => {
    if (isFailed) {
      return <ErrorIcon />;
    } else if (bytesUploaded === bytesTotal && uploadComplete) {
      return <TickIcon />;
    }
  };

  return (
    <div className="file-uploaded">
      <div className="file-uploaded-content">
        <div className="document-icon">
          <DocumentIcon />
        </div>
        <div className="file-data">
          <div className="file-data-labels">
            <div className="file-name">{fileName}</div>
            <div className="bytes-uploaded">{uploadStatus()}</div>
          </div>
          {bytesUploaded > 0 && (
            <div className="file-uploaded-progress-wrapper">
              <div className="file-uploaded-progress" style={{ width: progressPercentage }} />
            </div>
          )}
        </div>
      </div>
      <div className="indicator-icon-wrapper">{indicatorIcon()}</div>
    </div>
  );
};

export default IndividualFileUpload;
