import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as DateIcon } from 'assets/date.svg';
import { ReactComponent as TimeIcon } from 'assets/time.svg';

const Wrapper = styled.div`
  width: 100$;
  position: relative;
  display: flex;
  align-items: center;
`;

const InputField = styled.input`
  width: 100%;
  margin: 1.5rem 0;
  padding: 0.5rem 2.5rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.5rem;
  font-size: ${fontSizes.mediumSmall};
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  padding-top: 5px;
  svg {
    fill: ${colors.midGray};
    height: 15px;
    width: 15px;
  }
`;

type Props = {
  type: 'date' | 'time';
};

const DateTimePicker = ({ type }: Props) => {
  return (
    <Wrapper>
      <InputField disabled placeholder="Select Date" />
      <IconContainer>{type === 'date' ? <DateIcon /> : <TimeIcon />}</IconContainer>
    </Wrapper>
  );
};

export default DateTimePicker;
