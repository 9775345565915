import { CopyOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';
import { handleCopyClick } from '../ScaniflyAdmin/AccessTokens/helpers';
import { DATE_OPTIONS } from '../ScaniflyAdmin/CustomerSupportUpload/constants';
import { TableRecordData } from './AccessTokenDisplay';

interface AccessTokenDetails {
  id: string;
  tokenPrefix: string;
  createdAt: string;
}

interface ExpandedRowRenderProps {
  data: TableDataRecord;
  handleDelete: (params: TableRecordData) => void;
}

export interface TableDataRecord {
  id?: string;
  name?: string;
  companyId?: string;
  title: string;
  dataIndex: string;
  defaultSortOrder?: 'ascend' | 'descend';
  sorter?: (a: Record<string, any>, b: Record<string, any>) => number;
  render?: (value: any) => JSX.Element | number;
  accessToken?: AccessTokenDetails[];
}

export const tableColumnData: () => TableDataRecord[] = () => {
  return [
    {
      title: 'Access Token Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: Record<string, any>, b: Record<string, any>) => a.name.localeCompare(b.name),
    },
  ];
};

export const expandedRowRenderContent = ({ data, handleDelete }: ExpandedRowRenderProps) => {
  const columns: ColumnsType<AccessTokenDetails> = [
    {
      title: '#',
      key: 'index',
      width: '8rem',
      render: (_record, _item, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Token prefix',
      dataIndex: 'tokenPrefix',
      key: 'tokenPrefix',
      width: '15rem',
      render: (tokenPrefix: string) => (
        <div className="AccessToken-TokenWrapper">
          {tokenPrefix}
          <CopyOutlined
            role="button"
            className="AccessToken-ButtonWrapper-CopyIcon"
            onClick={() => handleCopyClick(tokenPrefix)}
            aria-label="copy token prefix"
          />
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '9rem',
      key: 'actions',
      render: (_: any, record: AccessTokenDetails) => (
        <div className="AccessToken-NestedTable--ActionsContainer">
          <Tooltip placement="top" title="Delete access token">
            <Button
              size="small"
              onClick={() =>
                handleDelete({
                  name: data.name,
                  id: record.id,
                  record: record.id,
                })
              }
              className="AccessToken-Button--Red"
              id="TokenDeleteBtn"
              aria-label="delete-access-token"
              data-testid="delete-token"
            >
              <TrashIcon />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={data.accessToken}
      pagination={false}
      className="AccessToken-NestedTable"
    />
  );
};
