import { mapBoxSatelliteStreetsUrl } from './constants';

export default function thumbnail(lat, lng, thumb = false) {
  const zoom = 17.39;
  const bearing = 0;
  const width = thumb ? 300 : 1200;
  const height = thumb ? 200 : 800;

  return `${mapBoxSatelliteStreetsUrl}/${lng},${lat},${zoom},${bearing}/${width}x${height}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
}
