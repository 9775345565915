import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Input } from 'antd';
import { useFormik } from 'formik';

import { resetPasswordRoute } from 'helpers/constants/routes';
import useAuth from 'helpers/hooks/useAuth';
import { renderError } from 'helpers/utils/createErrorMessage';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';
import './Login.scss';
import { validationSchema } from './validationSchema';

const initialValues = {
  [FORM_CONTROLS.EMAIL]: '',
  [FORM_CONTROLS.PASSWORD]: '',
};

const { Password } = Input;

const Login = () => {
  const auth = useAuth();
  const { errorLogin } = useSelector((state) => state.auth);

  const { touched, isValid, dirty, handleSubmit, errors, getFieldProps, setFieldValue } = useFormik(
    {
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        onLogin(values);
      },
    }
  );

  const onLogin = (credentials) => {
    auth.login(credentials);
  };

  useEffect(() => {
    if (errorLogin) {
      setFieldValue(FORM_CONTROLS.PASSWORD, '');
    }
  }, [errorLogin, setFieldValue]);

  const isFormValid = isValid && dirty;

  return (
    <form>
      <div className="FormControl-Wrapper">
        <label htmlFor="loginEmail">Your Email</label>
        <span
          className={`ant-input-affix-wrapper ant-input-password ${validateStatus(
            touched,
            errors,
            FORM_CONTROLS.EMAIL
          )}`}
        >
          <Input
            placeholder="Enter your email address"
            type={FORM_CONTROLS.EMAIL}
            id="loginEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
        </span>
        <div className="Form-Error" data-testid="emailValidationMessage">
          {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
        </div>
      </div>

      <div className="FormControl-Wrapper">
        <label htmlFor="loginPassword">Your Password</label>
        <Password
          placeholder="Enter your password"
          className={validateStatus(touched, errors, FORM_CONTROLS.PASSWORD)}
          id="loginPassword"
          {...getFieldProps(FORM_CONTROLS.PASSWORD)}
        />
        <div className="Form-Error" data-testid="passwordValidationMessage">
          {renderValidationMessage(touched, errors, FORM_CONTROLS.PASSWORD)}
        </div>
      </div>

      <Button
        className="Button--Big Login-Button"
        disabled={!isFormValid}
        aria-disabled={!isFormValid}
        htmlType="submit"
        onClick={handleSubmit}
        data-testid="loginButton"
      >
        Log In
      </Button>

      <div className="Login-ResetPassword-Wrapper">
        <Link className="Link" to={resetPasswordRoute()} data-testid="forgotPasswordButton">
          Forgot your password?
        </Link>
      </div>

      {errorLogin ? renderError(errorLogin.message) : null}
    </form>
  );
};

export default Login;
