import { ROLES } from 'screens/ScaniflyAdmin/constants';
import { User } from 'types';

export const filterTeammates = ({
  userList,
  searchTerm,
}: {
  userList: User[] | null;
  searchTerm: string;
}) =>
  userList?.filter((user) => {
    return [
      user.firstName,
      user.lastName,
      user.email,
      ...(user?.roles ?? []).map((role) => ROLES[role]),
      user.position,
    ].some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()));
  }) ?? [];
