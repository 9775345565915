import { Layer } from 'react-map-gl';

import PropTypes from 'prop-types';

import colors from 'helpers/constants/colors';

import { MAP_STYLES } from './constants';

const Clusters = (mapStyle = MAP_STYLES.satellite) => {
  const color = mapStyle.mapStyle === MAP_STYLES.streets ? colors.mainBlue : colors.lightBlue;

  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'feature',
    filter: ['has', 'point_count'],
    paint: {
      'circle-pitch-alignment': 'map',
      'circle-color': color,
      'circle-radius': ['step', ['get', 'point_count'], 15, 10, 20, 50, 30],
      'circle-opacity': 0.6,
      'circle-stroke-width': 2,
      'circle-stroke-color': color,
    },
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'feature',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Poppins Regular'],
      'text-size': 12.5,
      'text-letter-spacing': 0.08,
      'text-pitch-alignment': 'map',
    },
    paint: {
      'text-color': colors.white,
    },
  };

  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'symbol',
    source: 'feature',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': ['match', ['get', 'selected'], 'true', 'pulsing-dot', 'dot'],
      'icon-allow-overlap': true,
      'icon-pitch-alignment': 'map',
    },
  };

  Clusters.clusterLayerId = clusterLayer.id;
  Clusters.unclusteredPointLayerId = unclusteredPointLayer.id;

  return (
    <>
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </>
  );
};

Clusters.propTypes = {
  mapStyle: PropTypes.string,
};

export default Clusters;
