export const createCompanyUrl = () => '/companies/invitation';
export const addVatUrl = (companyId: string) => `/companies/vat/${companyId}`;
export const fetchUserCompanyUrl = () => '/companies/current';
export const updateCompanyUrl = (companyId: string) => `/companies/${companyId}`;
export const fetchCompanyUrl = (companyId: string) => `/companies/${companyId}`;
export const fetchCompanyByNameUrl = (companyName: string) => `/companies/name/${companyName}`;
export const fetchCompanyClientSecretUrl = () => '/companies/payments';
export const updateCompanyCreditCardInfoUrl = (customerId: string, paymentMethodId: string) =>
  `/companies/payments/${customerId}/cards/${paymentMethodId}`;
export const updateCompanyBillingInfoUrl = (customerId: string) =>
  `/companies/payments/billingInfo/${customerId}`;
export const updateCompanyLogoUrl = () => '/companies/current/image';
export const fetchCompanyLogoUrl = (token: string) =>
  `/companies/current/image?base64=true&token=${token}`;
export const fetchCompanyMembersUrl = () => '/companies/current/members';
export const fetchCompanyBillingInfoUrl = (customerId: string) =>
  `/companies/payments/${customerId}`;
export const fetchCompanySubscriptionInfoUrl = (companyId: string) =>
  `/accounting/current/${companyId}`;
export const fetchAllCompaniesUrl = () => '/companies';
export const fetchDSPCompaniesUrl = () => `/companies/dsp`;
