import { Switch } from 'antd';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import styled from 'styled-components';

export const SwitchWrapper = styled.div`
  margin-bottom: 1.5rem;
  font-size: ${fontSizes.extraSmall};
  color: ${colors.labelGray};
`;

const Option = styled.span`
  margin-left: 1rem;
`;

export const StyledToggle = ({
  checked,
  onChange,
  text,
}: {
  checked: boolean;
  onChange: (e: any) => void;
  text: string;
}) => {
  return (
    <SwitchWrapper>
      <Switch checked={checked} onChange={onChange} aria-labelledby={text} />
      <Option>{text}</Option>
    </SwitchWrapper>
  );
};
