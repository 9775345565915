import { Dispatch } from '@reduxjs/toolkit';

import {
  createProjectCategory as createProjectCategoryApi,
  deleteProjectCategory as deleteProjectCategoryApi,
  fetchProjectCategories,
  updateProjectCategory as updateProjectCategoryApi,
} from 'api/projectCategories/projectCategoriesService';
import { Project } from 'types';
import {
  CREATE_PROJECT_MEDIA_CATEGORY,
  DELETE_PROJECT_MEDIA_CATEGORY,
  GET_PROJECT_MEDIA_CATEGORIES,
  UPDATE_PROJECT_MEDIA_CATEGORY,
} from './constants';
import { actions } from './projectMediaCategoriesSlice';
import { CreateProjectCategoryPayload, UpdateProjectCategoryPayload } from './types';

export const getProjectMediaCategories = (projectId: string) => async (dispatch: Dispatch) => {
  const response = await fetchProjectCategories(projectId);
  if (response.data) {
    dispatch(actions[GET_PROJECT_MEDIA_CATEGORIES](response.data));
  }
  return response.data;
};

export const createProjectCategory =
  ({ projectId, categoryName }: CreateProjectCategoryPayload) =>
  async (dispatch: Dispatch) => {
    const response = await createProjectCategoryApi(projectId, categoryName);
    if (response.data) {
      const newMediaCategory = response.data.mediaCategories?.slice(-1);
      dispatch(actions[CREATE_PROJECT_MEDIA_CATEGORY](newMediaCategory[0]));
    }
    return response.data as Project;
  };

export const deleteProjectCategory =
  ({ projectId, categoryName }: CreateProjectCategoryPayload) =>
  async (dispatch: Dispatch) => {
    const response = await deleteProjectCategoryApi(projectId, categoryName);
    if (response.data) {
      dispatch(actions[DELETE_PROJECT_MEDIA_CATEGORY]({ categoryName }));
    }
    return response.data;
  };

export const updateProjectCategory =
  ({ projectId, categoryName, newCategoryName }: UpdateProjectCategoryPayload) =>
  async (dispatch: Dispatch) => {
    const response = await updateProjectCategoryApi({ projectId, categoryName, newCategoryName });
    if (response.data) {
      dispatch(
        actions[UPDATE_PROJECT_MEDIA_CATEGORY]({
          oldCategoryName: categoryName,
          newCategoryName: newCategoryName,
          project: response.data as Project,
        })
      );
    }
    return response.data;
  };
