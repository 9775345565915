export const scaniflyBillingAdminFilter = (
  isScaniflyBillingAdmin,
  functionReturningDataArray,
  month,
  year
) => {
  if (isScaniflyBillingAdmin) {
    return functionReturningDataArray(month, year);
  }
  return functionReturningDataArray(month, year).filter(
    (item) => item.key !== 'status' && item.key !== 'action'
  );
};
