import { createSelector } from 'reselect';
import { RootState } from 'state/store/store';
import { CREATE_API_KEY, DELETE_API_KEY, GET_USER_API_KEYS } from './constants';
import { ApiKey, ApiRequestActions } from './types';

export const selectApiKeyRequestStatus = (state: RootState, action: ApiRequestActions) =>
  state.apiKeys.requestState[action];

export const selectApiKeyGetStatus = (state: RootState) =>
  state.apiKeys.requestState[GET_USER_API_KEYS];
export const selectApiKeyCreateStatus = (state: RootState) =>
  state.apiKeys.requestState[CREATE_API_KEY];
export const selectApiKeyDeleteStatus = (state: RootState) =>
  state.apiKeys.requestState[DELETE_API_KEY];

export const selectCurrentUser = (state: RootState) => state.users?.currentUser;
export const selectApiKeys = (state: RootState) => state.apiKeys?.apiKeys;
export const selectUserMap = (state: RootState) => state.apiKeys.userMap;

export const selectCurrentUsersApiKeys = createSelector(
  [selectApiKeys, selectUserMap, selectCurrentUser],
  (apiKeys, userMap, user) => {
    if (!user?.id || !userMap || !apiKeys) return [];
    const userKeys = userMap[user.id] ?? [];
    return userKeys.reduce<ApiKey[]>((acc, keyId) => {
      const apiKey = apiKeys[keyId];
      if (apiKey) acc.push(apiKey);
      return acc;
    }, []);
  }
);
