import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';

import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as DataReviewIcon } from 'assets/data-review-icon.svg';

import fontSizes from 'helpers/constants/fontSizes';
import styled from 'styled-components';
import {
  DesignServicesStatusTitle,
  DesignServicesStatusWrapper,
} from '../../DesignSelection/FormWrapper/components';
import ActionButtons from '../ActionButtons/ActionButtons';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSizes.small};
  margin: 1rem 0;

  p {
    margin-bottom: 0;
  }
`;

const StyledIcon = styled(DataReviewIcon)`
  height: 7rem;
  width: auto;
`;

const DataReview = ({
  handleModalOpen,
  status,
  projectStatus,
}: {
  handleModalOpen: () => void;
  status: string;
  projectStatus: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const hideCancelButton =
    [ADMIN_PROJECT_STATUSES.PROCESSING, NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING].includes(
      projectStatus
    ) || status === DESIGN_STATUS.designInProgress;

  return (
    <DesignServicesStatusWrapper>
      <StyledIcon data-testid="data-review-icon" />
      <DesignServicesStatusTitle>{t('DesignStatusDisplay.dataReview')}</DesignServicesStatusTitle>
      <StyledWrapper>
        <p>{t('DesignService.anyQuestions')}</p>
        <p>
          {t('DesignService.learnMore')}{' '}
          <a
            href="https://help.scanifly.com/design-services-design-stages"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('DesignService.pleaseSeeLink')}
          </a>
        </p>
      </StyledWrapper>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
        hideCancelButton={hideCancelButton}
      />
    </DesignServicesStatusWrapper>
  );
};

export default DataReview;
