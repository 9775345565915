import { i18n } from 'helpers/utils/translations';

export const getRadioButtonTitle = ({
  isPrelim = false,
  isRevision = false,
}: {
  isPrelim?: boolean;
  isRevision?: boolean;
}) => {
  if (isRevision) {
    return i18n.t('DesignService.uploadMoreFiles');
  }
  if (isPrelim) {
    return i18n.t('DesignService.areNewBuildPlansRequired');
  }
  return i18n.t('DesignService.planSet.areItemsUploaded.title');
};
