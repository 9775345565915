import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button } from 'components';
import colors from 'helpers/constants/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledServiceGroupTitle } from '../../SelectDesignButton/components';
import { SubtitleWrapper } from '../SelectDesignOptions';

export const StyledHeaderContainer = styled.div<{ isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: ${(p) => (p.isCollapsed ? '0.15rem' : 0)} solid ${colors.lightGray};
  margin-top: 1.5rem;
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpandHeader = ({
  onButtonClick,
  isCollapsed,
  title,
  subTitle,
}: {
  onButtonClick: () => void;
  isCollapsed: boolean;
  title: string;
  subTitle?: string;
}) => {
  const { t } = useTranslation();
  return (
    <StyledHeaderContainer isCollapsed={isCollapsed}>
      <StyledLabelWrapper>
        <StyledServiceGroupTitle>{title}</StyledServiceGroupTitle>
        {subTitle ? (
          <SubtitleWrapper>
            <ExclamationCircleFilled /> {subTitle}
          </SubtitleWrapper>
        ) : null}
      </StyledLabelWrapper>
      <Button
        icon={isCollapsed ? 'expand' : 'collapse'}
        label={isCollapsed ? t(`DesignService.expand`) : t(`DesignService.collapse`)}
        onClick={onButtonClick}
        padding="0.5rem"
        margin="0.75rem 0"
        width="12rem"
        iconSize="1.5rem"
      />
    </StyledHeaderContainer>
  );
};
