import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useLaunchDarkly = () => {
  const { currentUser } = useSelector((state) => state.users);
  const { company: currentUsersCompany } = useSelector((state) => state.company);
  const ldClient = useLDClient();

  useEffect(() => {
    if (currentUser && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: currentUser.id,
        name: [currentUser.firstName, currentUser.lastName].join(' '),
        email: currentUser.email,
        ...(currentUsersCompany
          ? {
              companyId: currentUsersCompany.id,
              companyName: currentUsersCompany.name,
            }
          : {}),
      });
    }
  }, [currentUser, currentUsersCompany, ldClient]);
};

export default useLaunchDarkly;
