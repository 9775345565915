import { EmailLink } from 'components';

import '../SignUp.scss';

const SignUpTokenExpired = () => {
  return (
    <>
      <div className="SignUp-TokenExpired-Subtitle">
        Please contact the Scanifly Customer Success team at <EmailLink />.
      </div>
    </>
  );
};

export default SignUpTokenExpired;
