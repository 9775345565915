import Select from 'react-select';

import PropTypes from 'prop-types';

import colors from 'helpers/constants/colors';

import { customSelectStyles } from './CustomSelect.styles';

const CustomSelect = ({
  error = false,
  options,
  isMulti = false,
  tall = false,
  tagColor = colors.orange,
  variant,
  reset = null,
  disabled = false,
  loading = false,
  id,
  ...passedProps
}) => {
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => passedProps.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === passedProps.value) ?? null;
    } else {
      return isMulti ? [] : '';
    }
  };

  return (
    <Select
      {...passedProps}
      error={error}
      value={reset ? null : getValue()}
      options={options}
      styles={customSelectStyles(tall, variant, tagColor)}
      isMulti={isMulti}
      isLoading={loading || (!disabled && !(options && options.length))}
      aria-disabled={disabled}
      isDisabled={disabled}
      id={id}
    />
  );
};

CustomSelect.propTypes = {
  error: PropTypes.bool,
  options: PropTypes.array,
  tall: PropTypes.bool,
  isMulti: PropTypes.bool,
  tagColor: PropTypes.string,
  reset: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string,
};

export default CustomSelect;
