/**
 * For whatever reason this bucket is not on the Scanifly AWS account so we can't access it
 * with the image resizer endpoint
 */
const BUCKETS_THAT_DONT_WORK = ['scfly-dev-assets'];

/**
 * We have an image-resizer-api lambda function that will resize images to fit device size
 * Allows for us not having to load large images into small screen.
 * More efficient, less memory usage
 * @param s3Url all media is stored in S3 so any URL from backend will work here
 * @returns bucket and key that can be used in resize image endpoint
 */
function extractBucketAndKey(s3Url: string) {
  const url = new URL(s3Url);

  return {
    bucket: url.host.split('.')[0],
    key: url.pathname.slice(1),
  };
}

/**
 * @param imageUrl should be S3 URL
 * @returns URL for image that is resized the
 */
function getImageUrlForSize({
  imageUrl,
  width,
  height,
  screenDensity = 1,
  fit = 'contain',
  backgroundColor = { r: 0, g: 0, b: 0, alpha: 1 },
}: {
  imageUrl: string;
  width: number;
  height: number;
  fit?: 'cover' | 'fill' | 'contain';
  screenDensity?: number;
  backgroundColor?: { r: number; g: number; b: number; alpha: number };
}) {
  const { bucket, key } = extractBucketAndKey(imageUrl);
  if (BUCKETS_THAT_DONT_WORK.includes(bucket) || !process.env.REACT_APP_IMAGE_RESIZER_ENDPOINT) {
    return imageUrl;
  }
  const params = {
    bucket,
    key,
    edits: {
      resize: {
        width: width * screenDensity,
        height: height * screenDensity,
        fit,
        background: backgroundColor,
      },
    },
  };
  const b64EncodedParams = Buffer.from(
    encodeURIComponent(JSON.stringify(params)).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  ).toString('base64');
  return `${process.env.REACT_APP_IMAGE_RESIZER_ENDPOINT}/${b64EncodedParams}`;
}

const dynamicImageResizerUtils = {
  getImageUrlForSize,
};
export { BUCKETS_THAT_DONT_WORK };
export default dynamicImageResizerUtils;
