import { FocusEvent, FocusEventHandler, useState } from 'react';

export const useHandleSelectBlur = (
  handleBlur: Function,
  formControl: string
): [FocusEventHandler<HTMLInputElement>, (formValues: any) => boolean] => {
  const [isSelectTouched, setIsSelectTouched] = useState(false);

  const handleFieldBlur: FocusEventHandler<HTMLInputElement> = (
    event: FocusEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;

    !isSelectTouched && setIsSelectTouched(true);

    handleBlur(formControl, value);
  };

  const getFieldSelectError = (formValues: any) => isSelectTouched && !formValues?.[formControl];

  return [handleFieldBlur, getFieldSelectError];
};
