import type { UploadResult, Uppy, UppyFile } from '@uppy/core';
import { BasePlugin } from '@uppy/core';

export type EventPluginOptions = {
  id?: string;
  onFilesAdded?: (files: UppyFile[]) => void; // eslint-disable-line no-unused-vars
  onComplete?: (result: UploadResult) => void; // eslint-disable-line no-unused-vars
  onRestrictionFailed?: (file: UppyFile | undefined, error: Error) => void; // eslint-disable-line no-unused-vars
};

/**
 * This plugin is intended to provide a wrapper for Uppy event emitter events so that we can
 * manage the callbacks inside of React State management rather than assuming nothing will
 * change in the callbacks after mount.
 */
export default class EventPlugin extends BasePlugin {
  opts?: EventPluginOptions;

  constructor(uppy: Uppy, opts?: EventPluginOptions) {
    super(uppy, opts);

    this.opts = opts;
    this.id = this.opts?.id || 'EventPlugin';
    this.type = 'modifier';
  }

  onFilesAdded = (files: UppyFile[]) => {
    if (this.opts?.onFilesAdded) {
      this.opts.onFilesAdded(files);
    }
  };

  onComplete = (result: UploadResult) => {
    if (this.opts?.onComplete) {
      this.opts.onComplete(result);
    }
  };

  onRestrictionFailed = (file: UppyFile | undefined, error: Error) => {
    if (this.opts?.onRestrictionFailed) {
      this.opts.onRestrictionFailed(file, error);
    }
  };

  install() {
    this.uppy.on('files-added', this.onFilesAdded);
    this.uppy.on('complete', this.onComplete);
    this.uppy.on('restriction-failed', this.onRestrictionFailed);
  }

  uninstall() {
    this.uppy.off('files-added', this.onFilesAdded);
    this.uppy.off('complete', this.onComplete);
    this.uppy.off('restriction-failed', this.onRestrictionFailed);
  }
}
