import { Dispatch } from 'redux';
import { MediaCategory, MediaCategoryType } from 'types';

import {
  createMediaCategory as createMediaCategoryApi,
  deleteMediaCategory as deleteMediaCategoryApi,
  fetchAllMediaCategories,
  fetchMediaCategoriesByType,
  fetchMediaCategory,
  updateMediaCategory as updateMediaCategoryApi,
} from 'api/defaultCategories';

import {
  CREATE_MEDIA_CATEGORY,
  DELETE_MEDIA_CATEGORY,
  GET_ALL_MEDIA_CATEGORIES,
  GET_MEDIA_CATEGORIES_BY_TYPE,
  GET_MEDIA_CATEGORY_BY_ID,
  UPDATE_MEDIA_CATEGORY,
} from './constants';
import { actions } from './mediaCategoriesSlice';

export const getAllMediaCategories = () => async (dispatch: Dispatch) => {
  const response = await fetchAllMediaCategories();
  if (response.data) {
    dispatch(actions[GET_ALL_MEDIA_CATEGORIES](response.data));
  }
  return response.data;
};

export const getMediaCategoriesByType = (type: MediaCategoryType) => async (dispatch: Dispatch) => {
  const response = await fetchMediaCategoriesByType(type);
  if (response.data) {
    dispatch(actions[GET_MEDIA_CATEGORIES_BY_TYPE](response.data));
  }
  return response.data;
};

export const getMediaCategoryById = (id: string) => async (dispatch: Dispatch) => {
  const response = await fetchMediaCategory(id);
  if (response.data) {
    dispatch(actions[GET_MEDIA_CATEGORY_BY_ID](response.data));
  }
  return response.data;
};

export const createMediaCategory =
  (payload: Pick<MediaCategory, 'categoryName'>) => async (dispatch: Dispatch) => {
    const response = await createMediaCategoryApi(payload);
    if (response.data) {
      dispatch(actions[CREATE_MEDIA_CATEGORY](response.data));
    }
    return response.data;
  };

export const deleteMediaCategory = (id: string) => async (dispatch: Dispatch) => {
  const response = await deleteMediaCategoryApi(id);
  if (response.data) {
    dispatch(actions[DELETE_MEDIA_CATEGORY](id as any));
  }
  return response.data;
};

export const updateMediaCategory =
  (id: string, payload: Pick<MediaCategory, 'categoryName'>) => async (dispatch: Dispatch) => {
    const response = await updateMediaCategoryApi(id, payload);
    if (response.data) {
      dispatch(actions[UPDATE_MEDIA_CATEGORY](response.data));
    }
    return response.data;
  };
