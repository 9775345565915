import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input, Spin } from 'antd';

import {
  integrationCreateDirectory,
  integrationDestination,
  integrationDirectories,
} from 'api/integrations/integrationService';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-right-gray.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';

import { DirectorySelectProps, IBreadcrumb, IDirectory, ISelectedDirectory } from '../types';

const DirectorySelect = ({
  toggleSettingsModal,
  setDestinationPath,
  integration,
}: DirectorySelectProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<Boolean>();
  const [isError, setIsError] = useState(false);
  const [directories, setDirectories] = useState<IDirectory[]>([]);
  const [prevDirectories, setPrevDirectories] = useState<IDirectory[][]>([]);
  const [breadcrumb, setBreadcrumb] = useState<IBreadcrumb[]>([]);
  const [directoryName, setDirectoryName] = useState('');
  const [showGetStartedMessage, setShowGetStartedMessage] = useState(false);
  const [showSelectedDesitinationMessage, setShowSelectedDesitinationMessage] = useState(false);
  const [selectedDirectory, setSelectedDirectory] = useState<ISelectedDirectory>(
    {} as ISelectedDirectory
  );
  const { api: integrationApi, name: integrationName } = integration;

  const handleDirectoryClick = async (
    event: MouseEvent<HTMLButtonElement>,
    directoryId: string,
    directoryName: string
  ) => {
    if (event.detail > 1) return; // prevent double click

    const isDuplicate = breadcrumb.some((bc) => bc.directoryId === directoryId);

    if (!isDuplicate) setBreadcrumb([...breadcrumb, { directoryId, directoryName }]);
    await getDirectories(directoryId);
    setSelectedDirectory({ id: directoryId, name: directoryName });
    setPrevDirectories((prevState) => [...prevState, directories]);
  };

  const handleSelectDestination = () => {
    const directoryData = {
      id: selectedDirectory.id,
      name: breadcrumb.map(({ directoryName }) => directoryName).join(','),
    };

    integrationDestination(integrationApi, directoryData);
    setDestinationPath(directoryData.name);
    toggleSettingsModal();
  };

  const handleDirectoryCreate = async () => {
    if (!directoryName) return;

    const directoryCreateResponse = await integrationCreateDirectory({
      integrationApi,
      parentId: selectedDirectory.id,
      name: directoryName,
    });

    if (directoryCreateResponse.data.id) await getDirectories(selectedDirectory.id);
    setDirectoryName('');
  };

  const handleDirectoryNameInput = (e: any) => {
    e.target.value === '' ? setDirectoryName('') : setDirectoryName(e.target.value);
  };

  const handleBackDirectory = () => {
    const prevBreadcrumb = breadcrumb.slice(0, breadcrumb.length - 1);

    setBreadcrumb(prevBreadcrumb);
    setDirectories(prevDirectories.splice(-1)[0]);
    setSelectedDirectory({} as ISelectedDirectory);
  };

  const getDirectories = async (parentId = '') => {
    setIsLoading(true);
    try {
      const gDriveDirs = await integrationDirectories(integrationApi, parentId);

      setDirectories(gDriveDirs.data.items || []);
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    getDirectories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowGetStartedMessage(
      !isLoading && !selectedDirectory.id && breadcrumb.length === 0 && directories.length === 0
    );

    setShowSelectedDesitinationMessage(
      !isLoading && breadcrumb.length !== 0 && directories.length === 0
    );
  }, [isLoading, breadcrumb, selectedDirectory, directories]);

  return (
    <div className="DirectorySelect" data-testid="integrations-directory-select">
      <div className="breadcrumbs">
        {breadcrumb.length === 0 && (
          <div>
            <ChevronIcon />
          </div>
        )}
        {breadcrumb.map((item, index) => {
          return (
            <div key={index}>
              <ChevronIcon /> {item.directoryName}
            </div>
          );
        })}
      </div>
      {showGetStartedMessage && (
        <div className="message">
          {t('Integrations.createDirectoryMessage', { integrationName: integrationName })}
        </div>
      )}
      {showSelectedDesitinationMessage && (
        <div className="message">
          {t('Integrations.selectDestinationMessage', {
            directory: selectedDirectory.name,
          })}
        </div>
      )}
      {isError && <div className="message">{t('Integrations.error')}</div>}
      {isLoading && (
        <div className="spinnerContainer">
          <Spin className="spinner" />
        </div>
      )}
      {!showGetStartedMessage && !showSelectedDesitinationMessage && !isLoading && (
        <div className="directories">
          {directories.map((directory: IDirectory) => (
            <div className="directory" key={directory.id}>
              <button
                onClick={(e) => handleDirectoryClick(e, directory.id, directory.name)}
                aria-label={directory.name}
                title={directory.name}
              >
                <div>
                  <FolderIcon /> <div>{directory.name}</div>
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="footer">
        <div className="select-back">
          <Button
            className="Button--White"
            disabled={prevDirectories.length === 0}
            onClick={handleBackDirectory}
          >
            {t('Integrations.back')}
          </Button>
          <Button
            className="Button--Blue"
            disabled={!selectedDirectory.id}
            onClick={handleSelectDestination}
          >
            {t('Integrations.select')}
          </Button>
        </div>
        <div className="create-directory">
          <Input
            placeholder="Directory Name"
            size="large"
            aria-label="directory name"
            onInput={handleDirectoryNameInput}
            value={directoryName}
          />
          <Button
            className="Button--Blue"
            onClick={handleDirectoryCreate}
            disabled={directoryName.length === 0}
          >
            {t('Integrations.createDirectory')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DirectorySelect;
