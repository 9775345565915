import API from 'api/config';

import {
  loginUrl,
  logoutUrl,
  refreshTokenUrl,
  resetPasswordUrl,
  sendInvitationUrl,
} from './authUrls';
import { InviteUserRequest, LoginRequest, RefreshTokenResponse } from './types';

export const login = (credentials: LoginRequest) => API.post(loginUrl(), credentials);
export const logout = () => API.post(logoutUrl());
export const resetPassword = (email: string) => API.post(resetPasswordUrl(), email);
export const sendInvitation = (newUser: InviteUserRequest) =>
  API.post(sendInvitationUrl(), newUser);
export const refreshToken = (token: string) =>
  API.post<RefreshTokenResponse>(
    refreshTokenUrl(),
    {},
    {
      headers: {
        'Authorization-refresh': token,
      },
    }
  );
