import colors from 'helpers/constants/colors';
import fontFamily from 'helpers/constants/fontFamily';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';

const getHeight = (variant) => {
  switch (variant) {
    case 'filter':
      return '4.2rem';
    case 'form':
      return '4.2rem';
    default:
      return '5.2rem';
  }
};

const getBorderColor = (focused, error) =>
  error
    ? `${colors.red} !important`
    : focused
      ? `${colors.mainBlue} !important`
      : `${colors.neutralGray} !important`;

export const customSelectStyles = (tall, variant, backgroundColor) => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),

  control: (provided, state) => ({
    ...provided,
    padding: 0,
    cursor: state.isDisabled ? 'not-allowed' : state.isFocused ? 'text' : 'pointer',
    height: state.isMulti ? 'unset' : getHeight(variant),
    minHeight: getHeight(variant),
    border: `.1rem solid ${getBorderColor(state.isFocused, state.selectProps.error)}`,
    borderRadius: '.8rem',
    boxShadow: state.isFocused && `0 0 0 .2rem ${colors.focusBlue}`,
    '&:hover': {
      borderColor: !state.selectProps.error && `${colors.focusBlue} !important`,
    },
  }),

  valueContainer: (provided) => ({
    ...provided,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    height: 'unset',
    minHeight: '100%',
    padding: '0 .6rem',
  }),

  input: () => ({
    fontSize: fontSizes.mediumSmall,
    marginLeft: tall && '.7rem',
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: fontSizes.mediumSmall,
    paddingLeft: '.7rem',
    color: colors.darkGray,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
    minHeight: getHeight(variant),
  }),

  placeholder: (provided) => ({
    ...provided,
    minHeight: 'unset',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: colors.labelGray,
    fontFamily: fontFamily,
    fontWeight: fontWeights.normal,
    fontSize: fontFamily.mediumSmall,
    padding: tall ? '.5rem .7rem' : '0 0 0 0.3rem',
  }),

  multiValue: (provided) => ({
    ...provided,
    background: backgroundColor,
    borderRadius: '.8rem',
    height: '2.4rem',
    fontSize: fontSizes.mediumSmall,
    fontFamiy: fontFamily,
    margin: '.5rem .2rem',
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: colors.white,
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    fill: colors.white,
    color: colors.white,
  }),

  menu: (provided) => ({
    ...provided,
    color: colors.gray,
    borderRadius: '.8rem',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    padding: 0,
    borderRadius: '.8rem',
  }),

  option: (provided, { isSelected }) => ({
    ...provided,
    padding: '0.75rem',
    fontWeight: isSelected && fontWeights.semiBold,
    color: isSelected && colors.darkGray,
    backgroundColor: 'none',
    fontSize: tall ? fontSizes.medium : provided.fontSize,
    '&:hover': {
      backgroundColor: colors.background,
      cursor: 'pointer',
    },
  }),
});
