import { useContext, useState } from 'react';

import { SidebarContext } from 'screens/Sidebar';

import styled, { css } from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as Close } from 'assets/icons/x-icon.svg';

import { ComponentBuilder } from './ComponentBuilder';
import { SectionContent } from './SectionContent';

type DrawerProps = {
  isOpen: boolean;
  sidebarOpen?: boolean;
};

const Icon = css`
  width: 1.9rem;
  height: 1.9rem;
  z-index: 1000;
  vertical-align: middle;
  & path {
    fill: ${colors.black};
  }
`;

const DrawerContainer = css`
  position: fixed;
  top: 18vh;
  height: auto;
  width: auto;
  background-color: ${colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  overflow: auto;
`;

const DrawerToggle = css`
  position: fixed;
  top: 18vh;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s ease;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  ${Icon}
`;
const StyledClose = styled(Close)`
  ${Icon}
`;

const RightDrawerContainer = styled.div<DrawerProps>`
  ${DrawerContainer}
  right: ${({ isOpen }) => (isOpen ? '0' : '-700px')};
  transition: right 0.3s ease;
`;

const LeftDrawerContainer = styled.div<DrawerProps>`
  ${DrawerContainer}
  left: ${({ isOpen, sidebarOpen }) =>
    sidebarOpen ? (isOpen ? '270px' : '-700px') : isOpen ? '75px' : '-700px'};
  transition: left 0.3s ease;
`;
const LeftDrawerToggle = styled.button<DrawerProps>`
  ${DrawerToggle}
  left: ${({ sidebarOpen }) => (sidebarOpen ? '300px' : '100px')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(260px)' : '')};
`;

const RightDrawerToggle = styled.button<DrawerProps>`
  ${DrawerToggle}
  right: 20px;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(-170px)' : '')};
`;

const Content = styled.div`
  padding: 2rem;
`;
const Spacer = styled.div<DrawerProps>`
  display: hidden;
  width: ${({ isOpen }) => (isOpen ? '300px' : '100px')};
  transition: width 0.3 ease;
  height: 100%;
`;

export const RightDrawer = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Spacer isOpen={isOpen} />
      <RightDrawerToggle isOpen={isOpen} onClick={toggleDrawer}>
        {isOpen ? <StyledClose title="" /> : <StyledMenu title="Field Types" />}
      </RightDrawerToggle>
      <RightDrawerContainer isOpen={isOpen}>
        <Content>
          <ComponentBuilder />
        </Content>
      </RightDrawerContainer>
    </>
  );
};

export const LeftDrawer = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isSidebarCollapsed } = useContext(SidebarContext);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Spacer isOpen={isOpen} />
      <LeftDrawerToggle sidebarOpen={!isSidebarCollapsed} isOpen={isOpen} onClick={toggleDrawer}>
        {isOpen ? <StyledClose title="" /> : <StyledMenu title="Sections" />}
      </LeftDrawerToggle>
      <LeftDrawerContainer sidebarOpen={!isSidebarCollapsed} isOpen={isOpen}>
        <Content>
          <SectionContent />
        </Content>
      </LeftDrawerContainer>
    </>
  );
};
