import { DESIGN_STATUS, PROJECT_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { completedStatuses, errorStatuses, processingStatuses } from '../constants';

export const getDesignStatusStep = ({
  projectStatus,
  status,
  tierLevel,
}: {
  projectStatus?: string;
  status?: DESIGN_STATUS;
  tierLevel?: TIER_LEVEL;
}) => {
  const isWireframeSiteModelOrMaxFill = tierLevel
    ? [TIER_LEVEL.maxFill, TIER_LEVEL.siteModeling, TIER_LEVEL.wireframe].includes(tierLevel)
    : false;

  if (!projectStatus || !status) {
    return 0;
  }

  if (completedStatuses.includes(projectStatus) && status === DESIGN_STATUS.ordered) {
    return 0;
  }

  if (
    ((projectStatus === PROJECT_STATUS.remoteDesignStarted &&
      status !== DESIGN_STATUS.revisionInProgress) ||
      projectStatus === PROJECT_STATUS.noFlight) &&
    (status === DESIGN_STATUS.ordered || status === DESIGN_STATUS.designInProgress)
  ) {
    return 0;
  } else if (
    processingStatuses.includes(projectStatus) ||
    errorStatuses.includes(projectStatus) ||
    status === DESIGN_STATUS.ordered ||
    status === DESIGN_STATUS.dataReview
  ) {
    return 0;
  } else if (status === DESIGN_STATUS.dataRequest) {
    return 1;
  } else if (status === DESIGN_STATUS.designInProgress) {
    return 2;
  } else if (status === DESIGN_STATUS.dataRefinement) {
    return 3;
  } else if (status === DESIGN_STATUS.completed || status === DESIGN_STATUS.pendingApproval) {
    return 4;
  } else if (status === DESIGN_STATUS.revisionInProgress) {
    if (isWireframeSiteModelOrMaxFill) {
      return 2;
    }
    return 5;
  }
  return 1;
};
