import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import Account from 'screens/Account';
import AccountCompanyInfo from 'screens/Account/AccountCompanyInfo';
import AccountLoginSecurity from 'screens/Account/AccountLoginSecurity';
import AccountPayments from 'screens/Account/AccountPayments';
import AccountProfile from 'screens/Account/AccountProfile';
import Scanifly3dSettings from 'screens/Account/Scanifly3dSettings';
import ProjectCategories from 'screens/Albums';
import ProjectCategory from 'screens/Albums/ProjectCategory';
import Auth from 'screens/Auth';
import Login from 'screens/Auth/Login';
import Reset from 'screens/Auth/Reset';
import SetPassword from 'screens/Auth/SetPassword';
import SignUpBillingInformation from 'screens/Auth/SignUp/SignUpBillingInformation';
import SignUpMainInformation from 'screens/Auth/SignUp/SignUpMainInformation';
import SignUpTokenExpired from 'screens/Auth/SignUp/SignUpTokenExpired';
import Checklists from 'screens/Checklists/Checklists';
import ChecklistTemplateEditor from 'screens/ChecklistTemplate/Editor';
import { ChecklistAdminManager, ChecklistTemplateManager } from 'screens/ChecklistTemplate/Manager';
import CreditHistoryAdminQueue from 'screens/DesignServices/DesignServicesQueue/CreditsCompanyQueue/CreditHistoryAdminQueue';
import DesignServicesQueue from 'screens/DesignServices/DesignServicesQueue/DesignServicesQueue';
import DesignServicesCompanyQueue from 'screens/DesignServices/DesignServicesQueue/DSCompanyQueue/DesignServicesCompanyQueue';
import DesignSelection from 'screens/DesignServices/OrderDesign/DesignSelection/DesignSelection';
import DesignStatus from 'screens/DesignServices/OrderDesign/DesignStatus/DesignStatus';
import OrderDesignLanding from 'screens/DesignServices/OrderDesign/OrderDesignLanding';
import Integrations from 'screens/Integrations/Integrations';
import Layout from 'screens/Layout';
import Maintenance from 'screens/Maintenance/Maintenance';
import PublicReportDisplay from 'screens/Maintenance/PublicReportDisplay/PublicReportDisplay';
import Notes from 'screens/Notes/Notes';
import CustomerInfo from 'screens/Projects/CustomerInfo';
import ConsumptionData from 'screens/Projects/CustomerInfo/ConsumptionData';
import Designs from 'screens/Projects/Designs';
import ProjectOverview from 'screens/Projects/Overview';
import ProjectInfo from 'screens/Projects/ProjectInfo';
import ProjectsList from 'screens/Projects/ProjectsList';
import ScaniflyInfo from 'screens/Projects/ScaniflyInfo/ScaniflyInfo';
import SiteData from 'screens/Projects/SiteData';
import { S3DV2Wrapper, S3DV3Wrapper, S3DVSelect } from 'screens/Projects/Viewer';
import AccessTokens from 'screens/ScaniflyAdmin/AccessTokens';
import Accounting from 'screens/ScaniflyAdmin/Accounting/Accounting';
import AccountManager from 'screens/ScaniflyAdmin/AccountManager';
import CreditHistoryAll from 'screens/ScaniflyAdmin/CreditHistory/CreditHistoryAll';
import CreditHistoryCompany from 'screens/ScaniflyAdmin/CreditHistory/CreditHistoryCompany';
import CustomerSupportUpload from 'screens/ScaniflyAdmin/CustomerSupportUpload/CustomerSupportUpload';
import CustomerProjects from 'screens/ScaniflyAdmin/Projects/CustomerProjects';
import Projects from 'screens/ScaniflyAdmin/Projects/Projects';
import UserProjects from 'screens/ScaniflyAdmin/Projects/UserProjects';
import { ReferenceMedia } from 'screens/ScaniflyAdmin/ReferenceMedia';
import ScaniflyAdminMenu from 'screens/ScaniflyAdmin/ScaniflyAdminMenu';
import Users from 'screens/ScaniflyAdmin/Users';
import UsersCustomer from 'screens/ScaniflyAdmin/Users/UsersCustomer';
import TeamList from 'screens/Team/TeamList';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { currentUserRequested } from 'state/slices/usersSlice';

import { ACCESS } from 'helpers/constants/access';
import {
  account3dSettingsRoute,
  accountAccessTokensRoute,
  accountChecklistTemplateManagerRoute,
  accountCompanyChecklistTemplateEditorRoute,
  accountCompanyInfoRoute,
  accountLoginSecurityRoute,
  accountMenuRoute,
  accountPaymentsInvoicesRoute,
  accountProfileRoute,
  creditHistoryAdminQueueRoute,
  designServicesCompanyQueueRoute,
  designServicesLandingRoute,
  designServicesProviderQueueRoute,
  designStatusRoute,
  draftChecklistRoute,
  draftNotesRoute,
  draftProjectCategoriesRoute,
  draftProjectCategoryRoute,
  draftProjectConsumptionDataRoute,
  draftProjectCustomerInfoRoute,
  draftProjectDesignsRoute,
  draftProjectInfo,
  draftProjectOverviewRoute,
  draftProjectSiteDataRoute,
  integrationsRoute,
  maintenanceReportPublicDisplayRoute,
  maintenanceReportRoute,
  newProjectConsumptionDataRoute,
  newProjectCustomerInfoRoute,
  orderDesignDashRoute,
  orderDesignRoute,
  projectCategoriesRoute,
  projectCategoryRoute,
  projectChecklistRoute,
  projectConsumptionDataRoute,
  projectCustomerInfoRoute,
  projectDesignsRoute,
  projectInfoRoute,
  projectNotesRoute,
  projectOverviewRoute,
  projectSiteDataRoute,
  projectsListRoute,
  resetPasswordRoute,
  rootRoute,
  s3dV2,
  s3dV3,
  s3dVSelect,
  scaniflyAdminAccessTokensRoute,
  scaniflyAdminAccountingRoute,
  scaniflyAdminAccountingTableRoute,
  scaniflyAdminAccountManagerRoute,
  scaniflyAdminAllCreditHistoryRoute,
  scaniflyAdminChecklistManagerRoute,
  scaniflyAdminCompanyCreditHistoryRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDesignServicesQueueRoute,
  scaniflyAdminDraftConsumptionDataRoute,
  scaniflyAdminDraftCustomerInfoRoute,
  scaniflyAdminDraftNotesRoute,
  scaniflyAdminDraftOverviewRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminDraftProjectDesignsRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminDraftScaniflyInfoRoute,
  scaniflyAdminDraftSiteDataRoute,
  scaniflyAdminMenuRoute,
  scaniflyAdminNewProjectConsumptionDataRoute,
  scaniflyAdminNewProjectCustomerInfoRoute,
  scaniflyAdminNewProjectScaniflyInfoRoute,
  scaniflyAdminProjectCategoriesRoute,
  scaniflyAdminProjectCategoryRoute,
  scaniflyAdminProjectConsumptionDataRoute,
  scaniflyAdminProjectCustomerInfoRoute,
  scaniflyAdminProjectDesignsRoute,
  scaniflyAdminProjectNotesRoute,
  scaniflyAdminProjectOverviewRoute,
  scaniflyAdminProjectProjectInfoRoute,
  scaniflyAdminProjectsByUserRoute,
  scaniflyAdminProjectScaniflyInfoRoute,
  scaniflyAdminProjectsCustomerRoute,
  scaniflyAdminProjectSiteDataRoute,
  scaniflyAdminProjectsRoute,
  scaniflyAdminReferenceMediaRoute,
  scaniflyAdminUsersCustomerRoute,
  scaniflyAdminUsersRoute,
  setNewPasswordRoute,
  signUpBillingInformationRoute,
  signUpMainInformationRoute,
  teamListRoute,
  tokenValidationRoute,
  upgradeDesignRoute,
} from 'helpers/constants/routes';
import GuardedRoute from 'helpers/hocs/GuardedRoute';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import AccessTokenDisplay from '../AccessTokens/AccessTokenDisplay';

const Root = () => {
  const { t } = useTranslation();
  const { isLoggedIn, currentUser } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.company);
  const { activeSubscription } = useSelector((state) => state.adminSubscriptions);
  const {
    isScaniflyAdmin,
    isAdmin,
    isDesignServiceProvider,
    hasBillingPermissions,
    isSalesManager,
  } = usePermissions();
  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );
  const maintenanceReportAccess = useFeatureToggle(ACCESS.MAINTENANCE_REPORT);
  const designServicesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );
  const has3dSettingsAccess = isSalesManager || isAdmin;

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentUserToken = localStorage.getItem('accessToken');
    if (!currentUser && currentUserToken) {
      dispatch(currentUserRequested());
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (!activeSubscription && company?.id) {
      dispatch(companyActiveSubscriptionRequested(company?.id));
    }
  }, [dispatch, activeSubscription, company?.id]);

  useEffect(() => {
    if (!isLoggedIn) {
      const requestedUrl = location.pathname;
      if (requestedUrl && requestedUrl !== '/') {
        localStorage.setItem('requestedUrl', requestedUrl);
      }
    }
  }, [isLoggedIn, location.pathname]);

  const storedUrl = localStorage.getItem('requestedUrl');
  const getRedirectUrl = useMemo(() => {
    if (storedUrl) {
      return storedUrl;
    }
    return isDesignServiceProvider ? designServicesProviderQueueRoute() : projectsListRoute();
  }, [isDesignServiceProvider, storedUrl]);

  return (
    <Switch>
      <GuardedRoute exact path={rootRoute()} auth={!isLoggedIn} redirectUrl={getRedirectUrl}>
        <Auth title={t('Authentication.login')}>
          <Login />
        </Auth>
      </GuardedRoute>
      <GuardedRoute
        exact
        path={resetPasswordRoute()}
        auth={!isLoggedIn}
        redirectUrl={projectsListRoute()}
      >
        <Auth title={t('Authentication.reset')}>
          <Reset />
        </Auth>
      </GuardedRoute>
      <GuardedRoute
        exact
        path={setNewPasswordRoute()}
        auth={!isLoggedIn}
        redirectUrl={projectsListRoute()}
      >
        <Auth title={t('Authentication.newPassword')}>
          <SetPassword />
        </Auth>
      </GuardedRoute>
      <GuardedRoute
        path={tokenValidationRoute()}
        auth={!isLoggedIn}
        redirectUrl={projectsListRoute()}
      >
        <div className="SignUp-TokenExpired">
          <Auth title={t('Authentication.invitationTokenExpired')}>
            <SignUpTokenExpired />
          </Auth>
        </div>
      </GuardedRoute>
      <GuardedRoute
        path={signUpMainInformationRoute()}
        auth={!isLoggedIn}
        redirectUrl={projectsListRoute()}
      >
        <Auth
          title={t('Authentication.personalInfoTitle')}
          subtitle={t('Authentication.personalInfoSubtitle')}
        >
          <SignUpMainInformation />
        </Auth>
      </GuardedRoute>
      <GuardedRoute
        exact
        path={signUpBillingInformationRoute()}
        auth={!isLoggedIn}
        redirectUrl={projectsListRoute()}
      >
        <Auth title={t('Authentication.billingInfoTitle')}>
          <SignUpBillingInformation />
        </Auth>
      </GuardedRoute>
      <GuardedRoute
        exact
        path={s3dV2(':projectId', ':designId')}
        auth={isLoggedIn}
        redirectUrl={rootRoute()}
      >
        <S3DV2Wrapper />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={s3dV3(':projectId', ':designId')}
        auth={isLoggedIn}
        redirectUrl={rootRoute()}
      >
        <S3DV3Wrapper />
      </GuardedRoute>
      <GuardedRoute
        exact
        path={s3dVSelect(':projectId', ':designId')}
        auth={isLoggedIn && (isScaniflyAdmin || isDesignServiceProvider)}
        redirectUrl={rootRoute()}
      >
        <S3DVSelect />
      </GuardedRoute>
      <Route path={maintenanceReportPublicDisplayRoute(':reportId')} exact>
        <PublicReportDisplay />
      </Route>
      <Layout>
        <Switch>
          <GuardedRoute
            exact
            path={designServicesProviderQueueRoute()}
            auth={isLoggedIn && isDesignServiceProvider}
            redirectUrl={projectsListRoute()}
          >
            <DesignServicesQueue />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={designServicesCompanyQueueRoute()}
            auth={isLoggedIn && (isAdmin || isScaniflyAdmin)}
            redirectUrl={projectsListRoute()}
          >
            <DesignServicesCompanyQueue />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={creditHistoryAdminQueueRoute()}
            auth={isLoggedIn && (isAdmin || isScaniflyAdmin)}
            redirectUrl={projectsListRoute()}
          >
            <CreditHistoryAdminQueue />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={orderDesignDashRoute(':projectId', ':step')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <DesignSelection />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={orderDesignRoute(':projectId', ':step')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <DesignSelection />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={upgradeDesignRoute(':projectId', ':step')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <DesignSelection upgrade={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={designStatusRoute(':projectId', ':step')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <DesignStatus />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={designServicesLandingRoute(':projectId')}
            auth={isLoggedIn && designServicesAccess}
            redirectUrl={projectsListRoute()}
          >
            <OrderDesignLanding />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminMenuRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ScaniflyAdminMenu />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAccountManagerRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <AccountManager />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAccountingRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Accounting />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAccountingTableRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Accounting />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAccountingRoute(':companyId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Accounting />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminUsersRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Users />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminUsersCustomerRoute(':companyId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <UsersCustomer />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminCustomerSupportUploadRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CustomerSupportUpload />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminProjectsRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Projects />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAccessTokensRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <AccessTokens />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountAccessTokensRoute()}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <AccessTokenDisplay />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminDesignServicesQueueRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={projectsListRoute()}
          >
            <DesignServicesQueue isScaniflyAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminProjectsByUserRoute(':userId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <UserProjects />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminProjectsCustomerRoute(':companyId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CustomerProjects />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminAllCreditHistoryRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CreditHistoryAll />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminCompanyCreditHistoryRoute(':companyId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CreditHistoryCompany />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminReferenceMediaRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ReferenceMedia />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={projectsListRoute()}
            auth={isLoggedIn && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <ProjectsList />
          </GuardedRoute>
          <GuardedRoute exact path={teamListRoute()} auth={isLoggedIn} redirectUrl={rootRoute()}>
            <TeamList />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={integrationsRoute()}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <Integrations />
          </GuardedRoute>
          <GuardedRoute exact path={accountMenuRoute()} auth={isLoggedIn} redirectUrl={rootRoute()}>
            <Account />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountProfileRoute()}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <AccountProfile />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountLoginSecurityRoute()}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <AccountLoginSecurity />
          </GuardedRoute>
          {/* Scanifly 3D settings  change auth to change who has permissions */}
          <GuardedRoute
            exact
            path={account3dSettingsRoute()}
            auth={isLoggedIn && has3dSettingsAccess}
            redirectUrl={rootRoute()}
          >
            <Scanifly3dSettings />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountCompanyInfoRoute()}
            auth={isLoggedIn && isAdmin}
            redirectUrl={rootRoute()}
          >
            <AccountCompanyInfo />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountCompanyChecklistTemplateEditorRoute(':templateId')}
            auth={isLoggedIn && isAdmin}
            redirectUrl={rootRoute()}
          >
            <ChecklistTemplateEditor />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountChecklistTemplateManagerRoute()}
            auth={isLoggedIn && isAdmin}
            redirectUrl={rootRoute()}
          >
            <ChecklistTemplateManager />
          </GuardedRoute>
          <GuardedRoute
            exact
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
            path={scaniflyAdminChecklistManagerRoute(':companyId')}
          >
            <ChecklistAdminManager />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={accountPaymentsInvoicesRoute()}
            auth={isLoggedIn && hasBillingPermissions}
            redirectUrl={rootRoute()}
          >
            <AccountPayments />
          </GuardedRoute>
          <GuardedRoute
            path={newProjectCustomerInfoRoute()}
            auth={isLoggedIn && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo />
          </GuardedRoute>
          <GuardedRoute
            path={newProjectConsumptionDataRoute()}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminNewProjectCustomerInfoRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminNewProjectConsumptionDataRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectCustomerInfoRoute(':projectId')}
            auth={isLoggedIn && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectConsumptionDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftCustomerInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftConsumptionDataRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectCustomerInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectConsumptionDataRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectInfo(':projectId')}
            auth={isLoggedIn && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <ProjectInfo />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftProjectInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ProjectInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectProjectInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ProjectInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={draftNotesRoute(':projectId')}
            auth={!!(isLoggedIn && notesAccess)}
            redirectUrl={rootRoute()}
          >
            <Notes />
          </GuardedRoute>
          <GuardedRoute
            path={projectNotesRoute(':projectId')}
            auth={!!(isLoggedIn && notesAccess)}
            redirectUrl={rootRoute()}
          >
            <Notes />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftNotesRoute(':projectId')}
            auth={!!(isLoggedIn && isScaniflyAdmin && notesAccess)}
            redirectUrl={rootRoute()}
          >
            <Notes isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectNotesRoute(':projectId')}
            auth={!!(isLoggedIn && isScaniflyAdmin && notesAccess)}
            redirectUrl={rootRoute()}
          >
            <Notes isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={maintenanceReportRoute(':projectId')}
            auth={!!(isLoggedIn && maintenanceReportAccess)}
            redirectUrl={rootRoute()}
          >
            <Maintenance />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectCategoryRoute(':projectId', ':categoryName')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectCategory />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectCategoriesRoute(':projectId')}
            auth={!!isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectCategories />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminNewProjectScaniflyInfoRoute()}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ScaniflyInfo />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftScaniflyInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ScaniflyInfo />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={projectChecklistRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <Checklists />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={draftChecklistRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={projectsListRoute()}
          >
            <Checklists />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectScaniflyInfoRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <ScaniflyInfo isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminDraftProjectCategoriesRoute(':projectId')}
            auth={!!(isLoggedIn && isScaniflyAdmin)}
            redirectUrl={rootRoute()}
          >
            <ProjectCategories isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminProjectCategoriesRoute(':projectId')}
            auth={!!(isLoggedIn && isScaniflyAdmin)}
            redirectUrl={rootRoute()}
          >
            <ProjectCategories isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftProjectCategoryRoute(':projectId', ':categoryName')}
            auth={isLoggedIn && isScaniflyAdmin && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <ProjectCategory isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectCategoryRoute(':projectId', ':categoryName')}
            auth={isLoggedIn && isScaniflyAdmin && !isDesignServiceProvider}
            redirectUrl={rootRoute()}
          >
            <ProjectCategory isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminProjectDesignsRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Designs isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={scaniflyAdminDraftProjectDesignsRoute(':projectId')}
            auth={isLoggedIn && isScaniflyAdmin}
            redirectUrl={rootRoute()}
          >
            <Designs isAdminRoute={true} />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={projectCategoriesRoute(':projectId')}
            auth={!!isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectCategories />
          </GuardedRoute>
          <GuardedRoute
            path={projectCategoryRoute(':projectId', ':categoryName')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectCategory />
          </GuardedRoute>
          <GuardedRoute
            path={projectInfoRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectInfo />
          </GuardedRoute>
          <GuardedRoute
            path={projectCustomerInfoRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <CustomerInfo />
          </GuardedRoute>
          <GuardedRoute
            path={projectConsumptionDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ConsumptionData />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={projectDesignsRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <Designs />
          </GuardedRoute>
          <GuardedRoute
            exact
            path={draftProjectDesignsRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <Designs />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectSiteDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <SiteData />
          </GuardedRoute>
          <GuardedRoute
            path={projectSiteDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <SiteData />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftSiteDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <SiteData isAdminRoute />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectSiteDataRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <SiteData isAdminRoute />
          </GuardedRoute>
          <GuardedRoute
            path={draftProjectOverviewRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectOverview />
          </GuardedRoute>
          <GuardedRoute
            path={projectOverviewRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectOverview />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminDraftOverviewRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectOverview isAdminRoute />
          </GuardedRoute>
          <GuardedRoute
            path={scaniflyAdminProjectOverviewRoute(':projectId')}
            auth={isLoggedIn}
            redirectUrl={rootRoute()}
          >
            <ProjectOverview isAdminRoute />
          </GuardedRoute>
          <Redirect to={projectsListRoute()} />
        </Switch>
      </Layout>
      <Redirect to={rootRoute()} />
    </Switch>
  );
};

export default Root;
