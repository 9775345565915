import styled from 'styled-components';

import { DistantPhoto } from '../DistantPhoto';
import { DistantPhotoListProps } from './types';

const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
`;

function DistantPhotoList({
  distantPhotos,
  imagePreviews,
  onClick,
  selectedPhotos,
}: DistantPhotoListProps) {
  return (
    <StyledList>
      {distantPhotos.map(({ id, meta }) => {
        const isSelected =
          selectedPhotos.findIndex(({ id: selectedPhotoId }) => selectedPhotoId === id) !== -1;

        return (
          <DistantPhoto
            distanceApart={meta.distanceApart ?? 0}
            id={id}
            isSelected={isSelected}
            key={`distant-photo-${id}`}
            onClick={onClick}
            src={imagePreviews[id] ?? ''}
          />
        );
      })}
    </StyledList>
  );
}

export default DistantPhotoList;
