import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FileDoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import { AccountingInfo } from 'types/AccountingInfo';

import { invoiceCreationRequested } from 'state/slices/admin/adminInvoicesSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { openNotification } from 'helpers/utils/openNotification';

import { calculateTotalFeeWithAdditionalFees, checkIfDisabled } from './helpers';

const CreateInvoiceButton = ({
  month,
  year,
  companyId,
  accountingInformation,
}: {
  month: number;
  year: number;
  companyId: string;
  accountingInformation: AccountingInfo;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isInvoiceCreationLoading } = useSelector((state: RootState) => state.adminInvoices);
  const { subscription, stripeInvoice } = accountingInformation || {};
  const { paymentType, contractStartDate } = subscription || {};
  const monthlyTotal = calculateTotalFeeWithAdditionalFees({
    item: accountingInformation,
    month,
    year,
  });
  const isStripeInvoiceCreated = Boolean(stripeInvoice?.status);
  const isSubscriptionCreated = Boolean(subscription);
  const subscriptionStartMonth = contractStartDate
    ? moment.utc(contractStartDate).month()
    : undefined;

  const isDisabled = checkIfDisabled({
    month,
    year,
    monthlyTotal,
    paymentType,
    subscriptionStartMonth,
  });

  useEffect(() => {
    if (isInvoiceCreationLoading === false) {
      setLoading(false);
    }
  }, [isInvoiceCreationLoading]);

  return (
    <Button
      className="Button--White Accounting-Button"
      disabled={isDisabled || isStripeInvoiceCreated || !isSubscriptionCreated}
      icon={<FileDoneOutlined />}
      onClick={() => {
        if (!companyId) {
          openNotification({
            type: 'error',
            title: 'Error!',
            text: 'An invoice cannot be created without an active subscription. Please add a valid subscription before you attempt to create an invoice.',
          });
          return;
        }
        dispatch(invoiceCreationRequested({ companyId, month, year }));
        setLoading(true);
      }}
      loading={loading}
    >
      {t('AccountingForms.chargeAccount')}
    </Button>
  );
};

export default CreateInvoiceButton;
