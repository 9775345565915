import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { Languages } from '../../constants/language';
import en from './en.json';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  fallbackLng: Languages.en,
  interpolation: {
    escapeValue: false,
  },
  lng: Languages.en,
  resources: {
    [Languages.en]: {
      translation: en,
    },
    [Languages.pl]: {
      translation: en,
    },
  },
});

export default i18n;
