import API from '../config';
import {
  addVatUrl,
  createCompanyUrl,
  fetchCompanyBillingInfoUrl,
  fetchCompanyByNameUrl,
  fetchCompanyClientSecretUrl,
  fetchCompanyLogoUrl,
  fetchCompanyMembersUrl,
  fetchCompanySubscriptionInfoUrl,
  fetchCompanyUrl,
  fetchDSPCompaniesUrl,
  fetchUserCompanyUrl,
  updateCompanyBillingInfoUrl,
  updateCompanyCreditCardInfoUrl,
  updateCompanyLogoUrl,
  updateCompanyUrl,
} from './companyUrls';

export const fetchUserCompany = () => API.get(fetchUserCompanyUrl());
export const fetchCompanyBillingInfo = (customerId: string) =>
  API.get(fetchCompanyBillingInfoUrl(customerId));
export const fetchCompanySubcriptionInfo = (companyId: string) =>
  API.get(fetchCompanySubscriptionInfoUrl(companyId));
export const fetchCompany = (companyId: string) => API.get(fetchCompanyUrl(companyId));
export const fetchCompanyExistsByName = (companyName: string) =>
  API.get(fetchCompanyByNameUrl(companyName));
export const fetchCompanyLogo = (token: string) => API.get(fetchCompanyLogoUrl(token));
export const fetchCompanyMembers = () => API.get(fetchCompanyMembersUrl());
export const fetchCompanyClientSecret = () => API.post(fetchCompanyClientSecretUrl());
export const updateCompany = (companyId: string, companyInfo: object) =>
  API.patch(updateCompanyUrl(companyId), companyInfo);
export const updateCompanyCreditCardInfo = (customerId: string, paymentMethodId: string) =>
  API.patch(updateCompanyCreditCardInfoUrl(customerId, paymentMethodId));
export const updateCompanyBillingInfo = (customerId: string, newBillingInfo: object) =>
  API.patch(updateCompanyBillingInfoUrl(customerId), newBillingInfo);
export const updateCompanyLogo = (companyLogo: string) =>
  API.post(updateCompanyLogoUrl(), companyLogo);
export const createCompany = (companyData: object, token: string) =>
  API.post(createCompanyUrl(), companyData, {
    headers: {
      Invitation: token,
    },
  });
export const addVatToCompany = (companyId: string, vatNumber: string, token: string) =>
  API.post(
    addVatUrl(companyId),
    { VATNumber: vatNumber },
    {
      headers: {
        Invitation: token,
      },
    }
  );

export const fetchDSPCompanies = () => {
  return API.get(fetchDSPCompaniesUrl());
};
