import { useTranslation } from 'react-i18next';

import { DESIGN_STATUS, DESIGN_TIER_LEVEL_FILTERS } from '@cpm/scanifly-shared-data';
import ServiceRequest from 'types/ServiceRequest';

import { SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import { formatDate } from 'helpers/utils/dateFormat';

import { DESIGN_COMPLETED_DISPLAY_TITLES } from './OrderList';

const OrderRow = ({
  serviceRequest,
  index,
  serviceRequestsOrdered = [],
  modelRevisionsLength,
  customStyle,
}: {
  serviceRequest: ServiceRequest;
  index: number;
  serviceRequestsOrdered: ServiceRequest[];
  modelRevisionsLength: number;
  customStyle?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className={customStyle}>
      {DESIGN_COMPLETED_DISPLAY_TITLES.map((item) => {
        const { title, field } = item || {};
        let style = 'DesignStatus-Field';
        let value = field
          ? serviceRequest[field]
          : `Order ${serviceRequestsOrdered.length - index}`;
        if (title.toLowerCase().includes('date')) {
          if (field && serviceRequest[field]) {
            value = formatDate(serviceRequest[field]);
          }
        } else if (field === 'tierLevel') {
          value = `${
            DESIGN_TIER_LEVEL_FILTERS.find((item) => item.value === serviceRequest[field])?.text
          }${serviceRequest?.remote ? `\n${t('DesignService.uploadQueue.remoteTag')}` : ''}`;
          style = 'DesignStatus-ProductField';
        } else if (title === 'Order') {
          style = 'DesignStatus-ProductField';
        } else if (
          field === 'userInfo' &&
          `${serviceRequest[field]?.firstName}
        ${serviceRequest[field]?.lastName}`
        ) {
          value = `${serviceRequest[field]?.firstName} ${serviceRequest[field]?.lastName}`;
        } else if (field === 'upgradedFrom') {
          value = serviceRequest[field] ? (
            <SidebarTag type={TagTypes.upgrade} text="Upgrade" />
          ) : (
            <SidebarTag type={TagTypes.upgrade} text="Original" />
          );
        } else if (field === 'modelRevision') {
          value = serviceRequest[field] ? (
            <SidebarTag
              type={TagTypes.upgrade}
              text={`${modelRevisionsLength} Revision${modelRevisionsLength > 1 ? `s` : ''}`}
            />
          ) : null;
        } else if (field === 'status') {
          const tagProps =
            serviceRequest.status === DESIGN_STATUS.canceled
              ? { text: 'Canceled', style: TagTypes.canceled }
              : { text: 'Completed', style: TagTypes.completed };
          value = <SidebarTag type={tagProps.style} text={tagProps.text} />;
        }
        return (
          <div className={style} key={item.title}>
            {value && Object.keys(value).length ? value : '--'}
          </div>
        );
      })}
    </div>
  );
};

export default OrderRow;
