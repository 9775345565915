import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import SimpleDropdown, { SimpleDropdownProps } from './SimpleDropdown';

type EditDropdownProps = {
  label?: string;
} & Pick<
  SimpleDropdownProps,
  | 'renderSelectedOptions'
  | 'selectedOptionIds'
  | 'isMultiSelectEnabled'
  | 'renderValue'
  | 'options'
  | 'onChange'
>;

const EditTextContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  .dropdownContainer {
    width: 100%;
    flex: 5;

    .dropdown {
      padding: 0.5rem;
      margin: 0.5rem 0 0 0;
      border: 1px solid ${colors.neutralGray};
      border-radius: 0.5rem;
      width: 100%;

      button {
        border: 0;
        width: 100%;
        justify-content: flex-start;
        height: unset !important;
        border-width: 0 !important;
        margin: unset !important;
        padding: unset !important;
      }
    }
  }
`;

const EditTextLabel = styled.span`
  margin-right: 1rem;
  font-weight: bold;
  flex: 1;
`;

export const EditDropdown = ({ label, ...rest }: EditDropdownProps) => {
  return (
    <EditTextContainer>
      {label && <EditTextLabel>{label}</EditTextLabel>}
      <SimpleDropdown
        containerClassName={'dropdownContainer'}
        dropdownClassName={'dropdown'}
        {...rest}
      />
    </EditTextContainer>
  );
};
