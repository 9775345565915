import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';
import { PAYMENT_TYPE, PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import { capitalize } from 'lodash';
import moment from 'moment';
import { AccountingInfo } from 'types/AccountingInfo';

import colors from 'helpers/constants/colors';
import fontWeights from 'helpers/constants/fontWeights';
import { scaniflyAdminAccountingRoute } from 'helpers/constants/routes';
import { filterDateFormat } from 'helpers/utils/dateFormat';
import { numberFormatter } from 'helpers/utils/numberFormatter';
import { openNotification } from 'helpers/utils/openNotification';
import { formatUSD } from 'helpers/utils/priceFormatter';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import {
  COMPANY_SIZE_FILTERS,
  DANGER_ZONE_CATEGORY_FILTERS,
  DSP_PAYMENT_TYPE_FILTERS,
  INVOICE_STATUS_FILTERS,
  NO_DSP_CONTRACT,
  PAYMENT_TYPE_FILTERS,
} from './constants';
import CreateInvoiceButton from './CreateInvoiceButton';
import {
  calculateMonthlySubscriptionAmount,
  calculateProfit,
  calculateRemainingProjectCount,
  calculateTotalDspSubscriptionFee,
  calculateTotalFeeWithAdditionalFees,
  calculateTotalOverage,
  getCompanySizeByProfit,
  isEligibleForDesignServicesUpgrade,
  isEligibleForRenewal,
  isEligibleForRenewalOrUpgrade,
  renderDangerZoneCategory,
  renderStyledTableCell,
  selectInvoiceStatusColor,
} from './helpers';
import { RefreshButton } from './RefreshButton';

export const AccountingTableData = (month: number, year: number) => {
  const { t } = useTranslation();
  return [
    {
      title: '#',
      key: 'index',
      width: '6rem',
      render: (_: string, _item: AccountingInfo, index: number) => <span>{index + 1}</span>,
    },
    {
      title: t('AccountingTitles.companyName'),
      key: 'companyName',
      dataIndex: 'companyName',
      width: '20rem',
      defaultSortOrder: 'ascend',
      sorter: (a: AccountingInfo, b: AccountingInfo) => a.companyName.localeCompare(b.companyName),
      render: (name: string, item: AccountingInfo) => (
        <Link to={scaniflyAdminAccountingRoute(item.companyId)}>{name}</Link>
      ),
    },
    {
      title: t('AccountingTitles.stripeCustomerId'),
      key: 'stripeCustomerId',
      dataIndex: 'stripeCustomerId',
      width: '16rem',
      render: (_: string, item: AccountingInfo) =>
        item.subscription?.stripeCustomerId ? item.subscription?.stripeCustomerId : '-',
    },
    {
      title: () => {
        return (
          <div className="Accounting-TooltipWrapper">
            <Tooltip
              placement="topLeft"
              title={t('AccountingTitles.companySizeTooltipText')}
              arrowPointAtCenter
            >
              <QuestionMarkIcon className="Accounting-QuestionMarkIcon" />
            </Tooltip>
            {t('AccountingTitles.companySize')}
          </div>
        );
      },
      key: 'companySize',
      dataIndex: 'companySize',
      width: '20rem',
      render: (_: string, item: AccountingInfo) => {
        if (item.subscription && item.subscription.companySize) {
          return capitalize(item.subscription.companySize);
        }
        if (item.subscription && !item.subscription.companySize) {
          const profit = calculateProfit(
            item.subscription.subscriptionAmount,
            item.subscription.discountPercentage
          );
          return capitalize(getCompanySizeByProfit(profit));
        } else {
          return 'No active contract.';
        }
      },
      filters: COMPANY_SIZE_FILTERS,
      onFilter: (value: string, record: AccountingInfo) => {
        if (record.subscription && record.subscription.companySize) {
          return record.subscription.companySize.toLowerCase() === value.toLowerCase();
        } else if (record.subscription && !record.subscription.companySize) {
          const profit = calculateProfit(
            record.subscription.subscriptionAmount,
            record.subscription.discountPercentage
          );
          return getCompanySizeByProfit(profit) === value.toLowerCase();
        } else {
          return '';
        }
      },
    },
    {
      title: t('AccountingTitles.paymentType'),
      key: 'paymentType',
      dataIndex: 'paymentType',
      width: '20rem',
      render: (_: PAYMENT_TYPE, item: AccountingInfo) => {
        if (item.subscription && item.subscription.paymentType) {
          return capitalize(item.subscription.paymentType);
        } else if (item.subscription && !item.subscription.paymentType) {
          return capitalize(PAYMENT_TYPE.monthly);
        } else {
          return 'No active contract.';
        }
      },
      filters: PAYMENT_TYPE_FILTERS,
      onFilter: (value: PAYMENT_TYPE, record: AccountingInfo) => {
        if (record.subscription && record.subscription.paymentType) {
          return record.subscription.paymentType.toLowerCase() === value.toLowerCase();
        } else if (record.subscription && !record.subscription.paymentType) {
          return PAYMENT_TYPE.monthly === value.toLowerCase();
        } else {
          return '';
        }
      },
    },
    {
      title: t('AccountingTitles.monthlySubscription'),
      key: 'monthlySubscription',
      dataIndex: 'monthlySubscription',
      width: '16rem',
      render: (_: string, item: AccountingInfo) =>
        formatUSD(calculateMonthlySubscriptionAmount(item.subscription, month, year)),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        calculateMonthlySubscriptionAmount(a.subscription, month, year) -
        calculateMonthlySubscriptionAmount(b.subscription, month, year),
    },
    {
      title: t('AccountingTitles.discount'),
      key: 'discount',
      dataIndex: 'discount',
      width: '12rem',
      render: (_: string, item: AccountingInfo) =>
        item.subscription ? item.subscription.discountPercentage + '%' : 0,
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.subscription?.discountPercentage ?? 0) - (b.subscription?.discountPercentage ?? 0),
    },
    {
      title: t('AccountingTitles.dspPaymentType'),
      key: 'dspPaymentType',
      dataIndex: 'dspPaymentType',
      width: '20rem',
      render: (_: PAYMENT_TYPE, item: AccountingInfo) => {
        const { subscription } = item || {};
        const { dspPaymentType } = subscription || {};
        if (subscription && dspPaymentType) {
          return capitalize(dspPaymentType);
        } else if (subscription && !dspPaymentType) {
          return NO_DSP_CONTRACT;
        }
        return t('AccountingTitles.noActiveContract');
      },
      filters: DSP_PAYMENT_TYPE_FILTERS,
      onFilter: (value: string, record: AccountingInfo) => {
        const { subscription } = record || {};
        const { dspPaymentType } = subscription || {};
        if (subscription && dspPaymentType) {
          return dspPaymentType.toLowerCase() === value.toLowerCase();
        } else if (subscription && !dspPaymentType) {
          return value === NO_DSP_CONTRACT;
        } else {
          return '';
        }
      },
    },
    {
      title: t('AccountingTitles.dspSubscriptionFee'),
      key: 'dspSubscriptionFee',
      dataIndex: 'dspSubscriptionFee',
      width: '20rem',
      render: (_: string, item: AccountingInfo) => {
        const { subscription } = item || {};
        return formatUSD(calculateTotalDspSubscriptionFee({ subscription, month, year }));
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) => {
        return (
          calculateTotalDspSubscriptionFee({ subscription: a.subscription, month, year }) -
          calculateTotalDspSubscriptionFee({ subscription: b.subscription, month, year })
        );
      },
    },
    {
      title: t('AccountingTitles.monthlyOverage'),
      key: 'monthlyOverage',
      dataIndex: 'monthlyOverage',
      width: '16rem',
      render(_: string, item: AccountingInfo) {
        const totalOverage = calculateTotalOverage(item);

        return {
          props: {
            style: {
              background: totalOverage > 0 ? colors.yellowHighlight : '',
              fontWeight: totalOverage > 0 ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: formatUSD(totalOverage),
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        calculateTotalOverage(a) - calculateTotalOverage(b),
    },
    {
      title: t('AccountingTitles.dspAdditionalFeeTable'),
      key: 'dspAdditionalFee',
      dataIndex: 'dspAdditionalFee',
      width: '16rem',
      render: (_: string, item: AccountingInfo) => formatUSD(item.dspAdditionalFee ?? 0),
      sorter: (a: AccountingInfo, b: AccountingInfo) => a.dspAdditionalFee - b.dspAdditionalFee,
    },
    {
      title: t('AccountingTitles.monthlyTotal'),
      key: 'monthlyTotal',
      dataIndex: 'monthlyTotal',
      width: '16rem',
      render: (_: string, item: AccountingInfo) =>
        formatUSD(calculateTotalFeeWithAdditionalFees({ item, month, year })),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        calculateTotalFeeWithAdditionalFees({ item: a, month, year }) -
        calculateTotalFeeWithAdditionalFees({ item: b, month, year }),
    },
    {
      title: t('AccountingTitles.action'),
      key: 'action',
      render: (_: string, item: AccountingInfo) => {
        return (
          <CreateInvoiceButton
            month={month}
            year={year}
            companyId={item.companyId}
            accountingInformation={item}
          />
        );
      },
    },
    {
      title: (
        <div className="Accounting-InvoiceTitle">
          {t('AccountingTitles.status')}
          <RefreshButton month={month} year={year} />
        </div>
      ),
      key: 'status',
      dataIndex: 'status',
      width: '19rem',
      render: (_: string, item: AccountingInfo) => {
        const style = selectInvoiceStatusColor(item.stripeInvoice);
        return item.stripeInvoice ? (
          <>
            <a
              href={`https://dashboard.stripe.com/invoices/${item.stripeInvoice.stripeInvoiceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={cn('Accounting-StatusLink', style)}>
                {INVOICE_STATUS_FILTERS.find(
                  (status) => status.value === item.stripeInvoice?.status
                )?.text ?? ''}
              </div>
            </a>
            {item.stripeInvoice.status === 'failed' && item.stripeInvoice.failMessage ? (
              <small>{item.stripeInvoice.failMessage}</small>
            ) : null}
          </>
        ) : (
          t('AccountingTitles.invoiceNotCreated')
        );
      },
      filters: INVOICE_STATUS_FILTERS,
      onFilter: (value: string, record: AccountingInfo) =>
        record.stripeInvoice
          ? record.stripeInvoice.status.toLowerCase() === value.toLowerCase()
          : value === 'not created',
    },
    {
      title: t('AccountingTitles.stripeInvoice'),
      key: 'stripeInvoice',
      render: (_: string, item: AccountingInfo) => {
        return (
          <Button
            className="Button--White Accounting-Button-Wrapper"
            href={
              item.stripeInvoice && item.stripeInvoice.status !== 'draft'
                ? item.stripeInvoice?.invoicePDFUrl
                : undefined
            }
            disabled={!item.stripeInvoice || item.stripeInvoice.status === 'draft'}
            aria-disabled={!item.stripeInvoice}
            icon={<DownloadOutlined />}
            onClick={() => {
              if (item.stripeInvoice && item.stripeInvoice.status !== 'draft') {
                openNotification({
                  type: 'success',
                  title: 'Success!',
                  text: 'The PDF will automatically start downloading soon.',
                });
              }
            }}
          >
            {t('AccountingTitles.download')}
          </Button>
        );
      },
    },
    {
      title: () => {
        return (
          <div className="Accounting-TooltipWrapper">
            <Tooltip
              placement="topLeft"
              title={
                <section>
                  <div>{t('DangerZone.title')}</div>
                  <ol>
                    <li>{t('DangerZone.renewalDescription')}</li>
                    <li>{t('DangerZone.additionalFeesDescription')}</li>
                    <li>{t('DangerZone.inactiveDescription')}</li>
                    <li>{t('DangerZone.upgradeDescription')}</li>
                  </ol>
                </section>
              }
              overlayStyle={{ minWidth: '50rem' }}
              arrowPointAtCenter
            >
              <QuestionMarkIcon className="Accounting-QuestionMarkIcon" />
            </Tooltip>
            {t('AccountingTitles.dangerZone')}
          </div>
        );
      },
      key: 'dangerZone',
      width: '18rem',
      render: (_: string, item: AccountingInfo) => renderDangerZoneCategory(item),
      filters: DANGER_ZONE_CATEGORY_FILTERS,
      onFilter: (value: string, record: AccountingInfo) => {
        if (record.subscription) {
          return renderDangerZoneCategory(record)
            .toLowerCase()
            .split(' ')
            .join('')
            .includes(value.toLowerCase());
        } else {
          return '';
        }
      },
    },
    {
      title: t('AccountingTitles.contractStartDate'),
      key: 'contractStartDate',
      dataIndex: 'contractStartDate',
      width: '18rem',
      render: (_: string, item: AccountingInfo) =>
        item.subscription
          ? moment.utc(item.subscription.contractStartDate).format(filterDateFormat)
          : t('AccountingTitles.noActiveContract'),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        moment.utc(a.subscription?.contractStartDate).valueOf() -
        moment.utc(b.subscription?.contractStartDate).valueOf(),
    },
    {
      title: t('AccountingTitles.contractEndDate'),
      key: 'contractEndDate',
      dataIndex: 'contractEndDate',
      width: '17rem',
      render(_: string, item: AccountingInfo) {
        const { subscription } = item || {};
        const isHighlighted = isEligibleForRenewal(item);
        return {
          props: {
            style: {
              background: isHighlighted ? colors.yellowHighlight : '',
              fontWeight: isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: subscription
            ? moment.utc(subscription.contractEndDate).format(filterDateFormat)
            : t('AccountingTitles.noActiveContract'),
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        moment.utc(a.subscription?.contractEndDate).valueOf() -
        moment.utc(b.subscription?.contractEndDate).valueOf(),
    },
    {
      title: t('AccountingTitles.smallSubscription'),
      key: 'smallSubscription',
      dataIndex: 'smallSubscription',
      width: '17rem',
      render: (_: number, item: AccountingInfo) =>
        item.subscription ? numberFormatter(item.subscription?.smallProjectQuota) : 0,
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.subscription?.smallProjectQuota ?? 0) - (b.subscription?.smallProjectQuota ?? 0),
    },
    {
      title: t('AccountingTitles.largeSubscription'),
      key: 'largeSubscription',
      dataIndex: 'largeSubscription',
      width: '17rem',
      render: (_: number, item: AccountingInfo) =>
        item.subscription ? numberFormatter(item.subscription?.largeProjectQuota) : 0,
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.subscription?.largeProjectQuota ?? 0) - (b.subscription?.largeProjectQuota ?? 0),
    },
    {
      title: t('AccountingTitles.smallProjectsByMonth'),
      key: 'smallProjectsByMonth',
      dataIndex: 'smallProjectsByMonth',
      width: '18rem',
      render(_: number, item: AccountingInfo) {
        return renderStyledTableCell(item, 'smallProjectsByMonth', 'smallProjectQuota');
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.smallProjectsByMonth ?? 0) - (b.smallProjectsByMonth ?? 0),
    },
    {
      title: t('AccountingTitles.largeProjectsByMonth'),
      key: 'largeProjectsByMonth',
      dataIndex: 'largeProjectsByMonth',
      width: '18rem',
      render(_: number, item: AccountingInfo) {
        return renderStyledTableCell(item, 'largeProjectsByMonth', 'largeProjectQuota');
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.largeProjectsByMonth ?? 0) - (b.largeProjectsByMonth ?? 0),
    },
    {
      title: t('AccountingTitles.smallProjectsBySubscriptionStartDate'),
      key: 'smallProjectsBySubscriptionStartDate',
      dataIndex: 'smallProjectsBySubscriptionStartDate',
      width: '17rem',
      render: (smallProjectsBySubscriptionStartDate: number, item: AccountingInfo) => {
        const isHighlighted = isEligibleForRenewalOrUpgrade(item, PROJECT_TYPES.SMALL);
        return {
          props: {
            style: {
              background: isHighlighted ? colors.yellowHighlight : '',
              fontWeight: isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: item.subscription ? numberFormatter(smallProjectsBySubscriptionStartDate) : '0',
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.smallProjectsBySubscriptionStartDate ?? 0) -
        (b.smallProjectsBySubscriptionStartDate ?? 0),
    },
    {
      title: t('AccountingTitles.largeProjectsBySubscriptionStartDate'),
      key: 'largeProjectsBySubscriptionStartDate',
      dataIndex: 'largeProjectsBySubscriptionStartDate',
      width: '17rem',
      render: (largeProjectsBySubscriptionStartDate: number, item: AccountingInfo) => {
        const isHighlighted = isEligibleForRenewalOrUpgrade(item, PROJECT_TYPES.LARGE);
        return {
          props: {
            style: {
              background: isHighlighted ? colors.yellowHighlight : '',
              fontWeight: isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: item.subscription ? numberFormatter(largeProjectsBySubscriptionStartDate) : '0',
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.largeProjectsBySubscriptionStartDate ?? 0) -
        (b.largeProjectsBySubscriptionStartDate ?? 0),
    },
    {
      title: t('AccountingTitles.smallProjectsRemaining'),
      key: 'smalProjectsRemaining',
      dataIndex: 'smallProjectsRemaining',
      width: '16rem',
      render(_: number, item: AccountingInfo) {
        const remainingProjectCount = calculateRemainingProjectCount(item, PROJECT_TYPES.SMALL);
        const isHighlighted = isEligibleForRenewalOrUpgrade(item, PROJECT_TYPES.SMALL);
        return {
          props: {
            style: {
              background: remainingProjectCount && isHighlighted ? colors.yellowHighlight : '',
              fontWeight:
                remainingProjectCount && isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: numberFormatter(remainingProjectCount),
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        calculateRemainingProjectCount(a, PROJECT_TYPES.SMALL) -
        calculateRemainingProjectCount(b, PROJECT_TYPES.SMALL),
    },
    {
      title: t('AccountingTitles.largeProjectsRemaining'),
      key: 'largeProjectsRemaining',
      dataIndex: 'largeProjectsRemaining',
      width: '16rem',
      render(_: number, item: AccountingInfo) {
        const remainingProjectCount = calculateRemainingProjectCount(item, PROJECT_TYPES.LARGE);
        const isHighlighted = isEligibleForRenewalOrUpgrade(item, PROJECT_TYPES.LARGE);
        return {
          props: {
            style: {
              background: remainingProjectCount && isHighlighted ? colors.yellowHighlight : '',
              fontWeight:
                remainingProjectCount && isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: numberFormatter(remainingProjectCount),
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        calculateRemainingProjectCount(a, PROJECT_TYPES.LARGE) -
        calculateRemainingProjectCount(b, PROJECT_TYPES.LARGE),
    },
    {
      title: t('AccountingTitles.creditsBalance'),
      key: 'creditsBalance',
      dataIndex: 'creditsBalance',
      width: '16rem',
      render(creditsBalance: number, item: AccountingInfo) {
        const isHighlighted = isEligibleForDesignServicesUpgrade(item);
        return {
          props: {
            style: {
              background: isHighlighted ? colors.yellowHighlight : '',
              fontWeight: isHighlighted ? fontWeights.semiBold : fontWeights.normal,
            },
          },
          children: numberFormatter(creditsBalance),
        };
      },
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.creditsBalance ?? 0) - (b.creditsBalance ?? 0),
    },
    {
      title: t('AccountingTitles.totalPurchasedCredits'),
      key: 'totalPurchasedCredits',
      dataIndex: 'totalPurchasedCredits',
      width: '16rem',
      render: (_: number, item: AccountingInfo) =>
        (item.totalPurchasedCredits ?? 0).toLocaleString('en-US'),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.totalPurchasedCredits ?? 0) - (b.totalPurchasedCredits ?? 0),
    },
    {
      title: t('AccountingTitles.nearmapCreditsUsedBySubscriptionStart'),
      key: 'nearmapCreditsUsedBySubscriptionStart',
      dataIndex: 'nearmapCreditsUsedBySubscriptionStart',
      width: '18rem',
      render: (_: number, item: AccountingInfo) =>
        (item.nearmapCreditsUsedBySubscriptionStart ?? 0).toLocaleString('en-US'),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.nearmapCreditsUsedBySubscriptionStart ?? 0) -
        (b.nearmapCreditsUsedBySubscriptionStart ?? 0),
    },
    {
      title: t('AccountingTitles.designServiceCreditsUsedBySubscriptionStart'),
      key: 'designServiceCreditsUsedBySubscriptionStart',
      dataIndex: 'designServiceCreditsUsedBySubscriptionStart',
      width: '18rem',
      render: (_: number, item: AccountingInfo) =>
        (item.designServiceCreditsUsedBySubscriptionStart ?? 0).toLocaleString('en-US'),
      sorter: (a: AccountingInfo, b: AccountingInfo) =>
        (a.designServiceCreditsUsedBySubscriptionStart ?? 0) -
        (b.designServiceCreditsUsedBySubscriptionStart ?? 0),
    },
  ];
};
