import React from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { openNotification } from 'helpers/utils/openNotification';
import AccessTokenStyle from './AccessTokenStyle';

interface TokenDisplayModalProps {
  handleModalClose: () => void;
  accessToken: string | null;
}

const GenerateTokenDisplayModal: React.FC<TokenDisplayModalProps> = ({
  handleModalClose,
  accessToken,
}) => {
  return (
    <AccessTokenStyle>
      <div className="AccessToken-GenerateTokenModal-ContentWrapper">
        <div className="AccessToken-GenerateTokenModal-Description">
          The created access token is only going to be shown here once, so be sure to copy it to
          your clipboard:
        </div>
        <div className="AccessToken-GenerateTokenModal-Token">
          <div
            className="AccessToken-GenerateTokenModal-TokenText"
            aria-label="copy access token value"
          >
            {accessToken}
          </div>
          <CopyOutlined
            role="button"
            aria-label="copy access token value"
            className="AccessToken-ButtonWrapper-CopyIcon"
            onClick={async () => {
              if (accessToken) {
                try {
                  await navigator.clipboard.writeText(accessToken);
                  openNotification({
                    type: 'success',
                    title: 'Copied!',
                    text: 'Access token has been copied to the clipboard.',
                  });
                } catch {
                  openNotification({
                    type: 'error',
                    title: 'Copy Failed',
                    text: 'Failed to copy access token to the clipboard. Please copy it manually.',
                  });
                }
              }
            }}
          />
        </div>
        <div className="AccessToken-GenerateTokenModal-Buttons-Wrapper">
          <Button onClick={handleModalClose} className="Button--Red">
            Close
          </Button>
        </div>
      </div>
    </AccessTokenStyle>
  );
};

export default GenerateTokenDisplayModal;
