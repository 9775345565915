import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';

export const filterProjectsByType = (value, record) => {
  if (value === PROJECT_TYPES.SMALL) {
    return (
      record.type === PROJECT_TYPES.SMALL_COMMERCIAL ||
      record.type === PROJECT_TYPES.RESIDENTIAL ||
      record.type === PROJECT_TYPES.SMALL
    );
  } else if (value === PROJECT_TYPES.LARGE) {
    return (
      record.type === PROJECT_TYPES.COMMERCIAL ||
      record.type === PROJECT_TYPES.UTILITY ||
      record.type === PROJECT_TYPES.COMMERCIALRC ||
      record.type === PROJECT_TYPES.LARGE
    );
  }
  return false;
};
