import { Button, Row } from 'antd';

import { mapCountryCodeToName } from 'helpers/utils/mapCountryNameToCode';

import AccountPaymentsCustomerInformation from '../AccountPaymentsCustomerInformation';

export const renderBillingInfoDisplay = ({
  companyBillingInfo,
  toggleBillingInformationFormVisible,
  isBillingInformationFormVisible,
  handleBillingInfoFormVisibility,
}) => {
  const country =
    mapCountryCodeToName(companyBillingInfo?.customerAddress?.country) ??
    companyBillingInfo?.customerAddress?.country;
  const { customerName, customerEmail, vatNumber, customerAddress } = companyBillingInfo || {};
  const { line, city, state, zip } = customerAddress || {};

  if (isBillingInformationFormVisible) {
    return (
      <AccountPaymentsCustomerInformation
        companyBillingInfo={companyBillingInfo}
        toggleForm={toggleBillingInformationFormVisible}
      />
    );
  } else {
    return (
      <>
        <h2 className="AccountPayments-SubTitle">Billing Contact</h2>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div
              className="AccountPayments-Title"
              aria-label="Billing Info Form company name field"
            >
              Company Name
            </div>
            <span className="AccountPayments-Data">{customerName ? customerName : '-'}</span>
          </div>
          <div className="AccountPayments-Wrapper">
            <div
              className="AccountPayments-Title"
              aria-label="Billing Info Form billing email field"
            >
              Email
            </div>
            <div className="AccountPayments-Data">{customerEmail ? customerEmail : '-'}</div>
          </div>
        </Row>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Billing Info Form vat number field">
              VAT#
            </div>
            <span className="AccountPayments-Data">{vatNumber ? vatNumber : '-'}</span>
          </div>
        </Row>

        <h2 className="AccountPayments-SubTitle">Billing Address</h2>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Billing Info Form country field">
              Country
            </div>
            <span className="AccountPayments-Data">{country ? country : '-'}</span>
          </div>

          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Billing Info Form street field">
              Street
            </div>
            <span className="AccountPayments-Data">{line ? line : '-'}</span>
          </div>
        </Row>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Billing Info Form city field">
              City
            </div>
            <span className="AccountPayments-Data">{city ? city : '-'}</span>
          </div>

          {country === 'United States' ? (
            <div className="AccountPayments-Wrapper">
              <div className="AccountPayments-Title" aria-label="Billing Info Form state field">
                State
              </div>
              <span className="AccountPayments-Data">{state ? state : '-'}</span>
            </div>
          ) : (
            <div className="AccountPayments-Wrapper">
              <div className="AccountPayments-Title" aria-label="Billing Info Form zipcode field">
                ZIP Code
              </div>
              <span className="AccountPayments-Data">{zip ? zip : '-'}</span>
            </div>
          )}
        </Row>
        {country === 'United States' ? (
          <Row className="AccountPayments-Row">
            <div className="AccountPayments-Wrapper">
              <div className="AccountPayments-Title" aria-label="Billing Info Form zipcode field">
                ZIP Code
              </div>
              <span className="AccountPayments-Data">{zip ? zip : '-'}</span>
            </div>
          </Row>
        ) : null}

        <div className="AccountPayments-ButtonWrapper">
          <Button
            onClick={handleBillingInfoFormVisibility}
            className="Button--Blue AccountPayments-UpdateBillingInfo"
            data-testid="update-billing-info-first-button"
          >
            Update Billing Info
          </Button>
        </div>
      </>
    );
  }
};
