import { LabelTitle } from 'components/Form/styledComponents';
import { FormikProps } from 'formik';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { TFunction } from 'i18next';
import SizedForTolerance from 'screens/DesignServices/OrderDesign/DesignSelection/FormWrapper/TrueUpForms/SizedForTolerance';
import styled from 'styled-components';

const TrueUpWrapper = styled.div`
  padding: 3rem;
  background-color: ${colors.background};
  margin: 3rem 0;
  border-radius: 3rem;
`;

const Subtitle = styled.div`
  color: ${colors.labelGray};
  line-height: 1.7rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
  font-size: ${fontSizes.extraSmall};
`;

const StyledLabelTitle = styled(LabelTitle)`
  font-weight: ${fontWeights.semiBold};
  margin-left: 0.5rem;
`;

export const TrueUpSettings = ({
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  t,
}: { t: TFunction<'translation', undefined> } & Partial<FormikProps<any>>) => {
  return (
    <TrueUpWrapper>
      <StyledLabelTitle>{t('CompanySettings.trueUp')}</StyledLabelTitle>
      <Subtitle>{t('CompanySettings.trueUpDescription')}</Subtitle>
      <SizedForTolerance
        getFieldProps={getFieldProps}
        setFieldValue={setFieldValue}
        touched={touched}
        errors={errors}
        t={t}
      />
    </TrueUpWrapper>
  );
};
