import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { Notification } from 'types';

import { notificationUpdate } from 'state/slices/notificationSlice';
import { AppDispatch, RootState } from 'state/store/store';

import {
  ButtonColumn,
  Column,
  HiddenBorder,
  IconWrapper,
  InfoText,
  Main,
  PressableArea,
  ProjectName,
  Row,
  StyledCloseIcon,
  StyledMentionIcon,
  StyledMentionText,
  Time,
  UnreadIcon,
} from './components';

import { projectNotesRoute } from 'helpers/constants/routes';

type Props = {
  notification: Notification;
  unread: boolean;
};
function Mention({ notification, unread }: Props) {
  const history = useHistory();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const userId = currentUser?.id ?? '';
  const { id: notificationId, hasRead, visible } = notification;
  const hiddenToUser = [...visible, userId];
  const userHasRead = [...visible, userId];
  const isHidden = visible.includes(userId) ? true : false;
  const read = hasRead.includes(userId) ? true : false;
  const dispatch: AppDispatch = useDispatch();

  const hideNotification = () => {
    dispatch(notificationUpdate({ userId, notificationId, hasRead, visible: hiddenToUser }));
  };

  const navigateToComment = () => {
    history.push(projectNotesRoute(notification.projectId));
  };

  const showNotifications = () => {
    if (read) {
      const index = notification.hasRead.indexOf(userId);

      if (index > -1) {
        const markUnread = [...hasRead];
        markUnread.splice(index, 1);
        dispatch(notificationUpdate({ userId, notificationId, hasRead: markUnread, visible }));
      }
    }
    if (!read) {
      dispatch(notificationUpdate({ userId, notificationId, hasRead: userHasRead, visible }));
    }
  };

  if (unread && read) return null;
  if (isHidden) return null;
  else
    return (
      <Main onClick={navigateToComment}>
        <Row large>
          <IconWrapper>
            <StyledMentionIcon />
          </IconWrapper>
          <Column>
            <Row>
              <InfoText>
                {`${notification.createdBy.firstName} ${notification.createdBy.lastName} mentioned you in a comment`}
                <InfoText>
                  {`on `}
                  <ProjectName>{`${notification.projectName}`}</ProjectName>
                </InfoText>
              </InfoText>
              <ButtonColumn>
                <HiddenBorder onClick={showNotifications}>
                  <UnreadIcon hasRead={read} />
                </HiddenBorder>
                <PressableArea onClick={hideNotification}>
                  <StyledCloseIcon />
                </PressableArea>
              </ButtonColumn>
            </Row>

            <Time>{moment(notification.createdAt).fromNow()}</Time>
            <StyledMentionText comment={notification.text} />
          </Column>
        </Row>
      </Main>
    );
}

type MentionProps = {
  notifications: Notification[];
  unread: boolean;
};

function Mentions({ notifications, unread }: MentionProps) {
  if (!notifications) {
    return null;
  }

  return (
    <>
      {notifications.map((notification) => (
        <div key={notification.id}>
          <ul className="Header-Menu">
            <li key={notification.id}>
              <Mention notification={notification} unread={unread} />
            </li>
          </ul>
        </div>
      ))}
    </>
  );
}

export default Mentions;
