import { useTranslation } from 'react-i18next';

import { Col, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { getDefaultValue } from '../helpers/getDefaultValue';
import { getRadioButtonTitle } from '../helpers/getRadioButtonTitle';

import './ItemsUploadedRadioButtons.scss';

interface IItemsUploadedRadioButtons {
  handleFilesInAlbum: (e: RadioChangeEvent) => void;
  filesAlreadyStored: null | boolean;
  isPrelim?: boolean;
  isRevision?: boolean;
  handleNewBuildPlans?: (e: RadioChangeEvent) => void;
  areNewBuildPlansRequired?: boolean;
}

const ItemsUploadedRadioButtons = ({
  handleFilesInAlbum,
  filesAlreadyStored,
  isPrelim = false,
  isRevision = false,
  handleNewBuildPlans,
  areNewBuildPlansRequired,
}: IItemsUploadedRadioButtons) => {
  const { Group } = Radio;
  const { t } = useTranslation();

  return (
    <Col span={24} className="ItemsUploadedRadioButtons">
      <div>{getRadioButtonTitle({ isPrelim, isRevision })}</div>
      <Group
        onChange={isPrelim && !isRevision ? handleNewBuildPlans : handleFilesInAlbum}
        value={getDefaultValue({
          isPrelim,
          isRevision,
          areNewBuildPlansRequired,
          filesAlreadyStored,
        })}
        className="radio-button-wrapper"
      >
        <Radio value={true} data-testid="radio-button-yes">
          {t('DesignService.planSet.areItemsUploaded.yes')}
        </Radio>
        <Radio value={false} data-testid="radio-button-no">
          {t('DesignService.planSet.areItemsUploaded.no')}
        </Radio>
      </Group>
    </Col>
  );
};

export default ItemsUploadedRadioButtons;
