//TODO: add i18next
import { PERMISSION } from '@cpm/scanifly-shared-data';

export const permissionsMappedToLabels = new Map([
  [PERMISSION.BILLING, 'Billing'],
  [PERMISSION.UPLOAD, 'Create New Projects'],
  [PERMISSION.WATT3D, 'Scanifly3D Design Space'],
  [PERMISSION.SIMPLEDESIGN, 'Simple Design (Sales Rep)'],
  [PERMISSION.SALESMANAGER, 'Simple Design (Sales Manager)'],
]);
