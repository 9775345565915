import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerLabel = styled.div`
  font-weight: bold;
  margin: 1rem 0;
`;

const BooleanAnswerWrapper = styled.div`
  display: flex;
`;

type Props = {
  answer: boolean | null;
};

export const BooleanAnswer = ({ answer }: Props) => {
  const { t } = useTranslation();
  return (
    <AnswerWrapper>
      <AnswerLabel>{t('Checklists.answer')}</AnswerLabel>
      <BooleanAnswerWrapper>
        {answer !== null && `${answer ? 'True' : 'False'}`}
      </BooleanAnswerWrapper>
    </AnswerWrapper>
  );
};
