import { createContext, ReactNode, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import useToggle from 'helpers/hooks/useToggle';

type SidebarContextProps = {
  isSidebarCollapsed: boolean;
  toggleisSidebarCollapsed: () => void;
};

type SidebarProviderProps = {
  children: ReactNode;
};

const sidebarContextProps: SidebarContextProps = {
  isSidebarCollapsed: false,
  toggleisSidebarCollapsed: () => {},
};

export const SidebarContext = createContext<SidebarContextProps>(sidebarContextProps);

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isSidebarCollapsed, toggleisSidebarCollapsed] = useToggle(isMobile);

  const value = useMemo(
    () => ({
      isSidebarCollapsed,
      toggleisSidebarCollapsed,
    }),
    [isSidebarCollapsed, toggleisSidebarCollapsed]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
}

SidebarProvider.Context = SidebarContext;
