import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { camelCase } from 'lodash';
import { PRELIM_DESIGN_OPTIONS } from 'screens/DesignServices/constants';
import SelectDesignButton from '../../SelectDesignButton/SelectDesignButton';

type Props = {
  selected: {
    tierLevel: TIER_LEVEL;
    isExpedited: boolean;
  } | null;
  handleSelection: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
};

export const PrelimOptionsWrapper = ({ selected, handleSelection }: Props) => {
  return PRELIM_DESIGN_OPTIONS.map((option) => {
    return (
      <SelectDesignButton
        option={option}
        selected={selected}
        handleSelect={handleSelection}
        key={`${option.serviceType}-${option.isExpedited ? 'expedited' : 'nonexpedited'}`}
        hasNewTag={option?.hasNewTag ?? false}
        isExpedited={option.isExpedited ?? false}
        id={`${camelCase(option.serviceType)}-${option.isExpedited ? 'expedited' : 'nonexpedited'}`}
        isPrelim={true}
      />
    );
  });
};
