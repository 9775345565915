import jwtDecode from 'jwt-decode';

type DecodedJwt = {
  // @todo I haven't seen a decoded JWT contain these two fields yet
  // but SignUpMainInformation.ts thinks they do
  companyName?: string;
  companyId?: string;

  email: string;
  sub: string;
  iat: number;
  exp: number;
};

function decode(token: string) {
  return jwtDecode<DecodedJwt>(token);
}

function isExpired(token: string | null) {
  if (!token) {
    return true;
  }
  const { exp } = decode(token);
  return new Date().getTime() >= exp * 1000;
}

const jwtUtils = {
  decode,
  isExpired,
};

export default jwtUtils;
