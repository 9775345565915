import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Row } from 'antd';
import { FieldInputProps } from 'formik';

import { RootState } from 'state/store/store';

import { CustomSelect } from 'components';

import { isProductionEnv, isStagingEnv } from 'helpers/utils/environment/environment';

type Option = {
  text: string;
  value: string;
};

const PlanSetProviderSelect = ({
  setFieldValue,
  getFieldProps,
  field,
  formControl,
}: {
  setFieldValue: (field: string, value: string) => void;
  getFieldProps: (field: string) => FieldInputProps<any>;
  field: string;
  formControl: string;
}) => {
  const { t } = useTranslation();
  const { dspCompanies } = useSelector((state: RootState) => state.company);
  const { selectedSubscription } = useSelector((state: RootState) => state.adminSubscriptions);

  // Prod default design service provider is ESR. Company ID: '663363aaa5196dac477d54d5'
  // Staging default design service provider is EJG. Company ID: '6318e9973c97e1bcd00a850e'
  const ESRCompanyId = '663363aaa5196dac477d54d5';
  const EJGCompanyId = '6318e9973c97e1bcd00a850e';

  const defaultProviderCompanyId = isStagingEnv ? EJGCompanyId : ESRCompanyId;

  // Currently there are 2 companies in production that can do plansets
  // These are Saavi (Company ID: '63cad378abc4b500070268e3') and ESR (Company ID: '663363aaa5196dac477d54d5')
  const dspOptions = isProductionEnv
    ? dspCompanies.filter(
        (company) =>
          company.value === '63cad378abc4b500070268e3' ||
          company.value === '663363aaa5196dac477d54d5'
      )
    : dspCompanies;

  useEffect(() => {
    const defaultProvider = dspCompanies.find(
      (company) =>
        company.value === (selectedSubscription?.planSetProvider ?? defaultProviderCompanyId)
    );

    if (defaultProvider) {
      setFieldValue(formControl, defaultProvider.value);
    }
  }, [
    defaultProviderCompanyId,
    dspCompanies,
    formControl,
    selectedSubscription?.planSetProvider,
    setFieldValue,
  ]);

  return (
    <Row className="Accounting-Form-Row">
      <div className="FormControl-Wrapper">
        <label htmlFor={field}>{t('AccountingForms.planSetProvider')}</label>
        <CustomSelect
          {...getFieldProps(formControl)}
          onChange={(option: Option) => {
            setFieldValue(formControl, option.value);
          }}
          placeholder={t('AccountingForms.selectDefaultPlanSetProvider')}
          options={dspOptions.map((type) => ({ value: type.value, label: type.title }))}
          variant="filter"
          id={field}
          tall
        />
      </div>
    </Row>
  );
};

export { PlanSetProviderSelect };
