import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Row, Tooltip } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { companyBillingInfoUpdated } from 'state/slices/companySlice';

import { CountrySelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { mapCountryCodeToName, mapCountryNameToCode } from 'helpers/utils/mapCountryNameToCode';
import { isSame } from '../helpers/isSame';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import { FORM_CONTROLS } from '../constants/formControls';
import { initialValuesForBillingInfo } from '../constants/initialValues';
import './AccountPaymentsCustomerInformation.scss';
import { validationSchema } from './validationSchema';

const AccountPaymentsCustomerInformation = ({ toggleForm, companyBillingInfo }) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);

  const handleUpdateCustomer = useCallback(
    (newBillingInfo) => {
      dispatch(
        companyBillingInfoUpdated({ customerId: company?.customerId, newBillingInfo, toggleForm })
      );
    },
    [dispatch, company?.customerId, toggleForm]
  );

  const {
    touched,
    handleSubmit,
    errors,
    isValid,
    getFieldProps,
    setFieldValue,
    values,
    handleBlur,
    validateForm,
  } = useFormik({
    initialValues: initialValuesForBillingInfo,
    validationSchema,
    onSubmit: (values) => {
      const formattedValuesForCustomer = {
        name: values.name,
        email: values.email,
        address: {
          city: values.city,
          country: mapCountryNameToCode(values.country),
          state: values.country === 'United States' ? values.state : '',
          line: values.street,
          zip: values.zipCode,
        },
        vatNumber: values.vatNumber,
      };
      handleUpdateCustomer(formattedValuesForCustomer);
    },
  });

  const [handleCountryBlur, getCountrySelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.COUNTRY
  );

  useEffect(() => {
    if (!isValid) {
      validateForm();
    }
  }, [isValid, validateForm, values]);

  const formNotEdited = useMemo(
    () => companyBillingInfo === null || isSame(companyBillingInfo, values),
    [companyBillingInfo, values]
  );

  useEffect(() => {
    if (companyBillingInfo) {
      setFieldValue(FORM_CONTROLS.NAME, companyBillingInfo?.customerName);
      setFieldValue(FORM_CONTROLS.EMAIL, companyBillingInfo?.customerEmail);
      setFieldValue(
        FORM_CONTROLS.COUNTRY,
        mapCountryCodeToName(companyBillingInfo?.customerAddress?.country) ??
          companyBillingInfo?.customerAddress?.country
      );
      setFieldValue(FORM_CONTROLS.STATE, companyBillingInfo?.customerAddress?.state);
      setFieldValue(FORM_CONTROLS.STREET, companyBillingInfo?.customerAddress?.line);
      setFieldValue(FORM_CONTROLS.CITY, companyBillingInfo?.customerAddress?.city);
      setFieldValue(FORM_CONTROLS.ZIP_CODE, companyBillingInfo?.customerAddress?.zip);
      setFieldValue(FORM_CONTROLS.VAT_NUMBER, companyBillingInfo?.vatNumber);
    }
  }, [companyBillingInfo, setFieldValue]);

  return (
    <form className="AccountPaymentsCustomerInformation">
      <h2 className="AccountPaymentsCustomerInformation-SubTitle">Billing Contact</h2>
      <Row className="AccountPaymentsCustomerInformation-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="AccountPaymentsCustomerInformationName">Company Name</label>
          <Input
            placeholder="Name"
            className={validateStatus(touched, errors, FORM_CONTROLS.NAME)}
            id="AccountPaymentsCustomerInformationName"
            {...getFieldProps(FORM_CONTROLS.NAME)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.NAME)}
          </div>
        </div>
        <div className="FormControl-Wrapper">
          <label htmlFor="AccountPaymentsCustomerInformationEmail">Billing Email</label>
          <Input
            placeholder="name@company.com"
            className={validateStatus(touched, errors, FORM_CONTROLS.EMAIL)}
            id="AccountPaymentsCustomerInformationEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
          </div>
        </div>
      </Row>

      <Row className="AccountPaymentsCustomerInformation-Row">
        <div className="FormControl-Wrapper">
          <label
            className="AccountPaymentsCustomerInformation-Label"
            htmlFor="AccountPaymentsCustomerInformationVatNumber"
          >
            VAT#
            <Tooltip
              placement="topLeft"
              title={`This field is optional. If you're filling it in, you are responsible for ensuring the name, address and VAT# exactly match the information on ${
                values.country === 'United Kingdom'
                  ? 'United Kingdom’s Revenue & Customs (HMRC)'
                  : 'European Commission’s VAT Information Exchange System (VIES)'
              }.`}
              arrowPointAtCenter
            >
              <QuestionMarkIcon className="AccountPaymentsCustomerInformation-QuestionMarkIcon" />
            </Tooltip>
          </label>
          <Input
            placeholder="Enter VAT Number"
            id="AccountPaymentsCustomerInformationVatNumber"
            {...getFieldProps(FORM_CONTROLS.VAT_NUMBER)}
          />
        </div>
      </Row>

      <h2 className="AccountPaymentsCustomerInformation-SubTitle">Billing Address</h2>
      <Row className="AccountPaymentsCustomerInformation-Row">
        <div className="FormControl-Wrapper FormControl-Wrapper--Country">
          <CountrySelect
            {...getFieldProps(FORM_CONTROLS.COUNTRY)}
            formControl={FORM_CONTROLS.COUNTRY}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            onBlur={handleCountryBlur}
            error={getCountrySelectError(values)}
            variant="form"
          />
          <div className="Form-Error">
            {getCountrySelectError(values) && errors[FORM_CONTROLS.COUNTRY]}
          </div>
        </div>
      </Row>
      <Row className="AccountPaymentsCustomerInformation-Row">
        <div className="FormControl-Wrapper FormControl-Wrapper--Long">
          <label htmlFor="AccountPaymentsCustomerInformationStreet">Street</label>
          <Input
            placeholder="Street"
            className={validateStatus(touched, errors, FORM_CONTROLS.STREET)}
            id="AccountPaymentsCustomerInformationStreet"
            {...getFieldProps(FORM_CONTROLS.STREET)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.STREET)}
          </div>
        </div>
      </Row>

      <Row className="AccountPaymentsCustomerInformation-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="AccountPaymentsCustomerInformationCity">City</label>
          <Input
            placeholder="City"
            className={validateStatus(touched, errors, FORM_CONTROLS.CITY)}
            id="AccountPaymentsCustomerInformationCity"
            {...getFieldProps(FORM_CONTROLS.CITY)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CITY)}
          </div>
        </div>

        {values.country === 'United States' ? (
          <div className="FormControl-Wrapper FormControl-Wrapper--Short">
            <label htmlFor="AccountPaymentsCustomerInformationState">State</label>
            <Input
              placeholder="State"
              className={validateStatus(touched, errors, FORM_CONTROLS.STATE)}
              id="AccountPaymentsCustomerInformationState"
              {...getFieldProps(FORM_CONTROLS.STATE)}
            />
          </div>
        ) : null}

        <div className="FormControl-Wrapper FormControl-Wrapper--Short">
          <label htmlFor="AccountPaymentsCustomerInformationZip">ZIP Code</label>
          <Input
            placeholder="ZIP Code"
            className={validateStatus(touched, errors, FORM_CONTROLS.ZIP_CODE)}
            id="AccountPaymentsCustomerInformationZip"
            {...getFieldProps(FORM_CONTROLS.ZIP_CODE)}
          />
          <div className="Form-Error Form-Error--Short">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.ZIP_CODE)}
          </div>
        </div>
      </Row>

      <div className="AccountPaymentsCustomerInformation-Button-Wrapper">
        <Button
          onClick={toggleForm}
          className="Button--White"
          data-testid="cancel-billing-update-button"
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid || formNotEdited}
          aria-disabled={!isValid || formNotEdited}
          onClick={handleSubmit}
          className="Button--Blue"
          data-testid="update-billing-info-second-button"
        >
          Update Billing Info
        </Button>
      </div>
    </form>
  );
};

AccountPaymentsCustomerInformation.propTypes = {
  toggleForm: PropTypes.func.isRequired,
  companyBillingInfo: PropTypes.shape({
    customerAddress: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      line: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
    brand: PropTypes.string,
    customerId: PropTypes.string,
    expMonth: PropTypes.number,
    expYear: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerName: PropTypes.string,
    customerEmail: PropTypes.string,
    last4: PropTypes.string,
    paymentMethodId: PropTypes.string,
    vatNumber: PropTypes.string,
  }),
};

export default AccountPaymentsCustomerInformation;
