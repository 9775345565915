import { useMemo } from 'react';

import { DroneDataType } from '@cpm/scanifly-shared-data';
import { startCase } from 'lodash';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';

import { formatPercent } from 'helpers/utils/formatPercent';

import { COLORS } from '../constants';
import './DroneDataScoreGraph.scss';

type Props = {
  data: [keyof DroneDataType, string][];
  droneDataScore: number;
};

const DroneDataScoreGraph = ({ data, droneDataScore }: Props) => {
  const chartData = useMemo(
    () =>
      data.map(([key, value]) => ({
        name: startCase(key),
        value: +value,
      })),
    [data]
  );

  return (
    <PieChart className="DroneDataScoreGraph" width={100} height={100}>
      <Pie data={chartData} cx={45} cy={45} innerRadius={35} outerRadius={45} dataKey="value">
        <Label value={formatPercent(droneDataScore)} position="center" />
        {chartData.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]} />
        ))}
      </Pie>
      <Tooltip formatter={(value: number) => formatPercent(value)} />
    </PieChart>
  );
};

export default DroneDataScoreGraph;
