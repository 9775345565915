import { IEmojiData } from 'emoji-picker-react';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';

import { UppyFile } from '@uppy/core';
import cn from 'classnames';
import { debounce } from 'lodash';
import { MediaItem } from 'types';

import { notesActions, selectMentionsUserList } from 'state/slices/notes';
import { AppDispatch, RootState } from 'state/store/store';

import { UploadThumbnail } from 'components';

import useToggle from 'helpers/hooks/useToggle';
import { validators } from 'helpers/utils';

import { ReactComponent as EmojiIcon } from 'assets/icons/emoji-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image-solid.svg';
import placeholderImage from 'assets/image.svg';

import { FormFields, LABELS } from '../constants';
import { MAX_CHAR_LIMIT, NOTE_DEBOUNCE_MS } from './constants';
import EmojiPicker from './EmojiPicker/EmojiPicker';
import { insertEmojiAtCursorPos, swapTags } from './helpers';
import './NotesTextarea.scss';
import { mentionsInputStyle, mentionStyle } from './NotesTextArea.styles';
import { NotesTextareaProps } from './types';

const NotesTextarea = ({
  formControl,
  getFieldProps,
  handleDelete,
  handleSubmit,
  isUploadModalVisible,
  label,
  setFieldValue,
  toggleUploadModal,
  files,
  value,
  setValue,
}: NotesTextareaProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const isModal = label === LABELS.EDIT;

  const mentionsUserList = useSelector((state: RootState) => selectMentionsUserList(state));
  const { project } = useSelector((state: RootState) => state.project);

  const [cursorPosition, setCursorPosition] = useState(0);
  const [isEmojiPickerVisible, toggleIsEmojiPickerVisible] = useToggle(false);

  const handleEmojiPicker = (position: number) => {
    toggleIsEmojiPickerVisible();
    setCursorPosition(position);
  };

  const handleEmojiClick = (_: MouseEvent, emojiObject: IEmojiData) => {
    setFieldValue(
      formControl,
      insertEmojiAtCursorPos(getFieldProps(formControl).value, cursorPosition, emojiObject.emoji)
    );
    setValue((current) => `${current} ${emojiObject.emoji}`);
    setCursorPosition(cursorPosition + 1);
    toggleIsEmojiPickerVisible();
  };

  useEffect(() => {
    if (project?.id) {
      dispatch(notesActions.mentionsUserListRequested({ projectId: project.id }));
    }
    return () => {
      dispatch(notesActions.resetMentionsUserList());
    };
  }, [dispatch, project?.id]);

  const user: { [key: string]: { id: string; display: string }[] } = {
    '@': [],
  };

  mentionsUserList?.map((member) => {
    return user['@'].push({
      id: `${member.id}`,
      display: `@${member.firstName} ${member.lastName}`,
    });
  });

  const handleSubmitNote = (
    event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (getFieldProps(formControl).value !== '') {
        handleSubmit();
        setValue('');
      }
      setValue('');
    }
  };

  const onChange = (e: { target: { value: string } }) => {
    const displayText = swapTags(e.target.value);
    if (displayText.length <= MAX_CHAR_LIMIT) {
      setValue(e.target.value);
      setFieldValue(FormFields.note, e.target.value);
    }
  };

  return (
    <>
      {files?.map((file, index) => (
        <UploadThumbnail
          key={index}
          src={(file as MediaItem).thumbnailUrl || (file as UppyFile).preview || placeholderImage}
          handleDelete={() => handleDelete(file.id)}
          isVideo={validators.isVideo(file)}
        />
      ))}
      <MentionsInput
        style={mentionsInputStyle}
        value={value}
        onChange={onChange}
        aria-label={label}
        onKeyDown={handleSubmitNote}
        onKeyUp={debounce(
          // @ts-ignore typescript says selectionStart doesn't exist and I don't have time to dig into this
          () => setCursorPosition(document.activeElement.selectionStart),
          NOTE_DEBOUNCE_MS
        )}
      >
        <Mention
          trigger="@"
          data={user['@']}
          markup="@{{__type__||__id__||__display__}}"
          style={mentionStyle}
        />
      </MentionsInput>
      {isEmojiPickerVisible && (
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          toggleEmojiPickerVisible={toggleIsEmojiPickerVisible}
        />
      )}
      <div
        className={cn('NotesTextarea-Icon-Wrapper', {
          'NotesTextarea-Icon-Wrapper--Edit': isModal,
        })}
      >
        <EmojiIcon
          onClick={() => handleEmojiPicker(cursorPosition)}
          className={cn('NotesTextarea-Icon', {
            'NotesTextarea-Icon--Active': isEmojiPickerVisible,
          })}
          aria-label={t('Notes.addEmoji')}
        />
        <ImageIcon
          onClick={toggleUploadModal}
          className={cn('NotesTextarea-Icon', {
            'NotesTextarea-Icon--Active': isUploadModalVisible,
          })}
          aria-label={t('Notes.addImage')}
        />
      </div>
      <div
        className={cn('NotesTextarea-Image-Wrapper', {
          'NotesTextarea-Image-Wrapper--Edit': isModal,
        })}
      ></div>
    </>
  );
};

export default NotesTextarea;
