import { StaticMap, _useMapControl as useMapControl } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';

import { MAP_STYLES, MAP_THEMES } from '../../screens/MapWrapper/constants';
import './MapStyleControl.scss';

export default function MapStyleControl({ mapStyle, setMapStyle }) {
  const inverseMapStyle =
    mapStyle === MAP_STYLES.streets ? MAP_STYLES.satellite : MAP_STYLES.streets;

  const { context, containerRef } = useMapControl({
    onDragStart: (evt) => {
      evt.stopPropagation();
    },
    onClick: (evt) => {
      evt.stopPropagation();
      if (evt.type === 'click') {
        setMapStyle(inverseMapStyle);
      }
    },
  });

  const { zoom, latitude, longitude } = context.viewport;

  // Offset value to keep static map zoom ratio consistent with larger map
  const zoomOffset = 3.17;

  const calculatedZoom = Number(zoom.toFixed(3)) - zoomOffset;

  return (
    <div
      role="button"
      className="MapStyleControl"
      aria-label={inverseMapStyle + ' view'}
      ref={containerRef}
      tabIndex={0}
    >
      <StaticMap
        width="100%"
        // Adjusting the height to hide the mapbox logo on the bottom left corner
        height="128%"
        latitude={latitude}
        longitude={longitude}
        attributionControl={false}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        // Adjusting the zoom level to better match the main map
        zoom={calculatedZoom < 0 ? 0 : calculatedZoom}
        mapStyle={MAP_THEMES[inverseMapStyle]}
      />
    </div>
  );
}

MapStyleControl.propTypes = {
  setMapStyle: PropTypes.func,
  mapStyle: PropTypes.string,
};
