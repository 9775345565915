import API from '../config';
import {
  batchUpdateFoldersUrl,
  createFolderUrl,
  fetchFoldersByCompanyIdUrl,
  fetchFoldersUrl,
  foldersForProjectUrl,
  folderUrl,
  removeFoldersFromProjectsUrl,
} from './folderUrls';

export const fetchFolders = () => API.get(fetchFoldersUrl());
export const updateFolderForProject = (folderId: string, projectId: string) =>
  API.post(foldersForProjectUrl(folderId, projectId));
export const deleteFolderFromProject = (folderId: string, projectId: string) =>
  API.delete(foldersForProjectUrl(folderId, projectId));
export const createFolder = (name: string, color: string) =>
  API.post(createFolderUrl(), { name, color });
export const updateFolderColor = (id: string, color: string) => API.patch(folderUrl(id), { color });
export const updateFolderName = (id: string, name: string) => API.patch(folderUrl(id), { name });
export const batchUpdateFolders = (id: string, projectIds: string[]) =>
  API.patch(batchUpdateFoldersUrl(id), { projects: projectIds });
export const deleteFolder = (id: string) => API.delete(folderUrl(id));
export const removeFoldersFromProjects = (projectIds: string[]) =>
  API.delete(removeFoldersFromProjectsUrl(), { data: { projects: projectIds } });
export const fetchFoldersByCompanyId = (id: string) => API.get(fetchFoldersByCompanyIdUrl(id));
