import adminCompanies from 'state/slices/admin/adminCompaniesSlice';
import adminCompanyUsers from 'state/slices/admin/adminCompanyUsersSlice';
import adminInvoices from 'state/slices/admin/adminInvoicesSlice';
import adminProjects from 'state/slices/admin/adminProjectsSlice';
import adminSubscriptions from 'state/slices/admin/adminSubscriptionsSlice';
import adminUsers from 'state/slices/admin/adminUsersSlice';
import apiKeys from 'state/slices/apiKeyDisplay/apiKeySlice';
import auth from 'state/slices/authSlice';
import boundaries from 'state/slices/boundariesSlice';
import checklists from 'state/slices/checklists';
import companyCategories from 'state/slices/companyCategoriesSlice';
import company from 'state/slices/companySlice';
import designServiceRequests from 'state/slices/designServices/designServiceRequestsSlice';
import designServicesQueue from 'state/slices/designServices/designServicesQueueSlice';
import designServicesSettings from 'state/slices/designServices/designServicesSettingsSlice';
import designServiceStatistics from 'state/slices/designServices/designServiceStatisticsSlice';
import orderDesignServiceCredits from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import droneDataScore from 'state/slices/droneDataScoreSlice';
import exampleMedia from 'state/slices/exampleMedia';
import featureToggleOverrides from 'state/slices/featureToggleOverridesSlice';
import folders from 'state/slices/foldersSlice';
import localPreferences from 'state/slices/localPreferencesSlice';
import maintenanceReport from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { mediaSlice } from 'state/slices/media';
import mediaCategories from 'state/slices/mediaCategories';
import medias from 'state/slices/mediasSlice';
import moduleLibrary from 'state/slices/moduleLibrary/moduleLibrarySlice';
import notes from 'state/slices/notes';
import notification from 'state/slices/notificationSlice';
import projectDesigns from 'state/slices/projectDesignsSlice';
import projectCategories from 'state/slices/projectMediaCategories';
import project from 'state/slices/projectSlice';
import projects from 'state/slices/projectsSlice';
import referenceMedia from 'state/slices/referenceMedia';
import signUp from 'state/slices/signUpSlice';
import subscriptions from 'state/slices/subscriptionSlice';
import uploadManifests from 'state/slices/uploadManifestsSlice';
import users from 'state/slices/usersSlice';

export const reducers = {
  auth,
  signUp,
  projects,
  companyCategories,
  company,
  folders,
  projectCategories,
  project,
  users,
  medias,
  newmedia: mediaSlice.reducer,
  projectDesigns,
  notes,
  notification,
  boundaries,
  adminProjects,
  adminUsers,
  adminCompanyUsers,
  adminCompanies,
  adminSubscriptions,
  adminInvoices,
  featureToggleOverrides,
  uploadManifests,
  designServicesQueue,
  designServiceRequests,
  designServiceStatistics,
  designServicesSettings,
  orderDesignServiceCredits,
  checklists,
  droneDataScore,
  maintenanceReport,
  mediaCategories,
  moduleLibrary,
  localPreferences,
  referenceMedia,
  exampleMedia,
  subscriptions,
  apiKeys,
};
