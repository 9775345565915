import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';

const ManageContainer = styled.div`
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  border: 1px solid ${colors.lightGray};
  border-radius: 1.5rem;
  background-color: ${colors.white};
  height: auto;
  width: auto;
  flex: 0.5;
  flex-direction: column;

  @media screen and (max-height: ${breakpoints.md}) {
    padding: 1rem;
  }

  .checklist-manager-title {
    margin-top: 0px;
  }
`;

export default ManageContainer;
