import { UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/react';
import { Col } from 'antd';
import useUploadManager from 'helpers/hooks/useUploadManager';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IndividualFileUpload } from './IndividualFileUpload';
import { DropZoneProps } from './types';

function DropZone({
  projectId,
  projectMedias,
  projectGeolocation,
  categoryId,
  categoryName,
  onUploadComplete,
  uploadComplete,
  userId,
  dropHereOrText,
  title,
}: DropZoneProps) {
  const { t } = useTranslation();

  const { addedFiles, completedFiles, failedFiles, uploadProgressMap, uppy } = useUploadManager({
    clearOnSuccess: false,
    isDroneImages: false,
    projectMedias,
    projectGeolocation,
    projectId,
    categoryId,
    categoryName,
    onSurveyMediaImagesUploaded: onUploadComplete,
    userId,
  });

  const allFiles = useMemo(() => [...addedFiles, ...completedFiles], [addedFiles, completedFiles]);

  return (
    <>
      <Col span={24}>
        <p className="additional-info-title">{title}</p>
        <DragDrop
          uppy={uppy}
          locale={{
            strings: {
              // Text to show on the droppable area.
              // `%{browse}` is replaced with a link that opens the system file selection dialog.
              dropHereOr: dropHereOrText,
              // Used as the label for the link that opens the system file selection dialog.
              browse: t('DesignService.planSet.uploadForm.label'),
            },
          }}
        />
      </Col>
      <Col span={24} className="files-uploaded">
        {allFiles.length
          ? allFiles.map(({ id, name }, index) => {
              const isFailed =
                failedFiles.find((failedFile: UppyFile) => failedFile.id === id) !== undefined;
              return (
                <IndividualFileUpload
                  key={index}
                  fileName={name}
                  isFailed={isFailed}
                  uploadProgress={uploadProgressMap[id]}
                  uploadComplete={uploadComplete}
                />
              );
            })
          : null}
      </Col>
    </>
  );
}

export default DropZone;
