import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';

export const getStatusDescription = (status) => {
  if (
    status === NON_ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED ||
    status === NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED ||
    status === ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED ||
    status === ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED
  ) {
    return 'Draft';
  } else if (status === NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADING) {
    return 'Uploading';
  } else if (
    status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING ||
    status === ADMIN_PROJECT_STATUSES.PROCESSING
  ) {
    return 'Processing';
  } else if (
    status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE ||
    status === ADMIN_PROJECT_STATUSES.PROCESSED
  ) {
    return 'Processed';
  } else if (
    status === NON_ADMIN_PROJECT_STATUSES.UPLOAD_ERRORE ||
    status === ADMIN_PROJECT_STATUSES.ERROR
  ) {
    return 'Error';
  } else if (status === ADMIN_PROJECT_STATUSES.DELETED) {
    return 'Deleted';
  } else if (status === ADMIN_PROJECT_STATUSES.REPLACED) {
    return 'Replaced';
  }
  return '';
};
