import { ReactNode } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

const Panel = Collapse.Panel;

type Props = {
  header: JSX.Element;
  content?: ReactNode;
  customStyle?: string;
};

const CollapsibleOrderList = ({ header, content, customStyle }: Props) => {
  return (
    <div>
      {/* @ts-ignore */}
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition="right"
        className={customStyle}
      >
        {/* @ts-ignore */}
        <Panel header={header}>{content}</Panel>
      </Collapse>
    </div>
  );
};

export default CollapsibleOrderList;
