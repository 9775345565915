import { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Tooltip } from 'antd';
import cn from 'classnames';
import exifr from 'exifr';
import { MAP_ZOOM } from 'screens/MapWrapper/constants';

import { reverseGeocode } from 'helpers/utils/mapbox';

import { ReactComponent as ImageIcon } from 'assets/icons/image-solid.svg';

import { FormFields } from '../../types';
import { CustomerInfoAddressInputProps } from './types';

interface HTMLInputEvent extends ChangeEvent<HTMLInputElement> {
  target: HTMLInputElement & EventTarget;
}

const CustomerInfoAddressInput = ({
  props: { onChange, onBlur, onFocus },
  handleAddressBlur,
  isAddressStartingOrEndingWithSpace,
  isAddressTouched,
  isAddressValid,
  getFieldProps,
  setFieldValue,
  setIsAddressSelected,
  setIsAddressStartingOrEndingWithSpace,
  setIsAddressTouched,
  setIsAddressValid,
  setPin,
  pin,
  setViewport,
  address,
}: CustomerInfoAddressInputProps) => {
  const [inputValue, setInputValue] = useState(address);
  const [iconHovered, setIconHovered] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [metadata, setMetadata] = useState<{ place_name: string; center: any[] } | null>(null);
  const fileUpload = useRef<HTMLInputElement>(null);
  const mountedRef = useRef(true);

  const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const userEnteredAddress = inputValue.trim();
    onBlur(event);
    if (userEnteredAddress !== address.trim()) {
      setFieldValue(FormFields.address, userEnteredAddress);
      handleAddressBlur(event);
      setIsAddressSelected(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setInputValue(event.target.value);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    onFocus(event);
  };

  const getAddress = useCallback(
    async (latitude: number, longitude: number) => {
      const data = await reverseGeocode(latitude, longitude);
      if (data) {
        if (!mountedRef.current) {
          return setFieldValue(FormFields.address, data.features[0]?.place_name || '');
        }

        if (data?.features?.length > 0) {
          setInputValue(data.features[0]?.place_name);
          setMetadata(data.features[0]);
        }
      } else {
        setIsCleared(true);
        setMetadata(null);
        setIsAddressTouched(true);
      }
    },
    [setFieldValue, setIsAddressTouched]
  );

  const handleFileUpload = (event: HTMLInputEvent) => {
    let isSubscribed = true;
    const files = event.target.files ?? [];
    const file: any = files[0];
    exifr
      .gps(file)
      .then((output) => {
        if (isSubscribed) {
          getAddress(output.latitude, output.longitude);
        }
      })
      .catch(() => {
        setIsCleared(true);
        setMetadata(null);
        setIsAddressTouched(true);
      });
    return () => {
      isSubscribed = false;
    };
  };

  useEffect(() => {
    if (metadata || isCleared) {
      setFieldValue(FormFields.address, metadata ? metadata.place_name : '');
      setFieldValue(FormFields.latitude, metadata ? metadata.center[1] : '');
      setFieldValue(FormFields.longitude, metadata ? metadata.center[0] : '');

      setPin({
        latitude: metadata ? metadata.center[1] : null,
        longitude: metadata ? metadata.center[0] : null,
      });
      if (metadata) {
        setViewport((viewport: any) => ({
          ...viewport,
          latitude: metadata.center[1],
          longitude: metadata.center[0],
          zoom: MAP_ZOOM,
        }));
      }
      setIsAddressValid(true);
      setIsAddressSelected(false);

      if (isAddressStartingOrEndingWithSpace) {
        setIsAddressStartingOrEndingWithSpace(false);
      }
    }
  }, [
    metadata,
    isCleared,
    isAddressStartingOrEndingWithSpace,
    setIsAddressSelected,
    setIsAddressStartingOrEndingWithSpace,
    setIsAddressValid,
    setFieldValue,
    setPin,
    setViewport,
  ]);

  useEffect(() => {
    if (
      pin.latitude &&
      pin.longitude &&
      (getFieldProps(FormFields.latitude).value !== pin.latitude ||
        getFieldProps(FormFields.longitude).value !== pin.longitude)
    ) {
      getAddress(pin.latitude, pin.longitude);
    }
  }, [getAddress, getFieldProps, pin]);

  useEffect(() => {
    // Cleanup asynchronous getAddress task to avoid memory leak
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <Tooltip
        title="Address must be selected from the list, uploaded using an image, or marked manually on the map"
        trigger={!address ? 'hover' : 'none'}
        mouseEnterDelay={0.3}
        overlayStyle={iconHovered ? { display: 'none' } : { maxWidth: '37rem' }}
      >
        <input
          className={cn('ant-input', {
            Error: isAddressTouched && (!isAddressValid || isAddressStartingOrEndingWithSpace),
          })}
          placeholder="57th Street"
          onBlur={(event) => handleBlur(event)}
          onFocus={(event) => handleFocus(event)}
          onChange={(event) => handleChange(event)}
          value={inputValue}
          aria-label="address"
        />
        <Tooltip title="Add address using an image" mouseEnterDelay={0.2} mouseLeaveDelay={0}>
          <ImageIcon
            onClick={() => {
              fileUpload.current?.click();
            }}
            onMouseEnter={() => setIconHovered(true)}
            onMouseLeave={() => setTimeout(() => setIconHovered(false), 300)}
            aria-label="upload image to use as address"
          />
        </Tooltip>
      </Tooltip>
      <input
        type="file"
        accept="image/jpeg"
        onChange={(event) => handleFileUpload(event)}
        ref={fileUpload}
        className="Hidden"
        hidden
      />
    </>
  );
};

export default CustomerInfoAddressInput;
