import PropTypes from 'prop-types';

export const designPropTypes = PropTypes.shape({
  id: PropTypes.string,
  projectId: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  systemSize: PropTypes.number,
  ASA: PropTypes.number,
  TOF: PropTypes.number,
  TSRF: PropTypes.number,
  annualProduction: PropTypes.number,
  annualConsumption: PropTypes.number,
  offset: PropTypes.number,
  shadingGraphUrl: PropTypes.string,
  productionGraphUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});
