import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, Modal, Spin, Table } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import debounce from 'lodash/debounce';

import { adminCompanyNameAndIdRequested } from 'state/slices/admin/adminCompaniesSlice';

import { adminDeleteAccessToken } from 'api/admin/adminService';

import { GoBackButton } from 'components';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS, MODAL_PROPS } from 'helpers/constants/modals';
import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import '../ScaniflyAdmin.scss';
import GenerateTokenModal from './GenerateTokenModal';
import { fetchAccessTokens } from './helpers';
import { expandedRowRenderContent, tableColumnData } from './TableData';

const AccessTokens = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [generateTokenModalVisible, setGenerateTokenModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminCompanyNameAndIdRequested());
  }, [dispatch]);

  const handleDelete = (token) => {
    confirm({
      title: 'Delete Token',
      content: `Are you sure you want to delete the access token belonging to ${token.companyName}?`,
      okButtonProps: { style: { background: colors.red } },
      okText: 'Delete',
      onOk: () => handleDeleteToken(token),
      ...CONFIRM_PROPS,
    });
  };

  const handleDeleteToken = async (token) => {
    try {
      await adminDeleteAccessToken(token.id);
      openNotification({
        type: 'success',
        title: 'Success!',
        text: `Access token belonging to ${token.companyName} deleted successfully!`,
      });
      fetchAccessTokens(pageIndex, searchTerm, setIsDataLoading, setTotalCount, setTableData);
    } catch (error) {
      openNotification({
        type: 'error',
        title: 'There was an error while deleting the access token.',
        text: 'Please try again in a few seconds.',
      });
    }
  };

  const handleOpenGenerateTokenModal = () => {
    setGenerateTokenModalVisible(true);
  };

  const handleCloseGenerateTokenModal = () => {
    setGenerateTokenModalVisible(false);
    setSelectedCompany(null);
    setAccessToken(null);
  };

  useEffect(() => {
    fetchAccessTokens(pageIndex, searchTerm, setIsDataLoading, setTotalCount, setTableData);
  }, [pageIndex, searchTerm]);

  return (
    <>
      <Modal
        visible={generateTokenModalVisible}
        onCancel={handleCloseGenerateTokenModal}
        title="Generate Access Token"
        {...MODAL_PROPS}
      >
        <GenerateTokenModal
          handleModalClose={handleCloseGenerateTokenModal}
          pageIndex={pageIndex}
          searchTerm={searchTerm}
          setIsDataLoading={setIsDataLoading}
          setTotalCount={setTotalCount}
          setTableData={setTableData}
          setGenerateTokenModalVisible={setGenerateTokenModalVisible}
          accessToken={accessToken}
          selectedCompany={selectedCompany}
          setAccessToken={setAccessToken}
          setSelectedCompany={setSelectedCompany}
        />
      </Modal>
      <div className="ScaniflyAdmin-Wrapper">
        <div className="ScaniflyAdmin-SubHeader">
          <GoBackButton />
          <Input
            placeholder="Search by company name..."
            prefix={<SearchIcon />}
            aria-label="search by company name"
            onChange={debounce((e) => {
              setPageIndex(1);
              if (e.target.value.length) {
                setSearchTerm(e.target.value.toLowerCase());
              } else {
                setSearchTerm(null);
              }
            }, 400)}
            className="ScaniflyAdmin-Search"
          />
          <p data-testid="token-count">{totalCount} companies with access tokens</p>
          <Button className="Button--Blue" onClick={handleOpenGenerateTokenModal}>
            <PlusIcon aria-hidden="true" />
            New Access Token
          </Button>
        </div>
        <Table
          className="ScaniflyAdmin-Table"
          columns={tableColumnData()}
          dataSource={tableData}
          rowKey="id"
          showHeader={!!tableData.length}
          scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
          pagination={{
            hideOnSinglePage: true,
            total: totalCount,
            pageSize: PROJECT_LIST_SIZE,
            onChange: (page) => setPageIndex(page),
            showSizeChanger: false,
          }}
          expandable={{
            expandedRowRender: (record) => expandedRowRenderContent({ data: record, handleDelete }),
          }}
          loading={{
            indicator: (
              <div className="ScaniflyAdmin-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isDataLoading,
          }}
        />
      </div>
    </>
  );
};

export default AccessTokens;
