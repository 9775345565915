import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import fontSizes from 'helpers/constants/fontSizes';

import SectionHeader from '../SectionHeader/SectionHeader';

const StyledTextContainer = styled.div`
  font-size: ${fontSizes.mediumSmall};
  padding: 4rem 3rem 2rem 3rem;

  @media only screen and (max-width: ${breakpoints.sm}) {
    padding: 1rem;
    font-size: ${fontSizes.extraSmall};
  }
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 3rem;

  @media only screen and (max-width: ${breakpoints.sm}) {
    padding: 1rem;
  }
`;

const StyledImage = styled.img`
  width: 50%;
  margin-bottom: 1rem;

  @media only screen and (max-width: ${breakpoints.sm}) {
    margin-bottom: 0.25rem;
  }
`;

const ImageDisplay = ({
  imageArray,
  title,
  notes,
}: {
  imageArray: string[];
  title: string;
  notes?: string;
}) => {
  return (
    <>
      <SectionHeader title={title} />
      {notes?.length ? (
        <StyledTextContainer data-testid={`notes-${title}`}>{notes}</StyledTextContainer>
      ) : null}
      <StyledImageContainer>
        {imageArray.map((url: string, index: number) => (
          <StyledImage
            src={url}
            alt={title}
            key={`${url}-${index}`}
            className="PublicReportDisplay-Image"
            data-testid={`image-${title}-${index}`}
          />
        ))}
      </StyledImageContainer>
    </>
  );
};

export default ImageDisplay;
