import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';

import { tokenAcquired, userFilledMainInformationForm } from 'state/slices/signUpSlice';
import { userCreationRequested } from 'state/slices/usersSlice';

import { loginUrl } from 'api/auth/authUrls';

import { signUpBillingInformationRoute, tokenValidationRoute } from 'helpers/constants/routes';
import { USER_TYPES } from 'helpers/constants/USER_TYPES';
import { useQuery } from 'helpers/hooks/useQuery';

import '../SignUp.scss';
import SignUpError from '../SignUpError';
import SignUpTermsOfService from '../SignUpTermsOfService';
import { FORM_CONTROLS, INITIAL_COMPANY, PHONE_NUMBER_INITIAL_VALUE } from './constants';
import SignUpLoginCredentials from './SignUpLoginCredentials';
import SignUpProfileInformation from './SignUpProfileInformation';
import { validationSchema } from './validationSchema';

const SignUpMainInformation = () => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [userType, setUserType] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { mainInformation } = useSelector((state) => state.signUp);
  const { userExistsError, error } = useSelector((state) => state.users);
  const { companyExistsError, isCheckingCompany } = useSelector((state) => state.company);
  const query = useQuery();
  const token = useMemo(() => query.get('token'), [query]);

  const initialValues = {
    [FORM_CONTROLS.FIRST_NAME]: '',
    [FORM_CONTROLS.LAST_NAME]: '',
    [FORM_CONTROLS.ROLE]: '',
    [FORM_CONTROLS.PHONE_NUMBER]: PHONE_NUMBER_INITIAL_VALUE,
    [FORM_CONTROLS.PASSWORD]: '',
    [FORM_CONTROLS.CONFIRM_PASSWORD]: '',
  };

  const {
    touched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    getFieldProps,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const decodedToken = jwtDecode(token);
      if (new Date().getTime() > decodedToken.exp * 1000) {
        history.replace(tokenValidationRoute());
        return;
      }
      onSubmitSignUp(values);
    },
  });

  const isFormValid = isValid && dirty && isPhoneNumberValid;

  useEffect(() => {
    try {
      const decodedToken = jwtDecode(token);
      dispatch(tokenAcquired(token));
      setFieldValue(FORM_CONTROLS.EMAIL, decodedToken.email);

      if (new Date().getTime() > decodedToken.exp * 1000) {
        history.replace(tokenValidationRoute());
      }

      if (decodedToken.companyId === INITIAL_COMPANY) {
        setUserType(USER_TYPES.ORGANIZATION);
      } else {
        setFieldValue(FORM_CONTROLS.COMPANY, decodedToken.companyName);
        setUserType(USER_TYPES.TEAMMATE);
      }
    } catch {
      history.push(loginUrl());
    }
  }, [token, userType, setUserType, setFieldValue, dispatch, history]);

  useEffect(() => {
    if (mainInformation) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { password, confirmPassword, ...nonCredentialValues } = mainInformation;
      setValues(nonCredentialValues);

      if (mainInformation[FORM_CONTROLS.PHONE_NUMBER]) {
        setIsPhoneNumberValid(true);
      }
    }
  }, [mainInformation, setValues]);

  const onSubmitSignUp = (values) => {
    if (userType === USER_TYPES.TEAMMATE) {
      const formattedValues = {
        position: values.role,
        phone: values.phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
      };

      dispatch(
        userCreationRequested({
          user: formattedValues,
          email: values.email,
          token,
        })
      );
    } else {
      dispatch(userFilledMainInformationForm(values));
      history.push(signUpBillingInformationRoute());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SignUpProfileInformation
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        userType={userType}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        setIsPhoneNumberValid={setIsPhoneNumberValid}
        isPhoneNumberValid={isPhoneNumberValid}
      />

      <SignUpLoginCredentials
        userExistsError={userExistsError}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
      />

      <div className="SignUp-Footer-Wrapper">
        <SignUpTermsOfService />

        {error && <SignUpError errorMessage={error} />}
        {companyExistsError && <SignUpError errorMessage={companyExistsError} />}

        <Button
          className="Button--Big"
          disabled={!isFormValid || userExistsError || isCheckingCompany || companyExistsError}
          aria-disabled={!isFormValid || userExistsError || isCheckingCompany || companyExistsError}
          htmlType="submit"
          onClick={handleSubmit}
        >
          {userType === USER_TYPES.TEAMMATE ? 'Create Account' : 'Next Step'}
        </Button>
      </div>
    </form>
  );
};

SignUpMainInformation.propTypes = {
  validate: () => {},
};

export default SignUpMainInformation;
