export const FORM_CONTROLS = {
  CATEGORY_NAME: 'categoryName',
};

export const initialValues = {
  [FORM_CONTROLS.CATEGORY_NAME]: '',
};

export const MODAL_TYPES = {
  COMPANY: 'company',
  PROJECT: 'project',
};
