import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UploadManifest } from 'types';

import { saveUploadManifest } from 'api/uploadManifests/uploadManifestsService';

const name = 'uploadManifests';

type UploadManifestState = {
  uploadManifests: UploadManifest[];
};

export const saveUploadManifestRequested = createAsyncThunk(
  `${name}/`,
  async (uploadManifest: UploadManifest, { rejectWithValue }) => {
    try {
      const response = await saveUploadManifest(uploadManifest);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const uploadManifestsSlice = createSlice({
  name,
  initialState: {
    uploadManifests: [],
  } as UploadManifestState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveUploadManifestRequested.fulfilled, (state, { payload }) => {
      state.uploadManifests = [payload, ...state.uploadManifests];
    });
  },
});

export default uploadManifestsSlice.reducer;
