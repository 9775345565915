import { Col, Input, Row } from 'antd';

import styled from 'styled-components';

import { CustomSelect } from 'components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';

const Wrapper = styled(Row)`
  padding-top: 1rem;
`;
const FormRow = styled(Row)``;
const AdjustableProjectRow = styled(Row)`
  height: 9.8rem;
  color: ${colors.labelGray};

  @media (min-width: ${breakpoints.lg}) {
    height: 20rem !important;
  }
`;

const ProjectRow = styled(Row)`
  height: 9.8rem;
  color: ${colors.labelGray};
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Text = styled.p`
  margin-bottom: 0;
`;
const StyledPinIcon = styled(PinIcon)`
  width: 2.5rem;
  vertical-align: middle;
  margin-right: 0.5rem;
`;
const StyledLeftCol = styled(Col)`
  padding-left: 1.5rem;
  width: 50%;
  padding-right: 2rem;
`;
const StyledRightCol = styled(Col)`
  padding-left: 1.5rem;
  width: 50%;
  padding-right: 2rem;
`;
const FormWrapper = styled(Col)`
  padding-left: 1.5rem;
  width: 100%;
  padding-right: 2rem;
`;
const StyledSection = styled.section`
  width: 100%;
  min-height: 8rem;
  margin-bottom: 2rem;
`;
const LabelTitle = styled.h3`
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${colors.labelGray};
  display: flex;
  padding-left: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  min-height: 1.5rem;
`;
const BoldLabelTitle = styled(LabelTitle)`
  font-weight: 500;
  white-space: nowrap;
`;
const SetbackLabelTitle = styled(LabelTitle)`
  width: 20%;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const SetbackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
`;
const SetbackInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SetbackInput = styled(Input)`
  width: 85%;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
`;
const ErrorDiv = styled.div`
  padding-top: unset;
  font-size: ${fontSizes.extraExtraSmall};
  color: ${colors.red};
`;

export {
  AddressWrapper,
  AdjustableProjectRow,
  BoldLabelTitle,
  CustomSelect,
  ErrorDiv,
  FormRow,
  FormWrapper,
  InputWrapper,
  LabelTitle,
  ProjectRow,
  SetbackInput,
  SetbackInputWrapper,
  SetbackLabelTitle,
  SetbackWrapper,
  StyledLeftCol,
  StyledPinIcon,
  StyledRightCol,
  StyledSection,
  Text,
  Wrapper,
};
