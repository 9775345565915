import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Notification } from 'types';

import { fetchNotifications, updateNotification } from 'api/notification/notificationService';

const name = 'notification';

type NotificationState = {
  notifications: Notification[];
  type: string;
  noteId: string;
  text: string;
  completed: boolean;
  hasRead: string[];
  notificationsCreated: boolean;
  isLoading: boolean;
  isMentionLoading: boolean;
  isRequeustPending: boolean;
  isRequestSuccessful: boolean;
  error: string | null | undefined;
  visible: string[];
};

export const notificationRequested = createAsyncThunk(
  `${name}/notificationRequested`,
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await fetchNotifications(userId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const notificationUpdate = createAsyncThunk(
  `${name}/notificationUpdate`,
  async (
    {
      userId,
      notificationId,
      hasRead,
      visible,
    }: { userId: string; notificationId: string; hasRead: string[]; visible: string[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updateNotification(notificationId, hasRead, visible);
      dispatch(notificationRequested(userId));
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const notificationsSlice = createSlice({
  name,
  initialState: {
    notifications: [],
    type: '',
    noteId: '',
    text: '',
    completed: false,
    hasRead: [],
    notificationsCreated: false,
    isLoading: false,
    isMentionLoading: false,
    isRequeustPending: false,
    isRequestSuccessful: false,
    error: null,
    visible: [],
  } as NotificationState,
  reducers: {
    resetSubmittedForm: (state) => {
      state.isRequestSuccessful = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(notificationRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(notificationRequested.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isRequestSuccessful = true;
      state.error = null;
      state.notifications = payload;
    });
    builder.addCase(notificationRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(notificationUpdate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(notificationUpdate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isRequestSuccessful = true;
      state.error = null;
      // @ts-expect-error @todo notificationUpdate doesn't return anything so need to find out
      // how to proceed here, should we return something from the thunk or just remove this assignment?
      state.notifications = payload;
    });
    builder.addCase(notificationUpdate.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
  },
});

export const { resetSubmittedForm } = notificationsSlice.actions;

export default notificationsSlice.reducer;
