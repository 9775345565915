import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as GridViewIcon } from 'assets/icons/grid-view-icon.svg';
import { ReactComponent as ListViewIcon } from 'assets/icons/list-view-icon.svg';

import './ViewSwitchToggle.scss';

const ViewSwitchToggle = ({ isListView, setIsListView }) => {
  return (
    <div className="ViewSwitchToggle">
      <div className="ViewSwitchToggle-Wrapper">
        <button
          onClick={() => setIsListView(true)}
          className={cn('ViewSwitchToggle-Button', {
            'ViewSwitchToggle-Button--Active': isListView,
          })}
          aria-label="list view"
          aria-pressed={isListView}
        >
          <ListViewIcon />
        </button>
        <button
          onClick={() => setIsListView(false)}
          className={cn('ViewSwitchToggle-Button', {
            'ViewSwitchToggle-Button--Active': !isListView,
          })}
          aria-label="grid view"
          aria-pressed={!isListView}
        >
          <GridViewIcon />
        </button>
      </div>
    </div>
  );
};

ViewSwitchToggle.propTypes = {
  isListView: PropTypes.bool.isRequired,
  setIsListView: PropTypes.func.isRequired,
};

export default ViewSwitchToggle;
