import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import styled from 'styled-components';

import { Button, ErrorDisplay, GoBackButton, ModalContext } from 'components';

import { ChecklistTemplateStatusEnum } from '@cpm/scanifly-shared-data';
import colors from 'helpers/constants/colors';
import { openNotification } from 'helpers/utils/openNotification';
import { ChecklistTemplateProps } from '../types';

const Container = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 50px;
  max-width: 81%;
  display: flex;
  max-width: 90%;
`;

type Props = ChecklistTemplateProps;

export const Toolbar = ({
  hasUnsavedChanges,
  onSave,
  onReset,
  onCopy,
  onRename,
  onPublishUnpublish,
}: Props) => {
  const { t } = useTranslation();
  const { displayInputModal, displayDeleteModal } = useContext(ModalContext);
  const { status, title, updateChecklistTitle } = useContext(ChecklistTemplateContext);
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  const publishUnpublishActionText =
    status === ChecklistTemplateStatusEnum.published
      ? t('ChecklistTemplate.unpublish')
      : t('ChecklistTemplate.publish');
  const publishUnpublishButtonColor =
    status === ChecklistTemplateStatusEnum.published ? colors.red : colors.mainBlue;

  const onCopyConfirm = useCallback(
    async (input: string) => {
      onCopy && (await onCopy(input));
    },
    [onCopy]
  );
  const onChecklistCopyClick = useCallback(
    (templateTitle: string) => {
      displayInputModal({
        title: t('Checklists.copyChecklist'),
        description: t('Checklists.copyChecklistDescription', { title: templateTitle }),
        placeholderText: t('Checklists.copyChecklistCopyOf', { title: templateTitle }) ?? undefined,
        defaultValue: t('Checklists.copyChecklistCopyOf', { title: templateTitle }) ?? undefined,
        actionButtonOnClick: onCopyConfirm,
        actionButtonLabel: t('Checklists.copyChecklist'),
      });
    },
    [displayInputModal, t, onCopyConfirm]
  );

  const onResetConfirm = async () => {
    onReset && (await onReset());
  };
  const openResetModal = () =>
    displayDeleteModal({
      title: t('Checklists.reset'),
      description: t('Checklists.confirmReset'),
      actionButtonOnClick: onResetConfirm,
      actionButtonLabel: t('buttonTexts.reset'),
    });

  const onRenameClick = useCallback(
    (templateTitle: string) => {
      displayInputModal({
        title: t('Checklists.renameTemplate'),
        description: t('Checklists.renameTemplateDescription'),
        defaultValue: templateTitle,
        actionButtonOnClick: (input: string) => {
          updateChecklistTitle(input);
        },
        actionButtonLabel: t('Checklists.rename'),
      });
    },
    [displayInputModal, updateChecklistTitle, t]
  );

  const onSaveClick = useCallback(async () => {
    setRequestInProgress(true);
    try {
      onSave && (await onSave());
      openNotification({
        type: 'success',
        title: t('alertMessages.successTitle'),
        text: <div>{t('Checklists.saveSuccess')}</div>,
      });
    } catch (e: any) {
      openNotification({
        type: 'error',
        title: t('alertMessages.errorTitle'),
        text: (
          <div>
            {t('Checklists.saveFail')}
            <ErrorDisplay error={e} />
          </div>
        ),
      });
    } finally {
      setRequestInProgress(false);
    }
  }, [onSave, t]);

  const onPublishUnpublishClick = useCallback(async () => {
    displayInputModal({
      title:
        status === ChecklistTemplateStatusEnum.published
          ? t('ChecklistTemplate.unpublishChecklist', { title })
          : t('ChecklistTemplate.publishChecklist', { title }),
      description:
        status === ChecklistTemplateStatusEnum.published
          ? t('ChecklistTemplate.areYouSureUnpublish')
          : t('ChecklistTemplate.areYouSurePublish'),
      confirmValue: title.trim(),
      actionButtonOnClick: async () => {
        setRequestInProgress(true);
        try {
          onPublishUnpublish && (await onPublishUnpublish());
          openNotification({
            type: 'success',
            title: t('alertMessages.successTitle'),
            text: (
              <div>
                {status === ChecklistTemplateStatusEnum.published
                  ? t('ChecklistTemplate.unpublishSuccess')
                  : t('ChecklistTemplate.publishSuccess')}
              </div>
            ),
          });
        } catch (e: any) {
          openNotification({
            type: 'error',
            title: t('alertMessages.errorTitle'),
            text: (
              <div>
                {status === ChecklistTemplateStatusEnum.published
                  ? t('ChecklistTemplate.unpublishFail')
                  : t('ChecklistTemplate.publishFail')}
                <ErrorDisplay error={e} />
              </div>
            ),
          });
        } finally {
          setRequestInProgress(false);
        }
      },
      actionButtonLabel: publishUnpublishActionText,
      actionButtonColor: publishUnpublishButtonColor,
    });
  }, [
    displayInputModal,
    publishUnpublishButtonColor,
    publishUnpublishActionText,
    onPublishUnpublish,
    status,
    t,
    title,
  ]);

  return (
    <Container id="toolbar">
      <GoBackButton hasUnsavedChanges={hasUnsavedChanges} />
      {onCopy && (
        <Button
          icon="copy"
          disabled={requestInProgress}
          onClick={() => onChecklistCopyClick(title)}
          label={t('buttonTexts.copy')}
          width="9rem"
        />
      )}
      {onRename && (
        <Button
          icon="rename"
          disabled={requestInProgress}
          width="11rem"
          label={t('buttonTexts.rename')}
          onClick={() => onRenameClick(title)}
        />
      )}
      {onReset && (
        <Button
          disabled={requestInProgress}
          onClick={openResetModal}
          label={t('buttonTexts.reset')}
          width="9rem"
        />
      )}
      {onSave && (
        <Button
          icon="save"
          disabled={requestInProgress}
          onClick={onSaveClick}
          label={t('buttonTexts.save')}
          width="9rem"
        />
      )}
      {onPublishUnpublish && (
        <Button
          disabled={requestInProgress}
          onClick={() => onPublishUnpublishClick()}
          color={publishUnpublishButtonColor}
          label={publishUnpublishActionText}
          width="9rem"
        />
      )}
    </Container>
  );
};
