import { Divider } from 'components';

import AccountWrapper from '../AccountWrapper';
import './AccountCompanyInfo.scss';
import AccountCompanyInfoCategories from './AccountCompanyInfoCategories';
import AccountCompanyInfoLogo from './AccountCompanyInfoLogo';
import AccountCompanyInfoMain from './AccountCompanyInfoMain';
import DesignServicesSettings from './DesignServicesSettings/DesignServicesSettings';
import EditChecklistSection from './EditChecklistSection/EditChecklistSection';
import { Folders } from './Folders';

const AccountCompanyInfo = () => {
  return (
    <AccountWrapper>
      <AccountCompanyInfoLogo />
      <AccountCompanyInfoMain />
      <Divider />
      <EditChecklistSection />
      <Divider />
      <Folders />
      <Divider />
      <AccountCompanyInfoCategories />
      <Divider />
      <DesignServicesSettings />
    </AccountWrapper>
  );
};

export default AccountCompanyInfo;
