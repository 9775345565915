export const createErrorNotificationTextForSubscriptionCreation = (error) => {
  if (
    error.response.data.message.includes('contractStartDate must be a Date instance') ||
    error.response.data.message.includes('contractEndDate must be a Date instance')
  ) {
    return 'You must select a start and end date for the subscription.';
  }
  if (error.response.status === 400) {
    return `A subscription that fits this timeline already exists. Please be sure to not overlap the subscription dates.`;
  }
  if (error.response.status === 500) {
    return `Please fill all the required fields.`;
  }
  return `Something has gone wrong and we couldn't create a new subscription.`;
};
