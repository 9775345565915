import { useTranslation } from 'react-i18next';

import { Card } from 'antd';

import { ReactComponent as AlertIcon } from 'assets/icons/alert-icon.svg';

import '../../Auth/Auth.scss';
import './AppError.scss';

const AppError = () => {
  const { t } = useTranslation();
  return (
    <div className="AppError Auth">
      <Card>
        <AlertIcon />
        <h1>{t('error.notAvailable')}</h1>
        <p>
          {t('error.couldNotLoadPage')}
          <a href=".">{t('error.tryAgain')}</a>
        </p>
      </Card>
    </div>
  );
};

export default AppError;
