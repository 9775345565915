import breakpoints from 'helpers/constants/breakpoints';
import styled from 'styled-components';
import { User } from 'types';
import { AddTeammateButton } from '../AddTeammateButton/AddTeammateButton';
import Teammate from '../Teammate/Teammate';

const StyledWrapper = styled.div`
  padding: 2.1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  @media only screen and (max-width: ${breakpoints.lg}) {
    align-content: center;
    justify-content: center;
  }
`;

export const TeammateList = ({
  companyMembers,
  isAdmin,
  isUserFrozen,
  handleModalOpen,
}: {
  companyMembers: User[] | null;
  isAdmin: boolean;
  isUserFrozen: boolean;
  handleModalOpen: () => void;
}) => {
  return (
    <StyledWrapper>
      {companyMembers?.map((teammate) => (
        <Teammate key={teammate.id} teammate={teammate} data-testid="teammate" />
      ))}
      {isAdmin && !isUserFrozen ? <AddTeammateButton handleModalOpen={handleModalOpen} /> : null}
    </StyledWrapper>
  );
};
