import { useTranslation } from 'react-i18next';

import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';

const ActionButtons = ({
  handleClick = () => {},
  handleCancel = () => {},
  hideCancelButton = false,
  status,
}: {
  handleClick?: () => void;
  handleCancel?: () => void;
  hideCancelButton?: boolean;
  status?: string;
}) => {
  const { t } = useTranslation();

  const getButtonText = (status?: string) => {
    if (status === DESIGN_STATUS.dataRequest || status === DESIGN_STATUS.dataRefinement) {
      return t('DSActionButtons.viewComment');
    } else if (status === DESIGN_STATUS.pendingApproval) {
      return t('DSActionButtons.acceptModel');
    }
    return t('DSActionButtons.myProjects');
  };

  return (
    <div className="DesignStatus-ButtonWrapper">
      <Button className="Button--Blue my-projects" onClick={handleClick}>
        {getButtonText(status)}
      </Button>
      {!hideCancelButton ? (
        <Button className="Button--Cancel" onClick={handleCancel} data-testid="cancel-button">
          {status === DESIGN_STATUS.pendingApproval
            ? t('DSActionButtons.reviseModel')
            : t('DSActionButtons.cancelOrder')}
        </Button>
      ) : null}
    </div>
  );
};

export default ActionButtons;
