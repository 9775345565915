import { ROLES } from 'screens/ScaniflyAdmin/constants';

export const filterUsers = (users, searchTerm, isAccountBasedUsersTable = false) =>
  users.filter((entry) => {
    return [
      entry.firstName,
      entry.lastName,
      entry.email,
      isAccountBasedUsersTable ? '' : entry.company,
      ...entry.roles.map((role) => ROLES[role]),
      entry.position,
    ].some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()));
  });
