import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-icon.svg';
import Button from 'components/Button';
import { ImagePanzoom } from 'components/ImagePanzoom';
import colors from 'helpers/constants/colors';
import { openNotification } from 'helpers/utils/openNotification';
import { MediaViewModalProps } from '../types';

type ModalProps = MediaViewModalProps & {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

// It is usually somewhat risky to be so aggressive with z-index but in this case we
// know nothing should be above the overlay until it is dismissed.
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.darkGray};
  padding: 3rem;
  border-radius: 1rem;
  border: 1px solid ${colors.darkGray};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const ImageName = styled.div`
  flex-grow: 1;
  color: ${colors.white};
  margin: 0 1rem;
`;

const CloseModal = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.darkGray};
  border: 1px solid ${colors.lightGray};
  border-radius: 0.5rem;
  padding: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;

const CloseIcon = styled(XIcon)`
  width: 1rem;
  height: 1rem;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1rem;
  justify-content: center;
  align-content: center;
`;

const SelectButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.darkGray};
  margin: 1rem;

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  color: ${(props) => (props.disabled ? colors.neutralGray : colors.white)};
`;
const LeftChevron = styled(ChevronLeftIcon)`
  width: 2rem;
  height: 4rem;
`;

const RightChevron = styled(ChevronRightIcon)`
  width: 2rem;
  height: 4rem;
`;

const MediaViewModal = ({
  isVisible,
  setIsVisible,
  mediaMap,
  mediaOrder,
  initialSelectedId,
}: ModalProps) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [listenerActive, setListenerActive] = useState(false);

  useEffect(() => {
    const newIndex = mediaOrder.findIndex((id) => id === initialSelectedId);
    if (newIndex !== -1) {
      setSelectedIndex(newIndex);
    }
  }, [initialSelectedId, mediaOrder]);

  const incrementIndex = useCallback(() => {
    setSelectedIndex((index) => {
      if (index + 1 < mediaOrder.length) return index + 1;
      return mediaOrder.length - 1;
    });
  }, [mediaOrder.length]);
  const decrementIndex = useCallback(() => {
    setSelectedIndex((index) => {
      if (index - 1 > 0) return index - 1;
      return 0;
    });
  }, []);

  const selectedMedia = useMemo(
    () => mediaMap[mediaOrder[selectedIndex]] ?? { name: 'Oops...Something went wrong' },
    [mediaMap, mediaOrder, selectedIndex]
  );
  const handleClose = () => {
    setIsVisible(false);
  };
  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true);
      if (selectedMedia?.handleDownload) {
        await selectedMedia.handleDownload();
      }
    } catch (err: any) {
      if (err.message) {
        Sentry.captureException(err);
        openNotification({
          type: 'error',
          title: 'Oops!',
          text: err.message,
        });
      }
    } finally {
      setDownloading(false);
    }
  }, [selectedMedia]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsVisible(false);
      } else if (event.key === 'ArrowLeft') {
        decrementIndex();
      } else if (event.key === 'ArrowRight') {
        incrementIndex();
      }
    },
    [setIsVisible, decrementIndex, incrementIndex]
  );

  useEffect(() => {
    if (isVisible && !listenerActive) {
      document.addEventListener('keydown', handleKeyDown);
      setListenerActive(true);
    } else if (!isVisible && listenerActive) {
      document.removeEventListener('keydown', handleKeyDown);
      setListenerActive(false);
    }
  }, [isVisible, handleKeyDown, listenerActive]);

  return isVisible ? (
    <Overlay>
      <Modal>
        <Header>
          {selectedMedia?.handleDownload && (
            <Button
              disabled={downloading}
              icon="clouddownload"
              label={t(`MediaModal.download`) ?? ''}
              padding="0.5rem"
              width="17rem"
              border={`solid ${colors.white}`}
              color={colors.darkGray}
              onClick={handleDownload}
            />
          )}
          <ImageName>{selectedMedia.name}</ImageName>
          <CloseModal onClick={handleClose}>
            <CloseIcon />
          </CloseModal>
        </Header>
        <MainContent>
          <SelectButton onClick={decrementIndex} disabled={selectedIndex < 1}>
            <LeftChevron />
          </SelectButton>
          <MediaContainer>
            <ImagePanzoom
              thumbnailUrl={selectedMedia.thumbnailUrl}
              imageUrl={selectedMedia.fullSizeUrl}
              isPortrait={false}
            />
          </MediaContainer>
          <SelectButton onClick={incrementIndex} disabled={selectedIndex >= mediaOrder.length - 1}>
            <RightChevron />
          </SelectButton>
        </MainContent>
      </Modal>
    </Overlay>
  ) : null;
};

export default MediaViewModal;
