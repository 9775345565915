import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { capitalize } from 'lodash';

export const PROJECT_TYPE_FILTERS = [
  {
    text: capitalize(PROJECT_TYPES.SMALL),
    value: PROJECT_TYPES.SMALL,
  },
  {
    text: capitalize(PROJECT_TYPES.LARGE),
    value: PROJECT_TYPES.LARGE,
  },
];
