import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { Col, Input, RadioChangeEvent, Row } from 'antd';
import { FieldInputProps } from 'formik';
import { MAX_LENGTH_FOR_ADDITIONAL_NOTES } from 'screens/DesignServices/constants';

import { FORM_CONTROLS } from '../constants';
import UploadForm from '../UploadForm/UploadForm';

const AdditionalInformationForSiteModeling = ({
  getFieldProps,
  tierLevel,
  uploadComplete,
  setUploadComplete,
  handleFilesInAlbum,
  filesAlreadyStored,
  handleNewBuildPlans,
  areNewBuildPlansRequired,
}: {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  tierLevel: TIER_LEVEL;
  uploadComplete: boolean;
  setUploadComplete: Dispatch<SetStateAction<boolean>>;
  handleFilesInAlbum: (e: any) => void;
  filesAlreadyStored: null | boolean;
  handleNewBuildPlans?: (e: RadioChangeEvent) => void;
  areNewBuildPlansRequired?: boolean;
}) => {
  const { TextArea } = Input;
  const { t } = useTranslation();

  return (
    <Col className="CommentForm">
      <Row className="form-row additional-info">
        <Col span={24}>
          <div className="additional-info-title">
            {t('DesignService.siteModeling.additionalComments.title')}
          </div>
          <TextArea
            {...getFieldProps(FORM_CONTROLS.COMMENTS)}
            id={FORM_CONTROLS.COMMENTS}
            aria-label={t('DesignService.siteModeling.additionalComments.title') ?? ''}
            placeholder={t('DesignService.siteModeling.additionalComments.placeholder') ?? ''}
            rows={4}
            maxLength={MAX_LENGTH_FOR_ADDITIONAL_NOTES}
          />
        </Col>
      </Row>
      <UploadForm
        uploadComplete={uploadComplete}
        setUploadComplete={setUploadComplete}
        handleFilesInAlbum={handleFilesInAlbum}
        filesAlreadyStored={filesAlreadyStored}
        tierLevel={tierLevel}
        handleNewBuildPlans={handleNewBuildPlans}
        areNewBuildPlansRequired={areNewBuildPlansRequired}
      />
    </Col>
  );
};

export default AdditionalInformationForSiteModeling;
