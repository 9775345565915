import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getAdminChecklistTemplatesForCompany,
  selectCustomChecklistTemplates,
  selectDefaultChecklistTemplates,
} from 'state/slices/checklists';
import {
  StyledBackButton,
  StyledHazardIcon,
  TemplateContainer,
  Title,
  Wrapper,
} from './components';

import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { adminCompanyNameRequested } from 'state/slices/admin/adminCompanyUsersSlice';
import { AppDispatch, RootState } from 'state/store/store';
import { CustomTemplateListCard } from './CustomTemplateListCard';
import { DefaultTemplateListCard } from './DefaultTemplateListCard';

const ChecklistAdminManager = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { pathname } = location;
  const companyId = pathname.split('/').pop() as string;
  const isAdmin = pathname.includes('company-info/checklist-admin-manager');
  const isFetching = useRef(false);
  const customTemplates = useSelector((state: RootState) => selectCustomChecklistTemplates(state));

  const getTemplates = useCallback(async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    try {
      await dispatch<any>(getAdminChecklistTemplatesForCompany(companyId));
    } finally {
      isFetching.current = false;
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(adminCompanyNameRequested(companyId));
    getTemplates();
  }, [getTemplates, dispatch, companyId]);

  const defaultTemplates = useSelector((state: RootState) =>
    selectDefaultChecklistTemplates(state)
  );
  const { companyName } = useSelector((state: RootState) => state.adminCompanyUsers);

  return (
    <Wrapper>
      <StyledBackButton />
      <Title>
        <StyledHazardIcon /> {t('Checklists.editingFor')} {companyName}
      </Title>
      <TemplateContainer>
        <DefaultTemplateListCard
          selectedCompanyId={companyId}
          isAdmin={isAdmin}
          templates={defaultTemplates}
        />
        <CustomTemplateListCard
          selectedCompanyId={companyId}
          isAdmin={isAdmin}
          templates={customTemplates}
        />
      </TemplateContainer>
    </Wrapper>
  );
};

export default ChecklistAdminManager;
