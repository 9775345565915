import { ReactNode } from 'react';

import { Steps } from 'antd';

import { StepStatus } from '../DesignStatus/DesignStatus';
import './DesignSteps.scss';

export type Props = {
  current: number;
  options: { stepNumber: number; description: string; content: JSX.Element }[];
  handleStepChange: (step: number | string) => void;
  stepStatus?: StepStatus | undefined;
  isDesignStatusPage?: boolean;
  children?: ReactNode;
};

export const DesignSteps = ({
  current = 0,
  options = [],
  handleStepChange,
  stepStatus = StepStatus.wait,
  isDesignStatusPage = false,
  children,
}: Props) => {
  const Step = Steps.Step;

  const onChange = (value: number) => {
    const content = options.find((option) => option.stepNumber === value + 1)?.content;
    // We do not allow manual step changes (by clicking on the step):
    // 1. If there's no content to render for the given step
    // 2. If it is the design status page
    if (content && !isDesignStatusPage) {
      handleStepChange(value);
    }
  };

  const getStepStatus = ({
    index,
    current,
    stepStatus,
  }: {
    index: number;
    current: number;
    stepStatus: StepStatus | undefined;
  }) => {
    if (index < current) {
      return 'finish';
    }
    return stepStatus;
  };

  // NOTE: We're using antd version 4.15.2
  // Antd Steps component was rewritten after v 4.24.0 and this old style is deprecated after v 5.0
  // If we update antd to a version newer than v.24.0 we should refactor this component

  return (
    <div className="DesignSteps-MainWrapper">
      {/* @ts-ignore this lib is incompatible with react18*/}
      <Steps current={current} labelPlacement="vertical" onChange={onChange}>
        {options.map((item, index) => {
          const { description, content } = item || {};
          return (
            <Step
              key={description}
              description={description}
              disabled={Boolean(Object.keys(content).length)}
              status={getStepStatus({ index, current, stepStatus })}
            />
          );
        })}
      </Steps>
      <div className="steps-content">{children ?? options[current]?.content}</div>
    </div>
  );
};

export default DesignSteps;
