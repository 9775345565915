import { Alert } from 'antd';

import { SUPPORT_URL } from 'helpers/constants/urls';

export const createErrorMessage = (message) => {
  if (message === 'inactive_user') {
    return (
      <div>
        This account is no longer active. Please contact
        <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
          {' '}
          Scanifly support
        </a>
        .
      </div>
    );
  }
  return 'Oops! You entered wrong credentials. Please try again or use Forgot your password link.';
};

export const renderError = (message) => {
  return (
    <div className="Login-Error--WrongCredentials" data-testid="wrongCredentialsMessage">
      <Alert type="error" message={createErrorMessage(message)} />
    </div>
  );
};
