import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
  PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';

interface DateTimeFormatOptions {
  year?: 'numeric' | '2-digit' | undefined;
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
  day?: 'numeric' | '2-digit' | undefined;
  hour?: 'numeric' | '2-digit' | undefined;
  minute?: 'numeric' | '2-digit' | undefined;
  second?: 'numeric' | '2-digit' | undefined;
}

export const DATE_OPTIONS_FOR_ALBUMS: DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const DATE_OPTIONS: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const ALL_STATUS_FILTERS = [
  {
    text: `${PROJECT_STATUSES.DRAFT} (Customer / Drone Images Uploaded)`,
    value: NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
  },
  {
    text: `${PROJECT_STATUSES.DRAFT} (Customer / Location Submitted)`,
    value: NON_ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
  },
  {
    text: `${PROJECT_STATUSES.DRAFT} (Scanifly Admin / Drone Images Uploaded)`,
    value: ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
  },
  {
    text: `${PROJECT_STATUSES.DRAFT} (Scanifly Admin / Location Submitted)`,
    value: ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
  },
  {
    text: `${PROJECT_STATUSES.PROCESSING} (Scanifly Admin)`,
    value: ADMIN_PROJECT_STATUSES.PROCESSING,
  },
  {
    text: `${PROJECT_STATUSES.PROCESSING} (Customer)`,
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING,
  },
  {
    text: `${PROJECT_STATUSES.PROCESSED} (Scanifly Admin)`,
    value: ADMIN_PROJECT_STATUSES.PROCESSED,
  },
  {
    text: `${PROJECT_STATUSES.PROCESSED} (Customer)`,
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
  },
  {
    text: PROJECT_STATUSES.UPLOADING,
    value: NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADING,
  },
  {
    text: PROJECT_STATUSES.DELETED,
    value: ADMIN_PROJECT_STATUSES.DELETED,
  },
  {
    text: `${PROJECT_STATUSES.ERROR} (Admin Upload Error)`,
    value: ADMIN_PROJECT_STATUSES.ERROR,
  },
  {
    text: `${PROJECT_STATUSES.ERROR} (User Upload Error)`,
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_ERROR,
  },
  {
    text: PROJECT_STATUSES.REPLACED,
    value: ADMIN_PROJECT_STATUSES.REPLACED,
  },
  {
    text: PROJECT_STATUSES.MAINTENANCE,
    value: NON_ADMIN_PROJECT_STATUSES.MAINTENANCE,
  },
  {
    text: PROJECT_STATUSES.REMOTE,
    value: NON_ADMIN_PROJECT_STATUSES.REMOTE,
  },
  {
    text: PROJECT_STATUSES.NO_FLIGHT,
    value: NON_ADMIN_PROJECT_STATUSES.NO_FLIGHT,
  },
];

export const ADMIN_STATUS_FILTERS = [
  {
    text: `${PROJECT_STATUSES.DRAFT} (Drone Images Uploaded)`,
    value: ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
  },
  {
    text: `${PROJECT_STATUSES.DRAFT} (Location Submitted)`,
    value: ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED,
  },
  {
    text: PROJECT_STATUSES.PROCESSING,
    value: ADMIN_PROJECT_STATUSES.PROCESSING,
  },
  {
    text: PROJECT_STATUSES.PROCESSED,
    value: ADMIN_PROJECT_STATUSES.PROCESSED,
  },
  {
    text: PROJECT_STATUSES.PUBLISHED,
    value: NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
  },
  {
    text: PROJECT_STATUSES.UPLOADING,
    value: NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADING,
  },
  {
    text: PROJECT_STATUSES.DELETED,
    value: ADMIN_PROJECT_STATUSES.DELETED,
  },
  {
    text: `${PROJECT_STATUSES.ERROR} (Admin Upload Error)`,
    value: ADMIN_PROJECT_STATUSES.ERROR,
  },
  {
    text: PROJECT_STATUSES.REPLACED,
    value: ADMIN_PROJECT_STATUSES.REPLACED,
  },
  {
    text: PROJECT_STATUSES.MAINTENANCE,
    value: NON_ADMIN_PROJECT_STATUSES.MAINTENANCE,
  },
  {
    text: PROJECT_STATUSES.REMOTE,
    value: NON_ADMIN_PROJECT_STATUSES.REMOTE,
  },
  {
    text: PROJECT_STATUSES.NO_FLIGHT,
    value: NON_ADMIN_PROJECT_STATUSES.NO_FLIGHT,
  },
];
