import useToggle from 'helpers/hooks/useToggle';

import { ReactNode } from 'react';
import { ExpandHeader } from './ExpandHeader';

type Props = {
  collapsed?: boolean;
  staticContainer?: boolean;
  children: ReactNode;
  title: string;
  subTitle?: string;
};

const ExpandableDesignOptions = ({ collapsed = true, children, title, subTitle }: Props) => {
  const [isCollapsed, toggleCollapsible] = useToggle(collapsed);

  return (
    <>
      <ExpandHeader
        onButtonClick={toggleCollapsible}
        isCollapsed={isCollapsed}
        title={title}
        subTitle={subTitle}
      />
      <div>{isCollapsed ? null : children}</div>
    </>
  );
};

export default ExpandableDesignOptions;
