// Constants for managing consistent border radius values across the application

const borderRadius = {
  borderRadiusXXs: '0.6rem',
  borderRadiusXs: '0.8rem',
  borderRadiusSm: '1.2rem',
  borderRadiusXl: '3rem',
  borderRadiusSmLeft: '1.2rem 0 0 1.2rem',
  borderRadiusSmRight: '0 1.2rem 1.2rem 0',
  borderRadiusSmTop: '1.2rem 1.2rem 0 0',
};
export default borderRadius;
