import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from 'types';

import { adminFetchCompanyUsers } from 'api/admin/adminService';
import { fetchCompany } from 'api/company/companyService';

const name = 'adminCompanyUsers';

type AdminState = {
  isLoading: boolean;
  error: string | null;
  isSuccess: boolean;
  users: User[];
  companyName: string;
};

export const adminCompanyUsersRequested = createAsyncThunk(
  `${name}/companyUsersRequested`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await adminFetchCompanyUsers(companyId);
      return response.data.map((user: User) => ({ ...user, companyId }));
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminCompanyNameRequested = createAsyncThunk(
  `${name}/companyNameRequested`,
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompany(companyId);
      return response?.data?.name ?? '';
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminCompanyUsersSlice = createSlice({
  name,
  initialState: {
    isLoading: false,
    error: null,
    isSuccess: false,
    users: [],
    companyName: '',
  } as AdminState,
  reducers: {
    adminUserDeleted: (state, { payload }) => {
      state.users = state.users?.filter((user) => user.id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminCompanyUsersRequested.pending, (state: AdminState) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      adminCompanyUsersRequested.fulfilled,
      (state: AdminState, { payload }: { payload: User[] }) => {
        state.users = payload ?? [];
        state.error = null;
        state.isLoading = false;
        state.isSuccess = true;
      }
    );
    builder.addCase(
      adminCompanyUsersRequested.rejected,
      (state: AdminState, { error }: { error: any }) => {
        state.users = [];
        state.error = error.message;
        state.isLoading = false;
        state.isSuccess = false;
      }
    );
    builder.addCase(adminCompanyNameRequested.pending, (state: AdminState) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(
      adminCompanyNameRequested.fulfilled,
      (state: AdminState, { payload }: { payload: string }) => {
        state.companyName = payload ?? '';
        state.error = null;
        state.isLoading = false;
        state.isSuccess = true;
      }
    );
    builder.addCase(
      adminCompanyNameRequested.rejected,
      (state: AdminState, { error }: { error: any }) => {
        state.companyName = '';
        state.error = error.message;
        state.isLoading = false;
        state.isSuccess = false;
      }
    );
  },
});

export const { adminUserDeleted } = adminCompanyUsersSlice.actions;

export default adminCompanyUsersSlice.reducer;
