import i18n from '../../../helpers/utils/translations/i18n';

type Props = {
  isOrderTypeSelected?: boolean;
  userHasEnoughCredits?: boolean;
};

export const getOrderButtonTooltipTitle = ({
  isOrderTypeSelected,
  userHasEnoughCredits,
}: Props) => {
  if (!isOrderTypeSelected) {
    return i18n.t('tooltipTexts.orderTypeNotSelected');
  } else if (!userHasEnoughCredits) {
    return i18n.t('tooltipTexts.notEnoughDesignCredits');
  }
  return '';
};
