import { ReactNode } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode | ReactNode[];
}

const ButtonWrapper = styled.button<ButtonProps>`
  background-color: ${(props) => (props.disabled ? colors.darkGray : colors.white)};
  color: ${colors.darkGray};
  border: 0.1rem solid ${colors.neutralGray};
  padding: 0 1rem;
  border-radius: 0.8rem;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  height: 3.8rem;
  font-size: 14px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.85)};
  }
`;

export const LargeButton = ({ onClick, disabled, children }: ButtonProps) => {
  return (
    <ButtonWrapper onClick={onClick} disabled={disabled}>
      {children}
    </ButtonWrapper>
  );
};
