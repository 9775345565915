import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';

import { POSITION_FILTERS } from 'screens/ScaniflyAdmin/constants';

import { useAppSelector } from './useAppSelector';

type HubSpotConversations = {
  on: (event: string, callback: Function) => any;
  off: (event: string, callback: Function) => any;
  widget: {
    load: (options?: any) => undefined;
    remove: () => undefined;
    status: () => { loaded: boolean; pending: boolean };
  };
};

const getHubSpotConversations = (): HubSpotConversations | undefined =>
  (window as any).HubSpotConversations;
const hideHubSpotWidget = () => getHubSpotConversations()?.widget?.remove?.();
const showHubSpotWidget = () => getHubSpotConversations()?.widget?.load();

const useHubSpot = () => {
  /*
    At the moment hubspot does not allow contact information to be updated via the chat widget tracking code,
    this can be done with their conversations api which is a different implimentation all together.
    So until that is implimented at a future date , when the information is set initially it can not be
    updated for the user.
  */

  const [desiredState, setDesiredState] = useState<'shown' | 'hidden'>('shown');

  // Window event listener
  useEffect(() => {
    const onMessage = (event: MessageEvent<'hideChatWidget' | 'showChatWidget'>) => {
      switch (event.data) {
        case 'hideChatWidget':
          setDesiredState('hidden');
          break;
        case 'showChatWidget':
          setDesiredState('shown');
          break;
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    switch (desiredState) {
      case 'hidden':
        hideHubSpotWidget();
        break;
      case 'shown':
        showHubSpotWidget();
        break;
    }
  }, [desiredState]);

  useEffect(() => {
    const hs = getHubSpotConversations();
    if (!hs) return;

    const onWidgetLoaded = () => {
      if (desiredState === 'hidden') hideHubSpotWidget();
    };

    hs.on('widgetLoaded', onWidgetLoaded);

    return () => hs.off('widgetLoaded', onWidgetLoaded);
  }, [desiredState]);

  const [scriptLoading] = useScript(
    process.env.REACT_APP_HUBSPOT_ID
      ? {
          src: '//js-na1.hs-scripts.com/' + process.env.REACT_APP_HUBSPOT_ID + '.js',
          checkForExisting: true,
        }
      : { src: null }
  );
  const { currentUser } = useAppSelector((state) => state.users);
  const { company, isLoading } = useAppSelector((state) => state.company);

  useEffect(() => {
    if (process.env.REACT_APP_HUBSPOT_ID) {
      const hubSpot = ((window as any)._hsq = (window as any)._hsq || []);

      if (!currentUser || !hubSpot || scriptLoading || isLoading) {
        return;
      }

      try {
        const userInfo: any = {
          email: currentUser.email,
          id: currentUser.id,
          firstname: currentUser.firstName,
          lastname: currentUser.lastName,
          jobtitle: POSITION_FILTERS.find((el) => el.value === currentUser.position)?.text ?? '-',
        };

        if (currentUser.phone) {
          userInfo.phone = currentUser.phone;
        }

        if (company) {
          userInfo.companyname = company.name;
        }

        hubSpot.push(['identify', userInfo]);
        hubSpot.push(['trackPageView']);
      } catch (error) {
        console.error(error, {
          company,
          currentUser,
        });
      }
    }
  }, [currentUser, company, scriptLoading, isLoading]);
};

export default useHubSpot;
