import * as Yup from 'yup';

import { FORM_CONTROLS } from './constants';

export const validationSchema = (isAdminAddingTeammate) => {
  const schema = {
    [FORM_CONTROLS.EMAIL]: Yup.string().required('Email is required').email('Enter valid email'),
    ...(isAdminAddingTeammate && {
      [FORM_CONTROLS.COMPANY_ID]: Yup.string().required('Company is required'),
    }),
  };
  return Yup.object().shape(schema);
};
