import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Input, Modal, Spin, Table } from 'antd';
import { debounce } from 'lodash-es';
import { User } from 'types';

import {
  adminCompanyNameRequested,
  adminCompanyUsersRequested,
} from 'state/slices/admin/adminCompanyUsersSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { CSVExportButton, GoBackButton, InviteOrChangeTeammatesPermissions } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { filterUsers } from 'helpers/utils/filterUsers';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { ADMIN_USER_MANAGER_CSV_COLUMN_TITLES } from '../constants';
import '../ScaniflyAdmin.scss';
import { handleUsersCSVExport } from './helpers';
import { Columns } from './TableColumns';

const UsersCustomer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalClosedFromOutside, setModalClosedFromOutside] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<User[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const { users, companyName, isLoading } = useSelector(
    (state: RootState) => state.adminCompanyUsers
  );
  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    dispatch(adminCompanyUsersRequested(companyId));
    dispatch(adminCompanyNameRequested(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    setDataSource(users);
    setIsDataLoading(isLoading);
  }, [users, isLoading]);

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalClosedFromOutside(true);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal visible={modalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <InviteOrChangeTeammatesPermissions
          handleModalClose={handleModalClose}
          modalClosedFromOutside={modalClosedFromOutside}
          setModalClosedFromOutside={setModalClosedFromOutside}
          isAddTeammate={true}
          isEditModal={false}
          companyId={companyId}
          companyName={companyName}
        />
      </Modal>
      <div className="ScaniflyAdmin-Wrapper">
        <div className="ScaniflyAdmin-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            prefix={<SearchIcon />}
            aria-label="search users"
            onChange={debounce((e) => {
              const filteredData = filterUsers(users, e.target.value, true);
              setDataSource(filteredData);
            }, 400)}
            className="ScaniflyAdmin-Search"
            data-testid="searchbox"
          />
          <p data-testid="user-count">{dataSource.length} users</p>
          <Button onClick={handleModalOpen} className="Button--Blue">
            <PlusIcon />
            Add Teammate
          </Button>
          <CSVExportButton
            tableName={TABLE_NAMES.CUSTOMER_USERS}
            columnTitles={[...ADMIN_USER_MANAGER_CSV_COLUMN_TITLES]}
            dataSource={dataSource}
            filterQuery="Company"
            companyName={companyName}
            prepareCSVData={handleUsersCSVExport}
          />
        </div>
        <Table
          className="ScaniflyAdmin-Table"
          columns={Columns().filter((c) => c.dataIndex !== 'company')}
          dataSource={dataSource}
          rowKey="id"
          pagination={{ pageSize: 50 }}
          scroll={{ x: 'auto', y: 'calc(100vh - 34rem)' }}
          loading={{
            indicator: (
              <div className="ScaniflyAdmin-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isDataLoading,
          }}
        />
      </div>
    </>
  );
};

export default UsersCustomer;
