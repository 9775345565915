import { SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';
import colors from 'helpers/constants/colors';
import { ReactNode } from 'react';
import styled from 'styled-components';

const NewWrapper = styled.div`
  border-radius: 2rem;
  position: relative;
  background-color: ${colors.background};
  margin: 3rem 0;
  padding: 0.25rem 3rem 1rem 3rem;
`;

const TagWrapper = styled.div`
  position: absolute;
  right: 2rem;
  top: -0.75rem;
`;

export const WrapperWithNewTag = ({ children }: { children: ReactNode }) => {
  return (
    <NewWrapper>
      <TagWrapper>
        <SidebarTag text="NEW" type={TagTypes.new} />
      </TagWrapper>
      {children}
    </NewWrapper>
  );
};
