export const createReportUrl = () => `/maintenance-report`;

export const fetchReportUrl = (reportId: string) => `/maintenance-report/${reportId}`;

export const updateReportUrl = (reportId: string) => `/maintenance-report/${reportId}`;

export const deleteReportUrl = (reportId: string) => `/maintenance-report/${reportId}`;

export const fetchAllProjectReportsUrl = (projectId: string) =>
  `/maintenance-report/project/${projectId}`;
