import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Input, Modal, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import { Project } from 'types';
import { Extra, Pagination, ProjectFilter, Sorter, TableParams } from 'types/TableParams';

import {
  adminScaniflyProjectsRequested,
  resetStatusUpdateFlags,
  resetUserUpdateFlags,
} from 'state/slices/admin/adminProjectsSlice';
import { resetProject, resetTempProjectValues } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ChangeProjectStatusModal, CSVExportButton, GoBackButton } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { scaniflyAdminNewProjectCustomerInfoRoute } from 'helpers/constants/routes';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { getSortBy } from 'helpers/utils/getSortBy';
import { openNotification } from 'helpers/utils/openNotification';
import { getFilterByForProjects } from 'screens/DesignServices/helpers/getFilterBy';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { PROJECTS_TABLE_CSV_COLUMN_TITLES } from '../constants';
import '../ScaniflyAdmin.scss';
import ChangeUserModal from './ChangeUserModal/ChangeUserModal';
import { handleUploadQueueCSVExport } from './helpers';
import { TableColumnData } from './TableColumnData';

const CustomerSupportUpload = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [changeUserModalVisible, setChangeUserModalVisible] = useState<boolean>(false);
  const [changeProjectStatusModalVisible, setChangeProjectStatusModalVisible] =
    useState<boolean>(false);
  const [displayedData, setDisplayedData] = useState<Project[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>();
  const [tableParams, setTableParams] = useState<TableParams<Project, ProjectFilter>>({
    pagination: {
      current: 1,
    },
  });

  const {
    allScaniflyProjects,
    totalScaniflyProjects,
    statusUpdateError,
    statusUpdateSuccess,
    projectUpdateSuccess,
    projectUpdateError,
    isAllScaniflyProjectsLoading,
  } = useSelector((state: RootState) => state.adminProjects);

  useEffect(() => {
    setDisplayedData(allScaniflyProjects);
  }, [allScaniflyProjects, isAllScaniflyProjectsLoading]);

  useEffect(() => {
    if (statusUpdateError) {
      openNotification({
        type: 'error',
        title: 'There was an error.' + statusUpdateError,
        text: 'Please try again in a few seconds.',
      });

      dispatch(resetStatusUpdateFlags());
    }
  }, [dispatch, statusUpdateError]);

  useEffect(() => {
    if (projectUpdateError) {
      openNotification({
        type: 'error',
        title: 'There was an error.' + projectUpdateError,
        text: 'Please try again in a few seconds.',
      });

      dispatch(resetUserUpdateFlags());
    }
  }, [dispatch, projectUpdateError]);

  useEffect(() => {
    if (projectUpdateSuccess) {
      openNotification({
        type: 'success',
        title: 'Success!',
        text: projectUpdateSuccess,
      });

      dispatch(resetUserUpdateFlags());
    }
  }, [dispatch, projectUpdateSuccess]);

  useEffect(() => {
    if (statusUpdateSuccess) {
      openNotification({
        type: 'success',
        title: 'Success!',
        text: statusUpdateSuccess,
      });

      dispatch(resetStatusUpdateFlags());
    }
  }, [dispatch, statusUpdateSuccess]);

  const handleTableChange = (
    pagination: Pagination,
    filters: ProjectFilter | Record<string, FilterValue | null>,
    sorter: Sorter<Project> | Sorter<Project>[],
    extra: Extra<Project>
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  const handleModalClose = () => {
    setChangeProjectStatusModalVisible(false);
    setChangeUserModalVisible(false);
    setSelectedProjectId(null);
  };

  const openChangeUserModalForProject = (projectId: string) => {
    setChangeUserModalVisible(true);
    setSelectedProjectId(projectId);
  };

  const openChangeStatusModalForProject = (projectId: string) => {
    setChangeProjectStatusModalVisible(true);
    setSelectedProjectId(projectId);
  };

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    dispatch(
      adminScaniflyProjectsRequested({
        size: PROJECT_LIST_SIZE,
        pageIndex: pagination?.current ?? 1,
        sortBy: getSortBy(columnKey, order),
        filterBy: getFilterByForProjects(filters, searchText),
      })
    );
    dispatch(resetProject());
    dispatch(resetTempProjectValues());
  }, [dispatch, pageIndex, searchText, tableParams]);

  const handleNewProjectClick = () => {
    history.push(scaniflyAdminNewProjectCustomerInfoRoute(), {
      fromAdminConsole: true,
    });
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal visible={changeUserModalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <ChangeUserModal projectId={selectedProjectId} handleModalClose={handleModalClose} />
      </Modal>
      {/* @ts-ignore */}
      <Modal visible={changeProjectStatusModalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <ChangeProjectStatusModal
          projectId={selectedProjectId}
          handleModalClose={handleModalClose}
          tableName={TABLE_NAMES.UPLOAD_QUEUE}
        />
      </Modal>
      <div className="ScaniflyAdmin-Wrapper">
        <div className="ScaniflyAdmin-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            prefix={<SearchIcon />}
            aria-label="search projects"
            onChange={debounce((e) => {
              setSearchText(e.target.value.toLowerCase());
            }, 400)}
            className="ScaniflyAdmin-Search"
            data-testid="searchbox"
          />
          <p data-testid="project-count">{totalScaniflyProjects ?? 0} projects</p>
          <Button className="Button--Blue" onClick={handleNewProjectClick}>
            <PlusIcon />
            Upload Project
          </Button>
          <CSVExportButton
            tableName={TABLE_NAMES.UPLOAD_QUEUE}
            columnTitles={PROJECTS_TABLE_CSV_COLUMN_TITLES}
            dataSource={displayedData}
            filterQuery="Company Admin"
            searchTerm={searchText}
            prepareCSVData={handleUploadQueueCSVExport}
          />
        </div>
        <Table
          className="ScaniflyAdmin-Table"
          columns={TableColumnData(openChangeUserModalForProject, openChangeStatusModalForProject)}
          dataSource={displayedData}
          rowKey="id"
          scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
          pagination={{
            hideOnSinglePage: true,
            total: totalScaniflyProjects,
            pageSize: PROJECT_LIST_SIZE,
            onChange: (page) => {
              setPageIndex(page);
            },
            showSizeChanger: false,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            handleTableChange(pagination, filters, sorter, extra);
          }}
          loading={{
            indicator: (
              <div className="ScaniflyAdmin-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isAllScaniflyProjectsLoading,
          }}
        />
      </div>
    </>
  );
};

export default CustomerSupportUpload;
