import styled from 'styled-components';

import colors from 'helpers/constants/colors';

export const Wrapper = styled.div<{ wide?: boolean }>`
  display: flex;
  padding: 2rem 2rem 0;
  background: ${colors.background};
  z-index: 1;
  flex-direction: column;
`;

export const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-right: 1rem;
  overflow-y: auto;
`;
