import { BasePlugin } from '@uppy/core';
import exifr from 'exifr';
import ExifReader from 'exifreader';
import { debounce } from 'lodash';

export default class ImageLocationPlugin extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);

    this.id = this.opts.id || 'ImageLocationPlugin';
    this.type = 'modifier';
    this.setState = this.opts.stateSetter;
    this.toggleProgress = this.opts.toggleProgress;
    this.renderFileErrors =
      this.opts.renderFileErrors === undefined ? null : this.opts.renderFileErrors;
    this.rejectMissingGpsFiles =
      this.opts.rejectMissingGpsFiles === undefined ? false : this.opts.rejectMissingGpsFiles;

    this.parseExif = this.parseExif.bind(this);

    // we have to debounce because files-added fires for each file when using cloud upload
    this.handler = debounce(this.parseExif);
  }

  async parseExif(files) {
    const rejectedFiles = [];
    const promises = files.map(async (file) => {
      try {
        const gps = await exifr.gps(file.data);
        let latitude = gps.latitude;
        let longitude = gps.longitude;
        if (!latitude || !longitude) {
          const exifData = await ExifReader.load(file.data, { expanded: true, length: 128 * 1024 });
          latitude = exifData.gps.Latitude;
          longitude = exifData.gps.Longitude;
        }
        if (latitude && longitude) {
          return this.uppy.setFileMeta(file.id, {
            geolocation: `${latitude},${longitude}`,
          });
        } else if (this.opts.rejectMissingGpsFiles) {
          rejectedFiles.push(file);
          this.uppy.removeFile(file.id);
          this.uppy.log(`[Image Locator] Failed to locate ${file.id}:`, 'error');
        }
      } catch (err) {
        if (this.opts.rejectMissingGpsFiles) {
          rejectedFiles.push(file);
          this.uppy.removeFile(file.id);
          this.uppy.log(`[Image Locator] Failed to locate ${file.id}:`, 'error');
        }
        this.uppy.log(err, 'error');
      }
    });

    await Promise.all(promises);

    if (rejectedFiles.length && this.rejectMissingGpsFiles) {
      this.renderFileErrors(rejectedFiles);
    }

    const newFiles = this.uppy.getFiles();

    this.setState && this.setState(newFiles);

    return this.toggleProgress && this.toggleProgress();
  }

  install() {
    this.uppy.on('files-added', this.handler);
  }

  uninstall() {
    this.uppy.off('files-added', this.handler);
  }
}
