import { snakeCase } from 'lodash-es';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';

export const createFileName = ({
  tableName,
  companyName,
  month,
  year,
}: {
  tableName: string;
  companyName?: string;
  month?: number;
  year?: number;
}) => {
  if (tableName === TABLE_NAMES.ACCOUNTING && year) {
    if (typeof month !== 'number' || month > 11 || month < 0 || typeof year !== 'number') {
      return `${tableName}.csv`;
    }
    return `${tableName}_(${(month + 1).toString().padStart(2, '0')}/${year}).csv`;
  } else if (
    (tableName === TABLE_NAMES.CUSTOMER_USERS ||
      tableName === TABLE_NAMES.CUSTOMER_PROJECTS ||
      tableName === TABLE_NAMES.USER_PROJECTS) &&
    companyName &&
    typeof companyName === 'string'
  ) {
    return `${snakeCase(companyName)}_${tableName}.csv`;
  }
  return tableName ? `${tableName}.csv` : `data.csv`;
};
