import { useEffect, useState } from 'react';

import { t } from 'i18next';

import { Container, EditInput, IdLabel as Label, TooltipText } from './CommonFieldComponents';

type Props = {
  id: string;
};

function IdDisplay({ id }: Props) {
  const [clicked, setClicked] = useState<boolean>(false);
  const onCopyClicked = () => {
    setClicked(true);
    navigator.clipboard.writeText(id);
  };
  useEffect(() => {
    setTimeout(() => {
      setClicked(false);
    }, 1000);
  }, [clicked]);

  return (
    <Container>
      <Label>{t('Checklists.id')}</Label>
      {clicked && <TooltipText>{t('Checklists.clipboard')}</TooltipText>}
      <EditInput readOnly onClick={onCopyClicked} value={id} />
    </Container>
  );
}

export default IdDisplay;
