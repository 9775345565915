import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MediaItem, SurveyMedia } from 'types';

import {
  deleteCategoryMedias,
  deleteProjectMedias,
  fetchAllMedias,
  fetchCategoryMedia,
  fetchCategoryMedias,
  fetchProjectMedia,
  fetchProjectMedias,
} from 'api/medias/mediasService';

import { DRONE_IMAGES } from 'helpers/constants/categories';

const name = 'medias';

type MediaState = {
  media: MediaItem | null;
  projectMedias: SurveyMedia[];
  isLoading: boolean;
  isCategoryMediasLoading: boolean;
  isProjectMediasLoading: boolean;
  isAllMediasLoading: boolean;
  error: null | string | undefined;
  isMediasDeletionSuccessful: boolean;
};

export const projectMediaRequested = createAsyncThunk(
  `${name}/projectMediaRequested`,
  async (
    { id, mediaCategoryName }: { id: string; mediaCategoryName: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await (mediaCategoryName === DRONE_IMAGES
        ? fetchProjectMedia(id)
        : fetchCategoryMedia(id));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectMediasRequested = createAsyncThunk(
  `${name}/projectMediasRequested`,
  async (
    { projectId, mediaCategoryName }: { projectId: string; mediaCategoryName?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await (mediaCategoryName === DRONE_IMAGES
        ? fetchProjectMedias(projectId)
        : fetchCategoryMedias(projectId, mediaCategoryName));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allMediasRequested = createAsyncThunk(
  `${name}/allMediasRequested`,
  async ({ projectId }: { projectId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchAllMedias(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectMediasDeleted = createAsyncThunk(
  `${name}/projectMediasDeleted`,
  async (
    { medias, mediaCategoryName }: { medias: { medias: string[] }; mediaCategoryName: string },
    { rejectWithValue }
  ) => {
    try {
      await (mediaCategoryName === DRONE_IMAGES
        ? deleteProjectMedias(medias)
        : deleteCategoryMedias(medias));
      return medias;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const mediasSlice = createSlice({
  name,
  initialState: {
    media: null,
    projectMedias: [],
    isLoading: false,
    isCategoryMediasLoading: false,
    isProjectMediasLoading: false,
    isAllMediasLoading: false,
    error: null,
    isMediasDeletionSuccessful: false,
  } as MediaState,
  reducers: {
    resetProjectMedias: (state) => {
      state.projectMedias = [];
    },
    resetIsMediasDeletionSuccessful: (state) => {
      state.isMediasDeletionSuccessful = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectMediaRequested.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(projectMediaRequested.fulfilled, (state, { payload }) => {
      state.media = payload;
      state.error = null;
      state.isLoading = false;
    });
    builder.addCase(projectMediaRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(allMediasRequested.pending, (state) => {
      state.isAllMediasLoading = true;
      state.projectMedias = [];
    });
    builder.addCase(allMediasRequested.fulfilled, (state, { payload }) => {
      state.projectMedias = payload || [];
      state.error = null;
      state.isAllMediasLoading = false;
    });
    builder.addCase(allMediasRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isAllMediasLoading = false;
    });
    builder.addCase(projectMediasRequested.pending, (state) => {
      state.isProjectMediasLoading = true;
      state.projectMedias = [];
      state.isMediasDeletionSuccessful = false;
    });
    builder.addCase(projectMediasRequested.fulfilled, (state, { payload }) => {
      state.projectMedias = payload || [];
      state.error = null;
      state.isProjectMediasLoading = false;
    });
    builder.addCase(projectMediasRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isProjectMediasLoading = false;
    });
    builder.addCase(projectMediasDeleted.pending, (state) => {
      state.isLoading = true;
      state.isMediasDeletionSuccessful = false;
      state.isProjectMediasLoading = true;
    });
    builder.addCase(projectMediasDeleted.fulfilled, (state, { payload }) => {
      const mediaIdsToDelete = payload;
      state.projectMedias = state.projectMedias.filter(
        (media) => !mediaIdsToDelete.medias.includes(media.id)
      );
      state.error = null;
      state.isLoading = false;
      state.isMediasDeletionSuccessful = true;
      state.isProjectMediasLoading = false;
    });
    builder.addCase(projectMediasDeleted.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isMediasDeletionSuccessful = false;
      state.isProjectMediasLoading = false;
    });
  },
});

export const { resetProjectMedias, resetIsMediasDeletionSuccessful } = mediasSlice.actions;

export default mediasSlice.reducer;
