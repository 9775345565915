import { useMemo } from 'react';
import countryList from 'react-select-country-list';

import PropTypes from 'prop-types';

import CustomSelect from '../CustomSelect';

const CountrySelect = ({
  formControl,
  getFieldProps,
  setFieldValue,
  setSelectedCountry = null,
  label = 'Country',
  placeholder = 'Country',
  ...passedProps
}) => {
  const countryOptions = useMemo(
    () =>
      countryList()
        .getLabels()
        .map((country) => ({ label: country, value: country })),
    []
  );

  return (
    <>
      <label htmlFor={formControl}>{label}</label>
      <CustomSelect
        {...getFieldProps(formControl)}
        {...passedProps}
        id={formControl}
        placeholder={placeholder}
        onChange={(option) => {
          setFieldValue(formControl, option.value);
          setSelectedCountry && setSelectedCountry(option.value);
        }}
        options={countryOptions}
      />
    </>
  );
};

CountrySelect.propTypes = {
  formControl: PropTypes.string.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CountrySelect;
