import * as moment from 'moment-timezone';

import { basicTimeFormat, timezoneFormat } from 'helpers/constants/timeFormat';
import { fullMonthDateFormat } from 'helpers/utils/dateFormat';

const formatDate = (date) => {
  return moment(date).format(fullMonthDateFormat);
};

const getTimezone = (date) => {
  const dateOffset = moment(date).format(timezoneFormat);
  const timezoneName = moment.tz.names().find((timezoneName) => {
    return dateOffset === moment.tz(timezoneName).format(timezoneFormat);
  });
  const timezoneAbbr = moment.tz(timezoneName).zoneAbbr();
  return timezoneAbbr;
};

const formatTime = (date) => {
  return `${moment(date).format(basicTimeFormat)} ${getTimezone(date)}`;
};

const formatAltitude = (altitudeInMeters) => {
  return `${(altitudeInMeters * 3.28084).toFixed(2)} ft`;
};

const getLatSign = (num) => {
  if (num > 0) return 'N';
  if (num < 0) return 'S';
  return '';
};

const getLngSign = (num) => {
  if (num > 0) return 'E';
  if (num < 0) return 'W';
  return '';
};

const formatCoordinates = (num) => {
  const absNum = Math.abs(num);
  const degrees = Math.trunc(absNum);
  const minutes = Math.trunc(60 * (absNum - degrees));
  const seconds = Math.trunc(3600 * (absNum - degrees) - 60 * minutes);
  return `${degrees}°${minutes}′${seconds}″`;
};

const formatLocation = (lat, lng) => {
  const latSign = getLatSign(lat);
  const lngSign = getLngSign(lng);

  return `${formatCoordinates(lat)} ${latSign}, ${formatCoordinates(lng)} ${lngSign}`;
};

export const renderDate = (metadata) => {
  return (
    (metadata?.CreateDate || metadata?.createdAt) && (
      <div className="ImagePreviewMeta-Property">
        <span className="ImagePreviewMeta-Property-Name"> Date: </span>
        {formatDate(metadata.CreateDate || metadata.createdAt)}
      </div>
    )
  );
};

export const renderAltitude = (metadata) => {
  return (
    (metadata?.GPSAltitude || metadata?.relativeAltitude) && (
      <div className="ImagePreviewMeta-Property">
        <span className="ImagePreviewMeta-Property-Name">Relative Altitude:</span>
        {formatAltitude(metadata.GPSAltitude || metadata.relativeAltitude)}
      </div>
    )
  );
};

export const renderTime = (metadata) => {
  return (
    (metadata?.CreateDate || metadata?.createdAt) && (
      <div className="ImagePreviewMeta-Property">
        <span className="ImagePreviewMeta-Property-Name"> Time: </span>
        {formatTime(metadata.CreateDate || metadata?.createdAt)}
      </div>
    )
  );
};

export const renderOrientation = (metadata) => {
  return (
    (metadata?.Orientation || metadata?.cameraOrientation) && (
      <div className="ImagePreviewMeta-Property">
        <span className="ImagePreviewMeta-Property-Name">Orientation:</span>
        {metadata.Orientation ||
          `Pitch: ${metadata.cameraOrientation.pitch}, 
        Roll: ${metadata.cameraOrientation.roll}, 
        Yaw: ${metadata.cameraOrientation.yaw}`}
      </div>
    )
  );
};

export const renderLocation = (metadata) => {
  return (
    metadata?.latitude &&
    metadata?.longitude && (
      <div className="ImagePreviewMeta-Property">
        <span className="ImagePreviewMeta-Property-Name">Location:</span>
        {formatLocation(metadata.latitude, metadata.longitude)}
      </div>
    )
  );
};

export const isPortrait = (metadata) => {
  if (metadata?.height && metadata?.width) {
    return metadata.height > metadata.width;
  }
  if (metadata?.ExifImageHeight && metadata?.ExifImageWidth) {
    return metadata.ExifImageHeight > metadata.ExifImageWidth;
  }
  return false;
};
