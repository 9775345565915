import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChecklistStatusEnum } from '@cpm/scanifly-shared-data';

import {
  Button,
  Content,
  InfoRow,
  StatusTag,
  StyledChevronRight,
  Title,
  TitleRow,
  TopRow,
  UpdatedAt,
} from './components';

import { formatDateTime } from 'helpers/utils/dateFormat';

import { ChecklistTileProps } from './types';

const ChecklistTile = ({
  checklistStatus,
  clickable,
  projectName,
  title,
  updatedAt,
  onTileClick,
  selected,
}: ChecklistTileProps) => {
  const { t } = useTranslation();

  const statusText = useMemo(() => {
    switch (checklistStatus) {
      case ChecklistStatusEnum.completed:
        return t('Checklists.completed');
      case ChecklistStatusEnum.formSubmitted:
      case ChecklistStatusEnum.pdfProcessing:
        return t('Checklists.processing');
      case ChecklistStatusEnum.awaitingMedia:
        return t('Checklists.uploading');
      case ChecklistStatusEnum.draft:
        return t('Checklists.draft');
      case ChecklistStatusEnum.pdfProcessingFailed:
        return t('Checklists.failed');
      default:
        return t('Checklists.unknown');
    }
  }, [checklistStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button clickable={clickable} onClick={onTileClick} selected={selected}>
      <Content>
        <TopRow>
          <Title>{projectName}</Title>
          <StatusTag statusText={statusText} checklistStatus={checklistStatus} />
        </TopRow>
        <TitleRow>
          <Title>{title}</Title>
          <StyledChevronRight />
        </TitleRow>
        <InfoRow>
          <UpdatedAt>
            {t('Checklists.updatedAt', { updatedAt: formatDateTime(updatedAt) })}
          </UpdatedAt>
        </InfoRow>
      </Content>
    </Button>
  );
};

export default ChecklistTile;
