import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createCompanyCategory,
  deleteCompanyCategory,
  updateCompanyCategory,
} from 'api/companyCategories/companyCategoriesService';

const name = 'companyCategories';

type StateType = {
  isCreateRequestPending: boolean;
  isDeleteRequestPending: boolean;
  isUpdateRequestPending: boolean;
  isRequestSuccessful: boolean;
  error: any;
};

export const companyCategoryCreated = createAsyncThunk(
  `${name}/companyCategoryCreated`,
  async (
    { companyId, categoryName }: { companyId: string; categoryName: string },
    { rejectWithValue }
  ) => {
    try {
      await createCompanyCategory(companyId, categoryName);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyCategoryDeleted = createAsyncThunk(
  `${name}/companyCategoryDeleted`,
  async (
    { companyId, categoryName }: { companyId: string; categoryName: string },
    { rejectWithValue }
  ) => {
    try {
      await deleteCompanyCategory(companyId, categoryName);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyCategoryUpdated = createAsyncThunk(
  `${name}/companyCategoryUpdated`,
  async (
    {
      companyId,
      categoryName,
      newCategoryName,
    }: { companyId: string; categoryName: string; newCategoryName: string },
    { rejectWithValue }
  ) => {
    try {
      await updateCompanyCategory(companyId, categoryName, newCategoryName);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const companyCategoriesSlice = createSlice({
  name,
  initialState: {
    isCreateRequestPending: false,
    isDeleteRequestPending: false,
    isUpdateRequestPending: false,
    isRequestSuccessful: false,
    error: null,
  } as StateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companyCategoryCreated.pending, (state) => {
        state.isCreateRequestPending = true;
        state.isRequestSuccessful = false;
      })
      .addCase(companyCategoryCreated.fulfilled, (state) => {
        state.error = null;
        state.isCreateRequestPending = false;
        state.isRequestSuccessful = true;
      })
      .addCase(companyCategoryCreated.rejected, (state, { error }) => {
        state.error = error.message;
        state.isCreateRequestPending = false;
      })
      .addCase(companyCategoryDeleted.pending, (state) => {
        state.isDeleteRequestPending = true;
        state.isRequestSuccessful = false;
      })
      .addCase(companyCategoryDeleted.fulfilled, (state) => {
        state.error = null;
        state.isDeleteRequestPending = false;
        state.isRequestSuccessful = true;
      })
      .addCase(companyCategoryDeleted.rejected, (state, { error }) => {
        state.error = error.message;
        state.isDeleteRequestPending = false;
        state.isRequestSuccessful = false;
      })
      .addCase(companyCategoryUpdated.pending, (state) => {
        state.isUpdateRequestPending = true;
        state.isRequestSuccessful = false;
      })
      .addCase(companyCategoryUpdated.fulfilled, (state) => {
        state.error = null;
        state.isUpdateRequestPending = false;
        state.isRequestSuccessful = true;
      })
      .addCase(companyCategoryUpdated.rejected, (state, { error }) => {
        state.error = error.message;
        state.isUpdateRequestPending = false;
        state.isRequestSuccessful = false;
      });
  },
});

export default companyCategoriesSlice.reducer;
