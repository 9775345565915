import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ChecklistType } from '@cpm/scanifly-shared-data';
import { Spin } from 'antd';

import { getAllChecklistsForProject, selectChecklistsForProject } from 'state/slices/checklists';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { getProjectMediaCategories } from 'state/slices/projectMediaCategories';

import { Container, ProjectName, TileWrapper, Wrapper } from './components';

import { ChecklistInfo } from './ChecklistInfo';
import ChecklistTile from './ChecklistTile/ChecklistTile';
import ChecklistRootView from './ChecklistView';

const Checklists = () => {
  const dispatch: AppDispatch = useDispatch();
  const { project, isProjectRequestedLoading } = useSelector((state: RootState) => state.project);
  const [selectedChecklist, setSelectedChecklist] = useState<ChecklistType>();
  const { projectId } = useParams<{ projectId: string }>();
  const checklists = useSelector((state: RootState) =>
    selectChecklistsForProject(state, projectId)
  );

  const getProjectMediaCategoriesAction = useCallback(
    async (projectId: string): Promise<void> => {
      await dispatch<any>(getProjectMediaCategories(projectId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!project) {
      dispatch(projectRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project?.id) {
      dispatch(getAllChecklistsForProject(project.id));
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (projectId) {
      getProjectMediaCategoriesAction(projectId!);
    }
  }, [projectId, getProjectMediaCategoriesAction]);

  const hasChecklists = checklists.length;

  const checklistDisplay = useMemo(() => {
    if (!selectedChecklist) return null;
    return (
      <ChecklistRootView
        projectId={selectedChecklist.project.id}
        checklistId={selectedChecklist.id}
      />
    );
  }, [selectedChecklist]);

  return (
    <Container>
      <Wrapper wide={!hasChecklists && !isProjectRequestedLoading}>
        <ProjectName>{project?.name}</ProjectName>
        <TileWrapper>
          {hasChecklists ? (
            checklists.map((checklist) => (
              <ChecklistTile
                key={checklist.id}
                projectName={project?.name ?? ''}
                title={checklist.title}
                updatedAt={checklist.updatedAt}
                checklistStatus={checklist?.status}
                clickable={checklist?.generatedPdf !== '' || checklist?.generatedPdf !== undefined}
                onTileClick={() => {
                  setSelectedChecklist(checklist);
                }}
                selected={selectedChecklist?.id === checklist?.id}
              />
            ))
          ) : isProjectRequestedLoading ? (
            <div className="Checklists-Spinner">
              <Spin size="small" />
            </div>
          ) : (
            <ChecklistInfo />
          )}
        </TileWrapper>
      </Wrapper>
      {checklistDisplay}
    </Container>
  );
};

export default Checklists;
