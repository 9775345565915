import { userSuccessfullyUploadedProject } from 'state/slices/projectSlice';

import { DRONE_IMAGES } from 'helpers/constants/categories';
import { ADMIN_PROJECT_STATUSES, PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectCategoryRoute,
  projectDesignsRoute,
  rootRoute,
  scaniflyAdminDraftCustomerInfoRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminProjectDesignsRoute,
} from 'helpers/constants/routes';

export const goToProjectDetails = (id, statusDescription, dispatch) => {
  switch (statusDescription) {
    case PROJECT_STATUSES.NO_FLIGHT:
    case PROJECT_STATUSES.DRAFT:
      return draftProjectCategoryRoute(id, DRONE_IMAGES);
    case PROJECT_STATUSES.UPLOADING:
    case PROJECT_STATUSES.SUBMISSION_ERROR:
    case PROJECT_STATUSES.REMOTE:
    case PROJECT_STATUSES.MAINTENANCE:
      return projectDesignsRoute(id);
    case PROJECT_STATUSES.PROCESSED:
      dispatch(userSuccessfullyUploadedProject(true));
      return projectDesignsRoute(id);
    case PROJECT_STATUSES.PROCESSING:
    case PROJECT_STATUSES.ERROR:
      dispatch(userSuccessfullyUploadedProject(false));
      return projectDesignsRoute(id);
    default:
      return rootRoute();
  }
};

export const getAdminProjectRoute = (id, status) => {
  switch (status) {
    case ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED:
      return scaniflyAdminDraftProjectCategoriesRoute(id);
    case ADMIN_PROJECT_STATUSES.LOCATION_SUBMITTED:
      return scaniflyAdminDraftProjectInfoRoute(id);
    case ADMIN_PROJECT_STATUSES.PROCESSED:
      return scaniflyAdminProjectDesignsRoute(id);
    case ADMIN_PROJECT_STATUSES.ERROR:
    case ADMIN_PROJECT_STATUSES.PROCESSING:
      return scaniflyAdminProjectDesignsRoute(id);
    case ADMIN_PROJECT_STATUSES.PUBLISHED:
      return projectDesignsRoute(id);
    case ADMIN_PROJECT_STATUSES.REPROCESSING:
      return scaniflyAdminDraftCustomerInfoRoute(id);
    default:
      return rootRoute();
  }
};
