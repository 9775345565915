import styled from 'styled-components';

import colors from 'helpers/constants/colors';

export const Seperator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px solid ${colors.neutralGray};
  margin-top: 1rem;
`;
