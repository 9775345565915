import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as CheckmarkFilledIcon } from 'assets/icons/checkmark-filled.svg';

import { DistantPhotoProps } from './types';

const StyledContainer = styled.div`
  height: 9rem;
  position: relative;
  width: 10rem;

  &:hover {
    cursor: pointer;
    filter: brightness(80%);
    transition: all 0.3s ease;
  }
  .ant-spin-spinning {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 30%;
  }
  .distance-container {
    align-items: center;
    background-color: ${colors.background};
    border-radius: 8rem;
    bottom: 0.5rem;
    height: 2rem;
    justify-content: center;
    left: 0.25rem;
    opacity: 0.7;
    position: absolute;
    width: 9.5rem;
    z-index: 1;
  }
  .distance-text {
    color: ${colors.black};
    font-size: 0.9rem;
    padding-top: 0.25rem;
    text-align: center;
  }
  .image {
    height: 100%;
    width: 100%;
  }
  .selection-icon {
    height: 2rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    width: 2rem;
  }
`;

function DistantPhoto({ distanceApart, id, isSelected, onClick, src }: DistantPhotoProps) {
  const { t } = useTranslation();

  const selectOnClick = () => onClick(id);

  const testId = `distant-photo-${id}`;

  return (
    <StyledContainer
      data-testid={testId}
      onClick={selectOnClick}
      onKeyPress={selectOnClick}
      role="button"
    >
      {isSelected ? (
        <div className={'selection-icon'} data-testid={`${testId}-selected-icon`}>
          {<CheckmarkFilledIcon />}
        </div>
      ) : null}
      {src ? (
        <img
          alt={'distant'}
          crossOrigin={'anonymous'}
          className={'image'}
          data-testid={`${testId}-img`}
          src={src}
        />
      ) : (
        <Spin className="spinner" data-testid={`${testId}-spinner`} />
      )}
      <div className={'distance-container'}>
        <p
          className={'distance-text'}
          dangerouslySetInnerHTML={{
            __html: t('DistantPhotoReview.milesAway', { distanceApart }) || '',
          }}
          data-testid={`${testId}-distance`}
        />
      </div>
    </StyledContainer>
  );
}

export default memo(DistantPhoto);
