import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'antd';
import { Formik } from 'formik';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FormFields, FormValues } from '../types';
import { ValidationSchema } from '../validationSchema';
import './FolderModal.scss';
import { FolderModalProps } from './types';

const FolderModal = ({ folder, folders, onDelete, onClose, onRename, type }: FolderModalProps) => {
  const { t } = useTranslation();

  const initialValues = {
    [FormFields.folderName]: folder.name,
  };

  const validationSchema = useMemo(
    () => ValidationSchema(folders, folder.id),
    [folders, folder.id]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onRename}
    >
      {({ dirty, touched, errors, isValid, getFieldProps, handleSubmit }) => (
        <>
          <div className="FolderModal-Description">
            {type === 'Remove' ? (
              <p>{t('CompanyInfo.removeFolderWarning', { folderName: folder.name })}</p>
            ) : (
              <>
                <Input
                  autoFocus
                  defaultValue={folder.name}
                  placeholder="Enter folder name"
                  className={`FolderModal-Input ${validateStatus(
                    touched,
                    errors,
                    FormFields.folderName,
                    dirty
                  )}`}
                  onKeyUp={(e) => {
                    if (e.key === 'Escape') {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                  {...getFieldProps(FormFields.folderName)}
                  aria-label="rename folder"
                />
                <div className="Form-Error">
                  {renderValidationMessage(touched, errors, FormFields.folderName, dirty)}
                </div>
              </>
            )}
          </div>
          <div className="FolderModal-Buttons-Wrapper">
            <Button onClick={onClose} className="Button--White">
              Cancel
            </Button>
            {type === 'Remove' ? (
              <Button onClick={() => onDelete()} className="Button--Red" autoFocus>
                Delete
              </Button>
            ) : (
              <Button
                // @ts-ignore
                onClick={handleSubmit}
                className="Button--Blue"
                disabled={!isValid}
                aria-disabled={!isValid}
              >
                Rename Folder
              </Button>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default FolderModal;
