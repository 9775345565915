export const CREDIT_CARD_BRAND = {
  AMERICAN_EXPRESS: 'amex',
  DINERS_CLUB: 'diners',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  VISA: 'visa',
  UNKNOWN: 'unknown',
};
