import { useRef, useState } from 'react';

import { Button, Tooltip } from 'antd';

import { ThreeDEmbedModalProps } from './types';

const ThreeDEmbedModal = ({ modelUrl }: ThreeDEmbedModalProps) => {
  const modelEmbedRef = useRef<HTMLTextAreaElement | null>(null);
  const modelEmbed = `<iframe width="640" height="480" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" src="${modelUrl}" role="img" title="3d model"></iframe>`;
  const [isCopied, setIsCopied] = useState(false);

  const handleEmbedCopy = () => {
    const textArea = modelEmbedRef.current;
    textArea?.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(setIsCopied, 3000, false);
    // TODO: Check if component is still mounted
  };

  return (
    <div className="ThreeDView-Modal-Content">
      <textarea ref={modelEmbedRef} value={modelEmbed} readOnly={true} />
      <Tooltip placement="top" visible={isCopied} title={isCopied && 'Copied!'} arrowPointAtCenter>
        <Button
          onClick={() => handleEmbedCopy()}
          className="ThreeDView-Modal-Button Button--Blue"
          autoFocus
        >
          Copy
        </Button>
      </Tooltip>
    </div>
  );
};

export default ThreeDEmbedModal;
