export const getFormattedTimeDifferenceInMins = (startDate: Date, endDate: Date): string => {
  const timeDifferenceInMinutes = Math.floor(
    Math.abs(new Date(startDate).valueOf() - new Date(endDate).valueOf()) / 1000 / 60
  );

  return `${new Intl.NumberFormat('en-US').format(timeDifferenceInMinutes)} mins`;
};

export const getFormattedTimeDifference = (startDate: Date, endDate: Date): string => {
  const timeDifferenceInMinutes = Math.floor(
    Math.abs(new Date(startDate).valueOf() - new Date(endDate).valueOf()) / 1000 / 60
  );

  if (timeDifferenceInMinutes === 0) return '0 mins';

  const minutes = timeDifferenceInMinutes % 60;
  const hours = ((timeDifferenceInMinutes - minutes) / 60) % 24;
  const days = (timeDifferenceInMinutes - minutes - hours * 60) / 60 / 24;

  return `${formatDuration(days, 'day')}${formatDuration(hours, 'hr')}${formatDuration(
    minutes,
    'min'
  )}`.trim();
};

function formatDuration(time: number, unit: string): string {
  if (time === 0) return '';
  const plural = time > 1 ? 's' : '';
  return ` ${time} ${unit}${plural}`;
}

export const getFormattedTimeDifferenceForAverage = (averageInMinutes: number | null): string => {
  if (!averageInMinutes) return '0 mins';

  const roundedAverage = Math.round(averageInMinutes);

  if (roundedAverage === 0) return '0 mins';

  const minutes = roundedAverage % 60;
  const hours = ((roundedAverage - minutes) / 60) % 24;
  const days = (roundedAverage - minutes - hours * 60) / 60 / 24;

  return `${formatDuration(days, 'day')}${formatDuration(hours, 'hr')}${formatDuration(
    minutes,
    'min'
  )}`.trim();
};
