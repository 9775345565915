import { useRef } from 'react';

import { Tooltip } from 'antd';

const TooltipWrapper = ({ text, className }: { text: string; className?: string }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const isEllipsis = (titleRef.current?.offsetWidth ?? 0) < (titleRef.current?.scrollWidth ?? 0);

  const isLineClamped =
    (titleRef.current?.offsetHeight ?? 0) < (titleRef.current?.scrollHeight ?? 0);

  const textElement = (
    <div className={className} ref={titleRef}>
      {text}
    </div>
  );

  return isEllipsis || isLineClamped ? <Tooltip title={text}>{textElement}</Tooltip> : textElement;
};

export default TooltipWrapper;
