import { FC, PropsWithChildren, ReactNode } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

const Panel = Collapse.Panel;

type Props = {
  header: ReactNode;
  onChange?: () => void;
  content?: ReactNode;
  index?: number;
  className?: string;
};

const CollapsibleWrapper: FC<PropsWithChildren<Props>> = ({
  header,
  content,
  index = 1,
  onChange,
  className,
}: Props) => {
  return (
    <div className={className}>
      {/* @ts-ignore */}
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} />}
        expandIconPosition="right"
        onChange={onChange}
      >
        {/* @ts-ignore */}
        <Panel header={header} key={index}>
          {content}
        </Panel>
      </Collapse>
    </div>
  );
};

export default CollapsibleWrapper;
