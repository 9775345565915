import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Project } from 'types';
import { ProjectFilter, TableParams } from 'types/TableParams';

import {
  adminCompanyNameRequested,
  adminCompanyProjectsRequested,
} from 'state/slices/admin/adminProjectsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterByForProjects } from 'screens/DesignServices/helpers/getFilterBy';

import BaseProjects from './BaseProjects';

const CustomerProjects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { companyId } = useParams<{ companyId: string }>();
  const { allCustomerProjects, totalCompanyProjects, companyName } = useSelector(
    (state: RootState) => state.adminProjects
  );
  const [searchText, setSearchText] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams<Project, ProjectFilter>>({
    pagination: {
      current: 1,
    },
  });

  const extraProps = {
    filterQuery: 'Company Name',
    companyName: companyName,
  };

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    dispatch(
      adminCompanyProjectsRequested({
        companyId,
        size: PROJECT_LIST_SIZE,
        pageIndex: pagination?.current ?? 1,
        sortBy: getSortBy(columnKey, order),
        filterBy: getFilterByForProjects(filters, searchText),
      })
    );
    dispatch(adminCompanyNameRequested(companyId));
  }, [companyId, dispatch, searchText, tableParams]);

  const baseData = useMemo(() => {
    return { data: allCustomerProjects, projectCount: totalCompanyProjects ?? 0 };
  }, [allCustomerProjects, totalCompanyProjects]);

  return (
    <BaseProjects
      tableName={TABLE_NAMES.CUSTOMER_PROJECTS}
      isCustomerOrUserProjectsTable={true}
      baseData={baseData}
      setTableParams={setTableParams}
      setSearchText={setSearchText}
      extraProps={extraProps}
      companyId={companyId}
    />
  );
};

export default CustomerProjects;
