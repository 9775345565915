import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DESIGN_TIME_ESTIMATES, TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as InProgressIcon } from 'assets/icons/in-progress-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const RevisionInProgress = ({ tierLevel }: { tierLevel: TIER_LEVEL }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const processingTimeEstimate = `~${
    DESIGN_TIME_ESTIMATES[tierLevel as keyof typeof DESIGN_TIME_ESTIMATES] ?? 3
  } hours`;

  return (
    <div className="DesignStatus-Wrapper">
      <InProgressIcon className="DesignStatus-ProcessingIcon" />
      <div className="DesignStatus-Title">{t('DesignStatusDisplay.revisionInProgress')}</div>
      <p className="DesignStatus-Timeframe">
        {t('DesignStatusDisplay.timeframe')}{' '}
        <span className="DesignStatus-Value">{processingTimeEstimate}</span>
      </p>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        hideCancelButton={true}
      />
    </div>
  );
};

export default RevisionInProgress;
