import { ProjectFilter } from 'types/TableParams';

import { Filter } from '../DesignServicesQueue/TableParams';

export const getFilterByForDesignServices = (filters: Filter | undefined, searchText: string) => {
  const { status, tierLevel, systemSize, 'project.type': projectType } = filters || {};

  return {
    status: status?.length ? status : undefined,
    tierLevel: tierLevel?.length ? tierLevel : undefined,
    systemSize: systemSize?.length ? systemSize : undefined,
    projectType: projectType?.length ? projectType : undefined,
    searchText,
  };
};

export const getFilterByForProjects = (filters: ProjectFilter | undefined, searchText?: string) => {
  const { statusDescription, 'project.type': projectType, status, type, engine } = filters || {};

  return {
    statusDescription: statusDescription?.length ? statusDescription : undefined,
    status: status?.length ? status : undefined,
    projectType: projectType?.length ? projectType : undefined,
    type: type?.length ? type : undefined,
    engine: engine?.length ? engine : undefined,
    searchText,
  };
};
