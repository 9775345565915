import { useDispatch } from 'react-redux';

import { Button, Input } from 'antd';
import { useFormik } from 'formik';

import { newUserInvited } from 'state/slices/authSlice';

import { PERMISSION, ROLE } from '@cpm/scanifly-shared-data';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { openNotification } from 'helpers/utils/openNotification';

import AccountManager from '..';
import { FORM_CONTROLS } from '../constants';
import './AccountInvitation.scss';
import { validationSchema } from './validationSchema';

const AccountInvitation = () => {
  const dispatch = useDispatch();
  const initialValues = {
    [FORM_CONTROLS.EMAIL]: '',
  };

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const newUser = {
        email: values.email,
        roles: [ROLE.admin],
        permissions: Object.values(PERMISSION).filter(
          (permission) => permission !== PERMISSION.SIMPLEDESIGN
        ),
        foldersAccess: null,
      };
      dispatch(newUserInvited(newUser));
      AccountManager.handleModalClose();
      openNotification({
        type: 'success',
        title: 'Account Invite Sent!',
        text: `Your account invite has been sent to ${values.email}.`,
      });
      resetForm();
    },
  });

  const isFormValid = isValid && dirty;

  return (
    <>
      <h2 className="AccountInvitation-Title">
        Account invite{' '}
        <span role="img" aria-label="wave emoji">
          &#128075;
        </span>
      </h2>
      <div className="AccountInvitation">
        <div className="FormControl-Wrapper AccountInvitation-Input">
          <label htmlFor="accountInvitationEmail">Email</label>
          <Input
            placeholder="john.doe@scanifly.com"
            className={`${validateStatus(touched, errors, FORM_CONTROLS.EMAIL)}`}
            id="accountInvitationEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.EMAIL)}
          </div>
        </div>
        <div className="AccountInvitation-Button-Wrapper">
          <Button
            onClick={handleSubmit}
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            className="Button--Blue"
          >
            Send Invite
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountInvitation;
