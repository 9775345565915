import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { camelCase } from 'lodash';
import ServiceRequest from 'types/ServiceRequest';

import styled from 'styled-components';

import { serviceRequestUpdateRequested } from 'state/slices/designServices/designServiceRequestsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
  serviceRequest?: ServiceRequest;
};

const StyledModal = styled(Modal)`
  position: relative;
  .ant-modal-content .ant-modal-body {
    max-width: 50rem;

    .close-modal-icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }
  }
`;

const StyledTitle = styled.h2`
  display: inline-block;
  margin-bottom: 2rem;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.semiBold};
  color: ${colors.darkGray};
`;

const StyledSectionWrapper = styled.div`
  margin-bottom: 2rem;
  p {
    margin-bottom: 0.25rem;
    font-size: ${fontSizes.small};
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > :first-child {
    margin-right: 0.8rem;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    justify-content: center;
  }
`;

const ChangeDSPModal = ({ isModalOpen, toggleModal, serviceRequest }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { dspCompanies } = useSelector((state: RootState) => state.company);
  const [selectedOption, setSelectedOption] = useState<
    { title: string; value: string } | undefined | null
  >(null);

  useEffect(() => {
    if (serviceRequest && dspCompanies?.length) {
      setSelectedOption(
        dspCompanies.find(
          (company) =>
            company?.title?.toLowerCase() ===
            (serviceRequest?.designServiceProviderName ?? '')?.toLowerCase()
        )
      );
    }
  }, [serviceRequest, dspCompanies]);

  const isDefaultProviderSelected = useMemo(
    () =>
      selectedOption?.title?.toLowerCase() ===
      (serviceRequest?.designServiceProviderName ?? '')?.toLowerCase(),
    [selectedOption?.title, serviceRequest?.designServiceProviderName]
  );

  const onSuccess = () => {
    openNotification({
      type: 'success',
      title: t('DesignService.updateSuccessTitle'),
      text: t('DesignService.updateSuccessMessage', {
        orderId: serviceRequest?.id ?? '--',
      }),
    });
  };

  const onError = (errorMessage?: string) => {
    openNotification({
      type: 'error',
      title: t('DesignService.updateErrorTitle'),
      text: t('DesignService.updateErrorMessage', {
        orderId: serviceRequest?.id ?? '--',
        errorMessage,
      }),
    });
  };

  const handleDSPUpdate = () => {
    if (serviceRequest && selectedOption) {
      dispatch(
        serviceRequestUpdateRequested({
          serviceRequestId: serviceRequest.id,
          finalData: { designServiceProviderId: selectedOption.value },
          handleSuccess: onSuccess,
          handleError: onError,
        })
      );
      toggleModal();
    }
  };

  const dropdownMenu = (
    <Menu>
      {dspCompanies?.length
        ? dspCompanies.map(({ title, value }, index) => (
            <Menu.Item
              key={`${title}-${index}`}
              id={`option-${camelCase(title)}`}
              onClick={() => setSelectedOption({ title: title, value: value })}
            >
              {title}
            </Menu.Item>
          ))
        : null}
    </Menu>
  );

  return (
    // @ts-ignore this lib is incompatible with react18
    <StyledModal visible={isModalOpen} onCancel={toggleModal} {...MODAL_PROPS}>
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <>
        <StyledTitle>{t('DesignService.editDSPTitle')}</StyledTitle>
        <StyledSectionWrapper>
          <p>{t('DesignService.selectProvider')}</p>
          <p data-testid="orderId">
            {t('DesignService.orderId', {
              orderId: serviceRequest?.id ?? '',
            })}
          </p>
          <p data-testid="projectName">
            {t('DesignService.projectName', {
              projectName: serviceRequest?.project?.name ?? '',
            })}
          </p>
        </StyledSectionWrapper>
      </>
      <StyledSectionWrapper>
        <p>{t('DesignService.currentProvider')}</p>

        {/* @ts-ignore this lib is incompatible with react18 */}
        <Dropdown overlay={dropdownMenu} trigger={['click']} disabled={!dspCompanies?.length}>
          <Button className="Button--White" id={`update-dsp`} data-testid="dropdownButton">
            {selectedOption?.title ?? 'Select Provider'}
            <DownOutlined />
          </Button>
        </Dropdown>
      </StyledSectionWrapper>

      <StyledButtonsWrapper>
        <Button onClick={toggleModal} className="Button--White" data-testid="cancelButton">
          {t('DesignService.cancel')}
        </Button>
        <Button
          onClick={handleDSPUpdate}
          className="Button--Blue"
          disabled={isDefaultProviderSelected}
          aria-disabled={isDefaultProviderSelected}
          data-testid="updateButton"
        >
          {t('DesignService.update')}
        </Button>
      </StyledButtonsWrapper>
    </StyledModal>
  );
};

export default ChangeDSPModal;
