import { FormikProps } from 'formik';
import { FORM_CONTROLS } from 'screens/Account/AccountCompanyInfo/DesignServicesSettings/constants';
import styled from 'styled-components';
import { FormSetbackInput } from '../components';
import FormProps from '../PlanSetForms/props';
import { FIRE_SETBACKS, OBSTRUCTION_SETBACKS } from './constants';

const Wrapper = styled.div`
  max-width: 100%;
  display: flex;
`;

const SetbackInputForms = ({
  getFieldProps,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
}: Partial<FormikProps<Record<string, string>>>) => {
  if (!getFieldProps || !setFieldValue || !setFieldTouched || !touched || !errors) {
    return <div></div>;
  }

  return (
    <Wrapper>
      {/* Fire SetBacks */}
      <FormSetbackInput
        setbackName={FORM_CONTROLS.FIRE_SETBACKS}
        setbacks={FIRE_SETBACKS.setbacks}
        title={FIRE_SETBACKS.title}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        setFieldTouched={setFieldTouched as FormProps['setFieldTouched']}
        setFieldValue={setFieldValue as FormProps['setFieldTouched']}
      />
      {/* Obstruction SetBacks */}
      <FormSetbackInput
        setbacks={OBSTRUCTION_SETBACKS.setbacks}
        setbackName={FORM_CONTROLS.OBSTRUCTION_SETBACKS}
        title={OBSTRUCTION_SETBACKS.title}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        setFieldTouched={setFieldTouched as FormProps['setFieldTouched']}
        setFieldValue={setFieldValue as FormProps['setFieldTouched']}
      />
    </Wrapper>
  );
};

export default SetbackInputForms;
