import colors from 'helpers/constants/colors';
import fontFamily from 'helpers/constants/fontFamily';

const mentionsInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: fontFamily,
    zIndex: -1,
  },

  '&multiLine': {
    control: {
      fontFamily: fontFamily,
      minHeight: 120,
      maxHeight: 120,
      zIndex: 1,
    },
    highlighter: {
      padding: 9,
      border: '1px solid',
    },
    input: {
      paddingRight: 35,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px solid',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: colors.white,
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      width: '250%',
      position: 'absolute',
      bottom: 14,
      zIndex: 10,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: colors.mainBlue,
        color: colors.white,
      },
    },
  },
};

const mentionStyle = {
  backgroundColor: colors.mainBlue,
  padding: '3px .8px',
  borderRadius: '5px',
  zIndex: 3,
  color: colors.white,
  position: 'relative',
  top: '1px',
  fontFamily: fontFamily,
};

export { mentionStyle, mentionsInputStyle };
