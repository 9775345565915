import { useCallback, useContext, useEffect, useState } from 'react';

import { Carousel } from 'antd';
import { MediaItem } from 'types';

import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';

import { ImagePanzoom } from '../ImagePanzoom';
import { CarouselArrow } from './CarouselArrow';
import { MediaCarouselContext } from './MediaCarouselProvider';
import { MediaCarouselProps } from './types';

const StyledCarousel = styled.div`
  .ant-carousel .slick-slider {
    height: 52vh;
    margin-bottom: 11.6rem;
    position: relative;

    @media only screen and (max-width: 1650px) and (min-width: ${breakpoints.lg}) {
      margin-bottom: 8rem;
    }

    @media only screen and (max-width: ${breakpoints.lg}) {
      height: 48vh;
    }
  }

  .ant-carousel .slick-arrow .slick-prev {
    display: none;
  }
  .ant-carousel .slick-arrow .slick-next {
    display: none;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -6rem;
  }

  .slick-slide div {
    margin-bottom: 0;
  }

  .left-arrow {
    position: absolute;
    top: 35%;
    display: block;
    width: 2rem;
    margin-left: 1.5rem;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0;
    cursor: pointer;
    z-index: 1;
    background: transparent;
  }

  .right-arrow {
    position: absolute;
    top: 35%;
    display: block;
    width: 2rem;
    margin-right: 1.5rem;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: 0;
    cursor: pointer;
    z-index: 1;
    right: 10px;
    background: transparent;
  }
`;

const LEFT_ARROW_CHAR_CODE = 37;
const RIGHT_ARROW_CHAR_CODE = 39;

/**
 * Must be wrapped with a <MediaCarouselProvider>
 */
const MediaCarousel = ({
  imageUrl,
  allMedia,
  setSelectedMediaItem,
  isPortrait = false,
}: MediaCarouselProps) => {
  const { activeIndex, mediaCarouselRef, setActiveIndex } = useContext(MediaCarouselContext);

  const [leftRightKeyPress, setLeftRightKeyPress] = useState<'left' | 'right' | undefined>();

  const goLeft = useCallback(() => {
    const newIndex = ((activeIndex ?? 0) - 1 + allMedia.length) % allMedia.length;
    setActiveIndex(newIndex);
    setSelectedMediaItem(allMedia[newIndex]);
    mediaCarouselRef?.current?.goTo(newIndex);
  }, [activeIndex, allMedia, mediaCarouselRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const goRight = useCallback(() => {
    const newIndex = ((activeIndex ?? 0) + 1) % allMedia.length;
    setActiveIndex(newIndex);
    setSelectedMediaItem(allMedia[newIndex]);
    mediaCarouselRef?.current?.goTo(newIndex);
  }, [activeIndex, allMedia, mediaCarouselRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = useCallback((evt: KeyboardEvent) => {
    if ([LEFT_ARROW_CHAR_CODE, RIGHT_ARROW_CHAR_CODE].includes(evt.keyCode)) {
      evt.preventDefault();
      setLeftRightKeyPress(evt.keyCode === LEFT_ARROW_CHAR_CODE ? 'left' : 'right');
    }
  }, []);

  useEffect(() => {
    if (leftRightKeyPress === 'left') {
      goLeft();
    } else if (leftRightKeyPress === 'right') {
      goRight();
    }
    leftRightKeyPress !== undefined && setLeftRightKeyPress(undefined);
  }, [leftRightKeyPress, goLeft, goRight]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Listen for <- and -> arrow keys
   */
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledCarousel>
      {/* Prev image */}
      <CarouselArrow className={'left-arrow'} direction="left" onClick={goLeft} />

      <Carousel
        ref={mediaCarouselRef}
        effect="fade"
        arrows={true}
        dots={false}
        infinite={true}
        lazyLoad={'ondemand'}
        speed={100}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {allMedia.map((mediaItem, index) => (
          <ImagePanzoom
            key={index}
            imageUrl={imageUrl}
            mimeType={(mediaItem as MediaItem).mimeType}
            thumbnailUrl={(mediaItem as MediaItem).thumbnailUrl}
            isPortrait={isPortrait}
          />
        ))}
      </Carousel>

      {/* */}
      <CarouselArrow className={'right-arrow'} direction="right" onClick={goRight} />
    </StyledCarousel>
  );
};

export default MediaCarousel;
