export const SLICE_NAMESPACE = 'mediaCategories';

/**
 *  Update Store
 */
export const UPDATE_MEDIA_CATEGORY = `${SLICE_NAMESPACE}/UPDATE_MEDIA_CATEGORY`;
export const CREATE_MEDIA_CATEGORY = `${SLICE_NAMESPACE}/CREATE_MEDIA_CATEGORY`;
export const DELETE_MEDIA_CATEGORY = `${SLICE_NAMESPACE}/DELETE_MEDIA_CATEGORY`;
export const GET_MEDIA_CATEGORY_BY_ID = `${SLICE_NAMESPACE}/GET_MEDIA_CATEGORY_BY_ID`;
export const GET_MEDIA_CATEGORIES_BY_TYPE = `${SLICE_NAMESPACE}/GET_MEDIA_CATEGORY_BY_TYPE`;
export const GET_ALL_MEDIA_CATEGORIES = `${SLICE_NAMESPACE}/GET_ALL_MEDIA_CATEGORIES`;
