import { DRONE_DATA_SCORE as SCORES } from '@cpm/scanifly-shared-data';

export type Weights = {
  [SCORES.cameraTechnique]: string;
  [SCORES.flightPath]: string;
  [SCORES.imageQuality]: string;
};

export const oldWeights: Weights = {
  [SCORES.cameraTechnique]: '30%',
  [SCORES.flightPath]: '50%',
  [SCORES.imageQuality]: '20%',
};

export const newWeights: Weights = {
  [SCORES.cameraTechnique]: '25%',
  [SCORES.flightPath]: '50%',
  [SCORES.imageQuality]: '25%',
};
