export const SLICE_NAMESPACE = 'checklists';

/**
 *  Update Store
 */
export const UPDATE_CHECKLIST = `${SLICE_NAMESPACE}/UPDATE_CHECKLIST`;
export const UPDATE_CHECKLISTS = `${SLICE_NAMESPACE}/UPDATE_CHECKLISTS`;
export const UPDATE_CHECKLIST_TEMPLATE = `${SLICE_NAMESPACE}/UPDATE_CHECKLIST_TEMPLATE`;
export const UPDATE_CHECKLIST_TEMPLATES = `${SLICE_NAMESPACE}/UPDATE_CHECKLIST_TEMPLATES`;
export const DELETE_CHECKLIST_TEMPLATE = `${SLICE_NAMESPACE}/DELETE_CHECKLIST_TEMPLATE`;
export const UPDATE_ADMIN_CHECKLIST_TEMPLATES = `${SLICE_NAMESPACE}/UPDATE_ADMIN_CHECKLIST_TEMPLATES`;
