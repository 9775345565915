import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UppyFile } from '@uppy/core';
import { Modal } from 'antd';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import { FileMetaData } from 'helpers/utils/uppy/types';

import { DistantPhotoList } from './DistantPhotoList';
import { ModalFooter } from './ModalFooter';
import { ModalHeaderTitle } from './ModalHeaderTitle';
import { DistantPhotoReviewProps } from './types';

const StyledModal = styled(Modal)`
  @media (max-width: 600px) {
    max-width: 80%;
  }
  @media (min-width: 600px) {
    max-width: 60%;
  }

  .ant-modal-close {
    display: flex;
  }
  .ant-modal-title {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;
  }
  .subtitle {
    color: ${colors.labelGray};
    margin-top: 0;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    text-align: center;
  }
`;

/**
 * projectMedias with metadata `isDistant` will be shown in a modal for the user
 * to review and remove or ignore files before project upload.
 */
function DistantPhotoReview({ imagePreviews, onRemoved, projectMedias }: DistantPhotoReviewProps) {
  const { t } = useTranslation();

  const [distantPhotos, setDistantPhotos] = useState<UppyFile<FileMetaData>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setIgnoredDistantPhotos] = useState<UppyFile<FileMetaData>[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<UppyFile<FileMetaData>[]>(distantPhotos);

  const removeDistantPhotos = useCallback((distantPhotosToRemove: UppyFile[]) => {
    setDistantPhotos((currentDistantPhotos) => {
      // Save ignored ones so we don't display them again if the user adds more photos
      const ignoredPhotos = currentDistantPhotos.filter(
        ({ id: existingPhotoId }) =>
          distantPhotosToRemove.findIndex(
            ({ id: photoToRemoveId }) => existingPhotoId === photoToRemoveId
          ) === -1
      );
      setIgnoredDistantPhotos((currentIgnoredPhotos) => {
        const newIgnoredPhotos = ignoredPhotos.filter(
          (ignoredPhoto) =>
            currentIgnoredPhotos.findIndex(
              (currentIgnoredPhoto) => currentIgnoredPhoto.id === ignoredPhoto.id
            ) === -1
        );
        return [...newIgnoredPhotos, ...currentIgnoredPhotos];
      });

      // Then remove all `distantPhotos` so modal becomes invisible
      return [];
    });
  }, []);

  const onRemove = useCallback(() => {
    setIsLoading(true);
    removeDistantPhotos(selectedPhotos);
    // Notify parent to remove from Uppy
    onRemoved(selectedPhotos);
    setIsLoading(false);
    setSelectedPhotos([]);
  }, [onRemoved, removeDistantPhotos, selectedPhotos]);

  const onClick = useCallback((id: string) => {
    setDistantPhotos((distantPhotos) => {
      setSelectedPhotos((prevSelectedPhotos) => {
        const distantPhoto = distantPhotos.find((distantPhoto) => distantPhoto.id === id);
        if (!distantPhoto) {
          return prevSelectedPhotos;
        }

        const selectedIndex = prevSelectedPhotos.findIndex(({ id }) => id === distantPhoto.id);
        const tempSelectedPhotos = [...prevSelectedPhotos];
        if (selectedIndex !== -1) {
          tempSelectedPhotos.splice(selectedIndex, 1);
          return tempSelectedPhotos;
        } else {
          return [...tempSelectedPhotos, distantPhoto];
        }
      });
      return distantPhotos;
    });
  }, []);

  const onIgnore = useCallback(() => removeDistantPhotos([]), [removeDistantPhotos]);

  // Find photos that are alarmingly far from the project
  useEffect(() => {
    setIgnoredDistantPhotos((ignoredDistantPhotos) => {
      setDistantPhotos(() => {
        const distantPhotos = projectMedias.filter(
          ({ id, meta }) =>
            meta?.isDistant &&
            ignoredDistantPhotos.findIndex(
              (ignoredDistantPhoto) => ignoredDistantPhoto.id === id
            ) === -1
        );
        // Make all selected by default
        setSelectedPhotos(distantPhotos);
        return distantPhotos;
      });
      return ignoredDistantPhotos;
    });
  }, [projectMedias]);

  const isVisible = distantPhotos.length !== 0;

  return (
    // @ts-ignore antd is not compatible with @types/react v18
    <StyledModal
      closable
      data-testid={'distant-photo-review-modal'}
      footer={<ModalFooter isLoading={isLoading} onIgnore={onIgnore} onRemove={onRemove} />}
      onCancel={onIgnore}
      title={<ModalHeaderTitle />}
      visible={isVisible}
    >
      <p className={'subtitle'}>{t('DistantPhotoReview.belowImagesTooFar')}</p>
      <DistantPhotoList
        distantPhotos={distantPhotos}
        imagePreviews={imagePreviews}
        onClick={onClick}
        selectedPhotos={selectedPhotos}
      />
    </StyledModal>
  );
}

export default DistantPhotoReview;
