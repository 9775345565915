import { Folder } from 'types';

import { PERMISSION } from '@cpm/scanifly-shared-data';
import { OPTIONS, PERMISSION_TOOLTIP_TEXTS } from '../constants';
import { permissionsMappedToLabels } from './permissionsMappedToLabels';

const { ADMIN_TOOLS, ALL_PROJECTS } = OPTIONS;

type Props = {
  values: {
    email: string;
    companyId: string;
  };
  currentUser: {
    email: string;
  };
  isSimpleDesignFeatureEnabled?: boolean;
};

export const permissionsOptions = ({
  values,
  currentUser,
  isSimpleDesignFeatureEnabled,
}: Props) => {
  const { label, value } = ADMIN_TOOLS;
  const adminToolsOption = {
    label,
    value,
    tooltipText: PERMISSION_TOOLTIP_TEXTS.ADMIN_TOOLS,
  };

  // don't allow user to set themselves as simpleDesign
  const showSimpleDesign = isSimpleDesignFeatureEnabled && values.email !== currentUser.email;

  const allPermissionsIncludingSimpleDesign = Object.entries(PERMISSION).map(
    ([key, permission]) => {
      return {
        label: permissionsMappedToLabels.get(permission),
        value: permission,
        // @ts-ignore
        tooltipText: PERMISSION_TOOLTIP_TEXTS[key],
      };
    }
  );

  const allPermissionsExcludingSimpleDesign = Object.entries(PERMISSION)
    .filter(
      ([_, permission]) =>
        permission !== PERMISSION.SIMPLEDESIGN && permission !== PERMISSION.SALESMANAGER
    )
    .map(([key, permission]) => {
      return {
        label: permissionsMappedToLabels.get(permission),
        value: permission,
        // @ts-ignore
        tooltipText: PERMISSION_TOOLTIP_TEXTS[key],
      };
    });

  const allPermissions = showSimpleDesign
    ? allPermissionsIncludingSimpleDesign
    : allPermissionsExcludingSimpleDesign;

  return [adminToolsOption, ...allPermissions];
};

export const projectAccessOptions = ({
  folders,
  isAdminAddingTeammate,
  values,
}: {
  folders: Folder[];
  isAdminAddingTeammate: boolean;
  values: { [key: string]: any };
}) => {
  const { label, value } = ALL_PROJECTS;
  const allProjectsOption = {
    label,
    value,
  };

  const allCompanyFolders = folders.map((folder) => {
    return { label: folder.name, value: folder.id, color: folder.color };
  });
  return isAdminAddingTeammate && !values.companyId
    ? [allProjectsOption]
    : [allProjectsOption, ...allCompanyFolders];
};
