import { MaintenanceReport, MaintenanceReportPopulated } from '@cpm/scanifly-shared-data';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createMaintenanceReport,
  deleteMaintenanceReport,
  fetchAllMaintenanceReportsForProject,
  fetchMaintenanceReport,
  updateMaintenanceReport,
} from 'api/maintenanceReport/maintenanceReportService';

const name = 'maintenanceReport';

type StateType = {
  createdReport: null | MaintenanceReport;
  updatedReport: null | MaintenanceReport;
  report: null | MaintenanceReportPopulated;
  allReports: null | MaintenanceReport[];
  isReportLoading: boolean;
  isAllReportsLoading: boolean;
  isLoading: boolean;
  error?: any;
};

export const maintenanceReportCreationRequested = createAsyncThunk(
  `${name}/maintenanceReportCreationRequested`,
  async (
    { projectId, companyId, title }: { projectId: string; companyId: string; title: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createMaintenanceReport({
        projectId,
        companyId,
        title,
      });
      dispatch(allMaintenanceReportsRequested({ projectId }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const maintenanceReportRequested = createAsyncThunk(
  `${name}/maintenanceReportRequested`,
  async ({ reportId }: { reportId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchMaintenanceReport(reportId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const allMaintenanceReportsRequested = createAsyncThunk(
  `${name}/allMaintenanceReportsRequested`,
  async ({ projectId }: { projectId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchAllMaintenanceReportsForProject(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const maintenanceReportUpdateRequested = createAsyncThunk(
  `${name}/maintenanceReportUpdateRequested`,
  async (
    {
      onUpdateSuccess,
      title,
      reportId,
      summary,
      weatherConsiderations,
      issues,
      recommendedFixesAndImprovements,
      notesForGeneralMaintenance,
      notesForThermal,
    }: {
      onUpdateSuccess: () => void;
      reportId: string;
      projectId: string;
      title?: string;
      summary?: string;
      weatherConsiderations?: string;
      issues?: string;
      recommendedFixesAndImprovements?: string;
      notesForGeneralMaintenance?: string;
      notesForThermal?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateMaintenanceReport(
        reportId,
        title,
        summary,
        weatherConsiderations,
        issues,
        recommendedFixesAndImprovements,
        notesForGeneralMaintenance,
        notesForThermal
      );
      onUpdateSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const maintenanceReportDeletionRequested = createAsyncThunk(
  `${name}/maintenanceReportDeletionRequested`,
  async (
    { reportId, projectId }: { reportId: string; projectId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await deleteMaintenanceReport(reportId);
      dispatch(allMaintenanceReportsRequested({ projectId }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

const maintenanceReportSlice = createSlice({
  name,
  initialState: {
    createdReport: null,
    updatedReport: null,
    report: null,
    isReportLoading: false,
    allReports: null,
    isAllReportsLoading: false,
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {
    resetMaintenanceReportState: (state) => {
      state.createdReport = null;
      state.updatedReport = null;
      state.report = null;
      state.allReports = null;
      state.isReportLoading = false;
      state.isAllReportsLoading = false;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(maintenanceReportCreationRequested.pending, (state: StateType) => {
        state.isLoading = true;
      })
      .addCase(maintenanceReportCreationRequested.fulfilled, (state: StateType, { payload }) => {
        state.createdReport = payload;
        state.isLoading = false;
      })
      .addCase(maintenanceReportCreationRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      })
      .addCase(maintenanceReportRequested.pending, (state: StateType) => {
        state.isReportLoading = true;
      })
      .addCase(maintenanceReportRequested.fulfilled, (state: StateType, { payload }) => {
        state.report = payload;
        state.isReportLoading = false;
      })
      .addCase(maintenanceReportRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isReportLoading = false;
      })
      .addCase(allMaintenanceReportsRequested.pending, (state: StateType) => {
        state.isAllReportsLoading = true;
      })
      .addCase(allMaintenanceReportsRequested.fulfilled, (state: StateType, { payload }) => {
        state.allReports = payload;
        state.isAllReportsLoading = false;
      })
      .addCase(allMaintenanceReportsRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isAllReportsLoading = false;
      })
      .addCase(maintenanceReportUpdateRequested.pending, (state: StateType) => {
        state.isLoading = true;
      })
      .addCase(maintenanceReportUpdateRequested.fulfilled, (state: StateType, { payload }) => {
        state.updatedReport = payload;
        state.isLoading = false;
      })
      .addCase(maintenanceReportUpdateRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      })
      .addCase(maintenanceReportDeletionRequested.pending, (state: StateType) => {
        state.isLoading = true;
      })
      .addCase(maintenanceReportDeletionRequested.fulfilled, (state: StateType) => {
        state.isLoading = false;
      })
      .addCase(maintenanceReportDeletionRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isLoading = false;
      });
  },
});

export const { resetMaintenanceReportState } = maintenanceReportSlice.actions;

export default maintenanceReportSlice.reducer;
