import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';

import { RootState } from 'state/store/store';

import { FormInput, FormSetbackInput, ProjectAddress } from '../components';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { RadioChangeEvent } from 'antd';
import { FORM_CONTROLS } from '../constants';
import CommercialZone from '../shared/CommercialZone';
import {
  CategoryInputWrapper,
  getCorrectPlaceholderText,
} from '../SitePlanForms/ProjectInfoSitePlan';
import { FormWrapper, StyledLeftCol, StyledRightCol, Wrapper } from '../styledComponents';
import { FIRE_SETBACKS, OBSTRUCTION_SETBACKS, PROJECT_INFORMATION_FORM } from './constants';
import FormProps from './props';

const ProjectInfoPlanSet = ({
  getFieldProps,
  touched,
  setFieldValue,
  setFieldTouched,
  errors,
  handleCommercialZone,
  commercialZone,
}: FormProps & {
  commercialZone: boolean;
  handleCommercialZone: (e: RadioChangeEvent) => void;
}) => {
  const { project } = useSelector((state: RootState) => state.project);
  const { company } = useSelector((state: RootState) => state.company);
  const [placeholder, setPlaceholder] = useState<string>(SIZED_FOR_CATEGORIES.none);
  const companyAddress =
    `${company?.address?.line}, ${company?.address?.city}, ${company?.address?.state}, ${company?.address.zip}` ??
    '';

  useEffect(() => {
    setFieldValue(FORM_CONTROLS.COMPANY_ADDRESS, companyAddress, true);
    setFieldValue(FORM_CONTROLS.PROJECT_ADDRESS, project?.address ?? '', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <StyledLeftCol>
        {/* Homeowner  */}
        <FormInput
          title={PROJECT_INFORMATION_FORM.HOMEOWNER_NAME.title}
          fieldProps={getFieldProps(FORM_CONTROLS.HOMEOWNER_NAME)}
          placeHolder={PROJECT_INFORMATION_FORM.HOMEOWNER_NAME.placeholder}
          id={FORM_CONTROLS.HOMEOWNER_NAME}
          ariaLabel="customer name"
          className={validateStatus(touched, errors, FORM_CONTROLS.HOMEOWNER_NAME)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.HOMEOWNER_NAME)}
        />

        {/* Company Address Autofill  */}
        <FormInput
          title={PROJECT_INFORMATION_FORM.COMPANY_ADDRESS.title}
          fieldProps={getFieldProps(FORM_CONTROLS.COMPANY_ADDRESS)}
          id={FORM_CONTROLS.COMPANY_ADDRESS}
          ariaLabel="company address"
          className={validateStatus(touched, errors, FORM_CONTROLS.COMPANY_ADDRESS)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.COMPANY_ADDRESS)}
        />

        {/* Name of AHJ  */}
        <FormInput
          title={PROJECT_INFORMATION_FORM.AHJ.title}
          fieldProps={getFieldProps(FORM_CONTROLS.AHJ)}
          placeHolder={PROJECT_INFORMATION_FORM.AHJ.placeholder}
          id={FORM_CONTROLS.AHJ}
          ariaLabel="AHJ"
          className={validateStatus(touched, errors, FORM_CONTROLS.AHJ)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.AHJ)}
        />

        {/* Fire Setbacks Component */}
        <FormSetbackInput
          setbackName={FORM_CONTROLS.FIRE_SETBACKS}
          setbacks={FIRE_SETBACKS.setbacks}
          title={FIRE_SETBACKS.title}
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </StyledLeftCol>
      <StyledRightCol>
        {/* Project Address Autofill  */}
        <ProjectAddress
          address={project?.address}
          id={FORM_CONTROLS.PROJECT_ADDRESS}
          title={PROJECT_INFORMATION_FORM.PROJECT_ADDRESS.title}
        />

        <CategoryInputWrapper>
          {/* Sized for category */}
          <FormInput
            title={`${PROJECT_INFORMATION_FORM.SIZED_FOR_CATEGORY.title}${getFieldProps(FORM_CONTROLS.SIZED_FOR_CATEGORY).value !== SIZED_FOR_CATEGORIES.none ? '*' : ''}`}
            fieldProps={getFieldProps(FORM_CONTROLS.SIZED_FOR_CATEGORY)}
            id={FORM_CONTROLS.SIZED_FOR_CATEGORY}
            customSelect
            options={PROJECT_INFORMATION_FORM.SIZED_FOR_CATEGORY.options}
            onChange={(option: {
              key: string;
              value: keyof typeof PROJECT_INFORMATION_FORM.SIZED_FOR_VALUE.placeholder;
            }) => {
              setFieldValue(FORM_CONTROLS.SIZED_FOR_CATEGORY, option.value);
              const currentPlaceHolder = getCorrectPlaceholderText(option.value);
              setPlaceholder(currentPlaceHolder);
            }}
            filter
            ariaLabel="select sized for category"
          />

          {/* Sized for value */}
          <FormInput
            fieldProps={getFieldProps(FORM_CONTROLS.SIZED_FOR_VALUE)}
            title=""
            placeHolder={placeholder}
            id={FORM_CONTROLS.SIZED_FOR_VALUE}
            className={validateStatus(touched, errors, FORM_CONTROLS.SIZED_FOR_VALUE)}
            errorValidation={renderValidationMessage(
              touched,
              errors,
              FORM_CONTROLS.SIZED_FOR_VALUE
            )}
            type="number"
            step="1"
            min="0"
            onKeyDown={(e: KeyboardEvent) => {
              if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
                e.preventDefault();
              }
            }}
          />
        </CategoryInputWrapper>

        {/* Utility Company  */}
        <FormInput
          title={PROJECT_INFORMATION_FORM.UTILITY_COMPANY.title}
          fieldProps={getFieldProps(FORM_CONTROLS.UTILITY_COMPANY)}
          placeHolder={PROJECT_INFORMATION_FORM.UTILITY_COMPANY.placeholder}
          id={FORM_CONTROLS.UTILITY_COMPANY}
          className={validateStatus(touched, errors, FORM_CONTROLS.UTILITY_COMPANY)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.UTILITY_COMPANY)}
        />

        {/* Obstruction SetBacks */}
        <FormSetbackInput
          setbacks={OBSTRUCTION_SETBACKS.setbacks}
          setbackName={FORM_CONTROLS.OBSTRUCTION_SETBACKS}
          title={OBSTRUCTION_SETBACKS.title}
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
        />
      </StyledRightCol>

      {/* Commercial zone */}
      <FormWrapper>
        <CommercialZone
          handleCommercialZone={handleCommercialZone}
          commercialZone={commercialZone}
        />
      </FormWrapper>
    </Wrapper>
  );
};

export default ProjectInfoPlanSet;
