import { useDispatch } from 'react-redux';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

import { adminUpdateCompanyPricingTier } from 'state/slices/admin/adminCompaniesSlice';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { openNotification } from 'helpers/utils/openNotification';

import { TIERED_PRICING } from './constants';

const ChangeTierActionDropDown = ({ companyName, companyId, pricingTier }) => {
  const dispatch = useDispatch();

  const handleChangeTier = (companyId, newPricingTier) => {
    dispatch(adminUpdateCompanyPricingTier({ companyId, newPricingTier }));

    return openNotification({
      type: 'success',
      title: 'Tier Changed!',
      text: `${companyName} has been changed to ${newPricingTier}!`,
    });
  };

  const handleConfirm = (companyId, newPricingTier) => {
    confirm({
      title: 'Change Tier',
      content: `Are you sure that you want to change ${companyName} from ${pricingTier} to ${newPricingTier}?`,
      okText: 'Change Tier',
      okButtonProps: { style: { background: colors.blue } },
      onOk: () => {
        handleChangeTier(companyId, newPricingTier);
      },
      ...CONFIRM_PROPS,
    });
  };

  const changeTierActionList = TIERED_PRICING.map((obj) => {
    return {
      title: obj.header,
      actionType: () => handleConfirm(companyId, obj.tier),
      isDisabled: pricingTier === obj.tier,
    };
  });

  const dropdownMenu = (
    <Menu>
      {changeTierActionList.map(({ title, actionType, isDisabled }) => (
        <Menu.Item
          onClick={actionType}
          key={title}
          disabled={isDisabled}
          id={`option-${camelCase(title)}-${companyId}`}
        >
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={dropdownMenu} trigger={['click']}>
      <Button className="Button--White" id={`pricing-tier-select-${companyId}`}>
        Select <DownOutlined />
      </Button>
    </Dropdown>
  );
};

ChangeTierActionDropDown.propTypes = {
  companyName: PropTypes.string,
  companyId: PropTypes.string,
  accountHolder: PropTypes.object,
  pricingTier: PropTypes.string,
  newPricingTier: PropTypes.string,
};

export default ChangeTierActionDropDown;
