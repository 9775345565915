import { i18n } from 'helpers/utils/translations';

const getTitle = (key: string, isMaxFillOrSiteModelingRequest: boolean) => {
  if (key === 'design') {
    if (isMaxFillOrSiteModelingRequest) {
      return i18n.t('DesignService.turnaroundTime');
    }
    return i18n.t('DesignService.design');
  }
  if (key === 'total') return i18n.t('DesignService.totalEstimate');
  if (key === 'createdAt') return i18n.t('DesignService.orderDate');
};

export { getTitle };
