import moment from 'moment';

import { csvDateFormat } from 'helpers/utils/dateFormat';
import { formatPercent } from 'helpers/utils/formatPercent';
import { isProjectCompleted } from 'helpers/utils/isProjectCompleted';
import { renderProjectType } from 'helpers/utils/renderProjectType';

interface IDroneData {
  droneDataScore: number;
  cameraTechnique: number;
  imageQuality: number;
  flightPath: number;
}

interface IItem {
  id: string;
  name: string;
  status: string;
  type: string;
  completedDate: string;
  statusDescription: string;
  designs: any[];
  address: string;
  createdAt: string;
  createdBy: string;
  droneData?: IDroneData;
  droneDataScore?: number;
}

interface Props {
  dataSource: IItem[];
  droneDataScoreAccess?: boolean;
}

export const handleMyProjectsCSVExport = ({ dataSource, droneDataScoreAccess }: Props) => {
  return dataSource.map((item: IItem) => {
    const { id, type, completedDate, statusDescription, designs, droneData } = item || {};

    if (droneDataScoreAccess) {
      const { droneDataScore, cameraTechnique, imageQuality, flightPath } = droneData || {};

      return {
        ...item,
        type: type ? renderProjectType(type) : '-',
        completedDate: completedDate ? moment(completedDate).format(csvDateFormat) : '-',
        id: isProjectCompleted(statusDescription)
          ? `https://portal.scanifly.com/projects/${id}/designs`
          : '-',
        annualProduction: designs?.length ? designs[0].annualProduction : '-',
        ASA: designs?.length ? `${designs[0].ASA}%` : '-',
        systemSize: designs?.length ? designs[0].systemSize : '-',
        droneDataScore: formatPercent(droneDataScore ? Number(droneDataScore) : 0),
        cameraTechnique: formatPercent(cameraTechnique ? Number(cameraTechnique) : 0),
        imageQuality: formatPercent(imageQuality ? Number(imageQuality) : 0),
        flightPath: formatPercent(flightPath ? Number(flightPath) : 0),
      };
    } else {
      return {
        ...item,
        type: type ? renderProjectType(type) : '-',
        completedDate: completedDate ? moment(completedDate).format(csvDateFormat) : '-',
        id: isProjectCompleted(statusDescription)
          ? `https://portal.scanifly.com/projects/${id}/designs`
          : '-',
        annualProduction: designs?.length ? designs[0].annualProduction : '-',
        ASA: designs?.length ? `${designs[0].ASA}%` : '-',
        systemSize: designs?.length ? designs[0].systemSize : '-',
      };
    }
  });
};
