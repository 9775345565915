import PropTypes from 'prop-types';

import { designPropTypes } from 'helpers/props/design';

import { MODALS } from '../constants';
import CreateNewDesignModal from './CreateNewDesignModal';
import DeleteDesignModal from './DeleteDesignModal';
import DuplicateDesignModal from './DuplicateDesignModal/DuplicateDesignModal';
import RenameDesignModal from './RenameDesignModal';

const DesignsModals = ({ designs, modalVisible, handleModalClose, selectedDesign }) => (
  <>
    <CreateNewDesignModal
      designs={designs}
      isModalVisible={modalVisible === MODALS.ADD_NEW_DESIGN}
      handleModalClose={handleModalClose}
    />
    <DeleteDesignModal
      isModalVisible={modalVisible === MODALS.CONFIRM_DELETE_DESIGN}
      handleModalClose={handleModalClose}
      selectedDesign={selectedDesign}
    />
    <RenameDesignModal
      designs={designs}
      isModalVisible={modalVisible === MODALS.RENAME_DESIGN}
      handleModalClose={handleModalClose}
      selectedDesign={selectedDesign}
    />
    <DuplicateDesignModal
      isModalVisible={modalVisible === MODALS.DUPLICATE_DESIGN}
      handleModalClose={handleModalClose}
      selectedDesign={selectedDesign}
    />
  </>
);

DesignsModals.propTypes = {
  designs: PropTypes.arrayOf(designPropTypes).isRequired,
  modalVisible: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  selectedDesign: designPropTypes,
};

export default DesignsModals;
