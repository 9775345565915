import { Checkbox, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';

import { OPTIONS } from '../InviteOrChangeTeammatesPermissions/constants';
import Tag from '../Tag';
import './MultiCheck.scss';

const MultiCheck = ({ options, onChange, value, isFolders = false }) => {
  const { Group } = Checkbox;
  const flattenedOptions = options?.map(({ label, value, tooltipText }) => ({
    label,
    tooltipText,
    value: Array.isArray(value) ? value.toString() : value,
  }));
  const { ALL_PROJECTS } = OPTIONS;
  return (
    <div className="MultiCheck">
      {isFolders ? (
        <Group onChange={onChange} value={value}>
          {options.map((folder) => {
            return (
              <Checkbox key={folder.value} value={folder.value} id={folder.value}>
                {folder.value === ALL_PROJECTS.value ? (
                  folder.label
                ) : (
                  <Tag color={folder.color}>
                    <FolderIcon className="MultiCheck-FolderIcon" />
                    <span>{folder.label}</span>
                  </Tag>
                )}
              </Checkbox>
            );
          })}
        </Group>
      ) : (
        <Group onChange={onChange} value={value}>
          {flattenedOptions.map((option) => (
            <Tooltip title={option.tooltipText} key={option.value}>
              <Checkbox value={option.value} id={option.value}>
                {option.label}
              </Checkbox>
            </Tooltip>
          ))}
        </Group>
      )}
    </div>
  );
};

MultiCheck.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      color: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isFolders: PropTypes.bool,
};

export default MultiCheck;
