import PropTypes from 'prop-types';

import { validators } from 'helpers/utils';
import { isPdf } from 'helpers/utils/isPdf';

import {
  renderAltitude,
  renderDate,
  renderLocation,
  renderOrientation,
  renderTime,
} from './helpers';
import './ImagePreviewMeta.scss';

const ImagePreviewMeta = ({ image, isLoading, metadata }) => {
  if (isPdf(image)) {
    return null;
  }

  return (
    <div className="ImagePreviewMeta">
      <div className="ImagePreviewMeta-Divider" />
      <div className="ImagePreviewMeta-Title">
        {validators.isVideo(image) ? 'Video' : 'Image'} Metadata
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="ImagePreviewMeta-List">
          {renderDate(metadata)}
          {renderTime(metadata)}
          {renderLocation(metadata)}
          {renderAltitude(metadata)}
          {renderOrientation(metadata)}
        </div>
      )}
      {!isLoading && !metadata && <div>Couldn't find metadata for this file</div>}
    </div>
  );
};

ImagePreviewMeta.propTypes = {
  image: PropTypes.object,
  isLoading: PropTypes.bool,
  metadata: PropTypes.object,
};

export default ImagePreviewMeta;
