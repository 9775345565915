import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_MODULE_OPTIONS } from '@cpm/scanifly-shared-data';
import { Col, Input, Row } from 'antd';
import { FormikProps } from 'formik';

import { companyModuleLibrariesRequested } from 'state/slices/moduleLibrary/moduleLibrarySlice';
import { AppDispatch, RootState } from 'state/store/store';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';
import { DESIGN_INFORMATION_FORM } from './constants';
import { ModifiedCustomSelect } from './ModifiedCustomSelect';

export const DesignInformation = ({
  getFieldProps,
  setFieldValue,
  handleBlur,
  values,
  touched,
  errors,
}: FormikProps<Record<string, string | any>>) => {
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { companyModules } = useSelector((state: RootState) => state.moduleLibrary);
  const [moduleLibraryOptions, setModuleLibraryOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(companyModuleLibrariesRequested({ userId: currentUser.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const modifiedOptions = [...companyModules, ...DEFAULT_MODULE_OPTIONS].map((module) => ({
      label: module,
      value: module,
    }));
    setModuleLibraryOptions(modifiedOptions);
  }, [companyModules]);

  const [handleDesignOneBlur, getDesignOneSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.DESIGN_ONE_MODULE
  );

  const [handleDesignTwoBlur] = useHandleSelectBlur(handleBlur, FORM_CONTROLS.DESIGN_TWO_MODULE);

  const [handleModuleOrientationBlur, getModuleOrientationSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.MODULE_ORIENTATION
  );

  const [handleMixedOrientationBlur, getMixedOrientationSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.MIXED_ORIENTATION
  );

  const [handleModuleSpacingLongBlur, getModuleSpacingLongSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.MODULE_SPACING_LONG
  );

  const [handleModuleSpacingShortBlur, getModuleSpacingShortSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.MODULE_SPACING_SHORT
  );

  const [handleAzimuthStartBlur, getAzimuthStartSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.AZIMUTH_RANGE_START
  );

  const [handleAzimuthEndBlur, getAzimuthEndSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.AZIMUTH_RANGE_END
  );

  const designInformationFormModuleCustomSelects = [
    {
      formControl: FORM_CONTROLS.DESIGN_ONE_MODULE,
      formData: {
        ...DESIGN_INFORMATION_FORM.DESIGN_ONE_MODULE,
        options: moduleLibraryOptions,
      },
      handleBlur: handleDesignOneBlur,
      getError: getDesignOneSelectError,
    },
    {
      formControl: FORM_CONTROLS.DESIGN_TWO_MODULE,
      formData: {
        ...DESIGN_INFORMATION_FORM.DESIGN_TWO_MODULE,
        options: moduleLibraryOptions,
      },
      handleBlur: handleDesignTwoBlur,
    },
  ];

  const designInformationFormOrientationCustomSelects = [
    {
      formControl: FORM_CONTROLS.MODULE_ORIENTATION,
      formData: DESIGN_INFORMATION_FORM.MODULE_ORIENTATION,
      handleBlur: handleModuleOrientationBlur,
      getError: getModuleOrientationSelectError,
    },
    {
      formControl: FORM_CONTROLS.MIXED_ORIENTATION,
      formData: DESIGN_INFORMATION_FORM.MIXED_ORIENTATION,
      handleBlur: handleMixedOrientationBlur,
      getError: getMixedOrientationSelectError,
    },
  ];

  const designInformationFormModuleSpacingCustomSelects = [
    {
      formControl: FORM_CONTROLS.MODULE_SPACING_LONG,
      formData: DESIGN_INFORMATION_FORM.MODULE_SPACING_LONG,
      handleBlur: handleModuleSpacingLongBlur,
      getError: getModuleSpacingLongSelectError,
    },
    {
      formControl: FORM_CONTROLS.MODULE_SPACING_SHORT,
      formData: DESIGN_INFORMATION_FORM.MODULE_SPACING_SHORT,
      handleBlur: handleModuleSpacingShortBlur,
      getError: getModuleSpacingShortSelectError,
    },
  ];

  const designInformationAzimuthCustomSelects = [
    {
      formControl: FORM_CONTROLS.AZIMUTH_RANGE_START,
      formData: DESIGN_INFORMATION_FORM.AZIMUTH_RANGE_START,
      handleBlur: handleAzimuthStartBlur,
      getError: getAzimuthStartSelectError,
    },
    {
      title: '-',
    },
    {
      formControl: FORM_CONTROLS.AZIMUTH_RANGE_END,
      formData: DESIGN_INFORMATION_FORM.AZIMUTH_RANGE_END,
      handleBlur: handleAzimuthEndBlur,
      getError: getAzimuthEndSelectError,
    },
  ];

  const designInformationFormInputs = [
    {
      formControl: FORM_CONTROLS.PREFERRED_MODULE_TILT,
      formData: DESIGN_INFORMATION_FORM.PREFERRED_MODULE_TILT,
    },
    {
      formControl: FORM_CONTROLS.TSRF_MINIMUM,
      formData: DESIGN_INFORMATION_FORM.TSRF_MINIMUM,
    },
  ];

  return (
    <Row>
      <Col className="form-column left-column">
        {designInformationFormModuleCustomSelects.map((select) => (
          <ModifiedCustomSelect
            select={select}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            values={values}
            key={select.formData.title}
          />
        ))}
        <Row className="form-row multi-select-wrapper">
          {designInformationFormModuleSpacingCustomSelects.map((select) => (
            <ModifiedCustomSelect
              select={select}
              getFieldProps={getFieldProps}
              setFieldValue={setFieldValue}
              values={values}
              key={select.formData.title}
            />
          ))}
        </Row>
        <Row className="form-row multi-select-wrapper">
          {designInformationAzimuthCustomSelects.map((select) => {
            if (!select?.formData) {
              return (
                <div className="separator" key={select?.title}>
                  {select?.title}
                </div>
              );
            }
            return (
              <ModifiedCustomSelect
                select={select}
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                values={values}
                key={select.formData.title}
              />
            );
          })}
        </Row>
      </Col>
      <Col className="form-column right-column">
        {designInformationFormOrientationCustomSelects.map((select) => (
          <ModifiedCustomSelect
            select={select}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            values={values}
            key={select.formData.title}
          />
        ))}
        {designInformationFormInputs.map((input) => (
          <Row className="form-row" key={input.formData.title}>
            <section className="input-wrapper">
              <label htmlFor={input.formControl}>
                <h3 className="title">{input.formData.title}</h3>
              </label>
              <div className="input">
                <Input
                  {...getFieldProps(input.formControl)}
                  placeholder={input.formData.placeholder}
                  aria-label={input.formData.placeholder}
                  id={input.formControl}
                  className={validateStatus(touched, errors, input.formControl)}
                />
                <div className="Form-Error">
                  {renderValidationMessage(touched, errors, input.formControl)}
                </div>
              </div>
            </section>
          </Row>
        ))}
      </Col>
    </Row>
  );
};
