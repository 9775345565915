import { useTranslation } from 'react-i18next';

import Revision from 'types/Revision';

import './RevisionDataModal.scss';

const RevisionDataModal = (revision?: { revision?: Revision }) => {
  const { t } = useTranslation();
  const revisionData = revision?.revision;

  return (
    <>
      <header className="RevisionDataModal-Title">
        {t('DesignService.revisionDataModal.mainTitle')}
      </header>
      <section className="RevisionDataModal-Content">
        <div className="RevisionDataModal-Subtitle">
          {t('DesignService.revisionDataModal.revisionReasonsTitle')}
        </div>
        <div className="RevisionDataModal-FormInputs">
          {revisionData?.reasons?.length
            ? revisionData?.reasons.map((reason, index) => <p key={`reason-${index}`}>{reason}</p>)
            : t('DesignService.revisionDataModal.notAvailable')}
        </div>
        <div className="RevisionDataModal-Subtitle">
          {t('DesignService.revisionDataModal.revisionNoteTitle')}
        </div>
        <div className="RevisionDataModal-FormInputs">
          {revisionData?.note ? (
            <p>{revisionData?.note}</p>
          ) : (
            t('DesignService.revisionDataModal.notAvailable')
          )}
        </div>
      </section>
    </>
  );
};

export default RevisionDataModal;
