import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Button, Spin, Tooltip } from 'antd';

import { companyLogoUpdated } from 'state/slices/companySlice';

import CompanyInfoIcon from 'assets/company-info.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import './AccountCompanyInfoLogo.scss';

const AccountCompanyInfoLogo = () => {
  const uploadLogoInput = useRef(null);
  const [logo] = useState(CompanyInfoIcon);
  const { companyLogo } = useSelector((state) => state.company);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const dispatch = useDispatch();

  const changeHandler = ({ target }) => {
    const [logo] = target.files;

    if (logo) {
      setUploadInProgress(true);
      const newLogo = new FormData();
      newLogo.append('file', logo);
      dispatch(companyLogoUpdated(newLogo));
    }
  };

  const handleUploadLogo = () => {
    uploadLogoInput.current.click();
  };

  useEffect(() => {
    setUploadInProgress(false);
  }, [companyLogo]);

  return (
    <>
      {uploadInProgress && (
        <div className="AccountCompanyInfoLogo-Spinner">
          <Spin size="medium" />
        </div>
      )}
      <div className="AccountCompanyInfoLogo-Wrapper">
        <Avatar
          alt="Company Settings icon"
          src={
            uploadInProgress ? null : companyLogo ? `data:image/png;base64,${companyLogo}` : logo
          }
          className="AccountCompanyInfoLogo"
        />
        <div className="AccountCompanyInfoLogo-Button-Wrapper">
          <input
            className="AccountCompanyInfoLogo-UploadInput"
            ref={uploadLogoInput}
            type="file"
            accept="image/png, image/jpeg"
            name="file"
            onChange={changeHandler}
          />
          <Button onClick={handleUploadLogo} className="Button--Dark AccountCompanyInfoLogo-Button">
            Upload Logo
          </Button>
          <Tooltip
            placement="topLeft"
            title="The image file should be PNG or JPG format, recommended size 300 x 300 pixels"
            arrowPointAtCenter
          >
            <QuestionMarkIcon />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default AccountCompanyInfoLogo;
