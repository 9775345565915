import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Button, Radio } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import { isEqual, omit, pickBy } from 'lodash-es';
import MapWrapper from 'screens/MapWrapper';

import { adminCompanyNameAndIdRequested } from 'state/slices/admin/adminCompaniesSlice';
import {
  adminSubmittedProject,
  adminSuccessfullySubmittedProject,
  adminUpdatedProjectScaniflyInfo,
  projectRequested,
  setTempProjectValues,
  userSubmittedScaniflyInfo,
} from 'state/slices/projectSlice';
import { companyUsersRequested } from 'state/slices/usersSlice';

import { CustomSelect, GoBackButton } from 'components';

import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  rootRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminDraftScaniflyInfoRoute,
  scaniflyAdminNewProjectScaniflyInfoRoute,
  scaniflyAdminProjectProjectInfoRoute,
} from 'helpers/constants/routes';
import { validateStatus } from 'helpers/utils/formValidationHelpers';

import { ENGINE_OPTIONS, FORM_CONTROLS, initialValues } from './constants';
import './ScaniflyInfo.scss';
import { validationSchema } from './validationSchema';

const { Group, Button: RadioButton } = Radio;

const ScaniflyInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams();

  const [pin, setPin] = useState(null);
  const [isExiting, setIsExiting] = useState(false);
  const [resetSelect, setResetSelect] = useState(false);

  const {
    project,
    tempProjectData,
    isLoading,
    isScaniflyInfoUpdatedSuccessfully,
    adminIsSubmitted,
  } = useSelector((state) => state.project);
  const { companyNameAndIdList } = useSelector((state) => state.adminCompanies);
  const { selectedCompanyUsers } = useSelector((state) => state.users);

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: scaniflyAdminNewProjectScaniflyInfoRoute(),
    }),
  };

  const { isExact: isDraftProject } = {
    ...useRouteMatch({
      path: scaniflyAdminDraftScaniflyInfoRoute(project?.id),
    }),
  };

  const isDraft =
    project?.statusDescription === PROJECT_STATUSES.DRAFT ||
    project?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;

  const {
    touched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    getFieldProps,
    setFieldValue,
    setValues,
    values,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const projectCustomer = selectedCompanyUsers.find(
        (user) => user.id === values[FORM_CONTROLS.USER_ID]
      );
      const cleanTempData = omit(tempProjectData, 'userId');
      const valuesToSave = {
        ...(!projectId && { ...cleanTempData }),
        engine: values[FORM_CONTROLS.ENGINE],
        createdBy: projectCustomer.email,
        companyId: values[FORM_CONTROLS.COMPANY],
      };
      projectId ? handleUpdate(projectId, valuesToSave) : handleSaveAndContinue(valuesToSave);
    },
  });

  const isFormChanged = !isEqual(
    pickBy(values),
    pickBy({
      [FORM_CONTROLS.ENGINE]: project?.engine,
      [FORM_CONTROLS.COMPANY]: project?.submittedFor?.company.id,
      [FORM_CONTROLS.USER_ID]: project?.submittedFor?.id,
    })
  );

  const isFormValid = isFormChanged && isValid && dirty;
  const isSavedFormValid = isValid && dirty;

  const getNextRoute = () => {
    if (isExiting) {
      return scaniflyAdminCustomerSupportUploadRoute();
    } else if (!isDraft) {
      return scaniflyAdminProjectProjectInfoRoute(project.id);
    } else {
      return scaniflyAdminDraftProjectInfoRoute(project.id);
    }
  };

  useEffect(() => {
    if (!companyNameAndIdList.length) {
      dispatch(adminCompanyNameAndIdRequested());
    }
  }, [companyNameAndIdList.length, dispatch]);

  useEffect(() => {
    if (projectId && (!project || projectId !== project.id)) {
      dispatch(projectRequested(projectId));
    } else if (!projectId && tempProjectData) {
      setValues({
        [FORM_CONTROLS.ENGINE]: tempProjectData.engine || initialValues[FORM_CONTROLS.ENGINE],
        [FORM_CONTROLS.COMPANY]: tempProjectData.companyId || '',
        [FORM_CONTROLS.USER_ID]: tempProjectData.userId || '',
      });
    } else if (project && projectId) {
      dispatch(companyUsersRequested(project.submittedFor?.company.id));
      setValues({
        [FORM_CONTROLS.ENGINE]: project.engine,
        [FORM_CONTROLS.COMPANY]: project.submittedFor?.company.id,
        [FORM_CONTROLS.USER_ID]: project.submittedFor?.id,
      });
    } else if (!projectId) {
      resetForm();
      setPin(null);
    }
  }, [project, projectId, dispatch, setValues, resetForm, tempProjectData]);

  useEffect(() => {
    if (project?.geolocation) {
      setPin({
        location: project.geolocation,
        id: '1',
      });
    }
    if (tempProjectData?.latitude && tempProjectData?.longitude) {
      setPin({
        location: {
          latitude: tempProjectData.latitude,
          longitude: tempProjectData.longitude,
        },
        id: '1',
      });
    }
  }, [project, tempProjectData]);

  useEffect(() => {
    if (isScaniflyInfoUpdatedSuccessfully || adminIsSubmitted) {
      history.push(getNextRoute());
    }
    return () => {
      if (isScaniflyInfoUpdatedSuccessfully) {
        return dispatch(userSubmittedScaniflyInfo());
      }
      if (adminIsSubmitted) {
        return dispatch(adminSuccessfullySubmittedProject());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    dispatch,
    history,
    isDraft,
    isExiting,
    isFormValid,
    isNewProject,
    isScaniflyInfoUpdatedSuccessfully,
    adminIsSubmitted,
  ]);

  useEffect(() => {
    if (
      !isNewProject &&
      projectId === project?.id &&
      ((isDraftProject && !isDraft) || (!isDraftProject && isDraft))
    ) {
      history.push(rootRoute());
    }
  }, [project, history, isDraft, isDraftProject, isNewProject, projectId]);

  const handleSaveAndExit = () => {
    setIsExiting(true);
    handleSubmit();
  };

  const handleSaveAndContinue = (values) => {
    dispatch(adminSubmittedProject({ projectData: values }));
  };

  const handleUpdate = (projectId, values) => {
    if (!isFormChanged) {
      return history.push(getNextRoute());
    }
    dispatch(adminUpdatedProjectScaniflyInfo({ projectId, values }));
  };

  const handleBack = () => {
    const tempValuesToSet = {
      engine: values[FORM_CONTROLS.ENGINE],
      userId: values[FORM_CONTROLS.USER_ID],
      companyId: values[FORM_CONTROLS.COMPANY],
    };
    dispatch(setTempProjectValues(tempValuesToSet));
  };

  return (
    <>
      <MapWrapper isClickable={false} pins={pin ? [pin] : []}>
        <div className="ScaniflyInfo">
          <form onSubmit={handleSubmit}>
            <div className="ScaniflyInfo-SubmittedFor">
              {project?.submittedFor &&
                `Submitted for: ${project.submittedFor?.firstName} ${project.submittedFor?.lastName} at ${project.submittedFor?.company.name}`}
            </div>
            <h2 className="ProjectDesigns-ProjectName">
              {project ? project.name : tempProjectData?.projectName}
            </h2>
            <span className="ScaniflyInfo-Label">Submitted For</span>
            <div className="ScaniflyInfo-SelectWrapper">
              <CustomSelect
                {...getFieldProps(FORM_CONTROLS.COMPANY)}
                onChange={(option) => {
                  if (option.value !== values[FORM_CONTROLS.COMPANY]) {
                    dispatch(companyUsersRequested(option.value));
                    setFieldValue(FORM_CONTROLS.COMPANY, option.value);
                    setFieldValue(FORM_CONTROLS.USER_ID, '');
                    setResetSelect(true);
                  }
                }}
                className={validateStatus(touched, errors, FORM_CONTROLS.COMPANY)}
                placeholder="Select Company"
                options={companyNameAndIdList.map((company) => ({
                  value: company.id,
                  label: company.name,
                }))}
                variant="filter"
                aria-label="select company to upload for"
                tall
              />
              <CustomSelect
                {...getFieldProps(FORM_CONTROLS.USER_ID)}
                onChange={(option) => {
                  setResetSelect(false);
                  setFieldValue(FORM_CONTROLS.USER_ID, option.value);
                  setFieldValue(FORM_CONTROLS.ENGINE, getFieldProps(FORM_CONTROLS.ENGINE).value);
                }}
                className={validateStatus(touched, errors, FORM_CONTROLS.COMPANY)}
                reset={resetSelect}
                placeholder="Select User"
                options={selectedCompanyUsers.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName}`,
                }))}
                disabled={!values[FORM_CONTROLS.COMPANY] && !selectedCompanyUsers.length}
                aria-disabled={!values[FORM_CONTROLS.COMPANY] && !selectedCompanyUsers.length}
                variant="filter"
                aria-label="select user to upload for"
                tall
              />
            </div>
            <span className="ScaniflyInfo-Label">Engine</span>
            <div className="ScaniflyInfo-ProjectTypes">
              <Group
                onChange={(e) => setFieldValue(FORM_CONTROLS.ENGINE, e.target.value)}
                buttonStyle="solid"
                {...getFieldProps(FORM_CONTROLS.ENGINE)}
              >
                {ENGINE_OPTIONS.map(({ label, value }) => (
                  <RadioButton key={value} value={value} className="ScaniflyInfo-Button">
                    {label}
                  </RadioButton>
                ))}
              </Group>
            </div>

            <div className="ScaniflyInfo-Buttons">
              <div
                className={cn('ScaniflyInfo-Buttons-Wrapper', {
                  'ScaniflyInfo-Buttons-Wrapper-NewProject': isNewProject,
                })}
              >
                {isNewProject && <GoBackButton onClick={handleBack} />}
                {isDraft || isNewProject ? (
                  <div>
                    <Button
                      onClick={handleSaveAndExit}
                      className="Button--White"
                      disabled={project ? !isSavedFormValid : !isFormValid}
                      aria-disabled={project ? !isSavedFormValid : !isFormValid}
                      loading={isExiting && isLoading}
                    >
                      Save &amp; Exit
                    </Button>
                    <Button
                      htmlType="submit"
                      className="Button--Blue"
                      disabled={project ? !isSavedFormValid : !isFormValid}
                      aria-disabled={project ? !isSavedFormValid : !isFormValid}
                      loading={!isExiting && isLoading}
                    >
                      Save &amp; Continue
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      className="Button--Blue CustomerInfo-Form-Buttons-Submit"
                      disabled={project ? !isSavedFormValid : !isFormValid}
                      aria-disabled={project ? !isSavedFormValid : !isFormValid}
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </MapWrapper>
    </>
  );
};

export default ScaniflyInfo;
