import { useCallback, useEffect, useState } from 'react';
import { _MapContext as MapContext, Marker, Popup, StaticMap } from 'react-map-gl';

import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import cn from 'classnames';
// @ts-ignore
import DeckGL from 'deck.gl';
import { debounce } from 'lodash-es';
import 'mapbox-gl/dist/mapbox-gl.css';
import { EditableGeoJsonLayer, ExtrudeMode, TransformMode, ViewMode } from 'nebula.gl';
import { MAP_DEFAULTS, MAP_STYLES, MAP_THEMES, MAP_ZOOM } from 'screens/MapWrapper/constants';
import { BoundingBoxFeature } from 'types/BoundingBox';

import { ImagePreview, MapStyleControl } from 'components';

import { isGeneralBucket } from 'helpers/utils/isGeneralBucket';

import { ReactComponent as ExpandIcon } from 'assets/icons/expand-icon.svg';
import { ReactComponent as PinIcon } from 'assets/icons/map-pin.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move-icon.svg';
import { ReactComponent as RotateIcon } from 'assets/icons/rotate-icon.svg';
import placeholderImageUrl from 'assets/image.svg';

import { Link } from 'react-router-dom';
import { Metadata } from '../types';
import {
  DRONE_IMAGE_MARKERS,
  EDIT_BOUNDING_BOX_DEBOUNCE_MS,
  SCANIFLY_BLUE,
  SCANIFLY_GRAY,
  SCANIFLY_GREEN,
  SCANIFLY_LIGHT_BLUE,
  SCANIFLY_YELLOW,
} from './constants';
import { getBoundingBoxPointRadius } from './helpers';
import './ProjectCategoryMap.scss';
import { ProjectCategoryMapProps } from './types';

export const ProjectCategoryMap = ({
  metadata,
  allMedia,
  pin,
  popup,
  selectedMarkers,
  setPopup,
  disableBoundingBox,
  selectedImagePreview,
  setSelectedImagePreview,
  boundaries,
  droneImagesMapViewport,
  features,
  setFeatures,
  updateOrCreateBoundaries,
  projectType,
  imagePreviews,
}: ProjectCategoryMapProps) => {
  const [selectedImage, setSelectedImage] = useState<Metadata | null>(null);
  const [selectedMapStyle, setSelectedMapStyle] = useState(MAP_STYLES.satellite);
  const [selectedFeatureIndexes] = useState([0]);
  const [mode, setMode] = useState(() => (disableBoundingBox ? ExtrudeMode : TransformMode));

  const mapViewport =
    pin && !metadata.length && !boundaries
      ? {
          ...MAP_DEFAULTS,
          latitude: pin.latitude,
          longitude: pin.longitude,
          zoom: MAP_ZOOM,
        }
      : droneImagesMapViewport;

  const isViewportLoaded = !!mapViewport.latitude && !!mapViewport.longitude;

  // sets popup over map
  useEffect(() => {
    if (!metadata.length) {
      setPopup(null);
    }
    setSelectedImage(popup);
  }, [popup, setPopup, metadata]);

  // creates drone image markers
  const getDroneImagesFeatures = () => {
    const droneImagesFeatures = metadata.map((location) => ({
      type: 'Feature',
      properties: {
        location,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.geolocation.longitude, location.geolocation.latitude],
        id: location.id,
        properties: {
          selected: selectedMarkers.includes(location.id) || selectedImage?.id === location.id,
        },
      },
    }));
    return metadata
      ? {
          type: 'FeatureCollection',
          features: droneImagesFeatures,
        }
      : [];
  };

  // uses drone image markers to create layer over map
  // @ts-ignore
  const droneImageMarkers = new EditableGeoJsonLayer({
    id: DRONE_IMAGE_MARKERS,
    data: getDroneImagesFeatures(),
    mode: ViewMode,
    filled: true,
    pointRadiusScale: 0.4,
    getPointRadius: 1,
    // @ts-ignore
    getFillColor: (d: any) => (d.geometry.properties.selected ? SCANIFLY_BLUE : SCANIFLY_YELLOW),
    // @ts-ignore
    getLineColor: (d: any) => (d.geometry.properties.selected ? SCANIFLY_BLUE : SCANIFLY_YELLOW),
  });

  // popup handler
  const handleDroneImagePopup = (feature: any) => {
    setPopup(metadata.find((media) => media.id === feature?.object?.geometry.id) ?? null);
  };

  const editBoundingBox = ({ data }: any) => {
    if (data?.[0] && updateOrCreateBoundaries) {
      updateOrCreateBoundaries(data[0], true);
    }
  };

  const handleUpdate = (event: any) => {
    debouncedUpdateHandler(event);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateHandler = useCallback(
    debounce(editBoundingBox, EDIT_BOUNDING_BOX_DEBOUNCE_MS),
    [boundaries]
  );

  // creates bounding box layer of map
  // @ts-ignore
  const boundingBox = new EditableGeoJsonLayer({
    data: features,
    mode,
    selectedFeatureIndexes: features ? selectedFeatureIndexes : [],
    filled: true,
    pickable: !disableBoundingBox,
    _subLayerProps: {
      guides: {
        pointType: 'circle',
        getPointRadius: getBoundingBoxPointRadius(projectType ?? PROJECT_TYPES.SMALL),
        pointRadiusScale: 0.5,
        _subLayerProps: {
          'points-circle': {
            getFillColor: (guide: any) =>
              guide.properties.editHandleType === 'intermediate'
                ? SCANIFLY_GREEN
                : SCANIFLY_LIGHT_BLUE,
          },
        },
        getLineWidth: 5,
        getLineColor: (guide: any) =>
          guide.properties.editHandleType === 'intermediate' ? SCANIFLY_GREEN : SCANIFLY_LIGHT_BLUE,
      },
      geojson: {
        getFillColor: () => SCANIFLY_GRAY,
        getLineWidth: 5,
      },
    },
    onEdit: ({
      editType,
      updatedData,
    }: {
      editType: 'addPosition' | 'removePosition' | string;
      updatedData: {
        type: string;
        features: [BoundingBoxFeature];
      };
    }) => {
      if (editType === 'addPosition' || editType === 'removePosition') {
        return;
      }
      handleUpdate({ data: updatedData?.features });
      setFeatures(updatedData);
    },
  });

  return isViewportLoaded ? (
    <>
      {selectedImagePreview && (
        <ImagePreview
          allMedia={allMedia}
          image={selectedImagePreview}
          setSelectedMediaItem={setSelectedImagePreview}
          isDroneImages={!!boundaries}
        />
      )}
      <div
        aria-hidden={!!selectedImagePreview}
        className={cn('ProjectCategoryMap', {
          'ProjectCategoryMap--Hidden': !!selectedImagePreview,
        })}
      >
        <DeckGL
          initialViewState={mapViewport}
          controller={{
            doubleClickZoom: false,
          }}
          // @ts-ignore
          layers={[droneImageMarkers, boundaries && boundingBox]}
          onClick={handleDroneImagePopup}
          ContextProvider={MapContext.Provider}
        >
          {selectedImage && (
            <Popup
              latitude={selectedImage.geolocation?.latitude ?? 0}
              longitude={selectedImage.geolocation?.longitude ?? 0}
              offsetTop={-15}
              tipSize={0}
              closeButton={false}
              className="ProjectCategoryMap-Popup"
            >
              <Link to={selectedImage.data?.link ?? '#'}>
                <img
                  crossOrigin={isGeneralBucket(selectedImage?.thumbnailUrl) ? '' : 'anonymous'}
                  className="ProjectCategoryMap-Popup-Image"
                  src={
                    selectedImage.thumbnailUrl ||
                    imagePreviews[selectedImage.id] ||
                    placeholderImageUrl
                  }
                  alt="preview"
                />
              </Link>
              <div className="ProjectCategoryMap-Popup-Name">{selectedImage.fileName}</div>
            </Popup>
          )}
          <StaticMap
            {...mapViewport}
            width="100%"
            height="100%"
            key="map"
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            // @ts-ignore
            mapStyle={MAP_THEMES[selectedMapStyle]}
            aria-label="map"
          >
            {pin && !metadata.length && (
              <Marker longitude={pin.longitude} latitude={pin.latitude}>
                <PinIcon
                  className={cn('MapPin', {
                    'MapPin--SatelliteView': selectedMapStyle === MAP_STYLES.satellite,
                  })}
                />
              </Marker>
            )}
          </StaticMap>
          <MapStyleControl
            mapStyle={selectedMapStyle}
            setMapStyle={setSelectedMapStyle}
            key="style"
          />
          {!disableBoundingBox && projectType && boundaries && (
            <div className="ProjectCategoryMap-ButtonWrapper">
              <Button
                className={cn('Button--Filter ProjectCategoryMap-Button', {
                  'Button--Active': mode === TransformMode,
                })}
                onClick={() => setMode(() => TransformMode)}
              >
                Rotate &amp; Move Bounding Box
                <RotateIcon />
                <MoveIcon />
              </Button>
              <Button
                className={cn('Button--Filter ProjectCategoryMap-Button', {
                  'Button--Active': mode === ExtrudeMode,
                })}
                onClick={() => setMode(() => ExtrudeMode)}
              >
                Resize Bounding Box
                <ExpandIcon />
              </Button>
            </div>
          )}
        </DeckGL>
      </div>
    </>
  ) : null;
};

export default ProjectCategoryMap;
