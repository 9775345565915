import {
  adminUserPermissionsUpdated,
  adminUserProjectsAccessUpdated,
} from 'state/slices/admin/adminUsersSlice';
import {
  userAdminStatusChanged,
  userPermissionsUpdated,
  userProjectsAccessUpdated,
} from 'state/slices/usersSlice';

import { openNotification } from '../../../helpers/utils/openNotification';

export const updatePermissions = ({
  dispatch,
  teammate,
  companyId,
  selectedPermissionsWithoutAdminTools,
  projectAccess,
  isScaniflyAdmin,
  isAdminPromotion,
  isAdminDemotion,
}) => {
  const updatePermissionsPayload = {
    isAdminPromotion,
    id: teammate.id,
    companyId,
    permissions: selectedPermissionsWithoutAdminTools,
    projectAccess,
    isScaniflyAdmin,
  };

  if (isAdminPromotion || isAdminDemotion) {
    dispatch(userAdminStatusChanged(updatePermissionsPayload));
  } else {
    if (isScaniflyAdmin) {
      dispatch(adminUserPermissionsUpdated(updatePermissionsPayload));
      dispatch(
        adminUserProjectsAccessUpdated({
          userId: teammate.id,
          projectAccess,
        })
      );
    } else {
      dispatch(userPermissionsUpdated(updatePermissionsPayload));
      dispatch(
        userProjectsAccessUpdated({
          userId: teammate.id,
          projectAccess,
        })
      );
    }
  }

  openNotification({
    type: 'success',
    title: 'Teammate Updated!',
    text: `You have updated ${teammate.firstName + ' ' + teammate.lastName}'s permissions.`,
  });
};
