import { TIER_LEVEL } from '@cpm/scanifly-shared-data';

import { BUTTON, SITE_PLAN_FORMS } from '../OrderDesign/DesignSelection/FormWrapper/constants';

export const getButtonText = (buttonParams: {
  step?: number;
  formLength: number;
  tierLevel?: TIER_LEVEL;
  filesAlreadyStored?: null | boolean;
}) => {
  const { step, formLength, tierLevel, filesAlreadyStored = null } = buttonParams;
  const isPlanSet =
    tierLevel === TIER_LEVEL.planSet ||
    tierLevel === TIER_LEVEL.planSetForLarge ||
    tierLevel === TIER_LEVEL.planSetForPrelim;

  const isPlanSetCommentForm = isPlanSet && step === formLength;

  if (isPlanSetCommentForm && filesAlreadyStored) {
    return BUTTON.SUBMIT_REQUEST;
  }

  if (step === formLength) {
    if (tierLevel === TIER_LEVEL.sitePlan) {
      return BUTTON.SUBMIT_ORDER;
    }
    if (
      isPlanSet ||
      tierLevel === TIER_LEVEL.siteModeling ||
      tierLevel === TIER_LEVEL.maxFill ||
      tierLevel === TIER_LEVEL.trueUp
    ) {
      return BUTTON.SUBMIT_REQUEST;
    }
  }
  if (formLength > SITE_PLAN_FORMS.length) {
    return BUTTON.CONTINUE;
  }
  return BUTTON.NEXT;
};
