import useOnclickOutside from 'react-cool-onclickoutside';

import PropTypes from 'prop-types';

import './DropdownMenu.scss';

const DropdownMenu = ({ toggleMenu, children }) => {
  const menuRef = useOnclickOutside(toggleMenu);

  return (
    <div ref={menuRef} className="DropdownMenu-Wrapper">
      <ul className="DropdownMenu">{children}</ul>
    </div>
  );
};

DropdownMenu.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

export default DropdownMenu;
