import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  DESIGN_STATUS,
  PROJECT_STATUS,
  PROJECT_TYPES,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { Revision } from 'types';
import ServiceRequest from 'types/ServiceRequest';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ConditionalWrapper, Scanifly3DButton } from 'components';

import { NON_ADMIN_PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  orderDesignRoute,
  projectCategoriesRoute,
  projectDesignsRoute,
  upgradeDesignRoute,
} from 'helpers/constants/routes';

import { ReactComponent as DeliverablesIcon } from 'assets/deliverables-icon.svg';
import { ReactComponent as DesignReviewIcon } from 'assets/design-review-icon.svg';
import { ReactComponent as CompletedIcon } from 'assets/icons/completed-icon.svg';
import { ReactComponent as ModelIcon } from 'assets/models-icon.svg';

import OrderList from './OrderList';

type RevisionButtonParams = {
  isEligibleForRevision: boolean;
  handleModalOpen: (() => void) | undefined;
  t: TFunction<'translation', undefined>;
};

type DesignCompletedParams = {
  projectId: string;
};

const RevisionButton = ({ isEligibleForRevision, handleModalOpen, t }: RevisionButtonParams) => (
  <ConditionalWrapper
    condition={!isEligibleForRevision}
    wrapper={(children) => (
      <Tooltip placement="top" title={t('DesignService.designCompleted.modelRevisionError')}>
        {children}
      </Tooltip>
    )}
  >
    <Button
      className="Button--White DesignStatus-Button"
      onClick={handleModalOpen}
      disabled={!isEligibleForRevision}
      aria-disabled={!isEligibleForRevision}
      data-testid="revise-model-button"
    >
      {t('DesignService.designCompleted.buttonText.reviseModel')}
    </Button>
  </ConditionalWrapper>
);

const DesignCompleted = ({
  hasSitePlanOrPlanSet = 0,
  handleModalOpen,
  serviceRequestToBeRevised,
  handleOpenRevisionDataModal,
}: {
  hasSitePlanOrPlanSet?: number;
  handleModalOpen?: () => void;
  serviceRequestToBeRevised?: ServiceRequest;
  handleOpenRevisionDataModal?: (revision: Revision) => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { projectId } = useParams<DesignCompletedParams>();
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const { project } = useSelector((state: RootState) => state.project);

  const isEligibleForRevision = useMemo(
    () =>
      !serviceRequestToBeRevised?.modelRevision ||
      (Array.isArray(serviceRequestToBeRevised?.modelRevision) &&
        serviceRequestToBeRevised?.modelRevision?.length < 5) ||
      Number(serviceRequestToBeRevised?.modelRevisionCount) < 5,
    [serviceRequestToBeRevised?.modelRevision, serviceRequestToBeRevised?.modelRevisionCount]
  );

  useEffect(() => {
    if (!project && projectId) {
      dispatch(projectRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPlanSetRequest = serviceRequests
    ? serviceRequests.filter(
        (r) => r.tierLevel === TIER_LEVEL.planSet || r.tierLevel === TIER_LEVEL.planSetForLarge
      ).length
    : 0;

  const hasUncancelledPlanSetRequest = serviceRequests
    ? serviceRequests.filter(
        (r) =>
          (r.tierLevel === TIER_LEVEL.planSet || r.tierLevel === TIER_LEVEL.planSetForLarge) &&
          r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const isRemote = project?.status === PROJECT_STATUS.remoteDesignStarted;

  const uncancelledServiceRequests = serviceRequests
    ? serviceRequests.filter(
        (r) =>
          r.status !== DESIGN_STATUS.canceled &&
          r.tierLevel !== TIER_LEVEL.maxFill &&
          r.tierLevel !== TIER_LEVEL.siteModeling
      ).length
    : 0;

  const renderButtons = () => {
    if (!hasUncancelledPlanSetRequest && uncancelledServiceRequests && !isRemote) {
      return (
        <Button
          className="Button--White DesignStatus-Button"
          onClick={() => history.push(upgradeDesignRoute(projectId))}
        >
          {t('DesignService.designCompleted.buttonText.upgrade')}
        </Button>
      );
    } else if (!hasUncancelledPlanSetRequest || isRemote) {
      return (
        <Button
          className="Button--Blue DesignStatus-Button"
          onClick={() => history.push(orderDesignRoute(projectId))}
        >
          {t('DesignService.designCompleted.buttonText.newOrder')}
        </Button>
      );
    }
  };

  return (
    <div className="DesignStatus-ButtonsAndOrderWrapper">
      {hasSitePlanOrPlanSet ? (
        <div className="DesignStatus-Wrapper">
          <DesignReviewIcon className="DesignStatus-ProcessingIcon" />
          <div className="DesignStatus-Title">{t('DesignService.designCompleted.context')}</div>
          <div className="DesignStatus-Timeframe">
            <div className="DesignStatus-SubWrapper">
              <DeliverablesIcon className="DesignStatus-Icon" />
              <Link to={projectCategoriesRoute(projectId)} className="DesignStatus-Link">
                {t('DesignService.designCompleted.buttonText.viewDeliverables')}
              </Link>
            </div>
            <div className="DesignStatus-SubWrapper">
              <ModelIcon className="DesignStatus-Icon" />
              <Link to={projectDesignsRoute(projectId)} className="DesignStatus-Link">
                {t('DesignService.designCompleted.buttonText.viewModel')}
              </Link>
            </div>
          </div>
          <div className={hasUncancelledPlanSetRequest ? '' : 'DesignStatus-ButtonWrapper'}>
            <RevisionButton
              isEligibleForRevision={isEligibleForRevision}
              handleModalOpen={handleModalOpen}
              t={t}
            />
            {renderButtons()}
          </div>
        </div>
      ) : (
        <div className="DesignStatus-CompletedWrapper">
          <CompletedIcon className="DesignStatus-CompletedIcon" />
          <div className="DesignStatus-Title">{t('DesignService.designComplete')}</div>
          <div
            className={
              hasPlanSetRequest || project?.type === PROJECT_TYPES.LARGE
                ? 'DesignStatus-Scanifly3DButtonWrapper'
                : 'DesignStatus-ButtonWrapper'
            }
          >
            <Scanifly3DButton
              projectId={projectId}
              status={NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE}
              buttonClass={
                uncancelledServiceRequests || project?.type === PROJECT_TYPES.LARGE
                  ? 'DesignStatus-Button Button--Blue'
                  : 'DesignStatus-Button Button--White'
              }
              isDesignServicesQueue={true}
            />
            <RevisionButton
              isEligibleForRevision={isEligibleForRevision}
              handleModalOpen={handleModalOpen}
              t={t}
            />
            {renderButtons()}
          </div>
        </div>
      )}
      <OrderList handleOpenRevisionDataModal={handleOpenRevisionDataModal} />
    </div>
  );
};

export default DesignCompleted;
