import {
  CheckMarkFieldType,
  DatePickerFieldType,
  DropdownMenuFieldType,
  FieldTypeEnum,
  FieldTypeUnion,
  MediaFieldType,
  NotApplicableFieldType,
  RadioFieldType,
  ReferenceMediaFieldType,
  TextInputFieldType,
  TimePickerFieldType,
} from '@cpm/scanifly-shared-data';

import {
  CheckMark,
  DatePicker,
  DropdownMenu,
  MediaField,
  NotApplicable,
  Radio,
  ReferenceMediaField,
  TextInput,
  TimePicker,
} from './Fields';

type Props = {
  item: FieldTypeUnion;
};

// need to add formik which might need a change to the template json adding names
// for initial values
export function FormField({ item }: Props) {
  switch (item.componentType) {
    case FieldTypeEnum.textInput:
      return <TextInput item={item as TextInputFieldType} />;
    case FieldTypeEnum.notApplicable:
      return <NotApplicable item={item as NotApplicableFieldType} />;
    case FieldTypeEnum.dropDown:
      return <DropdownMenu item={item as DropdownMenuFieldType} />;
    case FieldTypeEnum.radio:
      return <Radio item={item as RadioFieldType} />;
    case FieldTypeEnum.media:
      return <MediaField item={item as MediaFieldType} />;
    case FieldTypeEnum.referenceMedia:
      return <ReferenceMediaField item={item as ReferenceMediaFieldType} />;
    case FieldTypeEnum.checkMark:
      return <CheckMark item={item as CheckMarkFieldType} />;
    case FieldTypeEnum.datePicker:
      return <DatePicker item={item as DatePickerFieldType} />;
    case FieldTypeEnum.timePicker:
      return <TimePicker item={item as TimePickerFieldType} />;
    default:
      return null;
  }
}
