import { PROJECT_STATUS } from '@cpm/scanifly-shared-data';
import { ServiceRequest } from 'types';

import { draftProjectDesignsRoute, projectDesignsRoute } from 'helpers/constants/routes';

export const getCorrectRouteForDesigns = (item: ServiceRequest) =>
  [
    PROJECT_STATUS.noFlight,
    PROJECT_STATUS.locationSubmitted,
    PROJECT_STATUS.droneImagesUploading,
    PROJECT_STATUS.uploadProcessing,
  ].includes(item.project?.status as PROJECT_STATUS)
    ? draftProjectDesignsRoute(item.project.id)
    : projectDesignsRoute(item.project.id);
