import PropTypes from 'prop-types';

import { SUPPORT_URL } from 'helpers/constants/urls';

const ContactScaniflySupport = ({ erroredAction }) => {
  return (
    <div>
      {`Something has gone wrong and we couldn't ${erroredAction}. Please contact`}
      <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
        {' '}
        Scanifly support
      </a>
      .
    </div>
  );
};

ContactScaniflySupport.propTypes = {
  erroredAction: PropTypes.string.isRequired,
};

export default ContactScaniflySupport;
