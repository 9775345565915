import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Spin } from 'antd';
import { useFormik } from 'formik';
import { kebabCase } from 'lodash';
import {
  ELECTRICAL_INFORMATION_FORM,
  FIRE_SETBACKS,
  OBSTRUCTION_SETBACKS,
  STRUCTURAL_INFORMATION_FORM,
} from 'screens/DesignServices/OrderDesign/DesignSelection/FormWrapper/PlanSetForms/constants';
import { FireSetbackData, ObstructionSetbackData } from 'types/DesignServiceSetting';

import styled from 'styled-components';

import { companyRequested } from 'state/slices/companySlice';
import {
  settingsCreationRequested,
  settingsRequested,
  settingsUpdateRequested,
} from 'state/slices/designServices/designServicesSettingsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { Button, ErrorDisplay } from 'components';
import {
  FormInput,
  FormSetbackInput,
} from 'screens/DesignServices/OrderDesign/DesignSelection/FormWrapper/components';

import colors from 'helpers/constants/colors';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import { useLocation } from 'react-router-dom';
import { FORM_CONTROLS, initialValues } from './constants';
import { TrueUpSettings } from './TrueUpSettings';
import { validationSchema } from './validationSchema';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  padding: 1rem 2rem;
  border-radius: 1rem;
  &:hover {
    background-color: ${colors.darkBlue};
    color: ${colors.white};
    border: 1px solid ${colors.lightGray};
  }
  display: inline-block;
  margin-right: 1rem;
`;

export const Subtitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-bottom: 3rem;
  color: ${colors.labelGray};
`;

const Title = styled.h3`
  line-height: 1.6rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -10rem;
  margin-right: 1rem;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

type DesignServicesSettingsType = {
  folderId: string;
  fireSetbacks: FireSetbackData;
  obstructionSetbacks: ObstructionSetbackData;
  sizedForCategory?: string;
  negativeTolerance?: number;
  positiveTolerance?: number;
};

const defaultFireSetbackData: FireSetbackData = {
  eave: 0,
  hip: 0,
  rake: 0,
  valley: 0,
  ridge: 0,
};

const defaultObstructionSetbackData: ObstructionSetbackData = {
  vents: 0,
  exhaust: 0,
  skylights: 0,
  parapet: 0,
  general: 0,
};

const DesignServicesSettings = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { hash } = useLocation();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { company } = useSelector((state: RootState) => state.company);
  const { settingsData, isLoading, error } = useSelector(
    (state: RootState) => state.designServicesSettings
  );
  const [isInformationSaved, setIsInformationSaved] = useState<boolean>(false);
  const designServicesSettingsRef = useRef<HTMLDivElement | null>(null);

  const scrollToDesignServicesSettings = hash === '#design-services-settings';

  useEffect(() => {
    if (scrollToDesignServicesSettings) {
      designServicesSettingsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToDesignServicesSettings]);

  useEffect(() => {
    if (!company && currentUser?.companyId) {
      dispatch(companyRequested(currentUser.companyId));
    }
  }, [company, currentUser?.companyId, dispatch]);

  useEffect(() => {
    if (currentUser?.companyId && settingsData === null) {
      dispatch(settingsRequested({ companyId: currentUser.companyId }));
    }
  }, [currentUser?.companyId, dispatch, settingsData]);

  const handleSuccess = () => {
    setIsInformationSaved(true);
  };

  const defaultData = useMemo(() => {
    return settingsData?.find((item) => item.folderId === 'default');
  }, [settingsData]);

  const formik = useFormik({
    initialValues: { ...initialValues, ...defaultData },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data: DesignServicesSettingsType = {
        ...values,
        folderId: 'default',
        obstructionSetbacks: { ...defaultObstructionSetbackData },
        fireSetbacks: { ...defaultFireSetbackData },
        negativeTolerance: Number(values.negativeTolerance),
        positiveTolerance: Number(values.positiveTolerance),
      };
      Object.entries(values.obstructionSetbacks).forEach(([key, value]) => {
        if (value && value !== 'undefined') {
          data.obstructionSetbacks[key as keyof ObstructionSetbackData] = Number(value);
        }
      });
      Object.entries(values.fireSetbacks).forEach(([key, value]) => {
        if (value && value !== 'undefined') {
          data.fireSetbacks[key as keyof FireSetbackData] = Number(value);
        }
      });

      if (currentUser?.companyId) {
        if (settingsData?.length === 0 && !isLoading) {
          dispatch(
            settingsCreationRequested({ companyId: currentUser?.companyId, ...data, handleSuccess })
          );
        } else {
          dispatch(
            settingsUpdateRequested({ companyId: currentUser?.companyId, ...data, handleSuccess })
          );
        }
      }
    },
  });

  const { setFieldValue, setFieldTouched, getFieldProps, handleSubmit, touched, errors } = formik;

  return (
    <>
      <Title id="design-services-settings" ref={designServicesSettingsRef}>
        {t('CompanySettings.designServicesSettings')}
      </Title>
      <Subtitle>{t('CompanySettings.designServicesSettingsDescription')}</Subtitle>
      <Wrapper>
        {/* True-Up settings */}
        <TrueUpSettings
          getFieldProps={getFieldProps}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          t={t}
        />

        {/* Fire SetBacks */}
        <FormSetbackInput
          setbackName={FORM_CONTROLS.FIRE_SETBACKS}
          setbacks={FIRE_SETBACKS.setbacks}
          title={FIRE_SETBACKS.title}
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        {/* Obstruction SetBacks */}
        <FormSetbackInput
          setbacks={OBSTRUCTION_SETBACKS.setbacks}
          setbackName={FORM_CONTROLS.OBSTRUCTION_SETBACKS}
          title={OBSTRUCTION_SETBACKS.title}
          getFieldProps={getFieldProps}
          touched={touched}
          errors={errors}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        {/* Attachment manufacturer and model */}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.ATTACHMENT.title}
          fieldProps={getFieldProps(FORM_CONTROLS.ATTACHMENT)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.ATTACHMENT.placeholder}
          ariaLabel={FORM_CONTROLS.ATTACHMENT}
          id={FORM_CONTROLS.ATTACHMENT}
          className={validateStatus(touched, errors, FORM_CONTROLS.ATTACHMENT)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.ATTACHMENT)}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        {/* Racking  manufacturer and model*/}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.RACKING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.RACKING)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.RACKING.placeholder}
          ariaLabel={FORM_CONTROLS.RACKING}
          id={FORM_CONTROLS.RACKING}
          className={validateStatus(touched, errors, FORM_CONTROLS.RACKING)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.RACKING)}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        {/* Module info */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.MODULE_INFO.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MODULE_INFO)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.MODULE_INFO.placeholder}
          id={FORM_CONTROLS.MODULE_INFO}
          className={validateStatus(touched, errors, FORM_CONTROLS.MODULE_INFO)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.MODULE_INFO)}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        {/* inverter info */}
        <FormInput
          title={ELECTRICAL_INFORMATION_FORM.INVERTER_INFO.title}
          fieldProps={getFieldProps(FORM_CONTROLS.INVERTER_INFO)}
          placeHolder={ELECTRICAL_INFORMATION_FORM.INVERTER_INFO.placeholder}
          id={FORM_CONTROLS.INVERTER_INFO}
          className={validateStatus(touched, errors, FORM_CONTROLS.INVERTER_INFO)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.INVERTER_INFO)}
          setFieldValue={setFieldValue}
          isSettingsPage={true}
        />
        <ButtonWrapper>
          {isInformationSaved && !isLoading && !error ? (
            <IconWrapper>
              <TickIcon />
            </IconWrapper>
          ) : null}
          {isLoading ? (
            <SpinnerWrapper>
              <Spin size="default" />
            </SpinnerWrapper>
          ) : null}
          <StyledButton
            id={`Settings-Menu-Button-${kebabCase(t('CompanySettings.designServicesSettings'))}`}
            onClick={() => handleSubmit()}
            color={colors.mainBlue}
          >
            {t('buttonTexts.save')}
          </StyledButton>
        </ButtonWrapper>
        {error ? <ErrorDisplay error={error} /> : null}
      </Wrapper>
    </>
  );
};

export default DesignServicesSettings;
