import { useTranslation } from 'react-i18next';

import { UploadProgressBarProps } from './types';

const UploadProgressBar = ({ uploadProgressPercent }: UploadProgressBarProps) => {
  const { t } = useTranslation();

  return (
    <div className={'uppy-Container'}>
      <div className="uppy-Root" dir="ltr">
        <div className="uppy-StatusBar is-uploading" aria-hidden="false">
          <div
            className="uppy-StatusBar-progress"
            role="progressbar"
            aria-label={`${uploadProgressPercent}%`}
            aria-valuetext={`${uploadProgressPercent}%`}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={uploadProgressPercent}
            style={{ width: `${uploadProgressPercent}%` }}
          />
          <div className="uppy-StatusBar-content" aria-label="Uploading" title="Uploading">
            <div className="uppy-StatusBar-status">
              <div className="uppy-StatusBar-statusPrimary">
                {t('ProjectCategory.uploading', { uploadProgressPercent })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadProgressBar;
