import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

export const siteModelingValidationSchema = Yup.object().shape({
  [FORM_CONTROLS.HOMEOWNER_NAME]: Yup.string()
    .required('*This field is required')
    .test(...noWhitespaces),
});
