import type { ChecklistTemplateType, ChecklistType } from '@cpm/scanifly-shared-data';
import { ChecklistTemplateTypeEnum } from '@cpm/scanifly-shared-data';
import { createSelector } from 'reselect';

import { RootState } from 'state/store/store';

const selectChecklistTemplates = (state: RootState): ChecklistTemplateType[] =>
  state.checklists.templatesOrder.map((id) => state.checklists.templates[id]);

const selectCustomChecklistTemplates = createSelector(
  [
    (state: RootState) => state.checklists.templatesOrder,
    (state: RootState) => state.checklists.templates,
  ],
  (order, templates) => {
    const templateList = order.map((id) => templates[id]);
    return templateList.filter((checklist) => checklist.type === ChecklistTemplateTypeEnum.custom);
  }
);

const selectDefaultChecklistTemplates = createSelector(
  [
    (state: RootState) => state.checklists.templatesOrder,
    (state: RootState) => state.checklists.templates,
  ],
  (order, templates) => {
    const templateList = order.map((id) => templates[id]);
    return templateList.filter(
      (checklist) =>
        checklist.type === ChecklistTemplateTypeEnum.scaniflyDefault ||
        checklist.type === ChecklistTemplateTypeEnum.default
    );
  }
);

const selectChecklistTemplateById = (
  state: RootState,
  templateId: string
): ChecklistTemplateType | undefined => state.checklists.templates[templateId];

const selectChecklistsForProject = (state: RootState, projectId: string): ChecklistType[] =>
  state.checklists.checklistsOrder[projectId]?.map((id) => state.checklists.checklists[id]) ?? [];

const selectChecklistById = (state: RootState, checklistId: string): ChecklistType =>
  state.checklists.checklists[checklistId];

export {
  selectChecklistById,
  selectChecklistsForProject,
  selectChecklistTemplateById,
  selectChecklistTemplates,
  selectCustomChecklistTemplates,
  selectDefaultChecklistTemplates,
};
