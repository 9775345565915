import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import cn from 'classnames';

import { toggleFeature } from 'state/slices/featureToggleOverridesSlice';

import useFlagsWithOverride from 'helpers/hooks/useFlagsWithOverride';
import usePermissions from 'helpers/hooks/usePermissions';
import { useViewportSize } from 'helpers/hooks/useViewportSize';

import './FeatureFlags.scss';
import FeatureFlags from './FeatureFlags.ui';

const FeatureFlagsContainer = () => {
  const dispatch = useDispatch();
  const { isScaniflyAdmin } = usePermissions();
  const flags = useFlagsWithOverride();
  const { height } = useViewportSize();

  const handleToggleChange = useCallback(
    (featureName, enabled) => {
      dispatch(toggleFeature({ featureName, enabled }));
    },
    [dispatch]
  );

  if (!isScaniflyAdmin) {
    return null;
  }

  return (
    <>
      <hr />
      <section className={cn({ 'FeatureFlags-Wrapper': height < 750 })}>
        <FeatureFlags flags={flags} onChange={handleToggleChange} />
      </section>
    </>
  );
};

export default FeatureFlagsContainer;
