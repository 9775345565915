import { MediaItem, SurveyMedia } from 'types';

export enum RequestStatus {
  inProgress = 'inProgress',
  complete = 'complete',
  failed = 'failed',
}

export type CategoryMap = {
  [key: string]: {
    // Category
    lastRequested?: number;
    requestStatus?: RequestStatus;
    errorMessage?: string;
    mediaOrder: string[]; // id
  };
};

export type MediaMap = {
  [key: string]: {
    // Project
    media: { [key: string]: MediaItem | SurveyMedia };
    categoryMap: CategoryMap;
  };
};

export type MediaState = {
  mediaMap: MediaMap;
  clientRefIdMap: {
    [key: string]: {
      projectId: string;
      mediaId: string;
    };
  };
};

export type CategoryRequestInProgressPayload = {
  payload: {
    projectId: string;
    categoryId: string;
  };
};

export type CategoryRequestSuccessfulPayload = {
  payload: {
    projectId: string;
    categoryId: string;
    media: MediaItem[] | SurveyMedia[];
  };
};

export type CategoryRequestFailedPayload = {
  payload: {
    projectId: string;
    categoryId: string;
    errorMessage: string;
  };
};
