import { DRONE_DATA_SCORE, DRONE_DATA_SCORE as SCORES } from '@cpm/scanifly-shared-data';

export const droneDataScoreFields: [DRONE_DATA_SCORE, string][] = [
  [SCORES.droneDataScore, 'Drone Data Score'],
  [SCORES.cameraTechnique, 'Camera Technique'],
  [SCORES.flightPath, 'Flight Path'],
  [SCORES.imageQuality, 'Image Quality'],
  [SCORES.cameraTiltScore, 'Camera Tilt Score'],
  [SCORES.imageFiltersScore, 'Image Filters Score'],
  [SCORES.surveyEfficiencyScore, 'Survey Efficiency Score'],
  [SCORES.imageCountScore, 'Image Count Score'],
  [SCORES.idealFlightDeviationScore, 'Ideal Flight Deviation Score'],
  [SCORES.interImageDistanceScore, 'Inter Image Distance Score'],
  [SCORES.detailFlightScore, 'Detail Flight Score'],
  [SCORES.contextFlightScore, 'Context Flight Score'],
];
