import { Dispatch } from 'redux';

import {
  deleteReferenceMedia as deleteReferenceMediaApi,
  fetchReferenceMediaByCategory,
} from 'api/referenceMedia';

import { DELETE_REFERENCE_MEDIA, GET_REFERENCE_MEDIA_BY_CATEGORY } from './constants';
import { actions } from './referenceMediaSlice';

export const getReferenceMediaByCategory = (categoryId: string) => async (dispatch: Dispatch) => {
  const response = await fetchReferenceMediaByCategory(categoryId);
  if (response.data) {
    dispatch(actions[GET_REFERENCE_MEDIA_BY_CATEGORY](response.data));
  }
  return response.data;
};
/*
export const createMediaCategory =
  (payload: Pick<MediaCategory, 'categoryName'>) => async (dispatch: Dispatch) => {
    const response = await createMediaCategoryApi(payload);
    if (response.data) {
      dispatch(actions[CREATE_MEDIA_CATEGORY](response.data));
    }
    return response.data;
  };*/

export const deleteReferenceMedia =
  ({ mediaId, categoryId }: { mediaId: string; categoryId: string }) =>
  async (dispatch: Dispatch) => {
    const response = await deleteReferenceMediaApi(mediaId);
    if (response.status === 200) {
      dispatch(actions[DELETE_REFERENCE_MEDIA]({ mediaId, categoryId } as any));
    }
    return response.data;
  };
