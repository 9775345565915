import { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as DownCaret } from 'assets/down.svg';
import { ReactComponent as UpCaret } from 'assets/up.svg';

const DisplayContainer = styled.div``;

const ErrorAndToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 1rem 0;
`;

const ResponseArea = styled.textarea`
  width: 100%;
  max-height: 400px; // Updated max-height
  overflow: auto; // Enable scrolling
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  display: flex; // Added Flexbox
  justify-content: center; // Centered horizontally
  align-items: center; // Centered vertically
`;

type ErrorDisplayProps = {
  error: any | unknown;
};

const ErrorDisplay = ({ error }: ErrorDisplayProps) => {
  const [isResponseVisible, setResponseVisible] = useState(false);

  const handleToggleClick = () => {
    setResponseVisible(!isResponseVisible);
  };

  const formattedJSON = JSON.stringify(error?.response?.data, null, 2) ?? '';
  const rows = formattedJSON.split('\n').length;

  return (
    <DisplayContainer>
      {error.message && (
        <ErrorAndToggleContainer>
          <ErrorMessage>{error.message}</ErrorMessage>
          {error?.response?.data && (
            <ToggleButton onClick={handleToggleClick}>
              {isResponseVisible ? <UpCaret /> : <DownCaret />}
            </ToggleButton>
          )}
        </ErrorAndToggleContainer>
      )}
      {isResponseVisible && error?.response?.data && (
        <ResponseArea rows={rows} disabled value={JSON.stringify(error.response.data, null, 2)} />
      )}
    </DisplayContainer>
  );
};

export default ErrorDisplay;
