import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  DesignServiceSetting,
  FireSetbackData,
  ObstructionSetbackData,
} from 'types/DesignServiceSetting';

import {
  createDesignServiceSettings,
  fetchDesignServiceSettings,
  updateDesignServiceSettings,
} from 'api/designServices/designServicesService';

const name = 'designServicesSettings';

type StateType = {
  settingsData: DesignServiceSetting[] | [] | null;
  isLoading: boolean;
  error?: any;
};

type DesignServiceSettingsInputType = {
  companyId: string;
  folderId: string;
  obstructionSetbacks: ObstructionSetbackData;
  fireSetbacks: FireSetbackData;
  attachment?: string;
  racking?: string;
  moduleInfo?: string;
  inverterInfo?: string;
  handleSuccess: () => void;
  sizedForCategory?: string;
  negativeTolerance?: number;
  positiveTolerance?: number;
};

export const settingsRequested = createAsyncThunk(
  `${name}/settingsRequested`,
  async ({ companyId }: { companyId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchDesignServiceSettings(companyId);
      return { data: response.data };
    } catch (error: any) {
      return rejectWithValue({ data: error?.response?.data });
    }
  }
);

export const settingsCreationRequested = createAsyncThunk(
  `${name}/settingsCreationRequested`,
  async (
    {
      companyId,
      folderId,
      obstructionSetbacks,
      fireSetbacks,
      attachment,
      racking,
      moduleInfo,
      inverterInfo,
      handleSuccess,
      sizedForCategory,
      negativeTolerance,
      positiveTolerance,
    }: DesignServiceSettingsInputType,
    { rejectWithValue }
  ) => {
    try {
      const response = await createDesignServiceSettings({
        companyId,
        folderId,
        obstructionSetbacks,
        fireSetbacks,
        attachment,
        racking,
        moduleInfo,
        inverterInfo,
        sizedForCategory,
        negativeTolerance,
        positiveTolerance,
      });
      handleSuccess();
      return { data: response.data };
    } catch (error: any) {
      return rejectWithValue({ data: error?.response?.data });
    }
  }
);

export const settingsUpdateRequested = createAsyncThunk(
  `${name}/settingsUpdateRequested`,
  async (
    {
      companyId,
      folderId,
      obstructionSetbacks,
      fireSetbacks,
      attachment,
      racking,
      moduleInfo,
      inverterInfo,
      handleSuccess,
      sizedForCategory,
      negativeTolerance,
      positiveTolerance,
    }: DesignServiceSettingsInputType,
    { rejectWithValue }
  ) => {
    try {
      const response = await updateDesignServiceSettings({
        companyId,
        folderId,
        obstructionSetbacks,
        fireSetbacks,
        attachment,
        racking,
        moduleInfo,
        inverterInfo,
        sizedForCategory,
        negativeTolerance,
        positiveTolerance,
      });
      handleSuccess();
      return { data: response.data };
    } catch (error: any) {
      return rejectWithValue({ data: error?.response?.data });
    }
  }
);

const designServicesSettingsSlice = createSlice({
  name,
  initialState: {
    settingsData: null,
    isLoading: false,
    error: null,
  } as StateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(settingsRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settingsRequested.fulfilled, (state, { payload }) => {
        state.settingsData = payload.data;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(settingsRequested.rejected, (state, { payload }: { payload: any }) => {
        state.error = payload?.data;
        state.isLoading = false;
      })
      .addCase(settingsCreationRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        settingsCreationRequested.fulfilled,
        (state, { payload }: { payload: { data: DesignServiceSetting } }) => {
          state.settingsData = [payload.data];
          state.error = null;
          state.isLoading = false;
        }
      )
      .addCase(settingsCreationRequested.rejected, (state, { payload }: { payload: any }) => {
        state.error = payload?.data;
        state.isLoading = false;
      })
      .addCase(settingsUpdateRequested.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settingsUpdateRequested.fulfilled, (state, { payload }) => {
        state.settingsData = [
          ...(state.settingsData?.filter(
            (item) =>
              item.parentCompanyId !== payload.data.parentCompanyId ||
              item.folderId !== payload.data.folderId
          ) ?? []),
          payload.data,
        ];
        state.error = null;
        state.isLoading = false;
      })
      .addCase(settingsUpdateRequested.rejected, (state, { payload }: { payload: any }) => {
        state.error = payload?.data;
        state.isLoading = false;
      });
  },
});

export default designServicesSettingsSlice.reducer;
