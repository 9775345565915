import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from 'screens/Sidebar/constants';
import { ProjectDesignData } from 'types';

import { MAX_DESIGNS } from 'helpers/constants/maxDesigns';
import { BREAKPOINT_LG, PROJECT_STEPS_PANEL } from 'helpers/utils/breakpoints';

import { DESIGN_TYPES } from './constants';

export const getLayoutWidth = (viewportWidth: number, isSidebarCollapsed: boolean) => {
  if (viewportWidth <= BREAKPOINT_LG) {
    return '100%';
  } else if (isSidebarCollapsed) {
    return viewportWidth - (COLLAPSED_SIDEBAR_WIDTH + PROJECT_STEPS_PANEL);
  } else {
    return viewportWidth - (SIDEBAR_WIDTH + PROJECT_STEPS_PANEL);
  }
};

export const isMaxDesignLimitReached = ({
  remoteDesigns = [],
  onsiteDesigns = [],
  activeDesignType,
  accessRemoteDesign,
}: {
  remoteDesigns: ProjectDesignData[];
  onsiteDesigns: ProjectDesignData[];
  activeDesignType?: string | null;
  accessRemoteDesign: boolean;
}) => {
  if (!accessRemoteDesign) {
    return onsiteDesigns.length >= MAX_DESIGNS;
  } else {
    if (activeDesignType === DESIGN_TYPES.REMOTE_DESIGN) {
      return remoteDesigns.length >= MAX_DESIGNS;
    }
    if (activeDesignType === DESIGN_TYPES.ONSITE_DESIGN) {
      return onsiteDesigns.length >= MAX_DESIGNS;
    }
    return false;
  }
};
