import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from 'types';

import {
  adminFetchAllUsers,
  adminFetchCompaniesNotPopulated,
  adminFetchScaniflyAdmins,
  adminUpdateStatus,
  deleteUser,
  updateUserPermissions,
  updateUserProjectAccess,
} from 'api/admin/adminService';

import { companyMembersRequested } from '../companySlice/companySlice';
import { adminCompanyUsersRequested, adminUserDeleted } from './adminCompanyUsersSlice';

const name = 'adminUsers';

type UserState = {
  isLoading: boolean;
  error: null | string | undefined;
  isSuccess: boolean;
  users: User[];
  scaniflyAdminList: User[];
  isScaniflyAdminListLoading: boolean;
};

export const adminUsersRequested = createAsyncThunk(
  `${name}/usersRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const userResponse = await adminFetchAllUsers();
      if (userResponse) {
        // paradoxically, this fetches companies without members
        const companyResponse = await adminFetchCompaniesNotPopulated();
        const companies = companyResponse.data;
        const usersWithCompanies = userResponse.data.map((user: User) => {
          const formattedUser = { ...user };
          const userCompany = companies.find((company: { memberIds: string[] }) =>
            company.memberIds.includes(user.id ?? '')
          );

          if (userCompany) {
            formattedUser['company'] = userCompany?.name;
            formattedUser['companyId'] = userCompany?.id;
          }
          return formattedUser;
        });
        return usersWithCompanies;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminScaniflyAdminListRequested = createAsyncThunk(
  `${name}/scaniflyAdminUserListRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const scaniflyAdminList = await adminFetchScaniflyAdmins();
      return scaniflyAdminList.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminDeleteUser = createAsyncThunk(
  `${name}/adminDeleteUser`,
  async (
    {
      userId,
      firstName,
      lastName,
      onDeletionSuccess,
    }: {
      userId: string;
      firstName?: string;
      lastName?: string;
      onDeletionSuccess: (userId: string, firstName?: string, lastName?: string) => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await deleteUser(userId);
      dispatch(adminUsersRequested());
      dispatch(adminUserDeleted(userId));
      onDeletionSuccess(userId, firstName, lastName);
      return userId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUpdateUserStatus = createAsyncThunk(
  `${name}/adminUpdateUserStatus`,
  async (
    {
      userId,
      targetStatus,
      onSuccess,
    }: { userId: string; targetStatus: string; onSuccess: (targetStatus: string) => void },
    { rejectWithValue }
  ) => {
    try {
      await adminUpdateStatus(userId, targetStatus);
      onSuccess(targetStatus);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUserPermissionsUpdated = createAsyncThunk(
  `${name}/adminUserPermissionsUpdated`,
  async (
    { id, companyId, permissions }: { id: string; companyId: string; permissions: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updateUserPermissions(id, { permissions: permissions });

      dispatch(companyMembersRequested());
      dispatch(adminUsersRequested());

      if (companyId) {
        dispatch(adminCompanyUsersRequested(companyId));
      }

      return {
        id,
        permissions,
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const adminUserProjectsAccessUpdated = createAsyncThunk(
  `${name}/adminUserProjectsAccessUpdated`,
  async (
    { userId, companyId, projectAccess }: { userId: string; companyId: string; projectAccess: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updateUserProjectAccess(userId, projectAccess);
      dispatch(adminUsersRequested());
      if (companyId) {
        dispatch(adminCompanyUsersRequested(companyId));
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminUsersSlice = createSlice({
  name,
  initialState: {
    isLoading: false,
    error: null,
    isSuccess: false,
    users: [],
    scaniflyAdminList: [],
    isScaniflyAdminListLoading: false,
  } as UserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminUsersRequested.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminUsersRequested.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminUsersRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    builder.addCase(adminDeleteUser.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminDeleteUser.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminDeleteUser.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    builder.addCase(adminUserPermissionsUpdated.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminUserPermissionsUpdated.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminUserPermissionsUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    builder.addCase(adminUserProjectsAccessUpdated.pending, (state) => {
      state.isLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(adminUserProjectsAccessUpdated.fulfilled, (state) => {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(adminUserProjectsAccessUpdated.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
      state.isSuccess = false;
    });
    builder.addCase(adminScaniflyAdminListRequested.pending, (state) => {
      state.isScaniflyAdminListLoading = true;
    });
    builder.addCase(adminScaniflyAdminListRequested.fulfilled, (state, { payload }) => {
      state.scaniflyAdminList = payload;
      state.error = null;
      state.isScaniflyAdminListLoading = false;
    });
    builder.addCase(adminScaniflyAdminListRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isScaniflyAdminListLoading = false;
    });
  },
});

export default adminUsersSlice.reducer;
