import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  GET_USER_API_KEYS,
  SET_REQUEST_STATE,
  SLICE_NAMESPACE,
} from './constants';
import { ApiKey, ApiKeyState, SetRequestStatePayload } from './types';

// Initial state
const initialState: ApiKeyState = {
  apiKeys: {},
  userMap: {}, // userMap keeps the api key ids
  requestState: {
    [CREATE_API_KEY]: {
      status: undefined,
      error: undefined,
    },
    [DELETE_API_KEY]: {
      status: undefined,
      error: undefined,
    },
    [GET_USER_API_KEYS]: {
      status: undefined,
      error: undefined,
    },
  },
};

const apiKeySlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    [GET_USER_API_KEYS]: (state, { payload: apiKeys }: PayloadAction<ApiKey[]>) => {
      const newApiKeys: { [key: string]: ApiKey } = {};
      const userMap: Record<string, string[]> = {};
      for (const apiKey of apiKeys) {
        newApiKeys[apiKey.id] = apiKey;
        userMap[apiKey.userId] = [...(userMap[apiKey.userId] ?? []), apiKey.id];
      }
      state.apiKeys = newApiKeys;
      state.userMap = userMap;
    },
    [CREATE_API_KEY]: (state, { payload: apiKey }: PayloadAction<ApiKey>) => {
      state.apiKeys[apiKey.id] = apiKey;
      state.userMap[apiKey.userId] = [...(state.userMap[apiKey.userId] ?? []), apiKey.id];
    },
    [DELETE_API_KEY]: (state, { payload: apiKeyId }: PayloadAction<string>) => {
      const apiKey = state.apiKeys[apiKeyId];
      if (!apiKey) return;
      state.userMap[apiKey.userId] = (state.userMap[apiKey.userId] ?? []).filter(
        (keyId) => keyId !== apiKeyId
      );
      delete state.apiKeys[apiKey.id];
    },
    [SET_REQUEST_STATE]: (
      state,
      { payload: { request, status, error } }: PayloadAction<SetRequestStatePayload>
    ) => {
      state.requestState[request] = {
        status,
        error,
      };
    },
  },
});

export const { actions } = apiKeySlice;
export default apiKeySlice.reducer;
