import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';

import { TagTypes } from './constants';

const StyledContainer = styled.div`
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  float: right;
  margin: 0 0.35rem;
`;

const StyledWrapper = styled.div<{ color: string; backgroundColor: string; fontWeight: number }>`
  padding: 0 0.7rem;
  border-radius: 1rem;
  font-size: ${fontSizes.extraExtraSmall};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const getColorByType = (type: TagTypes): string => {
  if (type === TagTypes.score || type === TagTypes.new) {
    return colors.white;
  } else if (type === TagTypes.completed) {
    return '#00973a';
  } else if (type === TagTypes.canceled) {
    return '#ff4d4d';
  }
  return colors.black;
};

const getBackgroundColorByType = (type: TagTypes): string => {
  if (type === 'score') {
    return colors.black;
  } else if (type === TagTypes.new) {
    return colors.purple;
  } else if (type === TagTypes.completed) {
    return '#ebfff2';
  } else if (type === TagTypes.canceled) {
    return '#ff4d4d20';
  }
  return colors.white;
};

const getFontWeightByType = (type: TagTypes) => {
  if (type === TagTypes.completed || type === TagTypes.canceled || type === TagTypes.upgrade) {
    return fontWeights.semiBold;
  }
  return fontWeights.normal;
};

const SidebarTag = ({
  type = TagTypes.score,
  text,
}: {
  type?: TagTypes;
  text?: string | number;
}) => {
  if (!text) return null;

  const color = getColorByType(type);
  const backgroundColor = getBackgroundColorByType(type);
  const fontWeight = getFontWeightByType(type);

  return (
    <StyledContainer>
      <StyledWrapper
        color={color}
        backgroundColor={backgroundColor}
        fontWeight={fontWeight}
        data-testid={`sidetag-${type}`}
      >
        {text}
      </StyledWrapper>
    </StyledContainer>
  );
};

export default SidebarTag;
