import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { swapComment } from 'helpers/utils/mention';
import { truncateLongText } from 'helpers/utils/truncateLongText';

const StyledText = styled.span`
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-right: 10px;
  cursor: pointer;
`;

type Props = {
  comment: string;
};

const MentionText = ({ comment }: Props) => {
  const [text, setText] = useState('');
  const [truncateText, setTruncateText] = useState(true);
  useEffect(() => {
    if (comment !== '') {
      const replaceText = swapComment(comment);
      if (truncateText) {
        setText(truncateLongText(replaceText, 100));
      } else {
        setText(replaceText);
      }
    }
  }, [comment, truncateText]);

  const onPress = () => {
    setTruncateText(!truncateText);
  };

  return (
    <StyledText
      onClick={onPress}
      dangerouslySetInnerHTML={{
        __html: text.replace(/\n\r?/g, '<br />'),
      }}
    />
  );
};
export default MentionText;
