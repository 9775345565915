import { ACCESS } from 'helpers/constants';

import useFlagsWithOverride from './useFlagsWithOverride';

const useFeatureToggle = (flag: ACCESS | ACCESS[]) => {
  const flags = useFlagsWithOverride();

  if (Array.isArray(flag)) {
    return flag.every((f) => Boolean(flags[f]));
  }

  return Boolean(flags[flag]);
};

export default useFeatureToggle;
