import CategoryFilter from 'screens/Projects/ProjectsFilters/CategoryFilter';
import MoreFilter from 'screens/Projects/ProjectsFilters/MoreFilter';

import { i18n } from 'helpers/utils/translations';

import DateFilter from '../ProjectsFilters/DateFilter';

export const FILTER = {
  ADDRESSES: 'addresses',
  FOLDER: 'folder',
  FOLDERS: 'folders',
  ROLES: 'roles',
  DATE: 'date',
  STATUS: 'status',
  LOCATION: 'location',
  CITY: 'city',
  MORE: 'more',
  MORE_FILTERS: 'More filters',
  CREATED_AT: 'Created Date',
  COMPLETED_AT: 'Processed Date',
  ON: 'on',
  BETWEEN: 'between',
  FROM: 'from',
  TO: 'to',
  TEAMMATES: 'teammates',
  PROJECT_TYPE: 'project type',
  POSITIONS: 'positions',
  STATUSES: 'statuses',
  TYPES: 'types',
  USERS: 'users',
};

export const DATE_OPTIONS = [
  {
    label: FILTER.CREATED_AT,
    value: 'createdAt',
  },
  {
    label: FILTER.COMPLETED_AT,
    value: 'completedDate',
  },
];

export const SORT = {
  PROJECT_NAME: 'Project name',
  DATE_CREATED: 'Date created',
  DATE_MODIFIED: 'Date modified',
  ASC: 'asc',
  DESC: 'desc',
  ORDER: 'order',
  SORT: 'sort',
  NAME: 'name',
  CREATED_AT: 'createdAt',
  COMPLETED_AT: 'completedDate',
  UPDATED_AT: 'updatedAt',
};

export const SORT_OPTIONS = [
  { label: SORT.DATE_CREATED, value: SORT.CREATED_AT },
  { label: SORT.DATE_MODIFIED, value: SORT.UPDATED_AT },
  { label: SORT.PROJECT_NAME, value: SORT.NAME },
];

export const MORE_FILTERS = [
  {
    id: FILTER.POSITIONS,
    label: FILTER.ROLES,
  },
  {
    id: FILTER.USERS,
    label: FILTER.TEAMMATES,
  },
  {
    id: FILTER.STATUSES,
    label: FILTER.STATUS,
  },
  {
    id: FILTER.TYPES,
    label: FILTER.PROJECT_TYPE,
  },
];

export const FILTERS = [
  {
    id: FILTER.FOLDERS,
    title: FILTER.FOLDER,
    label: FILTER.FOLDER,
    component: <CategoryFilter />,
  },
  {
    id: FILTER.DATE,
    title: FILTER.DATE,
    label: FILTER.DATE,
    component: <DateFilter />,
  },
  {
    id: FILTER.MORE,
    title: FILTER.MORE_FILTERS,
    label: FILTER.MORE,
    component: <MoreFilter />,
  },
];

export const MY_PROJECTS_TABLE_CSV_COLUMN_TITLES = [
  {
    title: i18n.t('CSVExportTitles.projectName'),
    dataIndex: 'name',
  },
  {
    title: i18n.t('CSVExportTitles.dateCompleted'),
    dataIndex: 'completedDate',
  },
  {
    title: i18n.t('CSVExportTitles.projectType'),
    dataIndex: 'type',
  },
  {
    title: i18n.t('CSVExportTitles.designsUrl'),
    dataIndex: 'id',
  },
  {
    title: i18n.t('CSVExportTitles.status'),
    dataIndex: 'statusDescription',
  },
  {
    title: i18n.t('CSVExportTitles.address'),
    dataIndex: 'address',
  },
  {
    title: i18n.t('CSVExportTitles.createdBy'),
    dataIndex: 'createdBy',
  },
  {
    title: i18n.t('CSVExportTitles.systemSize'),
    dataIndex: 'systemSize',
  },
  {
    title: i18n.t('CSVExportTitles.asa'),
    dataIndex: 'ASA',
  },
  {
    title: i18n.t('CSVExportTitles.annualProduction'),
    dataIndex: 'annualProduction',
  },
];

export const DDS_CSV_COLUMN_TITLES = [
  {
    title: i18n.t('CSVExportTitles.droneDataScore'),
    dataIndex: 'droneDataScore',
  },
  {
    title: i18n.t('CSVExportTitles.cameraTechnique'),
    dataIndex: 'cameraTechnique',
  },
  {
    title: i18n.t('CSVExportTitles.imageQuality'),
    dataIndex: 'imageQuality',
  },
  {
    title: i18n.t('CSVExportTitles.flightPath'),
    dataIndex: 'flightPath',
  },
];
