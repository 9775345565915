import { GoBackButton } from 'components';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { SearchBar } from './SearchBar';

const StyledWrapper = styled.div`
  display: flex;
  margin: 3.4rem 0 2rem 0;
  padding: 0 3.1rem;

  p {
    margin: 0.65rem 3.5rem 0 2rem;
    color: ${colors.labelGray};
    font-size: ${fontSizes.medium};
  }
`;

export const NavigationBar = ({
  handleSearchTextInputChange,
}: {
  handleSearchTextInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <StyledWrapper>
      <GoBackButton />
      <SearchBar handleSearchTextInputChange={handleSearchTextInputChange} />
    </StyledWrapper>
  );
};
