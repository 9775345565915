import { useTranslation } from 'react-i18next';

import { Col, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormikProps } from 'formik';
import { ProjectDesignData } from 'types';

import { CustomSelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';

import { FORM_CONTROLS, REQUIRED_ERROR } from '../constants';
import './PreliminaryDesignForm.scss';

const PreliminaryDesignForm = ({
  getFieldProps,
  setFieldValue,
  handleBlur,
  useExistingDesign,
  handleUseExistingDesign,
  values,
  projectDesigns,
}: {
  useExistingDesign: boolean;
  handleUseExistingDesign: (e: RadioChangeEvent) => void;
  projectDesigns: ProjectDesignData[];
} & FormikProps<Record<string, string>>) => {
  const { Group } = Radio;
  const { t } = useTranslation();

  const [handleSelectedDesignBlur, getSelectedDesignError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.SELECTED_DESIGN
  );

  return (
    <Col className="PreliminaryDesignForm">
      <Row>
        <Col span={24} className="PreliminaryDesignForm-RadioButtons ant-col left-column">
          <div>{t('DesignService.planSet.preliminaryDesignForm.useExistingDesign')}</div>
          <Group
            onChange={handleUseExistingDesign}
            value={useExistingDesign}
            className="radio-button-wrapper"
          >
            <Radio value={true}>
              {t('DesignService.planSet.preliminaryDesignForm.options.yes')}
            </Radio>
            <Radio value={false}>
              {t('DesignService.planSet.preliminaryDesignForm.options.no')}
            </Radio>
          </Group>
        </Col>
        <Col span={24} className="PreliminaryDesignForm-CustomSelect ant-col right-column">
          <label htmlFor={FORM_CONTROLS.SELECTED_DESIGN}>
            <h3 className="title">
              {t('DesignService.planSet.preliminaryDesignForm.selectDesign')}
            </h3>
          </label>
          <div className="input">
            <CustomSelect
              {...getFieldProps(FORM_CONTROLS.SELECTED_DESIGN)}
              id={FORM_CONTROLS.SELECTED_DESIGN}
              placeholder={t('DesignService.planSet.preliminaryDesignForm.selectDesignPlaceholder')}
              options={
                useExistingDesign
                  ? projectDesigns.map((design) => ({
                      value: `Design Name: ${design.name}, Design ID: ${design.id} `,
                      label: design.name,
                    })) ?? []
                  : []
              }
              onChange={(option: { value: string }) => {
                setFieldValue(FORM_CONTROLS.SELECTED_DESIGN, option.value);
              }}
              onBlur={handleSelectedDesignBlur}
              error={(useExistingDesign ? getSelectedDesignError(values) : false) ?? false}
              variant="filter"
              aria-label={t('DesignService.planSet.preliminaryDesignForm.selectDesign')}
              tall
              disabled={!useExistingDesign}
            />
            <div className="Form-Error">
              {/* @ts-ignore */}
              {useExistingDesign && getSelectedDesignError(values) ? REQUIRED_ERROR : ''}
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default PreliminaryDesignForm;
