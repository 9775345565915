import PropTypes from 'prop-types';

import FeatureFlag from './FeatureFlag.ui';

const FeatureFlags = ({ flags, onChange }) => (
  <ul className={'FeatureFlags-List'}>
    {Object.keys(flags).map((flag) => (
      <FeatureFlag
        key={`Key-FeatureFlag-${flag}-${flags[flag]}`}
        name={flag}
        enabled={flags[flag]}
        onChange={onChange}
      />
    ))}
  </ul>
);

FeatureFlags.propTypes = {
  flags: PropTypes.object,
  onChange: PropTypes.func,
};

export default FeatureFlags;
