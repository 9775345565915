export const FORM_CONTROLS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE: 'role',
  PHONE_NUMBER: 'phoneNumber',
  COMPANY: 'company',
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
};

export const DEFAULT_COUNTRY = 'us';
export const PHONE_NUMBER_INITIAL_VALUE = '1';
export const INITIAL_COMPANY = 'initial';
export const COMPANY_NAME_CHECK_DEBOUNCE_MS = 600;
