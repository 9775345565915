import { Link } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import { OLD_PRICING_TIER, PRICING_TIER } from '@cpm/scanifly-shared-data';
import { startCase } from 'lodash';
import capitalize from 'lodash/capitalize';
import { User } from 'types';

import {
  scaniflyAdminAccountingRoute,
  scaniflyAdminChecklistManagerRoute,
  scaniflyAdminCompanyCreditHistoryRoute,
  scaniflyAdminProjectsCustomerRoute,
  scaniflyAdminUsersCustomerRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';
import { handleCopyClick } from 'helpers/utils/handleCopyClick';

import { ReactComponent as ChecklistIcon } from 'assets/checklist-manager.svg';
import { ReactComponent as DesignsIcon } from 'assets/designs-icon.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar-sign-solid.svg';
import { ReactComponent as ListViewIcon } from 'assets/icons/list-view-icon.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team-icon.svg';

import { ColumnsType } from 'antd/lib/table';
import { Dispatch, SetStateAction } from 'react';
import { ACT_STATUS_FILTERS } from '../constants';
import { DATE_OPTIONS } from '../CustomerSupportUpload/constants';
import ActionDropdown from './ActionDropdown';
import ChangeTierActionDropDown from './ChangeTierActionDropdown';

export type AccountHolder = Record<'status' | 'email' | 'firstName' | 'lastName' | 'id', string>;

export type Account = {
  id: string;
  name: string;
  address: Partial<Record<'line' | 'city' | 'state' | 'zip' | 'country', string>>;
  accountHolder?: AccountHolder;
  newPricingTier?: PRICING_TIER;
  pricingTier?: PRICING_TIER | OLD_PRICING_TIER;
  members?: User[];
  createdAt: string;
};

const TableData = ({
  handleAddCreditModalOpen,
  handleRefundCreditModalOpen,
  handleRemoveCreditModalOpen,
  setSelectedCompany,
}: {
  handleAddCreditModalOpen: () => void;
  handleRefundCreditModalOpen: () => void;
  handleRemoveCreditModalOpen: () => void;
  setSelectedCompany: Dispatch<
    SetStateAction<{
      name: string;
      id: string;
    } | null>
  >;
}): ColumnsType<Account> => {
  const { isScaniflyBillingAdmin } = usePermissions();
  return [
    {
      title: 'Company',
      dataIndex: 'name',
      width: '18rem',
      defaultSortOrder: 'ascend',
      sorter: (a: { name?: string }, b: { name?: string }) =>
        (a?.name ?? '')?.localeCompare(b?.name ?? ''),
    },
    {
      title: 'Users',
      width: '8rem',
      render: (_: any, item: { id: string }) => (
        <Link
          className="Button--Icon"
          aria-label="company user manager"
          to={scaniflyAdminUsersCustomerRoute(item.id)}
        >
          <TeamIcon />
        </Link>
      ),
    },
    {
      title: 'Projects',
      width: '9rem',
      render: (_: any, item: { id: string }) => (
        <Link
          className="Button--Icon"
          aria-label="company project manager"
          to={scaniflyAdminProjectsCustomerRoute(item.id)}
        >
          <ListViewIcon />
        </Link>
      ),
    },
    {
      title: 'Accounting',
      width: '12rem',
      render: (_: any, item: { id: string }) => (
        <Link
          className="Button--Icon"
          aria-label="company project manager"
          to={{
            pathname: scaniflyAdminAccountingRoute(item.id),
          }}
        >
          <DollarIcon />
        </Link>
      ),
    },
    {
      title: 'Credit History',
      width: '9rem',
      render: (_: any, item: { id: string }) => (
        <Link
          className="Button--Icon"
          aria-label="credit history table"
          to={{
            pathname: scaniflyAdminCompanyCreditHistoryRoute(item.id),
          }}
        >
          <DesignsIcon />
        </Link>
      ),
    },
    {
      title: 'Checklists',
      width: '12rem',
      render: (_: any, item: { id: string }) => (
        <Link
          className="Button--Icon"
          aria-label="company checklists manager"
          to={{
            pathname: scaniflyAdminChecklistManagerRoute(item.id),
          }}
        >
          <ChecklistIcon />
        </Link>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'actStatus',
      width: '10rem',
      filters: ACT_STATUS_FILTERS,
      onFilter: (value, record: { accountHolder?: { status?: string } }) =>
        record.accountHolder?.status === value,
      render: (_: any, item: Account) => {
        return (
          (item.accountHolder ? capitalize(item.accountHolder.status) : '–') +
          ` (${startCase(item.newPricingTier || item.pricingTier || PRICING_TIER.Tier1)})`
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      render: (_: any, item: Account) => {
        return item.accountHolder ? (
          <ActionDropdown
            companyId={item.id}
            companyName={item.name}
            accountHolder={item.accountHolder}
            isScaniflyBillingAdmin={isScaniflyBillingAdmin}
            handleAddCreditModalOpen={handleAddCreditModalOpen}
            handleRefundCreditModalOpen={handleRefundCreditModalOpen}
            handleRemoveCreditModalOpen={handleRemoveCreditModalOpen}
            setSelectedCompany={setSelectedCompany}
          />
        ) : (
          ''
        );
      },
    },
    {
      title: 'Change Tier',
      dataIndex: 'action',
      render: (_: any, item: Account) => {
        return (
          <ChangeTierActionDropDown
            companyName={item.name}
            companyId={item.id}
            pricingTier={item.newPricingTier ? item.newPricingTier : item.pricingTier}
          />
        );
      },
    },
    {
      title: 'Company ID',
      dataIndex: 'id',
      width: '10rem',
      sorter: (a: { id: string }, b: { id: string }) => (a?.id ?? '').localeCompare(b?.id ?? ''),
      render: (id: string, item: Account) => {
        return (
          <div className="ScaniflyAdmin-ButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label="copy company id"
              className="ScaniflyAdmin-ButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, 'Company ID', item.name)}
            />
          </div>
        );
      },
    },
    {
      title: 'Account Holder',
      dataIndex: 'accountHolder',
      width: '17rem',
      sorter: (a: Account, b: Account) =>
        (a.accountHolder?.firstName ?? '').localeCompare(b.accountHolder?.firstName ?? ''),
      render: (accountHolder: AccountHolder) =>
        accountHolder ? (
          <a
            href={`mailto:${accountHolder.email}`}
            target="_blank"
            aria-label="email account holder"
            rel="noreferrer"
          >
            {`${accountHolder.firstName} ${accountHolder.lastName}`}
          </a>
        ) : (
          '–'
        ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      width: '20rem',
      sorter: (a: { createdAt: string }, b: { createdAt: string }) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (createdAt: string) => {
        return createdAt ? new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS) : '–';
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '22rem',
      render: (address: Record<'line' | 'city' | 'state' | 'zip' | 'country', string>) => {
        return address.line !== 'N/A'
          ? `${address.line}, ${address.city}, ${address.state}, ${address.zip}, ${address.country}`
          : '–';
      },
    },
  ];
};

export default TableData;
