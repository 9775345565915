import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'antd';
import { Subscription } from 'types';

import { CreditDisplayRow } from 'components/BillingAndCredits/CreditDisplayRow/CreditDisplayRow';
import {
  CreditHistoryLink,
  CreditsDescriptionText,
  CreditsHeader,
  SectionHeader,
  Value,
} from 'components/BillingAndCredits/SectionHeader/SectionHeader';

import { creditHistoryAdminQueueRoute } from 'helpers/constants/routes';
import { AccountDesignServicesCredits } from '../AccountDesignServicesCredits';

export const CreditsDisplay = ({
  companySubscriptionInfo,
}: {
  companySubscriptionInfo: {
    credits: { amount: number };
    subscription: Subscription;
    companyId: string;
  } | null;
}) => {
  const { t } = useTranslation();
  const [remainingCredits, setRemainingCredits] = useState(
    companySubscriptionInfo?.credits?.amount
  );

  return (
    <>
      <CreditDisplayRow>
        <CreditsHeader>{t('Credits.title')}</CreditsHeader>
        <SectionHeader>
          {t('Credits.existingCredits')}:
          <Value>
            {(remainingCredits ?? companySubscriptionInfo?.credits?.amount ?? 0).toLocaleString(
              'en-US'
            )}
          </Value>
        </SectionHeader>
      </CreditDisplayRow>
      <CreditDisplayRow>
        <CreditsDescriptionText>{t('Credits.costPerProject')}</CreditsDescriptionText>
        <CreditHistoryLink to={creditHistoryAdminQueueRoute()}>
          {t('Credits.creditHistory')}
        </CreditHistoryLink>
      </CreditDisplayRow>

      <AccountDesignServicesCredits
        companySubscriptionInfo={companySubscriptionInfo}
        setRemainingCredits={setRemainingCredits}
      />
      <Divider />
    </>
  );
};
