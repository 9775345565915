import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { resetProject, userSubmittedForm } from 'state/slices/projectSlice';

import {
  newProjectCustomerInfoRoute,
  projectsListRoute,
  scaniflyAdminNewProjectCustomerInfoRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as ScaniflyIcon } from 'assets/scaniflyicon.svg';
import { ReactComponent as ScaniflyLogo } from 'assets/scaniflylogo.svg';

import MenuItem from '../MenuItem';
import './SidebarHeader.scss';

const SidebarHeader = ({
  isSidebarCollapsed,
  className,
  isScaniflyAdminRoute,
  isNewProjectView,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hasUploadPermissions, isDesignServiceProvider, isSimpleDesignOrSalesManager } =
    usePermissions();

  const handleNewProjectClick = () => {
    dispatch(resetProject());
    if (isScaniflyAdminRoute) {
      return history.push(scaniflyAdminNewProjectCustomerInfoRoute());
    }
    if (!isNewProjectView) {
      dispatch(userSubmittedForm(false));
      history.push(newProjectCustomerInfoRoute());
    }
  };

  const newProjectButton = (
    <Button
      className={cn('Button--Dark', {
        'Button--Collapsed': isSidebarCollapsed,
        'Button--Disabled': !hasUploadPermissions && !isSidebarCollapsed,
      })}
      disabled={!hasUploadPermissions || isSimpleDesignOrSalesManager}
      aria-disabled={!hasUploadPermissions}
      aria-label="New Project"
      onClick={handleNewProjectClick}
    >
      {isSidebarCollapsed ? '+' : 'New Project'}
    </Button>
  );

  return (
    <div className={cn('Sidebar-Header-Wrapper', className)}>
      <MenuItem
        key="logo"
        className="Logo"
        title="Home"
        link={projectsListRoute()}
        isCollapsed={isSidebarCollapsed}
        icon={isSidebarCollapsed && <ScaniflyIcon />}
      >
        <div className="Sidebar-Header-Logo">
          <span className="Sidebar-Header-Logo--Hidden">Logo</span>
          {isSidebarCollapsed ? <ScaniflyIcon /> : <ScaniflyLogo className="Sidebar-Logo--Full" />}
        </div>
      </MenuItem>
      {!isDesignServiceProvider ? (
        <MenuItem
          key="new-project"
          className={cn('Sidebar-Header-NewProject', {
            'Sidebar-Header-NewProject--Collapsed': isSidebarCollapsed,
          })}
          title="New Project"
          isCollapsed={isSidebarCollapsed}
          icon={isSidebarCollapsed && newProjectButton}
        >
          <div className="Sidebar-Header-NewProjectButton">
            <Tooltip
              title="Please contact your Account Administrator to create a new project."
              overlayClassName={hasUploadPermissions && 'Hidden'}
              placement="right"
            >
              {newProjectButton}
            </Tooltip>
          </div>
        </MenuItem>
      ) : null}
    </div>
  );
};

SidebarHeader.propTypes = {
  className: PropTypes.string,
  isSidebarCollapsed: PropTypes.bool,
  isScaniflyAdminRoute: PropTypes.bool,
  isNewProjectView: PropTypes.bool,
};

export default SidebarHeader;
