import { useTranslation } from 'react-i18next';

import { FieldTypeEnum } from '@cpm/scanifly-shared-data';

import { Button } from 'components';

type Props = {
  type: FieldTypeEnum;
};

export function FieldType({ type }: Props) {
  const { t } = useTranslation();
  const getIcon = () => {
    switch (type) {
      case FieldTypeEnum.textInput:
        return 'text';
      case FieldTypeEnum.checkMark:
        return 'checkbox';
      case FieldTypeEnum.datePicker:
        return 'date';
      case FieldTypeEnum.timePicker:
        return 'time';
      case FieldTypeEnum.radio:
        return 'radio';
      case FieldTypeEnum.dropDown:
        return 'dropdown';
      case FieldTypeEnum.media:
        return 'media';
      case FieldTypeEnum.notApplicable:
        return 'notapplicable';
      default:
        return undefined;
    }
  };
  return (
    <Button
      disabled
      border="none"
      icon={getIcon()}
      label={t(`Checklists.${type}`) ?? ''}
      padding="0"
    />
  );
}
