import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaCategory, Project } from 'types';
import {
  CREATE_PROJECT_MEDIA_CATEGORY,
  DELETE_PROJECT_MEDIA_CATEGORY,
  GET_PROJECT_MEDIA_CATEGORIES,
  UPDATE_PROJECT_MEDIA_CATEGORY,
} from './constants';
import { ProjectCategoriesState } from './types';

const name = 'projectCategories';

const initialState: ProjectCategoriesState = {
  projectCategories: [],
};

const projectCategoriesSlice = createSlice({
  name,
  initialState,
  reducers: {
    [CREATE_PROJECT_MEDIA_CATEGORY]: (state, { payload }: PayloadAction<MediaCategory>) => {
      state.projectCategories = [...state.projectCategories, payload];
    },

    [DELETE_PROJECT_MEDIA_CATEGORY]: (
      state,
      { payload }: PayloadAction<{ categoryName: string }>
    ) => {
      state.projectCategories = state.projectCategories.filter(
        ({ categoryName }) => categoryName !== payload.categoryName
      );
    },

    [GET_PROJECT_MEDIA_CATEGORIES]: (
      state,
      { payload: mediaCategories }: PayloadAction<MediaCategory[]>
    ) => {
      state.projectCategories = mediaCategories;
    },

    [UPDATE_PROJECT_MEDIA_CATEGORY]: (
      state,
      {
        payload,
      }: PayloadAction<{ oldCategoryName: string; newCategoryName: string; project: Project }>
    ) => {
      state.projectCategories = state.projectCategories.map((cat) => {
        if (cat.categoryName === payload.oldCategoryName) {
          cat.categoryName = payload.newCategoryName;
        }
        return cat;
      });
    },
  },
  extraReducers: () => {},
});

const { actions } = projectCategoriesSlice;
export { actions };

export default projectCategoriesSlice.reducer;
