import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import fontSizes from 'helpers/constants/fontSizes';

const Header = styled.div`
  display: block;
  width: 100%;
  font-size: ${fontSizes.medium};
  margin-bottom: 1rem;
`;
const List = styled.ul`
  font-size: ${fontSizes.mediumSmall};
`;
const InformationNeeded = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header>{t('DesignService.planSet.informationNeeded.title')}</Header>
      <List>
        <li>{t('DesignService.planSet.informationNeeded.items.1')}</li>
        <li>{t('DesignService.planSet.informationNeeded.items.3')}</li>
        <li>{t('DesignService.planSet.informationNeeded.items.10')}</li>
      </List>
    </>
  );
};

export default InformationNeeded;
