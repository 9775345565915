import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

const StyledTitleContainer = styled.div`
  padding: 2.4rem 0 0 2.4rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledPlaceHolder = styled.div`
  cursor: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blueGray};
  width: 100%;
  margin: 2rem 0 9.6rem 0;

  .image {
    width: 10vh;
    height: 10vh;

    path {
      fill: ${colors.mainBlue};
    }
  }
`;

const StyledImagePreview = styled.div`
  background-color: ${colors.darkGray};
  min-height: calc(100vh - 9.5rem);
  color: ${colors.white};
  font-size: ${fontSizes.extraSmall};
  right: 0;
  bottom: 0;
  z-index: 1;

  @media only screen and (max-width: ${breakpoints.lg}) {
    position: relative;
  }

  .title {
    margin-top: 0.65rem;
  }

  svg {
    margin-right: 1rem;
    vertical-align: middle;
    margin-top: -0.25rem;
    width: 1.5rem;
    height: 1.65rem;
  }

  .icon {
    background: none;
    margin: 0 2rem;

    svg {
      width: 1.35rem;
      height: 1.35rem;
    }

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &:focus-visible {
      svg {
        outline: 0.2rem solid ${colors.mainBlue};
      }
    }
  }
`;

export { StyledImagePreview, StyledPlaceHolder, StyledTitleContainer };
