import { DownloadOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import PropTypes from 'prop-types';

import { ZipButton } from 'components';

import { getDisplayData, getHeader } from 'screens/DesignServices/helpers/getHeader';

import './ViewFormModal.scss';

const Panel = Collapse.Panel;

const ViewFormModal = ({ formData, projectName, handleClose }) => {
  const formDataArr = Object.entries(formData);

  return (
    <section>
      <h3>Project: {projectName}</h3>
      <Collapse bordered={false} className="ViewFormModal-Wrapper">
        {formDataArr.map((data, index) => {
          if (data[0] !== 'uploads') {
            const header = getHeader(data[0]);
            if (header) {
              const displayData = getDisplayData(formData, data);
              if (!displayData) return null;
              return (
                <Panel header={getHeader(data[0])} key={index}>
                  {displayData}
                </Panel>
              );
            }
          }
          return null;
        })}
        {!formDataArr.length ? <div>No form data can be found.</div> : null}
        {
          <section className="ViewFormModal-UploadsWrapper">
            <h3>File Uploads</h3>
            {formData?.uploads?.length
              ? formData.uploads.map(
                  (
                    {
                      imgUrl,
                      thumbnailUrl,
                      originalFilenameWithoutExtension,
                      originalFileExtension,
                    },
                    index
                  ) => (
                    <a
                      href={imgUrl}
                      download
                      key={`image-${index}`}
                      target="_blank"
                      rel="noreferrer"
                      className="ViewFormModal-DownloadButton"
                    >
                      {thumbnailUrl ? (
                        <img
                          src={thumbnailUrl}
                          alt={originalFilenameWithoutExtension}
                          className="ViewFormModal-Thumbnail"
                        />
                      ) : (
                        <DownloadOutlined className="ViewFormModal-DownloadIcon" />
                      )}
                      <span>{`${originalFilenameWithoutExtension}${originalFileExtension}`}</span>
                    </a>
                  )
                )
              : 'No files were uploaded for this service request.'}
          </section>
        }
      </Collapse>
      <section className="ViewFormModal-ButtonsWrapper">
        <ZipButton files={formData?.uploads} folderName={projectName} />
        <Button className="Button--Blue" onClick={handleClose}>
          Back
        </Button>
      </section>
    </section>
  );
};

ViewFormModal.propTypes = {
  formData: PropTypes.object,
  projectName: PropTypes.string,
  handleClose: PropTypes.func,
};

export default ViewFormModal;
