import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import ServiceRequest from 'types/ServiceRequest';

import styled from 'styled-components';

import { extraCreditChargeRequested } from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import { AppDispatch } from 'state/store/store';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import {
  CHARGE_EXTRA_CREDITS_FORM_CONTROLS,
  ChargeExtraCreditsFormValues,
  validationSchema,
} from './validationSchema';

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
  serviceRequest?: ServiceRequest;
};

const StyledModal = styled(Modal)`
  position: relative;
  .ant-modal-content .ant-modal-body {
    max-width: 50rem;

    .close-modal-icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;
    }
  }
`;

const StyledTitle = styled.h2`
  display: inline-block;
  margin-bottom: 2rem;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.semiBold};
  color: ${colors.darkGray};
`;

const StyledSpan = styled.span`
  font-size: ${fontSizes.extraSmall};
  font-weight: ${fontWeights.normal};
  color: ${colors.labelGray};
`;

const StyledSubtitle = styled.p`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.semiBold};
  color: ${colors.darkGray};
`;

const StyledSectionWrapper = styled.div`
  margin-bottom: 2rem;
  p {
    margin-bottom: 0.25rem;
    font-size: ${fontSizes.small};
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > :first-child {
    margin-right: 0.8rem;
  }

  @media only screen and (max-width: ${breakpoints.xs}) {
    justify-content: center;
  }
`;

const ChargeExtraCreditsModal = ({ isModalOpen, toggleModal, serviceRequest }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const initialValues = {
    creditCount: undefined,
    memo: '',
  };

  const { touched, errors, getFieldProps, handleSubmit, resetForm, isValid, dirty } =
    useFormik<ChargeExtraCreditsFormValues>({
      initialValues,
      validationSchema,
      onSubmit: (values: { memo: string; creditCount?: number }) => {
        if (values?.creditCount && serviceRequest && serviceRequest.company) {
          dispatch(
            extraCreditChargeRequested({
              creditCount: Number(values.creditCount),
              serviceRequestId: serviceRequest.id,
              companyId: serviceRequest.company.id,
              memo: values.memo,
              handleSuccess: onSuccess,
              handleError: onError,
            })
          );
        }
        toggleModal();
        resetForm();
      },
    });

  useEffect(() => {
    if (!isModalOpen) {
      resetForm();
    }
  }, [resetForm, isModalOpen]);

  const isFormValid = isValid && dirty;

  const onSuccess = (creditCount: number) => {
    openNotification({
      type: 'success',
      title: t('DesignService.chargeSuccessTitle'),
      text: t('DesignService.chargeSuccessMessage', {
        creditCount,
        serviceRequestId: serviceRequest?.id ?? '--',
      }),
    });
  };

  const onError = (errorMessage?: string) => {
    openNotification({
      type: 'error',
      title: t('DesignService.chargeErrorTitle'),
      text: t('DesignService.chargeErrorMessage', {
        serviceRequestId: serviceRequest?.id ?? '--',
        errorMessage,
      }),
    });
  };

  return (
    // @ts-ignore this lib is incompatible with react18
    <StyledModal visible={isModalOpen} onCancel={toggleModal} {...MODAL_PROPS}>
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <>
        <StyledTitle>{t('DesignService.chargeExtraCredits')}</StyledTitle>
        <StyledSectionWrapper>
          <p data-testid="orderId">
            {t('DesignService.orderId', {
              orderId: serviceRequest?.id ?? '',
            })}
          </p>
          <p data-testid="projectName">
            {t('DesignService.projectName', {
              projectName: serviceRequest?.project?.name ?? '',
            })}
          </p>
        </StyledSectionWrapper>
      </>

      <StyledSectionWrapper>
        <StyledSubtitle>{t('DesignService.selectCreditAmount')}</StyledSubtitle>
        <Input
          placeholder={t('AccountManager.enterCreditAmount') ?? ''}
          type="number"
          min="0"
          step="10"
          onKeyDown={(e) => {
            if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            if (isNaN(Number(e.clipboardData.getData('Text')))) {
              e.preventDefault();
            }
          }}
          className={`${validateStatus(
            touched,
            errors,
            CHARGE_EXTRA_CREDITS_FORM_CONTROLS.CREDIT_COUNT
          )}`}
          id="quantity"
          {...getFieldProps(CHARGE_EXTRA_CREDITS_FORM_CONTROLS.CREDIT_COUNT)}
        />
        <div className="Form-Error">
          {renderValidationMessage(
            touched,
            errors,
            CHARGE_EXTRA_CREDITS_FORM_CONTROLS.CREDIT_COUNT
          )}
        </div>
      </StyledSectionWrapper>

      <StyledSectionWrapper>
        <StyledSubtitle>
          {t('DesignService.memoExtraCharge')}{' '}
          <StyledSpan>{t('DesignService.memoDisclaimer')}</StyledSpan>
        </StyledSubtitle>
        <Input
          placeholder={t('DesignService.memoPlaceholder')}
          className={`${validateStatus(touched, errors, CHARGE_EXTRA_CREDITS_FORM_CONTROLS.MEMO)}`}
          id="memo"
          {...getFieldProps(CHARGE_EXTRA_CREDITS_FORM_CONTROLS.MEMO)}
        />
        <div className="Form-Error">
          {renderValidationMessage(touched, errors, CHARGE_EXTRA_CREDITS_FORM_CONTROLS.MEMO)}
        </div>
      </StyledSectionWrapper>

      <StyledButtonsWrapper>
        <Button onClick={toggleModal} className="Button--White" data-testid="cancelButton">
          {t('DesignService.cancel')}
        </Button>
        <Button
          onClick={() => handleSubmit()}
          className="Button--Blue"
          disabled={!isFormValid}
          aria-disabled={!isFormValid}
          data-testid="chargeButton"
        >
          {t('DesignService.charge')}
        </Button>
      </StyledButtonsWrapper>
    </StyledModal>
  );
};

export default ChargeExtraCreditsModal;
