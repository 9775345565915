import { Dispatch, SetStateAction } from 'react';

import { MaintenanceReport } from '@cpm/scanifly-shared-data';
import cn from 'classnames';
import moment from 'moment';

import { shortDateFormat } from 'helpers/utils/dateFormat';

import ActionButtons from '../ActionButtons/ActionButtons';
import '../Maintenance.scss';

type Props = {
  report: MaintenanceReport;
  selectedReport?: MaintenanceReport;
  handleCardClick: Function;
  handleRenameButtonClick: () => void;
  setSelectedReportId: Dispatch<SetStateAction<string | null>>;
  toggleDeleteModal: () => void;
};

const ReportCard = ({
  report,
  selectedReport,
  handleCardClick,
  handleRenameButtonClick,
  setSelectedReportId,
  toggleDeleteModal,
}: Props) => {
  const { id, createdAt, title, createdBy } = report || {};
  const isSelected = id === selectedReport?.id;

  return (
    <div
      className={cn('MaintenanceReport-Card', { selected: isSelected })}
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      onClick={() => handleCardClick(id)}
      data-testid="maintenance-report-card"
    >
      <p className="small">{moment.utc(createdAt).format(shortDateFormat)}</p>
      <p className="large" data-testid="maintenance-report-title">
        {title}
      </p>
      <p className="large">{`${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`}</p>
      <div className="small">
        <ActionButtons
          report={report}
          handleRenameButtonClick={handleRenameButtonClick}
          setSelectedReportId={setSelectedReportId}
          toggleDeleteModal={toggleDeleteModal}
        />
      </div>
    </div>
  );
};

export default ReportCard;
