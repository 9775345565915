import styled from 'styled-components';

import {
  CheckMarkFieldType,
  DatePickerFieldType,
  DropdownMenuFieldType,
  FieldTypeEnum,
  FieldTypeUnion,
  MediaFieldType,
  NotApplicableFieldType,
  RadioFieldType,
  ReferenceMediaFieldType,
  TextInputFieldType,
  TimePickerFieldType,
} from '@cpm/scanifly-shared-data';

import colors from 'helpers/constants/colors';

import {
  CheckMark,
  DatePicker,
  DropdownMenu,
  MediaField,
  NotApplicable,
  Radio,
  ReferenceMediaField,
  TextInput,
  TimePicker,
} from './fields';

type FieldCardProps = {
  field: FieldTypeUnion;
};

const Container = styled.div`
  margin-top: 1rem;
  background: ${colors.white};
  padding: 1rem;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: ${colors.midGray};
`;

const FieldSwitch = ({ item }: { item: FieldTypeUnion }) => {
  switch (item.componentType) {
    case FieldTypeEnum.textInput:
      return <TextInput item={item as TextInputFieldType} />;
    case FieldTypeEnum.notApplicable:
      return <NotApplicable item={item as NotApplicableFieldType} />;
    case FieldTypeEnum.dropDown:
      return <DropdownMenu item={item as DropdownMenuFieldType} />;
    case FieldTypeEnum.radio:
      return <Radio item={item as RadioFieldType} />;
    case FieldTypeEnum.media:
      return <MediaField item={item as MediaFieldType} />;
    case FieldTypeEnum.referenceMedia:
      return <ReferenceMediaField item={item as ReferenceMediaFieldType} />;
    case FieldTypeEnum.checkMark:
      return <CheckMark item={item as CheckMarkFieldType} />;
    case FieldTypeEnum.datePicker:
      return <DatePicker item={item as DatePickerFieldType} />;
    case FieldTypeEnum.timePicker:
      return <TimePicker item={item as TimePickerFieldType} />;
    default:
      return null;
  }
};

export const FieldCard = ({ field }: FieldCardProps) => {
  return (
    <Container>
      <FieldSwitch item={field} />
    </Container>
  );
};
