import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';

import { DESIGN_ACTIONS } from '../constants';

export const getTargetStatus = (action?: string) => {
  switch (action) {
    case DESIGN_ACTIONS.completed:
      return DESIGN_STATUS.completed;
    case DESIGN_ACTIONS.dataRequest:
      return DESIGN_STATUS.dataRequest;
    case DESIGN_ACTIONS.designing:
      return DESIGN_STATUS.designInProgress;
    case DESIGN_ACTIONS.dataRefinement:
      return DESIGN_STATUS.dataRefinement;
    case DESIGN_ACTIONS.requestApproval:
      return DESIGN_STATUS.pendingApproval;
    default:
      return undefined;
  }
};
