import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Button, Modal, Spin } from 'antd';
import { isEmpty, isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import MapWrapper from 'screens/MapWrapper';
import DeleteTeammateModal from 'screens/Team/TeamList/DeleteTeammateModal';
import Teammate from 'screens/Team/TeamList/Teammate';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { companyRequested, userCompanyRequested } from 'state/slices/companySlice';
import {
  adminFoldersRequested,
  deleteFolderFromProjectRequested,
  foldersRequested,
  updateFolderForProjectRequested,
  userSuccessfullyUpdatedFolderInProject,
} from 'state/slices/foldersSlice';
import {
  projectRequested,
  userDeleteProjectTeammate,
  userSuccessfullyUpdatedTeammates,
  userUpdatedProjectTeammate,
} from 'state/slices/projectSlice';

import { CustomSelect, MetadataDisplay, Tag } from 'components';

import { DRONE_IMAGES } from 'helpers/constants/categories';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectCategoryRoute,
  draftProjectInfo,
  projectCategoryRoute,
  projectsListRoute,
  rootRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDraftProjectInfoRoute,
  scaniflyAdminProjectCategoryRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-icon.svg';

import RemoteOnsiteModal from '../RemoteOnsiteModal/RemoteOnsiteModal';
import { FOLDER } from './constants';
import './ProjectInfo.scss';

const ProjectInfo = ({ isAdminRoute = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { isScaniflyAdmin, isAdmin, isDesignServiceProvider } = usePermissions();
  const { project, isProjectRequestedLoading, isLoading, isTeammateInProjectUpdatedSuccess } =
    useSelector((state) => state.project);
  const { company } = useSelector((state) => state.company);
  const { folders, isFoldersInProjectUpdatedSuccess } = useSelector((state) => state.folders);
  const { currentUser } = useSelector((state) => state.users);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [nextChosenRoute, setNextChosenRoute] = useState(null);
  const [isProjectRequestedStarted, setIsProjectRequestedStarted] = useState(false);
  const { projectId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [teammateToDelete, setTeammateToDelete] = useState({});
  const [selectedTeammates, setSelectedTeammates] = useState([]);
  const teammatesList = company?.members;
  const [isDesignModalVisible, toggleDesignModal] = useToggle(false);

  const teammatesOptions = teammatesList
    ?.map((member) => ({
      label: `${member.firstName} ${member.lastName}`,
      value: member.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const getFolderOptions = () => {
    const formattedfolders = folders?.map((folder) => ({
      label: folder.name,
      value: folder.id,
    }));

    if (currentUser?.foldersAccess === null || isScaniflyAdmin || isAdmin) {
      return [FOLDER.MY_PROJECTS].concat(formattedfolders);
    }

    if (currentUser?.foldersAccess?.length > 0) {
      return formattedfolders;
    }

    return [];
  };

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectInfo(projectId)
        : scaniflyAdminDraftProjectInfoRoute(projectId),
    }),
  };

  const isDraft =
    project?.statusDescription === PROJECT_STATUSES.DRAFT ||
    project?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;

  useEffect(() => {
    if ((projectId === project?.id && isNewProject && !isDraft) || (!isNewProject && isDraft)) {
      history.push(rootRoute());
    }
  }, [project, history, isDraft, isNewProject, projectId]);

  const isDataChanged =
    !isEqual(project?.teammates, selectedTeammates) ||
    !isEmpty(teammateToDelete) ||
    !isEqual(project?.folder, selectedFolder);

  const isRedirectNeeded =
    (selectedFolder === project?.folder ||
      (!project?.folder && selectedFolder === FOLDER.MY_PROJECTS.label)) &&
    isEqual(project?.teammates, selectedTeammates);

  const pin = {
    location: project?.geolocation || { latitude: null, longitude: null },
    id: '1',
  };

  useEffect(() => {
    dispatch(projectRequested(projectId));
    setIsProjectRequestedStarted(true);
  }, [dispatch, projectId]);

  useEffect(() => {
    if (!project) {
      return;
    }
    if (isAdminRoute && project?.submittedFor) {
      const id = project?.submittedFor?.company?.id;
      dispatch(adminFoldersRequested(id));
      dispatch(companyRequested(id));
    } else {
      dispatch(foldersRequested());
      dispatch(userCompanyRequested());
    }
  }, [dispatch, isAdminRoute, project]);

  useEffect(() => {
    if (project && !isProjectRequestedLoading && isProjectRequestedStarted) {
      setSelectedFolder(project.folder);
      setSelectedTeammates(project.teammates);
    }
  }, [project, projectId, isProjectRequestedLoading, isProjectRequestedStarted]);

  const handleOptionsChange = ({ value }) => {
    if (value === FOLDER.MY_PROJECTS.value) {
      return setSelectedFolder(FOLDER.MY_PROJECTS.label);
    }
    const currentFolder = folders?.filter((folder) => folder.id === value);
    setSelectedFolder(...currentFolder);
  };

  const handleTeammatesOptionsChange = ({ value }) => {
    const teammate = teammatesList?.filter((member) => member.id === value);
    const isAddedTeammate = selectedTeammates.some((member) => member.id === teammate[0].id);

    !isAddedTeammate && setSelectedTeammates([...selectedTeammates, ...teammate]);
  };

  const updateProjectFolder = () => {
    if (
      (!project?.folder && selectedFolder === FOLDER.MY_PROJECTS.label) ||
      (!project?.folder && !selectedFolder)
    ) {
      return;
    }

    dispatch(
      selectedFolder?.id
        ? updateFolderForProjectRequested({
            folderId: selectedFolder.id,
            projectId: project?.id,
          })
        : deleteFolderFromProjectRequested({
            folderId: project?.folder?.id,
            projectId: project?.id,
          })
    );
  };

  const updateProjectTeammates = () => {
    const projectTeammates = project?.teammates;
    const teammatesToDelete = projectTeammates.filter(
      (teammate) => !selectedTeammates.includes(teammate)
    );

    if (teammatesToDelete.length > 0) {
      teammatesToDelete.forEach((teammate) => {
        dispatch(
          userDeleteProjectTeammate({
            projectId: project.id,
            userId: teammate.id,
          })
        );
      });
    }

    selectedTeammates &&
      selectedTeammates.forEach((selectedTeammate) => {
        const isExistingTeammate = projectTeammates?.some(
          (teammate) => teammate.id === selectedTeammate.id
        );

        !isExistingTeammate &&
          dispatch(
            userUpdatedProjectTeammate({
              projectId: project.id,
              userId: selectedTeammate.id,
            })
          );
      });
  };

  useEffect(() => {
    if (
      (isFoldersInProjectUpdatedSuccess || isTeammateInProjectUpdatedSuccess) &&
      isDraft &&
      nextChosenRoute
    ) {
      dispatch(userSuccessfullyUpdatedTeammates());
      dispatch(userSuccessfullyUpdatedFolderInProject());
      history.push(nextChosenRoute);
    }
  }, [
    isFoldersInProjectUpdatedSuccess,
    history,
    nextChosenRoute,
    dispatch,
    isDraft,
    isTeammateInProjectUpdatedSuccess,
  ]);

  const companyId = useMemo(
    () => (isAdminRoute ? project?.submittedFor?.company?.id : project?.companyId),
    [isAdminRoute, project?.companyId, project?.submittedFor?.company?.id]
  );

  useEffect(() => {
    if (companyId) {
      dispatch(companyActiveSubscriptionRequested(companyId));
    }
  }, [companyId, dispatch]);

  const saveFolderAndTeammates = () => {
    updateProjectFolder();
    updateProjectTeammates();
  };

  const handleSaveAndExit = () => {
    saveFolderAndTeammates();
    setNextChosenRoute(isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : rootRoute());

    if (isRedirectNeeded) {
      history.push(isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : projectsListRoute());
    }
  };

  const getDraftRoute = () => {
    return isAdminRoute
      ? scaniflyAdminProjectCategoryRoute(projectId, DRONE_IMAGES)
      : draftProjectCategoryRoute(projectId, DRONE_IMAGES);
  };

  const getProjectNotesRoute = () => {
    return isAdminRoute
      ? scaniflyAdminProjectCategoryRoute(projectId, DRONE_IMAGES)
      : projectCategoryRoute(projectId, DRONE_IMAGES);
  };

  const handleSaveAndContinue = () => {
    if (isDataChanged) {
      saveFolderAndTeammates();
    }
    setNextChosenRoute(isNewProject ? getDraftRoute() : getProjectNotesRoute());

    if (isRedirectNeeded) {
      history.push(getDraftRoute());
    }
  };

  const handleRemoteOnsiteModal = () => {
    toggleDesignModal(!isDesignModalVisible);
    saveFolderAndTeammates();
  };

  const handleUpdate = () => {
    if (isDataChanged) {
      saveFolderAndTeammates();
    }
    history.push(getProjectNotesRoute());
  };

  const handleOpenRemoveTeammateModal = (id) => {
    setTeammateToDelete({ id });
    setModalVisible(true);
  };

  const handleRemoveTeammate = () => {
    setSelectedTeammates((selectedTeammates) =>
      selectedTeammates.filter((teammate) => teammate.id !== teammateToDelete.id)
    );
  };

  const renderTeamMates = () =>
    isProjectRequestedLoading ? (
      <div className="ProjectInfo-Spinner">
        <Spin size="large" />
      </div>
    ) : (
      <div className="ProjectInfo-TeammatesContainer">
        {selectedTeammates?.map((teammate) => (
          <Teammate
            key={teammate.id}
            teammate={teammate}
            isHorizontal
            removeTeammate={handleOpenRemoveTeammateModal}
          />
        ))}
      </div>
    );

  const handleModalClose = () => {
    setModalVisible(false);
    setTeammateToDelete({});
  };

  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        title="Remove teammate"
        {...MODAL_PROPS}
      >
        <DeleteTeammateModal
          teammate={selectedTeammates.filter((teammate) => teammate.id === teammateToDelete.id)[0]}
          handleModalClose={handleModalClose}
          handleDelete={handleRemoveTeammate}
        />
      </Modal>
      <RemoteOnsiteModal
        isDesignModalVisible={isDesignModalVisible}
        handleModalClose={handleModalClose}
        toggleDesignModal={toggleDesignModal}
        handleSaveAndContinue={handleSaveAndContinue}
        newProject={true}
      />
      <MapWrapper isClickable={false} pins={[pin]}>
        <div className="ProjectInfo">
          {isAdminRoute ? (
            <div className="ProjectInfo-SubmittedFor">
              {project?.submittedFor &&
                `Submitted for: ${project.submittedFor.firstName} ${project.submittedFor.lastName} at ${project.submittedFor.company.name}`}
            </div>
          ) : null}
          <h2 className="ProjectDesigns-ProjectName">{project?.name}</h2>
          <div
            className="ProjectInfo-SelectWrapper"
            data-testid="add-teammate-select-wrapper"
            id="teammate-select-wrapper"
          >
            <CustomSelect
              placeholder="Add Teammate"
              options={teammatesOptions}
              onChange={handleTeammatesOptionsChange}
              variant="filter"
              aria-label="add teammate"
              tall
              id="add-teammate-dropdown"
            />
            <span className="ProjectInfo-Label">{t('ProjectInfo.addTeammate')}</span>
          </div>
          {renderTeamMates()}

          <div className="ProjectInfo-SelectWrapper" id="folder-select-wrapper">
            <CustomSelect
              placeholder="Change Folder"
              options={getFolderOptions()}
              onChange={handleOptionsChange}
              value={selectedFolder?.id || null}
              variant="filter"
              aria-label="change folder"
              id="change-folder-dropdown"
              tall
            />
            <span className="ProjectInfo-Label">{t('ProjectInfo.addToFolder')}</span>
          </div>
          {selectedFolder?.id && (
            <Tag maxTextLength={30} color={selectedFolder?.color}>
              <div className="TagContent">
                <FolderIcon className="FolderIcon" />
                {selectedFolder?.name}
                <XIcon
                  className="XIcon"
                  onClick={() => handleOptionsChange([FOLDER.MY_PROJECTS.value])}
                  aria-label="remove folder"
                />
              </div>
            </Tag>
          )}
          <MetadataDisplay metadata={project?.externalMetadata ?? {}} />
          <div className="ProjectInfo-Buttons">
            <div className="ProjectInfo-Buttons-Wrapper">
              {isDraft ? (
                <>
                  <Button onClick={handleSaveAndExit} className="Button--White">
                    {t('buttonTexts.saveAndExit')}
                  </Button>
                  <Button onClick={handleRemoteOnsiteModal} className="Button--Blue">
                    {t('buttonTexts.saveAndContinue')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="Button--Blue CustomerInfo-Form-Buttons-Submit"
                    onClick={handleUpdate}
                    loading={isLoading}
                    disabled={isDesignServiceProvider}
                    aria-disabled={isDesignServiceProvider}
                  >
                    {t('buttonTexts.update')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </MapWrapper>
    </>
  );
};

ProjectInfo.propTypes = {
  isAdminRoute: PropTypes.bool,
};

export default ProjectInfo;
