/*eslint-disable no-useless-escape*/
import _clone from 'lodash/clone';
import _escapeRegExp from 'lodash/escapeRegExp';

export const focusTextarea = (ref) => {
  ref.current?.focus({ cursor: 'end' });
};

export const insertEmojiAtCursorPos = (value, position, emoji) => {
  if (!position || position === value.length - 1) {
    return value + emoji;
  }
  return value.substring(0, position) + emoji + value.substring(position);
};

export function swapTags(text) {
  let displayText = _clone(text);
  const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
  tags.map((myTag) => {
    const tagData = myTag.slice(3, -2);
    const tagDataArray = tagData.split('||');
    const tagDisplayValue = tagDataArray[2];
    return (displayText = displayText.replace(
      new RegExp(_escapeRegExp(myTag), 'gi'),
      tagDisplayValue
    ));
  });
  return displayText;
}

export function swapComment(text) {
  let displayText = _clone(text);
  const tags = text.match(/@\{\{[^\}]+\}\}/gi) || [];
  tags.map((myTag) => {
    const tagData = myTag.slice(3, -2);
    const tagDataArray = tagData.split('||');
    const tagDisplayValue = `<a>${tagDataArray[2]}</a>`;
    return (displayText = displayText.replace(
      new RegExp(_escapeRegExp(myTag), 'gi'),
      tagDisplayValue
    ));
  });
  return displayText;
}
