import type { Uppy, UppyFile } from '@uppy/core';
import { BasePlugin } from '@uppy/core';

export type UploadManifestPluginOptions = {
  id?: string;
  generateManifest?: (files: UppyFile[]) => Promise<string>;
};

export default class UploadManifestPlugin extends BasePlugin {
  opts?: UploadManifestPluginOptions;

  constructor(uppy: Uppy, opts?: UploadManifestPluginOptions) {
    super(uppy, opts);

    this.opts = opts;
    this.id = this.opts?.id || 'UploadManifestPlugin';
    this.type = 'modifier';
  }

  prepareUpload = async (fileIds: string[]) => {
    if (typeof this.opts?.generateManifest === 'function') {
      const files = fileIds.map((fileId) => {
        const file = this.uppy.getFile(fileId);
        this.uppy.emit('preprocess-progress', file, {
          mode: 'indeterminate',
          message: 'Generating upload manifest',
        });
        return file;
      });

      const manifestId = await this.opts.generateManifest(files);

      for (const file of files) {
        this.uppy.setFileMeta(file.id, {
          upload_manifest_id: manifestId,
        });
      }
    }

    // This is an Uppy pattern intended to prevent, relevant comment below
    // Why emit `preprocess-complete` for all files at once, instead of
    // above when each is processed?
    // Because it leads to StatusBar showing a weird “upload 6 files” button,
    // while waiting for all the files to complete pre-processing.
    for (const fileId of fileIds) {
      const file = this.uppy.getFile(fileId);
      this.uppy.emit('preprocess-complete', file);
    }
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}
