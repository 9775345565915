import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FormFields, FormValues } from './types';

export const validationSchema = Yup.object().shape({
  [FormFields.projectName]: Yup.string()
    .required('Project name is required')
    .test(...noWhitespaces),
  [FormFields.email]: Yup.lazy((value) =>
    !value || !value?.length ? Yup.string() : Yup.string().email('Enter valid email')
  ),
  [FormFields.address]: Yup.string()
    .required('Address is required')
    .test(...noWhitespaces),
});

export const isCoordsProvided = (values: FormValues) => !!values.latitude && !!values.longitude;
