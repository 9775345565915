import { ReactElement } from 'react';

const PermissionGatedButton = ({
  permission,
  action,
  children,
}: {
  permission: boolean;
  action: () => void;
  children: ReactElement;
}) => {
  if (!permission) {
    return (
      <div role="button" onClick={action} onKeyDown={() => {}} tabIndex={0}>
        {children}
      </div>
    );
  }
  return children;
};

export default PermissionGatedButton;
