import { ReactComponent as WarningIcon } from 'assets/icons/triangle-exclamation-solid.svg';
import { accountCompanyInfoRoute } from 'helpers/constants/routes';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import InternalScrollableLink from './InternalScrollableLink';

const StyledWarningIcon = styled(WarningIcon)`
  margin: 0 1rem 0 2rem;
  width: 4rem;
`;

export const UpdateSettingsDisclaimer = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const companySettingsRoute = accountCompanyInfoRoute();

  return (
    <>
      <StyledWarningIcon data-testid="warning-icon" />
      <div>
        <Trans
          i18nKey="DesignServicesAutomationToggles.updateGlobalSettings"
          components={[
            <InternalScrollableLink
              route={companySettingsRoute}
              text={t('DesignServicesAutomationToggles.seeThisLink')}
              hash={'#design-services-settings'}
            />,
          ]}
        />
      </div>
    </>
  );
};
