import { FORM_CONTROLS_MODEL } from 'screens/DesignServices/constants';
import * as Yup from 'yup';

export const revisionValidationSchema = () => {
  const schema = {
    [FORM_CONTROLS_MODEL.INCORRECT_OBSTRUCTION_MODELING]: Yup.boolean(),
    [FORM_CONTROLS_MODEL.INCORRECT_ROOF_MODELING]: Yup.boolean(),
    [FORM_CONTROLS_MODEL.INCORRECT_SHADE_ANALYSIS]: Yup.boolean(),
    [FORM_CONTROLS_MODEL.MISSING_TREES]: Yup.boolean(),
    [FORM_CONTROLS_MODEL.AHJ_REJECTION]: Yup.boolean(),
    [FORM_CONTROLS_MODEL.NOTE]: Yup.string(),
    [FORM_CONTROLS_MODEL.OTHER]: Yup.boolean(),
  };
  return Yup.object().shape(schema);
};
