import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Project } from 'types';
import { ProjectFilter, TableParams } from 'types/TableParams';

import {
  adminUserNameRequested,
  adminUserProjectsRequested,
} from 'state/slices/admin/adminProjectsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterByForProjects } from 'screens/DesignServices/helpers/getFilterBy';

import BaseProjects from './BaseProjects';

const UserProjects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useParams<{ userId: string }>();
  const { allUserProjects, totalUserProjects, userName } = useSelector(
    (state: RootState) => state.adminProjects
  );
  const [searchText, setSearchText] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams<Project, ProjectFilter>>({
    pagination: {
      current: 1,
    },
  });

  const extraProps = {
    filterQuery: 'Company Name',
    companyName: userName,
  };

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};

    dispatch(
      adminUserProjectsRequested({
        userId,
        size: PROJECT_LIST_SIZE,
        pageIndex: pagination?.current ?? 1,
        sortBy: getSortBy(columnKey, order),
        filterBy: getFilterByForProjects(filters, searchText),
      })
    );
    dispatch(adminUserNameRequested(userId));
  }, [userId, dispatch, searchText, tableParams]);

  return (
    <BaseProjects
      tableName={TABLE_NAMES.USER_PROJECTS}
      isCustomerOrUserProjectsTable={true}
      baseData={{ data: allUserProjects, projectCount: totalUserProjects ?? 0 }}
      setTableParams={setTableParams}
      setSearchText={setSearchText}
      extraProps={extraProps}
      userId={userId}
    />
  );
};

export default UserProjects;
