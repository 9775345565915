import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { companyRequested } from 'state/slices/companySlice';
import { serviceRequestsRequested } from 'state/slices/designServices/designServiceRequestsSlice';
import { settingsRequested } from 'state/slices/designServices/designServicesSettingsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { designStatusRoute, orderDesignRoute, projectsListRoute } from 'helpers/constants/routes';
import { getDesignStatusStep } from '../helpers/getDesignStatusStep';

export const OrderDesignLanding = () => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { serviceRequests, incompleteServiceRequest, isLoading, requestId } = useSelector(
    (state: RootState) => state.designServiceRequests
  );
  const componentRequestId = useMemo(() => uuidv4(), []);
  const { project, status } = incompleteServiceRequest || {};
  const { status: projectStatus } = project || {};

  const step = getDesignStatusStep({ projectStatus, status });

  useEffect(() => {
    if (projectId) {
      dispatch(serviceRequestsRequested({ projectId, requestId: componentRequestId }));
    }
  }, [componentRequestId, dispatch, projectId]);

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { company } = useSelector((state: RootState) => state.company);
  const { settingsData } = useSelector((state: RootState) => state.designServicesSettings);

  useEffect(() => {
    if (!company && currentUser?.companyId) {
      dispatch(companyRequested(currentUser.companyId));
    }
  }, [company, currentUser?.companyId, dispatch]);

  useEffect(() => {
    if (currentUser?.companyId && settingsData === null && !isLoading) {
      dispatch(settingsRequested({ companyId: currentUser.companyId }));
    }
  }, [currentUser?.companyId, dispatch, isLoading, settingsData]);

  const designServicesAccess = getFeatureAvailability(
    false,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  if (isLoading || requestId !== componentRequestId || !serviceRequests || !company) {
    return <Spin size="large"></Spin>;
  } else {
    if (!designServicesAccess) {
      return <Redirect to={projectsListRoute()} />;
    }
    if (serviceRequests && serviceRequests.length !== 0) {
      return <Redirect to={designStatusRoute(projectId, String(step))} />;
    }
    return <Redirect to={orderDesignRoute(projectId)} />;
  }
};

export default OrderDesignLanding;
