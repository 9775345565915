import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import { Card, Radio, RadioChangeEvent, Tooltip } from 'antd';
import cn from 'classnames';
import TooltipWrapper from 'screens/TooltipWrapper/TooltipWrapper';
import { ProjectDesignData } from 'types';

import { DropdownMenu, MenuMini } from 'components';
import { ImageFallback } from 'components/ImageFallback/ImageFallback';

import usePermissions from 'helpers/hooks/usePermissions';
import useS3D from 'helpers/hooks/useS3D';
import useToggle from 'helpers/hooks/useToggle';
import { formatDate } from 'helpers/utils/dateFormat';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/newtab-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';

import { MIN_DESIGNS, MODALS, VIEWS } from '../constants';
import './DesignTile.scss';

const { Meta } = Card;
const { Group } = Radio;

const viewOptions = [
  { label: '3D', value: VIEWS.THREE_D },
  { label: 'Info', value: VIEWS.INFO },
];

const DesignTile = ({
  design,
  projectId,
  thumbnailUrl,
  isSelected,
  selectedView,
  onSelect,
  usage,
  onViewChange,
  setModalVisible,
  projectDesigns = [],
  hasUserReachedDesignThreshold,
  id,
}: {
  design: ProjectDesignData;
  projectId: string;
  thumbnailUrl: string;
  isSelected: boolean;
  selectedView: string;
  onSelect: (designId: string) => void;
  usage?: number;
  onViewChange: Dispatch<SetStateAction<VIEWS>>;
  setModalVisible: Dispatch<SetStateAction<string>>;
  projectDesigns?: ProjectDesignData[];
  hasUserReachedDesignThreshold?: boolean;
  id: string;
}) => {
  const history = useHistory();
  const { hasScanifly3DPermissions, isSimpleDesignOrSalesManager } = usePermissions();
  const [isMenuOpen, toggleMenu] = useToggle();
  const s3d = useS3D();
  const totalDesigns = projectDesigns?.length ?? 0;
  const onsiteDesignCount = projectDesigns
    .map((design) => design.remote)
    .filter((type) => type !== true).length;
  const isLastOnsiteDesign = !design.remote && onsiteDesignCount <= 1;
  const { t } = useTranslation();

  const handleViewChange = (event: RadioChangeEvent) => {
    const { value: selectedView } = event.target;
    onViewChange(selectedView);
    if (!isSelected) {
      onSelect(design.id ?? '');
    }
  };

  const handleMenuOpen = () => {
    onSelect(design.id ?? '');
    toggleMenu();
  };

  const handleSelect = (id: string) => {
    onSelect(id);
    if (hasScanifly3DPermissions) {
      history.push(s3d(projectId, design.id ?? ''));
    }
  };

  const handleNewTabSelect = (id: string) => {
    onSelect(id);
    if (hasScanifly3DPermissions) {
      window.open(s3d(projectId, design.id ?? ''), '_blank');
    }
  };

  const getSystemOffSet = () => {
    // handle null and 0 values so no dividing by 0
    if (!design.annualProduction) return 0;
    if (!design.annualConsumption) return 0;

    const offSet = (design.annualProduction / design.annualConsumption) * 100;

    return offSet.toFixed(0).toString();
  };

  const isUpdated = design.createdAt !== design.updatedAt;
  const dateToDisplay = {
    label: isUpdated ? 'Updated' : 'Created',
    date: isUpdated ? design.updatedAt : design.createdAt,
  };

  const Dropdown = () => (
    <>
      <li>
        <button onClick={() => setModalVisible(MODALS.RENAME_DESIGN)} className="DropdownMenu-Item">
          <PencilIcon />
          {t('DesignTile.rename')}
        </button>
      </li>
      <li>
        <button
          onClick={() => setModalVisible(MODALS.DUPLICATE_DESIGN)}
          className="DropdownMenu-Item"
          disabled={hasUserReachedDesignThreshold}
          aria-disabled={hasUserReachedDesignThreshold}
        >
          <CopyOutlined />
          {t('DesignTile.duplicate')}
        </button>
      </li>
      <li>
        <Tooltip
          placement="top"
          title={totalDesigns === MIN_DESIGNS && t('DesignTile.cannotDelete')}
          arrowPointAtCenter
        >
          <button
            onClick={() => setModalVisible(MODALS.CONFIRM_DELETE_DESIGN)}
            className="DropdownMenu-Item"
            disabled={
              !hasScanifly3DPermissions || totalDesigns === MIN_DESIGNS || isLastOnsiteDesign
            }
            aria-disabled={
              !hasScanifly3DPermissions || totalDesigns === MIN_DESIGNS || isLastOnsiteDesign
            }
          >
            <CancelIcon />
            {t('DesignTile.delete')}
          </button>
        </Tooltip>
      </li>
      <li>
        <button onClick={() => handleNewTabSelect(design.id ?? '')} className="DropdownMenu-Item">
          <NewTabIcon />
          {t('DesignTile.newTab')}
        </button>
      </li>
    </>
  );

  return (
    <div className="DesignTile-Wrapper">
      <Tooltip placement="bottom" title={'Click to Design'} arrowPointAtCenter>
        <div className="DesignTile">
          <Card
            className={cn('DesignTile-Card', {
              'DesignTile-Card--Selected': isSelected,
            })}
            id={id}
            key={design.id}
            hoverable
            cover={
              <ImageFallback
                alt="model thumbnail"
                src={
                  design.thumbnailUrl
                    ? `${design.thumbnailUrl}?${design.updatedAt}` // force refresh thumbnail
                    : thumbnailUrl
                }
                fallbackSrc={thumbnailUrl} // if error saving thumbnail img in design - which could happen, this will fallback nicely
              />
            }
            onClick={() => handleSelect(design.id ?? '')}
          >
            {/* stopPropagation prevents the event from bubbling up to the card on click */}
            <div
              onClick={(e) => e.stopPropagation()}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => e.stopPropagation()}
            >
              {!isSimpleDesignOrSalesManager && (
                <MenuMini onMouseDown={(e) => e.stopPropagation()} onClick={handleMenuOpen} />
              )}
              {isMenuOpen && (
                <DropdownMenu toggleMenu={toggleMenu}>
                  <Dropdown />
                </DropdownMenu>
              )}
            </div>
            <Meta
              title={
                <div className="titleWrapper">
                  <TooltipWrapper text={design.name} className="DesignTile-Name" />
                </div>
              }
              description={
                <>
                  <p className="DesignTile-Info">
                    {t('DesignTile.systemSize')}:
                    <span> {design.systemSize ? design.systemSize.toFixed(3) : '0'}kW DC STC</span>
                  </p>
                  <p className="DesignTile-Info">
                    {t('DesignTile.annualSolarAccess')}:
                    <span> {design.ASA ? design.ASA.toFixed(0) : '0'}%</span>
                  </p>
                  <p className="DesignTile-Info">
                    {t('DesignTile.annualProduction')}:
                    <span>
                      {' '}
                      {design.annualProduction
                        ? Math.round(design.annualProduction).toLocaleString()
                        : '0'}
                      kWh
                    </span>
                  </p>
                  <p className="DesignTile-Info">
                    {t('DesignTile.annualConsumption')}:
                    <span> {usage ? parseInt(String(usage)).toLocaleString() : '0'}kWh</span>
                  </p>
                  <p className="DesignTile-Info">
                    {t('DesignTile.PVSystemOffset')}:<span> {getSystemOffSet()}%</span>
                  </p>
                  <p className="DesignTile-Date">
                    {dateToDisplay.label}: {formatDate(dateToDisplay.date)}
                  </p>
                </>
              }
            />
            {/* stopPropagation prevents the event from bubbling up to the card on click */}
            <div className="DesignTile-Footer" role="button" tabIndex={0}>
              <div
                onClick={(e) => e.stopPropagation()}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Group
                  options={viewOptions}
                  onChange={handleViewChange}
                  value={isSelected && selectedView}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </div>
          </Card>
        </div>
      </Tooltip>
    </div>
  );
};

export default DesignTile;
