import { useTranslation } from 'react-i18next';

import { GoBackButton } from 'components';

import { ReactComponent as Box } from 'assets/box.svg';
import { ReactComponent as Dropbox } from 'assets/dropbox.svg';
import { ReactComponent as GDrive } from 'assets/g-drive.svg';

import { INTEGRATIONS } from './constants';
import IntegrationCard from './IntegrationCard/IntegrationCard';
import './Integrations.scss';
import { IIntegrationIcons } from './types';

/*
  To add a new integration, add the name of the integration to the INTEGRATIONS array in constants.ts
  consistant with the backend endpoint for that integration, integration name to translations
  and add the icon to the ICONS object below.
*/

const Integrations = () => {
  const { t } = useTranslation();

  const ICONS: IIntegrationIcons = { gdrive: <GDrive />, dropbox: <Dropbox />, box: <Box /> };

  return (
    <div className="Integrations">
      <GoBackButton />
      <div className="Integrations-wrapper">
        <div className="card-container">
          {INTEGRATIONS.map((integration) => (
            <div className="card" key={integration}>
              <IntegrationCard
                icon={ICONS[integration]}
                integration={{
                  name: t(`Integrations.${integration}.name`),
                  api: integration,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
