import {
  CREDIT_TRANSACTION_CATEGORY,
  CREDIT_TRANSACTION_CATEGORY_DESCRIPTION,
} from '@cpm/scanifly-shared-data';

import colors from 'helpers/constants/colors';

export const getTagTitleAndColor = (
  category: CREDIT_TRANSACTION_CATEGORY
): { title: string; color: string } => {
  if (category === CREDIT_TRANSACTION_CATEGORY.order) {
    return { title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.order, color: colors.lightBlue };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.subscriptionDeposit) {
    return {
      title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.subscriptionDeposit,
      color: colors.purple,
    };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.subscriptionUpdate) {
    return {
      title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.subscriptionUpdate,
      color: colors.mainBlue,
    };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.oneOffPurchase) {
    return { title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.oneOffPurchase, color: colors.teal };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.refund) {
    return { title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.refund, color: colors.red };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.expiration) {
    return { title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.expiration, color: colors.green };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.nearmaps) {
    return { title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.nearmaps, color: colors.orange };
  } else if (category === CREDIT_TRANSACTION_CATEGORY.manualAdjustment) {
    return {
      title: CREDIT_TRANSACTION_CATEGORY_DESCRIPTION.manualAdjustment,
      color: colors.yellow,
    };
  }
  return { title: 'Unknown', color: colors.gray };
};
