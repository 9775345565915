import { newUserInvited } from 'state/slices/authSlice';

import { openNotification } from '../../../helpers/utils/openNotification';

import { ROLE } from '@cpm/scanifly-shared-data';
import { OPTIONS } from '../constants';

export const inviteNewUser = ({
  dispatch,
  companiesNotPopulated,
  values,
  currentPermissions,
  selectedPermissionsWithoutAdminTools,
  projectAccess,
}) => {
  const { ADMIN_TOOLS } = OPTIONS;
  const company = companiesNotPopulated.find((company) => company.id === values.companyId);
  const newTeammate = {
    email: values.email,
    roles: [currentPermissions.includes(ADMIN_TOOLS.value) ? ROLE.admin : ROLE.default],
    permissions: selectedPermissionsWithoutAdminTools,
    foldersAccess: projectAccess.folderIds,
    ...(values.companyId && { companyId: values.companyId }),
    ...(company && { companyName: company.name }),
  };

  dispatch(newUserInvited(newTeammate));

  openNotification({
    type: 'success',
    title: 'Account Invite Sent!',
    text: company
      ? `Your invite to join ${company.name} has been sent to ${values.email}.`
      : `An invitation has been sent to ${values.email}.`,
  });
};
