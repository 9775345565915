import styled from 'styled-components';

const BadgeBorder = styled.div`
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: ${(props) => props.color};
  z-index: 10;
  position: absolute;
  top: 12px;
  right: 9px;
`;

const BadgeContent = styled.p`
  color: white;
  zindex: 11;
  font-size: 9px;
  font-weight: 400;
`;

type BadgeProps = {
  color?: string;
  number: number;
};

function Badge({ color = '#ff4d4d', number }: BadgeProps) {
  if (!number) return null;
  return (
    <BadgeBorder color={color}>
      <BadgeContent>{number}</BadgeContent>
    </BadgeBorder>
  );
}

export default Badge;
