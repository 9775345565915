import { capitalize } from 'lodash';
import moment from 'moment';

import { USER_STATUS } from 'helpers/constants/userStatus';
import { csvDateFormat } from 'helpers/utils/dateFormat';
import { openNotification } from 'helpers/utils/openNotification';

export const createNotification = (targetStatus) => {
  switch (targetStatus) {
    case USER_STATUS.INACTIVE:
      return openNotification({
        type: 'error',
        title: 'Account Deactivated!',
        text: 'This account has been deactivated.',
      });
    case USER_STATUS.FROZEN:
      return openNotification({
        type: 'error',
        title: 'Account Frozen!',
        text: 'This account has been frozen.',
      });
    case USER_STATUS.ACTIVE:
      return openNotification({
        type: 'success',
        title: 'Account Reactivated!',
        text: 'The user accounts that are connected to this account are reactivated. Please use the Users page to edit user permissions.',
      });
    default:
      return openNotification({
        type: 'success',
        title: 'Account Activated!',
        text: 'This account has been activated.',
      });
  }
};

export const handleAccountManagerCSVExport = ({ dataSource }) => {
  return dataSource.map((item) => ({
    ...item,
    accountHolder: item.accountHolder
      ? `Name: ${item.accountHolder?.firstName} ${item.accountHolder?.lastName}\nEmail: ${item.accountHolder?.email}\nPhone: ${item.accountHolder?.phone}\nPosition: ${item.accountHolder?.positionDescription}`
      : 'Not Available',
    actStatus: item.accountHolder ? capitalize(item.accountHolder.status) : '-',
    createdAt: moment(item.createdAt).format(csvDateFormat) ?? '-',
    users: item.members?.map((item) => `${item.firstName} ${item.lastName}`).join('\n'),
    address: `${item.address.line}, ${item.address.city}, ${item.address.state}, ${item.address.zip}, ${item.address.country}`,
  }));
};
