import { DATE_OPTIONS } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';

export const filterAccounts = (accounts, searchTerm) =>
  accounts.filter((entry) => {
    return [
      entry.name,
      entry.accountHolder?.status,
      `${entry.address?.line}, ${entry.address?.city}, ${entry.address?.state}, ${entry.address?.zip}, ${entry.address?.country}`,
      `${entry.accountHolder?.firstName} ${entry.accountHolder?.lastName}`,
      new Date(entry.createdAt).toLocaleDateString('en-US', DATE_OPTIONS),
    ].some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()));
  });
