import { Alert } from 'antd';

import { responseStatus } from 'helpers/constants/responseStatus';
import { getNotificationMessage } from '../../helpers/getNotificationMessage';

import { ReactComponent as WarningIcon } from 'assets/icons/circle-exclamation-solid-icon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';

import './Notification.scss';

const Notification = ({
  creditsDebitedIntoAccount,
  response,
}: {
  creditsDebitedIntoAccount?: number | null | undefined;
  response: keyof typeof responseStatus;
}) => {
  const renderAlertMessage = () => {
    return (
      <div className="Notification-Wrapper">
        {response === responseStatus.success ? (
          <TickIcon className="Notification-Icon" />
        ) : (
          <WarningIcon className="Notification-Icon" />
        )}
        <div className="Notification-MessageWrapper">
          <p className="Notification-Title">
            {response === responseStatus.success ? 'Success!' : 'Error!'}
          </p>
          <p className="Notification-Text">
            {getNotificationMessage(creditsDebitedIntoAccount, response)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="Notification">
      <Alert message={renderAlertMessage()} type={response} />
    </div>
  );
};

export default Notification;
