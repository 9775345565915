import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { kebabCase } from 'lodash';
import { getAdminProjectRoute } from 'screens/Projects/ProjectsList/ProjectTile/helpers';
import { mockProjectData } from 'screens/ScaniflyAdmin/constants';
import { Project } from 'types';

import { adminProjectStatusUpdateRequested } from 'state/slices/admin/adminProjectsSlice';
import { formatValues } from 'state/slices/projectSlice';
import { AppDispatch } from 'state/store/store';

import {
  adminCreateProject,
  adminReplaceReprocessedProject,
  fetchProject,
} from 'api/projects/projectsService';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
  PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { isDraft, isErrored, isProcessed, isProcessing } from 'helpers/utils/isProjectCompleted';
import { openNotification } from 'helpers/utils/openNotification';

const ActionDropdown = ({
  project,
  openChangeUserModalForProject,
  openChangeStatusModalForProject,
  tableName,
}: {
  project: Project;
  openChangeUserModalForProject?: (projectId: string) => void;
  openChangeStatusModalForProject?: (projectId: string) => void;
  tableName: string;
}) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const {
    id: projectId,
    name: projectName,
    status: projectStatus,
    statusDescription: projectStatusDescription,
    submittedFor,
    reprocessing,
  } = project || {};

  const isProjectDraft = isDraft(projectStatus);
  const isProjectProcessing = isProcessing(projectStatus);
  const isProjectErrored = isErrored(projectStatus);
  const isProjectProcessed = isProcessed(projectStatus);
  const isAdminProjectProcessed = Boolean(projectStatus === ADMIN_PROJECT_STATUSES.PROCESSED);
  const isProjectCompleted = !(isProjectDraft || isProjectProcessing || isProjectErrored);
  const isProjectCopied = Boolean(reprocessing?.projectId);
  const isProjectDeleted = Boolean(projectStatus === ADMIN_PROJECT_STATUSES.DELETED);
  const isProjectReplaced = Boolean(projectStatus === ADMIN_PROJECT_STATUSES.REPLACED);

  const { id: userId } = submittedFor || {};

  const handleLog = useCallback(() => {
    if (!userId) {
      return;
    }

    window.open(`http://bucket.s3.amazonaws.com/${userId}/${projectId}/results/RealityCapture.log`);
  }, [projectId, userId]);

  const handlePublish = useCallback(() => {
    dispatch(
      adminProjectStatusUpdateRequested({
        projectId,
        targetStatus: NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
        tableName,
      })
    );
  }, [dispatch, projectId, tableName]);

  const handlePreview = useCallback(() => {
    history.push(getAdminProjectRoute(projectId, projectStatus));
  }, [history, projectId, projectStatus]);

  const handleChangeUser = useCallback(() => {
    if (openChangeUserModalForProject) {
      openChangeUserModalForProject(projectId);
    }
  }, [openChangeUserModalForProject, projectId]);

  const handleDelete = useCallback(() => {
    confirm({
      title: 'Delete Project',
      content: 'Are you sure that you want to delete ' + projectName + ' ?',
      okText: 'Delete Project',
      okButtonProps: { style: { background: colors.red } },
      onOk: () =>
        dispatch(
          adminProjectStatusUpdateRequested({
            projectId,
            targetStatus: ADMIN_PROJECT_STATUSES.DELETED,
            tableName,
          })
        ),
      ...CONFIRM_PROPS,
    });
  }, [dispatch, projectId, projectName, tableName]);

  const handleUndelete = useCallback(() => {
    if (openChangeStatusModalForProject) {
      openChangeStatusModalForProject(projectId);
    }
  }, [openChangeStatusModalForProject, projectId]);

  // NOTE: This action creates a copy of the project. It does not trigger an automatic reprocessing event.
  // The values being sent here are going to be overwritten by the backend endpoint
  // We just send them to not make the DTO angry
  const handleReprocess = useCallback(() => {
    confirm({
      title: 'Reprocess Project',
      content: 'This action will create a copy of ' + projectName + '. Do you want to continue?',
      okText: 'Reprocess Project',
      okButtonProps: { style: { background: colors.red } },
      onOk: () =>
        adminCreateProject({ projectId, projectData: formatValues(mockProjectData(projectName)) })
          .then(({ data }) => {
            history.push(getAdminProjectRoute(data.id, ADMIN_PROJECT_STATUSES.REPROCESSING));
          })
          .catch((err) => {
            openNotification({
              type: 'error',
              title: 'Error!',
              text: `Error: ${err.message}`,
            });
          }),
      ...CONFIRM_PROPS,
    });
  }, [history, projectId, projectName]);

  const handleReplace = useCallback(
    async (isReplace = true) => {
      let content = `This action will revert the replacing action for "${projectName}" project. Do you want to continue?`;
      if (isReplace) {
        let copiedProjectName = 'original project';
        if (project.reprocessing?.projectId) {
          const { data } = await fetchProject(project.reprocessing.projectId);
          copiedProjectName = data.name;
        }
        content = `This action will replace the model of the ${copiedProjectName} project with the model of the ${projectName} project. Do you want to continue?`;
      }

      confirm({
        title: `${isReplace ? 'Replace' : 'Revert'} Project`,
        content,
        okText: `${isReplace ? 'Replace' : 'Revert'} Project`,
        okButtonProps: { style: { background: colors.red } },
        onOk: () =>
          adminReplaceReprocessedProject(projectId)
            .then(() => {
              const newProjectStatus = isReplace
                ? ADMIN_PROJECT_STATUSES.REPLACED
                : ADMIN_PROJECT_STATUSES.PROCESSED;
              dispatch(
                adminProjectStatusUpdateRequested({
                  projectId,
                  targetStatus: newProjectStatus,
                  tableName,
                })
              );
            })
            .catch((err) => {
              openNotification({
                type: 'error',
                title: 'Error!',
                text: `Error: ${err.message}`,
              });
            }),
        ...CONFIRM_PROPS,
      });
    },
    [project.reprocessing?.projectId, dispatch, projectId, projectName, tableName]
  );

  const baseActionList = useMemo(
    () => [
      { title: 'Log', actionType: handleLog, isDisabled: !isProjectCompleted },
      {
        title: 'Publish',
        actionType: handlePublish,
        isDisabled:
          !isProjectCompleted || projectStatus === NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
      },
      {
        title: 'Preview',
        actionType: handlePreview,
        isDisabled:
          projectStatusDescription === PROJECT_STATUSES.ERROR ||
          projectStatusDescription === PROJECT_STATUSES.DELETED,
      },
      { title: 'Change User', actionType: handleChangeUser, isDisabled: false },
      { title: 'Delete', actionType: handleDelete, isDisabled: false },
      { title: 'Undelete', actionType: handleUndelete, isDisabled: false },
      {
        title: 'Reprocess',
        actionType: handleReprocess,
        isDisabled: isProjectProcessing || isProjectDraft || isProjectCopied,
      },
      { title: 'Replace', actionType: handleReplace, isDisabled: !isProjectCompleted },
      { title: 'Revert', actionType: () => handleReplace(false), isDisabled: !isProjectCompleted },
    ],
    [
      handleChangeUser,
      handleDelete,
      handleLog,
      handlePreview,
      handlePublish,
      handleReplace,
      handleReprocess,
      handleUndelete,
      isProjectCompleted,
      isProjectCopied,
      isProjectDraft,
      isProjectProcessing,
      projectStatus,
      projectStatusDescription,
    ]
  );

  const createCustomActionList = useCallback(
    (baseActionList: any[]) => {
      if (isProjectDeleted) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Delete');
      }
      // NOTE: Undelete action creates options based on the table.
      // For example, on upload queue, undelete options are admin project statuses
      // On admin console user > projects and account manager > projects the options are user project statuses
      // As the projects table lists every project (admin & user) and we can't distinguish
      // if it is an admin project or not, undelete action is not available on projects table
      if (!isProjectDeleted || tableName === TABLE_NAMES.PROJECTS) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Undelete');
      }
      if (!isAdminProjectProcessed || !userId) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Publish');
      }
      if (!userId) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Change User');
      }
      if (isProjectCopied || !(isProjectProcessed || isProjectErrored)) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Reprocess');
      }
      if (!isProjectCopied || !isProjectCompleted || isProjectReplaced || isProjectDeleted) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Replace');
      }
      if (!isProjectReplaced) {
        baseActionList = baseActionList.filter((item) => item.title !== 'Revert');
      }

      return baseActionList;
    },
    [
      isProjectDeleted,
      tableName,
      isAdminProjectProcessed,
      userId,
      isProjectCopied,
      isProjectProcessed,
      isProjectErrored,
      isProjectCompleted,
      isProjectReplaced,
    ]
  );

  const customActionList = useMemo(
    () => createCustomActionList(baseActionList),
    [baseActionList, createCustomActionList]
  );

  const dropdownMenu = (
    <Menu>
      {customActionList.map(({ title, actionType, isDisabled }) => (
        <Menu.Item
          onClick={actionType}
          key={title}
          disabled={isDisabled}
          id={`option-${kebabCase(title)}-${projectId}`}
        >
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    /* @ts-ignore screw you ant-d */
    <Dropdown overlay={dropdownMenu} trigger={['click']}>
      <Button className="Button--White" id={`project-action-select-${projectId}`}>
        Select <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
