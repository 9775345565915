import { Alert, Button } from 'antd';
import PropTypes from 'prop-types';

import './RetryAlert.scss';

const RetryAlert = ({ error, handleResubmit, isResubmissionLoading }) => {
  return (
    <Alert
      className="RetryAlert"
      message="There was an error during submission."
      showIcon
      description={
        <div className="RetryAlert-Description">
          <span>{`Please retry or contact support if it fails again`}</span>
          <div className="RetryAlert-Description-Buttons">
            <Button
              onClick={handleResubmit}
              loading={isResubmissionLoading}
              className="Button--Blue"
            >
              Resubmit
            </Button>
          </div>
          <span className={'RetryAlert-Description-Error'}>{error}</span>
        </div>
      }
      type="error"
    />
  );
};

RetryAlert.propTypes = {
  error: PropTypes.string.isRequired,
  handleResubmit: PropTypes.func.isRequired,
  isResubmissionLoading: PropTypes.bool.isRequired,
};

export default RetryAlert;
