import { createSlice } from '@reduxjs/toolkit';

const name = 'signUp';

type SignUpState = {
  mainInformation: any;
  billingAddress: any;
  token: string | null;
};

const signUpSlice = createSlice({
  name,
  initialState: {
    mainInformation: null,
    billingAddress: null,
    token: null,
  } as SignUpState,
  reducers: {
    userFilledMainInformationForm: (state, { payload }) => {
      state.mainInformation = payload;
    },
    userFilledBillingAddressForm: (state, { payload }) => {
      state.billingAddress = payload;
    },
    tokenAcquired: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export const { userFilledMainInformationForm, userFilledBillingAddressForm, tokenAcquired } =
  signUpSlice.actions;

export default signUpSlice.reducer;
