import { DESIGN_STATUS, DESIGN_STATUS_FILTERS } from '@cpm/scanifly-shared-data';

import { openNotification } from 'helpers/utils/openNotification';
import { i18n } from 'helpers/utils/translations';

export const getUpdateDesignStatusNotification = ({
  type,
  projectName,
  targetStatus,
  error,
}: {
  type: 'success' | 'error';
  projectName: string;
  targetStatus?: DESIGN_STATUS;
  error?: any;
}) => {
  if (type === 'success' && targetStatus) {
    openNotification({
      type: 'success',
      title: i18n.t('DesignService.pendingApprovalNotificationTitle'),
      text:
        targetStatus === DESIGN_STATUS.pendingApproval
          ? i18n.t('DesignService.pendingApprovalNotificationContent')
          : i18n.t('DesignStatusDisplay.updateStatusSuccess', {
              projectName,
              targetStatus: DESIGN_STATUS_FILTERS.find((item) => item.value === targetStatus)?.text,
            }),
    });
  } else if (type === 'error' && error) {
    openNotification({
      type: 'error',
      title: i18n.t('Generic.error'),
      text: i18n.t('DesignStatusDisplay.updateStatusError', { errorMessage: error.message }),
    });
  }
};
