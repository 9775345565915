import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { projectDesignUpdated, resetSubmittedForm } from 'state/slices/projectDesignsSlice';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { designPropTypes } from 'helpers/props/design';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';
import './RenameDesignModal.scss';

const RenameDesignModal = ({ designs, isModalVisible, handleModalClose, selectedDesign }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isRequestPending, isRequestSuccessful } = useSelector((state) => state.projectDesigns);

  const { touched, isValid, errors, dirty, getFieldProps, handleSubmit, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: validationSchema(designs, selectedDesign.id),
      onSubmit: (values) => {
        dispatch(
          projectDesignUpdated({
            projectId: selectedDesign.projectId,
            designId: selectedDesign.id,
            ...values,
          })
        );
      },
    });

  useEffect(() => {
    setFieldValue(FORM_CONTROLS.NAME, selectedDesign.name);
  }, [setFieldValue, selectedDesign, isModalVisible]);

  const handleModalVisibility = useCallback(() => {
    handleModalClose();
  }, [handleModalClose]);

  useEffect(() => {
    if (isRequestSuccessful) {
      handleModalVisibility();
      dispatch(resetSubmittedForm());
    }
    return () => {
      resetForm();
    };
  }, [dispatch, handleModalVisibility, isRequestSuccessful, resetForm]);

  const isFormValid = isValid && dirty;

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleModalVisibility}
      className="RenameDesignModal"
      {...MODAL_PROPS}
    >
      <span className="RenameDesignModal-Title">
        {t('RenameDesignModal.renameDesign')}: {selectedDesign.name}
      </span>
      <form onSubmit={handleSubmit}>
        <div className="FormControl-Wrapper">
          <Input
            defaultValue={selectedDesign.name}
            className={validateStatus(touched, errors, FORM_CONTROLS.NAME, dirty)}
            type={FORM_CONTROLS.NAME}
            aria-label={t('RenameDesignModal.ariaLabel')}
            {...getFieldProps(FORM_CONTROLS.NAME)}
            autoFocus
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.NAME, dirty)}
          </div>
        </div>
        <div className="RenameDesignModal-ButtonWrapper">
          <Button onClick={handleModalClose} className="Button--White">
            {t('RenameDesignModal.cancel')}
          </Button>
          <Button
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            htmlType="submit"
            className="Button--Blue"
            loading={isRequestPending}
          >
            {t('RenameDesignModal.button')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

RenameDesignModal.propTypes = {
  designs: PropTypes.arrayOf(designPropTypes).isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  selectedDesign: designPropTypes.isRequired,
};

export default RenameDesignModal;
