import { Button, Row } from 'antd';

import { mapCountryCodeToName } from 'helpers/utils/mapCountryNameToCode';

import AccountPaymentsCreditCardForm from '../AccountPaymentsCreditCardForm';
import { getCardData } from './getCardData';
import { renderCardLogo } from './renderCardLogo';

export const renderCCDisplay = ({
  companyBillingInfo,
  toggleCreditCardFormVisible,
  isCreditCardFormVisible,
  handleCCFormVisibility,
}) => {
  const country =
    mapCountryCodeToName(companyBillingInfo?.creditCardAddress?.country) ??
    companyBillingInfo?.creditCardAddress?.country;
  const { firstName, lastName, creditCardAddress } = companyBillingInfo || {};
  const { line, city, state, zip } = creditCardAddress || {};

  if (isCreditCardFormVisible) {
    return (
      <AccountPaymentsCreditCardForm
        companyBillingInfo={companyBillingInfo}
        toggleForm={toggleCreditCardFormVisible}
      />
    );
  } else {
    return (
      <>
        <div className="AccountPayments-CardInfo">
          {renderCardLogo(companyBillingInfo)}
          <div className="AccountPayments-CardInfo-Message">
            {companyBillingInfo ? null : <p>Please add your payment info</p>}
            <p>{getCardData(companyBillingInfo)}</p>
          </div>
        </div>

        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div
              className="AccountPayments-Title"
              aria-label="Credit Card Info Form firstname field"
            >
              First Name
            </div>
            <span className="AccountPayments-Data">{firstName ? firstName : '-'}</span>
          </div>
          <div className="AccountPayments-Wrapper">
            <div
              className="AccountPayments-Title"
              aria-label="Credit Card Info Form lastname field"
            >
              Last Name
            </div>
            <div className="AccountPayments-Data">{lastName ? lastName : '-'}</div>
          </div>
        </Row>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Credit Card Info Form country field">
              Country
            </div>
            <span className="AccountPayments-Data">{country ? country : '-'}</span>
          </div>

          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Credit Card Info Form street field">
              Street
            </div>
            <span className="AccountPayments-Data">{line ? line : '-'}</span>
          </div>
        </Row>
        <Row className="AccountPayments-Row">
          <div className="AccountPayments-Wrapper">
            <div className="AccountPayments-Title" aria-label="Credit Card Info Form city field">
              City
            </div>
            <span className="AccountPayments-Data">{city ? city : '-'}</span>
          </div>

          {country === 'United States' ? (
            <div className="AccountPayments-Wrapper">
              <div className="AccountPayments-Title" aria-label="Credit Card Info Form state field">
                State
              </div>
              <span className="AccountPayments-Data">{state ? state : '-'}</span>
            </div>
          ) : (
            <div className="AccountPayments-Wrapper">
              <div
                className="AccountPayments-Title"
                aria-label="Credit Card Info Form zipcode field"
              >
                ZIP Code
              </div>
              <span className="AccountPayments-Data">{zip ? zip : '-'}</span>
            </div>
          )}
        </Row>
        {country === 'United States' ? (
          <Row className="AccountPayments-Row">
            <div className="AccountPayments-Wrapper">
              <div
                className="AccountPayments-Title"
                aria-label="Credit Card Info Form zipcode field"
              >
                ZIP Code
              </div>
              <span className="AccountPayments-Data">{zip ? zip : '-'}</span>
            </div>
          </Row>
        ) : null}

        <div className="AccountPayments-ButtonWrapper">
          <Button
            onClick={handleCCFormVisibility}
            className="Button--Blue AccountPayments-ChangeCreditCard"
            data-testid="update-payment-info-button"
          >
            Update Payment Info
          </Button>
        </div>
      </>
    );
  }
};
