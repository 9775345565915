import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';

export const getAdditionalOptions = (options) => {
  //
  // pushes additional, past project types along with 'small' and 'large' options
  // (this helper can be removed if we update past project types in our db)
  //
  if (options.includes(PROJECT_TYPES.SMALL)) {
    options.push(PROJECT_TYPES.RESIDENTIAL, PROJECT_TYPES.SMALL_COMMERCIAL);
  }
  if (options.includes(PROJECT_TYPES.LARGE)) {
    options.push(PROJECT_TYPES.COMMERCIAL, PROJECT_TYPES.UTILITY);
  }
  return options;
};
