import flatten from 'lodash/flatten';
import { FILTER } from 'screens/Projects/ProjectsList/constants';

const filterByDate = (date) => {
  return (
    date &&
    (date.on || (date.from && date.to)) && {
      [date.type ?? 'createdAt']: {
        ...(date.range === FILTER.ON && { eq: date.on }),
        ...(date.from && date.to && { gte: date.from, lte: date.to }),
      },
    }
  );
};
const filterByBounds = (bounds) => {
  return (
    bounds &&
    bounds._ne &&
    bounds._sw && {
      latitude: { gte: bounds._sw.lat, lte: bounds._ne.lat },
      longitude: { gte: bounds._sw.lng, lte: bounds._ne.lng },
    }
  );
};
const filterByFolder = (folder) => {
  return folder?.length && { folderName: folder };
};
const filterByLocation = (location) => {
  return location?.length && { address: location };
};

const filterBySearchText = (searchText) => {
  return searchText && { searchText: searchText.toLowerCase() };
};

const filterByMore = (more) => {
  return {
    ...(more?.[FILTER.POSITIONS]?.length && {
      userPosition: more[FILTER.POSITIONS],
    }),
    ...(more?.[FILTER.USERS]?.length && {
      teammates: more[FILTER.USERS],
    }),
    ...(more?.[FILTER.STATUSES]?.length && {
      status: flatten(more[FILTER.STATUSES].map((status) => status.split(','))),
    }),
    ...(more?.[FILTER.TYPES]?.length && {
      type: more[FILTER.TYPES],
    }),
  };
};

export {
  filterByBounds,
  filterByDate,
  filterByFolder,
  filterByLocation,
  filterByMore,
  filterBySearchText,
};
