import API from 'api/config';

import {
  createReportUrl,
  deleteReportUrl,
  fetchAllProjectReportsUrl,
  fetchReportUrl,
  updateReportUrl,
} from './maintenanceReportUrls';

export const createMaintenanceReport = ({
  projectId,
  companyId,
  title,
}: {
  projectId: string;
  companyId: string;
  title: string;
}) => {
  return API.post(createReportUrl(), {
    project: projectId,
    company: companyId,
    title,
  });
};

export const fetchMaintenanceReport = (reportId: string) => {
  return API.get(fetchReportUrl(reportId));
};

export const fetchAllMaintenanceReportsForProject = (projectId: string) => {
  return API.get(fetchAllProjectReportsUrl(projectId));
};

export const updateMaintenanceReport = (
  reportId: string,
  title?: string,
  summary?: string,
  weatherConsiderations?: string,
  issues?: string,
  recommendedFixesAndImprovements?: string,
  notesForGeneralMaintenance?: string,
  notesForThermal?: string
) => {
  return API.patch(updateReportUrl(reportId), {
    title,
    summary,
    weatherConsiderations,
    issues,
    recommendedFixesAndImprovements,
    notesForGeneralMaintenance,
    notesForThermal,
  });
};

export const deleteMaintenanceReport = (reportId: string) => {
  return API.delete(deleteReportUrl(reportId));
};
