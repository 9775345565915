import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldConfig, FieldInputProps } from 'formik';
import { camelCase } from 'lodash';
import {
  CANCELLATION_OPTIONS,
  FORM_CONTROLS,
  MAX_LENGTH_FOR_CANCELATION_NOTE,
} from 'screens/DesignServices/constants';

import './CancelOrderModal.scss';

const CancelOrderModal = ({
  handleChange,
  handleSubmit,
  getFieldProps,
}: {
  handleChange: (e: CheckboxChangeEvent | ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
}) => {
  const { TextArea } = Input;
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} data-testid="cancelation-modal">
      <div className="CancelOrderModal-Wrapper">
        <p className="CancelOrderModal-Title">{t('DesignService.cancelOrderModal.title')}</p>
        <div className="CancelOrderModal-Section">
          <p className="CancelOrderModal-Label">{t('DesignService.cancelOrderModal.subheader')}</p>
          <div className="CancelOrderModal-CheckboxWrapper">
            {CANCELLATION_OPTIONS.map((option) => {
              return (
                <Checkbox
                  onChange={handleChange}
                  key={camelCase(option.text)}
                  name={option.name}
                  checked={
                    getFieldProps(FORM_CONTROLS[option.field as keyof typeof FORM_CONTROLS]).value
                  }
                >
                  {option.text}
                </Checkbox>
              );
            })}
          </div>
        </div>
        <div className="CancelOrderModal-Section">
          <p className="CancelOrderModal-Label">{t('DesignService.cancelOrderModal.noteTitle')}</p>
          <div className="CancelOrderModal-TextArea">
            <TextArea
              rows={4}
              placeholder={t('DesignService.cancelOrderModal.placeholder')}
              maxLength={MAX_LENGTH_FOR_CANCELATION_NOTE}
              name="cancelNote"
              onChange={handleChange}
              value={getFieldProps(FORM_CONTROLS.CANCEL_NOTE).value}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default CancelOrderModal;
