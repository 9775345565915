import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';
import { User } from 'types';

import { userDeletionRequested } from 'state/slices/usersSlice';
import { AppDispatch } from 'state/store/store';

import { openNotification } from 'helpers/utils/openNotification';

import './DeleteTeammateModal.scss';

const DeleteTeammateModal = ({
  handleModalClose,
  teammate,
  handleDelete,
  scaniflyAdmin,
}: {
  handleModalClose: () => void;
  teammate: User;
  handleDelete?: () => void;
  scaniflyAdmin: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const onDeletionSuccess = (firstName: string, lastName: string) => {
    openNotification({
      type: 'error',
      title: t('AdminConsoleUserManager.removeTeammateTitle'),
      text: t('AdminConsoleUserManager.removeTeammateText', {
        username: `${firstName + ' ' + lastName}`,
      }),
    });
  };

  const deleteTeammate = () => {
    if (handleDelete) {
      handleDelete();
    } else if (teammate?.id) {
      dispatch(
        userDeletionRequested({
          userId: teammate.id,
          firstName: teammate.firstName ?? '',
          lastName: teammate.lastName ?? '',
          onDeletionSuccess,
        })
      );
      handleModalClose();
    }
  };

  const modifiedUsername =
    !teammate?.firstName && !teammate?.lastName
      ? teammate?.email
      : `${teammate?.firstName ?? ''} ${teammate?.lastName ?? ''}`;

  return (
    <>
      <div className="DeleteTeammateModal-Description">
        {handleDelete && !scaniflyAdmin
          ? t('AdminConsoleUserManager.removeFromProjectConfirmation', {
              username: modifiedUsername,
            })
          : t('AdminConsoleUserManager.deleteConfirmation', {
              username: modifiedUsername,
            })}
      </div>
      <div className="DeleteTeammateModal-Buttons-Wrapper">
        <Button
          onClick={handleModalClose}
          className="Button--White"
          data-testid="teammate-cancel-remove"
        >
          {t('AdminConsoleUserManager.cancel')}
        </Button>
        <Button
          onClick={deleteTeammate}
          className="Button--Red"
          autoFocus
          data-testid="teammate-remove"
        >
          {t('AdminConsoleUserManager.remove')}
        </Button>
      </div>
    </>
  );
};

export default DeleteTeammateModal;
