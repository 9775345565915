import { ReactNode } from 'react';

import styled from 'styled-components';

interface CornerProps {
  whichCorner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  children: ReactNode | ReactNode[];
}

const CornerContainer = styled.div<CornerProps>`
  position: absolute;
  ${({ whichCorner }) => {
    switch (whichCorner) {
      case 'top-left':
        return `
          top: 0;
          left: 0;
        `;
      case 'top-right':
        return `
          top: 0;
          right: 0;
        `;
      case 'bottom-left':
        return `
          bottom: 0;
          left: 0;
        `;
      case 'bottom-right':
        return `
          bottom: 0;
          right: 0;
        `;
      default:
        return '';
    }
  }};
`;

export const PutItInTheCorner = ({ whichCorner, children }: CornerProps) => {
  return <CornerContainer whichCorner={whichCorner}>{children}</CornerContainer>;
};
