export const FORM_CONTROLS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  COUNTRY: 'country',
  STREET: 'street',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  NAME: 'name',
  EMAIL: 'email',
  VAT_NUMBER: 'VATNumber',
};
