export const FORM_CONTROLS = {
  COMPANY: 'company',
  USER_ID: 'userId',
  ENGINE: 'engine',
};

export const initialValues = {
  [FORM_CONTROLS.COMPANY]: '',
  [FORM_CONTROLS.USER_ID]: '',
  [FORM_CONTROLS.ENGINE]: 'watt3d',
};

export const ENGINE_OPTIONS = [
  { label: 'Watt3d', value: 'watt3d' },
  { label: 'RC', value: 'rc' },
];
