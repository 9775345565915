import { ModalFuncProps, ModalProps } from 'antd';

export const CONFIRM_PROPS: Pick<
  ModalProps,
  'cancelText' | 'destroyOnClose' | 'maskClosable' | 'centered'
> &
  Pick<ModalFuncProps, 'autoFocusButton'> & {
    role: string;
    ariaModal: boolean;
  } = {
  autoFocusButton: 'ok',
  cancelText: 'Cancel',
  role: 'dialog',
  ariaModal: true,
  destroyOnClose: true,
  maskClosable: true,
  centered: true,
};

export const MODAL_PROPS = {
  footer: null,
  role: 'dialog',
  ariaModal: true,
  destroyOnClose: true,
  centered: true,
};
