import { PAYMENT_TYPE, PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { Row } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import {
  calculateMonthlyFee,
  calculateRemainingProjectCount,
} from 'screens/ScaniflyAdmin/Accounting/helpers';

import { filterDateFormat } from 'helpers/utils/dateFormat';
import { formatUSD } from 'helpers/utils/priceFormatter';

export const renderSubscriptionInfoDisplay = ({ companySubscriptionInfo }) => {
  const {
    smallProjectsBySubscriptionStartDate,
    largeProjectsBySubscriptionStartDate,
    subscription,
  } = companySubscriptionInfo || {};

  const {
    contractStartDate,
    contractEndDate,
    smallProjectQuota,
    largeProjectQuota,
    paymentType,
    subscriptionAmount,
    discountPercentage,
  } = companySubscriptionInfo?.subscription || {};

  return (
    <>
      <Row className="AccountPayments-Row">
        <div className="AccountPayments-Wrapper">
          <div className="AccountPayments-Title" aria-label="subscription start date">
            Subscription Start Date
          </div>
          <span className="AccountPayments-Data">
            {subscription
              ? moment.utc(new Date(contractStartDate)).format(filterDateFormat)
              : 'No active contract.'}
          </span>
        </div>
        <div className="AccountPayments-Wrapper">
          <div className="AccountPayments-Title" aria-label="subscription end date">
            Subscription End Date
          </div>
          <div className="AccountPayments-Data">
            {subscription
              ? moment.utc(new Date(contractEndDate)).format(filterDateFormat)
              : 'No active contract.'}
          </div>
        </div>
      </Row>

      <h2 className="AccountPayments-SubTitle">Subscription Details</h2>
      <Row className="AccountPayments-Row">
        <div className="AccountPayments-Wrapper">
          <div className="AccountPayments-Title" aria-label="small project quota">
            Small Projects
          </div>
          <span className="AccountPayments-Data">
            {typeof smallProjectQuota === 'number' ? smallProjectQuota : '-'}
          </span>
        </div>
        <div className="AccountPayments-Wrapper">
          <div className="AccountPayments-Title" aria-label="large project quota">
            Large Projects
          </div>
          <div className="AccountPayments-Data">
            {typeof largeProjectQuota === 'number' ? largeProjectQuota : '-'}
          </div>
        </div>
      </Row>
      <Row className="AccountPayments-Row">
        <div className="AccountPayments-Wrapper">
          <div className="AccountPayments-Title" aria-label="payment type">
            Payment Type
          </div>
          <span className="AccountPayments-Data">
            {paymentType
              ? capitalize(paymentType)
              : subscription
                ? capitalize(PAYMENT_TYPE.monthly)
                : '-'}
          </span>
        </div>
        <div className="AccountPayments-Wrapper">
          <div
            className="AccountPayments-Title"
            aria-label="total or monthly subscription fees depending on the payment type"
          >
            {`${
              paymentType === PAYMENT_TYPE.upfront ? 'Total' : 'Monthly'
            } Subscription Fee \n(excluding additional fees)`}
          </div>
          <div className="AccountPayments-Data">
            {subscription
              ? formatUSD(
                  calculateMonthlyFee({
                    monthlySubscription: subscriptionAmount,
                    discount: discountPercentage,
                  })
                )
              : '-'}
          </div>
        </div>
      </Row>

      <h2 className="AccountPayments-SubTitle">Projects Count to Date</h2>
      <Row className="AccountPayments-Row">
        <div className="AccountPayments-Wrapper">
          <div
            className="AccountPayments-Title"
            aria-label="uploaded small project count since subscription start date"
          >
            Small Projects
          </div>
          <span className="AccountPayments-Data">
            {typeof smallProjectsBySubscriptionStartDate === 'number'
              ? smallProjectsBySubscriptionStartDate
              : '-'}
          </span>
        </div>
        <div className="AccountPayments-Wrapper">
          <div
            className="AccountPayments-Title"
            aria-label="uploaded large project count since subscription start date"
          >
            Large Projects
          </div>
          <div className="AccountPayments-Data">
            {typeof largeProjectsBySubscriptionStartDate === 'number'
              ? largeProjectsBySubscriptionStartDate
              : '-'}
          </div>
        </div>
      </Row>

      <h2 className="AccountPayments-SubTitle">Projects Remaining</h2>
      <Row className="AccountPayments-Row">
        <div className="AccountPayments-Wrapper">
          <div
            className="AccountPayments-Title"
            aria-label="remaining small project count for subscription"
          >
            Small Projects
          </div>
          <span className="AccountPayments-Data">
            {subscription
              ? calculateRemainingProjectCount(companySubscriptionInfo, PROJECT_TYPES.SMALL)
              : '-'}
          </span>
        </div>
        <div className="AccountPayments-Wrapper">
          <div
            className="AccountPayments-Title"
            aria-label="remaining large project count for subscription"
          >
            Large Projects
          </div>
          <div className="AccountPayments-Data">
            {subscription
              ? calculateRemainingProjectCount(companySubscriptionInfo, PROJECT_TYPES.LARGE)
              : '-'}
          </div>
        </div>
      </Row>
    </>
  );
};
