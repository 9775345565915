import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import PropTypes from 'prop-types';

import { adminUpdatedProject, projectRequested } from 'state/slices/projectSlice';
import { companyUsersRequested } from 'state/slices/usersSlice';

import { CustomSelect } from 'components';

import './ChangeUserModal.scss';

const ChangeUserModal = ({ projectId, handleModalClose }) => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.project);
  const { selectedCompanyUsers } = useSelector((state) => state.users);

  const [selectedUserEmail, setSelectedUserEmail] = useState();

  useEffect(() => {
    if (!projectId) return;
    dispatch(projectRequested(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (project?.submittedFor) {
      dispatch(companyUsersRequested(project.submittedFor.company.id));
      setSelectedUserEmail(project.submittedFor.email);
    }
  }, [project, dispatch]);

  const handleChangeUser = () => {
    if (selectedUserEmail === project.createdBy || !selectedUserEmail) return;
    dispatch(
      adminUpdatedProject({
        projectId,
        values: { createdBy: selectedUserEmail },
      })
    );
    handleModalClose();
  };

  return (
    <div className="ChangeUserModal-Wrapper">
      <div className="ChangeUserModal-Title">{`Change User for ${project?.name}`}</div>
      <div className="ChangeUserModal-Select-Wrapper">
        <CustomSelect
          placeholder="Select a User"
          options={
            selectedCompanyUsers.map((member) => ({
              value: member.email,
              label: `${member.firstName} ${member.lastName}`,
            })) ?? []
          }
          variant="filter"
          value={selectedUserEmail}
          onChange={(option) => setSelectedUserEmail(option.value)}
          tall
        />
      </div>
      <div className="ChangeUserModal-Buttons-Wrapper">
        <Button className="Button--White" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button className="Button--Blue" onClick={handleChangeUser}>
          Change User
        </Button>
      </div>
    </div>
  );
};

ChangeUserModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
};

export default ChangeUserModal;
