import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { kebabCase } from 'lodash';

import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import { accountChecklistTemplateManagerRoute } from 'helpers/constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledButton = styled(Link)`
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  padding: 10px 20px;
  border-radius: 10px;
  &:hover {
    background-color: ${colors.darkBlue};
    color: ${colors.white};
    border: 1px solid ${colors.lightGray};
  }
  display: inline-block;
  margin-right: 40px;
  margin-top: -30px;

  @media screen and (max-width: ${breakpoints.xxl}) {
    margin-right: 0;
    margin-top: 0;
  }
`;

const Subtitle = styled.div`
  font-size: 1.2rem;
  color: $label-gray;
  line-height: 1.7rem;
  margin-bottom: 3rem;
  width: 70%;
  color: ${colors.labelGray};
`;

const Title = styled.h3`
  line-height: 1.6rem;
`;

const EditChecklistSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Title className={'checklist-manager-title'}>{t('Checklists.checklistManager')}</Title>
      <Wrapper>
        <Subtitle>{t('Checklists.manageCompany')}</Subtitle>
        <StyledButton
          id={`Account-Menu-Button-${kebabCase(PAGE_TITLES.CHECKLIST_MANAGER)}`}
          to={accountChecklistTemplateManagerRoute()}
        >
          {t('Checklists.manage')}
        </StyledButton>
      </Wrapper>
    </>
  );
};

export default EditChecklistSection;
