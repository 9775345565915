import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomYAxisTick } from 'components';

import colors from 'helpers/constants/colors';

import './CustomerInfoUsageChart.scss';

const CustomerInfoUsageChart = ({ values }) => {
  const chartData = Object.keys(values).map((name, i) => ({
    name: upperFirst(name),
    kWh: Object.values(values)[i] || 0,
  }));

  const hasValue = Object.values(values).filter((value) => value > 0).length;

  return (
    <div className="CustomerInfoUsageChart">
      <div className="CustomerInfoUsageChart-Legend">
        <div className="CustomerInfoUsageChart-Legend-Bar" />
        Consumption
      </div>
      <ResponsiveContainer width="99%" minWidth={280} height={256}>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickFormatter={(name) => name.charAt(0)}
            tick={{ fill: colors.labelGray }}
            tickLine={false}
            tickMargin={16}
          />
          <YAxis
            axisLine={false}
            tick={<CustomYAxisTick />}
            tickLine={false}
            interval="preserveEnd"
          />
          <Tooltip cursor={hasValue} wrapperStyle={!hasValue && { display: 'none' }} />
          <Bar dataKey="kWh" fill={colors.mainBlue} radius={[6, 6, 6, 6]} barSize={12} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

CustomerInfoUsageChart.propTypes = {
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default CustomerInfoUsageChart;
