export const adminFetchAllProjectsUrl = () => '/admin/projects/companies';

export const adminFetchUserProjectsUrl = (userId: string) => `/admin/projects/user/${userId}`;

export const adminScaniflyProjectsUrl = () => `/admin/projects`;

export const adminFetchActiveSubscriptionUrl = (companyId: string) =>
  `/subscriptions/active/${companyId}`;

export const adminFetchSelectedSubscriptionUrl = (subscriptionId: string) =>
  `/subscriptions/select/${subscriptionId}`;

export const adminCreateInvoiceUrl = (companyId: string, month: number, year: number) =>
  `/invoices/${companyId}/${month}/${year}`;

export const adminUpdateInvoiceUrl = (companyId: string, month: number, year: number) =>
  `/invoices/company/${companyId}/${month}/${year}`;

export const adminRefreshInvoiceStatusesUrl = (month: number, year: number) =>
  `/invoices/refresh/${month}/${year}`;

export const adminCreateNewSubscriptionUrl = () => `/subscriptions`;

export const adminUpdateSubscriptionUrl = (subscriptionId: string) =>
  `/subscriptions/update/${subscriptionId}`;

export const adminFetchAllSubscriptionsOfAGivenCompanyUrl = (companyId: string) =>
  `/subscriptions/all/${companyId}`;

export const adminFetchAllUsersUrl = () => '/users/admin/users';

export const adminDeleteUserUrl = (userId: string) => `/users/${userId}`;

export const adminFreezeUserUrl = (userId: string) => `/users/freeze/${userId}`;

export const adminDeactivateUserUrl = (userId: string) => `/users/deactivate/${userId}`;

export const adminReactivateUserUrl = (userId: string) => `/users/reactivate/${userId}`;

export const adminEditUserUrl = (userId: string) => `/users/admin/update/${userId}`;

export const adminFetchCompaniesUrl = () => `/companies`;

export const adminUpdateCompanyMemberStatusesUrl = (companyId: string) =>
  `/companies/updateMemberStatus/${companyId}`;

export const adminFetchCompaniesWithPopulatedMembersUrl = () => `/companies/populatedMembers`;

export const adminFetchCompaniesNotPopulatedUrl = () => `/companies/members`;

export const adminFetchCompanyNameAndIdsUrl = () => `/companies/basicCompanyInfo`;

export const adminFetchCompaniesWithAccountInfoUrl = (month: number, year: number) =>
  `/accounting/all/${month}/${year}`;

export const adminFetchScaniflyAdminsUrl = () => `/users/scaniflyAdminList`;

export const adminFetchCompanyProjectsUrl = (companyId: string) =>
  `/admin/projects/company/${companyId}`;

export const adminFetchCompanyUsersUrl = (companyId: string) => `/users/admin/users/${companyId}`;

export const adminUpdateUserPermissionsUrl = (id: string) => `/users/${id}/permissions`;

export const adminUpdateUserProjectAccessUrl = (id: string) => `/users/${id}/access`;

export const adminFetchAccessTokensUrl = () => '/api/v1/auth/tokens';

export const adminDeleteAccessTokenUrl = (id: string) => `/api/v1/auth/${id}`;

export const adminGenerateAccessTokenUrl = (id: string) => `/api/v1/auth/${id}`;

export const adminUpdateCompanyPricingTierUrl = (companyId: string) =>
  `/companies/pricingTier/${companyId}`;

export const adminUpdateProjectUrl = (projectId: string) => `/admin/projects/${projectId}`;

export const adminReplaceReprocessedProjectUrl = (projectId: string) =>
  `admin/projects/reprocessing/${projectId}`;
