import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Modal, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import { Revision } from 'types';
import ServiceRequest from 'types/ServiceRequest';

import { dspCompaniesRequested } from 'state/slices/companySlice';
import { projectsRequested } from 'state/slices/designServices/designServicesQueueSlice';
import { resetProject } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { Button, CSVExportButton, GoBackButton } from 'components';
import ChangeDSPModal from './components/ChangeDSPModal/ChangeDSPModal';
import ChargeExtraCreditsModal from './components/ChargeExtraCredits/ChargeExtraCreditsModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal/ConfirmDeleteModal';
import DeleteNearmapModal from './components/DeleteNearmapModal/DeleteNearmapModal';
import StatisticsModal from './components/StatisticsModal/StatisticsModal';
import ViewFormModal from './components/ViewFormModal/ViewFormModal';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterByForDesignServices } from '../helpers/getFilterBy';
import { handleDesignServiceQueueCSVExport } from '../helpers/handleDesignServiceQueueCSVExport';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { getDSPColumnTitles } from '../constants';
import './DesignServices.scss';
import ViewRevisionsModal from './DSCompanyQueue/ViewRevisionsModal/ViewRevisionsModal';
import TableData from './TableData';
import { Extra, Filter, Pagination, Sorter, TableParams } from './TableParams';

const DesignServicesQueue = ({
  isScaniflyAdminRoute = false,
}: {
  isScaniflyAdminRoute?: boolean;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [displayedData, setDisplayedData] = useState<Array<ServiceRequest>>([]);
  const [viewFormModalOpen, toggleViewFormModal] = useToggle();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();
  const [isEditDSPModalOpen, toggleEditDSPModal] = useToggle();
  const [isChargeExtraCreditsModalOpen, toggleChargeExtraCreditsModalOpen] = useToggle();
  const [isDeleteNearmapModalOpen, toggleDeleteNearmapModal] = useToggle();
  const [revisionModalOpen, toggleRevisionModal] = useToggle();
  const [isStatisticsModalOpen, toggleStatisticsModal] = useToggle();
  const [selectedServiceRequestId, setSelectedServiceRequestId] = useState<string>();
  const [modelRevisionData, setModelRevisionData] = useState<Revision | Revision[]>([]);
  const [formData, setFormData] = useState({});
  const [projectName, setProjectName] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
    },
  });

  const { designServiceQueueProjectList, isLoading, projectCount } = useSelector(
    (state: RootState) => state.designServicesQueue
  );

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { isScaniflyAdmin } = usePermissions();

  const selectedServiceRequest = designServiceQueueProjectList.find(
    ({ id }) => id === selectedServiceRequestId
  );

  const handleToggleDeleteModal = (serviceRequestId: string) => {
    toggleDeleteModal();
    setSelectedServiceRequestId(serviceRequestId);
  };

  const handleToggleEditDSPModal = (serviceRequestId: string) => {
    toggleEditDSPModal();
    setSelectedServiceRequestId(serviceRequestId);
  };

  const handleToggleChargeExtraCreditsModal = (serviceRequestId: string) => {
    toggleChargeExtraCreditsModalOpen();
    setSelectedServiceRequestId(serviceRequestId);
  };

  const handleToggleDeleteNearmapModal = (serviceRequestId: string) => {
    toggleDeleteNearmapModal();
    setSelectedServiceRequestId(serviceRequestId);
  };

  const handleViewRevisionsClick = (
    project: { id: string; name: string },
    modelRevision: Revision | Revision[]
  ) => {
    setProjectName(project.name);
    setModelRevisionData(modelRevision);
    toggleRevisionModal();
  };

  useEffect(() => {
    setDisplayedData(designServiceQueueProjectList);
  }, [designServiceQueueProjectList]);

  useEffect(() => {
    dispatch(resetProject());
  }, [dispatch]);

  useEffect(() => {
    if (isScaniflyAdmin) {
      dispatch(dspCompaniesRequested());
    }
  }, [dispatch, isScaniflyAdmin]);

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};

    const companyIdProp = isScaniflyAdminRoute ? {} : { companyId: currentUser?.companyId };
    if (currentUser?.companyId) {
      dispatch(
        projectsRequested({
          size: PROJECT_LIST_SIZE,
          pageIndex: pagination?.current ?? 1,
          sortBy: getSortBy(columnKey, order),
          filterBy: getFilterByForDesignServices(filters, searchText),
          ...companyIdProp,
        })
      );
    }
  }, [currentUser?.companyId, dispatch, isScaniflyAdminRoute, searchText, tableParams]);

  const handleTableChange = (
    pagination: Pagination,
    filters: Filter | Record<string, FilterValue | null>,
    sorter: Sorter | Sorter[],
    extra: Extra
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  const DSPQueueCSVTitles = useMemo(() => getDSPColumnTitles(isScaniflyAdmin), [isScaniflyAdmin]);

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        visible={viewFormModalOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ViewFormModal
          handleClose={toggleViewFormModal}
          formData={formData}
          projectName={projectName}
        />
      </Modal>
      {/* @ts-ignore */}
      <Modal
        visible={revisionModalOpen}
        onCancel={toggleRevisionModal}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ViewRevisionsModal revisionData={modelRevisionData} projectName={projectName} />
      </Modal>
      <ConfirmDeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        toggleDeleteModal={toggleDeleteModal}
        serviceRequest={selectedServiceRequest}
      />
      <ChangeDSPModal
        isModalOpen={isEditDSPModalOpen}
        toggleModal={toggleEditDSPModal}
        serviceRequest={selectedServiceRequest}
      />
      <ChargeExtraCreditsModal
        isModalOpen={isChargeExtraCreditsModalOpen}
        toggleModal={toggleChargeExtraCreditsModalOpen}
        serviceRequest={selectedServiceRequest}
      />
      <DeleteNearmapModal
        isModalOpen={isDeleteNearmapModalOpen}
        toggleModal={toggleDeleteNearmapModal}
        serviceRequest={selectedServiceRequest}
      />
      <StatisticsModal isModalOpen={isStatisticsModalOpen} toggleModal={toggleStatisticsModal} />
      <div className="DesignServices-Wrapper">
        <div className="DesignServices-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            role="searchbox"
            prefix={<SearchIcon />}
            aria-label="search projects"
            onChange={debounce((e) => {
              setSearchText(e.target.value);
            }, 400)}
            className="ScaniflyAdmin-Search"
          />
          <p data-testid="project-count">{projectCount ?? 0} projects</p>
          {isScaniflyAdminRoute ? (
            <Button onClick={toggleStatisticsModal}>Statistics</Button>
          ) : null}
          <CSVExportButton
            tableName={TABLE_NAMES.DESIGN_SERVICES_QUEUE}
            columnTitles={DSPQueueCSVTitles}
            prepareCSVData={handleDesignServiceQueueCSVExport}
          />
        </div>
        <Table
          className="DesignServices-Table"
          columns={TableData({
            toggleViewFormModal,
            setFormData,
            setProjectName,
            handleViewRevisionsClick,
            handleToggleDeleteModal,
            handleToggleEditDSPModal,
            handleToggleChargeExtraCreditsModal,
            handleToggleDeleteNearmapModal,
          })}
          dataSource={displayedData}
          rowKey="id"
          scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
          pagination={{
            total: projectCount,
            pageSize: PROJECT_LIST_SIZE,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          onChange={(pagination, filters, sorter, extra) =>
            handleTableChange(pagination, filters, sorter, extra)
          }
          loading={{
            indicator: (
              <div className="DesignServices-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isLoading,
          }}
        />
      </div>
    </>
  );
};

export default DesignServicesQueue;
