import { useState } from 'react';

export const usePaymentField = () => {
  const [touched, setTouched] = useState(false);
  const [filled, setFilled] = useState(false);
  const [error, setError] = useState(null);
  const [focused, setFocused] = useState(false);

  return [touched, setTouched, focused, setFocused, filled, setFilled, error, setError];
};
