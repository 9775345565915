import countryList from 'react-select-country-list';

export const mapCountryNameToCode = (selectedCountry) => {
  if (selectedCountry) {
    return countryList().data.find((country) => country.label === selectedCountry)?.value;
  }
};

export const mapCountryCodeToName = (selectedCountry) => {
  if (selectedCountry) {
    return countryList().data.find((country) => country.value === selectedCountry)?.label;
  }
};
