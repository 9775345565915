export const ALL_STEPS = {
  MY_PROJECTS: 'My Projects',
  PROJECT_OVERVIEW: 'Project Overview',
  CUSTOMER_INFO: 'Customer Info',
  CONSUMPTION_DATA: 'Consumption Data',
  PROJECT_INFO: 'Project Admin',
  SITE_DATA: 'Site Data',
  MODELS: 'Models',
  DESIGNS: 'Designs',
  DRONE_IMAGES: 'Drone Images',
  CHECKLISTS: 'Checklists',
  ALBUMS: 'Albums',
  COMMENTS: 'Comments',
  MAINTENANCE: 'Maintenance',
  USERS: 'Users',
  ACCOUNT_MANAGER: 'Account Manager',
  UPLOAD_QUEUE: 'Upload Queue',
  PROJECTS: 'Projects',
  ACCOUNTING: 'Accounting',
  ACCESS_TOKENS: 'Access Tokens',
  SCANIFLY_INFO: 'Scanifly Info',
  CHECKLIST_MANAGER: 'Checklist Manager',
  DESIGN_QUEUE: 'Design Queue',
  CREDIT_HISTORY: 'Credit History',
  PROJECT_NAME: 'Project Name',
};

export const PROJECT_MENU = {
  CUSTOMER_INFO: 'Customer Info',
  PROJECT_INFO: 'Project Admin',
  MODELS: 'Models',
  DRONE_IMAGES: 'Drone Images',
  ALBUMS: 'Albums',
  COMMENTS: 'Comments',
};

export const NEW_PROJECT_STEPS = {
  CUSTOMER_INFO: 'Customer Info',
  PROJECT_INFO: 'Project Admin',
  MODELS: 'Models',
  ALBUMS: 'Albums',
  COMMENTS: 'Comments',
};

export const CS_PROJECT_STEPS = {
  CUSTOMER_INFO: 'Customer Info',
  SCANIFLY_INFO: 'Scanifly Info',
  PROJECT_INFO: 'Project Admin',
  MODELS: 'Models',
  DRONE_IMAGES: 'Drone Images',
  ALBUMS: 'Albums',
  COMMENTS: 'Comments',
};

export const CS_UPLOAD_STEPS = {
  CUSTOMER_INFO: 'Customer Info',
  SCANIFLY_INFO: 'Scanifly Info',
  PROJECT_INFO: 'Project Admin',
  MODELS: 'Models',
  ALBUMS: 'Albums',
  COMMENTS: 'Comments',
};

export const SCANIFLY_ADMIN_MENU = {
  USERS: 'Users',
  ACCOUNT_MANAGER: 'Account Manager',
  UPLOAD_QUEUE: 'Upload Queue',
  PROJECTS: 'Projects',
  ACCOUNTING: 'Accounting',
  ACCESS_TOKENS: 'Access Tokens',
  DS_UPLOAD_QUEUE: 'Design Services Queue',
  CREDIT_HISTORY: 'Credit History',
};

export const SIDEBAR_WIDTH = 272;
export const COLLAPSED_SIDEBAR_WIDTH = 80;

export enum DESIGN_SERVICES_MENU {
  ORDER_A_DESIGN = 'Order A Design',
}
