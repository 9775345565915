import styled from 'styled-components';

const SelectContainer = styled.div`
  height: unset;
  display: flex;
  flex-direction: column;

  label {
    width: 50%;
    padding-top: 1.2rem;
  }
`;

export default SelectContainer;
