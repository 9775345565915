import { COPY_TO_CLIPBOARD_MESSAGE } from 'screens/ScaniflyAdmin/constants';

import { openNotification } from './openNotification';

export const handleCopyClick = (text: string, fieldName = '', name?: string) => {
  navigator.clipboard.writeText(text);
  openNotification({
    type: 'success',
    title: 'Success!',
    text: COPY_TO_CLIPBOARD_MESSAGE(name ? `${fieldName} of ${name}` : `${fieldName}: ${text}`),
  });
};
