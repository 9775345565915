import { Popup } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { goToProjectDetails } from 'screens/Projects/ProjectsList/ProjectTile/helpers';

import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import { formatDate } from 'helpers/utils/dateFormat';
import { thumbnail } from 'helpers/utils/mapbox';

import './MapPopup.scss';

const MapPopup = ({ selectedProject }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const id = selectedProject.id;
  const location = selectedProject.geolocation;

  const handlePopupClick = (id, statusDescription, dispatch) => {
    history.push(goToProjectDetails(id, statusDescription, dispatch));
  };

  return (
    <div
      role="presentation"
      className="MapPopup"
      onClick={() => handlePopupClick(id, selectedProject.statusDescription, dispatch)}
    >
      <Popup
        latitude={location.latitude}
        longitude={location.longitude}
        offsetTop={-20}
        tipSize={0}
        closeButton={false}
      >
        {selectedProject.statusDescription !== PROJECT_STATUSES.PROCESSED && (
          <div className="MapPopup-Status">
            <Tag color={selectedProject.statusColor}>{selectedProject.statusDescription}</Tag>
          </div>
        )}
        <img
          src={
            selectedProject.thumbnailUrl || thumbnail(location.latitude, location.longitude, true)
          }
          alt="project thumbnail"
          className="MapPopup-Image"
        />
        <div className="MapPopup-Wrapper">
          <div className="MapPopup-Title">{selectedProject.name}</div>
          <div className="MapPopup-Date">{'Created: ' + formatDate(selectedProject.createdAt)}</div>
        </div>
      </Popup>
    </div>
  );
};

MapPopup.propTypes = {
  selectedProject: PropTypes.object.isRequired,
};

export default MapPopup;
