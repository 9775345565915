import { Coords } from '@cpm/scanifly-shared-data';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import Uppy, { BasePlugin, UppyFile } from '@uppy/core';
import exifr from 'exifr';
import { v4 as uuidv4 } from 'uuid';

import { ExifData, getPitch, getRoll, getYaw } from '../exif';
import { PhotoMetaPluginOptions } from './types';

/**
 * Max miles a photo can be from the project location to be flagged as being too far
 */
const MAX_MILES_APART = 1;

export default class PhotoMetaPlugin extends BasePlugin {
  projectGeolocation?: Coords;

  constructor(uppy: Uppy, opts?: PhotoMetaPluginOptions) {
    super(uppy, opts);

    this.id = opts?.id || 'PhotoMetaPlugin';
    this.type = 'modifier';
    this.projectGeolocation = opts?.projectGeolocation;
  }

  /**
   *
   * Parses exif and xmp from media to populate photo_meta field.
   *
   * Heads up! Camera orientation (i.e. pitch, roll, yaw) differs between
   * drone manufacturers. Currently accounting for Autel, DJI, and Skydio.
   *
   */
  async parseMeta(files: UppyFile[]) {
    const promises = files.map(async (file) => {
      try {
        const meta = await exifr.parse(file.data, { xmp: true });
        const photoMeta = {
          width: meta?.ExifImageWidth,
          height: meta?.ExifImageHeight,
          cameraOrientation: {
            pitch: getPitch(meta),
            roll: getRoll(meta),
            yaw: getYaw(meta),
          },
          createdAt: meta?.CreateDate,
          exposureTime: meta?.ExposureTime,
          fStop: meta?.FNumber,
          iso: meta?.ISO,
          relativeAltitude: meta?.GPSAltitude,
        };

        const distanceApart = this.projectGeolocation
          ? this.getDistanceFromProject(meta)
          : undefined;
        return this.uppy.setFileMeta(file.id, {
          client_ref_id: uuidv4(),
          photo_meta: JSON.stringify(photoMeta),
          ...(distanceApart && { isDistant: distanceApart > MAX_MILES_APART }),
          ...(distanceApart && { distanceApart }),
        });
      } catch (err: any) {
        this.uppy.log(err, 'error');
      }
    });

    await Promise.all(promises);
  }

  /**
   * Compares photo location against projectGeolocation
   * @param meta - editable media with EXIF data
   * @returns distance from project in miles or 0 if no coordinates found in exif data
   */
  getDistanceFromProject(meta: ExifData) {
    const fromPoint = point([
      this.projectGeolocation!.longitude,
      this.projectGeolocation!.latitude,
    ]);
    const { latitude, longitude } = meta;
    if (latitude !== undefined && longitude !== undefined) {
      const toPoint = point([longitude, latitude]);
      return parseFloat(distance(fromPoint, toPoint, { units: 'miles' }).toFixed(2));
    }
    return 0;
  }

  install() {
    this.uppy.on('files-added', this.parseMeta.bind(this));
  }

  uninstall() {
    this.uppy.off('files-added', this.parseMeta.bind(this));
  }
}
