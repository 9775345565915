import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Note } from 'types';

import { AppDispatch, RootState } from 'state/store/store';

import {
  createNoteApi,
  deleteNoteApi,
  fetchMentionsUserListApi,
  fetchNoteApi,
  fetchtNotesApi,
  updatetNoteApi,
} from 'api/notes/notesService';

import { SET_SELECTED_NOTE, SLICE_NAMESPACE } from './constants';
import { selectSelectedNote } from './selectors';

const notesRequested = createAsyncThunk(
  `${SLICE_NAMESPACE}/notesRequested`,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const response = await fetchtNotesApi(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const noteRequested = createAsyncThunk(
  `${SLICE_NAMESPACE}/noteRequested`,
  async (noteId: string, { rejectWithValue }) => {
    try {
      const response = await fetchNoteApi(noteId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createNote = createAsyncThunk(
  `${SLICE_NAMESPACE}/createNote`,
  async (payload: { projectId: string; text: string }, { rejectWithValue }) => {
    try {
      const response = await createNoteApi(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deleteNote = createAsyncThunk(
  `${SLICE_NAMESPACE}/deleteNote`,
  async (
    { projectId, noteId }: { projectId: string; noteId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await deleteNoteApi(noteId);
      dispatch(notesRequested(projectId));
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const updateNote = createAsyncThunk(
  `${SLICE_NAMESPACE}/updateNote`,
  async (
    {
      projectId,
      noteId,
      text,
      media,
    }: { projectId: string; noteId: string; text: string; media: string[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await updatetNoteApi(noteId, text, media);
      dispatch(notesRequested(projectId));
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const mentionsUserListRequested = createAsyncThunk(
  `${SLICE_NAMESPACE}/mentionsUserListRequested`,
  async ({ projectId }: { projectId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchMentionsUserListApi(projectId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const setSelectedNote = createAction<Note | null>(SET_SELECTED_NOTE);

const deleteBlankNote = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  // Make sure it's blank
  const selectedNote = selectSelectedNote(getState());
  if (
    (selectedNote?.text?.length ?? 0) === 0 &&
    (selectedNote?.media?.length ?? 0) === 0 &&
    selectedNote?.id
  ) {
    const { project, id } = selectedNote;
    if (project) {
      await dispatch<any>(deleteNote({ projectId: project as string, noteId: id }));
    }
  }
  dispatch(setSelectedNote(null));
};

export {
  createNote,
  deleteBlankNote,
  deleteNote,
  mentionsUserListRequested,
  noteRequested,
  notesRequested,
  updateNote,
};
