import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Radio, Row, Spin } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useFormik } from 'formik';
import moment from 'moment';
import AccountWrapper from 'screens/Account/AccountWrapper';

import { adminCompaniesNotPopulatedRequested } from 'state/slices/admin/adminCompaniesSlice';
import {
  adminCompanyAllSubscriptionsRequested,
  companySubscriptionWithIdRequested,
  newSubscriptionCreationRequested,
  resetSelectedSubscription,
  subscriptionUpdateRequested,
} from 'state/slices/admin/adminSubscriptionsSlice';
import { adminScaniflyAdminListRequested } from 'state/slices/admin/adminUsersSlice';

import { CustomSelect, Divider } from 'components';

import { CONFIRM_PROPS } from 'helpers/constants/modals';
import {
  scaniflyAdminAccountingRoute,
  scaniflyAdminAccountingTableRoute,
} from 'helpers/constants/routes';

import '../ScaniflyAdmin.scss';
import './Accounting.scss';
import AccountingForm from './AccountingForm/AccountingForm';
import AccountingTable from './AccountingTable';
import { ACCOUNTING_TABS, FORM_CONTROLS, initialValues, toFormData } from './constants';
import { validationSchema } from './validationSchema';

const Accounting = () => {
  const { companyId } = useParams();
  const [tab, setTab] = useState(ACCOUNTING_TABS.FORM);
  const [informationSaved, setInformationSaved] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { Group, Button: TabButton } = Radio;
  const isFormVisible = tab === ACCOUNTING_TABS.FORM;

  const onTabChange = (event) => setTab(event.target.value);

  const { companiesNotPopulated } = useSelector((state) => state.adminCompanies);
  const { activeSubscription, selectedSubscription, isSubscriptionDataLoading } = useSelector(
    (state) => state.adminSubscriptions
  );

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();

  useEffect(() => {
    if (location.pathname.includes(ACCOUNTING_TABS.TABLE)) {
      setTab(ACCOUNTING_TABS.TABLE);
    } else {
      setTab(ACCOUNTING_TABS.FORM);
      setSelectedSubscriptionId();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(adminCompaniesNotPopulatedRequested());
    dispatch(adminScaniflyAdminListRequested());
  }, [dispatch]);

  useEffect(() => {
    if (
      activeSubscription?.id &&
      selectedSubscriptionId === undefined &&
      activeSubscription?.companyId === companyId
    ) {
      setSelectedSubscriptionId(activeSubscription.id);
    }
  }, [activeSubscription, companyId, selectedSubscriptionId]);

  useEffect(() => {
    dispatch(companySubscriptionWithIdRequested(selectedSubscriptionId));
  }, [dispatch, selectedSubscriptionId]);

  useEffect(() => {
    if (companyId) {
      dispatch(adminCompanyAllSubscriptionsRequested({ companyId }));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (selectedSubscriptionId === '') {
      resetSelectedSubscription();
    }
  }, [dispatch, companyId, selectedSubscriptionId]);

  const initialData = useMemo(() => {
    if (selectedSubscription && selectedSubscriptionId === selectedSubscription.id) {
      return {
        ...toFormData(selectedSubscription),
        contractStartDate: moment.utc(selectedSubscription.contractStartDate),
        contractEndDate: moment.utc(selectedSubscription.contractEndDate),
        scaniflyOwner:
          typeof selectedSubscription.scaniflyOwner === 'string'
            ? selectedSubscription.scaniflyOwner
            : selectedSubscription.scaniflyOwner?.id,
      };
    }

    return { ...initialValues, [FORM_CONTROLS.COMPANY]: companyId ?? '' };
  }, [companyId, selectedSubscription, selectedSubscriptionId]);

  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } = useFormik({
    initialValues: initialData,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const validatedValues = {
        ...values,
        discountPercentage: Math.max(values.discountPercentage, 0),
        subscriptionAmount: Math.max(values.subscriptionAmount, 0),
        largeProjectQuota: Math.max(values.largeProjectQuota, 0),
        smallProjectQuota: Math.max(values.smallProjectQuota, 0),
        dspPaymentType:
          values.dspPaymentType && values.dspPaymentType.length && values.totalCredits
            ? values.dspPaymentType
            : null,
        canUseRemoteDesign: values.canUseRemoteDesign ?? false,
        canUseMaintenance: values.canUseMaintenance ?? false,
      };

      if (!selectedSubscriptionId) {
        dispatch(
          newSubscriptionCreationRequested({
            newSubscription: validatedValues,
            setInformationSaved,
          })
        );
      }
      if (selectedSubscriptionId) {
        confirm({
          title: 'Update Subscription Data',
          content: 'Are you sure you want to update the existing subscription data?',
          okText: 'Update',
          onOk: () => {
            dispatch(
              subscriptionUpdateRequested({
                subscriptionId: selectedSubscriptionId,
                updatedSubscription: validatedValues,
                setInformationSaved,
                onUpdateSuccess: () => {
                  if (companyId) {
                    dispatch(adminCompanyAllSubscriptionsRequested({ companyId }));
                    setSelectedSubscriptionId();
                  }
                },
              })
            );
          },
          ...CONFIRM_PROPS,
        });
      }
    },
  });

  return (
    <div className="Accounting">
      <div className="Accounting-TabWrapper">
        <Group onChange={onTabChange} value={tab} optionType="button" buttonStyle="solid">
          <TabButton
            value={ACCOUNTING_TABS.FORM}
            onClick={() => history.push(scaniflyAdminAccountingRoute())}
          >
            Form
          </TabButton>
          <TabButton
            value={ACCOUNTING_TABS.TABLE}
            onClick={() => history.push(scaniflyAdminAccountingTableRoute())}
          >
            Table
          </TabButton>
        </Group>
      </div>
      {isFormVisible && (
        <AccountWrapper>
          <h2 className="Title">Customer Info</h2>
          <Row className="Accounting-Form-Row">
            <div className="FormControl-Wrapper">
              <label htmlFor="accountingCustomer">Customer</label>
              <CustomSelect
                {...getFieldProps(FORM_CONTROLS.COMPANY)}
                onChange={(option) => {
                  setFieldValue(FORM_CONTROLS.COMPANY, option.value);
                  setSelectedSubscriptionId();
                  setInformationSaved(false);
                  history.replace(`/scanifly-admin/accounting/${option.value}`);
                }}
                placeholder="Select Company"
                options={companiesNotPopulated.map((company) => ({
                  value: company.id,
                  label: company.name,
                }))}
                loading={isSubscriptionDataLoading}
                variant="filter"
                id="accountingCustomer"
                tall
              />
            </div>
          </Row>
          {companyId && !isSubscriptionDataLoading ? (
            <>
              <Divider />
              <div className="Accounting-Form">
                <AccountingForm
                  getFieldProps={getFieldProps}
                  setFieldValue={setFieldValue}
                  informationSaved={informationSaved}
                  handleSubmit={handleSubmit}
                  setSelectedSubscriptionId={setSelectedSubscriptionId}
                  selectedSubscriptionId={selectedSubscriptionId}
                  setInformationSaved={setInformationSaved}
                  touched={touched}
                  errors={errors}
                />
              </div>
            </>
          ) : companyId ? (
            <div className="Accounting-Spinner">
              <Spin />
            </div>
          ) : (
            ''
          )}
        </AccountWrapper>
      )}
      {!isFormVisible && <AccountingTable />}
    </div>
  );
};

export default Accounting;
