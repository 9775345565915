import { i18n } from 'helpers/utils/translations';

import { FORM_CONTROLS } from '../OrderDesign/DesignSelection/FormWrapper/constants';
import { DESIGN_INFORMATION_FORM } from '../OrderDesign/DesignSelection/FormWrapper/MaxFillForms/constants';
import {
  COMMENTS_FORM,
  ELECTRICAL_INFORMATION_FORM,
  FIRE_SETBACKS,
  OBSTRUCTION_SETBACKS,
  PRELIMINARY_DESIGN_FORM,
  PROJECT_INFORMATION_FORM,
  STRUCTURAL_INFORMATION_FORM,
} from '../OrderDesign/DesignSelection/FormWrapper/PlanSetForms/constants';
import {
  SITE_INFORMATION_FORM,
  SYSTEM_INFORMATION_FORM,
} from '../OrderDesign/DesignSelection/FormWrapper/SitePlanForms/constants';

export type FormData = {
  title: string;
  placeholder?: string | {};
  options?: {
    label: string;
    value: string;
  }[];
  fieldKey?: string;
};

const formList: Record<string, FormData>[] = [
  SITE_INFORMATION_FORM,
  SYSTEM_INFORMATION_FORM,
  PROJECT_INFORMATION_FORM,
  STRUCTURAL_INFORMATION_FORM,
  ELECTRICAL_INFORMATION_FORM,
  COMMENTS_FORM,
  PRELIMINARY_DESIGN_FORM,
  DESIGN_INFORMATION_FORM,
];

const titleArrToFormMap = formList.map((item) => {
  return {
    form: item,
    titles: Object.keys(item),
  };
});

export const getHeader = (field: string): string | undefined => {
  if (field === 'modelRevision') {
    return i18n.t('DesignService.designServiceQueue.viewFormModal.revisionTitle') ?? '';
  }
  if (field === FORM_CONTROLS.FIRE_SETBACKS) {
    return i18n.t('DesignServiceForm.fireSetbacks') ?? '';
  }
  if (field === FORM_CONTROLS.OBSTRUCTION_SETBACKS) {
    return i18n.t('DesignServiceForm.obstructionSetbacks') ?? '';
  }
  if (field === FORM_CONTROLS.NEW_BUILD_PLANS_REQUIRED) {
    return i18n.t('DesignService.areNewBuildPlansRequired') ?? '';
  }
  if (field === FORM_CONTROLS.AZIMUTH_RANGE_END) return;
  const fieldKey = Object.entries(FORM_CONTROLS).find((item) => item.includes(field))?.[0] ?? '';

  return titleArrToFormMap.find((item) => item.titles.includes(fieldKey))?.form?.[fieldKey]?.title;
};

export const getDisplayData = (
  allFromData: any,
  [field, value]: [string, string | Record<string, string> | boolean]
): string | undefined => {
  if (typeof value === 'string') {
    if (field === FORM_CONTROLS.AZIMUTH_RANGE_START) {
      return `${value}° - ${allFromData.azimuthRangeEnd}°`;
    }
    if (field.includes('Spacing')) {
      return `${value}"`;
    }
    if (field === FORM_CONTROLS.SIZED_FOR_CATEGORY) {
      const key = allFromData?.sizedForCategory;
      const value = allFromData?.sizedForValue;
      let header = '';
      let unit = '';

      if (key === 'consumption') {
        header = i18n.t('SizedFor.consumption');
      } else if (key === 'production') {
        header = i18n.t('SizedFor.production');
      } else if (key === 'systemSize') {
        header = i18n.t('SizedFor.systemSize');
      }

      if (allFromData?.negativeTolerance || allFromData?.positiveTolerance) {
        return `${header}: Negative Tolerance: ${allFromData?.negativeTolerance}% Positive Tolerance: ${allFromData?.positiveTolerance}% `;
      } else {
        if (key === 'none') {
          return;
        } else if (key === 'consumption') {
          unit = '%';
        } else if (key === 'production') {
          unit = 'kWh';
        } else if (key === 'systemSize') {
          unit = 'kW';
        }
        return `${header}: ${value}${unit}`;
      }
    }
    return value;
  } else if (typeof value === 'boolean') {
    return allFromData[field]
      ? i18n.t('DesignService.planSet.areItemsUploaded.yes')
      : i18n.t('DesignService.planSet.areItemsUploaded.no');
  } else {
    const allFields = Object.entries(value).map(([key, val]) => {
      let header = '';
      let unit = '';
      if (field === FORM_CONTROLS.FIRE_SETBACKS) {
        header = FIRE_SETBACKS.setbacks.find((item) => item.id === key)?.title ?? '';
        unit = '"';
      }
      if (field === FORM_CONTROLS.OBSTRUCTION_SETBACKS) {
        header = OBSTRUCTION_SETBACKS.setbacks.find((item) => item.id === key)?.title ?? '';
        unit = '"';
      }
      if (header.length === 0) {
        return header;
      }
      return `${header}: ${val}${unit} \n`;
    });
    return allFields.join('');
  }
};
