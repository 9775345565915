import { RootState } from 'state/store/store';
import { MediaCategory } from 'types';

const selectCompanyId = (state: RootState): string | undefined => state.company.company?.id;
const selectCompanyMediaCategories = (state: RootState): MediaCategory[] =>
  state.company.company?.mediaCategories ?? [];
const selectDefaultCompanyMediaCategories = (state: RootState): MediaCategory[] =>
  state.company.defaultCategories;
const selectAllMediaCategories = (state: RootState): MediaCategory[] =>
  [...(state.company.company?.mediaCategories ?? [])].concat(
    state.company?.defaultCategories ?? []
  );

export {
  selectAllMediaCategories,
  selectCompanyId,
  selectCompanyMediaCategories,
  selectDefaultCompanyMediaCategories,
};
