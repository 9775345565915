import { useTranslation } from 'react-i18next';

import { Collapse } from 'antd';
import { capitalize } from 'lodash';
import Revision from 'types/Revision';

import { getFormattedTimeDifference } from 'helpers/utils/getFormattedTimeDifference';
import { i18n } from 'helpers/utils/translations';

import './ViewRevisionsModal.scss';

const Panel = Collapse.Panel;

const getHeader = (field: string): string => {
  if (field === 'createdAt') {
    return i18n.t('DesignService.designServiceQueue.viewFormModal.createdAt');
  }

  if (field === 'completedAt') {
    return i18n.t('DesignService.designServiceQueue.viewFormModal.completedAt');
  }

  return capitalize(field);
};

const modifyDataDisplay = ([key, value]: [string, string | string[] | Date]): string => {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return i18n.t('DesignService.designServiceQueue.viewFormModal.noRevisionData');
    }
    return value.join('\n');
  }

  if (key === 'completedAt') {
    return new Date(value).toLocaleDateString('en-US');
  }

  return typeof value === 'string' ? value : value.toLocaleDateString('en-US');
};

const header = (revision: Revision, totalLength: number, index: number) => {
  return (
    <div className="ViewRevisionsModal-RevisionHeader">
      <h3 className="title">{`Revision-${totalLength - index}`}</h3>
      <h3 className="date">
        {i18n.t('ViewRevisions.requestedAt', {
          date: revision?.createdAt
            ? new Date(revision.createdAt ?? '').toLocaleDateString('en-US')
            : '--',
        })}
      </h3>
    </div>
  );
};

const ViewRevisionsModal = ({
  revisionData,
  projectName,
}: {
  revisionData: Revision | Revision[];
  projectName: string;
}) => {
  const { t } = useTranslation();
  const revisionDataArr = Array.isArray(revisionData)
    ? [...revisionData].reverse()
    : [revisionData];

  return (
    <section>
      <h2>{t('ViewRevisions.project', { projectName: projectName })}</h2>
      <div className="ViewRevisionsModal-CollapseWrapper">
        {revisionDataArr.map((revision, index) => (
          /* @ts-ignore */
          <Collapse
            bordered={false}
            className="ViewRevisionsModal-Wrapper"
            key={`collapse-${index}`}
          >
            {/* @ts-ignore */}
            <Panel header={header(revision, revisionDataArr.length, index)}>
              <hr />
              {Object.keys(revision).length === 0
                ? t('DesignService.designServiceQueue.viewFormModal.notFound')
                : null}
              {Object.entries(revision)
                .filter((data) => data[0] !== 'createdAt')
                .map((data, index) => {
                  return (
                    /* @ts-ignore */
                    <div key={`reasons-${index}`} className="ViewRevisionsModal-DataWrapper">
                      <h3>{getHeader(data[0])}</h3>
                      <div className="ViewRevisionsModal-Data">{modifyDataDisplay(data)}</div>
                    </div>
                  );
                })}
              {revision?.createdAt && revision?.completedAt ? (
                <>
                  <h3>{t('ViewRevisions.turnaroundTime')}</h3>
                  {`${getFormattedTimeDifference(
                    new Date(revision.completedAt),
                    new Date(revision.createdAt)
                  )}`}
                </>
              ) : null}
            </Panel>
          </Collapse>
        ))}
      </div>
    </section>
  );
};

export default ViewRevisionsModal;
