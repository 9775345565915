import { Card } from 'antd';
import PropTypes from 'prop-types';

import { GoBackButton } from 'components';

import './AccountWrapper.scss';

const AccountWrapper = ({ children }) => {
  return (
    <div className="AccountWrapper">
      <div className="AccountWrapper-GoBackButton">
        <GoBackButton />
      </div>

      <Card className="Card--Small AccountWrapper-Card">
        <div className="AccountWrapper-GoBackButton--SmallScreen">
          <GoBackButton />
        </div>
        {children}
      </Card>
    </div>
  );
};

AccountWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountWrapper;
