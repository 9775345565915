import { useTranslation } from 'react-i18next';

import { Button, Input, Row, Tooltip } from 'antd';
import cn from 'classnames';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';

import { handleNotAllowedCharactersInput } from 'helpers/utils/formValidationHelpers';

import { ReactComponent as ThunderIcon } from 'assets/icons/thunder-icon.svg';

import { QUARTERS } from '../constants';
import './CustomerInfoUsage.scss';
import CustomerInfoUsageChart from './CustomerInfoUsageChart';
import { getAnnualUsage, getQUsage } from './helpers';

const CustomerInfoUsage = ({ usage, getFieldProps, handleInputChange, showModal }) => {
  const annualUsage = getAnnualUsage(usage);
  const { t } = useTranslation();

  const renderQuarterInputs = (quarter) => {
    const [name] = Object.keys(quarter);
    const [months] = Object.values(quarter);
    const value = getQUsage(months, usage);

    return (
      <Row key={name}>
        {months.map((month) => (
          <div className="FormControl-Wrapper" key={month}>
            <label htmlFor="month">{upperFirst(month)}</label>
            <Input
              type="number"
              placeholder="kWh"
              {...getFieldProps(`usage.${month}`)}
              onKeyPress={handleNotAllowedCharactersInput}
              onChange={(event) => handleInputChange(event.target.value, `usage.${month}`)}
              id="month"
              data-testid={`consumption-data-month-${month}`}
            />
          </div>
        ))}
        <div
          className={cn({
            'FormControl-Wrapper': true,
            'CustomerInfoUsage-Form-SafariOverride': Boolean(value),
          })}
        >
          <label htmlFor="name">{upperFirst(name)}</label>
          <Input placeholder="kWh" id="name" aria-disabled="true" disabled value={value} />
        </div>
      </Row>
    );
  };

  return (
    <div className="CustomerInfoUsage">
      <div className="CustomerInfoUsage-Form">
        {QUARTERS.map(renderQuarterInputs)}
        <div className="CustomerInfoUsage-AnnualUsage">
          <div className="CustomerInfoUsage-AnnualUsage-Input">
            <ThunderIcon />
            <span className="CustomerInfoUsage-AnnualUsage-Message">
              Annual<span> Consumption</span>:
            </span>
            {annualUsage <= 10000 ? (
              `${annualUsage.toLocaleString()} kWh`
            ) : (
              <Tooltip placement="top" title={`${annualUsage.toLocaleString()} kWh`}>
                {`${(annualUsage / 1000).toFixed(1)} MWh`}
              </Tooltip>
            )}
          </div>
          <Button className="Button--White" disabled={annualUsage <= 0} onClick={showModal}>
            {t('ProjectConsumptionInfo.clearModal.button')}
          </Button>
        </div>
      </div>
      {annualUsage > 0 ? <CustomerInfoUsageChart values={usage} /> : null}
    </div>
  );
};

CustomerInfoUsage.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  usage: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default CustomerInfoUsage;
