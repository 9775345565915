import React from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

interface EditButtonProps {
  onClick?: () => void;
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

export const AddButton: React.FC<EditButtonProps> = ({ onClick }: EditButtonProps) => {
  return (
    <Button type="button" onClick={onClick}>
      <PlusIcon />
    </Button>
  );
};
