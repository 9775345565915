// eslint-disable-next-line import/no-anonymous-default-export
export default {
  xxs: '370px',
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};
