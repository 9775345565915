import { Link } from 'react-router-dom';

const InternalScrollableLink = ({
  route,
  text,
  hash,
}: {
  route: string;
  text: string;
  hash?: string;
}) => {
  return <Link to={`${route}${hash ?? ''}`}>{text}</Link>;
};

export default InternalScrollableLink;
