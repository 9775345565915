import { Switch } from 'antd';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const Wrapper = styled.div`
  position: absolute;
  top: 7.8rem;
  right: 5.2rem;
  width: 42rem;
  max-height: 52rem;
  background-color: white;
  border: 0.1rem solid #cfcfcf;
  border-radius: 1.2rem;
  padding: 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

const NotificationHeader = styled.div`
  width: 110%;
  display: flex;
  justify-content: space-between;
  margin-left: -4rem;
`;

const NotificationList = styled.ul`
  list-style: none;
`;

const StyledTabWrapper = styled.div`
  height: 1px;
  display: flex;
  width: 110%;
  margin-left: -4rem;
  background-color: #cfcfcf;
  margin-top: -0.89rem;
  margin-bottom: 1.2rem;
`;

const MentionTabTitle = styled.h4`
  margin-left: -4rem;
  border-bottom: 3px solid blue;
  flex: 3;
  width: 75px;
  color: ${colors.mainBlue};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -3.8rem;
  justify-content: space-between;
`;

const Day = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #73788a;
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  color: #73788a;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-top: -0.63rem;
  cursor: pointer;
`;
const BottomButton = styled(Button)`
  align-self: center;
  width: 115%;
  border-top: 1px solid #cfcfcf;
  margin-left: -4rem;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  position: sticky;
  background-color: white;
  bottom: -20px;
  margin-bottom: -30px;
  margin-top: 5px;
`;

const NoNotificationMessage = styled.h5`
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  margin-left: -3rem;
`;

const SpacingDiv = styled.div`
  height: 136px;
  opacity: 0;
`;

const ToggleTitle = styled.span`
  display: flex;
  cursor: pointer;
  justify-content: right;
  margin-left: 4rem;
  margin-top: 0.3rem;
`;

const StyledSwitch = styled(Switch)`
  margin-top: 0.45rem;
`;

export {
  BottomButton,
  Button,
  Day,
  MentionTabTitle,
  NoNotificationMessage,
  NotificationHeader,
  NotificationList,
  Row,
  SpacingDiv,
  StyledSwitch,
  StyledTabWrapper,
  ToggleTitle,
  Wrapper,
};
