import { useHistory } from 'react-router-dom';

import { Button } from 'antd';

import { scaniflyAdminNewProjectCustomerInfoRoute } from 'helpers/constants/routes';

import { ReactComponent as LargeArrow } from 'assets/icons/arrow-large.svg';

const UploadProjectButton = () => {
  const history = useHistory();
  const handleNewProjectClick = () => history.push(scaniflyAdminNewProjectCustomerInfoRoute());

  return (
    <Button className="Button--Blue" onClick={handleNewProjectClick}>
      <LargeArrow />
      Upload Project
    </Button>
  );
};

export default UploadProjectButton;
