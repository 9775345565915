import '../PublicReportDisplay.scss';

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <section className="PublicReportDisplay-SectionHeader">
      <h2>{title}</h2>
    </section>
  );
};

export default SectionHeader;
