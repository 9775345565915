import { Input, Row } from 'antd';
import PropTypes from 'prop-types';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

const { Password } = Input;

const SignUpLoginCredentials = ({ touched, errors, getFieldProps, userExistsError }) => {
  return (
    <>
      <h2 className="SignUp-Form-Title SignUp-Form-Title--Second">Login Credentials</h2>

      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper SignUp-Form-Wrapper">
          <label htmlFor="signUpEmail">Your Email</label>
          <Input
            disabled
            aria-disabled="true"
            id="signUpEmail"
            {...getFieldProps(FORM_CONTROLS.EMAIL)}
          />
          <div className="Form-Error Form-Error--Long">{userExistsError}</div>
        </div>
      </Row>

      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper SignUp-Form-Wrapper">
          <label htmlFor="signUpPassword">Your Password</label>
          <Password
            placeholder="Your Password"
            className={validateStatus(touched, errors, FORM_CONTROLS.PASSWORD)}
            id="signUpPassword"
            {...getFieldProps(FORM_CONTROLS.PASSWORD)}
          />
          <div className="Form-Error Form-Error--Long">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.PASSWORD)}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="signUpPasswordConfirm">Confirm Your Password</label>
          <Password
            placeholder="Confirm Your Password"
            className={validateStatus(touched, errors, FORM_CONTROLS.CONFIRM_PASSWORD)}
            id="signUpPasswordConfirm"
            {...getFieldProps(FORM_CONTROLS.CONFIRM_PASSWORD)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CONFIRM_PASSWORD)}
          </div>
        </div>
      </Row>
    </>
  );
};

SignUpLoginCredentials.propTypes = {
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  getFieldProps: PropTypes.func.isRequired,
  userExistsError: PropTypes.string,
};

export default SignUpLoginCredentials;
