import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldTypeUnion, SectionType } from '@cpm/scanifly-shared-data';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

import { Button, ModalContext } from 'components';
import OptionsButton from 'components/OptionsButton/OptionsButton';

import colors from 'helpers/constants/colors';

import { ReactComponent as DownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as UpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';

type ItemTitleProps = {
  active?: boolean;
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.1;
  align-self: flex-start;
  max-height: 80vh;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  flex: 1;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  min-width: 20rem;
  overflow: hidden auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  display: inline-block;
`;

const Row = styled.div<{ index: number }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 0px;
  border: none;
  background-color: transparent;

  ${({ index }) => {
    if (index !== 0) return `border-top: 1px solid ${colors.neutralGray};`;
  }}
`;

type SectionTitleProps = {
  /**
   * If text is really long don't set `nowrap` otherwise
   * it will occupy too much of the screen
   */
  nowrap: boolean;
};
const SectionTitle = styled.button<SectionTitleProps>`
  display: flex;
  flex: 1;
  font-size: 15px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-left: 1rem;
  text-align: left;
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'normal')};
`;
const ItemTitle = styled.span<ItemTitleProps>`
  font-weight: ${({ active }) => (active ? '600' : '400')};
`;

const AlignRight = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const IconContainer = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

const AddSectionButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  margin-top: 1rem;
  cursor: pointer;
`;

const PlusIconContainer = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  margin: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const StyledUpIcon = styled(UpIcon)`
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 2rem;
  ${IconContainer}: hover & {
    fill: ${colors.mainBlue};
  }
`;

const StyledDownIcon = styled(DownIcon)`
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 2rem;
  ${IconContainer}: hover & {
    fill: ${colors.mainBlue};
  }
`;

// Leaving this outside of the component for now, it should probably be added to the shared data repo with tests eventually
const cloneAndUpdateFields = (fields: FieldTypeUnion[]): FieldTypeUnion[] => {
  // Create a map to track old IDs to new IDs
  const idMap: Record<string, string> = {};

  // Clone each field with a new UUID
  const clonedFields = fields.map((field) => {
    const newId = uuidv4();
    idMap[field.id] = newId;
    return { ...JSON.parse(JSON.stringify(field)), id: newId };
  });

  // Update the targetFieldId in the conditional property
  return clonedFields.map((field) => {
    if (field?.conditional?.isEqual) {
      const oldTargetId = field.conditional.isEqual.targetFieldId;
      const newTargetId = idMap[oldTargetId];
      if (newTargetId) {
        field.conditional.isEqual.targetFieldId = newTargetId;
      }
    }
    return field;
  });
};

export const SectionContent = () => {
  const { t } = useTranslation();
  const {
    createSection,
    deleteSection,
    moveSection,
    updateSectionTitle,
    sections,
    setActiveSection,
    activeSection,
    handleSectionDrop,
    handleDragStart,
    handleDragOver,
  } = useContext(ChecklistTemplateContext);
  const { displayInputModal, displayDeleteModal } = useContext(ModalContext);

  const RenderButton = ({
    component,
    onClick,
  }: {
    component: React.ReactElement;
    onClick: () => void;
  }) => {
    return <IconContainer onClick={onClick}>{component}</IconContainer>;
  };

  const onRenameSectionClick = useCallback(
    (section: SectionType) => {
      const renameSectionSubmit = (input: string) => {
        updateSectionTitle(section.id, input);
      };
      displayInputModal({
        title: t('Checklists.renameSection'),
        description: t('Checklists.renameSectionDescription'),
        placeholderText: section.title,
        defaultValue: section.title,
        actionButtonOnClick: renameSectionSubmit,
        actionButtonLabel: t('Checklists.renameSection'),
      });
    },
    [displayInputModal, updateSectionTitle, t]
  );

  const onCopySectionClick = useCallback(
    (section: SectionType) => {
      const copySectionSubmit = (input: string) => {
        const sectionId = uuidv4();
        const newSection = {
          id: sectionId,
          title: input,
          components: cloneAndUpdateFields(section.components),
        };
        createSection(newSection);
        setActiveSection(sectionId);
      };
      displayInputModal({
        title: t('Checklists.copySection'),
        description: t('Checklists.copySectionDescription'),
        placeholderText: section.title,
        actionButtonOnClick: copySectionSubmit,
        actionButtonLabel: t('Checklists.createCopy'),
      });
    },
    [createSection, displayInputModal, setActiveSection, t]
  );

  const onDeleteSectionClick = useCallback(
    (section: SectionType) => {
      const deleteSectionSubmit = () => {
        deleteSection(section.id);
      };
      displayDeleteModal({
        title: t('Checklists.deleteSection'),
        description: t('Checklists.deleteSectionDescription', { title: section.title }),
        actionButtonOnClick: deleteSectionSubmit,
        actionButtonLabel: t('Checklists.deleteSection'),
      });
    },
    [displayDeleteModal, deleteSection, t]
  );

  const renderRows = useMemo(
    () =>
      sections.map((item, index) => {
        return (
          <Row
            draggable
            key={item.id}
            onDragStart={handleDragStart(index)}
            onDragOver={handleDragOver}
            onDrop={handleSectionDrop(index)}
            index={index}
          >
            <SectionTitle
              nowrap={item.title.length < 30}
              id={`sectionTitle-${index}`}
              onClick={() => setActiveSection(item.id)}
            >
              <ItemTitle active={activeSection?.id === item.id}>{item.title}</ItemTitle>
            </SectionTitle>
            <AlignRight>
              <RenderButton onClick={() => moveSection(item.id, -1)} component={<StyledUpIcon />} />
              <RenderButton
                onClick={() => moveSection(item.id, 1)}
                component={<StyledDownIcon />}
              />
              <OptionsButton border="none" padding={'0'}>
                <Button
                  border="none"
                  icon="copy"
                  onClick={() => onCopySectionClick(item)}
                  label={t('Checklists.copy')}
                />
                <Button
                  border="none"
                  icon="rename"
                  onClick={() => onRenameSectionClick(item)}
                  label={t('Checklists.rename')}
                />
                <Button
                  border="none"
                  icon="delete"
                  onClick={() => onDeleteSectionClick(item)}
                  label={t('Checklists.delete')}
                />
              </OptionsButton>
            </AlignRight>
          </Row>
        );
      }),
    [
      handleDragOver,
      handleSectionDrop,
      handleDragStart,
      moveSection,
      sections,
      onRenameSectionClick,
      onCopySectionClick,
      onDeleteSectionClick,
      setActiveSection,
      t,
      activeSection?.id,
    ]
  );

  const onAddSectionClick = useCallback(() => {
    const createSectionSubmit = (input: string) => {
      const sectionId = uuidv4();
      const newSection = {
        id: sectionId,
        title: input,
        components: [],
      };
      createSection(newSection);
      setActiveSection(sectionId);
    };
    displayInputModal({
      title: t('Checklists.newSection'),
      description: t('Checklists.newSectionDescription'),
      placeholderText: t('Checklists.newSectionInputPlaceholder') ?? undefined,
      actionButtonOnClick: createSectionSubmit,
      actionButtonLabel: t('Checklists.createSection'),
    });
  }, [createSection, displayInputModal, setActiveSection, t]);

  return (
    <Container id="sectionContainer">
      <Title>{t('Checklists.sectionsHeader')}</Title>
      <InnerContainer id="sectionContent">{renderRows}</InnerContainer>
      <AddSectionButton onClick={onAddSectionClick}>
        <PlusIconContainer>
          <PlusIcon />
        </PlusIconContainer>
        {t('Checklists.addSection')}
      </AddSectionButton>
    </Container>
  );
};
