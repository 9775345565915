import styled from 'styled-components';

const TextWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
`;

type Props = {
  text: string;
};

export const Description = ({ text }: Props) => {
  return <TextWrapper>{text}</TextWrapper>;
};
