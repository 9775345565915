import { useEffect, useState } from 'react';

export const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState<{ width: number | null; height: number | null }>(
    {
      width: null,
      height: null,
    }
  );
  useEffect(() => {
    function handleResize() {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return viewportSize;
};
