import PropTypes from 'prop-types';

export const teammatePropTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.string,
  photoUrl: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  position: PropTypes.string,
  positionDescription: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  foldersAccess: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});
