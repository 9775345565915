import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';

export const renderProjectType = (type) => {
  if (
    type === PROJECT_TYPES.SMALL_COMMERCIAL ||
    type === PROJECT_TYPES.RESIDENTIAL ||
    type === PROJECT_TYPES.SMALL
  ) {
    return PROJECT_TYPE_FILTERS.find((filter) => filter.value === PROJECT_TYPES.SMALL).text;
  } else if (
    type === PROJECT_TYPES.COMMERCIAL ||
    type === PROJECT_TYPES.UTILITY ||
    type === PROJECT_TYPES.COMMERCIALRC ||
    type === PROJECT_TYPES.LARGE
  ) {
    return PROJECT_TYPE_FILTERS.find((filter) => filter.value === PROJECT_TYPES.LARGE).text;
  } else {
    return '-';
  }
};
