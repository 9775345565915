import { i18n } from 'helpers/utils/translations';

export const getDisclaimerText = ({
  isPrelim = false,
  isRevision = false,
}: {
  isPrelim?: boolean;
  isRevision?: boolean;
}) => {
  if (isRevision) {
    return i18n.t('DesignService.revisionRequestDisclaimer');
  }
  if (isPrelim) {
    return i18n.t('DesignService.newBuildDisclaimerText');
  }
  return '';
};
