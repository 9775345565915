import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Button, Spin, Tooltip } from 'antd';

import { currentUserAvatarUpdated } from 'state/slices/usersSlice';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { ReactComponent as UserAvatarIcon } from 'assets/user-avatar.svg';

import './AccountProfileAvatar.scss';

const AccountProfileAvatar = () => {
  const uploadAvatarInput = useRef(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { currentUserAvatar } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const changeHandler = ({ target }) => {
    const [avatar] = target.files;
    if (avatar) {
      setUploadInProgress(true);
      const newAvatar = new FormData();
      newAvatar.append('file', avatar);
      dispatch(currentUserAvatarUpdated(newAvatar));
    }
  };

  const handleUploadAvatar = () => {
    uploadAvatarInput.current.click();
  };

  useEffect(() => {
    setUploadInProgress(false);
  }, [currentUserAvatar]);

  return (
    <>
      {uploadInProgress && (
        <div className="AccountProfileAvatar-Spinner">
          <Spin size="medium" />
        </div>
      )}
      <div className="AccountProfileAvatar-Wrapper">
        {!uploadInProgress && currentUserAvatar ? (
          <Avatar
            alt="Avatar"
            src={`data:image/png;base64,${currentUserAvatar}`}
            className="AccountProfileAvatar"
          />
        ) : (
          <UserAvatarIcon className="AccountProfileAvatar" />
        )}
        <div className="AccountProfileAvatar-Button-Wrapper">
          <input
            className="AccountProfileAvatar-UploadInput"
            ref={uploadAvatarInput}
            type="file"
            accept="image/png, image/jpeg"
            name="file"
            onChange={changeHandler}
          />
          <Button onClick={handleUploadAvatar} className="Button--Dark AccountProfileAvatar-Button">
            Upload Image
          </Button>
          <Tooltip
            placement="topLeft"
            title="The image file should be PNG or JPG format, recommended size 300 x 300 pixels"
            arrowPointAtCenter
          >
            <QuestionMarkIcon />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default AccountProfileAvatar;
