import cn from 'classnames';

import DropdownArrow from 'assets/icons/dropdown-arrow.svg';

import { ReactElement } from 'react';
import MenuItem from '../MenuItem';
import './CollapsibleMenu.scss';

type Props = {
  label: string;
  icon: JSX.Element;
  id: string;
  link: string;
  disabled: boolean;
  isSidebarCollapsed: boolean;
  children: ReactElement[];
  expanded: boolean;
  expandMenuItem: (label: string) => void;
};

const CollapsibleMenu = ({
  label,
  icon,
  id,
  link,
  disabled,
  isSidebarCollapsed,
  children,
  expanded = false,
  expandMenuItem,
}: Props) => {
  const idToUse = `Collapsible-Menu-${id}`;
  return (
    <div
      className={cn(
        'Collapsible-Menu',
        { 'Collapsible-Menu--disabled': disabled },
        { 'Collapsible-Menu--icon-only': isSidebarCollapsed }
      )}
    >
      <MenuItem
        key={id}
        icon={icon}
        disabled={disabled}
        isCollapsed={isSidebarCollapsed}
        link={link}
        className={cn({ 'Collapsible-Menu-Main-Button': !isSidebarCollapsed })}
        title={label}
      >
        {label}
      </MenuItem>
      <input
        type="checkbox"
        checked={expanded}
        onClick={() => expandMenuItem(label)}
        id={idToUse}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
      <label className="Collapsible-Menu-Label" htmlFor={idToUse}>
        <img alt="" src={DropdownArrow} className="Collapsible-Menu-Label-Arrow" />
      </label>
      <div
        className={cn(
          'content',
          { 'content--hidden': isSidebarCollapsed },
          { 'content--expanded': !isSidebarCollapsed }
        )}
      >
        {children?.length &&
          children.map((child, index) => (
            <div
              key={`child-${index}`}
              className={cn(
                'content-child',
                { 'content-child--enabled': !disabled },
                { 'content-child--disabled': disabled }
              )}
            >
              {child}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CollapsibleMenu;
