import {
  getAdminProjectRoute,
  goToProjectDetails,
} from 'screens/Projects/ProjectsList/ProjectTile/helpers';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';

export const getLink = (tableName, id, status, statusDescription) => {
  if (tableName === TABLE_NAMES.PROJECTS && status.startsWith('admin')) {
    return getAdminProjectRoute(id, status);
  }
  return goToProjectDetails(id, statusDescription, () => {});
};
