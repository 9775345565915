import { SettingsShell } from '@cpm/settings-ui';
import breakpoints from 'helpers/constants/breakpoints';
import styled from 'styled-components';

// Matching positioning and responsiveness of other Settings pages
export const Wrapper = styled.div`
  padding: 6em;
  @media (max-width: ${breakpoints.lg}) {
    padding: 2em;
  }
`;
// Don't need background/border for this one since Settings takes care of that
export const Container = styled.div`
  margin: 4em auto;
`;
export const ButtonContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
`;

export const Shell = styled(SettingsShell)`
  width: 100%;
  height: auto;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;
