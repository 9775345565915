import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Modal } from 'antd';
import ServiceRequest from 'types/ServiceRequest';

import { deleteNearmapsRequested } from 'state/slices/nearmaps/nearmapsSlice';
import { AppDispatch } from 'state/store/store';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import '../ConfirmDeleteModal/ConfirmDeleteModal.scss';

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
  serviceRequest?: ServiceRequest;
};

const DeleteNearmapModal = ({ isModalOpen, toggleModal, serviceRequest }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccess = (id: string) => {
    openNotification({
      type: 'success',
      title: t('DesignService.nearmapDeleteSuccessTitle'),
      text: t('DesignService.nearmapDeleteSuccessContent', {
        id: id ?? '',
      }),
    });
  };

  const onError = (id: string, errorMessage?: string) => {
    openNotification({
      type: 'error',
      title: t('DesignService.nearmapDeleteErrorTitle'),
      text: t('DesignService.nearmapDeleteErrorContent', {
        id: id ?? '',
        errorMessage: errorMessage ?? '',
      }),
    });
  };

  const handleDeleteServiceRequest = () => {
    if (serviceRequest) {
      dispatch(
        deleteNearmapsRequested({
          projectId: serviceRequest.project.id,
          onSuccess,
          onError,
        })
      );
      toggleModal();
    }
  };

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isModalOpen}
      onCancel={toggleModal}
      className="ConfirmDeleteModal"
      {...MODAL_PROPS}
    >
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <>
        <h2 className="ConfirmDeleteModal-Title">{t('DesignService.deleteNearmapModalTitle')}</h2>
        <p>
          {t('DesignService.deleteNearmapModalContent', {
            projectName: serviceRequest?.project?.name ?? '',
            projectId: serviceRequest?.project?.id ?? '',
          })}
        </p>
      </>

      <div className="ConfirmDeleteModal-Buttons-Wrapper">
        <Button onClick={toggleModal} className="Button--White">
          {t('DesignService.cancel')}
        </Button>
        <Button onClick={handleDeleteServiceRequest} className="Button--Blue">
          {t('DesignService.delete')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteNearmapModal;
