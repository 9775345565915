enum FormFields {
  note = 'note',
  recipients = 'recipients',
}

type FormValues = {
  note: string;
  recipients: string[];
};

const InitialValues = {
  note: '',
  recipients: [],
};

const LABELS = {
  ADD: 'add comment',
  EDIT: 'edit comment',
};

export { FormFields, InitialValues, LABELS };
export type { FormValues };
