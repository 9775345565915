import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

export const trueUpValidationSchemaWithExistingDesign = () =>
  Yup.object().shape({
    [FORM_CONTROLS.SIZED_FOR_CATEGORY]: Yup.string()
      .required('*This field is required')
      .test(...noWhitespaces),
    [FORM_CONTROLS.NEGATIVE_TOLERANCE]: Yup.string()
      .required('*This field is required')
      .test(...noWhitespaces),
    [FORM_CONTROLS.POSITIVE_TOLERANCE]: Yup.string()
      .required('*This field is required')
      .test(...noWhitespaces),
  });
