import { Input } from 'antd';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import fontSizes from 'helpers/constants/fontSizes';
import { debounce } from 'lodash';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  flex-grow: 0.3;
  margin: 0 0.5rem 0 2rem;
  height: 3.8rem;

  &-prefix {
    width: 1.7rem;
    margin-right: 1.2rem;
  }

  .ant-input {
    font-size: ${fontSizes.medium};
    margin-left: 0.5rem;
  }
`;

export const SearchBar = ({
  handleSearchTextInputChange,
}: {
  handleSearchTextInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();
  return (
    <StyledInput
      placeholder={t('Shared.searchNameOrRole')}
      role="searchbox"
      prefix={<SearchIcon />}
      aria-label={t('Shared.searchNameOrRole')}
      onChange={debounce((e) => handleSearchTextInputChange(e), 400)}
    />
  );
};
