import { FormikProps } from 'formik';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { SIZED_FOR_CATEGORIES } from 'screens/DesignServices/constants';
import styled from 'styled-components';
import { FormInput } from '../components';
import { FORM_CONTROLS } from '../constants';
import {
  CategoryInputWrapper,
  getCorrectPlaceholderText,
} from '../SitePlanForms/ProjectInfoSitePlan';
import { TRUE_UP_FORM } from './constants';

const SizedForInputWrapper = styled(CategoryInputWrapper)`
  #sizedForCategory {
    min-width: 16rem;
  }
`;

const SizedForTrueUp = ({
  getFieldProps,
  setFieldValue,
  touched,
  errors,
  t,
}: { t: TFunction<'translation', undefined> } & Partial<FormikProps<Record<string, string>>>) => {
  const [placeholder, setPlaceholder] = useState<string>(SIZED_FOR_CATEGORIES.none);
  if (!getFieldProps || !setFieldValue || !touched || !errors) {
    return <div></div>;
  }

  return (
    <SizedForInputWrapper>
      {/* Sized for category */}
      <FormInput
        title={`${TRUE_UP_FORM.SIZED_FOR_CATEGORY.title}${getFieldProps(FORM_CONTROLS.SIZED_FOR_CATEGORY).value !== SIZED_FOR_CATEGORIES.none ? '*' : ''}`}
        fieldProps={getFieldProps(FORM_CONTROLS.SIZED_FOR_CATEGORY)}
        id={FORM_CONTROLS.SIZED_FOR_CATEGORY}
        customSelect
        options={TRUE_UP_FORM.SIZED_FOR_CATEGORY_FOR_NO_DESIGN_SELECTED.options}
        defaultValue={SIZED_FOR_CATEGORIES.none}
        onChange={(option: {
          key: string;
          value: keyof typeof TRUE_UP_FORM.SIZED_FOR_VALUE.placeholder;
        }) => {
          setFieldValue(FORM_CONTROLS.SIZED_FOR_CATEGORY, option.value);
          const currentPlaceHolder = getCorrectPlaceholderText(option.value);
          setPlaceholder(currentPlaceHolder);
        }}
        filter
        ariaLabel={t('SizedFor.categoryAriaLabel')}
      />

      {/* Sized for value */}
      <FormInput
        fieldProps={getFieldProps(FORM_CONTROLS.SIZED_FOR_VALUE)}
        title=""
        placeHolder={placeholder}
        id={FORM_CONTROLS.SIZED_FOR_VALUE}
        className={validateStatus(touched, errors, FORM_CONTROLS.SIZED_FOR_VALUE)}
        errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.SIZED_FOR_VALUE)}
        type="number"
        step="1"
        min="0"
        onKeyDown={(e: KeyboardEvent) => {
          if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        ariaLabel={t('SizedFor.valueAriaLabel')}
      />
    </SizedForInputWrapper>
  );
};

export default SizedForTrueUp;
