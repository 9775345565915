import { COMPANY_SIZE, INVOICE_STATUS, PAYMENT_TYPE } from '@cpm/scanifly-shared-data';
import { capitalize } from 'lodash';
import { Subscription } from 'types';

import { i18n } from 'helpers/utils/translations';

export const ACCOUNTING_TABS = {
  FORM: 'form',
  TABLE: 'table',
};

export const RENEWAL_CUTOFF_PERCENTAGE = 0.75;
export const UPGRADE_CUTOFF_PERCENTAGE = 0.15;
export const DS_CUTOFF_PERCENTAGE = 0.2;

export const FORM_CONTROLS = {
  COMPANY: 'companyId',
  SUBSCRIPTION_ID: 'id',
  CONTRACT: 'hubspotUrl',
  CONTRACT_END: 'contractEndDate',
  CONTRACT_START: 'contractStartDate',
  DISCOUNT: 'discountPercentage',
  SUBSCRIPTION: 'subscriptionAmount',
  NOTES: 'notes',
  // Scanifly Owner is the Scanifly Sales Rep
  OWNER: 'scaniflyOwner',
  ACCOUNT_MANAGER: 'scaniflyAccountManager',
  PROJECT_QUOTA_LG: 'largeProjectQuota',
  PROJECT_QUOTA_SM: 'smallProjectQuota',
  COMPANY_SIZE: 'companySize',
  PAYMENT_TYPE: 'paymentType',
  DSP_PAYMENT_TYPE: 'dspPaymentType',
  DSP_TIER_SMALL: 'dspTierSmall',
  DSP_TIER_LARGE: 'dspTierLarge',
  DSP_SUBSCRIPTION_FEE_LARGE: 'dspSubscriptionFeeLarge',
  DSP_SUBSCRIPTION_FEE_SMALL: 'dspSubscriptionFeeSmall',
  TOTAL_CREDITS: 'totalCredits',
  CAN_USE_REMOTE_DESIGN: 'canUseRemoteDesign',
  CAN_USE_MAINTENANCE: 'canUseMaintenance',
  AUTOMATED_WIREFRAME_PRELIM: 'automatedWireframePrelim',
  AUTOMATED_TRUE_UP: 'automatedTrueUp',
  AUTOMATED_WIREFRAME_SM_ONSITE: 'automatedWireframeSmallOnsite',
  AUTOMATED_WIREFRAME_LG_ONSITE: 'automatedWireframeLargeOnsite',
  PLAN_SET_PROVIDER: 'planSetProvider',
} as const;

export const initialValues = {
  [FORM_CONTROLS.COMPANY]: '',
  [FORM_CONTROLS.SUBSCRIPTION_ID]: '',
  [FORM_CONTROLS.CONTRACT]: '',
  [FORM_CONTROLS.CONTRACT_END]: '',
  [FORM_CONTROLS.CONTRACT_START]: '',
  [FORM_CONTROLS.DISCOUNT]: 0,
  [FORM_CONTROLS.SUBSCRIPTION]: 0,
  [FORM_CONTROLS.NOTES]: '',
  [FORM_CONTROLS.OWNER]: '',
  [FORM_CONTROLS.ACCOUNT_MANAGER]: undefined,
  [FORM_CONTROLS.PROJECT_QUOTA_LG]: 0,
  [FORM_CONTROLS.PROJECT_QUOTA_SM]: 0,
  [FORM_CONTROLS.PAYMENT_TYPE]: PAYMENT_TYPE.monthly,
  [FORM_CONTROLS.DSP_PAYMENT_TYPE]: '',
  [FORM_CONTROLS.DSP_TIER_SMALL]: 0,
  [FORM_CONTROLS.DSP_TIER_LARGE]: 0,
  [FORM_CONTROLS.DSP_SUBSCRIPTION_FEE_LARGE]: 0,
  [FORM_CONTROLS.DSP_SUBSCRIPTION_FEE_SMALL]: 0,
  [FORM_CONTROLS.TOTAL_CREDITS]: 0,
  [FORM_CONTROLS.CAN_USE_MAINTENANCE]: false,
  [FORM_CONTROLS.CAN_USE_REMOTE_DESIGN]: false,
  [FORM_CONTROLS.AUTOMATED_WIREFRAME_PRELIM]: false,
  [FORM_CONTROLS.AUTOMATED_WIREFRAME_SM_ONSITE]: false,
  [FORM_CONTROLS.AUTOMATED_WIREFRAME_LG_ONSITE]: false,
  [FORM_CONTROLS.AUTOMATED_TRUE_UP]: false,
};

export const toFormData = (subscription: Subscription) =>
  Object.fromEntries(Object.values(FORM_CONTROLS).map((value) => [value, subscription[value]]));

export const INVOICE_STATUS_FILTERS = [
  {
    text: capitalize(INVOICE_STATUS.draft),
    value: INVOICE_STATUS.draft,
  },
  {
    text: capitalize(INVOICE_STATUS.open),
    value: INVOICE_STATUS.open,
  },
  {
    text: capitalize(INVOICE_STATUS.paid),
    value: INVOICE_STATUS.paid,
  },
  {
    text: capitalize(INVOICE_STATUS.failed),
    value: INVOICE_STATUS.failed,
  },
  {
    text: capitalize(INVOICE_STATUS.uncollectible),
    value: INVOICE_STATUS.uncollectible,
  },
  {
    text: capitalize(INVOICE_STATUS.void),
    value: INVOICE_STATUS.void,
  },
  {
    text: 'Not created',
    value: 'not created',
  },
];

export const COMPANY_SIZE_FILTERS = [
  {
    text: capitalize(COMPANY_SIZE.small),
    value: COMPANY_SIZE.small,
  },
  {
    text: capitalize(COMPANY_SIZE.medium),
    value: COMPANY_SIZE.medium,
  },
  {
    text: capitalize(COMPANY_SIZE.large),
    value: COMPANY_SIZE.large,
  },
];

export const PAYMENT_TYPE_FILTERS = [
  {
    text: capitalize(PAYMENT_TYPE.monthly),
    value: PAYMENT_TYPE.monthly,
  },
  {
    text: capitalize(PAYMENT_TYPE.upfront),
    value: PAYMENT_TYPE.upfront,
  },
];

export const NO_DSP_CONTRACT = 'No DSP contract';

export const DSP_PAYMENT_TYPE_FILTERS = [
  ...PAYMENT_TYPE_FILTERS,
  {
    text: NO_DSP_CONTRACT,
    value: NO_DSP_CONTRACT,
  },
];

export const ACCOUNTING_CSV_COLUMN_TITLES = [
  {
    title: i18n.t('AccountingTitles.companyName'),
    dataIndex: 'companyName',
  },
  {
    title: i18n.t('AccountingTitles.stripeCustomerId'),
    dataIndex: 'stripeCustomerId',
  },
  {
    title: i18n.t('AccountingTitles.accountHolderName'),
    dataIndex: 'accountHolderName',
  },
  {
    title: i18n.t('AccountingTitles.accountHolderEmail'),
    dataIndex: 'accountHolderEmail',
  },
  {
    title: i18n.t('AccountingTitles.paymentType'),
    dataIndex: 'paymentType',
  },
  {
    title: i18n.t('AccountingTitles.monthlySubscription'),
    dataIndex: 'monthlySubscription',
  },
  {
    title: i18n.t('AccountingTitles.discount'),
    dataIndex: 'discount',
  },
  {
    title: i18n.t('AccountingTitles.dspSubscriptionFee'),
    dataIndex: 'dspSubscriptionFee',
  },
  {
    title: i18n.t('AccountingTitles.monthlyOverage'),
    dataIndex: 'monthlyOverage',
  },
  {
    title: i18n.t('AccountingTitles.dspAdditionalFee'),
    dataIndex: 'dspAdditionalFee',
  },
  {
    title: i18n.t('AccountingTitles.monthlyTotal'),
    dataIndex: 'monthlyTotal',
  },
  {
    title: i18n.t('AccountingTitles.status'),
    dataIndex: 'status',
  },
  {
    title: i18n.t('AccountingTitles.dangerZone'),
    dataIndex: 'dangerZone',
  },
  {
    title: i18n.t('AccountingTitles.contractStartDate'),
    dataIndex: 'contractStartDate',
  },
  {
    title: i18n.t('AccountingTitles.contractEndDate'),
    dataIndex: 'contractEndDate',
  },
  {
    title: i18n.t('AccountingTitles.smallSubscription'),
    dataIndex: 'smallSubscription',
  },
  {
    title: i18n.t('AccountingTitles.largeSubscription'),
    dataIndex: 'largeSubscription',
  },
  {
    title: i18n.t('AccountingTitles.smallProjectsByMonth'),
    dataIndex: 'smallProjectsByMonth',
  },
  {
    title: i18n.t('AccountingTitles.largeProjectsByMonth'),
    dataIndex: 'largeProjectsByMonth',
  },
  {
    title: i18n.t('AccountingTitles.smallProjectsBySubscriptionStartDate'),
    dataIndex: 'smallProjectsBySubscriptionStartDate',
  },
  {
    title: i18n.t('AccountingTitles.largeProjectsBySubscriptionStartDate'),
    dataIndex: 'largeProjectsBySubscriptionStartDate',
  },
  {
    title: i18n.t('AccountingTitles.smallProjectsRemaining'),
    dataIndex: 'smallProjectsRemaining',
  },
  {
    title: i18n.t('AccountingTitles.largeProjectsRemaining'),
    dataIndex: 'largeProjectsRemaining',
  },
  {
    title: i18n.t('AccountingTitles.creditsBalance'),
    dataIndex: 'creditsBalance',
  },
  {
    title: i18n.t('AccountingTitles.totalPurchasedCredits'),
    dataIndex: 'totalPurchasedCredits',
  },
  {
    title: i18n.t('AccountingTitles.nearmapCreditsUsedBySubscriptionStart'),
    dataIndex: 'nearmapCreditsUsedBySubscriptionStart',
  },
  {
    title: i18n.t('AccountingTitles.designServiceCreditsUsedBySubscriptionStart'),
    dataIndex: 'designServiceCreditsUsedBySubscriptionStart',
  },
  {
    title: i18n.t('AccountingTitles.scaniflyOwner'),
    dataIndex: 'scaniflyOwner',
  },
  {
    title: i18n.t('AccountingTitles.scaniflyAccountManager'),
    dataIndex: 'scaniflyAccountManager',
  },
  {
    title: i18n.t('AccountingTitles.updatedAt'),
    dataIndex: 'updatedAt',
  },
];

export const DANGER_ZONE_CATEGORY = {
  renewal: i18n.t('DangerZone.renewal'),
  additionalFees: i18n.t('DangerZone.additionalFees'),
  inactive: i18n.t('DangerZone.inactive'),
  upgrade: i18n.t('DangerZone.upgrade'),
};

export const DANGER_ZONE_CATEGORY_FILTERS = [
  {
    text: DANGER_ZONE_CATEGORY.renewal,
    value: 'renewal',
  },
  {
    text: DANGER_ZONE_CATEGORY.additionalFees,
    value: 'additionalFees',
  },
  {
    text: DANGER_ZONE_CATEGORY.inactive,
    value: 'inactive',
  },
  {
    text: DANGER_ZONE_CATEGORY.upgrade,
    value: 'upgrade',
  },
];

export const DSP_TIERS = [
  {
    title: 'Wireframes',
    value: 'acceleratedWireframes',
  },
  {
    title: 'Site Plans',
    value: 'sitePlans',
  },
  {
    title: 'Site Plans + Accelerated',
    value: 'acceleratedSitePlans',
  },
  {
    title: 'Plan Sets',
    value: 'planSets',
  },
];
