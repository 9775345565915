import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from './constants';

export const validationSchema = Yup.object().shape({
  [FORM_CONTROLS.SUBSCRIPTION]: Yup.string()
    .required('This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.DISCOUNT]: Yup.string()
    .required('This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PROJECT_QUOTA_SM]: Yup.string()
    .required('This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.PROJECT_QUOTA_LG]: Yup.string()
    .required('This field is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.CONTRACT_START]: Yup.string()
    .required('Contract start date is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.CONTRACT_END]: Yup.string()
    .required('Contract end date is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.OWNER]: Yup.string()
    .required('Scanifly Owner is required')
    .test(...noWhitespaces),
  [FORM_CONTROLS.CONTRACT]: Yup.string()
    .required('A contract link is required')
    .test(...noWhitespaces),
});
