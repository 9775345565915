import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import styled from 'styled-components';

import { ModalContext } from 'components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as GoBackIcon } from 'assets/icons/go-back-icon.svg';

type GoBackButtonProps = {
  onClick?: () => Promise<void> | void;
  hasUnsavedChanges?: boolean;
};

const boxShadow = `0 0 1px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.13)`;
const hoverBoxShadow = `0px 0px 1px rgba(41, 80, 255, 1), 0px 0px 0px 1px rgba(41, 80, 255, 1)`;
const BackButton = styled.button`
  margin-right: 5px;
  background-color: ${colors.white};
  border-radius: 0.7rem;
  font-size: ${fontSizes.mediumSmall};
  font-weight: 500;
  padding: 0 1.6rem;
  border: none;
  box-shadow: ${boxShadow};
  height: 3.8rem;

  &:hover {
    color: ${colors.mainBlue};
    box-shadow: ${hoverBoxShadow};
    svg path {
      fill: ${colors.mainBlue};
    }
  }
`;

const BackIcon = styled(GoBackIcon)`
  height: 1rem;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
`;

const GoBackButton = ({ onClick, hasUnsavedChanges }: GoBackButtonProps) => {
  const { displayDeleteModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const history = useHistory();
  const onBackConfirm = useCallback(async () => {
    if (onClick) {
      await onClick();
    }
    history.goBack();
  }, [onClick, history]);
  const onBackClick = useCallback(async () => {
    if (hasUnsavedChanges) {
      displayDeleteModal({
        title: t('Generic.discardTitle'),
        description: t('Generic.areYouSureDiscard'),
        actionButtonOnClick: onBackConfirm,
        actionButtonLabel: t('Generic.discard'),
      });
    } else {
      onBackConfirm();
    }
  }, [hasUnsavedChanges, displayDeleteModal, onBackConfirm, t]);

  return (
    <BackButton onClick={onBackClick}>
      <BackIcon /> {t('buttonTexts.back')}
    </BackButton>
  );
};

export default GoBackButton;
