import { DragEvent, useContext, useRef } from 'react';

import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';

import styled from 'styled-components';

import Section from 'screens/ChecklistTemplate/Editor/components/Section';

// 655px is the minimum the current layout can support, we need to consider how
// the buttons should transition on smaller screens before we can go smaller
const ContentContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.95
  overflow-y: auto;
  overflow-x: hidden;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-overflow: ellipsis;
  flex: 0.01;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  flex: 0.8;
`;

type Props = {
  title: string;
};

export const ContentContainer = ({ title }: Props) => {
  const { activeSection } = useContext(ChecklistTemplateContext);
  const containerRef = useRef<HTMLDivElement>(null);
  if (!activeSection) {
    return null;
  }

  const handleAutoScroll = (e: DragEvent<HTMLDivElement>) => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      const { top, bottom } = scrollContainer.getBoundingClientRect();
      const buffer = 100;
      const scrollSpeed = 2;

      const clientY = e.nativeEvent.clientY;

      if (clientY - top < buffer) {
        scrollContainer.scrollTop -= scrollSpeed;
      } else if (bottom - clientY < buffer) {
        scrollContainer.scrollTop += scrollSpeed;
      }
    }
  };
  return (
    <Column>
      <Title>{title}</Title>
      <ContentContainerDiv ref={containerRef} onDragOverCapture={handleAutoScroll}>
        <Section key={activeSection.id} sectionData={activeSection} />
      </ContentContainerDiv>
    </Column>
  );
};
