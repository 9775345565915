import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChecklistTemplatesForCompany,
  selectCustomChecklistTemplates,
  selectDefaultChecklistTemplates,
} from 'state/slices/checklists';
import { AppDispatch, RootState } from 'state/store/store';
import { StyledBackButton, TemplateContainer, Wrapper } from './components';

import { CustomTemplateListCard } from './CustomTemplateListCard';
import { DefaultTemplateListCard } from './DefaultTemplateListCard';

const ChecklistManager = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useRef(false);
  const customTemplates = useSelector((state: RootState) => selectCustomChecklistTemplates(state));

  const getTemplates = useCallback(async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    try {
      await dispatch<any>(getChecklistTemplatesForCompany());
    } finally {
      isFetching.current = false;
    }
  }, [dispatch]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  const defaultTemplates = useSelector((state: RootState) =>
    selectDefaultChecklistTemplates(state)
  );

  return (
    <Wrapper>
      <StyledBackButton />
      <TemplateContainer>
        <DefaultTemplateListCard templates={defaultTemplates} />
        <CustomTemplateListCard templates={customTemplates} />
      </TemplateContainer>
    </Wrapper>
  );
};

export default ChecklistManager;
