import {
  CREDIT_TRANSACTION_CATEGORY,
  CREDIT_TRANSACTION_CATEGORY_FILTERS,
  DESIGN_TIER_LEVEL_FILTERS,
} from '@cpm/scanifly-shared-data';
import moment from 'moment';
import CreditsTransaction from 'types/CreditTransaction';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { csvDateFormat } from 'helpers/utils/dateFormat';
import { i18n } from 'helpers/utils/translations';

export const handleCreditTransactionsCSVExport = ({
  dataSource,
}: {
  dataSource: CreditsTransaction[];
}) => {
  return dataSource.map((item) => {
    const {
      category,
      credit,
      debit,
      project,
      createdAt,
      serviceRequest,
      user,
      nearmapArea,
      nearmapAllDates,
      companyStripeId,
    } = item || {};

    const creditsModified =
      category === CREDIT_TRANSACTION_CATEGORY.order ||
      category === CREDIT_TRANSACTION_CATEGORY.expiration ||
      category === CREDIT_TRANSACTION_CATEGORY.nearmaps
        ? credit * -1
        : debit;

    return {
      ...item,
      credits: creditsModified,
      companyStripeId: companyStripeId ?? i18n.t('CreditHistory.noStripeId'),
      projectName: project?.name ? project.name : '--',
      category: category
        ? CREDIT_TRANSACTION_CATEGORY_FILTERS.find((cat) => cat.value === category)?.text
        : '--',
      projectType: project?.type
        ? PROJECT_TYPE_FILTERS.find((type) => type.value === project.type)?.text
        : '--',
      serviceRequestId: serviceRequest?.id ? serviceRequest?.id : '--',
      tierLevel: serviceRequest?.id
        ? `${
            DESIGN_TIER_LEVEL_FILTERS.find((status) => status.value === serviceRequest?.type)?.text
          }${
            serviceRequest?.upgradedFrom ? ' ' + i18n.t('DesignService.uploadQueue.upgradeTag') : ''
          }`
        : '--',
      createdAt: createdAt ? moment(createdAt).format(csvDateFormat) : '--',
      userEmail: user?.id ? user?.email : '--',
      nearmapArea:
        category === CREDIT_TRANSACTION_CATEGORY.nearmaps && nearmapArea
          ? `${nearmapArea}m\u00B2 ${nearmapAllDates ? i18n.t('CreditHistory.allDates') : ''}`
          : '--',
      isExpedited: `${serviceRequest?.isExpedited ? i18n.t('Generic.yes') : i18n.t('Generic.no')}`,
      newBuildPlansRequired: `${serviceRequest?.newBuildPlansRequired ? i18n.t('Generic.yes') : i18n.t('Generic.no')}`,
    };
  });
};
