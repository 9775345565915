export const SLICE_NAMESPACE = 'exampleMedia';

/**
 *  Update Store
 */
export const CREATE_EXAMPLE_MEDIA = `${SLICE_NAMESPACE}/CREATE_EXAMPLE_MEDIA`;
export const DELETE_EXAMPLE_MEDIA = `${SLICE_NAMESPACE}/DELETE_EXAMPLE_MEDIA`;
export const DELETE_EXAMPLE_MEDIA_BY_TEMPLATE_ID = `${SLICE_NAMESPACE}/DELETE_EXAMPLE_MEDIA_BY_TEMPLATE_ID`;

export const GET_EXAMPLE_MEDIA_BY_TEMPLATE_ID = `${SLICE_NAMESPACE}/GET_EXAMPLE_MEDIA_BY_TEMPLATE_ID`;
