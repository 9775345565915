export const USAGE = 'usage';

export const MONTHS = {
  JANUARY: 'january',
  FEBRUARY: 'february',
  MARCH: 'march',
  APRIL: 'april',
  MAY: 'may',
  JUNE: 'june',
  JULY: 'july',
  AUGUST: 'august',
  SEPTEMBER: 'september',
  OCTOBER: 'october',
  NOVEMBER: 'november',
  DECEMBER: 'december',
};

export const QUARTERS = [
  {
    q1: [MONTHS.JANUARY, MONTHS.FEBRUARY, MONTHS.MARCH],
  },
  {
    q2: [MONTHS.APRIL, MONTHS.MAY, MONTHS.JUNE],
  },
  {
    q3: [MONTHS.JULY, MONTHS.AUGUST, MONTHS.SEPTEMBER],
  },
  {
    q4: [MONTHS.OCTOBER, MONTHS.NOVEMBER, MONTHS.DECEMBER],
  },
];

export const DEFAULT_COUNTRY = 'us';
export const PHONE_NUMBER_INITIAL_VALUE = '1';

export const USAGE_LIMIT = Number.MAX_SAFE_INTEGER;

export const CUSTOMER_INFO_TABS = {
  FORM: 'form',
  USAGE: 'usage',
};
