import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Modal, Tooltip } from 'antd';
import { useFormik } from 'formik';

import { selectIsRequestPending } from 'state/slices/notes';
import { RootState } from 'state/store/store';

import { MODAL_PROPS } from 'helpers/constants/modals';

import { LABELS } from '../constants';
import NotesTextarea from '../NotesTextarea/NotesTextarea';
import './EditNoteModal.scss';
import { EditNoteModalProps, FormFields, FormValues, InitialValues } from './types';

const EditNoteModal = ({
  filesToRender,
  handleUpload,
  isWaitingToFetchNote,
  isUploadModalVisible,
  onClose,
  selectedNote,
  setDeletedFiles,
  setValue,
  updateNote,
  value,
}: EditNoteModalProps) => {
  const { t } = useTranslation();

  const isRequestPending = useSelector((state: RootState) => selectIsRequestPending(state));

  const { handleSubmit, setFieldValue, getFieldProps } = useFormik<FormValues>({
    initialValues: InitialValues,
    onSubmit: async (values) => {
      if (!selectedNote.project || !selectedNote.id) {
        return;
      }

      await updateNote(values.note);
      setValue('');
      onClose();
    },
  });

  const handleDeleteImage = (id: string) => {
    setDeletedFiles((prevDeleted) => [id, ...prevDeleted]);
  };

  const isEmpty = !getFieldProps(FormFields.note).value;

  useEffect(() => {
    setFieldValue(FormFields.note, selectedNote.text);
    setFieldValue(FormFields.images, selectedNote.media);
    setValue(selectedNote.text);
  }, [setFieldValue, setValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    /* @ts-ignore */
    <Modal visible={true} onCancel={onClose} className="EditNoteModal" {...MODAL_PROPS}>
      <span className="EditNoteModal-Title">{t('Notes.editComment')}</span>
      <form onSubmit={handleSubmit}>
        <NotesTextarea
          files={filesToRender}
          label={LABELS.EDIT}
          formControl={FormFields.note}
          getFieldProps={getFieldProps}
          handleDelete={handleDeleteImage}
          handleSubmit={handleSubmit}
          isUploadModalVisible={isUploadModalVisible}
          toggleUploadModal={handleUpload}
          setFieldValue={setFieldValue}
          value={value}
          setValue={setValue}
        />
        <div className="EditNoteModal-ButtonWrapper">
          <Button onClick={onClose} className="Button--White">
            {t('Generic.cancel')}
          </Button>
          <Tooltip title={isEmpty && t('Notes.pleaseAddtext')}>
            <Button
              htmlType="submit"
              className="Button--Blue"
              disabled={isEmpty || isWaitingToFetchNote}
              aria-disabled={isEmpty || isWaitingToFetchNote}
              /* @ts-ignore */
              onClick={handleSubmit}
              loading={isRequestPending || isWaitingToFetchNote}
            >
              {t('Notes.saveComment')}
            </Button>
          </Tooltip>
        </div>
      </form>
    </Modal>
  );
};

export default EditNoteModal;
