import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import colors from 'helpers/constants/colors';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  height: 3.6rem;
  width: 25.8rem;
  padding: 1.1rem 1.4rem;
  border: 0.1rem solid ${colors.neutralGray};
  border-radius: 0.8rem;
  color: ${colors.darkGray};
  align-items: center;
  span {
    margin-top: 0.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 100%;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.1rem;
  }
`;

type MediaCategoryItemProps = {
  categoryName: string;
};

function MediaCategoryItem({ categoryName }: MediaCategoryItemProps) {
  return (
    <StyledContainer>
      <CategoryIcon />
      <span>{categoryName}</span>
    </StyledContainer>
  );
}

export default MediaCategoryItem;
