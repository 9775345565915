import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 49%;

  @media (max-width: ${breakpoints.lg}) {
    width: 90%;
    margin-bottom: 1rem;
  }
`;

const Title = styled.div`
  font-size: ${fontSizes.small};
  color: ${colors.labelGray};
  white-space: pre-wrap;
  margin-right: 0.5rem;
  width: 66%;
`;

const Value = styled.div`
  font-size: ${fontSizes.small};
  width: 34%;
`;

function ServiceCreditPair({
  subitem,
}: {
  subitem: {
    title?: string;
    creditCount: string | number;
  };
}) {
  if (!subitem?.title || !subitem.creditCount) return null;
  return (
    <Wrapper key={subitem.title}>
      <Title>{subitem.title}</Title>
      <Value>{subitem.creditCount.toString()}</Value>
    </Wrapper>
  );
}

export { ServiceCreditPair, Title, Value, Wrapper };
