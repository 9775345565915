import { useSelector } from 'react-redux';

import { Revision } from 'types';
import ServiceRequest from 'types/ServiceRequest';

import { RootState } from 'state/store/store';

import CollapsibleOrderList from './CollapsibleOrderList';
import OrderRow from './OrderRow';
import RevisionList from './RevisionList';

export const DESIGN_COMPLETED_DISPLAY_TITLES: {
  title: string;
  field: keyof ServiceRequest | null;
}[] = [
  { title: 'Order', field: null },
  { title: 'Product', field: 'tierLevel' },
  { title: 'Order Date', field: 'createdAt' },
  { title: 'Date Completed', field: 'completedDate' },
  { title: 'Order Form', field: 'orderForm' },
  { title: 'Ordered By', field: 'userInfo' },
  { title: 'Order Type', field: 'upgradedFrom' },
  { title: 'Revisions', field: 'modelRevision' },
  { title: 'Status', field: 'status' },
];

const OrderList = ({
  handleOpenRevisionDataModal,
}: {
  handleOpenRevisionDataModal?: (revision: Revision) => void;
}) => {
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);

  const serviceRequestsOrdered = serviceRequests ? [...serviceRequests].reverse() : [];
  return (
    <div className="DesignStatus-InfoWrapper">
      <div>
        <div className="DesignStatus-InfoTitles">
          {DESIGN_COMPLETED_DISPLAY_TITLES.map((item) => {
            return (
              <p key={item.title} className="DesignStatus-Field">
                {item.title}
              </p>
            );
          })}
        </div>
        <hr className="DesignStatus-Divider" />
      </div>
      {serviceRequestsOrdered?.map((serviceRequest, index) => {
        const modelRevisionList = serviceRequest.modelRevision
          ? Array.isArray(serviceRequest.modelRevision)
            ? serviceRequest.modelRevision
            : [serviceRequest.modelRevision]
          : [];

        const modelRevisionsLength = modelRevisionList.length;

        if (modelRevisionsLength) {
          return (
            <CollapsibleOrderList
              header={
                <OrderRow
                  serviceRequest={serviceRequest}
                  index={index}
                  modelRevisionsLength={modelRevisionsLength}
                  serviceRequestsOrdered={serviceRequestsOrdered}
                />
              }
              content={
                <RevisionList
                  serviceRequest={serviceRequest}
                  index={index}
                  modelRevision={modelRevisionList}
                  customStyle={'DesignStatus-RevisionValueWrapper'}
                  handleOpenRevisionDataModal={handleOpenRevisionDataModal}
                />
              }
              customStyle={'DesignStatus-CollapsibleValueWrapper'}
              key={`${serviceRequest.id}-${index}`}
            />
          );
        }
        return (
          <div className="DesignStatus-InfoSection" key={serviceRequest.id}>
            <div>
              <OrderRow
                serviceRequest={serviceRequest}
                index={index}
                modelRevisionsLength={modelRevisionsLength}
                serviceRequestsOrdered={serviceRequestsOrdered}
                customStyle={'DesignStatus-ValueWrapper'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderList;
