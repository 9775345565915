import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Input } from 'antd';
import { useFormik } from 'formik';

import { creditAdjustmentRequested } from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import { AppDispatch } from 'state/store/store';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { openNotification } from 'helpers/utils/openNotification';

import { ADD_DESIGN_CREDIT_FORM_CONTROLS } from '../constants';
import './AdjustDesignCredits.scss';
import {
  getButtonText,
  getCreditTransactionCategoryByAction,
  getFailureText,
  getModalTitle,
  getPlaceholder,
  getSuccessText,
} from './helpers';
import { validationSchema } from './validationSchema';

const AdjustDesignCredits = ({
  selectedCompany,
  handleAddCreditModalClose,
  selectedAction,
}: {
  selectedCompany: { name: string; id: string } | null;
  handleAddCreditModalClose: () => void;
  selectedAction: string | null;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const initialValues = {
    quantity: undefined,
    memo: '',
  };

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: { memo: string; quantity?: number }) => {
      if (values.quantity && transactionCategory) {
        dispatch(
          creditAdjustmentRequested({
            companyId: selectedCompany?.id ?? '',
            quantity: Number(values.quantity),
            category: transactionCategory,
            memo: String(values.memo),
            onSuccess: notifyUserOnSuccess,
            onFailure: notifyUserOnFailure,
          })
        );
      }
      handleAddCreditModalClose();
      resetForm();
    },
  });

  if (!selectedCompany || !selectedAction) {
    return;
  }

  const transactionCategory = getCreditTransactionCategoryByAction(selectedAction);
  const modalTitle = getModalTitle({
    action: selectedAction,
    t,
    companyName: selectedCompany.name,
  });
  const buttonText = getButtonText({
    action: selectedAction,
    t,
  });
  const placeholder = getPlaceholder({
    action: selectedAction,
    t,
  });

  const notifyUserOnSuccess = (quantity: number, amount: number) => {
    const text = getSuccessText({
      quantity: Math.abs(quantity),
      companyName: selectedCompany.name,
      balance: amount,
      t,
      action: selectedAction,
    });

    openNotification({
      type: 'success',
      title: t('alertMessages.successTitle'),
      text: text,
    });
  };

  const notifyUserOnFailure = () => {
    const text = getFailureText({
      companyName: selectedCompany.name,
      t,
      action: selectedAction,
    });

    openNotification({
      type: 'success',
      title: t('alertMessages.errorTitle'),
      text: text,
    });
  };

  const isFormValid = isValid && dirty;

  return (
    <>
      <h2 className="AdjustDesignCredits-Title" data-testid="adjust-credits-modal-title">
        {modalTitle}
      </h2>
      <div className="AdjustDesignCredits-Input">
        <label htmlFor="quantity" data-testid="adjust-credits-modal-quantity">
          {t('AccountManager.quantity')}{' '}
        </label>
        <Input
          placeholder={t('AccountManager.enterCreditAmount') ?? ''}
          type="number"
          min="0"
          step="10"
          className={`${validateStatus(touched, errors, ADD_DESIGN_CREDIT_FORM_CONTROLS.QUANTITY)}`}
          id="quantity"
          {...getFieldProps(ADD_DESIGN_CREDIT_FORM_CONTROLS.QUANTITY)}
        />
        <div className="Form-Error">
          {renderValidationMessage(touched, errors, ADD_DESIGN_CREDIT_FORM_CONTROLS.QUANTITY)}
        </div>
      </div>
      <div className="FormControl-Wrapper AdjustDesignCredits-Input">
        <label htmlFor="memo" data-testid="adjust-credits-modal-memo">
          {t('AccountManager.memo')}
        </label>
        <Input
          placeholder={placeholder}
          className={`${validateStatus(touched, errors, ADD_DESIGN_CREDIT_FORM_CONTROLS.MEMO)}`}
          id="memo"
          {...getFieldProps(ADD_DESIGN_CREDIT_FORM_CONTROLS.MEMO)}
        />
        <div className="Form-Error">
          {renderValidationMessage(touched, errors, ADD_DESIGN_CREDIT_FORM_CONTROLS.MEMO)}
        </div>
      </div>
      <div className="AdjustDesignCredits-Button-Wrapper">
        <Button
          onClick={() => handleSubmit()}
          disabled={!isFormValid}
          aria-disabled={!isFormValid}
          className="Button--Blue"
          data-testid="adjust-credits-modal-button"
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default AdjustDesignCredits;
