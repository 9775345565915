import { Input, Row } from 'antd';
import PropTypes from 'prop-types';

import { CountrySelect } from 'components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS } from '../constants';

const SignUpBillingAddress = ({
  touched,
  errors,
  getFieldProps,
  setFieldValue,
  values,
  handleBlur,
  setSelectedCountry,
}) => {
  const [handleCountryBlur, getCountrySelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.COUNTRY
  );

  return (
    <>
      <h2 className="SignUp-Form-Title">Billing Address</h2>

      <Row className="SignUp-Form-Row">
        <div className="SignUp-Form-Wrapper-Select">
          <CountrySelect
            {...getFieldProps(FORM_CONTROLS.COUNTRY)}
            formControl={FORM_CONTROLS.COUNTRY}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            setSelectedCountry={setSelectedCountry}
            onBlur={handleCountryBlur}
            error={getCountrySelectError(values)}
            aria-label="select country"
            tall
          />
          <div className="Form-Error">
            {getCountrySelectError(values) && errors[FORM_CONTROLS.COUNTRY]}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="billingStreet">Street</label>
          <Input
            placeholder="Street"
            className={validateStatus(touched, errors, FORM_CONTROLS.STREET)}
            id="billingStreet"
            {...getFieldProps(FORM_CONTROLS.STREET)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.STREET)}
          </div>
        </div>
      </Row>

      <Row className="SignUp-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="billingCity">City</label>
          <Input
            placeholder="City"
            className={validateStatus(touched, errors, FORM_CONTROLS.CITY)}
            id="billingCity"
            {...getFieldProps(FORM_CONTROLS.CITY)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.CITY)}
          </div>
        </div>

        {getFieldProps(FORM_CONTROLS.COUNTRY).value === 'United States' ? (
          <div className="FormControl-Wrapper">
            <label htmlFor="billingState">State</label>
            <Input
              placeholder="State"
              className={`SignUp-Form--Short ${validateStatus(
                touched,
                errors,
                FORM_CONTROLS.STATE
              )}`}
              id="billingState"
              {...getFieldProps(FORM_CONTROLS.STATE)}
            />
            <div className="Form-Error Form-Error--Short">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.STATE)}
            </div>
          </div>
        ) : null}

        <div className="FormControl-Wrapper">
          <label htmlFor="billingZip">ZIP Code</label>
          <Input
            placeholder="ZIP Code"
            className={`SignUp-Form--Medium ${validateStatus(
              touched,
              errors,
              FORM_CONTROLS.ZIP_CODE
            )}`}
            id="billingZip"
            {...getFieldProps(FORM_CONTROLS.ZIP_CODE)}
          />
          <div className="Form-Error Form-Error--Medium">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.ZIP_CODE)}
          </div>
        </div>
      </Row>
    </>
  );
};

SignUpBillingAddress.propTypes = {
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  getFieldProps: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
};

export default SignUpBillingAddress;
