import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
  ComponentBuilder,
  ContentContainer,
  ContentRow,
  ScreenWrapper,
  SectionContent,
  Toolbar,
} from './layout';
import { ChecklistTemplateProps } from './types';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 60px;
`;

const ChecklistTemplate = ({
  hasUnsavedChanges,
  onCopy,
  onRename,
  onSave,
  onReset,
  onPublishUnpublish,
}: ChecklistTemplateProps) => {
  const { t } = useTranslation();
  return (
    <ScreenWrapper>
      <Row id="toolbarRow">
        <Toolbar
          hasUnsavedChanges={hasUnsavedChanges}
          onCopy={onCopy}
          onRename={onRename}
          onSave={onSave}
          onReset={onReset}
          onPublishUnpublish={onPublishUnpublish}
        />
      </Row>
      <ContentRow>
        <SectionContent />
        <ContentContainer title={t('Checklists.checklistForm')} />
        <ComponentBuilder />
      </ContentRow>
    </ScreenWrapper>
  );
};

export default ChecklistTemplate;
