import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldTypeEnum } from '@cpm/scanifly-shared-data';

import styled from 'styled-components';

import { AddComponentButton } from './AddComponentButton';

const Container = styled.div`
  display: flex;
  flex: 0.1;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
`;

// `max-height: calc(100vh - 15rem)` The 15rem is the size of the header + the toolbar + the "Field Types" text
const InnerContainer = styled.div`
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 15rem);

  @media (max-width: 768px) {
    width: 50px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Title = styled.h1`
  text-align: left;
  margin-left: 1rem;
`;

export const ComponentBuilder = () => {
  const { t } = useTranslation();

  const componentData = useMemo(() => {
    // Used to define order of component display and prevent
    // components that are not yet supported from displaying
    const componentOrder = [
      FieldTypeEnum.textInput,
      FieldTypeEnum.dropDown,
      FieldTypeEnum.checkMark,
      FieldTypeEnum.radio,
      FieldTypeEnum.media,
      FieldTypeEnum.timePicker,
      FieldTypeEnum.datePicker,
      FieldTypeEnum.notApplicable,
      FieldTypeEnum.referenceMedia,
    ];

    const componentData: { title: string; fieldType: FieldTypeEnum }[] = [];
    for (const field of componentOrder) {
      componentData.push({
        title: t(`Checklists.${field}`),
        fieldType: field,
      });
    }
    return componentData;
  }, [t]);

  const renderItems = componentData.map(({ title, fieldType }, index) => {
    return <AddComponentButton key={`component-${index}`} title={title} fieldType={fieldType} />;
  });
  return (
    <Container>
      <Title>{t('ChecklistTemplate.fieldTypes')}</Title>
      <InnerContainer>{renderItems}</InnerContainer>
    </Container>
  );
};
