import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

import { ToggleResolutionButtonProps } from './types';

const StyledButton = styled(Button)`
  color: ${colors.white};
`;

function ToggleResolutionButton({
  className,
  isHighRes,
  toggleHighRes,
}: ToggleResolutionButtonProps) {
  const { t } = useTranslation();

  return (
    <StyledButton
      className={className}
      onClick={toggleHighRes}
      aria-label="toggle resolution"
      data-testid={'toggle-resolution-btn'}
    >
      {t(`ToggleResolutionButton.${isHighRes ? 'toggleLowQuality' : 'toggleHighQuality'}`)}
    </StyledButton>
  );
}

export default ToggleResolutionButton;
