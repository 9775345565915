import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { AUTOMATED_DESIGN_SERVICES_SUPPORT_URL } from 'helpers/constants/urls';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { LearnMore } from './LearnMore';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${colors.neutralGray};
  background: ${colors.white};
  margin-bottom: 2rem;
`;

const Disclaimer = styled.div`
  font-size: ${fontSizes.mediumSmall};
  color: ${colors.labelGray};
  margin-left: 1.5rem;
`;

interface AutomatedServiceNotificationBoxProps {
  product?: string;
  t: TFunction<'translation', undefined>;
}

export const AutomatedServiceNotificationBox = ({
  product,
  t,
}: AutomatedServiceNotificationBoxProps) => {
  if (!product) return;

  return (
    <Wrapper>
      <LearnMore
        href={AUTOMATED_DESIGN_SERVICES_SUPPORT_URL}
        text={t('DesignServicesAutomationToggles.learnMore')}
      />
      <Disclaimer>
        {t('DesignServicesAutomationToggles.automationDisclaimer', { product })}
      </Disclaimer>
    </Wrapper>
  );
};
