import { Folder } from 'types';
import * as Yup from 'yup';

import { noWhitespaces } from 'helpers/utils/formValidationHelpers';

import { FormFields } from './types';

export const ValidationSchema = (tempFolderList: Folder[] = [], folderId: string | null = null) => {
  return Yup.object().shape({
    [FormFields.folderName]: Yup.string()
      .required('Folder name is required')
      .test(noWhitespaces[0], noWhitespaces[1], noWhitespaces[2])
      .test('uniqueNames', 'Folder names must be unique', (value) => {
        return folderId
          ? !tempFolderList.filter(({ name, id }) => !name.localeCompare(value) && id !== folderId)
              .length // allows the user to rename a folder to its original value, but prevents duplicate folder names
          : !tempFolderList.filter(({ name }) => !name.localeCompare(value)).length; // prevents the user from adding duplicate folder names
      }),
  });
};
