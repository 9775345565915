import { Card } from 'antd';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ScaniflyLogo } from 'assets/scaniflylogo.svg';

import './Auth.scss';

const Auth = ({ children, title /* subtitle  */ }) => (
  <main className="Auth">
    <Card className="Auth-Card">
      <ScaniflyLogo className="Auth-Card-Logo" />
      <h1 className={cn('Auth-Card-Title')}>{title}</h1>
      {children}
    </Card>
  </main>
);

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Auth;
