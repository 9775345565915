import styled from 'styled-components';

import breakpoints from 'helpers/constants/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .videoPlayer {
    margin-top: 2rem;
    width: 70rem;

    @media screen and (max-width: ${breakpoints.xxl}) {
      width: 100%;
    }
  }
`;

export default Container;
