import { openNotification } from 'helpers/utils/openNotification';

export const getNotification = (statusUpdateSuccess, statusUpdateError) => {
  if (statusUpdateSuccess) {
    openNotification({
      type: 'success',
      title: 'Success!',
      text: statusUpdateSuccess,
    });
  }

  if (statusUpdateError) {
    openNotification({
      type: 'error',
      title: 'There was an error.' + statusUpdateError,
      text: 'Please try again in a few seconds.',
    });
  }
};
