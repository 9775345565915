import API from 'api/config';

import { fetchFeedbackReportUrl } from './droneDataScoreUrls';

export const fetchDDSFeedbackReportUrl = ({
  projectId,
  companyId,
}: {
  projectId: string;
  companyId: string;
}) => {
  return API.get(fetchFeedbackReportUrl({ projectId, companyId }));
};
