import API from '../config';
import {
  createProjectCategoryUrl,
  fetchProjectCategoriesUrl,
  projectCategoryUrl,
} from './projectCategoriesUrls';

export const fetchProjectCategories = (projectId: string) =>
  API.get(fetchProjectCategoriesUrl(projectId));
export const createProjectCategory = (projectId: string, categoryName: string) =>
  API.post(createProjectCategoryUrl(projectId), { categoryName });
export const deleteProjectCategory = (projectId: string, categoryName: string) =>
  API.delete(projectCategoryUrl(projectId, categoryName));
export const updateProjectCategory = ({
  projectId,
  categoryName,
  newCategoryName,
}: {
  projectId: string;
  categoryName: string;
  newCategoryName: string;
}) => API.patch(projectCategoryUrl(projectId, categoryName), { newCategoryName });
