import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { EmailLink } from 'components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as BoltIcon } from 'assets/icons/bolt-icon.svg';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 42rem;
  color: ${colors.darkGray};
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  span {
    margin-left: 1rem;
    font-size: ${fontSizes.medium};
  }
`;

const StyledSubheader = styled.h2`
  margin: 0 auto 2rem auto;
  font-size: 2.25rem;
  width: 95%;
`;

const StyledMessage = styled.h2`
  width: 90%;
  margin: 0 auto 2rem auto;
  font-size: ${fontSizes.medium};
`;

const StyledContact = styled.h2`
  margin: 0 auto 2rem auto;
  width: 90%;
  font-size: ${fontSizes.medium};
  color: ${colors.labelGray};
`;

const UpgradeTierLevelForAccess = ({ messageGroup }: { messageGroup: string }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledHeader>
        <BoltIcon />
        <span>{t(`UpgradeCopy.title`)}</span>
      </StyledHeader>
      <StyledSubheader>{t(`UpgradeCopy.${messageGroup}.subheader`)}</StyledSubheader>
      <StyledMessage>{t(`UpgradeCopy.${messageGroup}.message`)}</StyledMessage>
      <StyledContact>
        Contact your Scanifly rep or reach out to <EmailLink /> for upgrading.
      </StyledContact>
    </StyledWrapper>
  );
};

export default UpgradeTierLevelForAccess;
