import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  if (!condition && !wrapper && !children) return null;
  return condition && wrapper ? wrapper(<div>{children}</div>) : <div>{children}</div>;
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.node,
};

export default ConditionalWrapper;
