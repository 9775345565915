import { UploadManifest } from 'types';

import API from '../config';
import { saveUploadManifestUrl } from './uploadManifestsUrls';

export const saveUploadManifest = ({
  files,
  uploadManifestId,
  mediaCategoryId,
  projectId,
  intent,
  userId,
}: UploadManifest) =>
  API.post(saveUploadManifestUrl(), {
    files,
    intent,
    mediaCategoryId,
    projectId,
    uploadManifestId,
    userId,
  });
