import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NotApplicableFieldType } from '@cpm/scanifly-shared-data';
import { useFormik } from 'formik';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';
import { EditButtons, EditFieldText } from 'screens/ChecklistTemplate/Editor/common';

import { Description, FieldWrapper, Label, RowNoMargin, Spacer } from './CommonFieldComponents';
import useChecklistButtons from './hooks/useChecklistsButtons';

type Props = {
  item: NotApplicableFieldType;
};

function NotApplicable({ item: { id, componentType, label, description } }: Props) {
  const { t } = useTranslation();
  const { updateField } = useContext(ChecklistTemplateContext);

  const handleUpdateField = useCallback(
    (newValues: Partial<NotApplicableFieldType>) => {
      updateField(id, newValues);
    },
    [updateField, id]
  );

  const formik = useFormik({
    initialValues: {
      label,
      description,
    },
    onSubmit: (values) => {
      handleUpdateField(values);
    },
  });

  const { handleDelete, handleEdit, handleSave, handleCancel, editing } = useChecklistButtons({
    id,
    handleSubmit: formik.handleSubmit,
    resetForm: formik.resetForm,
  });
  return (
    <FieldWrapper>
      {editing.includes(id) ? (
        <form>
          <RowNoMargin>
            <Label>{t('Checklists.editingField')}</Label>
            <EditButtons
              mode="edit"
              componentType={componentType}
              onSave={handleSave}
              onDelete={handleDelete}
              onCancel={handleCancel}
            />
          </RowNoMargin>
          <Spacer height={2} />
          <EditFieldText
            id="label"
            label={t('Checklists.label') as string}
            onChange={formik.handleChange}
            value={formik.values.label}
          />
          <Spacer />
          <EditFieldText
            parentId={id}
            id="description"
            label={t('Checklists.description') as string}
            onChange={formik.handleChange}
            value={formik.values.description}
            expandable
          />
        </form>
      ) : (
        <>
          <RowNoMargin>
            <Label>{label}</Label>
            <EditButtons
              mode="display"
              componentType={componentType}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </RowNoMargin>
          {description && <Spacer />}
          {description && <Description>{description}</Description>}
        </>
      )}
    </FieldWrapper>
  );
}

export default NotApplicable;
