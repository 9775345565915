import { useCallback, useContext, useMemo } from 'react';

import { FieldTypeEnum, FieldTypeUnion } from '@cpm/scanifly-shared-data';
import { ChecklistTemplateContext } from 'screens/ChecklistTemplate/Editor/ChecklistTemplateProvider';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';

import { Button } from 'components';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 760px) {
    margin-left: 3rem;
  }
`;

type AddComponentButtonProps = {
  title: string;
  fieldType: FieldTypeEnum;
};

export const AddComponentButton = ({ title, fieldType }: AddComponentButtonProps) => {
  const { activeSection, addField } = useContext(ChecklistTemplateContext);

  const defaultFieldData: FieldTypeUnion | undefined = useMemo(() => {
    const baseField = {
      id: 'placeholder',
      isRequired: false,
      label: 'Placeholder label',
      value: null,
    };
    switch (fieldType) {
      case FieldTypeEnum.textInput:
        return { ...baseField, componentType: FieldTypeEnum.textInput };
      case FieldTypeEnum.media:
        return { ...baseField, componentType: FieldTypeEnum.media };
      case FieldTypeEnum.datePicker:
        return { ...baseField, componentType: FieldTypeEnum.datePicker };
      case FieldTypeEnum.timePicker:
        return { ...baseField, componentType: FieldTypeEnum.timePicker };
      case FieldTypeEnum.dropDown:
        return { ...baseField, componentType: FieldTypeEnum.dropDown, options: ['example option'] };
      case FieldTypeEnum.radio:
        return { ...baseField, componentType: FieldTypeEnum.radio, options: ['example option'] };
      case FieldTypeEnum.checkMark:
        return { ...baseField, componentType: FieldTypeEnum.checkMark, value: false };
      case FieldTypeEnum.referenceMedia:
        return {
          ...baseField,
          componentType: FieldTypeEnum.referenceMedia,
          referenceMediaCategoryId: '',
        };
      case FieldTypeEnum.notApplicable:
        return {
          ...baseField,
          componentType: FieldTypeEnum.notApplicable,
          label: 'Not applicable',
          description: 'Mark this section as not applicable',
          value: false,
        };
      default:
        return;
    }
  }, [fieldType]);

  const handleAddComponent = useCallback(() => {
    if (activeSection?.id && defaultFieldData) {
      addField(activeSection.id, { ...defaultFieldData, id: uuidv4() });
    }
  }, [activeSection?.id, addField, defaultFieldData]);

  const icon = useMemo(() => {
    switch (fieldType) {
      case FieldTypeEnum.textInput:
        return 'text';
      case FieldTypeEnum.dropDown:
        return 'dropdown';
      case FieldTypeEnum.checkMark:
        return 'checkbox';
      case FieldTypeEnum.radio:
        return 'radio';
      case FieldTypeEnum.referenceMedia:
        return 'media';
      case FieldTypeEnum.media:
        return 'media';
      case FieldTypeEnum.timePicker:
        return 'time';
      case FieldTypeEnum.datePicker:
        return 'date';
      case FieldTypeEnum.notApplicable:
        return 'notapplicable';
      default:
        return;
    }
  }, [fieldType]);

  return (
    <Col>
      <Button
        justifyContent="flex-start"
        width="18rem"
        padding="0 0 0 3rem"
        height="50px"
        icon={icon}
        onClick={handleAddComponent}
        label={title}
      />
    </Col>
  );
};
