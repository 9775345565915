import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  border: 1px solid ${colors.white};
  color: ${colors.white};
  width: 3rem;
  margin: 2rem 2.5rem 0;
`;

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0.25rem 0;
  font-size: ${fontSizes.mediumSmall};
  font-weight: ${fontWeights.semiBold};

  &:active {
    background: ${colors.whiteTenPercent};
  }
`;

const ZoomStepper = ({ zoomIn, zoomOut }: { zoomIn: () => void; zoomOut: () => void }) => {
  return (
    <StyledWrapper>
      <StyledButton
        type="button"
        aria-label="zoom in"
        data-testid="zoom-in-button"
        onClick={() => {
          zoomIn();
        }}
      >
        +
      </StyledButton>
      <StyledButton
        type="button"
        aria-label="zoom out"
        data-testid="zoom-out-button"
        onClick={() => {
          zoomOut();
        }}
      >
        –
      </StyledButton>
    </StyledWrapper>
  );
};

export default ZoomStepper;
