import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { integrationAuth } from 'api/integrations/integrationService';

import { IntegrationConnectionProps } from '../types';
import ConnectionStatus from './ConnectionStatus';

const IntegrationConnection = ({
  isAuth,
  isConnected,
  setIsConnected,
  integration,
}: IntegrationConnectionProps) => {
  const { t } = useTranslation();

  const handleIntegrationAuth = async () => {
    const authData = await integrationAuth(integration.api);
    window.location.href = authData.data;
  };

  return (
    <div className="IntegrationConnection">
      {isAuth ? (
        <ConnectionStatus
          isConnected={isConnected}
          setIsConnected={setIsConnected}
          integration={integration}
        />
      ) : (
        <Button type="primary" onClick={handleIntegrationAuth} data-testid="connect-button">
          {t('Integrations.connect')}
        </Button>
      )}
    </div>
  );
};

export default IntegrationConnection;
