import Picker from 'emoji-picker-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import PropTypes from 'prop-types';

import './EmojiPicker.scss';

const EmojiPicker = ({ onEmojiClick, toggleEmojiPickerVisible }) => {
  const emojiPicker = useOnclickOutside(() => {
    toggleEmojiPickerVisible();
  });

  return (
    <span ref={emojiPicker}>
      <Picker
        disableSearchBar={true}
        groupVisibility={{
          activities: false,
          flags: false,
          recently_used: false,
        }}
        onEmojiClick={onEmojiClick}
      />
    </span>
  );
};

EmojiPicker.propTypes = {
  onEmojiClick: PropTypes.func.isRequired,
  toggleEmojiPickerVisible: PropTypes.func.isRequired,
};

export default EmojiPicker;
