import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerLabel = styled.div`
  font-weight: bold;
  margin: 1rem 0;
`;

const TextWrapper = styled.div`
  display: flex;
`;

type Props = {
  answer: string | null;
};

export const TextAnswer = ({ answer }: Props) => {
  const { t } = useTranslation();
  return (
    <AnswerWrapper>
      <AnswerLabel>{t('Checklists.answer')}</AnswerLabel>
      <TextWrapper>{answer !== null && answer}</TextWrapper>
    </AnswerWrapper>
  );
};
