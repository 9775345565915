import React, { ChangeEvent } from 'react';

import styled from 'styled-components';

interface CheckmarkInputProps {
  id: string;
  label: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  readOnly?: boolean;
}

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 1rem;
`;

const CheckboxLabel = styled.span`
  text-align: center;
`;

export const CheckmarkInput: React.FC<CheckmarkInputProps> = ({
  id,
  label,
  onChange,
  checked,
  readOnly = true,
}: CheckmarkInputProps) => {
  return (
    <CheckboxContainer htmlFor={id}>
      <CheckboxInput id={id} onChange={onChange} checked={checked} disabled={readOnly} />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};
