export const createDesignServiceRequestUrl = () => `/serviceRequests`;
// The below variable has the same value as the above. Did this for legibility purposes.
export const fetchDesignServicesQueueProjectsUrl = () => `/serviceRequests`;

export const fetchDesignServicesQueueCompanyProjectsUrl = (companyId?: string) =>
  `/serviceRequests/company/${companyId}`;

export const updateProjectDesignStatusUrl = (id: string) => `/serviceRequests/${id}`;

export const deleteDesignServiceRequestUrl = (id: string) => `/serviceRequests/${id}`;

export const fetchDesignServiceRequestsByProjectIdUrl = (projectId: string) =>
  `/serviceRequests/project/${projectId}`;

export const orderOneOffCreditsUrl = (companyId: string) => `/credits/${companyId}`;

export const listAllCreditTransactionsUrl = (companyId: string) => `/credits/${companyId}`;

export const chargeExtraCreditsForServiceRequestUrl = () => `/credits/chargeExtra`;

export const listAllCreditTransactionsPerMonthUrl = (month: number, year: number) =>
  `/credits/all/${month}/${year}`;

export const adjustCreditsUrl = (companyId: string) => `/credits/${companyId}/manualAdjustment`;

export const updateServiceRequestUrl = (serviceRequestId: string) =>
  `/serviceRequests/${serviceRequestId}`;

export const getDesignServiceStatisticsUrl = () => `/serviceRequests/statistics`;

export const getDesignServicesSettingsUrl = (companyId: string) =>
  `/designServicesSettings/${companyId}`;

export const createDesignServicesSettingsUrl = (companyId: string) =>
  `/designServicesSettings/${companyId}`;

export const updateDesignServicesSettingsUrl = (companyId: string) =>
  `/designServicesSettings/${companyId}`;
