export const SYSTEM_INFORMATION_FORM = {
  SYSTEM_SIZE: {
    title: 'What is the system size (DC kW)?',
    placeholder: 'Select range',
    options: [
      { label: '1 - 30 kW', value: '1-30' },
      { label: '31 - 99 kW', value: '31-99' },
      { label: '100+ kW', value: '100+' },
    ],
  },
  ELECTRICAL_EQUIPMENT_DESCRIPTION: {
    title: 'Please provide a description of the electrical equipment uploaded',
    placeholder: '200A MDP inside garage',
  },
};

export const SITE_INFORMATION_FORM = {
  SETBACKS: {
    title: 'List all fire setbacks and obstruction setbacks',
    placeholder: '36" from the ridge',
  },
  VIEWSHEDS: {
    title: 'Where should viewsheds be placed for shade analysis?',
    options: [
      {
        label: '1 viewshed in the center of each panel',
        value: '1 viewshed in the center of each panel',
      },
      {
        label: '1 viewshed in 4 corners of each panel',
        value: '1 viewshed in 4 corners of each panel',
      },
    ],
    placeholder: 'Select viewshed placement',
  },
  AHJ: {
    title: 'Name of the AHJ associated with this project?',
    placeholder: 'AHJ Name',
  },
};
