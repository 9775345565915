import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { EmailLink } from 'components';

import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';

import ActionButtons from '../ActionButtons/ActionButtons';

const ProjectProcessingError = ({ handleModalOpen }: { handleModalOpen: () => void }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="DesignStatus-Wrapper">
      <ErrorIcon className="DesignStatus-ErrorIcon" />
      <div className="DesignStatus-Title">{t('DesignStatusDisplay.processingError')}</div>
      <div className="DesignStatus-Text">
        <p>
          Please reach out to our Customer Success team (chat box below or <EmailLink />) for more
          information.
        </p>
      </div>
      <ActionButtons
        handleClick={() => handleMyProjectsButtonClick(history)}
        handleCancel={handleModalOpen}
      />
    </div>
  );
};

export default ProjectProcessingError;
