import { DESIGN_STATUS } from '@cpm/scanifly-shared-data';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ServiceRequest from 'types/ServiceRequest';

import {
  deleteDesignServiceRequest,
  fetchCompanyProjectsForDesignServiceQueue,
  fetchProjectsForDesignServiceQueue,
  updateProjectDesignStatus,
} from 'api/designServices/designServicesService';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { omit } from 'lodash';
import { getUpdateDesignStatusNotification } from 'screens/DesignServices/helpers/getUpdateNotification';

const name = 'designServicesQueue';

type StateType = {
  designServiceQueueCompanyProjectList: Array<ServiceRequest>;
  averageTurnaroundTime: null | number;
  countsByStatus: Partial<Record<DESIGN_STATUS, number>> | null;
  designServiceQueueProjectList: Array<ServiceRequest>;
  projectsForCSVExport: Array<ServiceRequest>;
  projectsLoadedSuccessfully: boolean;
  isLoading: boolean;
  statusUpdateError: null | string;
  statusUpdateSuccess: null | string;
  error: any;
  projectCount: number;
  pageIndex: number;
};

export const projectsRequested = createAsyncThunk(
  `${name}/projectsRequested`,
  async (
    {
      pageIndex = 1,
      size = PROJECT_LIST_SIZE,
      sortBy = undefined,
      filterBy = undefined,
      companyId,
    }: { pageIndex: number; size: number; sortBy?: any; filterBy?: any; companyId?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchProjectsForDesignServiceQueue(
        pageIndex,
        size,
        sortBy,
        filterBy,
        companyId
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const companyProjectsRequested = createAsyncThunk(
  `${name}/companyProjectsRequested`,
  async (
    {
      pageIndex = 1,
      size = PROJECT_LIST_SIZE,
      sortBy = undefined,
      filterBy = undefined,
      companyId,
    }: { pageIndex: number; size: number; sortBy?: any; filterBy?: any; companyId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchCompanyProjectsForDesignServiceQueue({
        pageIndex,
        size,
        sortBy,
        filterBy,
        companyId,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const serviceRequestDeletionRequested = createAsyncThunk(
  `${name}/serviceRequestDeletionRequested`,
  async (
    { id, onSuccess }: { id: string; onSuccess: (id: string) => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteDesignServiceRequest(id);
      onSuccess(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const designStatusUpdateRequested = createAsyncThunk(
  `${name}/designStatusUpdateRequested`,
  async (
    {
      designId,
      projectName,
      targetStatus,
      onSuccess,
    }: {
      designId: string;
      projectName: string;
      targetStatus?: DESIGN_STATUS;
      onSuccess?: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateProjectDesignStatus(designId, targetStatus);
      if (onSuccess) {
        onSuccess();
      } else {
        getUpdateDesignStatusNotification({ type: 'success', projectName, targetStatus });
      }
      return response.data;
    } catch (error: any) {
      getUpdateDesignStatusNotification({ type: 'error', projectName, targetStatus, error });
      return rejectWithValue(error.response.data);
    }
  }
);

const designServicesQueueSlice = createSlice({
  name,
  initialState: {
    designServiceQueueCompanyProjectList: [],
    designServiceQueueProjectList: [],
    projectsForCSVExport: [],
    countsByStatus: null,
    averageTurnaroundTime: null,
    projectsLoadedSuccessfully: false,
    isLoading: true,
    statusUpdateError: null,
    statusUpdateSuccess: null,
    error: null,
    projectCount: 0,
    pageIndex: 1,
  } as StateType,
  reducers: {
    resetProjectsLoadedSuccessfully: (state) => {
      state.projectsLoadedSuccessfully = false;
    },
    resetStatusUpdateFlags: (state) => {
      state.statusUpdateError = null;
      state.statusUpdateSuccess = null;
    },
    updateServiceRequestForQueue: (state, action: { payload: ServiceRequest }) => {
      state.designServiceQueueProjectList = state.designServiceQueueProjectList?.length
        ? state.designServiceQueueProjectList.map((serviceRequest) =>
            serviceRequest.id === action.payload.id ? action.payload : serviceRequest
          )
        : [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectsRequested.pending, (state: StateType) => {
        state.isLoading = true;
        state.projectsLoadedSuccessfully = false;
      })
      .addCase(projectsRequested.fulfilled, (state: StateType, { payload }) => {
        state.designServiceQueueProjectList = payload.items;
        if (state.projectsForCSVExport.length !== payload.items.length) {
          state.projectsForCSVExport = payload.items;
        }
        state.pageIndex = payload.pageIndex;
        state.projectsLoadedSuccessfully = true;
        state.projectCount = payload.totalCount;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(companyProjectsRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.projectsLoadedSuccessfully = false;
        state.isLoading = false;
      })
      .addCase(companyProjectsRequested.pending, (state: StateType) => {
        state.isLoading = true;
        state.projectsLoadedSuccessfully = false;
      })
      .addCase(companyProjectsRequested.fulfilled, (state: StateType, { payload }) => {
        state.designServiceQueueCompanyProjectList = payload.items;
        if (state.projectsForCSVExport.length !== payload.items.length) {
          state.projectsForCSVExport = payload.items;
        }
        state.averageTurnaroundTime = payload.averageTurnaroundTime;
        state.countsByStatus = omit(payload, [
          'averageTurnaroundTime',
          'items',
          'totalCount',
          'pageIndex',
        ]);
        state.pageIndex = payload.pageIndex;
        state.projectsLoadedSuccessfully = true;
        state.projectCount = payload.totalCount;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(projectsRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.projectsLoadedSuccessfully = false;
        state.isLoading = false;
      })
      .addCase(designStatusUpdateRequested.fulfilled, (state: StateType, { payload }) => {
        state.designServiceQueueProjectList = [
          ...state.designServiceQueueProjectList.filter((project) => project.id !== payload.id),
          payload,
        ];
        state.statusUpdateSuccess = `Status for ${payload.name} project has been successfully updated.`;
        state.isLoading = false;
      })
      .addCase(designStatusUpdateRequested.rejected, (state, { error }) => {
        state.statusUpdateError = error.message ?? null;
        state.statusUpdateSuccess = null;
        state.isLoading = false;
      })
      .addCase(serviceRequestDeletionRequested.fulfilled, (state: StateType, { payload }) => {
        state.designServiceQueueProjectList = state.designServiceQueueProjectList.filter(
          (project) => project.id !== payload._id
        );
        state.isLoading = false;
      })
      .addCase(serviceRequestDeletionRequested.rejected, (state, { error }) => {
        state.statusUpdateError = error.message ?? null;
        state.statusUpdateSuccess = null;
        state.isLoading = false;
      });
  },
});

export const {
  resetProjectsLoadedSuccessfully,
  resetStatusUpdateFlags,
  updateServiceRequestForQueue,
} = designServicesQueueSlice.actions;

export default designServicesQueueSlice.reducer;
