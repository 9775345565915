import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DatePicker, Input, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import moment from 'moment';
import CreditsTransaction from 'types/CreditTransaction';
import { CreditTransactionFilter, Extra, Pagination, Sorter, TableParams } from 'types/TableParams';

import { allCreditTransactionsRequestedPerMonth } from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { CSVExportButton, GoBackButton } from 'components';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { monthDateFormat } from 'helpers/utils/dateFormat';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterBy } from './helpers/getFilterBy';
import { handleCreditTransactionsCSVExport } from './helpers/handleCreditTransactionsCSVExport';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import '../ScaniflyAdmin.scss';
import { CREDIT_HISTORY_CSV_COLUMN_TITLES_FOR_ALL_COMPANIES } from './constants/CREDIT_HISTORY_CSV_COLUMN_TITLES';
import TableData from './TableData';

const CreditHistoryAll = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [date, setDate] = useState<moment.Moment>(moment().startOf('month').subtract(1, 'month'));
  const [searchText, setSearchText] = useState('');
  const [displayedData, setDisplayedData] = useState<CreditsTransaction[]>([]);

  const [tableParams, setTableParams] = useState<
    TableParams<CreditsTransaction, CreditTransactionFilter>
  >({
    pagination: {
      current: 1,
    },
  });

  const { allCreditTransactionsByMonth, isAllCreditTransactionsByMonthLoading } = useSelector(
    (state: RootState) => state.orderDesignServiceCredits
  );

  const year = date.year();
  const month = date.month();

  useEffect(() => {
    if (!isAllCreditTransactionsByMonthLoading) {
      setDisplayedData(allCreditTransactionsByMonth?.items);
    }
  }, [allCreditTransactionsByMonth?.items, isAllCreditTransactionsByMonthLoading]);

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    dispatch(
      allCreditTransactionsRequestedPerMonth({
        month,
        year,
        size: PROJECT_LIST_SIZE,
        pageIndex: pagination?.current ?? 1,
        sortBy: getSortBy(columnKey, order),
        filterBy: getFilterBy({ filters, searchText }),
      })
    );
  }, [dispatch, month, searchText, tableParams, year]);

  const handleTableChange = (
    pagination: Pagination,
    filters: CreditTransactionFilter | Record<string, FilterValue | null>,
    sorter: Sorter<CreditsTransaction> | Sorter<CreditsTransaction>[],
    extra: Extra<CreditsTransaction>
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  const handleDateChange = (value: moment.Moment | null) => {
    if (value) {
      setDate(value);
    }
  };

  return (
    <div className="ScaniflyAdmin-Wrapper">
      <div className="ScaniflyAdmin-SubHeader">
        <GoBackButton />
        <Input
          placeholder={t('Shared.search')}
          role="searchbox"
          prefix={<SearchIcon />}
          aria-label="search credit transactions"
          onChange={debounce((e) => {
            setSearchText(e.target.value);
          }, 400)}
          className="ScaniflyAdmin-Search"
        />
        <div className="ScaniflyAdmin-DatePicker">
          <DatePicker
            picker="month"
            value={date}
            format={monthDateFormat}
            allowClear={false}
            onChange={handleDateChange}
            aria-label="filter table by month"
            data-testid="credit-table-date-picker"
          />
        </div>
        <div data-testid="transaction-count" className="ScaniflyAdmin-DataDisplay">
          {allCreditTransactionsByMonth?.totalCount ?? 0} transactions
        </div>
        <CSVExportButton
          tableName={TABLE_NAMES.CREDIT_HISTORY_FOR_ALL_COMPANIES}
          columnTitles={CREDIT_HISTORY_CSV_COLUMN_TITLES_FOR_ALL_COMPANIES}
          prepareCSVData={handleCreditTransactionsCSVExport}
          month={month}
          year={year}
        />
      </div>
      <Table
        title={() => (
          <div className="Accounting-Table-Title">
            {date.format('YYYY MMMM') + ' Credit History Table'}
          </div>
        )}
        className="ScaniflyAdmin-Table"
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'Accounting-Table-Row--Light' : 'Accounting-Table-Row--Dark'
        }
        columns={TableData({ isAllCompaniesCreditHistoryTable: true })}
        dataSource={displayedData}
        rowKey="id"
        scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
        pagination={{
          total: allCreditTransactionsByMonth?.totalCount,
          pageSize: PROJECT_LIST_SIZE,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={(pagination, filters, sorter, extra) =>
          handleTableChange(pagination, filters, sorter, extra)
        }
        loading={{
          indicator: (
            <div className="ScaniflyAdmin-Spinner">
              <Spin size="large" />
            </div>
          ),
          spinning: isAllCreditTransactionsByMonthLoading,
        }}
      />
    </div>
  );
};

export default CreditHistoryAll;
