import { RadioFieldType } from '@cpm/scanifly-shared-data';

import {
  Description,
  FieldType,
  Label,
  OptionAnswer,
  Options,
  Required,
  Seperator,
} from './components';

type Props = {
  item: RadioFieldType;
};

export const Radio = ({ item }: Props) => {
  return (
    <>
      <FieldType type={item.componentType} />
      <Label text={item.label} />
      {item.description && <Description text={item.description} />}
      <Options options={item.options} />
      <Required required={item.isRequired} />
      <Seperator />
      <OptionAnswer answer={item.value} />
    </>
  );
};
