import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};

const DownloadFeedbackPDFButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <span
      className="DroneDataScore-Download"
      onClick={onClick}
      aria-label="download drone data pdf"
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      {t('DroneDataScore.download')}
    </span>
  );
};

export default DownloadFeedbackPDFButton;
