import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

const CategoryFilter = ({ filterOptions, handleOptionsChange, currentValue }) => {
  const { Group } = Checkbox;

  return (
    <Group
      className="CategoryFilter"
      options={filterOptions}
      onChange={handleOptionsChange}
      value={currentValue}
    />
  );
};

CategoryFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.node, value: PropTypes.string })
  ),
  handleOptionsChange: PropTypes.func,
  currentValue: PropTypes.arrayOf(PropTypes.string),
};

export default CategoryFilter;
