import { DESIGN_TIER_LEVEL_FILTERS } from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import Revision from 'types/Revision';
import ServiceRequest from 'types/ServiceRequest';

import { SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import { formatDate } from 'helpers/utils/dateFormat';

import { DESIGN_COMPLETED_DISPLAY_TITLES } from './OrderList';

const RevisionList = ({
  serviceRequest,
  modelRevision,
  customStyle,
  index,
  handleOpenRevisionDataModal = () => {},
}: {
  serviceRequest: ServiceRequest;
  modelRevision: Revision[];
  customStyle?: string;
  index: number;
  handleOpenRevisionDataModal?: (revision: Revision) => void;
}) => {
  return (
    <div key={`revisionList-${index}`}>
      {modelRevision.map((revision: Revision, i: number) => {
        return (
          <div className={customStyle} key={`revisionRow-${i}`}>
            {DESIGN_COMPLETED_DISPLAY_TITLES.map((item) => {
              const { field } = item || {};
              let style = 'DesignStatus-Field';
              let value;
              if (field === 'createdAt') {
                value = revision.createdAt ? formatDate(revision.createdAt) : '--';
              } else if (field === 'completedDate') {
                value = revision.completedAt ? formatDate(revision.completedAt) : '--';
              } else if (field === 'tierLevel') {
                value = DESIGN_TIER_LEVEL_FILTERS.find(
                  (item) => item.value === serviceRequest[field]
                )?.text;
                style = 'DesignStatus-ProductField';
              } else if (field === 'modelRevision') {
                value = <SidebarTag type={TagTypes.upgrade} text="Revision" />;
              } else if (field === 'orderForm') {
                value = (
                  <Button onClick={() => handleOpenRevisionDataModal(revision)}>
                    Revision Form
                  </Button>
                );
              } else if (field === null || field === 'status') {
                value = ' ';
              }
              return (
                <div className={style} key={item.title}>
                  {value && Object.keys(value).length ? value : '--'}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default RevisionList;
