import styled from 'styled-components';

import colors from 'helpers/constants/colors';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blueGray};

  .image {
    width: 30vh;
    height: 30vh;
    margin-top: -9vh;
  }
`;

export default Container;
