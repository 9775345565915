import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { adminCompanyNameAndIdRequested } from 'state/slices/admin/adminCompaniesSlice';

import { adminGenerateAccessToken } from 'api/admin/adminService';

import { CustomSelect } from 'components';

import { openNotification } from 'helpers/utils/openNotification';

import './AccessTokens.scss';
import { fetchAccessTokens, handleAccessTokenCopyClick } from './helpers';

const handleGenerateToken = async (
  selectedCompany,
  pageIndex,
  searchTerm,
  setIsDataLoading,
  setTotalCount,
  setTableData,
  setAccessToken
) => {
  try {
    const token = await adminGenerateAccessToken(selectedCompany?.value);
    setAccessToken(token.data);
    openNotification({
      type: 'success',
      title: 'Access Token Generated!',
      text: `Token creation for '${selectedCompany?.label}' was successful.`,
    });
    fetchAccessTokens(pageIndex, searchTerm, setIsDataLoading, setTotalCount, setTableData);
  } catch (error) {
    const status = error.response.status;
    openNotification({
      type: 'error',
      title:
        status === 409
          ? 'A token for this company already exists.'
          : 'There was an error while creating the access token.',
      text:
        status === 409
          ? 'For now, we can only generate a single access token for a company.'
          : 'Please try again in a few seconds.',
    });
  }
};

const GenerateTokenModal = ({
  handleModalClose,
  pageIndex,
  searchTerm,
  setIsDataLoading,
  setTotalCount,
  setTableData,
  selectedCompany,
  accessToken,
  setSelectedCompany,
  setAccessToken,
}) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { companyNameAndIdList } = useSelector((state) => state.adminCompanies);
  const dispatch = useDispatch();

  const options = companyNameAndIdList.map((company) => {
    return { value: company.id, label: company.name };
  });

  useEffect(() => {
    dispatch(adminCompanyNameAndIdRequested());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      setIsErrorVisible(false);
    }
  }, [selectedCompany]);

  return (
    <div className="ScaniflyAdmin-GenerateTokenModal-ContentWrapper">
      {accessToken ? (
        <>
          <div className="ScaniflyAdmin-GenerateTokenModal-Description">
            The access token created for {selectedCompany?.label} is only going to be shown here
            once, so be sure to copy it to your clipboard:
          </div>
          <div className="ScaniflyAdmin-GenerateTokenModal-Token">
            <div
              className="ScaniflyAdmin-GenerateTokenModal-TokenText"
              aria-label="copy access token value"
            >
              {accessToken}
            </div>
            <CopyOutlined
              role="button"
              aria-label="copy access token value"
              className="ScaniflyAdmin-ButtonWrapper-CopyIcon"
              onClick={() => handleAccessTokenCopyClick(accessToken, selectedCompany)}
            />
          </div>
          <div className="ScaniflyAdmin-GenerateTokenModal-Buttons-Wrapper">
            <Button onClick={handleModalClose} className="Button--Red">
              Close
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="ScaniflyAdmin-GenerateTokenModal-SelectWrapper">
            <label htmlFor="selectCompany">Company</label>
            <CustomSelect
              onChange={(option) => setSelectedCompany(option)}
              placeholder="Select Company"
              options={options}
              value={selectedCompany?.value}
              variant="form"
              id="selectCompany"
            />
            <div
              className={
                isErrorVisible ? 'ScaniflyAdmin-Error-Visible' : 'ScaniflyAdmin-Error-NotVisible'
              }
            >
              {'Selecting a company is required.'}
            </div>
          </div>
          <div className="ScaniflyAdmin-GenerateTokenModal-Buttons-Wrapper">
            <Button onClick={handleModalClose} className="Button--White">
              Cancel
            </Button>
            <Button
              onClick={() =>
                !selectedCompany
                  ? setIsErrorVisible(true)
                  : handleGenerateToken(
                      selectedCompany,
                      pageIndex,
                      searchTerm,
                      setIsDataLoading,
                      setTotalCount,
                      setTableData,
                      setAccessToken
                    )
              }
              className="Button--Blue"
              autoFocus
            >
              Create
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

GenerateTokenModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  pageIndex: PropTypes.number,
  searchTerm: PropTypes.string,
  setIsDataLoading: PropTypes.func,
  setTotalCount: PropTypes.func,
  setTableData: PropTypes.func,
  setGenerateTokenModalVisible: PropTypes.func,
  selectedCompany: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  accessToken: PropTypes.string,
  setSelectedCompany: PropTypes.func,
  setAccessToken: PropTypes.func,
};

export default GenerateTokenModal;
