import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldTypeEnum } from '@cpm/scanifly-shared-data';

import styled from 'styled-components';

import { Button, ModalContext, UpDownButton } from 'components';
import OptionsButton from 'components/OptionsButton/OptionsButton';

import colors from 'helpers/constants/colors';

import { FieldTypeDisplay } from './FieldTypeDisplay';

interface EditButtonsProps {
  mode: 'edit' | 'display';
  componentType: FieldTypeEnum;
  onDelete?: () => void;
  onCopy?: () => void;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  onCondition?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Slot = styled.div`
  flex: 0 auto;
  flex-direction: row;
`;

const SlotOne = styled(Slot)``;

const SlotTwo = styled(Slot)``;

// The up down buttons are slightly larger than the other buttons
const SlotThree = styled(Slot)`
  flex: 0 auto;
`;

const SlotFour = styled(Slot)`
  flex: 0 auto;
`;

export const EditButtons = ({
  mode,
  componentType,
  onEdit,
  onDelete,
  onCopy,
  onSave,
  onCancel,
  onUp,
  onDown,
  onCondition,
}: EditButtonsProps) => {
  const { t } = useTranslation();
  const { displayDeleteModal } = useContext(ModalContext);

  const onDeleteClick = () => {
    displayDeleteModal({
      title: t('Checklists.deleteField'),
      description: t('Checklists.deleteFieldDescription'),
      actionButtonOnClick: () => onDelete && onDelete(),
      actionButtonLabel: t('buttonTexts.delete'),
    });
  };

  const onConditionClick = () => {
    onCondition && onCondition();
  };

  return (
    <Container>
      <SlotOne>
        <FieldTypeDisplay type={componentType} />
      </SlotOne>
      <SlotTwo>
        {onEdit && mode === 'display' && (
          <Button
            color={colors.mainBlue}
            width="8rem"
            icon="edit"
            onClick={onEdit}
            label={t('Checklists.edit')}
          />
        )}
        {onSave && mode === 'edit' && (
          <Button
            icon="save"
            color={colors.green}
            width="8rem"
            onClick={onSave}
            label={t('Checklists.save')}
          />
        )}
      </SlotTwo>
      <SlotThree>
        {onUp && onDown && mode === 'display' && (
          <UpDownButton upOnClick={onUp} downOnClick={onDown} />
        )}
        {onCancel && mode === 'edit' && (
          <Button onClick={onCancel} width="8rem" label={t('Checklists.cancel')} />
        )}
      </SlotThree>
      <SlotFour>
        {onEdit && mode === 'display' && (
          <OptionsButton>
            {onCopy && mode === 'display' && (
              <Button border="none" onClick={onCopy} icon="copy" label={t('Checklists.copy')} />
            )}
            <Button
              border="none"
              icon="delete"
              label={t('Checklists.delete')}
              onClick={onDeleteClick}
            />
            {onCondition && mode === 'display' && (
              <Button
                border="none"
                onClick={onConditionClick}
                icon="add"
                label={t('Checklists.condition')}
              />
            )}
          </OptionsButton>
        )}
        {onDelete && mode === 'edit' && (
          <Button
            icon="delete"
            color={colors.red}
            onClick={onDelete}
            width="auto"
            padding={'0 0 0 1rem'}
            iconSize="2rem"
          />
        )}
      </SlotFour>
    </Container>
  );
};
