import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { UploadedUppyFile, UploadResult, UppyFile } from '@uppy/core';
import { Space, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import MapWrapper from 'screens/MapWrapper';
import { MediaItem, Note as NoteType } from 'types';

import {
  CreateNotePayload,
  notesActions,
  selectIsLoading,
  selectIsNoteLoading,
  selectNotes,
  selectSelectedNote,
} from 'state/slices/notes';
import { notificationRequested } from 'state/slices/notificationSlice';
import { projectRequested, userUpdatedProjectTeammate } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { Button, ImagePreview, UploadDashboardModal } from 'components';

import colors from 'helpers/constants/colors';
import { rootRoute } from 'helpers/constants/routes';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as NoteIcon } from 'assets/notes.svg';

import { FormFields, FormValues, InitialValues, LABELS } from './constants';
import EditNoteModal from './EditNoteModal/EditNoteModal';
import Note from './Note/Note';
import './Notes.scss';
import NotesTextarea from './NotesTextarea/NotesTextarea';

const Notes = ({ isAdminRoute = false }: { isAdminRoute?: boolean }) => {
  const [addedFiles, setAddedFiles] = useState<UploadedUppyFile<any, any>[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<MediaItem | null | UppyFile>(null);
  const [isUploadModalVisible, toggleIsUploadModalVisible] = useToggle(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // Backend needs time to save note so we wait before fetching,
  // need to prevent user from doing things during this window
  const [isWaitingToFetchNote, setIsWaitingToFetchNote] = useState(false);
  const [value, setValue] = useState('');

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const notesEndRef = useRef<HTMLDivElement>(null);
  const notesWrapperRef = useRef<HTMLDivElement>(null);

  const { projectId } = useParams<{ projectId: string }>();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { project } = useSelector((state: RootState) => state.project);
  const notes = useSelector((state: RootState) => selectNotes(state));
  const selectedNote = useSelector((state: RootState) => selectSelectedNote(state));
  const isLoading = useSelector((state: RootState) => selectIsLoading(state));
  const isNoteLoading = useSelector((state: RootState) => selectIsNoteLoading(state));

  const pin = {
    location: project?.geolocation || { latitude: 0, longitude: 0 },
    id: '1',
  };

  const createNoteAction = useCallback(
    async (payload: CreateNotePayload): Promise<void> => {
      await dispatch(notesActions.createNote(payload));
    },
    [dispatch]
  );
  const getNoteAction = useCallback(async () => {
    if (selectedNote?.id) {
      dispatch(notesActions.noteRequested(selectedNote?.id));
    }
  }, [dispatch, selectedNote?.id]);
  const setSelectedNoteAction = useCallback(
    (payload: NoteType | null) => {
      setIsEditing(payload !== null);
      dispatch(notesActions.setSelectedNote(payload));
    },
    [dispatch]
  );
  const createBlankNoteAction = useCallback(async () => {
    try {
      await createNoteAction({
        text: '',
        projectId,
      });
    } catch (err: any) {
      Sentry.captureException(err);
    }
  }, [createNoteAction]); // eslint-disable-line react-hooks/exhaustive-deps
  const updateNoteAction = useCallback(
    async (text: string) => {
      if (!selectedNote?.id) {
        return;
      }
      const media = selectedNote.media
        .filter(({ id }) => !deletedFiles.includes(id))
        .map(({ id }) => id);
      await dispatch(
        notesActions.updateNote({
          projectId,
          noteId: selectedNote.id,
          text,
          media,
        })
      );
      await createBlankNoteAction();
    },
    [createBlankNoteAction, deletedFiles, dispatch, projectId, selectedNote]
  );

  const filterMedia = useCallback(
    (uploadedMedia: MediaItem[], uppyMedia: UploadedUppyFile<any, any>[] = []) => {
      // Filter out any media that have been deleted or duplicated
      const seenFilenames = new Set();
      uploadedMedia.forEach(({ originalFilenameWithoutExtension, originalFileExtension }) => {
        const fileName = `${originalFilenameWithoutExtension}${originalFileExtension}`;
        seenFilenames.add(fileName);
      });

      const mergedArray: (MediaItem | UploadedUppyFile<any, any>)[] = [...uploadedMedia];

      uppyMedia.forEach((uppyItem) => {
        const fileName = uppyItem.name;
        if (!seenFilenames.has(fileName)) {
          seenFilenames.add(fileName);
          mergedArray.push(uppyItem);
        }
      });
      return mergedArray.filter(
        (item) => !deletedFiles.includes(item.id || (item as MediaItem).fileId)
      );
    },
    [deletedFiles]
  );

  const filesToRender = useMemo(() => {
    return filterMedia(selectedNote?.media ?? [], addedFiles);
  }, [filterMedia, selectedNote, addedFiles]);

  const { handleSubmit, setFieldValue, getFieldProps } = useFormik<FormValues>({
    initialValues: InitialValues,
    onSubmit: async (values) => {
      if (!selectedNote?.id) {
        return;
      }
      await updateNoteAction(values.note);

      // If user leaving note has not been added to project, they're automatically added
      if (currentUser && project) {
        const isCurrentUserTeammateOnProject = project.teammates.some((teammate) => {
          if (typeof teammate === 'string') {
            // Handle the case where teammate is a string (assuming it's a userId)
            return teammate === currentUser.id;
          } else {
            // Handle the case where teammate is a User object
            return teammate.id === currentUser.id;
          }
        });
        if (!isCurrentUserTeammateOnProject) {
          dispatch(
            userUpdatedProjectTeammate({
              projectId: project!.id,
              userId: currentUser!.id!,
            })
          );
        }
      }
      setValue('');
      setFieldValue(FormFields.note, InitialValues.note);
      setAddedFiles([]);
      setDeletedFiles([]);
    },
  });

  const handleSaveAndExit = () => {
    history.push(rootRoute());
  };

  const editNoteModalOnClose = useCallback(() => {
    setAddedFiles([]);
    setDeletedFiles([]);
    setSelectedImage(null);
    setIsEditing(false);
  }, []);

  const handleUploadComplete = useCallback(
    (result: UploadResult) => {
      setIsWaitingToFetchNote(true);
      setTimeout(() => {
        getNoteAction();
        setIsWaitingToFetchNote(false);
      }, 5000);

      setAddedFiles((prevAddedFiles) => {
        // User has uploaded media to a new note
        return prevAddedFiles.length ? prevAddedFiles.concat(result.successful) : result.successful;
      });
    },
    [getNoteAction]
  );

  const handleDeleteImage = (id: string) => {
    setDeletedFiles([id, ...deletedFiles]);
    setAddedFiles(addedFiles.filter((file) => file.id !== id));
  };

  const isScrollbarPresent = () => {
    return (
      (notesWrapperRef.current?.scrollHeight ?? 0) > (notesWrapperRef.current?.clientHeight ?? 0)
    );
  };

  useEffect(() => {
    if (!project || project.id !== projectId) {
      dispatch(projectRequested(projectId));
    }
  }, [dispatch, project, projectId]);

  useEffect(() => {
    dispatch(notesActions.notesRequested(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    notesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedNote]);

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(notificationRequested(currentUser!.id!));
    }
  }, [currentUser?.id, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Make sure we have a blank note to work with
   * and remove it when this component dismounts if the user didn't add to it
   */
  useEffect(() => {
    createBlankNoteAction();
    const cleanup = () => {
      notesActions.deleteBlankNote();
    };
    return cleanup;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled =
    (!selectedNote && !isNoteLoading) || value.length === 0 || isWaitingToFetchNote;
  const isImagePreviewVisible = !!(notes.length && selectedImage);

  return (
    <>
      <Prompt
        when={!!selectedNote || !!(addedFiles.length - deletedFiles.length)}
        message={t('Notes.unsavedChanges')}
      />
      {selectedNote?.id ? (
        <UploadDashboardModal
          isModalVisible={isUploadModalVisible}
          noteId={selectedNote?.id ?? ''}
          toggleIsModalVisible={toggleIsUploadModalVisible}
          handleUploadComplete={handleUploadComplete}
          categoryId={process.env.REACT_APP_NOTES_CATEGORY_ID!}
          endpoint={`${process.env.REACT_APP_API}/notes/upload`}
        />
      ) : null}
      {isEditing && selectedNote && (
        <EditNoteModal
          filesToRender={filesToRender}
          handleUpload={toggleIsUploadModalVisible}
          isWaitingToFetchNote={isWaitingToFetchNote}
          isUploadModalVisible={isUploadModalVisible}
          onClose={editNoteModalOnClose}
          selectedNote={selectedNote}
          setDeletedFiles={setDeletedFiles}
          setValue={setValue}
          updateNote={updateNoteAction}
          value={value}
        />
      )}
      <MapWrapper isClickable={false} pins={[pin]} hiddenVisibility={!!selectedImage}>
        <div className="Notes">
          {isAdminRoute && (
            <div className="Notes-SubmittedFor">
              {project?.submittedFor &&
                `Submitted for: ${project.submittedFor.firstName} ${project.submittedFor.lastName} at ${project.submittedFor.company.name}`}
            </div>
          )}
          <h2 className="Notes-ProjectName">{project?.name}</h2>
          <h3 className="Notes-Label">{t('Notes.addCommentsHere')}</h3>
          {notes?.length && notes.filter((note) => note.id !== selectedNote?.id)?.length ? (
            <div
              className={cn('Notes-Wrapper', {
                'Notes-Wrapper--Minimized': !!(addedFiles.length - deletedFiles.length),
                'Notes-Wrapper--Scroll': isScrollbarPresent(),
              })}
              ref={notesWrapperRef}
            >
              {notes.map(
                (note) =>
                  note.id !== selectedNote?.id &&
                  note.text && (
                    <Note
                      key={note.id}
                      note={note}
                      selected={!!note.media?.find((item) => item.id === selectedImage?.id)}
                      setSelectedNote={setSelectedNoteAction}
                      setSelectedImage={setSelectedImage}
                    />
                  )
              )}
              <div className="Notes-End" ref={notesEndRef} />
              {isLoading && (notes.length ? !selectedNote : null) && (
                <div className="Notes-Spinner">
                  <Spin size="large" />
                </div>
              )}
            </div>
          ) : (
            <div
              className={cn('Notes-Empty', {
                'Notes-Empty--Minimized': !!(addedFiles.length - deletedFiles.length),
              })}
            >
              {isLoading && (notes.length ? !selectedNote : null) ? (
                <Spin size="large" />
              ) : (
                /* @ts-ignore dumb component */
                <Space direction="vertical" size="large">
                  <NoteIcon />
                  <p>{t('Notes.noComments')}</p>
                </Space>
              )}
            </div>
          )}
          <form className="Notes-Form">
            {!isAdminRoute && (
              <>
                <NotesTextarea
                  files={filesToRender ?? []}
                  label={LABELS.ADD}
                  formControl={FormFields.note}
                  isUploadModalVisible={isUploadModalVisible}
                  toggleUploadModal={toggleIsUploadModalVisible}
                  getFieldProps={getFieldProps}
                  handleDelete={handleDeleteImage}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  value={value}
                  setValue={setValue}
                />
              </>
            )}
            <div className="Notes-Buttons">
              <div className="Notes-Buttons-Wrapper">
                {!isAdminRoute && (
                  <Tooltip title={isDisabled ? t('Notes.pleaseAddText') : ''} mouseEnterDelay={0.8}>
                    <Button
                      width="130px"
                      color={colors.mainBlue}
                      // @ts-ignore
                      onClick={handleSubmit}
                      aria-disabled={isDisabled}
                      disabled={isDisabled}
                      label="Add Comment"
                      loading={isWaitingToFetchNote}
                    />
                  </Tooltip>
                )}
                <Button width="120px" onClick={handleSaveAndExit} label="Save &amp; Exit" />
              </div>
            </div>
          </form>
        </div>
      </MapWrapper>
      {isImagePreviewVisible && (
        <ImagePreview
          image={selectedImage}
          allMedia={
            notes?.filter((note) => note.media?.includes(selectedImage as MediaItem))[0]?.media
          }
          setSelectedMediaItem={setSelectedImage}
          isDroneImages={false}
        />
      )}
    </>
  );
};

export default Notes;
