import { FormInput } from '../components';

import { useHandleSelectBlur } from 'helpers/hooks/useHandleSelectBlur';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, REQUIRED_ERROR } from '../constants';
import { StyledLeftCol, StyledRightCol, Wrapper } from '../styledComponents';
import { STRUCTURAL_INFORMATION_FORM } from './constants';
import FormProps from './props';

export const StructuralInfo = ({
  getFieldProps,
  setFieldValue,
  handleBlur,
  values,
  touched,
  errors,
}: FormProps) => {
  const [handleRoofingTypeBlur, getRoofingTypeSelectError] = useHandleSelectBlur(
    handleBlur,
    FORM_CONTROLS.ROOFING_TYPE
  );

  return (
    <Wrapper>
      <StyledLeftCol>
        {/* Roofing type */}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.ROOFING_TYPE.title}
          fieldProps={getFieldProps(FORM_CONTROLS.ROOFING_TYPE)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.ROOFING_TYPE.placeholder}
          id={FORM_CONTROLS.ROOFING_TYPE}
          error={getRoofingTypeSelectError(values)}
          blur={handleRoofingTypeBlur}
          customSelect
          options={STRUCTURAL_INFORMATION_FORM.ROOFING_TYPE.options}
          //@ts-ignore
          onChange={(option) => {
            setFieldValue(FORM_CONTROLS.ROOFING_TYPE, option.value);
          }}
          filter
          tall
          ariaLabel="select type of roofing"
          errorValidation={getRoofingTypeSelectError(values) && REQUIRED_ERROR}
        />
        {/* Attachment manufacturer and model */}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.ATTACHMENT.title}
          fieldProps={getFieldProps(FORM_CONTROLS.ATTACHMENT)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.ATTACHMENT.placeholder}
          ariaLabel="attachment"
          id={FORM_CONTROLS.ATTACHMENT}
          className={validateStatus(touched, errors, FORM_CONTROLS.ATTACHMENT)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.ATTACHMENT)}
          setFieldValue={setFieldValue}
        />
        {/* Racking  manufacturer and model*/}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.RACKING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.RACKING)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.RACKING.placeholder}
          id={FORM_CONTROLS.RACKING}
          className={validateStatus(touched, errors, FORM_CONTROLS.RACKING)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.RACKING)}
          setFieldValue={setFieldValue}
        />
      </StyledLeftCol>
      <StyledRightCol>
        {/* Center rafter spacing*/}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.CENTER_SPACING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.CENTER_SPACING)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.CENTER_SPACING.placeholder}
          id={FORM_CONTROLS.CENTER_SPACING}
          className={validateStatus(touched, errors, FORM_CONTROLS.CENTER_SPACING)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.CENTER_SPACING)}
        />
        {/* Rafter Size */}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.RAFTER_SIZE.title}
          fieldProps={getFieldProps(FORM_CONTROLS.RAFTER_SIZE)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.RAFTER_SIZE.placeholder}
          id={FORM_CONTROLS.RAFTER_SIZE}
          className={validateStatus(touched, errors, FORM_CONTROLS.RAFTER_SIZE)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.RAFTER_SIZE)}
        />
        {/* Preffered max spacing attachments */}
        <FormInput
          title={STRUCTURAL_INFORMATION_FORM.MAX_SPACING.title}
          fieldProps={getFieldProps(FORM_CONTROLS.MAX_SPACING)}
          placeHolder={STRUCTURAL_INFORMATION_FORM.MAX_SPACING.placeholder}
          id={FORM_CONTROLS.MAX_SPACING}
          className={validateStatus(touched, errors, FORM_CONTROLS.MAX_SPACING)}
          errorValidation={renderValidationMessage(touched, errors, FORM_CONTROLS.MAX_SPACING)}
        />
      </StyledRightCol>
    </Wrapper>
  );
};
