import { useSelector } from 'react-redux';

import { Alert } from 'antd';
import PropTypes from 'prop-types';

import { USER_ALREADY_EXISTS_ERROR } from 'helpers/constants/errors';
import { SUPPORT_URL } from 'helpers/constants/urls';

import './AccountLoginSecurityError.scss';

export default function AccountLoginSecurityError({ errorMessage }) {
  const { loginCredentialsUpdateError } = useSelector((state) => state.users);

  const message = () => {
    if (USER_ALREADY_EXISTS_ERROR.every((i) => loginCredentialsUpdateError.includes(i))) {
      return loginCredentialsUpdateError;
    }
    return (
      <div>
        {errorMessage}: Something went wrong, we couldn't update your login credentials. Please
        contact
        <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
          {' '}
          Scanifly support
        </a>{' '}
        for more information.
      </div>
    );
  };

  return <Alert className="AccountLoginSecurityError" message={message()} type="error" />;
}

AccountLoginSecurityError.propTypes = {
  errorMessage: PropTypes.string,
};
