import { MapViewport } from './types';

export const MAP_DEFAULTS: MapViewport = {
  width: '100%',
  height: '100%',
  bearing: 0,
  pitch: 0,
  latitude: 0,
  longitude: 0,
};

export const MAP_ZOOM = 19;

export const MAP_STYLES = {
  satellite: 'satellite',
  streets: 'streets',
};

export const MAP_THEMES = {
  satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  streets: 'mapbox://styles/mapbox/streets-v11',
};

export const MARKER_SIZE = 90;
