import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { loadStripe } from '@stripe/stripe-js';
import Root from 'screens/App/Root';

import { persistor, store } from 'state/store/store';

import { authorizationProvider } from 'api/config';

import { ModalProvider } from 'components';

import { STRIPE_FONT } from 'helpers/constants/stripeConstants';
import ScrollToTop from 'helpers/hocs/ScrollToTop';
import history from 'helpers/utils/history';

import AppError from './AppError/AppError';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

authorizationProvider(store);

const App = () => (
  <Sentry.ErrorBoundary fallback={<AppError />} showDialog>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Elements
            stripe={stripe}
            options={{
              fonts: [
                {
                  cssSrc: STRIPE_FONT,
                },
              ],
            }}
          >
            <ScrollToTop />
            <ModalProvider>
              <Root />
            </ModalProvider>
          </Elements>
        </Router>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
);

const LDProvider = process.env.REACT_APP_LD_CLIENT_SIDE_ID
  ? withLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    })(App)
  : App;

export default LDProvider;
