import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import { EmailLink } from 'components';

import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';

const ErrorContent = ({ handleModalClose }: { handleModalClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="OrderResponseModal-Wrapper">
      <ErrorIcon className="OrderResponseModal-ErrorIcon" />
      <div className="OrderResponseModal-Title">{t('DesignService.error')}</div>
      <div className="OrderResponseModal-Text">
        <p>
          Please reach out to our Customer Success team (chat box below or <EmailLink />) for more
          information.
        </p>
      </div>
      <Button className="Button--Blue" onClick={handleModalClose}>
        {t('DesignService.close')}
      </Button>
    </div>
  );
};

export default ErrorContent;
