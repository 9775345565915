import { TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import FormProps from '../PlanSetForms/props';
import UploadForm from '../UploadForm/UploadForm';
import SetbackInputForms from './SetbackInputForms';

const UploadForms = ({
  getFieldProps,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  tierLevel,
  filesAlreadyStored,
  handleFilesInAlbum,
  uploadComplete,
  setUploadComplete,
  ...formikProps
}: {
  tierLevel: TIER_LEVEL;
  filesAlreadyStored: any;
  handleFilesInAlbum: (e: any) => void;
  uploadComplete: boolean;
  setUploadComplete: Dispatch<SetStateAction<boolean>>;
} & FormikProps<Record<string, string>>) => {
  return (
    <>
      <SetbackInputForms
        setFieldTouched={setFieldTouched as FormProps['setFieldTouched']}
        setFieldValue={setFieldValue as FormProps['setFieldValue']}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        {...formikProps}
      />
      <UploadForm
        uploadComplete={uploadComplete}
        setUploadComplete={setUploadComplete}
        handleFilesInAlbum={handleFilesInAlbum}
        filesAlreadyStored={filesAlreadyStored}
        tierLevel={tierLevel}
        areNewBuildPlansRequired={false}
      />
    </>
  );
};

export default UploadForms;
