import { Dispatch } from 'react';
import { isTablet } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Card, Checkbox } from 'antd';
import cn from 'classnames';
import TooltipWrapper from 'screens/TooltipWrapper/TooltipWrapper';
import { Project } from 'types';

import { AppDispatch } from 'state/store/store';

import { Tag, Tooltip } from 'components';

import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import { maintenanceReportRoute } from 'helpers/constants/routes';
import { THUMBNAIL_URL } from 'helpers/constants/urls';
import { isDesktopView } from 'helpers/utils/breakpoints';
import { formatDate } from 'helpers/utils/dateFormat';

import { ReactComponent as Drone } from 'assets/drone-small.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder-icon.svg';
import { ReactComponent as Satellite } from 'assets/satellite-small.svg';

import { goToProjectDetails } from './helpers';
import './ProjectTile.scss';
import ProjectTileMenu from './ProjectTileMenu';

const { Meta } = Card;

type AdditionalTypes = {
  isListView: boolean;
  onCheck: ((id: string) => {}) | null;
  isChecked?: boolean;
  isDesignsPage?: boolean;
  onsiteDesignCount?: number;
  remoteDesignCount?: number;
  canUseRemoteDesignFeature: boolean;
};

type PropType = AdditionalTypes & Project;

const ProjectTile = ({
  id,
  thumbnailUrl,
  folder,
  name,
  address,
  status,
  statusColor,
  statusDescription,
  createdAt,
  isListView,
  onCheck,
  isChecked,
  isDesignsPage = false,
  onsiteDesignCount = 0,
  remoteDesignCount = 0,
  canUseRemoteDesignFeature,
}: PropType) => {
  const handleCheckProject = (id: string) => onCheck && onCheck(id);

  thumbnailUrl = thumbnailUrl || THUMBNAIL_URL;
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const desktopView = isDesktopView();

  const handleTileClick = (id: string, statusDescription: string, dispatch: Dispatch<any>) => {
    if (statusDescription === PROJECT_STATUSES.MAINTENANCE) {
      history.push(maintenanceReportRoute(id));
    } else {
      history.push(goToProjectDetails(id, statusDescription, dispatch));
    }
  };

  const getIcons = () => (
    <div className="ProjectTile-IconWrapper">
      {remoteDesignCount ? (
        <Tooltip title="Has Remote Designs">
          <Satellite
            data-testid="project-has-satellite-icon"
            className={isListView ? 'ProjectTile-Icon--Blue' : 'ProjectTile-Icon--White'}
          />
        </Tooltip>
      ) : null}
      {onsiteDesignCount ? (
        <Tooltip title="Has On-site Designs">
          <Drone
            data-testid="project-has-onsite-icon"
            className={isListView ? 'ProjectTile-Icon--Blue' : 'ProjectTile-Icon--White'}
          />
        </Tooltip>
      ) : null}
    </div>
  );

  return (
    <div
      className={cn('ProjectTile', {
        'ProjectTile--List': (isListView && desktopView) || isTablet,
      })}
    >
      {onCheck && (
        <div className="ProjectTile-Checkbox">
          <Checkbox
            onChange={() => handleCheckProject(id)}
            checked={isChecked}
            aria-checked={isChecked}
            aria-label="select project"
          />
        </div>
      )}
      <div className="ProjectTile-Status">
        {canUseRemoteDesignFeature && desktopView && !isDesignsPage ? getIcons() : null}
        {status &&
          statusDescription !== PROJECT_STATUSES.PROCESSED &&
          statusDescription !== PROJECT_STATUSES.REMOTE && (
            <Tag maxTextLength={30} color={statusColor}>
              <>
                {canUseRemoteDesignFeature &&
                  desktopView &&
                  !isDesignsPage &&
                  statusDescription !== PROJECT_STATUSES.MAINTENANCE && (
                    <Drone className="ProjectTile-TagIcon" />
                  )}
                {statusDescription}
              </>
            </Tag>
          )}
        {status &&
          onCheck &&
          statusDescription === (PROJECT_STATUSES.DRAFT || PROJECT_STATUSES.ERROR) && (
            <ProjectTileMenu name={name} id={id} />
          )}
      </div>
      <Card
        className={cn('ProjectTile-Card')}
        key={id}
        hoverable
        tabIndex={0}
        cover={<img alt="project thumbnail" src={thumbnailUrl} />}
        onClick={() => statusDescription && handleTileClick(id, statusDescription, dispatch)}
        data-testid="project-tile"
      >
        <div className="ProjectTile-Folder">
          {folder && (
            <Tag maxTextLength={30} color={folder?.color}>
              <div className="TagContent">
                <FolderIcon className="FolderIcon" />
                <span className="ProjectTile-Folder-Text">{folder?.name}</span>
              </div>
            </Tag>
          )}
        </div>
        <Meta
          title={<TooltipWrapper text={name} />}
          description={
            <>
              <TooltipWrapper className="ProjectTile-Address" text={address || ''} />
              <p className="ProjectTile-Date">Created: {formatDate(createdAt)}</p>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default ProjectTile;
