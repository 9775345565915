import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  adjustCredits,
  chargeExtraCreditsForServiceRequest,
  fetchAllCreditTransactionsForCompany,
  fetchAllCreditTransactionsForMonth,
  orderALaCarteCredits,
} from 'api/designServices/designServicesService';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { i18n } from 'helpers/utils/translations';

import { companySubscriptionInfoRequested } from '../companySlice/companySlice';

const name = 'orderDesignServiceCredits';

export const allCreditTransactionsRequestedPerMonth = createAsyncThunk(
  `${name}/allCreditTransactionsRequestedPerMonth`,
  async (
    {
      month,
      year,
      pageIndex = 1,
      size = PROJECT_LIST_SIZE,
      sortBy = undefined,
      filterBy = undefined,
    }: { month: number; year: number; pageIndex: number; size: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchAllCreditTransactionsForMonth({
        month,
        year,
        pageIndex,
        size,
        sortBy,
        filterBy,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const creditTransactionsRequested = createAsyncThunk(
  `${name}/creditTransactionsRequested`,
  async (
    {
      companyId,
      pageIndex = 1,
      size = PROJECT_LIST_SIZE,
      sortBy = undefined,
      filterBy = undefined,
    }: { companyId: string; pageIndex: number; size: number; sortBy?: {}; filterBy?: {} },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchAllCreditTransactionsForCompany(
        companyId,
        pageIndex,
        size,
        sortBy,
        filterBy
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const extraCreditChargeRequested = createAsyncThunk(
  `${name}/extraCreditChargeRequested`,
  async (
    {
      creditCount,
      serviceRequestId,
      companyId,
      memo,
      handleSuccess,
      handleError,
    }: {
      creditCount: number;
      serviceRequestId: string;
      companyId: string;
      memo: string;
      handleSuccess: (creditCount: number) => void;
      handleError: (description?: string) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chargeExtraCreditsForServiceRequest(
        creditCount,
        serviceRequestId,
        companyId,
        memo
      );
      handleSuccess(creditCount);
      return response.data;
    } catch (error: any) {
      handleError(i18n.t('DesignService.designCompleted.modelRevisionError'));
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const orderCreditsRequested = createAsyncThunk(
  `${name}/orderCreditsRequested`,
  async (
    {
      companyId,
      creditCount,
      onSuccess,
    }: {
      companyId: string;
      creditCount?: number;
      onSuccess: (balance: number, addedCredits?: number) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    if (creditCount) {
      try {
        const response = await orderALaCarteCredits({
          companyId,
          creditCount,
        });
        onSuccess(response.data.amount, creditCount);
        dispatch(companySubscriptionInfoRequested(companyId));
        return response.data.amount;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const creditAdjustmentRequested = createAsyncThunk(
  `${name}/creditAdjustmentRequested`,
  async (
    {
      companyId,
      quantity,
      category,
      memo,
      onSuccess,
      onFailure,
    }: {
      companyId: string;
      quantity: number;
      memo: string;
      category: string;
      onSuccess: (quantity: number, amount: number) => void;
      onFailure: () => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await adjustCredits({
        companyId,
        quantity,
        category,
        memo,
      });
      onSuccess(quantity, response.data.amount);
    } catch (error: any) {
      onFailure();
      return rejectWithValue(error.response.data);
    }
  }
);

type OrderDesignState = {
  creditTransactions: { items: []; balance: number; totalCount: number; pageIndex: number };
  isCreditTransactionsLoading: boolean;
  creditTransactionsError?: null | string;
  allCreditTransactionsByMonth: {
    items: [];
    balance: number;
    totalCount: number;
    pageIndex: number;
  };
  isAllCreditTransactionsByMonthLoading: boolean;
  allCreditTransactionsByMonthError?: null | string;
  balance: number | null;
  isLoading: boolean;
  error?: null | string;
  isSuccess: boolean;
};

const orderDesignServiceCreditsSlice = createSlice({
  name,
  initialState: {
    creditTransactions: { items: [], balance: 0, totalCount: 0, pageIndex: 1 },
    isCreditTransactionsLoading: false,
    creditTransactionsError: null,
    allCreditTransactionsByMonth: { items: [], balance: 0, totalCount: 0, pageIndex: 1 },
    isAllCreditTransactionsByMonthLoading: false,
    allCreditTransactionsByMonthError: null,
    balance: null,
    isLoading: false,
    error: null,
    isSuccess: false,
    options: null,
    isOptionsLoading: false,
    optionsError: null,
    isOptionsSuccess: false,
  } as OrderDesignState,
  reducers: {
    resetCreditOrderParameters: (state) => {
      state.balance = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.error = null;
    },
    resetCreditTransactions: (state) => {
      state.creditTransactions = { items: [], balance: 0, totalCount: 0, pageIndex: 1 };
      state.isCreditTransactionsLoading = false;
      state.creditTransactionsError = null;
    },
    resetAllCreditTransactionsByMonth: (state) => {
      state.allCreditTransactionsByMonth = { items: [], balance: 0, totalCount: 0, pageIndex: 1 };
      state.isAllCreditTransactionsByMonthLoading = false;
      state.allCreditTransactionsByMonthError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(creditTransactionsRequested.pending, (state) => {
      state.creditTransactionsError = null;
      state.isCreditTransactionsLoading = true;
    });
    builder.addCase(creditTransactionsRequested.fulfilled, (state, { payload }) => {
      state.creditTransactions = payload;
      state.isCreditTransactionsLoading = false;
    });
    builder.addCase(creditTransactionsRequested.rejected, (state, { error }) => {
      state.creditTransactionsError = error.message;
      state.isCreditTransactionsLoading = false;
    });
    builder.addCase(allCreditTransactionsRequestedPerMonth.pending, (state) => {
      state.allCreditTransactionsByMonthError = null;
      state.isAllCreditTransactionsByMonthLoading = true;
    });
    builder.addCase(allCreditTransactionsRequestedPerMonth.fulfilled, (state, { payload }) => {
      state.allCreditTransactionsByMonth = payload;
      state.isAllCreditTransactionsByMonthLoading = false;
    });
    builder.addCase(allCreditTransactionsRequestedPerMonth.rejected, (state, { error }) => {
      state.allCreditTransactionsByMonthError = error.message;
      state.isAllCreditTransactionsByMonthLoading = false;
    });
    builder.addCase(orderCreditsRequested.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(orderCreditsRequested.fulfilled, (state, { payload }) => {
      state.balance = payload;
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(orderCreditsRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(creditAdjustmentRequested.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(creditAdjustmentRequested.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(creditAdjustmentRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
    builder.addCase(extraCreditChargeRequested.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(extraCreditChargeRequested.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(extraCreditChargeRequested.rejected, (state, { error }) => {
      state.error = error.message;
      state.isLoading = false;
    });
  },
});

export const {
  resetCreditOrderParameters,
  resetCreditTransactions,
  resetAllCreditTransactionsByMonth,
} = orderDesignServiceCreditsSlice.actions;

export default orderDesignServiceCreditsSlice.reducer;
