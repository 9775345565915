import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { ServiceRequest } from 'types';

import styled from 'styled-components';

import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import { ACCESS } from 'helpers/constants';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { StyledServiceGroupTitle } from '../SelectDesignButton/components';
import ExpandableDesignOptions from './ExpandableDesignOptions/ExpandableDesignOptions';
import { getDesignOptions } from './getDesignOptions';
import { OnsiteOptionsWrapper } from './OnsiteOptionsWrapper/OnsiteOptionsWrapper';
import { PrelimOptionsWrapper } from './PrelimOptionsWrapper/PrelimOptionsWrapper';

export const SubtitleWrapper = styled.div`
  color: ${colors.darkGray};
  font-size: ${fontSizes.small};
  margin-left: 0.5rem;
  margin-bottom: 1rem;
`;

type SelectDesignOptionsProps = {
  selected: { tierLevel: TIER_LEVEL; isExpedited: boolean } | null;
  handleSelection: (serviceType: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => void;
  setSelectedDesignInfo: (designInfo: Partial<ServiceRequest> & { projectId?: string }) => void;
  projectId: string;
  upgrade?: boolean;
  actionButtons?: JSX.Element;
};

const SelectDesignOptions = ({
  selected,
  handleSelection,
  setSelectedDesignInfo,
  projectId,
  upgrade,
  actionButtons: ActionButtons,
}: SelectDesignOptionsProps) => {
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const { project } = useSelector((state: RootState) => state.project);
  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const designServicesPrelimAccess = useFeatureToggle(ACCESS.DESIGN_SERVICES_PRELIM);

  const prelimAccess =
    useFeatureToggle(ACCESS.REMOTE_DESIGN) || activeSubscription?.canUseRemoteDesign;

  useEffect(() => {
    if (!activeSubscription && project?.companyId) {
      dispatch(companyActiveSubscriptionRequested(project.companyId));
    }
  }, [project?.companyId, dispatch, activeSubscription]);

  useEffect(() => {
    if (selected) {
      setSelectedDesignInfo({
        projectId,
        tierLevel: selected.tierLevel,
        status: DESIGN_STATUS.ordered,
        isExpedited: selected?.isExpedited ?? false,
      });
    }
  }, [projectId, selected, setSelectedDesignInfo]);

  const hasTrueUpRequests = serviceRequests
    ? serviceRequests.filter(
        (r: ServiceRequest) =>
          r.tierLevel === TIER_LEVEL.trueUp && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const hasSitePlanRequests = serviceRequests
    ? serviceRequests.filter(
        (r: ServiceRequest) =>
          r.tierLevel === TIER_LEVEL.sitePlan && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const isRemoteOrDraft = !project?.type;

  const designOptions = getDesignOptions({
    project,
    upgrade,
    hasSitePlanRequests,
    hasTrueUpRequests,
  });

  if (isRemoteOrDraft) {
    return (
      <>
        <StyledServiceGroupTitle>{t('DesignService.preliminary')}</StyledServiceGroupTitle>
        <PrelimOptionsWrapper selected={selected} handleSelection={handleSelection} />
        {ActionButtons}
      </>
    );
  }

  return designServicesPrelimAccess && prelimAccess ? (
    <>
      {!upgrade ? (
        <ExpandableDesignOptions title={t('DesignService.preliminary')}>
          <PrelimOptionsWrapper selected={selected} handleSelection={handleSelection} />
        </ExpandableDesignOptions>
      ) : null}

      <ExpandableDesignOptions
        title={t('DesignService.onsite')}
        subTitle={t('OnsiteServiceRequestSubtitle')}
        collapsed={!project?.type}
      >
        <OnsiteOptionsWrapper
          designOptions={designOptions}
          selected={selected}
          handleSelection={handleSelection}
        />
      </ExpandableDesignOptions>
      {ActionButtons}
    </>
  ) : (
    <>
      <StyledServiceGroupTitle>{t('DesignService.onsite')}</StyledServiceGroupTitle>
      <SubtitleWrapper>
        <ExclamationCircleFilled /> {t('OnsiteServiceRequestSubtitle')}
      </SubtitleWrapper>
      <OnsiteOptionsWrapper
        designOptions={designOptions}
        selected={selected}
        handleSelection={handleSelection}
      />
      {ActionButtons}
    </>
  );
};

export default SelectDesignOptions;
