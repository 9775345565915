import { object, string } from 'yup';

import { FORM_CONTROLS } from './form';

export const validationSchema = object({
  [FORM_CONTROLS.TITLE]: string()
    .required('Report title is required')
    .trim()
    .strict(true)
    .min(1, 'The report title needs to be at least 1 character long')
    .max(30, 'The report title can be maximum 30 characters long'),
});
