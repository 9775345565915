import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchCompanyModuleLibraries } from 'api/moduleLibrary/moduleLibraryService';

const name = 'moduleLibrary';

type StateType = {
  companyModules: any[];
  isCompanyModulesLoading: boolean;
  error?: any;
};

export const companyModuleLibrariesRequested = createAsyncThunk(
  `${name}/companyModuleLibrariesRequested`,
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyModuleLibraries(userId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

const moduleLibrarySlice = createSlice({
  name,
  initialState: {
    companyModules: [],
    isCompanyModulesLoading: false,
    error: null,
  } as StateType,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companyModuleLibrariesRequested.pending, (state: StateType) => {
        state.isCompanyModulesLoading = true;
      })
      .addCase(companyModuleLibrariesRequested.fulfilled, (state: StateType, { payload }) => {
        state.companyModules = Object.keys(payload.modules);
        state.isCompanyModulesLoading = false;
      })
      .addCase(companyModuleLibrariesRequested.rejected, (state, { error }) => {
        state.error = error.message;
        state.isCompanyModulesLoading = false;
      });
  },
});

export default moduleLibrarySlice.reducer;
