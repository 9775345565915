import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import { integrationStatus } from 'api/integrations/integrationService';

import { useQuery } from 'helpers/hooks/useQuery';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as GearIcon } from 'assets/icons/gear-icon-2.svg';

import { OAUTH2STATUS, OAUTH_ERROR } from '../constants';
import IntegrationsSettingsModal from '../IntegrationSettingsModal/IntegrationSettingsModal';
import { IntegrationCardProps } from '../types';
import './IntegrationCard.scss';
import IntegrationConnection from './IntegrationConnection';

const IntegrationCard = ({ icon, integration }: IntegrationCardProps) => {
  const { t } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isSettingsModalVisible, toggleSettingsModal] = useToggle(false);
  const [destinationPath, setDestinationPath] = useState('');
  const query = useQuery();
  const oauth2Status = useMemo(() => query.get(OAUTH2STATUS), [query]);

  const handleModalClose = () => toggleSettingsModal();

  const handleSettingsClick = () => {
    toggleSettingsModal();
  };

  useEffect(() => {
    const isIntegrationConnected = async () => {
      const googleIntegrationStatus = await integrationStatus(integration.api);

      if (googleIntegrationStatus?.data?.connected) {
        setIsAuth(true);
        setIsConnected(true);

        if (googleIntegrationStatus?.data?.destination) {
          setDestinationPath(googleIntegrationStatus?.data?.destination?.name);
        } else {
          toggleSettingsModal();
        }
      }
    };
    isIntegrationConnected();

    if (oauth2Status === OAUTH_ERROR) {
      setIsAuth(false);
      setIsConnected(false);
    }
  }, [setIsAuth, toggleSettingsModal, oauth2Status, integration.api]);

  return (
    <>
      <IntegrationsSettingsModal
        isSettingsModalVisible={isSettingsModalVisible}
        handleModalClose={handleModalClose}
        toggleSettingsModal={toggleSettingsModal}
        setDestinationPath={setDestinationPath}
        integration={integration}
        headerTitle={
          <div className="header-title">
            {icon}
            {integration.name}
          </div>
        }
      />
      <div className="IntegrationCard">
        <div className="integrationIcon">{icon}</div>
        {isConnected && (
          <button
            className="settings"
            onClick={handleSettingsClick}
            data-testid="integration-settings-button"
          >
            {t('Integrations.settings')} <GearIcon />
          </button>
        )}
        <div className="name">{integration.name}</div>
        <div className="destination">
          {destinationPath && (
            <>
              <span className="title">Destination:</span>{' '}
              <Tooltip
                placement="bottom"
                title={destinationPath.split(',').join(' > ')}
                arrowPointAtCenter
              >
                <div className="value" data-testid="integration-destination">
                  {destinationPath.split(',').pop()}
                </div>
              </Tooltip>
            </>
          )}
        </div>
        <IntegrationConnection
          isAuth={isAuth}
          isConnected={isConnected}
          setIsConnected={setIsConnected}
          integration={integration}
        />
      </div>
    </>
  );
};

export default IntegrationCard;
